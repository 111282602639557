import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { UserOutlined } from '@ant-design/icons';
// import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import { Button, Col, Input, Row, Form } from 'antd';

const initialGroupValues = {
  name: '',
};

class CreateGroupForm extends PureComponent {
  state = {
    error: null,
    user: initialGroupValues,
    loading: false,
  };

  formOnChange = (e) => {
    const {
      target: { name, value },
    } = e;
    this.setState({ user: { ...this.state.user, [name]: value } });
  };

  handleSubmit = () => {
    const {
      user: { name },
    } = this.state;

    if (!name) this.setState({ error: { name: true } });
    else
      this.setState({ loading: true }, () => {
        this.props
          .createGroup(name)
          .then(() => {
            this.props.setVisibility(false);
            this.setState({ user: initialGroupValues, loading: false });
          })
          .catch(() => this.setState({ loading: false }));
      });
  };

  render() {
    const {
      error,
      user: { name },
      loading,
    } = this.state;
    return (
      <Form onFinish={this.handleSubmit} onChange={this.formOnChange}>
        <Row gutter={12}>
          <Col>
            <Form.Item validateStatus={error && error.name ? 'error' : null}>
              <Input
                name="name"
                prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                placeholder="Name (Required)"
                value={name}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={loading} disabled={!name}>
              Create
            </Button>
          </Form.Item>
        </Row>
      </Form>
    );
  }
}

CreateGroupForm.propTypes = {
  createGroup: PropTypes.func,
};

export default CreateGroupForm;
