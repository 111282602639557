import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Table, Loader, Dimmer, Segment, Form, Input, Button } from 'semantic-ui-react';
import { InboxOutlined } from '@ant-design/icons';
import { Popconfirm } from 'antd';
import Dropzone from 'react-dropzone';
import debounce from 'lodash.debounce';

import {
  getGroups,
  createGroup,
  deleteGroup,
  uploadContactsGroup,
  searchContactGroup,
} from '../../actions/groups/groups';
import history from '../../utils/history';
import NavigationMenu from '../../components/navigationMenu';
import DropDown from '../../components/addDropDown';
import TimeStamp from '../../utils/timeStamp';
import PermissionHandler from '../../utils/permissionsHandler';

import './groupContactsPage.sass';

class groupContactsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tableLoading: true,
      newGroup: {
        name: '',
        createSuccess: false,
        loading: false,
      },
      uploaded: false,
      percent: 0,
      newGroupName: '',
      searchText: '',
      searchLoading: false,
    };
    this.delayedSearch = debounce(this.searchAction, 400);
  }

  componentDidMount() {
    this.props.getGroups().then(() => {
      this.setState({ tableLoading: false });
    });
  }

  onChangeCreateGroup = (e) => this.setState({ newGroup: { ...this.state.newGroup, name: e.target.value } });

  submitCreateGroup = () => {
    this.setState({ newGroup: { ...this.state.newGroup, loading: true } });
    const { name } = this.state.newGroup;
    if (name) {
      this.props
        .createGroup(name)
        .then(() =>
          this.setState({ newGroup: { ...this.state.newGroup, name: '', createSuccess: true, loading: false } }),
        )
        .catch(() =>
          this.setState({ newGroup: { ...this.state.newGroup, name: '', createSuccess: true, loading: false } }),
        );
    }
  };

  onDrop = (file) => {
    const config = {
      onUploadProgress: (progressEvent) => {
        const percent = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        if (percent >= 100) {
          this.setState({ percent: 100 });
        } else {
          this.setState({ percent });
        }
      },
    };

    this.props.uploadContactsGroup(file[0], this.state.newGroupName, config).catch(() =>
      this.setState({
        uploaded: false,
        percent: 0,
      }),
    );
  };

  eraseUploadData = () => {
    this.setState({
      newGroupName: '',
      uploaded: false,
      percent: 0,
    });
  };

  searchAction = () => {
    this.setState({ searchLoading: true }, () =>
      this.props.searchContactGroup(this.state.searchText).then(() => this.setState({ searchLoading: false })),
    );
  };

  searchGroup = (e) => {
    this.setState({ searchText: e.target.value });
    this.delayedSearch();
  };

  render() {
    const groups =
      this.props.groups.allGroups &&
      Object.values(this.props.groups.allGroups).length !== 0 &&
      Object.values(this.props.groups.allGroups)
        .reverse()
        .map((group) => {
          const key = `contact-${group.id}`;
          return (
            <Table.Row key={key}>
              <Table.Cell onClick={() => history.push(`/groups/${group.id}`)}>{group.name}</Table.Cell>
              <Table.Cell onClick={() => history.push(`/groups/${group.id}`)}>{group.contact_count}</Table.Cell>
              <Table.Cell onClick={() => history.push(`/groups/${group.id}`)}>
                <TimeStamp date={group.created_at} />
              </Table.Cell>
              <Table.Cell>
                <PermissionHandler button="contact-group-delete">
                  <Popconfirm
                    style={{ zIndex: 100 }}
                    title="Are you sure you want to delete this Group?"
                    okText="Yes"
                    cancelText="No"
                    onConfirm={() => this.props.deleteGroup(group.id)}
                  >
                    <Button size="mini">Delete</Button>
                  </Popconfirm>
                </PermissionHandler>
              </Table.Cell>
            </Table.Row>
          );
        });

    return (
      <div>
        <NavigationMenu statusPage="groups" />
        <div className={`groups-page ${this.props.user.sticky_header ? '--fixed' : ''}`}>
          <PermissionHandler button="contact-group-create">
            <DropDown
              className="groups-page__drop-down"
              createSuccess={this.state.newGroup.createSuccess}
              buttonName="Create Group"
              height={45}
            >
              <Form onChange={(e) => this.onChangeCreateGroup(e)}>
                <Form.Group widths="equal">
                  <Form.Field>
                    <Input value={this.state.newGroup.name} size="mini" name="name" fluid placeholder="Group name" />
                  </Form.Field>
                  <Button
                    loading={this.state.newGroup.loading}
                    onClick={this.submitCreateGroup}
                    size="mini"
                    disabled={!this.state.newGroup.name}
                  >
                    Create
                  </Button>
                </Form.Group>
              </Form>
            </DropDown>
          </PermissionHandler>

          <DropDown
            createSuccess={this.state.uploaded}
            buttonName="Upload Contacts Group"
            height={260}
            onClose={() => this.eraseUploadData()}
          >
            <Input
              value={this.state.newGroupName}
              onChange={(e) => this.setState({ newGroupName: e.target.value })}
              style={{ marginBottom: 15, width: 200 }}
              size="mini"
              placeholder=" New Group Name"
            />
            <div className="wrap-contacts-group-upload">
              <Dropzone
                accept="text/csv"
                multiple={false}
                onDrop={this.onDrop}
                className="contacts-page__drop-zone"
                disabled={this.state.newGroupName.length < 3}
              >
                <div className="contacts-page__drop-zone_wrapper">
                  <p className="contacts-page__drop-zone_icon">
                    <InboxOutlined />
                  </p>
                  <p className="contacts-page__drop-zone_text">
                    {this.state.newGroupName.length < 3 && 'Type in field above the new name of group'}
                    {this.state.newGroupName.length >= 3 && 'Click or drag file (only one) to this area to upload'}
                  </p>
                  {this.state.newGroupName.length >= 3 && (
                    <div className="contacts-page__drop-zone_hint">Support only for CSV file</div>
                  )}
                  <div
                    className="contacts-page__drop-zone_percent"
                    style={{ opacity: this.state.percent === 0 ? 0 : 1 }}
                  >
                    <div className="contacts-page__drop-zone_percent-count" style={{ width: `${this.state.percent}%` }}>
                      <div
                        className="contacts-page__drop-zone_percent-done"
                        style={{ opacity: this.state.percent === 100 ? 1 : 0 }}
                      >
                        Successfully Uploaded
                      </div>
                    </div>
                  </div>
                </div>
              </Dropzone>
            </div>
          </DropDown>

          <Input
            loading={this.state.searchLoading}
            placeholder="Search group"
            style={{ width: 200, marginTop: 20 }}
            size="mini"
            value={this.state.searchText}
            onChange={this.searchGroup}
          />

          {groups && (
            <Segment vertical>
              <Dimmer active={this.state.tableLoading} inverted>
                <Loader inverted content="Fetching table..." />
              </Dimmer>
              <Table basic selectable>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell width={8}>Group Name</Table.HeaderCell>
                    <Table.HeaderCell width={2}>Users</Table.HeaderCell>
                    <Table.HeaderCell width={3}>Created at</Table.HeaderCell>
                    <Table.HeaderCell width={3}>Actions</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>

                <Table.Body>{groups}</Table.Body>
              </Table>
            </Segment>
          )}
        </div>
      </div>
    );
  }
}

groupContactsPage.defaultProps = {
  user: {},
};

groupContactsPage.propTypes = {
  getGroups: PropTypes.func.isRequired,
  createGroup: PropTypes.func.isRequired,
  groups: PropTypes.shape({
    allGroups: PropTypes.oneOfType([PropTypes.array, PropTypes.shape({})]),
  }).isRequired,
  deleteGroup: PropTypes.func.isRequired,
  uploadContactsGroup: PropTypes.func.isRequired,
  searchContactGroup: PropTypes.func.isRequired,
  user: PropTypes.shape({
    sticky_header: PropTypes.number,
  }),
};

const mapStateToProps = (store) => ({
  groups: store.groups,
  user: store.user,
});

export default connect(mapStateToProps, {
  getGroups,
  createGroup,
  deleteGroup,
  uploadContactsGroup,
  searchContactGroup,
})(groupContactsPage);
