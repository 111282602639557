import React, { PureComponent } from 'react';

class JobResultE164Search extends PureComponent {
  render() {
    if (!this.props?.data?.additional_info?.data) return <div>Data is missing</div>;
    const {
      data: { additional_info: info },
    } = this.props;

    if (info && info.data) {
      if (info.data.found)
        return (
          <div className="job-content__default-result">
            {info.data.plmnNetwork && (
              <div className="dashboard-page__search-result_info">
                <div className="job-content__default-result_header">Plmn Network</div>
                <div className="dashboard-page__search-result_info__tables">
                  <div>
                    <div className="table-item">
                      <div className="table-item__name">Country</div>
                      <div className="table-item__value">{info.data.plmnNetwork.country}</div>
                    </div>
                    <div className="table-item">
                      <div className="table-item__name">NetworkBrand</div>
                      <div className="table-item__value">{info.data.plmnNetwork.networkBrand}</div>
                    </div>
                    <div className="table-item">
                      <div className="table-item__name">NetworkName</div>
                      <div className="table-item__value">{info.data.plmnNetwork.networkName}</div>
                    </div>
                    <div className="table-item">
                      <div className="table-item__name">NetworkId</div>
                      <div className="table-item__value">{info.data.plmnNetwork.networkId}</div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        );
    }

    return <div>Result failed</div>;
  }
}

export default JobResultE164Search;
