import React from 'react';
import { FIRE_ALERT, SINGLE_SEARCH, GET_IMSI_BY_PHONE, GET_PHONE_BY_IMSI } from '../../constants/actionTypes';
import api from '../../utils/appApi';
import { dispatchNotificationOrSound } from '../../utils/notificationAndSound';

const parseSearchError = (err, dispatch) => {
  if (err?.response?.data) {
    if (!err?.response?.data?.errors?.note) {
      const phone = !err?.response?.data?.errors?.phone ? '' : err.response.data.errors.phone[0];
      const imsi = !err?.response?.data?.errors?.imsi ? '' : err.response.data.errors.imsi[0];
      const credits = !err?.response?.data?.errors?.user ? '' : err.response.data.errors.user[0];
      const queryType = !err?.response?.data?.errors?.query_type ? '' : err.response.data.errors.query_type[0];
      const notes = !err?.response?.data?.errors?.note ? '' : JSON.stringify(err.response.data.errors.note[0]);

      fireNotificationBrowser(`${err.response.data.message}`, 'error');

      dispatch({
        type: FIRE_ALERT,
        payload: {
          specificMessage: `${phone} ${imsi} ${credits} ${queryType} ${notes}`,
          message: err.response.data.message,
        },
      });
    } else {
      const user0 = !err.response.data.errors.user ? '' : err.response.data.errors.user[0];
      const user1 = !err.response.data.errors.user ? '' : err.response.data.errors.user[1];
      const notes = !err.response.data.errors.note ? {} : err.response.data.errors.note[0];

      fireNotificationBrowser(`${err.response.data.message}`, 'error');
      dispatch({
        type: FIRE_ALERT,
        payload: {
          specificMessage: (
            <div style={{ overflowWrap: 'break-word' }}>
              {createNotes(notes)}
              {user0 && <hr />}
              {user0 && <div>{user0}</div>}
              {user1 && <div>{user1}</div>}
            </div>
          ),
          message: err.response.data.message,
        },
      });
    }
  }
};

const createNotes = (notes) =>
  Object.keys(notes).map((noteName, index) => {
    const key = `noteId-${index}`;
    return (
      <div key={key}>
        <span style={{ fontWeight: 600 }}>{noteName}:</span> {notes[noteName]}
      </div>
    );
  });

const fireNotificationBrowser = (message, type) => {
  const url = `${window.location.protocol}//${window.location.host}/`;
  if (type === 'success') dispatchNotificationOrSound(message, url, 'success', true);
  else dispatchNotificationOrSound(message, url, 'error', true);
};

export const getSingleSearch = (data) => (dispatch) =>
  new Promise((resolve, reject) =>
    api.contact
      .getSingleSearch(data)
      .then((res) => {
        if (res.data.data.code !== null || res.data.data.title !== null) {
          const detail = !res.data.data.detail ? '' : `${res.data.data.detail}`;
          const code = !res.data.data.code ? '' : `${res.data.data.code}`;
          const title = !res.data.data.title ? '' : `${res.data.data.title}`;

          fireNotificationBrowser(`${title && title}, Code: ${code}. Detail: ${detail}`, 'error');

          dispatch({
            type: FIRE_ALERT,
            payload: {
              specificMessage: (
                <div style={{ overflowWrap: 'break-word' }}>
                  {code && (
                    <div>
                      <span style={{ fontWeight: 600 }}>Code:</span> {code}
                    </div>
                  )}
                  {detail && (
                    <div>
                      <span style={{ fontWeight: 600 }}>Detail:</span> {detail}
                    </div>
                  )}
                </div>
              ),
              message: title && title,
            },
          });
        } else fireNotificationBrowser('Location found', 'success');

        dispatch({
          type: SINGLE_SEARCH,
          payload: res.data.data,
        });
        return resolve(res.data.data);
      })
      .catch((err) => {
        parseSearchError(err, dispatch);
        reject(err);
      }),
  );

export const getImsiByPhone = (phone) => (dispatch) =>
  new Promise((resolve, reject) =>
    api.contact
      .getImsiByPhone(phone)
      .then((res) => {
        if (res.data.data.code !== null || res.data.data.title !== null) {
          const detail = !res.data.data.detail ? '' : `${res.data.data.detail}`;
          const code = !res.data.data.code ? '' : `${res.data.data.code}`;
          const title = !res.data.data.title ? '' : `${res.data.data.title}`;

          fireNotificationBrowser(`${title && title}, Code: ${code}. Detail: ${detail}`, 'error');

          dispatch({
            type: FIRE_ALERT,
            payload: {
              specificMessage: (
                <div style={{ overflowWrap: 'break-word' }}>
                  {code && (
                    <div>
                      <span style={{ fontWeight: 600 }}>Code:</span> {code}
                    </div>
                  )}
                  {detail && (
                    <div>
                      <span style={{ fontWeight: 600 }}>Detail:</span> {detail}
                    </div>
                  )}
                </div>
              ),
              message: title && title,
            },
          });
        } else fireNotificationBrowser('IMSI was found', 'success');

        dispatch({
          type: GET_IMSI_BY_PHONE,
          payload: res.data.data,
        });
        return resolve(res.data.data);
      })
      .catch((err) => {
        parseSearchError(err, dispatch);
        return reject(err);
      }),
  );

export const getForwarding = (phone) => (dispatch) =>
  new Promise((resolve, reject) =>
    api.contact
      .getForwarding(phone)
      .then((res) => {
        if (res.data.data.code !== null || res.data.data.title !== null) {
          const detail = !res.data.data.detail ? '' : `${res.data.data.detail}`;
          const code = !res.data.data.code ? '' : `${res.data.data.code}`;
          const title = !res.data.data.title ? '' : `${res.data.data.title}`;

          fireNotificationBrowser(`${title && title}, Code: ${code}. Detail: ${detail}`, 'error');

          dispatch({
            type: FIRE_ALERT,
            payload: {
              specificMessage: (
                <div style={{ overflowWrap: 'break-word' }}>
                  {code && (
                    <div>
                      <span style={{ fontWeight: 600 }}>Code:</span> {code}
                    </div>
                  )}
                  {detail && (
                    <div>
                      <span style={{ fontWeight: 600 }}>Detail:</span> {detail}
                    </div>
                  )}
                </div>
              ),
              message: title && title,
            },
          });
        }
        if (res.data.data.additional_info.forwarded === null) {
          fireNotificationBrowser(res.data.data.additional_info.error, 'error');
          dispatch({
            type: FIRE_ALERT,
            payload: {
              specificMessage: res.data.data.additional_info.error,
              message: 'Error',
            },
          });
        } else fireNotificationBrowser('Forwarding done', 'success');
        return resolve(res.data.data);
      })
      .catch((err) => {
        if (err.response.data.errors) {
          const phoneError = !err.response.data.errors.phone ? '' : err.response.data.errors.phone[0];

          fireNotificationBrowser(`${err.response.data.message}`, 'error');

          dispatch({
            type: FIRE_ALERT,
            payload: {
              specificMessage: `${phoneError}`,
              message: err.response.data.message,
            },
          });
        }
        return reject(err);
      }),
  );

export const getPhoneByImsi = (imsi) => (dispatch) =>
  new Promise((resolve, reject) =>
    api.contact
      .getPhoneByImsi(imsi)
      .then((res) => {
        if (res.data.data.code !== null || res.data.data.title !== null) {
          const detail = !res.data.data.detail ? '' : `${res.data.data.detail}`;
          const code = !res.data.data.code ? '' : `${res.data.data.code}`;
          const title = !res.data.data.title ? '' : `${res.data.data.title}`;

          fireNotificationBrowser(`${title && title}, Code: ${code}. Detail: ${detail}`, 'error');

          dispatch({
            type: FIRE_ALERT,
            payload: {
              specificMessage: (
                <div style={{ overflowWrap: 'break-word' }}>
                  {code && (
                    <div>
                      <span style={{ fontWeight: 600 }}>Code:</span> {code}
                    </div>
                  )}
                  {detail && (
                    <div>
                      <span style={{ fontWeight: 600 }}>Detail:</span> {detail}
                    </div>
                  )}
                </div>
              ),
              message: title && title,
            },
          });
        } else fireNotificationBrowser('Phone was found', 'success');

        dispatch({
          type: GET_PHONE_BY_IMSI,
          payload: res.data.data,
        });
        return resolve(res.data.data);
      })
      .catch((err) => {
        parseSearchError(err, dispatch);
        reject(err);
      }),
  );

export const getLocationSearch = (data) => (dispatch) =>
  new Promise((resolve, reject) =>
    api.contact
      .getSingleSearch(data)
      .then((res) => {
        dispatch({
          type: SINGLE_SEARCH,
          payload: res.data.data,
        });
        return resolve(res.data.data);
      })
      .catch((err) => {
        parseSearchError(err, dispatch);
        reject(err);
      }),
  );

export const getImsiLookupSearch = (phone) => (dispatch) =>
  new Promise((resolve, reject) =>
    api.contact
      .getImsiByPhone(phone)
      .then((res) => {
        dispatch({
          type: GET_IMSI_BY_PHONE,
          payload: res.data.data,
        });
        return resolve(res.data.data);
      })
      .catch((err) => {
        parseSearchError(err, dispatch);
        return reject(err);
      }),
  );

export const getPhoneLookupSearch = (imsi) => (dispatch) =>
  new Promise((resolve, reject) =>
    api.contact
      .getPhoneByImsi(imsi)
      .then((res) => {
        dispatch({
          type: GET_IMSI_BY_PHONE,
          payload: res.data.data,
        });
        return resolve(res.data.data);
      })
      .catch((err) => {
        parseSearchError(err, dispatch);
        return reject(err);
      }),
  );

export const getForwardingSearch = (phone) => (dispatch) =>
  new Promise((resolve, reject) =>
    api.contact
      .getForwarding(phone)
      .then((res) => {
        return resolve(res.data.data);
      })
      .catch((err) => {
        if (err.response.data && err.response.data.errors) {
          const phoneError = !err.response.data.errors.phone ? '' : err.response.data.errors.phone[0];
          fireNotificationBrowser(`${err.response.data.message}`, 'error');

          dispatch({
            type: FIRE_ALERT,
            payload: {
              specificMessage: `${phoneError}`,
              message: err.response.data.message,
            },
          });
        }
        return reject(err);
      }),
  );

export const getDosSearch = (imsi) => (dispatch) =>
  new Promise((resolve, reject) =>
    api.contact
      .getDos(imsi)
      .then((res) => {
        return resolve(res.data.data);
      })
      .catch((err) => {
        fireNotificationBrowser(`${err.response.data.message}`, 'error');
        dispatch({
          type: FIRE_ALERT,
          payload: {
            specificMessage: '',
            message: err.response.data.message,
          },
        });
        return reject(err);
      }),
  );

export const getE164Search = (data) => (dispatch) =>
  new Promise((resolve, reject) =>
    api.contact
      .getE164(data)
      .then((res) => {
        return resolve(res.data.data);
      })
      .catch((err) => {
        fireNotificationBrowser(`${err.response.data.message}`, 'error');
        dispatch({
          type: FIRE_ALERT,
          payload: {
            specificMessage: '',
            message: err.response.data.message,
          },
        });
        return reject(err);
      }),
  );

export const getE164SearchValidation = (phone, source_type) => (dispatch) =>
  new Promise((resolve, reject) =>
    api.contact
      .getE164validation(phone, source_type)
      .then((res) => {
        return resolve(res.data);
      })
      .catch((err) => {
        return reject(err);
      }),
  );

export const getE212Search = (data) => (dispatch) =>
  new Promise((resolve, reject) =>
    api.contact
      .getE212(data)
      .then((res) => {
        return resolve(res.data);
      })
      .catch((err) => {
        fireNotificationBrowser(`${err.response.data.message}`, 'error');
        dispatch({
          type: FIRE_ALERT,
          payload: {
            specificMessage: '',
            message: err.response.data.message,
          },
        });
        return reject(err);
      }),
  );

export const getE212SearchValidation = (imsi, source_type) => (dispatch) =>
  new Promise((resolve, reject) =>
    api.contact
      .getE212validation(imsi, source_type)
      .then((res) => {
        return resolve(res.data);
      })
      .catch((err) => {
        return reject(err);
      }),
  );
