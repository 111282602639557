import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import TimeStamp from '../../utils/timeStamp';
// import { Button } from 'semantic-ui-react';

const TeamWhiteListTable = (props) => {
  const columns = [
    {
      title: 'Team',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Updated at',
      dataIndex: 'updated',
      key: 'updated',
      render: (text) => <TimeStamp date={text} />,
    },
  ];

  const data =
    props.whiteList && Object.values(props.whiteList)?.length !== 0
      ? Object.values(props.whiteList)
          ?.reverse()
          ?.map((list) => {
            const key = `team-white-list-${list.id}`;
            return {
              key,
              id: list.id,
              // loading: props.loading,
              name: list.name,
              ranges: list.ranges,
              created: list.created_at,
              updated: list.updated_at,
              expand: (
                <div className="white-list-table-component__list">
                  {list?.ranges?.map((range) => (
                    <div
                      key={`range-team-${list.name}-list-${range.id}`}
                      className={`white-list-table-component__list_item ${
                        range.range_type === 1 ? '--whitelist-msisdn' : '--whitelist-imsi'
                      }`}
                    >
                      <span>
                        {range.range_type === 1 ? 'MSISDN: ' : 'IMSI: '}
                        {range.range}*
                      </span>
                      <span
                        role="button"
                        tabIndex={0}
                        className="white-list-table-component__list_item_delete"
                        onClick={() => props.deleteFromWhiteList(range.id)}
                      >
                        x
                      </span>
                    </div>
                  ))}
                </div>
              ),
            };
          })
      : [];

  return (
    <Table
      className="white-list-table-component"
      columns={columns}
      dataSource={data}
      expandedRowRender={(record) => <div>{record.expand}</div>}
      // onExpand={(expanded, record) => expandRow(expanded, record.key, record)}
    />
  );
};

TeamWhiteListTable.defaultProps = {
  // loading: 0,
};

TeamWhiteListTable.propTypes = {
  whiteList: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  // eslint-disable-next-line
  deleteFromWhiteList: PropTypes.func.isRequired,
  // loading: PropTypes.arrayOf(PropTypes.number),
};

export default TeamWhiteListTable;
