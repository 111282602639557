import React, { PureComponent } from 'react';
import { NumberOutlined, PhoneOutlined, UserAddOutlined, UserOutlined } from '@ant-design/icons';
// import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import { Button, Col, Input, Radio, Row, Form } from 'antd';

const initialUserValues = {
  name: '',
  alias: '',
  phone: '',
  imsi: '',
  description: '',
  sex: '',
};

class CreateContactForm extends PureComponent {
  state = {
    error: null,
    user: initialUserValues,
    loading: false,
  };

  formOnChange = (e) => {
    const {
      target: { name, value },
    } = e;
    this.setState({ user: { ...this.state.user, [name]: value } });
  };

  handleSubmit = () => {
    const {
      user: { name },
      user,
    } = this.state;

    if (!name) this.setState({ error: { name: true } });
    else
      this.setState({ loading: true }, () => {
        this.props
          .createContact(user)
          .then(() => {
            this.props.setVisibility(false);
            this.setState({ user: initialUserValues, loading: false });
          })
          .catch(() => this.setState({ loading: false }));
      });
  };

  render() {
    const {
      error,
      user: { name, alias, phone, imsi, description, sex },
      loading,
    } = this.state;
    return (
      <Form onFinish={this.handleSubmit} onChange={this.formOnChange}>
        <Row gutter={12}>
          <Col span={12}>
            <Form.Item validateStatus={error && error.name ? 'error' : null}>
              <Input
                name="name"
                prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                placeholder="Name (Required)"
                value={name}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Input
              name="alias"
              prefix={<UserAddOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder="Alias"
              value={alias}
            />
          </Col>
        </Row>
        <Row gutter={12}>
          <Col span={12}>
            <Input
              name="phone"
              prefix={<PhoneOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder="Phone"
              value={phone}
            />
          </Col>
          <Col span={12}>
            <Input
              name="imsi"
              prefix={<NumberOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder="IMSI"
              value={imsi}
            />
          </Col>
        </Row>
        <Row>
          <Input.TextArea
            name="description"
            rows={2}
            prefix={<PhoneOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
            placeholder="Description"
            value={description}
          />
        </Row>
        <Row>
          <span className="dashboard-tables__create-form_label">Gender:</span>
          <Radio.Group name="sex" value={sex}>
            <Radio value="1">Male</Radio>
            <Radio value="0">Female</Radio>
          </Radio.Group>
        </Row>
        <Button type="primary" htmlType="submit" loading={loading} disabled={!name || name.length < 3}>
          Create
        </Button>
      </Form>
    );
  }
}

export default CreateContactForm;
