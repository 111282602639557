import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { Table } from 'antd';

import './settingsLogTable.sass';

class LogTable extends PureComponent {
  expandContent = (data) => (
    <div>
      <div style={{ marginBottom: 10 }}>
        <span style={{ fontWeight: 600 }}>Binary: </span>
        <span>{data.binary}</span>
      </div>
      <div>
        <span style={{ fontWeight: 600 }}>Text: </span> <span>{data.text}</span>
      </div>
    </div>
  );
  render() {
    const columns = [
      {
        title: 'Message id',
        dataIndex: 'messageid',
        key: 'messageid',
      },
      {
        title: 'Source',
        dataIndex: 'source',
        key: 'source',
      },
      {
        title: 'Destination',
        dataIndex: 'destination',
        key: 'destination',
      },
      {
        title: 'Added',
        dataIndex: 'added',
        key: 'added',
        sorter: (a, b) => new Date(a.added) - new Date(b.added),
        defaultSortOrder: 'descend',
      },
    ];
    return (
      <div className="settings-log-table" style={{ marginTop: 20 }}>
        <Table
          rowKey="added"
          dataSource={this.props.data}
          columns={columns}
          expandedRowRender={(record) => this.expandContent(record)}
          pagination={{ pageSize: 10 }}
        />
      </div>
    );
  }
}

LogTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default LogTable;
