import React from 'react';

import {
  FaPhone,
  FaPhoneSlash,
  FaMapMarkedAlt,
  FaProjectDiagram,
  FaMapMarkerAlt,
  FaCompass,
  FaGlobe,
} from 'react-icons/fa';

const RobotIconType = (type, color) => {
  switch (type) {
    case 'online':
      return <FaPhone color={color} />;
    case 'offline':
      return <FaPhoneSlash color={color} />;
    case 'tracker':
      return <FaMapMarkedAlt color={color} />;
    case 'point-to-point':
      return <FaProjectDiagram color={color} />;
    case 'geo-point':
      return <FaMapMarkerAlt color={color} />;
    case 'geo-points':
      return <FaCompass color={color} />;
    case 'country-zone':
      return <FaGlobe color={color} />;

    default:
      return null;
  }
};

export default RobotIconType;
