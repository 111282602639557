import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Form, Header } from 'semantic-ui-react';
import { notification } from 'antd';
import { editUser } from '../../../actions/user/auth';

import './changePassword.sass';

class ChangePassword extends Component {
  state = {
    password: '',
    password_confirmation: '',
    error: false,
  };

  submitHasErrors = (password, repeatPassword) => {
    if (password.length <= 5 || !/[a-z]/.test(password) || !/[A-Z]/.test(password) || !/[0-9]/.test(password)) {
      return { result: true, message: 'Incorrect password' };
    } else if (password !== repeatPassword) return { result: true, message: "Passwords don't match" };

    return { result: false, message: '' };
  };

  changePasswordOnSubmit = (e) => {
    e.preventDefault();
    const pass1 = this.state.password;
    const pass2 = this.state.password_confirmation;

    const checkResult = this.submitHasErrors(pass1, pass2);

    if (!checkResult.result) {
      this.setState({ loading: true }, () => {
        this.props.editUser(this.props.userId, { password: pass1, password_confirmation: pass2 }).then(() => {
          this.setState({ password: '', password_confirmation: '' });
          notification.success({
            message: 'Success',
            description: 'The password was changed successfully',
          });
        });
      });
    } else if (checkResult.result) {
      this.setState({ error: true, password: '', password_confirmation: '' });
      notification.error({
        message: 'Error',
        description: 'Incorrect password',
      });
    } else if (pass1 !== pass2) {
      this.setState({ error: true, password: '', password_confirmation: '' });
      notification.error({
        message: 'Error',
        description: "The passwords don't match",
      });
    } else if (pass1 === '' || pass2 === '') {
      this.setState({ error: true });
      notification.error({
        message: 'Error',
        description: "The password fields can't be empty",
      });
    }
  };

  changePasswordOnChange = (e) => {
    e.preventDefault();
    this.setState({ [e.target.name]: e.target.value, error: false });
  };

  render() {
    return (
      <div className="change-password-form">
        <Form onChange={(e) => this.changePasswordOnChange(e)}>
          <Header as="h3">Change Password</Header>
          <Form.Group widths="equal">
            <Form.Input
              value={this.state.password}
              name="password"
              fluid
              size="mini"
              placeholder="New Password"
              type="password"
              error={this.state.error}
            />
            <Form.Input
              value={this.state.password_confirmation}
              name="password_confirmation"
              fluid
              size="mini"
              placeholder="Repeat New Password"
              type="password"
              error={this.state.error}
            />
            <Form.Button size="mini" type="submit" onClick={this.changePasswordOnSubmit}>
              Submit
            </Form.Button>
          </Form.Group>
        </Form>
        <div style={{ fontSize: 14 }}>
          {
            'Password must contain at least one upper & lower case character, one number and be at least 6 characters long.'
          }
        </div>
      </div>
    );
  }
}

ChangePassword.defaultProps = {
  userId: 0,
  editUser: () => null,
};

ChangePassword.propTypes = {
  editUser: PropTypes.func,
  userId: PropTypes.number,
};

export default connect(null, { editUser })(ChangePassword);
