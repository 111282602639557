import {
  GET_TEAMS,
  GET_SINGLE_TEAM,
  REMOVE_USER_FROM_TEAM,
  ADD_USER_TO_TEAM,
  MAKE_LEADER,
  CREATE_TEAM,
  DELETE_TEAM,
  EDIT_TEAM_NAME,
  LOGOUT,
} from '../../constants/actionTypes';
import initialState from './initState';

export default function users(state = initialState, action = {}) {
  switch (action.type) {
    case GET_TEAMS:
      return {
        ...state,
        allTeams: action.payload,
      };
    case EDIT_TEAM_NAME:
      return {
        ...state,
        allTeams: Object.values(action.payload),
      };
    case GET_SINGLE_TEAM: {
      const users = {};
      action.payload?.users_many?.forEach((user) => (users[user.id] = user));
      return {
        ...state,
        singleTeam: { ...action.payload, users_many: users },
      };
    }
    case REMOVE_USER_FROM_TEAM: {
      if (state?.singleTeam?.users_many) {
        const newUsers = state.singleTeam ? { ...state.singleTeam.users_many } : null;
        if (newUsers) {
          delete newUsers[action.payload.id];
          return {
            ...state,
            singleTeam: {
              ...state.singleTeam,
              users_many: newUsers,
            },
          };
        }
      }
      return state;
    }
    case ADD_USER_TO_TEAM: {
      if (state?.singleTeam?.users_many) {
        return {
          ...state,
          singleTeam: {
            ...state.singleTeam,
            users_many: {
              ...state.singleTeam.users_many,
              [action.payload.id]: { ...action.payload },
            },
          },
        };
      }
      return state;
    }
    case MAKE_LEADER: {
      if (state.singleTeam && state.singleTeam.users) {
        return {
          ...state,
          singleTeam: {
            ...state.singleTeam,
            users_many: {
              ...state.singleTeam.users_many,
              [action.payload.id]: { ...action.payload },
            },
          },
        };
      }
      return state;
    }
    case CREATE_TEAM:
      return {
        ...state,
        allTeams: [
          ...state.allTeams,
          {
            ...action.payload,
            leaders: [],
          },
        ],
      };
    case DELETE_TEAM:
      return {
        ...state,
        allTeams: Object.values(action.payload),
      };
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
}
