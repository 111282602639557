import React, { PureComponent } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Input, message, TreeSelect } from 'antd';
import { CheckCircleFilled, ExclamationCircleFilled } from '@ant-design/icons';
import AutoPassiveSearchResult from '../autoPassiveSearchResult';

import { Button, Icon } from 'semantic-ui-react';
import { debounce } from 'lodash';

import {
  getLocationSearch,
  getImsiLookupSearch,
  getPhoneLookupSearch,
  getForwardingSearch,
  getDosSearch,
  getE164Search,
  getE164SearchValidation,
  getE212Search,
  getE212SearchValidation,
} from '../../actions/singleSearch/singleSearch';
import { setJobSearchLoading, searchDataOnChange } from '../../actions/jobs/jobs';

import PermissionHandler from '../../utils/permissionsHandler';

import './searchComponent.sass';

export const searchTypes = {
  1: 'Location Search',
  4: 'IMSI Lookup',
  // 5: 'MSISDN Lookup',
  6: 'Forwarding Check',
  // 7: 'Hold',
  8: 'E164 Lookup',
  // 10: 'E212 Lookup',
};

class SearchComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.node = React.createRef();
    this.state = {
      searchType: '1',
      queryType: 'passive',
      searchData: {},

      autoPassiveSearchDataPhone: null,
      molsPhone: -1,
      isDropdownVisiblePhone: false,
      isDropdownLoadingPhone: false,
      errorMessagePhone: '',
      hasErrorPhone: false,

      autoPassiveSearchDataImsi: null,
      molsImsi: -1,
      isDropdownVisibleImsi: false,
      isDropdownLoadingImsi: false,
      errorMessageImsi: '',
      hasErrorImsi: false,
    };
    this.debouncedInputSetValue = debounce(() => this.props.searchDataOnChange({ ...this.state.searchData }), 500);
  }

  searchTypeOnChange = (searchType) => {
    this.setState({ searchType });
    this.resetAutoPassiveSearchComponent();
  };

  setQueryType = ({ target: { name } }) => this.setState({ queryType: name });

  inputOnChange = (e) => {
    const targetName = e.target.getAttribute('name');
    const targetValue = e.target.value;

    this.setState(
      (state) => ({ searchData: { ...state.searchData, [targetName]: targetValue } }),
      () => this.debouncedInputSetValue(),
    );
  };

  inputOnChangeWithValidation = (e) => {
    const targetName = e.target.getAttribute('name');
    const targetValue = e.target.value;

    const phoneInput = targetName === 'phone';
    const imsiInput = targetName === 'imsi';

    const isPhoneValidLength = phoneInput && targetValue.length > 8 && targetValue.length < 18;
    const isPhoneInvalidLength =
      phoneInput && targetValue.length > 0 && (targetValue.length < 9 || targetValue.length > 17);
    const isImsiValidLength = imsiInput && targetValue.length > 13 && targetValue.length < 16;
    const isImsiInvalidLength =
      imsiInput && targetValue.length > 0 && (targetValue.length < 14 || targetValue.length > 15);

    if (isPhoneValidLength) {
      this.setState({
        isDropdownLoadingPhone: true,
        isDropdownVisiblePhone: true,
        autoPassiveSearchDataPhone: null,
      });
      this.props
        .getE164SearchValidation(targetValue, 4)
        .then((res) => {
          this.onDataIsValid(res, 'numberOwnership', targetName);
        })
        .catch((err) => {
          const errorMessage = err.response.data.message;
          this.onDataIsInvalidFormat(errorMessage, targetName);
        });
    } else if (isPhoneInvalidLength) {
      this.setState({
        isDropdownLoadingPhone: true,
        isDropdownVisiblePhone: true,
        autoPassiveSearchDataPhone: null,
      });
      const errorMessage = 'The phone must be between 9 and 17 digits.';
      this.onDataIsInvalidLength(errorMessage, targetName);
    } else if (isImsiValidLength) {
      this.setState({
        isDropdownLoadingImsi: true,
        isDropdownVisibleImsi: true,
        autoPassiveSearchDataImsi: null,
      });
      this.props
        .getE212SearchValidation(targetValue, 4)
        .then((res) => {
          this.onDataIsValid(res, 'plmnNetwork', targetName);
        })
        .catch((err) => {
          const errorMessage = err.response.data.message;
          this.onDataIsInvalidFormat(errorMessage, targetName);
        });
    } else if (isImsiInvalidLength) {
      this.setState({
        isDropdownLoadingImsi: true,
        isDropdownVisibleImsi: true,
        autoPassiveSearchDataImsi: null,
      });
      const errorMessage = 'The imsi must be between 14 and 15 digits.';
      this.onDataIsInvalidLength(errorMessage, targetName);
    } else {
      if (phoneInput) {
        this.setState({
          molsPhone: -1,
          autoPassiveSearchDataPhone: null,
          isDropdownVisiblePhone: false,
          errorMessagePhone: '',
          hasErrorPhone: false,
        });
      }
      if (imsiInput) {
        this.setState({
          molsImsi: -1,
          autoPassiveSearchDataImsi: null,
          isDropdownVisibleImsi: false,
          errorMessageImsi: '',
          hasErrorImsi: false,
        });
      }
    }

    this.setState(
      (state) => ({ searchData: { ...state.searchData, [targetName]: targetValue } }),
      () => this.debouncedInputSetValue(),
    );
  };

  inputOnBlur = () => {
    this.setState({
      isDropdownVisiblePhone: false,
      isDropdownVisibleImsi: false,
    });
  };
  inputOnFocus = (e) => {
    const targetName = e.target.getAttribute('name');
    if (targetName === 'phone' && (this.state.errorMessagePhone || this.state.autoPassiveSearchDataPhone)) {
      this.setState({
        isDropdownVisiblePhone: true,
      });
    }
    if (targetName === 'imsi' && (this.state.errorMessageImsi || this.state.autoPassiveSearchDataImsi)) {
      this.setState({
        isDropdownVisibleImsi: true,
      });
    }
  };

  onDataIsValid = (generalRes, keyName, input) => {
    if (input === 'phone') {
      if (generalRes.data) {
        this.setState({
          molsPhone: generalRes.data[keyName]['3moLS'],
          autoPassiveSearchDataPhone: generalRes.data[keyName],
          isDropdownVisiblePhone: true,
          isDropdownLoadingPhone: false,
          hasErrorPhone: false,
        });
      } else {
        this.setState({
          autoPassiveSearchDataPhone: null,
          isDropdownVisiblePhone: true,
          isDropdownLoadingPhone: false,
          errorMessagePhone: generalRes.error.detail,
          hasErrorPhone: true,
        });
      }
    } else {
      if (generalRes.data) {
        this.setState({
          molsImsi: generalRes.data[keyName]['3moLS'],
          autoPassiveSearchDataImsi: generalRes.data[keyName],
          isDropdownVisibleImsi: true,
          isDropdownLoadingImsi: false,
          hasErrorImsi: false,
        });
      } else {
        this.setState({
          autoPassiveSearchDataImsi: null,
          isDropdownVisibleImsi: true,
          isDropdownLoadingImsi: false,
          errorMessageImsi: generalRes.error.detail,
          hasErrorImsi: true,
        });
      }
    }
  };

  onDataIsInvalidLength = (message, input) => {
    if (input === 'phone') {
      this.setState({
        autoPassiveSearchDataPhone: null,
        isDropdownVisiblePhone: true,
        isDropdownLoadingPhone: false,
        errorMessagePhone: message,
        hasErrorPhone: true,
      });
    } else {
      this.setState({
        autoPassiveSearchDataImsi: null,
        isDropdownVisibleImsi: true,
        isDropdownLoadingImsi: false,
        errorMessageImsi: message,
        hasErrorImsi: true,
      });
    }
  };

  onDataIsInvalidFormat = (message, input) => {
    if (input === 'phone') {
      this.setState({
        autoPassiveSearchDataPhone: null,
        isDropdownVisiblePhone: true,
        isDropdownLoadingPhone: false,
        errorMessagePhone: message,
        hasErrorPhone: true,
      });
    } else {
      this.setState({
        autoPassiveSearchDataImsi: null,
        isDropdownVisibleImsi: true,
        isDropdownLoadingImsi: false,
        errorMessageImsi: message,
        hasErrorImsi: true,
      });
    }
  };

  resetAutoPassiveSearchComponent = () => {
    this.setState({
      autoPassiveSearchDataPhone: null,
      molsPhone: -1,
      isDropdownVisiblePhone: false,
      isDropdownLoadingPhone: false,
      errorMessagePhone: '',
      hasErrorPhone: false,

      autoPassiveSearchDataImsi: null,
      molsImsi: -1,
      isDropdownVisibleImsi: false,
      isDropdownLoadingImsi: false,
      errorMessageImsi: '',
      hasErrorImsi: false,
    });
  };

  componentDidUpdate(prevProps) {
    const { searchData: searchDataPrev } = prevProps;
    const { searchData: searchDataCurrent } = this.props;

    if (searchDataCurrent?.phone !== searchDataPrev?.phone) {
      this.setState((state) => ({ searchData: { ...state.searchData, phone: searchDataCurrent?.phone || '' } }));
    }

    if (searchDataCurrent?.imsi !== searchDataPrev?.imsi) {
      this.setState((state) => ({ searchData: { ...state.searchData, imsi: searchDataCurrent?.imsi || '' } }));
    }
  }

  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside);
  }

  handleClickOutside = (e) => {
    const dropdownIsVisible = this.state.isDropdownVisiblePhone || this.state.isDropdownVisibleImsi;
    const elementPhone = e.target.id === 'locationSearchPhoneInput';
    const elementImsi = e.target.id === 'locationSearchImsiInput';

    if (dropdownIsVisible) {
      if (elementPhone || elementImsi) {
        e.target.addEventListener('click', function () {
          return;
        });
      } else if (this.node.current && !this.node.current.contains(e.target)) {
        this.setState({
          isDropdownVisiblePhone: false,
          isDropdownVisibleImsi: false,
        });
      }
    }
  };

  // Search handle logic
  handleSearch = () => {
    const { searchType, queryType, searchData } = this.state;
    const {
      getLocationSearch,
      getImsiLookupSearch,
      getPhoneLookupSearch,
      getForwardingSearch,
      getDosSearch,
      setJobSearchLoading,
      getE164Search,
      getE212Search,
      searchDataOnChange,
    } = this.props;

    this.resetAutoPassiveSearchComponent();

    switch (searchType) {
      case '1': {
        if (searchData && (searchData.phone || searchData.imsi)) {
          setJobSearchLoading(true);
          getLocationSearch({ ...searchData, query_type: queryType })
            .then(() => {
              searchDataOnChange(null);
              setJobSearchLoading(false);
            })
            .catch(() => setJobSearchLoading(false));
        } else message.error('Phone or IMSI is required!');
        break;
      }
      case '4': {
        if (searchData && searchData.phone) {
          setJobSearchLoading(true);
          getImsiLookupSearch(searchData.phone)
            .then(() => {
              searchDataOnChange(null);
              setJobSearchLoading(false);
            })
            .catch(() => setJobSearchLoading(false));
        } else message.error('Phone is required!');
        break;
      }
      case '5': {
        if (searchData && searchData.imsi) {
          setJobSearchLoading(true);
          getPhoneLookupSearch(searchData.imsi)
            .then(() => {
              searchDataOnChange(null);
              setJobSearchLoading(false);
            })
            .catch(() => setJobSearchLoading(false));
        } else message.error('IMSI is required!');
        break;
      }
      case '6': {
        if (searchData && searchData.phone) {
          setJobSearchLoading(true);
          getForwardingSearch(searchData.phone)
            .then(() => {
              searchDataOnChange(null);
              setJobSearchLoading(false);
            })
            .catch(() => setJobSearchLoading(false));
        } else message.error('Phone is required!');
        break;
      }
      case '7': {
        if (searchData && searchData.imsi) {
          setJobSearchLoading(true);
          getDosSearch(searchData.imsi)
            .then(() => {
              searchDataOnChange(null);
              setJobSearchLoading(false);
            })
            .catch(() => setJobSearchLoading(false));
        } else message.error('IMSI is required!');
        break;
      }
      case '8': {
        if (searchData && searchData.phone) {
          setJobSearchLoading(true);
          getE164Search({ phone: searchData.phone, query_type: queryType })
            .then(() => {
              searchDataOnChange(null);
              setJobSearchLoading(false);
            })
            .catch(() => setJobSearchLoading(false));
        } else message.error('Phone is required!');
        break;
      }
      case '10': {
        if (searchData && searchData.imsi) {
          getE212Search({ imsi: searchData.imsi, query_type: 'passive' })
            .then(() => {
              searchDataOnChange(null);
              setJobSearchLoading(false);
            })
            .catch(() => setJobSearchLoading(false));
        } else message.error('IMSI is required!');
        break;
      }
      default:
        break;
    }
  };

  renderErrorIcon = () => {
    return (
      <div
        style={{
          color: '#F44336',
          position: 'absolute',
          top: -5,
          right: -5,
          zIndex: 1,
          background: 'white',
          width: '18px',
          height: '18px',
          borderRadius: '50%',
        }}
      >
        <ExclamationCircleFilled style={{ color: '#F44336', position: 'absolute', top: 1, right: 1, zIndex: 2 }} />
      </div>
    );
  };

  renderValidIcon = () => {
    return (
      <div
        style={{
          color: '#4CAF50',
          position: 'absolute',
          top: -5,
          right: -5,
          zIndex: 1,
          background: 'white',
          width: '18px',
          height: '18px',
          borderRadius: '50%',
        }}
      >
        <CheckCircleFilled style={{ color: '#4CAF50', position: 'absolute', top: 1, right: 1, zIndex: 2 }} />
      </div>
    );
  };

  renderAutoPassiveSearchComponentPhone = () => {
    return (
      <AutoPassiveSearchResult
        mols={this.state.molsPhone}
        data={this.state.autoPassiveSearchDataPhone}
        loading={this.state.isDropdownLoadingPhone}
        message={this.state.errorMessagePhone}
      />
    );
  };

  renderAutoPassiveSearchComponentImsi = () => {
    return (
      <AutoPassiveSearchResult
        mols={this.state.molsImsi}
        data={this.state.autoPassiveSearchDataImsi}
        loading={this.state.isDropdownLoadingImsi}
        message={this.state.errorMessageImsi}
      />
    );
  };

  renderSearchInput = () => {
    const { searchType, searchData } = this.state;
    switch (searchType) {
      case '1':
        return (
          <React.Fragment>
            <div style={{ position: 'relative', marginRight: 20 }}>
              <Input
                id="locationSearchPhoneInput"
                placeholder="E164 Phone Number"
                suffix={<Icon name="phone" />}
                name={'phone'}
                onChange={this.inputOnChangeWithValidation}
                onFocus={this.inputOnFocus}
                onMouseEnter={this.inputOnFocus}
                value={(searchData && searchData.phone) || ''}
              />

              {this.state.errorMessagePhone.length > 0 &&
                this.state.hasErrorPhone &&
                !this.state.isDropdownLoadingPhone && <div>{this.renderErrorIcon()}</div>}

              {this.state.autoPassiveSearchDataPhone !== null &&
                !this.state.hasErrorPhone &&
                !this.state.isDropdownLoadingPhone && <div>{this.renderValidIcon()}</div>}

              {this.state.isDropdownVisiblePhone && (
                <div ref={this.node}>{this.renderAutoPassiveSearchComponentPhone()}</div>
              )}
            </div>

            <div style={{ position: 'relative' }}>
              <Input
                id="locationSearchImsiInput"
                placeholder="IMSI"
                suffix={<Icon name="hashtag" />}
                name={'imsi'}
                onChange={this.inputOnChangeWithValidation}
                onFocus={this.inputOnFocus}
                onMouseEnter={this.inputOnFocus}
                value={(searchData && searchData.imsi) || ''}
              />

              {this.state.errorMessageImsi.length > 0 &&
                this.state.hasErrorImsi &&
                !this.state.isDropdownLoadingImsi && <div>{this.renderErrorIcon()}</div>}

              {this.state.autoPassiveSearchDataImsi !== null &&
                !this.state.hasErrorImsi &&
                !this.state.isDropdownLoadingImsi && <div>{this.renderValidIcon()}</div>}

              {this.state.isDropdownVisibleImsi && (
                <div ref={this.node}>{this.renderAutoPassiveSearchComponentImsi()}</div>
              )}
            </div>
          </React.Fragment>
        );
      case '4':
        return (
          <Input
            placeholder="E164 Phone Number"
            suffix={<Icon name="phone" />}
            name={'phone'}
            onChange={this.inputOnChange}
            value={(searchData && searchData.phone) || ''}
          />
        );
      case '5':
        return (
          <Input
            placeholder="IMSI"
            suffix={<Icon name="hashtag" />}
            name={'imsi'}
            onChange={this.inputOnChange}
            value={(searchData && searchData.imsi) || ''}
          />
        );
      case '6':
        return (
          <Input
            placeholder="E164 Phone Number"
            suffix={<Icon name="phone" />}
            name={'phone'}
            onChange={this.inputOnChange}
            value={(searchData && searchData.phone) || ''}
          />
        );
      case '7':
        return (
          <Input
            placeholder="IMSI"
            suffix={<Icon name="hashtag" />}
            name={'imsi'}
            onChange={this.inputOnChange}
            value={(searchData && searchData.imsi) || ''}
          />
        );
      case '8':
        return (
          <Input
            placeholder="E164 Phone Number"
            suffix={<Icon name="phone" />}
            name={'phone'}
            onChange={this.inputOnChange}
            value={(searchData && searchData.phone) || ''}
          />
        );
      case '10':
        return (
          <Input
            placeholder="IMSI"
            suffix={<Icon name="hashtag" />}
            name={'imsi'}
            onChange={this.inputOnChange}
            value={(searchData && searchData.imsi) || ''}
          />
        );
      case '11':
        return (
          <React.Fragment>
            <Input
              placeholder="MCC"
              suffix={<Icon name="phone" />}
              name={'mcc'}
              onChange={this.inputOnChange}
              value={searchData?.mcc || ''}
              style={{ width: 220 }}
            />
            <Input
              placeholder="MNC"
              suffix={<Icon name="phone" />}
              name={'mnc'}
              onChange={this.inputOnChange}
              value={searchData?.mnc || ''}
              style={{ marginRight: 10, width: 220 }}
            />
            <Input
              placeholder="IMSI"
              suffix={<Icon name="hashtag" />}
              name={'imsi'}
              onChange={this.inputOnChange}
              value={searchData?.imsi || ''}
            />
          </React.Fragment>
        );
      default:
        return null;
    }
  };

  render() {
    const { searchType, queryType } = this.state;
    const { jobSearchLoading } = this.props;

    return (
      <div className="search-component">
        <div className="search-component__left-content">
          <TreeSelect
            value={searchType}
            treeDefaultExpandAll
            onChange={this.searchTypeOnChange}
            dropdownClassName="search-component-tree-dropdown"
            style={{ width: 180, minWidth: 180 }}
          >
            {Object.keys(searchTypes).map((key) => {
              const searchTypeName = searchTypes[key];
              // if (searchTypeName === 'Location Search') {
              //   return (
              //     <TreeSelect.TreeNode key={key} value={key} title={searchTypeName}>
              //       <TreeSelect.TreeNode
              //         key="10"
              //         value="10"
              //         title={
              //           <div style={{ marginLeft: 10 }}>
              //             <SwapRightOutlined />
              //             <span>Roaming Search</span>
              //           </div>
              //         }
              //       />
              //     </TreeSelect.TreeNode>
              //   );
              // }
              return <TreeSelect.TreeNode key={key} value={key} title={searchTypeName} />;
            })}
          </TreeSelect>
          <div className="search-component__inputs">{this.renderSearchInput()}</div>
        </div>
        <div className="search-component__right-content">
          {(searchType === '1' || searchType === '8') && (
            <Button.Group style={{ marginRight: 20 }}>
              <Button name="active" positive={queryType === 'active'} onClick={this.setQueryType}>
                Active
              </Button>
              <Button.Or text="or" />
              <Button name="passive" positive={queryType === 'passive'} onClick={this.setQueryType}>
                Passive
              </Button>
            </Button.Group>
          )}
          <PermissionHandler button="search-create">
            <Button loading={jobSearchLoading} disabled={jobSearchLoading} size="small" onClick={this.handleSearch}>
              Search
            </Button>
          </PermissionHandler>
        </div>
      </div>
    );
  }
}

SearchComponent.propTypes = {
  getLocationSearch: PropTypes.func.isRequired,
  getImsiLookupSearch: PropTypes.func.isRequired,
  getPhoneLookupSearch: PropTypes.func.isRequired,
  getForwardingSearch: PropTypes.func.isRequired,
  getDosSearch: PropTypes.func.isRequired,
  getE164Search: PropTypes.func.isRequired,
  getE164SearchValidation: PropTypes.func.isRequired,
  getE212Search: PropTypes.func.isRequired,
  getE212SearchValidation: PropTypes.func.isRequired,
  setJobSearchLoading: PropTypes.func.isRequired,
  searchDataOnChange: PropTypes.func.isRequired,
};

const mapStateToProps = (store) => ({
  jobSearchLoading: store.jobs.jobSearchLoading,
  jobSearchDisabled: store.jobs.jobSearchDisabled,
  searchData: store.jobs.searchData,
});

export default connect(mapStateToProps, {
  getLocationSearch,
  getImsiLookupSearch,
  getPhoneLookupSearch,
  getForwardingSearch,
  getDosSearch,
  setJobSearchLoading,
  getE164Search,
  getE164SearchValidation,
  getE212Search,
  getE212SearchValidation,
  searchDataOnChange,
})(SearchComponent);
