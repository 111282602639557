import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import { Button } from 'semantic-ui-react';
import {
  ContactsOutlined,
  UsergroupAddOutlined,
  CloseOutlined,
  UserOutlined,
  UserAddOutlined,
  PhoneOutlined,
  NumberOutlined,
} from '@ant-design/icons';
import { Form, Popover, Col, Row, Input, Select, Radio, Button as AntdButton, Spin } from 'antd';
import Flag from 'react-world-flags';

import {
  getPlots,
  createContact,
  getDashboardData,
  saveLocationFroTerminal,
  addTerminal,
} from '../../../../../actions/contacts/contact';
import { sendMessage } from '../../../../../actions/agents/smsNotifications';
import { getSearchJobs } from '../../../../../actions/jobs/jobs';
import { getLocalNotifications } from '../../../../../actions/smsNotifications/smsNotifications';

import setRange from '../../../../../utils/setRange';
import lte from '../../../../../assets/images/lte.png';
import TimeStamp from '../../../../../utils/timeStamp';
import Country from '../../../../../utils/country';
import NewMap from '../../../../newMap/newMap';
import PermissionsHandler from '../../../../../utils/permissionsHandler';
import { Link } from 'react-router-dom';

const initialContactValues = {
  name: '',
  alias: '',
  description: '',
  sex: '',
};

class JobResultLocationSearch extends PureComponent {
  state = {
    plotsView: false,
    creatingContactLoading: false,
    selectedAgents: [],
    sendLoading: false,
    smsActivePopup: null,
    selectedContactId: null,
    saveToContactLoading: false,
    createContactExpanded: false,
    templates: null,
    newContact: initialContactValues,
  };

  formRef = React.createRef();

  toggleCreateContact = () => this.setState((state) => ({ createContactExpanded: !state.createContactExpanded }));

  getPlots = (e) => {
    const { plotsLocations } = this.props;
    const { plotsView } = this.state;

    if (!plotsView) {
      const { markers } = e;
      if (!plotsLocations[markers[0].id]) {
        return this.props
          .getPlots(`${markers[0].mcc}`, `${markers[0].mnc}`, `${markers[0].lac}`, `${markers[0].id}`)
          .then((res) => {
            this.setState({ plotsView: !this.state.plotsView });
            return res;
          });
      }
      this.setState({ plotsView: !this.state.plotsView });
      return new Promise((resolve) => resolve(null));
    }

    this.setState({ plotsView: !this.state.plotsView });
    return new Promise((resolve) => resolve(null));
  };

  submitNewContact = () => {
    const { data, createContact, getDashboardData, saveLocationFroTerminal } = this.props;

    const newContact = {
      name: this.state.newContact.name,
      alias: this.state.newContact.alias,
      phone: data.phone,
      imsi: data.imsi,
      description: this.state.newContact.description,
      sex: this.state.newContact.sex,
    };

    this.setState({ creatingContactLoading: true });

    createContact(newContact)
      .then((res) => {
        getDashboardData();
        this.formRef.current.resetFields();
        this.setState({
          newContact: initialContactValues,
          selectedContactId: null,
        });
        this.toggleCreateContact();
        saveLocationFroTerminal(res.primary_terminal.id, data.id)
          .then(() => {
            this.setState({
              creatingContactLoading: false,
            });
            this.props.getSearchJobs();
          })
          .catch(() => this.setState({ creatingContactLoading: false }));
      })
      .catch(() => {
        this.setState({ creatingContactLoading: false });
      });
  };

  formOnChange = (e) => {
    const {
      target: { name, value },
    } = e;
    this.setState({ newContact: { ...this.state.newContact, [name]: value } });
  };

  sendMessage = (location) => {
    const { mcc, lac, cell_id, name, imsi, phone, lat, long, mnc, range, age, location_time } = location;

    if (this.state.selectedAgents.length > 0) {
      this.setState({ sendLoading: true }, () => {
        this.props
          .sendMessage({
            agent_ids: this.state.selectedAgents,
            location: {
              mcc,
              lac,
              cell_id,
              name,
              imsi,
              phone,
              lat,
              long,
              mnc,
              range,
              age,
              robot_id: null,
              location_time,
            },
          })
          .then(() => this.setState({ smsActivePopup: null, sendLoading: false }));
      });
    }
  };

  setActivePopupNotification = () => {
    const { smsActivePopup } = this.state;
    const {
      data: { id },
    } = this.props;

    if (id === smsActivePopup) this.setState({ smsActivePopup: null });
    else this.setState({ smsActivePopup: id });
  };

  selectContact = (value) => this.setState({ selectedContactId: value });

  saveToContact = () => {
    const { selectedContactId } = this.state;
    const {
      data: { phone, imsi },
      addTerminal,
    } = this.props;

    if (selectedContactId) {
      this.setState({ saveToContactLoading: true }, () => {
        if (phone !== '' || imsi !== '') {
          addTerminal(selectedContactId, { phone, imsi })
            .then(() => {
              this.formRef.current.resetFields();
              this.setState({
                newContact: initialContactValues,
                selectedContactId: null,
              });
              this.setState({ saveToContactLoading: false, createContactExpanded: false, selectedContactId: null });
              this.props.getSearchJobs();
            })
            .catch(() => this.setState({ saveToContactLoading: false }));
        }
      });
    }
  };

  getTemplates = () => {
    if (this.state.templates === null) {
      this.setState((state) => ({ ...state, templates: { data: [], loading: true } }));
      this.props.getLocalNotifications().then((res) => {
        if (res && Object.values(res).length > 0) {
          const notifications = [];
          Object.keys(res).forEach((key) => notifications.push(res[key]));
          this.setState((state) => ({ ...state, templates: { data: notifications, loading: false } }));
        }
      });
    }
  };

  render() {
    const {
      selectedAgents,
      sendLoading,
      smsActivePopup,
      saveToContactLoading,
      creatingContactLoading,
      createContactExpanded,
      newContact,
      selectedContactId,
    } = this.state;
    const {
      data,
      data: {
        id,
        phone,
        imsi,
        mcc,
        mnc,
        lac,
        cell_id,
        age,
        country_iso,
        serving_tech,
        lat,
        long,
        state,
        network,
        imei,
        name,
        range,
        created_at,
        angle_start,
        angle_end,
        location_time,
        query_completed_time,
        additional_info,
        angle,
        width,
      },
      user: { sms_service },
      agents,
      contacts,
      darkMode,
    } = this.props;

    const contactsSelect = contacts
      ? Object.values(contacts).map((contact) => {
          const key = `key-${contact.id}`;
          return (
            <Select.Option key={key} value={contact.id}>
              {contact.name}
            </Select.Option>
          );
        })
      : [];

    const createContactPopover = (
      <div className="dashboard-tables__create-form">
        <Form
          initialValues={{
            name: '',
            alias: '',
            description: '',
            selectContact: null,
          }}
          ref={this.formRef}
          onFinish={this.submitNewContact}
          onChange={this.formOnChange}
        >
          <Row gutter={12}>
            <Col span={12}>
              <Form.Item name="name">
                <Input
                  name="name"
                  prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                  placeholder="Name (Required)"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="alias">
                <Input
                  name="alias"
                  prefix={<UserAddOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                  placeholder="Alias"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={12}>
            <Col span={12}>
              <Input
                name="phone"
                prefix={<PhoneOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                placeholder="Phone"
                value={data.phone}
              />
            </Col>
            <Col span={12}>
              <Input
                name="imsi"
                prefix={<NumberOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                placeholder="IMSI"
                value={data.imsi}
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item name="description">
                <Input.TextArea
                  name="description"
                  rows={2}
                  prefix={<PhoneOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                  placeholder="Description"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <span className="dashboard-tables__create-form_label">Gender:</span>
            <Radio.Group name="sex" value={newContact.sex}>
              <Radio value="1">Male</Radio>
              <Radio value="0">Female</Radio>
            </Radio.Group>
          </Row>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <AntdButton type="primary" htmlType="submit" loading={creatingContactLoading}>
              Create
            </AntdButton>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Form.Item name="selectContact" style={{ margin: 0 }}>
                <Select
                  name="selectContact"
                  showSearch
                  style={{ width: 200 }}
                  placeholder="Select a contact"
                  optionFilterProp="children"
                  onChange={this.selectContact}
                  className="dashboard-page__search-result_save-contact__wrapper_select"
                  defaultValue={this.state.selectedContactId}
                >
                  {contactsSelect}
                </Select>
              </Form.Item>

              <AntdButton
                type="button"
                loading={saveToContactLoading}
                size="mini"
                onClick={this.saveToContact}
                disabled={!selectedContactId}
                style={{ marginLeft: 10 }}
              >
                Save to Contact
              </AntdButton>
            </div>
          </div>
        </Form>
      </div>
    );

    return (
      <div className="job-content__location-result">
        <div className="dashboard-page__search-result_info">
          <div className="dashboard-page__search-result_info__tables">
            <div className="info-table-left">
              <div className="table-item">
                <div className="table-item__name">MCC</div>
                <div className="table-item__value">{mcc}</div>
              </div>
              <div className="table-item">
                <div className="table-item__name">MNC</div>
                <div className="table-item__value">{mnc}</div>
              </div>
              <div className="table-item">
                <div className="table-item__name">LAC/TAC</div>
                <div className="table-item__value">{lac}</div>
              </div>
              <div className="table-item">
                <div className="table-item__name">CELLID</div>
                <div className="table-item__value">
                  {cell_id}
                  {serving_tech === 'EUTRAN' && (
                    <img
                      src={lte}
                      alt="lte"
                      style={{
                        width: 14,
                        height: 14,
                        boxShadow: 'none',
                        marginLeft: 5,
                      }}
                    />
                  )}
                </div>
              </div>
              <div className="table-item">
                <div className="table-item__name">Age</div>
                <div className="table-item__value">
                  {age}
                  {age !== null && <span style={{ opacity: 0.5 }}> (min) </span>}
                  {(age === '0' || age === '1') && <span className="age-blink-dot" />}
                </div>
              </div>
              <div className="table-item">
                <div className="table-item__name">Range</div>
                <div className="table-item__value">
                  {range} {range !== null && <span style={{ opacity: 0.5 }}>(m)</span>}
                </div>
              </div>
              <div className="table-item">
                <div className="table-item__name">Azimuth</div>
                <div className="table-item__value">
                  {width !== 360 && (
                    <div>
                      <span>{angle}&#176;</span> <span>({width}&#176;)</span>
                    </div>
                  )}
                </div>
              </div>
              <div className="table-item">
                <div className="table-item__name">Submitted</div>
                <div className="table-item__value">
                  <TimeStamp seconds date={created_at} />
                </div>
              </div>
            </div>
            <div className="info-table-right">
              <div className="table-item">
                <div className="table-item__name">Country</div>
                <div className="table-item__value">
                  {country_iso && (
                    <span className="dashboard-page__search-result_info__text">
                      <Flag code={country_iso} height="12" style={{ filter: darkMode ? 'invert(1)' : 'initial' }} />
                      <span> {Country.find((e) => e.iso === country_iso).country}</span>
                    </span>
                  )}
                </div>
              </div>
              <div className="table-item">
                <div className="table-item__name">Lat</div>
                <div className="table-item__value">{lat}</div>
              </div>
              <div className="table-item">
                <div className="table-item__name">Lng</div>
                <div className="table-item__value">{long}</div>
              </div>
              <div className="table-item">
                <div className="table-item__name">State</div>
                <div className="table-item__value">{state}</div>
              </div>
              <div className="table-item">
                <div className="table-item__name">Network</div>
                <div className="table-item__value">{network}</div>
              </div>
              <div className="table-item">
                <div className="table-item__name">IMEI</div>
                <div className="table-item__value">{imei}</div>
              </div>
              <div className="table-item">
                <div className="table-item__name">Completed</div>
                <div className="table-item__value">
                  <TimeStamp seconds date={query_completed_time} />
                </div>
              </div>
            </div>
          </div>

          {(lat || cell_id || mnc || lac || mcc) && (
            <div className="dashboard-page__search-result_save-contact">
              <Popover
                onVisibleChange={this.toggleCreateContact}
                placement="rightBottom"
                title={
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <span>Create contact with results bellow</span>
                    <AntdButton
                      size="small"
                      shape="circle"
                      icon={<CloseOutlined />}
                      onClick={this.toggleCreateContact}
                    />
                  </div>
                }
                trigger="click"
                visible={createContactExpanded}
                content={createContactPopover}
              >
                <PermissionsHandler button="contact-create" style={{ display: 'inline-flex' }}>
                  <AntdButton size="small" icon={<ContactsOutlined />} onClick={this.toggleCreateContact}>
                    {createContactExpanded ? 'Close Creation' : 'Create Contact'}
                  </AntdButton>
                </PermissionsHandler>
              </Popover>
              <Popover
                placement="topRight"
                content={
                  sms_service === 1 ? (
                    <div>
                      <div style={{ height: '30px' }}>
                        {this.state.templates?.loading && (
                          <div>
                            <Spin />
                            <span style={{ marginLeft: 10 }}>Checking for existing Templates</span>
                          </div>
                        )}
                        {this.state.templates?.data?.length > 0 && this.state.templates.data[0].content ? (
                          <p>You can check your existing template {<Link to="/profile">here</Link>}</p>
                        ) : (
                          <div>
                            <p>
                              You don't have any template. Please <Link to="/profile">create</Link> one
                            </p>
                          </div>
                        )}
                      </div>
                      <Select
                        optionFilterProp="label"
                        showSearch
                        mode="multiple"
                        style={{ width: 400 }}
                        placeholder="Select Agents(s)"
                        onChange={(e) => this.setState({ selectedAgents: e })}
                        value={selectedAgents}
                        options={
                          agents
                            ? Object.values(agents)
                                .reverse()
                                .map((agent) => ({ label: agent.name, value: agent.id }))
                            : []
                        }
                      />
                      <div style={{ display: 'flex', marginTop: 30 }}>
                        <Button
                          negative
                          compact
                          size="mini"
                          onClick={() => this.setState({ smsActivePopup: null, selectedAgents: [] })}
                        >
                          Cancel
                        </Button>
                        <Button
                          loading={sendLoading}
                          positive
                          compact
                          size="mini"
                          style={{ marginLeft: 10 }}
                          onClick={() => this.sendMessage(data)}
                        >
                          Send
                        </Button>
                      </div>
                    </div>
                  ) : (
                    <div>SMS service not configured</div>
                  )
                }
                title={sms_service === 1 ? 'Select Agent(s)' : 'SMS Disabled'}
                trigger="click"
                onClick={this.getTemplates}
                visible={smsActivePopup === id}
              >
                <AntdButton
                  style={{ marginLeft: 5 }}
                  size="small"
                  icon={<UsergroupAddOutlined />}
                  onClick={this.setActivePopupNotification}
                >
                  Select Agents
                </AntdButton>
              </Popover>
            </div>
          )}
        </div>
        <div className="dashboard-page__search-result_map">
          {navigator.onLine && lat && (
            <NewMap
              id={id}
              mapInfo={{
                isOnDashboard: true,
                markers: [
                  {
                    id: cell_id,
                    cellId: cell_id,
                    title: name,
                    lat: parseFloat(lat),
                    lng: parseFloat(long),
                    hour: location_time,
                    range: parseInt(range, 10),
                    angle_start,
                    angle_end,
                    mcc,
                    mnc,
                    lac,
                  },
                ],
                type: 'single-marker',
                zoom: setRange(range),
              }}
            />
          )}
          {!lat && <div className="dashboard-page__search-result_map__no">No coordinates</div>}
        </div>
      </div>
    );
  }
}

JobResultLocationSearch.propTypes = {
  data: PropTypes.shape({}).isRequired,
  getPlots: PropTypes.func.isRequired,
  createContact: PropTypes.func.isRequired,
  getDashboardData: PropTypes.func.isRequired,
  saveLocationFroTerminal: PropTypes.func.isRequired,
  sendMessage: PropTypes.func.isRequired,
  addTerminal: PropTypes.func.isRequired,
  getSearchJobs: PropTypes.func.isRequired,
  getLocalNotifications: PropTypes.func.isRequired,
  darkMode: PropTypes.bool.isRequired,
};

const mapStateToProps = (store) => ({
  agents: store.agents,
  user: store.user,
  contacts: store.contact.allContacts.contacts,
  plotsLocations: store.contact.plotsLocations ? store.contact.plotsLocations : {},
  darkMode: store.app.darkMode,
});

export default connect(mapStateToProps, {
  getPlots,
  createContact,
  getDashboardData,
  saveLocationFroTerminal,
  sendMessage,
  addTerminal,
  getSearchJobs,
  getLocalNotifications,
})(JobResultLocationSearch);
