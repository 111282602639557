import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { Tabs } from 'antd';

import NavigationMenu from '../../components/navigationMenu';
import PresenceTable from './presenceTable';
import SensorTable from './sensorTable';
import Correlation from './correlationContainer';
import GroupContent from './groupContent';

import './presencePage.sass';

class PresencePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: 'cell-tab',
      panes: [
        { title: 'Cell', content: <PresenceTable />, key: 'cell-tab', closable: false },
        { title: 'Sensors', content: <SensorTable />, key: 'sensors-tab', closable: false },
        {
          title: 'Correlation',
          content: <Correlation openGroupCallback={this.openGroup} deleteGroupCallback={this.deleteGroup} />,
          key: 'correlation-tab',
          closable: false,
        },
      ],
      openedGroups: [],
    };
  }

  tabChange = (key) => {
    this.setState({ tab: key });
  };

  tabAction = (key, action) => {
    if (action === 'remove') {
      const index = this.state.panes.findIndex((pane) => pane.key === key);

      if (index >= 0) {
        const newPanes = [...this.state.panes];
        newPanes.splice(index, 1);
        const newOpenedGroups = this.state.openedGroups.filter((group) => group.key !== key);

        let newTabKey = this.state.tab;
        if (newTabKey === key) {
          if (index === newPanes.length) {
            newTabKey = newPanes[newPanes.length - 1].key;
          } else {
            newTabKey = newPanes[index].key;
          }
        }

        this.setState({ tab: newTabKey, panes: newPanes, openedGroups: newOpenedGroups });
      }
    }
  };

  // Open Group callback from Correlation tab
  openGroup = (id, name) => {
    const index = this.state.openedGroups.findIndex((group) => group.id === id);

    if (index >= 0) {
      const key = this.state.openedGroups[index].key;
      this.setState({ tab: key });
    } else {
      const key = `group-tab-${id}`;
      const newPanes = [...this.state.panes, { title: name, content: <GroupContent id={id} />, key: key }];
      const newOpenedGroups = [...this.state.openedGroups, { id: id, key: key }];
      this.setState({ panes: newPanes, openedGroups: newOpenedGroups, tab: key });
    }
  };

  // Delete Group callback from Correlation tab
  deleteGroup = (id) => {
    const index = this.state.openedGroups.findIndex((group) => group.id === id);

    if (index >= 0) {
      const key = this.state.openedGroups[index].key;
      const newOpenedGroups = [...this.state.openedGroups];
      newOpenedGroups.splice(index, 1);

      const paneIndex = this.state.panes.findIndex((pane) => pane.key === key);

      if (paneIndex >= 0) {
        const newPanes = [...this.state.panes];
        newPanes.splice(paneIndex, 1);

        let newTabKey = this.state.tab;
        if (newTabKey === key) {
          if (paneIndex === newPanes.length) {
            newTabKey = newPanes[newPanes.length - 1].key;
          } else {
            newTabKey = newPanes[paneIndex].key;
          }
        }

        this.setState({ tab: newTabKey, panes: newPanes, openedGroups: newOpenedGroups });
      }
    }
  };

  render() {
    const { tab, panes } = this.state;
    return (
      <div>
        <NavigationMenu statusPage="cell-data" />
        <div className={`presence-page ${this.props.user.sticky_header ? '--fixed' : ''}`}>
          <Tabs
            type="editable-card"
            activeKey={tab}
            onChange={this.tabChange}
            animated={false}
            hideAdd
            onEdit={this.tabAction}
          >
            {panes.map((pane) => (
              <Tabs.TabPane tab={pane.title} key={pane.key} closable={pane.closable}>
                {pane.content}
              </Tabs.TabPane>
            ))}
          </Tabs>
        </div>
      </div>
    );
  }
}

PresencePage.defaultProps = {
  user: {},
};

PresencePage.propTypes = {
  user: PropTypes.shape({
    sticky_header: PropTypes.number,
  }),
};

const mapStateToProps = (store) => ({
  user: store.user,
});

export default connect(mapStateToProps)(PresencePage);
