import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import './notice.sass';

class Notice extends PureComponent {
  render() {
    const { notice } = this.props;
    return (
      <div className="notice-component">
        <div className="notice-component__inner">
          <div className="notice-component__inner_header">Notice</div>
          <p className="notice-component__inner_message">{notice}</p>
        </div>
      </div>
    );
  }
}

Notice.propTypes = {
  notice: PropTypes.string.isRequired,
};

export default Notice;
