import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Button, Header } from 'semantic-ui-react';
import TimezoneSelect from 'react-timezone-select';
import { notification } from 'antd';

import { changeTimeZone } from '../../../actions/user/auth';

import './changeTimeZone.sass';

class ChangeTimeZone extends Component {
  state = {
    newLocation: null,
    loading: false,
    isSelected: false,
    defaultLocation: '',
  };

  setDefaultLocation() {
    this.setState({ defaultLocation: this.props.user.timezone });
  }

  componentDidMount() {
    this.setDefaultLocation();
  }

  changeTimeZoneOnSubmit = (e) => {
    e.preventDefault();
    this.setState({ loading: true }, () => {
      this.props
        .changeTimeZone(this.props.user.id, this.state.newLocation)
        .then(() => {
          this.setState({ loading: false });
          notification.success({
            message: 'Success',
            description: 'The time zone was set',
          });
        })
        .catch((err) => {
          this.setState({ loading: false });
          notification.error({
            message: 'Error',
            description: err.response.data.message,
          });
        });
    });
  };

  render() {
    const state = this.state;
    return (
      <div className="change-time-zone">
        <form action="" onSubmit={(e) => this.changeTimeZoneOnSubmit(e)}>
          <Header as="h3">Change TimeZone</Header>
          <div className="change-time-zone__input-group">
            <TimezoneSelect
              className="timezone-picker"
              onChange={(timezone) => {
                this.setState({ newLocation: timezone.value });
              }}
              value={this.state.newLocation ? this.state.newLocation : this.state.defaultLocation}
              styles={{
                option: (provided, state) => ({
                  ...provided,
                  fontWeight: state.isSelected ? 'bold' : 'normal',
                  color: 'black',
                  fontSize: '14px',
                  backgroundColor: '#fff',
                  borderColor: '#d9d9d9',
                  ':hover': {
                    backgroundColor: 'rgba(224, 224, 224, 1)',
                  },
                }),
                indicatorSeparator: () => ({
                  display: 'none',
                }),
                control: (provided) => ({
                  ...provided,
                  border: '1px solid #d9d9d9',
                  boxShadow: 'none',
                  fontSize: '14px',
                  minHeight: '35px',
                  height: '35px',
                  '&:hover': {
                    border: '1px solid #d9d9d9',
                    cursor: 'pointer',
                  },
                }),
              }}
            />
            <Button
              size="mini"
              loading={this.state.loading}
              disabled={!this.state.newLocation}
              className="ui button timezone-button"
              type="submit"
            >
              Submit
            </Button>
          </div>
        </form>
      </div>
    );
  }
}

ChangeTimeZone.propTypes = {
  user: PropTypes.shape({
    timezone: PropTypes.string,
    id: PropTypes.number,
  }).isRequired,
  changeTimeZone: PropTypes.func.isRequired,
};

const mapStateToProps = (store) => ({
  user: store.user,
});
export default connect(mapStateToProps, { changeTimeZone })(ChangeTimeZone);
