import React, { useState, memo, useMemo, useCallback, useRef } from 'react';
import styled, { css } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import { Button, InputNumber, Modal } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

import { ColorPicker, toColor, useColor } from 'react-color-palette';
import 'react-color-palette/lib/css/styles.css';

import debounce from 'lodash.debounce';

import { addHeatMapSetting, editHeatMapSetting, deleteHeatMapSetting } from '../../../../actions/app/application';

import parseHeatMapSettings from '../../../../utils/parseHeatMapSettings';

const HeatMapSettings = () => {
  const [modalStatus, setModalStatus] = useState({
    type: '',
    visible: false,
    data: null,
  });
  const [newThresholdValue, setNewThresholdValue] = useState(0);

  const [color, setColor] = useColor('hex', '#121212');

  const heatMapSettings = useSelector((store) => store.user?.map?.settings);

  const dispatch = useDispatch();

  const deleteItem = useCallback((id) => dispatch(deleteHeatMapSetting(id)), [dispatch]);

  const editThresholdColor = useCallback(
    (item) => {
      if (!modalStatus.visible) {
        const newColor = toColor('hex', item.color);
        setColor(newColor);
        setModalStatus((state) => ({ ...state, type: 'edit', visible: !state.visible, data: item }));
      }
    },
    [modalStatus.visible, setColor],
  );

  const existingThresholdValueOnChange = useCallback(
    (item, e) => dispatch(editHeatMapSetting(item.id, { ...item, value: e })),
    [dispatch],
  );

  const debouncedValueRef = useRef(debounce((item, e) => existingThresholdValueOnChange(item, e), 500));

  const getItems = useCallback(() => {
    const parsedSettings = parseHeatMapSettings(heatMapSettings);
    return parsedSettings?.map(({ id, color, value }) => {
      const item = { id, color, value };
      if (value === 'greaterThen') {
        return (
          <Item key={`item-${id}`} disabled>
            <ItemColor color={color} onClick={() => editThresholdColor(item)} />
            <ItemSeparator />
            <ItemValueWrapper>
              <span>Value: </span>
              <span style={{ color: 'black' }}>Greater than {parsedSettings[1]?.value}</span>
            </ItemValueWrapper>
          </Item>
        );
      } else if (value === 'lowerThen') {
        return (
          <Item key={`item-${id}`} disabled>
            <ItemColor color={color} onClick={() => editThresholdColor(item)} />
            <ItemSeparator />
            <ItemValueWrapper>
              <span>Value: </span>
              <span style={{ color: 'black' }}>Lower than {parsedSettings[parsedSettings.length - 2]?.value - 10}</span>
            </ItemValueWrapper>
          </Item>
        );
      }

      return (
        <Item key={`item-${id}`}>
          <ItemColor color={color} onClick={() => editThresholdColor(item)} />
          <ItemSeparator />
          <ItemValueWrapper>
            <span>Value: </span>
            <ItemValue
              defaultValue={value}
              bordered={false}
              onChange={(e) => debouncedValueRef.current(item, e)}
              formatter={(value) => `${value} dBm`}
              parser={(value) => value.replace(' dBm', '')}
            />
          </ItemValueWrapper>

          {parsedSettings.length > 3 && <StyledDelete onClick={() => deleteItem(id)} />}
        </Item>
      );
    });
  }, [heatMapSettings, deleteItem, editThresholdColor]);

  const closeModal = () => setModalStatus({ type: '', visible: false, data: null });

  const newThresholdValueOnChange = (e) => setNewThresholdValue(e);

  const addNewThreshold = () => {
    setModalStatus((state) => ({ ...state, type: 'add', visible: !state.visible }));
  };

  const submitHeatMapSettings = () => {
    if (modalStatus.type === 'add') {
      dispatch(addHeatMapSetting({ value: `${newThresholdValue}`, color: color.hex })).then(() => closeModal());
    } else {
      dispatch(editHeatMapSetting(modalStatus.data.id, { ...modalStatus.data, color: color.hex })).then(() =>
        closeModal(),
      );
    }
  };

  return (
    <>
      <ListWrapper>{getItems()}</ListWrapper>
      <Button onClick={addNewThreshold}>Add new threshold</Button>
      <Modal title="Basic Modal" visible={modalStatus.visible} onOk={submitHeatMapSettings} onCancel={closeModal}>
        {modalStatus.type === 'add' ? (
          <ItemValue
            defaultValue={newThresholdValue}
            bordered
            onChange={newThresholdValueOnChange}
            formatter={(value) => `${value} dBm`}
            parser={(value) => value.replace(' dBm', '')}
            style={{ marginBottom: 10 }}
          />
        ) : (
          ''
        )}
        <ColorPicker width={456} height={228} color={color} onChange={setColor} />
      </Modal>
    </>
  );
};

const ListWrapper = styled.div`
  width: 230px;
  margin: 0 auto 10px auto;
`;

const Item = styled.div`
  position: relative;
  height: 38px;
  border: 1px solid #c8c8c8;
  border-radius: 3px;

  display: flex;
  align-items: center;

  margin-bottom: 5px;

  z-index: 1;

  &:last-child {
    margin-bottom: 0;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      background: #eeeeee;
    `}
`;

const ItemValueWrapper = styled.div`
  padding: 0 10px;

  span {
    font-size: 14px;
    color: grey;
  }

  .ant-input-number-focused {
    box-shadow: none;
  }
`;

const ItemValue = styled(InputNumber)`
  width: 92px;
  input {
    font-weight: 600;
  }
`;

const ItemSeparator = styled.span`
  width: 1px;
  height: 100%;
  background: #c8c8c8;
`;

const ItemColor = styled.div`
  width: 20px;
  height: 20px;
  background: ${({ color }) => color};
  border-radius: 3px;
  overflow: hidden;
  cursor: pointer;
  z-index: 2;

  margin: 0 10px;
`;

const StyledDelete = styled(DeleteOutlined)`
  position: absolute;
  right: 10px;

  cursor: pointer;

  path {
    fill: grey;
  }

  &:hover {
    path {
      fill: #fc4a1e;
    }
  }
`;

export default memo(HeatMapSettings);
