import { GET_ALL_SENSORS, GET_CELL_REPORT, GET_SENSORS, LOGOUT } from '../../constants/actionTypes';
import initialState from '../alert/initState';

export default function cellReport(state = {}, action = {}) {
  switch (action.type) {
    case GET_CELL_REPORT:
      return {
        ...state,
        reportCells: { ...action.payload },
      };
    case GET_SENSORS:
      return {
        ...state,
        sensorCells: { ...action.payload },
      };
    case GET_ALL_SENSORS:
      return {
        ...state,
        allSensors: { ...action.payload },
      };
    case LOGOUT:
      return {};
    default:
      return state;
  }
}
