import React, { memo } from 'react';
import styled from 'styled-components';

import { Field, Form, Formik } from 'formik';
import { Button, Input } from 'antd';
import * as Yup from 'yup';
import capitalizeFirstCharacter from '../../../../utils/capitalizeFirstCharacter';

const GlobalSettingsSchema = Yup.object().shape({
  public_osm_url: Yup.string().nullable().required(),
  hosted_osm_url: Yup.string().nullable(),
  google_map_url: Yup.string().nullable(),
  google_map_token: Yup.string().nullable(),
  mapbox_style_satellite: Yup.string().nullable(),
  mapbox_token: Yup.string().nullable(),
  mapbox_account_name: Yup.string().nullable(),
});

const GlobalMapSettings = ({ globalMapSettings, setGlobalMapSettings }) => {
  const submitGlobalMapSettings = (values) => {
    setGlobalMapSettings(values);
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{ ...globalMapSettings }}
      validationSchema={GlobalSettingsSchema}
      onSubmit={submitGlobalMapSettings}
    >
      {() => (
        <Form>
          {Object.keys(globalMapSettings).map((key) => {
            const fieldName = key.trim().replaceAll('_', ' ');
            if (key === 'loading') return null;
            return (
              <InputWrapper key={key}>
                <label htmlFor={key}>{capitalizeFirstCharacter(fieldName)}</label>
                <Field id={key} name={key}>
                  {({ field, form: { touched, errors } }) => {
                    return (
                      <>
                        <Input {...field} type="text" />
                        {errors[key] && touched[key] && <InputError>{errors[key].replaceAll('_', ' ')}</InputError>}
                      </>
                    );
                  }}
                </Field>
              </InputWrapper>
            );
          })}
          <Button loading={globalMapSettings.loading} htmlType="submit">
            Submit
          </Button>
        </Form>
      )}
    </Formik>
  );
};

const InputWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;

  label {
    text-align: left;
    margin-bottom: 5px;
    font-size: 14px;
  }
`;

const InputError = styled.span`
  color: #be2e2e;
  font-size: 13px;
`;

export default memo(GlobalMapSettings);
