import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { CloseCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';

import './addDropDown.sass';

class AddDropDown extends Component {
  state = {
    height: 0,
    opacity: 0,
    isOpen: false,
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.createSuccess !== this.props.createSuccess && this.props.createSuccess) {
      this.toggleCreate();
    }
    if (this.props.autoClose) {
      if (nextProps.createSuccess !== this.props.createSuccess && !this.props.createSuccess) {
        this.closeFrom();
      }
    }
    if (nextProps.expand !== this.props.expand) {
      this.setState({
        opacity: nextProps.expand === true ? 1 : 0,
        height: nextProps.expand === true ? this.props.height : 0,
      });
    }
    if (nextProps.height !== this.props.height && nextProps.expand === true) {
      this.setState({ height: nextProps.height });
    }
  }

  toggleCreate = () => {
    this.props.onClick();
    const { height } = this.state;
    if (height === 0) this.setState({ height: this.props.height, opacity: 1 });
    else if (height !== 0) this.setState({ height: 0, opacity: 0 });

    if (this.props.handleDropDownProperties) {
      this.props.handleDropDownProperties(true, this.props.type);
      this.setState({
        isOpen: true,
      });
    }
  };

  closeFrom = () => {
    this.toggleCreate();
    this.props.onClose();

    if (this.props.handleDropDownProperties) {
      this.props.handleDropDownProperties(false, this.props.type);
      this.setState({
        isOpen: false,
      });
    }
  };

  render() {
    return (
      <div className={`add-drop-down-component ${this.props.className}`}>
        <div className="add-drop-down-component__buttons-area">
          <div
            className="add-drop-down-component__buttons-area_button"
            onClick={() => this.toggleCreate(this.props.height)}
          >
            <PlusCircleOutlined className="button-icon" />
            <span className="button-text">{this.props.buttonName}</span>
          </div>
          <div className="add-drop-down-component__buttons-area_button --close-button" onClick={this.closeFrom}>
            <CloseCircleOutlined
              className="button-icon"
              style={{
                display: this.state.opacity === 0 ? 'none' : 'block',
                pointerEvents: this.state.opacity === 0 ? 'none' : 'initial',
              }}
            />
            <span
              style={{
                display: this.state.opacity === 0 ? 'none' : 'block',
                pointerEvents: this.state.opacity === 0 ? 'none' : 'initial',
              }}
              className="button-text"
            >
              Close
            </span>
          </div>
        </div>
        <div
          className={`add-drop-down-component__expand-area ${
            this.state.height === 0 && this.props.hideContent && '--hide'
          }`}
          style={{ height: this.state.height, opacity: this.state.opacity }}
        >
          {this.state.height !== 0 && this.props.children}
        </div>
      </div>
    );
  }
}

AddDropDown.defaultProps = {
  height: 0,
  createSuccess: false,
  children: {},
  className: '',
  onClick: () => null,
  expand: false,
  onClose: () => null,
  hideContent: true,
  autoClose: false,
};

AddDropDown.propTypes = {
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  createSuccess: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  buttonName: PropTypes.string.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func,
  expand: PropTypes.bool,
  onClose: PropTypes.func,
  hideContent: PropTypes.bool,
  handleDropDownProperties: PropTypes.func,
  type: PropTypes.string,
  autoClose: PropTypes.bool,
};

export default AddDropDown;
