import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Popover } from 'antd';

const PermissionsHandler = ({ button, permissions, children, style }) => {
  let havePermissions = false;
  permissions.map((permission) => {
    if (permission.name === button) havePermissions = true;
    return null;
  });

  return (
    <div style={style ? { marginRight: 0, ...style } : { marginRight: 0 }} className="button-handler-component">
      {havePermissions ? (
        children
      ) : (
        <div className="button-handler-wrapper" style={{ position: 'relative' }}>
          <Popover trigger="hover" content="You don't have permissions to use this action">
            <div style={{ position: 'absolute', width: '100%', height: '100%' }} />
          </Popover>
          <div className="handler-children-component" style={{ pointerEvents: 'none', opacity: 0.4 }}>
            {children}
          </div>
        </div>
      )}
    </div>
  );
};

PermissionsHandler.defaultProps = {
  permissions: [],
};

PermissionsHandler.propTypes = {
  children: PropTypes.shape({}).isRequired,
  permissions: PropTypes.arrayOf(PropTypes.shape({})),
  button: PropTypes.string.isRequired,
};

const mapStateToProps = (store) => ({
  permissions: store.user.permissions,
});

export default connect(mapStateToProps)(PermissionsHandler);
