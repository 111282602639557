import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Card, Button, Tag, Select, Spin, Popconfirm, Input as Input2, notification } from 'antd';
import { Form, Input, Button as Button2, Radio } from 'semantic-ui-react';

import DropDown from '../../../../components/addDropDown';
import TimeStamp from '../../../../utils/timeStamp';

import './teamManagement.sass';

class TeamManagement extends Component {
  state = {
    tab: 'teams',
    tabList: [
      {
        key: 'teams',
        tab: 'Teams',
      },
    ],
    loading: {},
    nameEditState: {},
    stateNameEdit: {},
    saveEditLoading: {},
    teamName: '',
    createSuccess: false,
    selected: '',
    spinning: false,
    deleteSelected: {
      mode: 'delete',
      user_id: '',
      name: '',
    },
  };

  onTabChange = (key, type) => {
    this.setState({ ...this.state, [type]: key });
    if (key === 'teams') {
      this.setState({
        tabList: [
          {
            key: 'teams',
            tab: 'Teams',
          },
        ],
      });
    }
  };

  navigateToTeam = (teamId, name) => {
    this.setState({ loading: { [name]: true } });
    this.props.getSingleTeam(teamId).then(() => {
      this.setState(
        {
          ...this.state,
          tabList: [
            {
              key: 'teams',
              tab: 'Teams',
            },
            {
              key: 'team',
              tab: name,
            },
          ],
        },
        () => {
          this.setState({ ...this.state, tab: 'team', loading: {} }, () => {
            document.getElementsByClassName('ant-tabs-tab')[1].click();
          });
        },
      );
    });
  };

  editTeam = (teamId, name) => {
    this.setState({
      nameEditState: { ...this.state.nameEditState, [teamId]: true },
      stateNameEdit: { ...this.state.stateNameEdit, [teamId]: name },
    });
  };

  saveTeamName = (teamId, name) => {
    this.setState({ saveEditLoading: { ...this.state.saveEditLoading, [teamId]: true } });
    this.props
      .editTeam(teamId, this.state.stateNameEdit[teamId])
      .then(() =>
        this.setState({
          saveEditLoading: { ...this.state.saveEditLoading, [teamId]: false },
          nameEditState: { ...this.state.nameEditState, [teamId]: false },
        }),
      )
      .catch(() => {
        this.setState({
          saveEditLoading: { ...this.state.saveEditLoading, [teamId]: false },
          nameEditState: { ...this.state.nameEditState, [teamId]: false },
          stateNameEdit: { ...this.state.stateNameEdit, [teamId]: name },
        });
      });
  };

  createTeam = () => {
    if (this.state.teamName) {
      this.props.createTeam(this.state.teamName).then(() => this.setState({ createSuccess: true, teamName: '' }));
    }
  };

  handleChangeSelect = (e, i) => {
    this.setState({ selected: e, spinning: true });
    this.props
      .addUserToTeam({ user_id: i.key, team_id: this.props.teams.singleTeam.id })
      .then(() => this.setState({ selected: '', spinning: false }))
      .catch(() => this.setState({ selected: '', spinning: false }));
  };

  handleChangeRadio = (e, { value }) =>
    this.setState({
      deleteSelected: {
        user_id: '',
        mode: value,
      },
    });

  handleTransferToUser = (value, data) => {
    this.setState({
      deleteSelected: {
        ...this.state.deleteSelected,
        user_id: data.key,
        name: value,
      },
    });
  };

  confirmDeletion = (teamId) => {
    if (this.state.deleteSelected.mode !== '') {
      const {
        deleteSelected: { mode, user_id: userId },
      } = this.state;
      this.props.deleteTeam(teamId, { mode, user_id: userId });
    } else {
      notification.error({
        message: 'Delete fail',
        description: 'You must select DELETE or Transfer option',
      });
    }
  };

  render() {
    const usersOptions =
      this.props.users.allUsers &&
      Object.values(this.props.users.allUsers).map((user) => (
        <Select.Option key={user.id} value={user.username}>
          {user.username}
        </Select.Option>
      ));

    const teams =
      this.props.teams.allTeams &&
      this.props.teams.allTeams.map((team) => {
        return (
          <Card
            className="team-management-component__cards_card"
            key={team.id}
            hoverable={!!1}
            title={
              this.state.nameEditState[team.id] !== true ? (
                <div>{team.name}</div>
              ) : (
                <Input2
                  className="team-management-component__cards_card__input"
                  type="text"
                  value={this.state.stateNameEdit[team.id]}
                  onChange={(e) =>
                    this.setState({
                      stateNameEdit: { ...this.state.stateNameEdit, [team.id]: e.target.value },
                    })
                  }
                />
              )
            }
            extra={
              <div style={{ display: 'flex' }}>
                {this.state.nameEditState[team.id] !== true ? (
                  <Button style={{ marginRight: 10 }} size="small" onClick={() => this.editTeam(team.id, team.name)}>
                    Edit
                  </Button>
                ) : (
                  <Button
                    style={{ marginRight: 10 }}
                    size="small"
                    loading={this.state.saveEditLoading[team.id]}
                    onClick={() => this.saveTeamName(team.id, team.name)}
                  >
                    Save
                  </Button>
                )}
                <Button
                  size="small"
                  loading={this.state.loading[team.name]}
                  onClick={() => this.navigateToTeam(team.id, team.name)}
                >
                  Info
                </Button>
              </div>
            }
            style={{ width: 340 }}
          >
            <div>
              <span className="card-span">Created at:</span>
              <TimeStamp date={team.created_at} />
            </div>
            <div>
              <span className="card-span">Updated at:</span>
              <TimeStamp date={team.updated_at} />
            </div>
            <Popconfirm
              name={`pop-up-${team.id}`}
              className="team-management-component__delete"
              title={
                <div>
                  <Form className="team-management-component__delete_form">
                    <Form.Field className="team-management-component__delete_form__text">
                      Delete mode will remove all team data then delete the team itself, transfer mode will transfer all
                      team data to a specific user then remove the team only.
                    </Form.Field>
                    <Form.Group className="team-management-component__delete_form__mode">
                      <Radio
                        className="team-management-component__delete_form__mode_radio"
                        label="Delete"
                        name="delete"
                        value="delete"
                        checked={this.state.deleteSelected.mode === 'delete'}
                        onChange={this.handleChangeRadio}
                      />
                      <Radio
                        className="team-management-component__delete_form__mode_radio"
                        label="Transfer"
                        name="transfer"
                        value="transfer"
                        checked={this.state.deleteSelected.mode === 'transfer'}
                        onChange={this.handleChangeRadio}
                      />
                    </Form.Group>
                    <Form.Field>
                      <Select
                        className="team-management-component__delete_form__select"
                        disabled={this.state.deleteSelected.mode === 'delete'}
                        showSearch
                        style={{ width: 200 }}
                        value={this.state.deleteSelected.name}
                        placeholder="Select user for transfer"
                        optionFilterProp="children"
                        onChange={(e, data) => this.handleTransferToUser(e, data)}
                        filterOption={(input, option) =>
                          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {usersOptions}
                      </Select>
                    </Form.Field>
                  </Form>
                </div>
              }
              onConfirm={() => this.confirmDeletion(team.id)}
            >
              <div className="team-management-component__delete_button" role="button" tabIndex={0}>
                Delete
              </div>
            </Popconfirm>
          </Card>
        );
      });

    const TeamInfo = () => {
      if (this.props.teams.singleTeam) {
        return (
          <div className="team-management-component__info">
            <div className="team-management-component__info_add-user">
              <Spin className="spinning" spinning={this.state.spinning} size="default" />
              <Select
                showSearch
                className="select-team"
                placeholder="Add user to team"
                optionFilterProp="children"
                size="default"
                value={this.state.selected}
                style={{ width: 200 }}
                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                label="Add user to team"
                combobox="false"
                onChange={(e, i) => this.handleChangeSelect(e, i)}
              >
                {usersOptions}
              </Select>
            </div>
            <div style={{ marginRight: '40px' }}>
              <div style={{ marginBottom: '10px' }} className="team-management-component__info_name">
                {this.props.teams.singleTeam.name}
              </div>
              <div className="team-management-component__info_date">
                <span style={{ fontWeight: 'bold' }}>Created at:</span>{' '}
                <TimeStamp date={this.props.teams.singleTeam.created_at} />
              </div>
              <div className="team-management-component__info_date">
                <span style={{ fontWeight: 'bold' }}>Updated at:</span>{' '}
                <TimeStamp date={this.props.teams.singleTeam.updated_at} />
              </div>
            </div>
            <div className="team-management-component__users">
              <div style={{ marginBottom: '10px' }} className="team-management-component__info_name">
                Users in team
              </div>
              <div className="team-management-component__users_wrapper">
                {Object.values(this.props.teams.singleTeam.users_many).map((user) => (
                  <Tag
                    key={user.id}
                    style={{ display: 'flex', alignItems: 'center' }}
                    closable
                    color={user.is_team_lead === 1 ? 'green' : 'grey'}
                    onClose={() => this.props.removeUserFromTeam(user.id, this.props.teams.singleTeam.id)}
                  >
                    <div
                      className="make-lead"
                      style={{ marginRight: '10px' }}
                      role="button"
                      tabIndex={0}
                      // onClick={() => this.props.makeTeamLead(user.id)}
                    >
                      {user.username}
                    </div>
                  </Tag>
                ))}
                {Object.values(this.props.teams.singleTeam.users_many).length === 0 &&
                  `No users in ${this.props.teams.singleTeam.name}`}
              </div>
            </div>
          </div>
        );
      }
      return '';
    };

    const content = {
      teams: <div className="team-management-component__cards">{teams}</div>,
      team: <TeamInfo />,
    };
    return (
      <div className="team-management-component">
        <DropDown
          className="team-management-component__drop-down"
          createSuccess={this.state.createSuccess}
          buttonName="Create Team"
          height={100}
        >
          <Form onChange={(e) => this.setState({ teamName: e.target.value })}>
            <Form.Group widths="equal">
              <Form.Field>
                <Input size="mini" value={this.state.teamName} name="team" fluid placeholder="Team Name" />
              </Form.Field>
            </Form.Group>
            <Button2 onClick={() => this.createTeam()} size="mini">
              Create
            </Button2>
          </Form>
        </DropDown>
        <Card
          className="team-management-component__wrapper"
          style={{ width: '100%' }}
          tabList={this.state.tabList}
          onTabChange={(key) => {
            this.onTabChange(key, 'tab');
          }}
        >
          {content[this.state.tab]}
        </Card>
      </div>
    );
  }
}

TeamManagement.defaultProps = {
  getSingleTeam: () => null,
  removeUserFromTeam: () => null,
  createTeam: () => null,
  // makeTeamLead: () => null,
  addUserToTeam: () => null,
  user: {},
};

TeamManagement.propTypes = {
  teams: PropTypes.shape({
    allTeams: PropTypes.array,
    singleTeam: PropTypes.object,
  }).isRequired,
  getSingleTeam: PropTypes.func,
  removeUserFromTeam: PropTypes.func,
  createTeam: PropTypes.func,
  // makeTeamLead: PropTypes.func,
  users: PropTypes.shape({
    allUsers: PropTypes.object,
  }).isRequired,
  addUserToTeam: PropTypes.func,
  deleteTeam: PropTypes.func.isRequired,
  editTeam: PropTypes.func.isRequired,
};

export default TeamManagement;
