import api from '../../utils/appApi';
import { GET_SURVEYS, UPLOAD_SURVEY, UPDATE_SURVEY, DELETE_SURVEY } from '../../constants/actionTypes';

export const getSurveys = (data) => (dispatch) =>
  new Promise((resolve, reject) =>
    api.survey
      .getSurveys(data)
      .then((res) => {
        dispatch({
          type: GET_SURVEYS,
          payload: res.data?.data,
        });
        return resolve(res.data);
      })
      .catch((err) => reject(err)),
  );

export const uploadSurvey = (file) => (dispatch) =>
  new Promise((resolve, reject) => {
    const formData = new FormData();
    formData.append('file', file);

    return api.survey
      .upload(formData)
      .then((res) => {
        dispatch({ type: UPLOAD_SURVEY, payload: res.data?.data });
        return resolve(res);
      })
      .catch((err) => {
        return reject(err.response.data);
      });
  });

export const updateSurvey = (data) => (dispatch) => dispatch({ type: UPDATE_SURVEY, payload: data });

export const deleteSurvey = (id) => (dispatch) =>
  new Promise((resolve, reject) =>
    api.survey
      .delete(id)
      .then((res) => {
        dispatch({ type: DELETE_SURVEY, payload: id });
        return resolve(res);
      })
      .catch((err) => reject(err)),
  );
