import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

const UserRoute = ({ user, component: Component, ...rest }) => (
  <Route {...rest} render={(props) => (user.isAuthorized ? <Redirect to="/" /> : <Component {...props} />)} />
);

UserRoute.defaultProps = {
  user: {},
};

UserRoute.propTypes = {
  component: PropTypes.func.isRequired,
  user: PropTypes.shape({
    isAuthorized: PropTypes.bool.isRequired,
  }),
};

const mapStateToProps = (store) => ({
  user: store.user,
});

export default connect(mapStateToProps)(UserRoute);
