import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment-timezone';

class timeStamp extends Component {
  state = {};

  render() {
    const { date, seconds, format } = this.props;

    if (!date) return <span style={{ color: 'grey' }}>No date</span>;

    if (this.props.user.timezone && date)
      return (
        <span>
          {moment(date)
            .tz(this.props.user.timezone)
            .format(format ? format : `YYYY-MM-DD HH:mm${seconds ? ':ss' : ''}`)}
        </span>
      );

    return <span>{moment(date).format(format ? format : `YYYY-MM-DD HH:mm${seconds ? ':ss' : ''}`)}</span>;
  }
}

timeStamp.defaultProps = {
  date: '',
  seconds: false,
  format: null,
};

timeStamp.propTypes = {
  date: PropTypes.string,
  user: PropTypes.shape({
    timezone: PropTypes.string,
  }).isRequired,
  seconds: PropTypes.bool,
};

const mapStateToProps = (store) => ({
  user: store.user,
});

export default connect(mapStateToProps)(timeStamp);
