import { useEffect } from 'react';
import L from 'leaflet';

import './ruler/ruler';
import './ruler/ruler.css';

const RulerComponent = ({ map }) => {
  useEffect(() => {
    if (!map) return;

    L.control.ruler().addTo(map);
  }, [map]);

  return null;
};

export default RulerComponent;
