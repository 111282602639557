import React, { memo, useState, useEffect, useCallback, useRef } from 'react';
import { useDispatch } from 'react-redux';
import debounce from 'lodash.debounce';
import PropTypes from 'prop-types';

import { Pagination, Table, Row, Col, Input } from 'antd';
import { searchUnsavedContacts } from '../../actions/unsavedContacts/unsavedContacts';
import AddContactForm from '../addContactForm';
import '../../containers/ContactsPage/contactsPage.sass';

const UnsavedContactsTable = ({ trigger, triggerAction }) => {
  const dispatch = useDispatch();
  const [activePageData, setActivePageData] = useState([]);
  const [activePageNum, setActivePageNum] = useState(1);
  const [perPage, setPerPage] = useState(5);
  const [allData, setAllData] = useState([]);
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [searchText, setSearchText] = useState('');

  const pageSizeOptions = ['5', '10', '20', '50', '100'];

  const columns = [
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone',
      columnId: '1',
      width: '30%',
      render: (_, contact) => {
        let phoneNone = 'No information';
        if (contact.phone !== null) {
          phoneNone = contact.phone;
        }

        return <span>{phoneNone}</span>;
      },
    },
    {
      title: 'IMSI',
      dataIndex: 'imsi',
      key: 'imsi',
      columnId: '2',
      width: '30%',
      render: (_, contact) => {
        let imsiNone = 'No information';
        if (contact.imsi !== null) {
          imsiNone = contact.imsi;
        }

        return <span>{imsiNone}</span>;
      },
    },
    {
      title: 'Action',
      key: 'action',
      columnId: '3',
      render: (_, contact) => {
        return <AddContactForm data={contact} triggerAction={triggerAction} />;
      },
    },
  ];

  async function getData(page, perPage, searchText) {
    try {
      setIsTableLoading(true);
      const res = await dispatch(searchUnsavedContacts(page, perPage, searchText));
      setActivePageData(res.data);
      setAllData(res);
    } catch (err) {
      console.error(err);
    } finally {
      setIsTableLoading(false);
    }
  }

  const searchAction = (inputValue, pageSize) => {
    setIsTableLoading(true);
    dispatch(searchUnsavedContacts(1, pageSize, inputValue))
      .then((res) => {
        setIsTableLoading(false);
        setActivePageData(res.data);
        setAllData(res);
        setActivePageNum(res.current_page);
        document.getElementById('unsaved-contacts-input').focus();
      })
      .catch(() => {
        setActivePageData([]);
      })
      .finally(() => {
        setIsTableLoading(false);
      });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceHandle = useCallback(debounce(searchAction, 1000), []);

  const handleSearchByContact = (e) => {
    setSearchText(e.target.value);
    debounceHandle(e.target.value, perPage);
  };

  const handlePaginationChange = (page, pageSize) => {
    setActivePageNum(page);
    setPerPage(pageSize);
    getData(page, pageSize, searchText);
  };

  const handlePageSizeChange = (page, pageSize) => {
    setPerPage(pageSize);
  };

  // get data on first page load
  useEffect(() => {
    getData(activePageNum, perPage, searchText);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // get data when new contact was created
  useEffect(() => {
    if (trigger) {
      getData(activePageNum, perPage, searchText).then(() => triggerAction);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger]);

  // get data on Change Page Size
  useEffect(() => {
    setActivePageNum(1);

    getData(1, perPage, searchText);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [perPage]);

  return (
    <div className="grey-wrapper" style={{ marginTop: 20 }}>
      <Row>
        <Col span={12}>
          <h4>UNSAVED CONTACTS</h4>
        </Col>
      </Row>

      <Row style={{ margin: '10px 0' }}>
        <Col span={12}>
          <Input
            placeholder="Search in unsaved contacts"
            style={{ width: 200 }}
            size="mini"
            value={searchText}
            onChange={handleSearchByContact}
            disabled={isTableLoading}
            id="unsaved-contacts-input"
          />
        </Col>

        <Col span={12}>
          <Pagination
            showSizeChanger
            className="table-pagination"
            defaultCurrent={1}
            pageSizeOptions={pageSizeOptions}
            pageSize={perPage}
            onChange={handlePaginationChange}
            onShowSizeChange={handlePageSizeChange}
            current={activePageNum}
            total={allData.total}
            size="small"
            disabled={isTableLoading}
          />
        </Col>
      </Row>

      {activePageData ? (
        <Table
          key="dataIndex"
          loading={isTableLoading}
          columns={columns}
          dataSource={activePageData}
          pagination={false}
          size="small"
          scroll={{ x: 'auto' }}
        />
      ) : null}
    </div>
  );
};

UnsavedContactsTable.defaultProps = {
  trigger: false,
  triggerAction: () => null,
};

UnsavedContactsTable.propTypes = {
  trigger: PropTypes.bool,
  triggerAction: PropTypes.func,
};

export default memo(UnsavedContactsTable);
