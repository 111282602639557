import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import GlobalMapSettings from './globalMapSettings';
import HeatMapSettings from './heatMapSettings';

const MapSettings = ({ globalMapSettings, setGlobalMapSettings }) => {
  return (
    <SettingsWrapper>
      <ColumnSetting>
        <ColumnHeader>Global Map Settings</ColumnHeader>
        <ColumnContent>
          <GlobalMapSettings globalMapSettings={globalMapSettings} setGlobalMapSettings={setGlobalMapSettings} />
        </ColumnContent>
      </ColumnSetting>
      <ColumnSetting>
        <ColumnHeader>HeatMap Settings</ColumnHeader>
        <ColumnContent>
          <HeatMapSettings />
        </ColumnContent>
      </ColumnSetting>
    </SettingsWrapper>
  );
};

const SettingsWrapper = styled.div`
  display: flex;
`;
const ColumnSetting = styled.div`
  width: 50%;
  padding: 0 20px;
`;
const ColumnHeader = styled.h4``;
const ColumnContent = styled.div``;

MapSettings.propTypes = {
  globalMapSettings: PropTypes.shape({
    public_osm_url: PropTypes.string,
    hosted_osm_url: PropTypes.string,
    google_map_url: PropTypes.string,
    mapbox_style_basic: PropTypes.string,
    mapbox_style_satellite: PropTypes.string,
    mapbox_token: PropTypes.string,
    mapbox_account_name: PropTypes.string,
    loading: PropTypes.bool,
  }),
  setGlobalMapSettings: PropTypes.func,
};

export default memo(MapSettings);
