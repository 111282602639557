import React, { memo, useState, useMemo, useEffect, useCallback } from 'react';
import styled from 'styled-components';

const SearchSignal = ({ mols }) => {
  const [options, setOptions] = useState(['', '', '', '']);

  useMemo(() => {
    const payload = [...options];
    switch (mols) {
      case -1: {
        payload[0] = '#B0BEC5';
        payload[1] = '#B0BEC5';
        payload[2] = '#B0BEC5';
        payload[3] = '#B0BEC5';
        setOptions(payload);
        break;
      }
      case 0: {
        payload[0] = '#F44336';
        payload[1] = '#B0BEC5';
        payload[2] = '#B0BEC5';
        payload[3] = '#B0BEC5';
        setOptions(payload);
        break;
      }
      case 1: {
        payload[0] = '#FBC02D';
        payload[1] = '#FBC02D';
        payload[2] = '#B0BEC5';
        payload[3] = '#B0BEC5';
        setOptions(payload);
        break;
      }
      case 2: {
        payload[0] = '#4CAF50';
        payload[1] = '#4CAF50';
        payload[2] = '#4CAF50';
        payload[3] = '#4CAF50';
        setOptions(payload);
        break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mols]);

  return (
    <MolsWrapper className="dark-inverse">
      <StyledBar h={5} c={options[0]} />
      <StyledBar h={8} c={options[1]} />
      <StyledBar h={11} c={options[2]} />
      <StyledBar h={14} c={options[3]} />
    </MolsWrapper>
  );
};

export const MolsWrapper = styled.div`
  display: flex;
  align-items: self-end;
`;

export const StyledBar = styled.div`
  width: 4px;
  height: ${({ h }) => `${h}px`};
  background: ${({ c }) => c};
  margin-right: 1px;
`;

export default memo(SearchSignal);
