import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { Button, Input } from 'semantic-ui-react';
import { ContainerOutlined, DeleteOutlined, EditOutlined, StarFilled, StarOutlined } from '@ant-design/icons';
import { Popconfirm, Tooltip } from 'antd';

import PermissionHandler from '../../utils/permissionsHandler';

import './terminal.sass';

class Terminal extends PureComponent {
  state = {
    locateSelected: false,
    allowed: true,
    terminalEdit: false,
    terminalInfo: {
      phone: '',
      imsi: '',
      isPrimary: 1,
    },
  };

  UNSAFE_componentWillMount() {
    this.setState({
      locateSelected: this.props.isPrimary && true,
      terminalInfo: {
        ...this.state.terminalInfo,
        phone: this.props.phone,
        imsi: this.props.imsi,
        isPrimary: this.props.isPrimary,
      },
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.selectedLocations.indexOf(nextProps.id) === -1)
      this.setState({ locateSelected: false, allowed: true });
    else if (nextProps.allowedSelected.indexOf(nextProps.id) === -1)
      this.setState({ locateSelected: true, allowed: false });
    else this.setState({ locateSelected: true, allowed: true });

    this.setState({
      terminalInfo: {
        ...this.state.terminalInfo,
        phone: nextProps.phone,
        imsi: nextProps.imsi,
        isPrimary: nextProps.isPrimary,
      },
    });
  }

  setPrimary = (terminalId) => {
    if (this.props.isPrimary === 0) this.props.setPrimary(terminalId);
  };

  selectToLocate = (id) => {
    this.setState({ locateSelected: !this.state.locateSelected });
    if (!this.state.locateSelected === true) this.props.locateSelected(id, 'add');
    else if (!this.state.locateSelected === false) this.props.locateSelected(id, 'remove');
  };

  editTerminal = () => {
    this.setState({ terminalEdit: !this.state.terminalEdit });
  };

  cancelTerminal = () => {
    this.setState({
      ...this.state,
      terminalEdit: !this.state.terminalEdit,
      terminalInfo: {
        ...this.state.terminalInfo,
        phone: this.props.phone === null ? '' : this.props.phone,
        imsi: this.props.imsi === null ? '' : this.props.imsi,
      },
    });
  };

  saveTerminal = (terminalId) => {
    const { phone, imsi } = this.state.terminalInfo;
    this.props
      .saveTerminal(terminalId, { phone, imsi })
      .then(() => this.setState({ terminalEdit: !this.state.terminalEdit }))
      .catch(() => this.cancelTerminal());
  };

  archiveTerminal = (terminalId) => {
    this.props.archiveTerminal(terminalId);
  };

  deleteTerminal = (terminalId) => {
    this.props.deleteTerminal(terminalId);
  };

  inputOnChange = (e) => {
    this.setState({
      terminalInfo: {
        ...this.state.terminalInfo,
        [e.target.name]: e.target.value,
      },
    });
  };

  render() {
    const { id, isEditable, isRemovable, isArchived, color } = this.props;
    const { phone, imsi, isPrimary } = this.state.terminalInfo;

    return (
      <div className={`terminal-component ${!!isArchived && '--archived'}`}>
        <div className="terminal-component__ident-color" style={{ backgroundColor: color }} />
        {this.state.terminalEdit === false && (
          <div className="terminal-component__contacts">
            <div className="terminal-component__contacts_info">
              Phone:{' '}
              {!phone ? (
                <span className="terminal-component__contacts_info --no-meta"> {'No phone'}</span>
              ) : (
                <span
                  className="terminal-component__contacts_info_data terminal-component__contacts_info_data-phone"
                  style={{ color: 'black' }}
                >
                  {phone}
                </span>
              )}
            </div>
            <div className="terminal-component__contacts_info">
              IMSI:{' '}
              {!imsi ? (
                <span className="terminal-component__contacts_info --no-meta"> {'No IMSI'}</span>
              ) : (
                <span
                  className="terminal-component__contacts_info_data terminal-component__contacts_info_data-imsi"
                  style={{ color: 'black' }}
                >
                  {imsi}
                </span>
              )}
            </div>
          </div>
        )}
        {this.state.terminalEdit && (
          <div className="terminal-component__contacts">
            <Input
              name="phone"
              transparent
              placeholder="Phone"
              value={phone === null ? '' : phone}
              onChange={(e) => this.inputOnChange(e)}
            />
            <Input
              name="imsi"
              transparent
              placeholder="IMSI"
              value={imsi === null ? '' : imsi}
              onChange={(e) => this.inputOnChange(e)}
            />
          </div>
        )}
        {this.state.terminalEdit === false && (
          <PermissionHandler button="terminal-edit">
            <div className="terminal-component__buttons">
              <div
                className={`terminal-component__buttons_button ${!!isArchived === true ? '--disabled' : ''}`}
                onClick={() => this.setPrimary(id)}
                role="button"
                tabIndex={0}
              >
                <Tooltip placement="top" title={!!isPrimary ? 'Remove primary' : 'Set as Primary'} mouseEnterDelay={1}>
                  {!!isPrimary ? <StarFilled style={{ color: '#ffe044' }} /> : <StarOutlined />}
                </Tooltip>
              </div>
              <div
                role="button"
                tabIndex={0}
                onClick={this.editTerminal}
                className={`terminal-component__buttons_button ${!!isEditable === false ? '--disabled' : ''}`}
              >
                <Tooltip placement="top" title="Edit terminal" mouseEnterDelay={1}>
                  <EditOutlined />
                </Tooltip>
                {/*<img src={eidtButton} alt="edit" />*/}
              </div>
              <Popconfirm
                title="Are you sure you want to archive/unarchive?"
                onConfirm={() => this.archiveTerminal(id)}
                okText="Yes"
                cancelText="Cancel"
              >
                <div className="terminal-component__buttons_button">
                  <Tooltip placement="top" title="Archive terminal" mouseEnterDelay={1}>
                    <ContainerOutlined />
                  </Tooltip>
                </div>
              </Popconfirm>
              <Popconfirm
                title="Are you sure you want to delete this Terminal?"
                onConfirm={() => this.deleteTerminal(id)}
                okText="Yes"
                cancelText="Cancel"
              >
                <div className={`terminal-component__buttons_button ${!!isRemovable === false ? '--disabled' : ''}`}>
                  <Tooltip placement="top" title="Delete terminal" mouseEnterDelay={1}>
                    <DeleteOutlined />
                  </Tooltip>
                </div>
              </Popconfirm>
            </div>
          </PermissionHandler>
        )}
        {this.state.terminalEdit && (
          <div className="terminal-component__buttons-edit">
            <Button size="mini" role="button" tabIndex={0} onClick={() => this.saveTerminal(id)}>
              Save
            </Button>
            <Button size="mini" role="button" tabIndex={0} onClick={this.cancelTerminal}>
              Cancel
            </Button>
          </div>
        )}
        {this.state.terminalEdit === false && (
          <div
            onClick={this.props.allAllowed.includes(id) ? () => this.selectToLocate(id) : null}
            role="button"
            tabIndex={0}
            className={`terminal-component__check${this.state.locateSelected ? ' --checked' : ''}${
              !this.state.allowed ? ' --not-allowed' : ''
            }`}
          >
            {!this.state.allowed ? 'x' : this.props.allAllowed.includes(id) ? '✓' : 'x'}
          </div>
        )}
      </div>
    );
  }
}

Terminal.defaultProps = {
  phone: '',
  imsi: '',
  isPrimary: 0,
  isArchived: 0,
  isEditable: 0,
  isRemovable: 0,
  id: 0,
  selectedLocations: [],
  color: '',
  locateSelected: () => null,
  setPrimary: () => null,
  saveTerminal: () => null,
  archiveTerminal: () => null,
  deleteTerminal: () => null,
};

Terminal.propTypes = {
  phone: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool]),
  imsi: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool]),
  isPrimary: PropTypes.number,
  isArchived: PropTypes.number,
  isEditable: PropTypes.number,
  isRemovable: PropTypes.number,
  id: PropTypes.number,
  locateSelected: PropTypes.func,
  setPrimary: PropTypes.func,
  saveTerminal: PropTypes.func,
  archiveTerminal: PropTypes.func,
  deleteTerminal: PropTypes.func,
  selectedLocations: PropTypes.arrayOf(PropTypes.number),
  allowedSelected: PropTypes.arrayOf(PropTypes.number),
  color: PropTypes.string,
};

export default Terminal;
