import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { CheckCircleOutlined, CloseCircleOutlined, DeleteOutlined, InfoCircleOutlined } from '@ant-design/icons';

import { Table, Popover, Popconfirm } from 'antd';

import { deleteFileRow } from '../../actions/files/files';
import TimeStamp from '../../utils/timeStamp';
import formatBytes from '../../utils/formatBytes';

class FilesTable extends Component {
  state = {};

  render() {
    const columns = [
      {
        title: 'Id',
        dataIndex: 'id',
        key: 'id',
        render: (text) => <div>{text}</div>,
        width: '5%',
      },
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        render: (text) => <div>{text}</div>,
        width: '10%',
      },
      {
        title: 'Total rows',
        dataIndex: 'total_rows',
        key: 'total_rows',
        width: '8%',
        render: (text) => <div>{text === null ? 0 : text}</div>,
      },
      {
        title: 'New',
        dataIndex: 'new_rows',
        key: 'new_rows',
        width: '8%',
        render: (text) => <div>{text === null ? 0 : text}</div>,
      },
      {
        title: 'Updated',
        dataIndex: 'updated_rows',
        key: 'updated_rows',
        width: '8%',
        render: (text) => <div>{text === null ? 0 : text}</div>,
      },
      {
        title: 'Processed',
        dataIndex: 'processed_rows',
        key: 'processed_rows',
        width: '8%',
        render: (text) => <div>{text === null ? 0 : text}</div>,
      },
      {
        title: 'Current',
        dataIndex: 'current_row',
        key: 'current_row',
        width: '8%',
        render: (text) => <div>{text === null ? 0 : text}</div>,
      },
      {
        title: 'Progress',
        key: 'progress',
        render: (text, record) => {
          const number = (record.current_row / record.total_rows) * 100;
          return (
            <div key={record.id}>
              {!isNaN(number) && (
                <div className="table-percentage__cell">
                  {number.toFixed(0) < 34 ? (
                    <div
                      style={{
                        width: `${number.toFixed(1)}%`,
                        maxWidth: '100%',
                        background: '#00b800',
                        height: '100%',
                      }}
                    >
                      <div className="table-percentage__cell_out-number">{number.toFixed(1)}%</div>
                    </div>
                  ) : (
                    <div
                      style={{ width: `${number.toFixed(1)}%`, maxWidth: '100%' }}
                      className="table-percentage__cell_number"
                    >
                      {number.toFixed(1)}%
                    </div>
                  )}
                </div>
              )}
              {!number && record.status !== 'extraction-error' && record.status !== 'import-error' && (
                <div>Waiting in queue</div>
              )}
              {isNaN(number) && (
                <div className="table-percentage__cell">
                  <div style={{ width: `${0}%`, background: '#00b800', height: '100%' }}>
                    <div className="table-percentage__cell_out-number">{0}%</div>
                  </div>
                </div>
              )}
            </div>
          );
        },
        className: 'table-percentage',
        width: '8%',
      },
      {
        title: 'File size',
        dataIndex: 'file_size',
        key: 'file_size',
        render: (text, record) => <div>{formatBytes(record.file_size)}</div>,
        width: '7%',
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        width: '6%',
        render: (text, record) => (
          <Popover content={<div>{record.message}</div>} title={record.status}>
            <div style={{ textAlign: 'center' }}>
              {record.status === 'imported' && <CheckCircleOutlined style={{ color: 'green' }} />}
              {(record.status === 'uploaded' ||
                record.status === 'extracting' ||
                record.status === 'unzipped' ||
                record.status === 'importing') && <InfoCircleOutlined style={{ color: '#ecb60e' }} />}
              {(record.status === 'extraction-error' || record.status === 'import-error') && (
                <CloseCircleOutlined style={{ color: 'red' }} />
              )}
            </div>
          </Popover>
        ),
      },
      {
        title: 'Created at',
        dataIndex: 'created_at',
        key: 'created_at',
        width: '8%',
        render: (text) => <TimeStamp date={text} />,
      },
      {
        title: 'Updated at',
        dataIndex: 'updated_at',
        key: 'updated_at',
        width: '9%',
        render: (text) => <TimeStamp date={text} />,
      },
      {
        title: 'Del',
        dataIndex: 'delete',
        key: 'delete',
        width: '5%',
        render: (text, record) => (
          <Popconfirm
            title={
              <div>
                <div style={{ fontWeight: 600 }}>Delete this file record?</div>
                Deleting the record before the file is processed will leave the
                <br />
                physical file orphaned on the disc, please then delete
                <br />
                the file manually using the <span style={{ fontWeight: 600 }}>Files</span> section
              </div>
            }
            okText="Yes"
            cancelText="No"
            onConfirm={() => this.props.deleteFileRow(record.id)}
          >
            <DeleteOutlined style={{ cursor: 'pointer' }} />
          </Popconfirm>
        ),
      },
    ];
    return (
      <Table rowKey={'id'} columns={columns} dataSource={this.props.dataFiles} scroll={{ y: 240 }} pagination={false} />
    );
  }
}

FilesTable.propTypes = {
  dataFiles: PropTypes.oneOfType([PropTypes.array]).isRequired,
  deleteFileRow: PropTypes.func.isRequired,
};

export default connect(null, { deleteFileRow })(FilesTable);
