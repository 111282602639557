import {
  ADD_FILE,
  FIRE_ALERT,
  GET_FILES,
  GET_CELLS,
  UPDATE_CELL,
  DELETE_CELL,
  DELETE_FOLDER,
  DELETE_FILE,
  DELETE_CELLS,
  DELETE_FILE_ROW,
  UPDATE_SURVEY,
  UPDATE_FILE,
} from '../../constants/actionTypes';
import api from '../../utils/appApi';

export const addFile = (file, config) => (dispatch) =>
  new Promise((resolve, reject) => {
    const formData = new FormData();
    formData.append('file', file);
    api.files
      .addFile(formData, config)
      .then((res) => {
        dispatch({
          type: ADD_FILE,
          payload: res.data.data,
        });
        return resolve(res.data.data);
      })
      .catch((err) => {
        const fileError = !err.response.data.errors.file ? '' : err.response.data.errors.file[1];
        dispatch({
          type: FIRE_ALERT,
          payload: {
            specificMessage: `${fileError}`,
            message: err.response.data.message,
          },
        });
        return reject(err);
      });
  });

export const updateFile = (file) => (dispatch) => dispatch({ type: UPDATE_FILE, payload: file });

export const getFiles = () => (dispatch) =>
  new Promise((resolve, reject) => {
    api.files
      .getFiles()
      .then((res) => {
        dispatch({
          type: GET_FILES,
          payload: res.data,
        });
        return resolve(res.data.data);
      })
      .catch((err) => reject(err));
  });

export const getCells = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    return api.files
      .getCells(data)
      .then((res) => {
        dispatch({
          type: GET_CELLS,
          payload: res.data,
        });
        return resolve(res.data);
      })
      .catch((err) => {
        const lat = !err.response.data.errors.lat ? '' : err.response.data.errors.lat[0];
        const long = !err.response.data.errors.long ? '' : err.response.data.errors.long[0];
        const mcc = !err.response.data.errors.mcc ? '' : err.response.data.errors.mcc[0];
        const mnc = !err.response.data.errors.mnc ? '' : err.response.data.errors.mnc[0];
        const lac = !err.response.data.errors.lac ? '' : err.response.data.errors.lac[0];
        const range = !err.response.data.errors.range ? '' : err.response.data.errors.range[0];
        const angle = !err.response.data.errors.angle ? '' : err.response.data.errors.angle[0];
        const width = !err.response.data.errors.width ? '' : err.response.data.errors.width[0];
        const cellId = !err.response.data.errors.cell_id ? '' : err.response.data.errors.cell_id[0];
        dispatch({
          type: FIRE_ALERT,
          payload: {
            specificMessage: `${lat} ${long} ${mcc} ${mnc} ${lac} ${cellId} ${range} ${angle} ${width}`,
            message: err.response.data.message,
          },
        });
        reject(err);
      });
  });

export const updateCell = (cellId, data) => (dispatch) =>
  new Promise((resolve, reject) => {
    api.files
      .updateCell(cellId, data)
      .then((res) => {
        dispatch({
          type: UPDATE_CELL,
          payload: res.data.data,
        });
        return resolve(res.data.data);
      })
      .catch((err) => reject(err));
  });

export const deleteCell = (cellId) => (dispatch) =>
  new Promise((resolve, reject) => {
    api.files
      .deleteCell(cellId)
      .then((res) => {
        dispatch({
          type: DELETE_CELL,
          payload: res.data.data,
        });
        return resolve(res.data.data);
      })
      .catch((err) => reject(err));
  });

export const deleteFolder = (folder) => (dispatch) =>
  new Promise((resolve, reject) => {
    api.files
      .deleteFolder(folder)
      .then((res) => {
        dispatch({
          type: DELETE_FOLDER,
          payload: res.data.data,
        });
        return resolve(res.data.data);
      })
      .catch((err) => reject(err));
  });

export const deleteFile = (name) => (dispatch) =>
  new Promise((resolve, reject) => {
    api.files
      .deleteFile(name)
      .then((res) => {
        dispatch({
          type: DELETE_FILE,
          payload: name,
        });
        return resolve(res.data.data);
      })
      .catch((err) => reject(err));
  });

export const deleteCells = () => (dispatch) =>
  new Promise((resolve, reject) => {
    api.files
      .deleteCells()
      .then(() => {
        dispatch({
          type: DELETE_CELLS,
          payload: null,
        });
        return resolve();
      })
      .catch((err) => reject(err));
  });

export const deleteFileRow = (id) => (dispatch) =>
  new Promise((resolve, reject) => {
    api.files
      .deleteFileRow(id)
      .then(() => {
        dispatch({
          type: DELETE_FILE_ROW,
          payload: id,
        });
        return resolve();
      })
      .catch((err) => reject(err));
  });
