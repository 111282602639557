import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import JobErrorContent from './jobErrorContent';
import ResultParser from './jobResultContent/resultParser';

class JobContent extends PureComponent {
  getContent = () => {
    const { jobStatus, data } = this.props;
    switch (jobStatus) {
      case 'result':
        return <ResultParser data={data} />;
      case 'error':
        return <JobErrorContent data={data} />;
      default:
        return null;
    }
  };
  render() {
    return this.getContent();
  }
}

JobContent.propTypes = {
  data: PropTypes.shape({}).isRequired,
  jobStatus: PropTypes.string.isRequired,
};

export default JobContent;
