import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import PhoneInput from 'react-phone-input-2';

import { notification } from 'antd';
import { Form, Header } from 'semantic-ui-react';
import { editUser } from '../../../actions/user/auth';

import 'react-phone-input-2/lib/style.css';
import './changePhoneEmail.sass';

class ChangePhoneEmail extends Component {
  state = {
    email: '',
    phone: '',
    loading: false,
  };

  componentDidMount() {
    this.updateState();
  }

  updateState = () =>
    this.setState({
      email: this.props?.userInfo?.email || '',
      phone: this.props?.userInfo?.phone || '',
    });

  editUser = (e) => {
    e.preventDefault();
    const { phone, email } = this.state;
    this.setState({ loading: true }, () => {
      this.props
        .editUser(this.props.userInfo.id, { email, phone: phone[0] !== '+' ? `+${phone}` : phone })
        .then(() => {
          this.setState({ loading: false });
          notification.success({
            message: 'Success',
            description: 'The phone & email was changed successfully',
          });
        })
        .catch(() => this.setState({ loading: false }));
    });
  };

  render() {
    return (
      <div className="change-phone-email">
        <Form onSubmit={this.editUser}>
          <Header as="h3">Change Phone & Email</Header>
          <Form.Group widths="equal">
            <PhoneInput
              style={{ width: '300px' }}
              country={'gb'}
              value={this.state.phone}
              onChange={(phone) => this.setState({ phone })}
            />
            <Form.Input
              value={this.state.email}
              name="email"
              fluid
              size="mini"
              placeholder="Email"
              onChange={(e) => this.setState({ [e.target.name]: e.target.value })}
            />
            <Form.Button loading={this.state.loading} size="mini" type="submit" onClick={this.editUser}>
              Submit
            </Form.Button>
          </Form.Group>
        </Form>
      </div>
    );
  }
}

ChangePhoneEmail.defaultProps = {
  userInfo: {
    phone: '',
    email: '',
  },
  editUser: () => null,
};

ChangePhoneEmail.propTypes = {
  editUser: PropTypes.func,
  userInfo: PropTypes.shape({
    id: PropTypes.number,
    phone: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    email: PropTypes.string,
  }),
};

export default connect(null, { editUser })(ChangePhoneEmail);
