import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Card, Button, Skeleton } from 'antd';
import { Button as ButtonSemantic } from 'semantic-ui-react';

import NavigationMenu from '../../components/navigationMenu';
import RobotInfoComponent from '../../components/robotInfoComponent';
import NewMap from '../../components/newMap/newMap';
import ContactHistoryTable from '../../components/expandableTables/contactHistoryTable';
import { getSingleRobot, getContactTerminals, editRobot, createRobot } from '../../actions/robots/robots';
import { getAllContacts, getPlots } from '../../actions/contacts/contact';
import { getGroups } from '../../actions/groups/groups';
import { sendMessage } from '../../actions/agents/smsNotifications';
import { getLocalNotifications } from '../../actions/smsNotifications/smsNotifications';
import PermissionsHandler from '../../utils/permissionsHandler';

import setZoom from '../../utils/setRange';
import RobotClone from '../../components/robotCreation/robotClone';
import RobotCreationEdit from '../../components/robotCreation/robotCreationEdit';
import AddDropDown from '../../components/addDropDown';

import './robotViewPage.sass';
import history from '../../utils/history';

class RobotViewPage extends Component {
  state = {
    tableLoading: true,
    mapInfo: {
      type: 'simple-marker',
      zoom: 2,
      markers: [],
      savedPoints: [],
    },
    createSuccess: false,
    expand: false,
    isEditExpanded: false,
    isCloneExpanded: false,
    tableData: null,
    plotsView: false,
    lastTimePinPressed: new Date().toUTCString(),
    isPageLoading: true,
  };

  createRobot = (data) => {
    return this.props.createRobot(data).then((res) => {
      history.push(`/robots/${res.id}`);

      const cloneDiv = document.querySelector('.add-drop-down-component-clone');
      if (cloneDiv) {
        const button = cloneDiv.querySelector(
          '.add-drop-down-component__buttons-area .add-drop-down-component__buttons-area_button',
        );
        if (button) {
          if (this.state.isCloneExpanded) {
            setTimeout(() => {
              button.click();
            }, 200);
          }
        }
      }
    });
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.match.params.robotId !== this.props.match.params.robotId) {
      this.setState({ tableLoading: true, isPageLoading: true });
      this.props.getSingleRobot(nextProps.match.params.robotId).then((res) => {
        this.setState({
          tableLoading: false,
          mapInfo: {
            ...this.state.mapInfo,
            type: 'all-markers',
            zoom: 2,
            markers: res.payload?.locations ? Object.values(res.payload?.locations) : [],
          },
          tableData: res.payload?.locations ? Object.values(res.payload?.locations) : [],
          isPageLoading: false,
        });
      });
    }
    if (JSON.stringify(nextProps.singleRobot?.locations) !== JSON.stringify(this.props?.singleRobot?.locations)) {
      this.setState({
        tableData: nextProps.singleRobot?.locations ? Object.values(nextProps.singleRobot?.locations) : [],
      });
    }
    if (
      nextProps.singleRobot.id &&
      this.props.singleRobot.id &&
      Object.values(nextProps.singleRobot?.locations).length !==
        Object.values(this.props.singleRobot?.locations)?.length
    ) {
      if (this.state.mapInfo.type === 'single-marker') {
        this.setState({
          tableData: Object.values(nextProps.singleRobot.locations),
        });
      } else {
        this.setState({
          mapInfo: {
            ...this.state.mapInfo,
            markers: nextProps.singleRobot?.locations ? Object.values(nextProps.singleRobot?.locations) : [],
          },
          tableData: nextProps.singleRobot?.locations ? Object.values(nextProps.singleRobot?.locations) : [],
        });
      }
    }
  }

  componentDidMount() {
    const robotId = this.props.match.params.robotId;
    this.props.getAllContacts();
    this.props.getGroups();
    this.props.getSingleRobot(robotId).then((res) => {
      this.setState({
        tableLoading: false,
        mapInfo: {
          ...this.state.mapInfo,
          type: 'all-markers',
          zoom: 2,
          markers: res.payload?.locations ? Object.values(res.payload.locations) : [],
        },
        tableData: res.payload?.locations ? Object.values(res.payload.locations) : [],
        isPageLoading: false,
      });

      if (this.props.location.state === 'isRedirected' && !this.state.isEditExpanded && !this.state.isCloneExpanded) {
        const cloneDiv = document.querySelector('.add-drop-down-component-clone');
        if (cloneDiv) {
          const button = cloneDiv.querySelector(
            '.add-drop-down-component__buttons-area .add-drop-down-component__buttons-area_button',
          );
          if (button) {
            setTimeout(() => {
              button.click();

              window.history.replaceState(null, '');
            }, 200);
          }
        }
        this.setState({ createSuccess: true, isCloneExpanded: true });
      }
    });
  }

  pinAction = (id, lat, lng, title, hour, range, startAngle, endAngle, mcc, mnc, lac, cellId) => {
    let zoom = 16;
    if (parseInt(range, 10) > 200000) zoom = 5;
    else if (parseInt(range, 10) > 100000) zoom = 7;
    else if (parseInt(range, 10) > 50000) zoom = 8;
    else if (parseInt(range, 10) > 30000) zoom = 9;
    else if (parseInt(range, 10) > 10000) zoom = 10;
    else if (parseInt(range, 10) > 5000) zoom = 11;
    else if (parseInt(range, 10) > 2000) zoom = 12;
    else if (parseInt(range, 10) > 1200) zoom = 13;
    else if (parseInt(range, 10) > 700) zoom = 14;
    else if (parseInt(range, 10) > 300) zoom = 15;
    else if (parseInt(range, 10) > 200) zoom = 16;
    else if (parseInt(range, 10) <= 0) zoom = 17;
    this.setState({
      ...this.state,
      mapInfo: {
        type: 'single-marker',
        zoom,
        markers: [
          { id, lat, lng, title, hour, range, angle_start: startAngle, angle_end: endAngle, mcc, mnc, lac, cellId },
        ],
      },
      tree: { selectedTree: ['single'] },
      lastTimePinPressed: new Date().toUTCString(),
    });
  };

  showResult = (type) => {
    if (type === 'geo-point') {
      this.setState({
        mapInfo: {
          type: 'geo-point',
          zoom: setZoom(this.props.singleRobot.result.message.data.distance),
          sourceLocation: this.props.singleRobot.result.message.data.source_location,
          fixedPoint: this.props.singleRobot.result.message.data.fixed_point,
          distance: this.props.singleRobot.configuration.distance,
          extraDistance: this.props.singleRobot.result.message.data.distance,
        },
      });
    } else if (type === 'point-to-point') {
      this.setState({
        mapInfo: {
          type: 'point-to-point',
          zoom: setZoom(this.props.singleRobot.result.message.data.distance),
          sourceLocation: this.props.singleRobot.result.message.data.source_location,
          targetLocation: this.props.singleRobot.result.message.data.target_location,
          distance: this.props.singleRobot.configuration.distance,
          extraDistance: this.props.singleRobot.result.message.data.distance,
        },
      });
    } else if (type === 'geo-points') {
      if (this.props.singleRobot.configuration.source === 'contact') {
        this.setState({
          mapInfo: {
            ...this.state.mapInfo,
            type: 'geo-points',
            zoom: setZoom(this.props.singleRobot.result.message.data.distance),
            savedPoints: [
              {
                ...this.props.singleRobot.result.message.data.fixed_point,
              },
            ],
            markers: [{ ...this.props.singleRobot.result.message.data.source_location }],
            locationToZoom: this.props.singleRobot.result.message.data.fixed_point,
          },
        });
      } else if (this.props.singleRobot.configuration.source === 'contact-group') {
        const geoPoints = [];
        const markers = [];
        this.props.singleRobot.result.message.data.map((geo) => {
          geoPoints.push(geo.geo_point);
          Object.values(geo.locations).map((locations) => {
            locations.map((res) => {
              markers.push(res.first_point);
              return null;
            });
            return null;
          });
          return null;
        });
        this.setState({
          mapInfo: {
            ...this.state.mapInfo,
            type: 'geo-points',
            zoom: 2,
            savedPoints: geoPoints,
            markers,
            locationToZoom: { lat: 51.8225761, long: 10.8984375 },
          },
        });
      }
    }
  };

  viewAllLocations = () => {
    this.setState({
      mapInfo: {
        ...this.state.mapInfo,
        type: 'all-markers',
        zoom: 2,
        markers: Object.values(this.props.singleRobot.locations),
      },
    });
  };

  getPlots = (e) => {
    if (!this.state.plotsView) {
      const { markers } = e;
      return this.props
        .getPlots(`${markers[0].mcc}`, `${markers[0].mnc}`, `${markers[0].lac}`, `${markers[0].id}`)
        .then((res) => {
          this.setState({ plotsView: !this.state.plotsView });
          return res;
        });
    }

    this.setState({ plotsView: !this.state.plotsView });
    return {};
  };

  showRoot = () => {
    if (!this.state.rootView) {
      this.setState({
        mapInfo: {
          ...this.state.mapInfo,
          type: 'root-map',
        },
        rootView: !this.state.rootView,
      });
    } else if (this.state.rootView) {
      this.setState({
        mapInfo: {
          ...this.state.mapInfo,
          type: 'all-markers',
        },
        rootView: !this.state.rootView,
      });
    }
  };

  toggleDropdownVisibility = (state, type) => {
    if (type === 'edit') {
      this.setState({ isEditExpanded: !this.state.isEditExpanded });
    }
    if (type === 'clone') {
      this.setState({ isCloneExpanded: !this.state.isCloneExpanded });
    }
  };

  render() {
    const { darkMode } = this.props;
    return (
      <div>
        <NavigationMenu statusPage="robots" />
        <div className={`robots-edit ${this.props.user.sticky_header ? '--fixed' : ''}`}>
          {!this.state.isCloneExpanded && (
            <PermissionsHandler button="robot-edit">
              <AddDropDown
                buttonName="Edit Robot"
                createSuccess={this.state.createSuccess}
                height={this.props.singleRobot.type === 'geo-points' ? 550 : 450}
                expand={this.state.isEditExpanded}
                type="edit"
                handleDropDownProperties={this.toggleDropdownVisibility}
                autoClose
                className={this.state.isPageLoading && ' disabled'}
              >
                {this.props.singleRobot && this.props.singleRobot.id && this.props.user.timezone ? (
                  <RobotCreationEdit
                    type={this.props.singleRobot.type}
                    timeZone={this.props.user.timezone}
                    groups={this.props.groups}
                    contacts={this.props.contacts}
                    robotInfo={this.props.singleRobot}
                    getContactTerminals={(contactId) => this.props.getContactTerminals(contactId)}
                    terminals={this.props.terminals}
                    robotEdit={(robotId, data) => this.props.editRobot(robotId, data)}
                  />
                ) : (
                  ''
                )}
              </AddDropDown>
            </PermissionsHandler>
          )}
          <div className="robots-clone">
            {!this.state.isEditExpanded && (
              <AddDropDown
                buttonName="Clone Robot"
                createSuccess={this.state.createSuccess}
                height={this.props.singleRobot.type === 'geo-points' ? 550 : 450}
                expand={this.state.isCloneExpanded}
                handleDropDownProperties={this.toggleDropdownVisibility}
                type="clone"
                autoClose
                className={`add-drop-down-component-clone ${this.state.isPageLoading && ' disabled'}`}
              >
                {this.props.singleRobot && this.props.singleRobot.id && this.props.user.timezone ? (
                  <RobotClone
                    type={this.props.singleRobot.type}
                    timeZone={this.props.user.timezone}
                    groups={this.props.groups}
                    contacts={this.props.contacts}
                    robotInfo={this.props.singleRobot}
                    getContactTerminals={(contactId) => this.props.getContactTerminals(contactId)}
                    terminals={this.props.terminals}
                    createRobot={this.createRobot}
                  />
                ) : (
                  ''
                )}
              </AddDropDown>
            )}
          </div>
        </div>
        <div className="robot-view-page">
          <div className="robot-view-page__left-part">
            <RobotInfoComponent robotData={this.props.singleRobot} tableLoading={this.state.tableLoading} />

            {this.props.singleRobot.result && (
              <Card
                title="Robot Result"
                style={{ width: 300 }}
                loading={this.state.tableLoading}
                extra={
                  (this.props.singleRobot.result.robot_type === 'geo-point' ||
                    this.props.singleRobot.result.robot_type === 'geo-points' ||
                    this.props.singleRobot.result.robot_type === 'point-to-point') && (
                    <Button
                      loading={this.state.tableLoading}
                      disabled={this.state.tableLoading}
                      onClick={() => this.showResult(this.props.singleRobot.result.robot_type)}
                      size="small"
                    >
                      Show
                    </Button>
                  )
                }
              >
                {this.props.singleRobot.result.robot_type === 'online' && (
                  <p>{this.props.singleRobot.result.message.explanation}</p>
                )}

                {this.props.singleRobot.result.robot_type === 'offline' &&
                this.props.singleRobot.result.message.data !== null ? (
                  <Card title="Robot Result" style={{ width: 300 }}>
                    <div className="offline-card-content">
                      {this.props.singleRobot.result.message.data.map((item, index) => {
                        const key = `result-offline-${index}`;
                        return (
                          <div key={key} className="offline-card-content__item">
                            <div className="offline-card-content__item_info">
                              {item.phone && (
                                <div>
                                  Phone: <span style={{ fontWeight: 600 }}>{item.phone}</span>
                                </div>
                              )}
                              {item.imsi && (
                                <div>
                                  IMSI: <span style={{ fontWeight: 600 }}>{item.imsi}</span>
                                </div>
                              )}
                            </div>
                            <div>
                              {item.code === 13 || item.code === 38 ? (
                                <span>{item.detail}</span>
                              ) : (
                                <span>State: {item.state}</span>
                              )}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </Card>
                ) : null}

                {this.props.singleRobot?.result && this.props.singleRobot.result?.message ? (
                  <div className="offline-card-content">
                    <div className="offline-card-content__item">
                      <div className="offline-card-content__item_info">
                        {this.props.singleRobot.result?.message?.status && (
                          <div>
                            <span style={{ fontWeight: 600 }}>Status: </span>
                            <span>{this.props.singleRobot.result?.message?.status}</span>
                          </div>
                        )}
                        {this.props.singleRobot.result?.message?.explanation && (
                          <div>
                            <span style={{ fontWeight: 600 }}> Explanation: </span>
                            <span>{this.props.singleRobot.result?.message?.explanation}</span>
                          </div>
                        )}
                        {this.props.singleRobot.result?.message?.data && (
                          <div>
                            {typeof this.props.singleRobot.result?.message?.data !== 'object' ? (
                              <span style={{ fontWeight: 600 }}> Data: </span>
                            ) : null}
                            <span>
                              {typeof this.props.singleRobot.result?.message?.data !== 'object'
                                ? this.props.singleRobot.result?.message?.data
                                : null}
                            </span>
                          </div>
                        )}
                        {this.props.singleRobot.result?.message?.alert && (
                          <div>
                            <span style={{ fontWeight: 600 }}> Alert: </span>
                            <span>{this.props.singleRobot.result?.message?.alert}</span>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ) : null}
              </Card>
            )}

            <ButtonSemantic
              style={{ marginTop: 20 }}
              onClick={() => this.viewAllLocations()}
              size="mini"
              loading={this.state.tableLoading}
            >
              View all locations
            </ButtonSemantic>
          </div>
          <div className="robot-view-page__right-part">
            {navigator.onLine && (
              <NewMap
                mapInfo={this.state.mapInfo}
                showRoot={this.showRoot}
                pinPressedTime={this.state.lastTimePinPressed}
              />
            )}
            <ContactHistoryTable
              pinAction={(id, lat, lng, title, hour, range, startAngle, endAngle, mcc, mnc, lac, cellId) =>
                this.pinAction(id, lat, lng, title, hour, range, startAngle, endAngle, mcc, mnc, lac, cellId)
              }
              className="table-edit"
              loading={this.state.tableLoading}
              locations={this.state.tableData}
              sms={this.props.user.sms_service}
              agents={this.props.agents}
              getLocalNotifications={this.props.getLocalNotifications}
              sendMessage={this.props.sendMessage}
              darkMode={darkMode}
            />
          </div>
        </div>
      </div>
    );
  }
}

RobotViewPage.defaultProps = {
  singleRobot: {
    description: '',
    source: [],
    singleRobot: {
      description: '',
      configuration: {
        distance: 0,
      },
      result: {
        message: {
          data: {},
        },
      },
      locations: [],
    },
  },
  user: {
    timezone: null,
  },
  terminals: [],
  agents: {},
  plotsLocations: {},
  contacts: {},
  groups: {},
};

RobotViewPage.propTypes = {
  createRobot: PropTypes.func.isRequired,
  getSingleRobot: PropTypes.func.isRequired,
  getContactTerminals: PropTypes.func.isRequired,
  singleRobot: PropTypes.shape({
    id: PropTypes.number,
    description: PropTypes.string,
    type: PropTypes.string,
    locations: PropTypes.array,
    configuration: PropTypes.shape({
      distance: PropTypes.number,
      source: PropTypes.string,
    }),
    source: PropTypes.array,
    result: PropTypes.shape({
      robot_type: PropTypes.string,
      message: PropTypes.shape({
        explanation: PropTypes.string,
        data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
      }),
    }),
  }),
  match: PropTypes.shape({
    params: PropTypes.shape({
      robotId: PropTypes.string,
    }),
  }).isRequired,
  user: PropTypes.shape({
    timezone: PropTypes.string,
    sms_service: PropTypes.number,
    sticky_header: PropTypes.number,
  }),
  contacts: PropTypes.shape({}),
  groups: PropTypes.oneOfType([PropTypes.array, PropTypes.shape({})]),
  terminals: PropTypes.object,
  agents: PropTypes.shape({}),
  getGroups: PropTypes.func.isRequired,
  getAllContacts: PropTypes.func.isRequired,
  editRobot: PropTypes.func.isRequired,
  plotsLocations: PropTypes.shape({}),
  getPlots: PropTypes.func.isRequired,
  sendMessage: PropTypes.func.isRequired,
  getLocalNotifications: PropTypes.func.isRequired,
  darkMode: PropTypes.bool.isRequired,
};

const mapStateToProps = (store) => ({
  singleRobot: store.robots.singleRobot,
  user: store.user,
  terminals: store.robots.contactTerminals,
  groups: store.groups.allGroups,
  contacts: store.contact.allContacts.contacts,
  plotsLocations: store.contact.plotsLocations ? store.contact.plotsLocations : {},
  agents: store.agents,
  darkMode: store.app.darkMode,
  history: PropTypes.object,
});

export default connect(mapStateToProps, {
  getSingleRobot,
  getContactTerminals,
  getAllContacts,
  getGroups,
  editRobot,
  getPlots,
  sendMessage,
  getLocalNotifications,
  createRobot,
})(RobotViewPage);
