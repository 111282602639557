import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { SyncOutlined } from '@ant-design/icons';

import { Select, DatePicker, Tabs, Table, Popconfirm, Button as AntdButton } from 'antd';
import { Button } from 'semantic-ui-react';
import moment from 'moment-timezone';

import { getAllSensors } from '../../actions/cellReport/cellReport';
import { addSensorToGroup, showSensorsGroup, removeSensorFromGroup } from '../../actions/sensorGroups/groups';

import DropDown from '../../components/addDropDown';
import TimeStamp from '../../utils/timeStamp';

class GroupContent extends PureComponent {
  state = {
    sensorSelectValue: '',
    addSensorLoading: false,
    timeToShow: null,
    added: false,
    tab: '1',
    loadingRefresh: false,
    tableLoading: false,
  };

  componentDidMount() {
    const { id } = this.props;
    this.props.getAllSensors({ per_page: 800, order_direction: 'desc', order_column: 'sensor_time' });
    if (this.props.groupSensors[id] && this.props.groupSensors[id].sensors.length > 0) this.setSensorsData(id, false);
    else this.setSensorsData(id, true);
  }

  setSensorsData = (id, toLoad) => {
    if (toLoad) {
      this.setState({ tableLoading: true }, () => {
        this.props.showSensorsGroup(id).then(() => this.setState({ tableLoading: false }));
      });
    } else this.props.showSensorsGroup(id);
  };

  handleSensorSelect = (value) => this.setState({ sensorSelectValue: value });

  handleChangeTime = (times) => {
    if (times) {
      const time1 = times[0] ? moment(times[0]).tz(this.props.timezone) : null;
      const time2 = times[1] ? moment(times[1]).tz(this.props.timezone) : null;
      this.setState({
        timeToShow: [time1, time2],
      });
    } else {
      this.setState({
        timeToShow: null,
      });
    }
  };

  addSensor = () => {
    const { id } = this.props;
    const { sensorSelectValue, timeToShow } = this.state;
    const startTime = moment(timeToShow[0]).format('YYYY-MM-DD HH:mm');
    const endTime = moment(timeToShow[1]).format('YYYY-MM-DD HH:mm');
    this.props
      .addSensorToGroup(sensorSelectValue, id, startTime, endTime)
      .then(() => this.setState({ timeToShow: null, added: true, sensorSelectValue: '' }));
  };

  tabChange = (key) => this.setState({ tab: key });

  refreshData = () =>
    this.setState({ loadingRefresh: true }, () => {
      this.props.showSensorsGroup(this.props.id).then(() => this.setState({ loadingRefresh: false }));
    });

  render() {
    moment.tz.setDefault(this.props.timezone);
    const { id, sensors, groupSensors } = this.props;
    const { addSensorLoading, timeToShow, added, tab, sensorSelectValue, loadingRefresh, tableLoading } = this.state;
    const sensorsOptions =
      sensors &&
      Object.values(sensors).map((sensor, index) => {
        const key = `sensor-${index}`;
        return (
          <Select.Option key={key} value={sensor.id}>
            {sensor.sensor_id}
          </Select.Option>
        );
      });
    const buttonStatusDisable = !sensorSelectValue || timeToShow === null;

    const sensorColumns = [
      {
        title: 'Sensor ID',
        dataIndex: 'sensor_id',
        key: 'sensor_id',
      },
      {
        title: 'Lat',
        dataIndex: 'lat',
        key: 'lat',
      },
      {
        title: 'Lng',
        dataIndex: 'long',
        key: 'long',
      },
      {
        title: 'Sensor time',
        dataIndex: 'sensor_time',
        key: 'sensor_time',
        render: (text) => <TimeStamp date={text} />,
      },
      {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
      },
      {
        title: 'Action',
        dataIndex: 'delete',
        key: 'delete',
        render: (text, data) => (
          <Popconfirm
            style={{ zIndex: 100 }}
            title="Delete this Sensor?"
            okText="Yes"
            cancelText="No"
            onConfirm={() => this.props.removeSensorFromGroup(data.id, this.props.id)}
          >
            <Button size="mini">Remove</Button>
          </Popconfirm>
        ),
      },
    ];

    const reportColumns = [
      {
        title: 'IMSI',
        dataIndex: 'imsi',
        key: 'imsi',
        width: '30%',
      },
      {
        title: 'Found at sensors',
        dataIndex: 'found_at_sensors',
        key: 'found_at_sensors',
        width: '60%',
        render: (text, data) => <div>{Object.values(data.found_at_sensors).join('; ')}</div>,
      },
      {
        title: 'Match',
        dataIndex: 'match',
        key: 'match',
        width: '10%',
      },
    ];

    return (
      <div className="group-container">
        <DropDown className="cell-data-page__drop-down" createSuccess={added} buttonName="Add Sensor" height={40}>
          <div className="group-container__add-sensor">
            <Select
              showSearch
              style={{ width: 300 }}
              placeholder="Select sensor"
              optionFilterProp="children"
              onChange={this.handleSensorSelect}
              value={sensorSelectValue}
              filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
              {sensorsOptions}
            </Select>
            <DatePicker.RangePicker
              showTime={{ format: 'HH:mm' }}
              format="YYYY/MM/DD HH:mm"
              placeholder={['Start Time', 'End Time']}
              onChange={this.handleChangeTime}
              onOk={this.handleChangeTime}
              value={timeToShow}
            />
            <Button
              disabled={buttonStatusDisable}
              className="add-sensor-button"
              onClick={this.addSensor}
              loading={addSensorLoading}
              size="mini"
            >
              Add
            </Button>
          </div>
        </DropDown>
        <AntdButton
          type="primary"
          shape="circle"
          icon={<SyncOutlined />}
          loading={loadingRefresh}
          onClick={this.refreshData}
        />
        <Tabs className="group-container__tab" activeKey={tab} onChange={this.tabChange}>
          <Tabs.TabPane tab="Sensors" key="1">
            <Table
              loading={tableLoading}
              columns={sensorColumns}
              dataSource={groupSensors && groupSensors[id] && groupSensors[id].sensors}
              rowKey={'id'}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Correlated IMSI Results" key="2">
            <Table
              columns={reportColumns}
              dataSource={groupSensors && groupSensors[id] && groupSensors[id].report}
              rowKey={'imsi'}
            />
          </Tabs.TabPane>
        </Tabs>
      </div>
    );
  }
}

GroupContent.defaultProps = {
  sensors: {},
  groupsSensors: {},
};

GroupContent.propTypes = {
  addSensorToGroup: PropTypes.func.isRequired,
  showSensorsGroup: PropTypes.func.isRequired,
  removeSensorFromGroup: PropTypes.func.isRequired,
  getAllSensors: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
  sensors: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.object),
  }),
  groupSensors: PropTypes.shape({}).isRequired,
  timezone: PropTypes.string.isRequired,
};

const mapStateToProps = (store) => ({
  sensors: store.cellReport.allSensors,
  groupSensors: store.sensorGroups.groupsSensors,
  timezone: store.user.timezone,
});

export default connect(mapStateToProps, {
  getAllSensors,
  addSensorToGroup,
  showSensorsGroup,
  removeSensorFromGroup,
})(GroupContent);
