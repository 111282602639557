import React, { memo, useMemo, useEffect } from 'react';

import L from 'leaflet';
import { MapContainer, TileLayer, useMap } from 'react-leaflet';

import { isEqual } from 'lodash';

import SingleMarker from './singleMarker';
import MarkerCluster from './markerCluster';
import PlotMap from './plotMap';
import RulerComponent from './rulerComponent';

// Addons
import ReactLeafletGoogleLayer from 'react-leaflet-google-layer';
import 'leaflet-fullscreen/dist/Leaflet.fullscreen';
import 'leaflet-fullscreen/dist/leaflet.fullscreen.css';

import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';

import 'leaflet/dist/leaflet.css';
import 'react-leaflet-markercluster/dist/styles.min.css';
import './newMap.sass';
import GeoPointMap from './geoPointMap';
import GeoPointsMap from './geoPointsMap';
import { useSelector } from 'react-redux';

// marker Icon setup
let DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
  iconSize: [24, 41],
  iconAnchor: [12, 39],
  popupAnchor: [2, -40],
});
L.Marker.prototype.options.icon = DefaultIcon;

const doNotRerender = (props, nextProps) => isEqual(props, nextProps);

// Tile component for choosing right tile layer set by admin
const TileLayerComponent = memo(() => {
  const mapCredentials = useSelector((store) => store.user?.map?.credentials);

  if (mapCredentials.google_map_token)
    return <ReactLeafletGoogleLayer apiKey={mapCredentials.google_map_token} type={'roadmap'} />;
  else if (mapCredentials.hosted_osm_url) return <TileLayer url={mapCredentials.hosted_osm_url} />;
  else if (mapCredentials.mapbox_token && mapCredentials.mapbox_style_basic && mapCredentials.mapbox_account_name) {
    const { mapbox_style_basic, mapbox_token, mapbox_account_name } = mapCredentials;
    const style = mapbox_style_basic.split(/[/ ]+/).pop();
    const url = `https://api.mapbox.com/styles/v1/${mapbox_account_name}/${style}/tiles/256/{z}/{x}/{y}@2x?access_token=${mapbox_token}`;
    return <TileLayer url={url} />;
  }

  return <TileLayer url={mapCredentials.public_osm_url} />;
});

const MapComponent = ({
  id,
  mapInfo: { markers, type, zoom, range, showMarker, savedPoints, isOnDashboard = false },
  center,
  showRoot,
  disablePlotRoute,
  getLatLng,
}) => {
  const map = useMap();

  // This checking is done to not do zoom action for rootMap because of autoFit bounds
  if (type !== 'root-map' && type !== 'geo-point-map' && type !== 'geo-points-map') map.setView(center, zoom);

  useEffect(() => {
    L.control.scale({ metric: true, imperial: false }).setPosition('bottomright').addTo(map);
  }, [map]);

  return (
    <div id="main-map-component">
      <TileLayerComponent />
      <RulerComponent map={map} />
      {type === 'single-marker' && (
        <SingleMarker id={id} map={map} zoom={zoom} marker={markers[0]} center={center} isOnDashboard={isOnDashboard} />
      )}
      {type === 'all-markers' && (
        <MarkerCluster
          markers={markers}
          maxClusterRadius={40}
          showRoot={showRoot}
          disablePlotRoute={disablePlotRoute}
        />
      )}
      {type === 'root-map' && <PlotMap map={map} markers={markers} maxClusterRadius={40} showRoot={showRoot} />}

      {type === 'geo-point-map' && <GeoPointMap markers={markers} map={map} getLatLng={getLatLng} range={range} />}

      {type === 'geo-points-map' && (
        <GeoPointsMap map={map} getLatLng={getLatLng} range={range} showMarker={showMarker} savedPoints={savedPoints} />
      )}
    </div>
  );
};

const Map = ({ id, mapInfo, showRoot = () => null, disablePlotRoute = false, getLatLng = () => null }) => {
  const center = useMemo(() => {
    switch (mapInfo.type) {
      case 'single-marker':
        return [mapInfo.markers[0].lat, mapInfo.markers[0].lng];
      default:
        return [51.505, -0.09];
    }
  }, [mapInfo]);

  return (
    <div className="leaflet-map-component" id={`main-map-wrapper-${id}`} style={{ position: 'relative' }}>
      <MapContainer
        id={id || 'main-map'}
        center={center}
        zoom={mapInfo.zoom}
        doubleClickZoom={false}
        tap={false}
        fullscreenControl
      >
        <MapComponent
          id={id}
          mapInfo={mapInfo}
          center={center}
          showRoot={showRoot}
          disablePlotRoute={disablePlotRoute}
          getLatLng={getLatLng}
        />
      </MapContainer>
    </div>
  );
};

export default memo(Map, doNotRerender);
