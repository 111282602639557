import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import moment from 'moment-timezone';

import {
  DownOutlined,
  GlobalOutlined,
  MinusOutlined,
  PlusOutlined,
  ControlOutlined,
  RobotOutlined,
  PushpinOutlined,
} from '@ant-design/icons';

import { Card, DatePicker, Radio, Dropdown, Menu } from 'antd';
import { Button, Icon } from 'semantic-ui-react';

import {
  getContactInfo,
  getComments,
  createComment,
  deleteComment,
  getPlots,
  getAllContacts,
  locateContactResult,
} from '../../actions/contacts/contact';
import { sendMessage } from '../../actions/agents/smsNotifications';
import { getLocalNotifications } from '../../actions/smsNotifications/smsNotifications';

import NavigationMenu from '../../components/navigationMenu';
import ContactComponent from './contactComponent';
import ContactHistoryTable from '../../components/expandableTables/contactHistoryTable';
import setRange from '../../utils/setRange';
import history from '../../utils/history';
import PermissionHandler from '../../utils/permissionsHandler';
import ContactComments from '../../components/contactComments';
import SessionUnresolvedLocationsHelper from '../../utils/SessionUnresolvedLocationsHelper';
import Map from '../../components/newMap/newMap';

import './contactViewPage.sass';
import timeToUTC from '../../utils/timeToUTC';

const { RangePicker } = DatePicker;

const locationsGroupNames = {
  all: 'All',
  manual: 'Manual',
  others: 'Others',
  robots: 'Robots',
};

class UserProfile extends Component {
  state = {
    loading: true,
    mapInfo: {
      type: 'all-markers',
      zoom: 2,
      markers: [],
    },
    tableData: null,
    rootView: false,
    routeToShow: 0,
    maxRouteToShow: 0,
    startRoute: false,
    timeToShow: [],
    showFilter: false,
    filterSet: false,
    filterLoading: false,
    filterDisabled: true,
    currentSelectedRobotId: null,
    robotExpand: false,
    plotsView: false,
    locationsToShow: 'global-all',
    locationsGroupType: 'all',
    allLocationsIds: [],
    lastTimePinPressed: new Date().toUTCString(),
  };

  componentDidMount() {
    this.props.getContactInfo(this.props.match.params.contactId, {}).then((res) => {
      this.setState(
        {
          loading: false,
          mapInfo: {
            type: 'all-markers',
            zoom: 2,
            markers: res?.locations?.all ? Object.values(res?.locations?.all).reverse() : [],
          },
          tableData: res?.locations?.all ? Object.values(res?.locations?.all).reverse() : [],
        },
        () => {
          const sessionData = new SessionUnresolvedLocationsHelper();
          if (sessionData.state && sessionData.state[res.id]) {
            this.setSessionLocationsInTable(res, sessionData);
          }
        },
      );
    });

    this.props.getAllContacts();
    this.props.getComments(this.props.match.params.contactId);
  }

  setSessionLocationsInTable(data, sessionData) {
    const locationTime = new Date().toISOString();

    const allLocationsIds = new Set();
    if (data.locations && data.locations.all && data.locations.all) {
      Object.values(data.locations.all).forEach((location) => {
        if (location.interval && location.interval.location_ids)
          Object.values(location.interval.location_ids).forEach((locationId) => allLocationsIds.add(locationId));
      });
    }

    Object.values(sessionData.state[data.id]).forEach((location) => {
      if (!allLocationsIds.has(location.id)) {
        this.props.locateContactResult({
          locations: { [location.id]: { ...location, location_time: locationTime } },
        });
      } else sessionData.removeLocationFromSession({ location });
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    const { locationsToShow, locationsGroupType, currentSelectedRobotId } = this.state;
    const robotId = currentSelectedRobotId || parseInt(locationsGroupType.split(/[()]/)[1], 10);
    const includesRobot =
      locationsGroupType.includes('Robot') && locationsToShow === 'locations'
        ? true
        : locationsToShow.includes('Robot');
    let key = 'all';

    if (locationsToShow === 'global-all') key = 'all';
    else if (locationsToShow === 'global-others') key = 'others';
    else if (locationsToShow === 'locations' && locationsGroupType === 'all') key = 'all';
    else if (locationsToShow === 'locations' && locationsGroupType === 'manual') key = 'manual';
    else if (locationsToShow === 'locations' && locationsGroupType === 'robots') key = 'robots';
    else if (locationsToShow === 'locations' && locationsGroupType === 'others') key = 'others';
    else if (includesRobot) key = 'robots';

    const sessionData = new SessionUnresolvedLocationsHelper();
    // let sessionLocations = null;
    if (
      nextProps.contactInfo &&
      nextProps.contactInfo.locations &&
      sessionData.state &&
      sessionData.state[nextProps.contactInfo.id]
    ) {
      // sessionLocations = { ...sessionData.state[nextProps.contactInfo.id] };
    }

    if (nextProps.contactInfo && nextProps.contactInfo.locations) {
      let locations = [];
      if (locationsToShow === 'global-all' || locationsToShow === 'global-others') {
        locations = nextProps.contactInfo?.locations?.[key]
          ? Object.values(nextProps.contactInfo?.locations?.[key])
          : [];
      } else if (locationsGroupType !== 'robots' && !includesRobot) {
        locations = nextProps.contactInfo?.locations?.locationsGroup?.[key]
          ? Object.values(nextProps.contactInfo?.locations?.locationsGroup?.[key])
          : [];
      } else if (nextProps.contactInfo.locations?.locationsGroup?.robots?.[robotId]) {
        locations = nextProps.contactInfo?.locations?.locationsGroup?.robots?.[robotId]?.locations
          ? Object.values(nextProps.contactInfo?.locations?.locationsGroup?.robots?.[robotId].locations)
          : [];
      } else locations = [];

      if (
        this.state.mapInfo.type !== 'single-marker' &&
        locations.length !== 0 &&
        (nextProps.contactInfo?.locations?.[key] || nextProps.contactInfo?.locations?.locationsGroup?.[key])
      ) {
        this.setState({
          mapInfo: {
            ...this.state.mapInfo,
            type: 'all-markers',
            markers: locations,
          },
          tableData: locations.reverse(),
        });
      } else if (this.state.mapInfo.type === 'single-marker' && locations.length !== 0) {
        this.setState({
          tableData: locations.reverse(),
        });
      } else if (locations.length === 0) {
        this.setState({
          mapInfo: {
            ...this.state.mapInfo,
            type: 'all-markers',
            markers: [],
          },
          tableData: [],
        });
      }
    }
  }

  filterTimeOnChange = (times) => {
    const time1 = times?.[0];
    const time2 = times?.[1];
    this.setState({ timeToShow: [time1, time2] });
  };

  filterLocationsOnOk = (times) => {
    if (times?.length === 2 && times[0] && times[1]) {
      this.setState(
        {
          filterDisabled: false,
          filterLoading: true,
        },
        () => {
          const { timezone } = this.props.user;
          const time1 = times?.[0];
          const time2 = times?.[1];
          this.setState({ timeToShow: [time1, time2] });

          this.props
            .getContactInfo(this.props.match.params.contactId, {
              start: timeToUTC(times[0].format('YYYY-MM-DD HH:mm:ss'), timezone, 'YYYY-MM-DD HH:mm:ss'),
              end: timeToUTC(times[1].format('YYYY-MM-DD HH:mm:ss'), timezone, 'YYYY-MM-DD HH:mm:ss'),
            })
            .then(() => {
              this.setState({
                filterLoading: false,
              });
            });
        },
      );
    }
  };

  resetFilter = () => {
    this.setState({ filterLoading: true, filterDisabled: true }, () => {
      this.props.getContactInfo(this.props.match.params.contactId, {}).then(() =>
        this.setState({
          filterLoading: false,
        }),
      );
    });
  };

  pinAction = (id, lat, lng, title, hour, range, startAngle, endAngle, mcc, mnc, lac, cellId) => {
    const zoom = setRange(range);
    this.setState({
      ...this.state,
      mapInfo: {
        ...this.state.mapInfo,
        type: 'single-marker',
        zoom,
        markers: [
          { id, lat, lng, title, hour, range, angle_start: startAngle, angle_end: endAngle, mcc, mnc, lac, cellId },
        ],
      },
      rootView: false,
      routeToShow: 0,
      maxRouteToShow: 0,
      startRoute: false,
      plotsView: false,
      locationsToShow: 'single',
      lastTimePinPressed: new Date().toUTCString(),
    });
  };

  createRobot = () => {
    history.push({
      pathname: '/robots',
      state: {
        fromContact: true,
        fromGroup: false,
        contactInfo: {
          id: this.props.contactInfo.id,
          name: this.props.contactInfo.name,
        },
      },
    });
  };

  showRoot = () => {
    if (!this.state.rootView) {
      this.setState({
        mapInfo: {
          ...this.state.mapInfo,
          type: 'root-map',
        },
        rootView: !this.state.rootView,
      });
    } else if (this.state.rootView) {
      this.setState({
        mapInfo: {
          ...this.state.mapInfo,
          type: 'all-markers',
        },
        rootView: !this.state.rootView,
      });
    }
  };

  showFilter = () => this.setState({ ...this.state, showFilter: !this.state.showFilter });

  getPlots = (e) => {
    if (!this.state.plotsView) {
      const { markers } = e;
      if (!this.props.plotsLocations[markers[0].id]) {
        return this.props
          .getPlots(`${markers[0].mcc}`, `${markers[0].mnc}`, `${markers[0].lac}`, `${markers[0].id}`)
          .then((res) => {
            this.setState({ plotsView: !this.state.plotsView });
            return res;
          });
      }
      this.setState({ plotsView: !this.state.plotsView });
      return new Promise((resolve) => resolve(null));
    }

    this.setState({ plotsView: !this.state.plotsView });
    return new Promise((resolve) => resolve(null));
  };

  locationsTypeOnChange = ({ target: { value } }) => {
    const { locationsGroupType } = this.state;
    const includesRobot =
      locationsGroupType.includes('Robot') && value === 'locations' ? true : value.includes('Robot');

    const parsedValue = locationsGroupType.includes('Robot') && value === 'locations' ? locationsGroupType : value;

    this.setState(
      {
        locationsToShow:
          value === 'global-all' || value === 'locations' || value === 'global-others' ? value : 'locations',
        currentSelectedRobotId: null,
      },
      () => {
        switch (parsedValue) {
          case 'global-all':
            const allLocations = this.props?.contactInfo?.locations?.all
              ? Object.values(this.props.contactInfo.locations.all)
              : [];
            this.setState({
              mapInfo: {
                type: 'all-markers',
                zoom: 2,
                markers: allLocations,
              },
              rootView: false,
              tableData: allLocations.reverse(),
            });
            break;
          case 'locations':
            const locationsGroup = this.props.contactInfo?.locations?.locationsGroup?.[locationsGroupType]
              ? Object.values(this.props.contactInfo?.locations?.locationsGroup?.[locationsGroupType])
              : [];
            this.setState({
              mapInfo: {
                type: 'all-markers',
                zoom: 2,
                markers: locationsGroup,
              },
              rootView: false,
              tableData: locationsGroup.reverse(),
            });
            break;
          case 'global-others':
            const othersLocations = this.props.contactInfo?.locations?.others
              ? Object.values(this.props.contactInfo?.locations?.others)
              : [];
            this.setState({
              mapInfo: {
                type: 'all-markers',
                zoom: 2,
                markers: othersLocations,
              },
              rootView: false,
              tableData: othersLocations.reverse(),
            });
            break;
          default:
            if (includesRobot) {
              const robotId = parseInt(value.split(/[()]/)[1], 10) || parseInt(locationsGroupType.split(/[()]/)[1], 10);
              const robotLocations = this.props.contactInfo.locations.locationsGroup.robots[robotId]
                ? Object.values(this.props.contactInfo.locations.locationsGroup.robots[robotId].locations)
                : [];
              this.setState({
                mapInfo: {
                  type: 'all-markers',
                  zoom: 2,
                  markers: robotLocations,
                },
                currentSelectedRobotId: robotId,
                tableData: robotLocations.reverse(),
              });
            } else if (value === 'manual' || value === 'all' || value === 'others') {
              const locations = this.props.contactInfo.locations?.locationsGroup?.[value]
                ? Object.values(this.props.contactInfo.locations?.locationsGroup?.[value] || {})
                : [];
              this.setState({
                mapInfo: {
                  type: 'all-markers',
                  zoom: 2,
                  markers: locations,
                },
                rootView: false,
                tableData: locations?.reverse(),
              });
            }
        }
      },
    );
  };

  setLocationsGroup = (location) => {
    this.setState({ locationsToShow: 'locations', locationsGroupType: location }, () => {
      this.locationsTypeOnChange({ target: { value: location } });
    });
  };

  render() {
    const { contactInfo, darkMode } = this.props;

    const robots =
      contactInfo?.locations?.locationsGroup?.robots &&
      Object.values(contactInfo.locations.locationsGroup.robots).length !== 0
        ? Object.keys(contactInfo.locations.locationsGroup.robots).map((key) => {
            const keyId = `${key}`;
            const robot = contactInfo.locations.locationsGroup.robots[key];
            if (!robot.name) return null;
            return (
              <Menu.Item onClick={() => this.setLocationsGroup(`Robot: ${robot.name}(${robot.id})`)} key={keyId}>{`${
                robot.name ? `Robot ${robot.name}(id-${robot.id})` : 'Robot-deleted'
              }`}</Menu.Item>
            );
          })
        : null;

    return (
      <div>
        <NavigationMenu statusPage="contact-view" />
        <div className={`contact-view-page ${this.props.user.sticky_header ? '--fixed' : ''}`}>
          <div className="contact-view-page__left-part">
            <ContactComponent className="contact-component-edit" contactInfo={this.props.contactInfo} />
            <PermissionHandler button="robot-create">
              <Button size="mini" onClick={this.createRobot} loading={this.state.loading} disabled={this.state.loading}>
                Create Robot <Icon className="contact-view-page__left-part_robot-button" name="add" />
              </Button>
            </PermissionHandler>

            <ContactComments
              comments={this.props.comments}
              createComment={(text) => this.props.createComment(this.props.match.params.contactId, text)}
              deleteComment={(commentId) => this.props.deleteComment(commentId)}
            />
          </div>

          <div className="contact-view-page__right-part">
            {navigator.onLine && (
              <Map
                mapInfo={this.state.mapInfo}
                showRoot={this.showRoot}
                pinPressedTime={this.state.lastTimePinPressed}
              />
            )}

            <Card
              title="Show"
              bordered={false}
              className={`contact-view-page__right-part_locations-info${this.state.showFilter ? ' --opened' : ''}`}
            >
              <Radio.Group
                defaultValue="global-all"
                buttonStyle="solid"
                value={this.state.locationsToShow}
                onChange={this.locationsTypeOnChange}
                className="contact-view-page__right-part_radio-group-filter"
                disabled={!this.props.contactInfo.locations}
              >
                <Radio.Button value="global-all">All</Radio.Button>
                <Radio.Button value="locations">
                  <span>Locations:</span>
                  <Dropdown
                    disabled={!this.props.contactInfo.locations}
                    overlay={
                      <Menu>
                        <Menu.Item onClick={() => this.setLocationsGroup('all')} key="all">
                          <GlobalOutlined />
                          All
                        </Menu.Item>
                        <Menu.Item onClick={() => this.setLocationsGroup('manual')} key="manual">
                          <ControlOutlined />
                          Manual
                        </Menu.Item>
                        <Menu.SubMenu
                          title={
                            <span>
                              <RobotOutlined /> Robots
                            </span>
                          }
                          key="robots"
                          disabled={!robots}
                        >
                          {robots && robots}
                        </Menu.SubMenu>
                        <Menu.Item onClick={() => this.setLocationsGroup('others')} key="others">
                          <PushpinOutlined />
                          Others
                        </Menu.Item>
                      </Menu>
                    }
                  >
                    <span>
                      <span>
                        {locationsGroupNames[this.state.locationsGroupType]
                          ? locationsGroupNames[this.state.locationsGroupType]
                          : this.state.locationsGroupType}
                      </span>{' '}
                      <DownOutlined />
                    </span>
                  </Dropdown>
                </Radio.Button>
                <Radio.Button value="global-others">Other Queries</Radio.Button>
              </Radio.Group>
              <div
                className="contact-view-page__right-part_filter__buttons"
                onClick={this.showFilter}
                role="button"
                tabIndex={0}
              >
                <span className="contact-view-page__right-part_filter__buttons_label">Filter locations</span>
                {!this.state.showFilter ? (
                  <PlusOutlined className="contact-view-page__right-part_filter__buttons_button" />
                ) : (
                  <MinusOutlined className="contact-view-page__right-part_filter__buttons_button" />
                )}
              </div>
            </Card>

            <div className="contact-view-page__right-part_filter">
              <div
                style={{ marginLeft: '10px' }}
                className={`contact-view-page__right-part_filter__content ${
                  this.state.showFilter && '--active-filter'
                }`}
              >
                <RangePicker
                  showTime
                  format="YYYY/MM/DD HH:mm:ss"
                  style={{ width: '68%' }}
                  placeholder={['Start Time', 'End Time']}
                  value={this.state.timeToShow}
                  onChange={this.filterTimeOnChange}
                  onOk={this.filterLocationsOnOk}
                />
                <Button
                  size="mini"
                  onClick={this.resetFilter}
                  style={{ marginLeft: 20 }}
                  loading={this.state.filterLoading}
                  disabled={this.state.filterDisabled}
                >
                  Reset
                </Button>
              </div>
            </div>

            <ContactHistoryTable
              pinAction={this.pinAction}
              className="table-edit"
              loading={this.state.loading}
              locations={this.state.tableData}
              agents={this.props.agents}
              sendMessage={this.props.sendMessage}
              getLocalNotifications={this.props.getLocalNotifications}
              sms={this.props.user.sms_service}
              darkMode={darkMode}
            />
          </div>
        </div>
      </div>
    );
  }
}

UserProfile.defaultProps = {
  contactInfo: {
    all_locations: [],
  },
  user: {},
  comments: {},
  agents: {},
  plotsLocations: {},
};

UserProfile.propTypes = {
  user: PropTypes.shape({
    timezone: PropTypes.string,
    id: PropTypes.number,
    sms_service: PropTypes.number,
    sticky_header: PropTypes.number,
  }),
  match: PropTypes.shape({
    params: PropTypes.shape({
      contactId: PropTypes.string,
    }),
  }).isRequired,
  contactInfo: PropTypes.shape({
    all_locations: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    user_locations: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    id: PropTypes.number,
    name: PropTypes.string,
    robots: PropTypes.oneOfType([
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      }),
      PropTypes.array,
    ]),
  }),
  agents: PropTypes.shape({}),
  getContactInfo: PropTypes.func.isRequired,
  getComments: PropTypes.func.isRequired,
  createComment: PropTypes.func.isRequired,
  deleteComment: PropTypes.func.isRequired,
  sendMessage: PropTypes.func.isRequired,
  getLocalNotifications: PropTypes.func.isRequired,
  getPlots: PropTypes.func.isRequired,
  getAllContacts: PropTypes.func.isRequired,
  locateContactResult: PropTypes.func.isRequired,
  comments: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.array]),
  plotsLocations: PropTypes.shape({}),
  darkMode: PropTypes.bool.isRequired,
};

const mapStateToProps = (store) => ({
  contactInfo: store.contact.contactInfo,
  user: store.user,
  comments: store.contact.comments,
  agents: store.agents,
  plotsLocations: store.contact.plotsLocations ? store.contact.plotsLocations : {},
  darkMode: store.app.darkMode,
});

export default connect(mapStateToProps, {
  getContactInfo,
  getComments,
  createComment,
  deleteComment,
  sendMessage,
  getLocalNotifications,
  getPlots,
  getAllContacts,
  locateContactResult,
})(UserProfile);
