import React, { memo } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

import { Tooltip } from 'antd';

import { LoadingOutlined } from '@ant-design/icons';

const MapButton = ({
  Icon,
  onClick,
  loading = false,
  active = false,
  position,
  toolTipText = '',
  disabled = false,
}) => {
  return toolTipText ? (
    <Tooltip placement="left" title={toolTipText}>
      <MapButtonStyled
        active={active}
        load={loading}
        onClick={disabled ? () => null : onClick}
        position={position}
        disabled={disabled}
      >
        {loading ? <LoadingOutlined /> : <Icon />}
      </MapButtonStyled>
    </Tooltip>
  ) : (
    <MapButtonStyled
      active={active}
      load={loading}
      onClick={disabled ? () => null : onClick}
      position={position}
      disabled={disabled}
    >
      {loading ? <LoadingOutlined /> : <Icon />}
    </MapButtonStyled>
  );
};

MapButton.propTypes = {
  Icon: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  active: PropTypes.bool,
  position: PropTypes.shape({ top: PropTypes.number, right: PropTypes.number }),
  toolTipText: PropTypes.string,
};

export const MapButtonStyled = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
  width: 40px;
  height: 40px;
  background-color: white;
  border-radius: 2px;
  overflow: hidden;
  z-index: 1000;

  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;

  display: flex;
  align-items: center;
  justify-content: center;

  ${({ position }) =>
    position &&
    css`
      top: ${`${position.top}px`} !important;
      right: ${`${position.right}px`} !important;
    `}

  svg {
    font-size: ${({ load }) => (load ? '20px' : '25px')};
    margin-bottom: 2px;

    path {
      fill: #5a5a5a;
    }
  }

  ${({ active }) =>
    active &&
    css`
      background-color: #9ed45a;

      svg {
        path {
          fill: #f1f1f1;
        }
      }

      &:hover {
        background-color: #92c453 !important;

        svg {
          path {
            fill: #ececec !important;
          }
        }
      }
    `}
  &:hover {
    background-color: #f4f4f4;

    svg {
      path {
        fill: #393939;
      }
    }
  }

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.6;
      cursor: not-allowed;
    `}
`;

export default memo(MapButton);
