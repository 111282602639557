import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Form, Input, Button, Radio } from 'semantic-ui-react';
import { InboxOutlined } from '@ant-design/icons';
import { Input as AntdInput } from 'antd';
import Dropzone from 'react-dropzone';

import { createContact, uploadContacts } from '../../actions/contacts/contact';

import NavigationMenu from '../../components/navigationMenu';
import AddDropDown from '../../components/addDropDown';
import PermissionHandler from '../../utils/permissionsHandler';

import './contactsPage.sass';
import UnsavedContactsTable from '../../components/unsavedContactsTable/unsavedContactsTable';
import SavedContactsTable from '../../components/savedContactsTable/savedContactsTable';

const { TextArea } = AntdInput;

class ContactsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      createSuccess: false,
      contactCreateLoading: false,
      newContact: {
        sex: '',
      },
      uploaded: false,
      percent: 0,
      savedTableChanged: false,
      unsavedTableChanged: false,
    };
  }

  handleChange = (e, { sex }) => this.setState({ newContact: { sex } });

  submitNewContact = (e) => {
    const newContact = {
      name: e.target.name.value.trim(),
      alias: e.target.alias.value,
      phone: e.target.phone.value,
      imsi: e.target.imsi.value,
      description: e.target.description.value,
      sex: this.state.newContact.sex,
    };

    this.setState({ contactCreateLoading: true }, () => {
      this.props
        .createContact(newContact)
        .then(() => {
          this.setTableMustUpdate('page');
          this.setState({ createSuccess: true, contactCreateLoading: false });
          setTimeout(() => this.setState({ createSuccess: false }), 500);
        })
        .catch(() => {
          this.setState({ createSuccess: false, contactCreateLoading: false });
        });
    });
  };

  onDrop = (file) => {
    const config = {
      onUploadProgress: (progressEvent) => {
        const percent = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        if (percent >= 100) {
          this.setState({ percent: 100 });
        } else {
          this.setState({ percent });
        }
      },
    };
    this.props.uploadContacts(file[0], config).then((res) => {
      this.setTableMustUpdate('page');
    });
  };

  eraseUploadData = () => {
    this.setState({
      uploaded: false,
      percent: 0,
    });
  };

  setTableMustUpdate = (value) => {
    if (value === 'page') {
      this.setState({
        savedTableChanged: true,
      });

      setTimeout(() => {
        this.setState({
          savedTableChanged: false,
        });
      }, 100);
    } else {
      this.setState({
        savedTableChanged: true,
        unsavedTableChanged: true,
      });
      setTimeout(() => {
        this.setState({
          savedTableChanged: false,
          unsavedTableChanged: false,
        });
      }, 100);
    }
  };

  render() {
    const { sex } = this.state.newContact;

    return (
      <div>
        <NavigationMenu statusPage="contacts" />
        <div className={`contacts-page ${this.props.user.sticky_header ? '--fixed' : ''}`}>
          <div className="contacts-page__add-contact-drop-down">
            <PermissionHandler button="contact-create">
              <AddDropDown buttonName="Create Contact" createSuccess={this.state.createSuccess} height={'auto'}>
                <Form style={{ width: '50%' }} onSubmit={this.submitNewContact}>
                  <Form.Group widths="equal">
                    <Form.Field>
                      <Input size="mini" name="name" fluid placeholder="Name" />
                    </Form.Field>
                    <Form.Field>
                      <Input size="mini" name="alias" fluid placeholder="Alias" />
                    </Form.Field>
                  </Form.Group>
                  <Form.Group widths="equal">
                    <Form.Field>
                      <Input size="mini" name="phone" fluid placeholder="Phone" />
                    </Form.Field>
                    <Form.Field>
                      <Input size="mini" name="imsi" fluid placeholder="IMSI" />
                    </Form.Field>
                  </Form.Group>
                  <Form.Group widths="equal">
                    <Form.Field>
                      <TextArea autoSize={{ minRows: 3 }} name="description" placeholder="Description" />
                    </Form.Field>
                  </Form.Group>
                  <Form.Group inline>
                    <label htmlFor="sss">Gender</label>
                    <Form.Field
                      control={Radio}
                      label="Male"
                      sex="1"
                      checked={sex === '1'}
                      onChange={this.handleChange}
                    />
                    <Form.Field
                      control={Radio}
                      label="Female"
                      sex="0"
                      checked={sex === '0'}
                      onChange={this.handleChange}
                    />
                  </Form.Group>
                  <Button
                    size="mini"
                    type="submit"
                    loading={this.state.contactCreateLoading}
                    disabled={this.state.contactCreateLoading}
                  >
                    Create
                  </Button>
                </Form>
              </AddDropDown>
            </PermissionHandler>
            <AddDropDown
              createSuccess={this.state.uploaded}
              buttonName="Upload Contacts"
              height={210}
              onClose={() => this.eraseUploadData()}
            >
              <Dropzone accept="text/csv" multiple={false} onDrop={this.onDrop} className="contacts-page__drop-zone">
                <div className="contacts-page__drop-zone_wrapper">
                  <p className="contacts-page__drop-zone_icon">
                    <InboxOutlined />
                  </p>
                  <p className="contacts-page__drop-zone_text">Click or drag file (only one) to this area to upload</p>
                  <p className="contacts-page__drop-zone_hint">Support only for CSV file</p>
                  <div
                    className="contacts-page__drop-zone_percent"
                    style={{ opacity: this.state.percent === 0 ? 0 : 1 }}
                  >
                    <div className="contacts-page__drop-zone_percent-count" style={{ width: `${this.state.percent}%` }}>
                      <div
                        className="contacts-page__drop-zone_percent-done"
                        style={{ opacity: this.state.percent === 100 ? 1 : 0 }}
                      >
                        Successfully Uploaded
                      </div>
                    </div>
                  </div>
                </div>
              </Dropzone>
            </AddDropDown>
          </div>

          <SavedContactsTable trigger={this.state.savedTableChanged || this.state.unsavedTableChanged} />
          <UnsavedContactsTable trigger={this.state.unsavedTableChanged} triggerAction={this.setTableMustUpdate} />
        </div>
      </div>
    );
  }
}

ContactsPage.defaultProps = {
  user: {},
};

ContactsPage.propTypes = {
  createContact: PropTypes.func.isRequired,
  contact: PropTypes.shape({
    allContacts: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  }).isRequired,
  uploadContacts: PropTypes.func.isRequired,
  user: PropTypes.shape({
    sticky_header: PropTypes.number,
  }),
};

const mapStateToProps = (store) => ({
  contact: store.contact,
  user: store.user,
});

export default connect(mapStateToProps, {
  createContact,
  uploadContacts,
})(ContactsPage);
