import React, { useState, useMemo, memo } from 'react';

import { Table, Switch, Button } from 'antd';

const CREATE = 'create';
const EDIT = 'edit';
const DELETE = 'delete';
const VIEW = 'view';
const ENABLE = 'enable';

const ALL_ACTION_PERMISSIONS = [CREATE, EDIT, DELETE, VIEW, ENABLE];

const PERMISSIONS_DESCRIPTION = {
  cell: {
    name: 'Cell',
    actions: [CREATE, EDIT, DELETE],
    role: 2,
  },
  'cell-report': {
    name: 'Cell report',
    actions: [VIEW],
  },
  contact: {
    name: 'Contact',
    actions: [CREATE, EDIT, DELETE],
  },
  'contact-group': {
    name: 'Contact Group',
    actions: [CREATE, EDIT, DELETE],
  },
  hold: {
    name: 'Hold',
    actions: [ENABLE],
  },
  locate: {
    name: 'Locate',
    actions: [CREATE, EDIT, DELETE],
  },
  report: {
    name: 'Report',
    actions: [VIEW],
  },
  robot: {
    name: 'Robot',
    actions: [CREATE, EDIT, DELETE],
  },
  search: {
    name: 'Search',
    actions: [CREATE],
  },
  'team-contact': {
    name: 'Team contact',
    actions: [EDIT, DELETE],
    role: 3,
  },
  'team-contact-group': {
    name: 'Team contact group',
    actions: [EDIT, DELETE],
    role: 3,
  },
  team: {
    name: 'Team',
    actions: [CREATE, EDIT, DELETE],
    role: 2,
  },
  'team-robot': {
    name: 'Team robot',
    actions: [EDIT, DELETE],
    role: 3,
  },
  terminal: {
    name: 'Terminal',
    actions: [CREATE, EDIT, DELETE],
  },
};

const emptyPermissions = {
  'robot-create': false,
  'robot-edit': false,
  'robot-delete': false,
  'contact-create': false,
  'contact-edit': false,
  'contact-delete': false,
  'contact-group-create': false,
  'contact-group-edit': false,
  'contact-group-delete': false,
  'locate-create': false,
  'locate-edit': false,
  'locate-delete': false,
  'terminal-create': false,
  'terminal-edit': false,
  'terminal-delete': false,
  'team-create': false,
  'team-edit': false,
  'team-delete': false,
  'search-create': false,
  'report-view': false,
  'team-contact-edit': false,
  'team-contact-delete': false,
  'team-robot-edit': false,
  'team-robot-delete': false,
  'team-contact-group-edit': false,
  'team-contact-group-delete': false,
  'cell-create': false,
  'cell-edit': false,
  'cell-delete': false,
  'cell-report-view': false,
  'hold-enable': false,
};

const truePermissions = {
  'robot-create': true,
  'robot-edit': true,
  'robot-delete': true,
  'contact-create': true,
  'contact-edit': true,
  'contact-delete': true,
  'contact-group-create': true,
  'contact-group-edit': true,
  'contact-group-delete': true,
  'locate-create': true,
  'locate-edit': true,
  'locate-delete': true,
  'terminal-create': true,
  'terminal-edit': true,
  'terminal-delete': true,
  'team-create': true,
  'team-edit': true,
  'team-delete': true,
  'search-create': true,
  'report-view': true,
  'team-contact-edit': true,
  'team-contact-delete': true,
  'team-robot-edit': true,
  'team-robot-delete': true,
  'team-contact-group-edit': true,
  'team-contact-group-delete': true,
  'cell-create': true,
  'cell-edit': true,
  'cell-delete': true,
  'cell-report-view': true,
  'hold-enable': true,
};

const Permissions = ({ authRoleId, tableData, userId, userRoleId, permissions, submitPermissions }) => {
  const [componentPermissions, setComponentPermissions] = useState(permissions);

  const toggleAll = () => {
    if (Object.values(componentPermissions).find((p) => p === true)) {
      submitPermissions(emptyPermissions, userId).then(() => setComponentPermissions(emptyPermissions));
    } else submitPermissions(truePermissions, userId).then(() => setComponentPermissions(truePermissions));
  };

  const data = useMemo(() => {
    const parsedPermissions = {};

    Object.keys(componentPermissions).forEach((key) => {
      const keyArray = key.split('-');
      const pAction = keyArray.pop();
      const pString = keyArray.join('-');
      parsedPermissions[pString] = { ...parsedPermissions[pString], [pAction]: componentPermissions[key] };
    });

    const dataPermissions = [];

    Object.keys(parsedPermissions).forEach((pKey) => {
      const rolePermissionId = PERMISSIONS_DESCRIPTION[pKey].role;
      if (rolePermissionId && rolePermissionId === userRoleId) {
        dataPermissions.push({
          key: pKey,
          permission: PERMISSIONS_DESCRIPTION[pKey]?.name,
          ...parsedPermissions[pKey],
        });
      } else if (rolePermissionId === undefined) {
        dataPermissions.push({
          key: pKey,
          permission: PERMISSIONS_DESCRIPTION[pKey]?.name,
          ...parsedPermissions[pKey],
        });
      }
    });

    return dataPermissions;
  }, [componentPermissions, userRoleId]);

  const columns = [
    {
      title: 'Permission',
      dataIndex: 'permission',
      key: 'permission',
    },
    ...ALL_ACTION_PERMISSIONS.map((permissionAction) => ({
      title: permissionAction.charAt(0).toUpperCase() + permissionAction.slice(1),
      dataIndex: permissionAction,
      key: permissionAction,
      render: (status, render) =>
        status !== undefined && (
          <Switch
            size="small"
            checked={status}
            onClick={(status) =>
              submitPermissions(
                { ...componentPermissions, [`${render.key}-${permissionAction}`]: status },
                userId,
              ).then(() =>
                setComponentPermissions({ ...componentPermissions, [`${render.key}-${permissionAction}`]: status }),
              )
            }
          />
        ),
    })),
  ];

  return (
    <div
      className={`permission-wrapper ${
        authRoleId === 1 && tableData !== 'admin' ? 'permission-wrapper--disabled' : ''
      }`}
    >
      <h3>Permissions edit</h3>
      <Button onClick={toggleAll}>Toggle all permissions</Button>
      <Table
        dataSource={data}
        columns={columns}
        size="small"
        pagination={{ position: ['none', ['none']], defaultPageSize: 100 }}
        className={`permission-table`}
      />
    </div>
  );
};

export default memo(Permissions);
