import { FIRE_ALERT, GET_CELL_REPORT, GET_SENSORS, GET_ALL_SENSORS } from '../../constants/actionTypes';
import api from '../../utils/appApi';

export const getCellReport = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    api.cellReport
      .getCellReport(data)
      .then((res) => {
        dispatch({
          type: GET_CELL_REPORT,
          payload: res?.data,
        });
        return resolve(res?.data);
      })
      .catch((err) => {
        if (err.response) {
          const lat = !err.response.data.errors.lat ? '' : err.response.data.errors.lat[0];
          const long = !err.response.data.errors.lng ? '' : err.response.data.errors.lng[0];
          const imsi = !err.response.data.errors.imsi ? '' : err.response.data.errors.imsi[0];
          const sensorId = !err.response.data.errors.sensor_id ? '' : err.response.data.errors.sensor_id[0];
          const sensorSerial = !err.response.data.errors.sensor_serial ? '' : err.response.data.errors.sensor_serial[0];
          const sensorTechnology = !err.response.data.errors.sensor_technology
            ? ''
            : err.response.data.errors.sensor_technology[0];
          const rsrp = !err.response.data.errors.rsrp ? '' : err.response.data.errors.rsrp[0];
          dispatch({
            type: FIRE_ALERT,
            payload: {
              specificMessage: `${lat} ${long} ${sensorId} ${imsi} ${sensorSerial} ${sensorTechnology} ${rsrp}`,
              message: err.response.data.message,
            },
          });
        }
        reject(err);
      });
  });

export const getSensors = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    api.cellReport
      .getSensors(data)
      .then((res) => {
        dispatch({
          type: GET_SENSORS,
          payload: res.data,
        });
        return resolve(res.data);
      })
      .catch((err) => {
        const lat = !err.response.data.errors.lat ? '' : err.response.data.errors.lat[0];
        const long = !err.response.data.errors.long ? '' : err.response.data.errors.long[0];
        const phone = !err.response.data.errors.phone ? '' : err.response.data.errors.phone[0];
        const imsi = !err.response.data.errors.imsi ? '' : err.response.data.errors.imsi[0];
        const mcc = !err.response.data.errors.mcc ? '' : err.response.data.errors.mcc[0];
        const mnc = !err.response.data.errors.mnc ? '' : err.response.data.errors.mnc[0];
        const lac = !err.response.data.errors.lac ? '' : err.response.data.errors.lac[0];
        const cellId = !err.response.data.errors.cell_id ? '' : err.response.data.errors.cell_id[0];
        dispatch({
          type: FIRE_ALERT,
          payload: {
            specificMessage: `${lat} ${long} ${phone} ${imsi} ${mcc} ${mnc} ${lac} ${cellId}`,
            message: err.response.data.message,
          },
        });
        reject(err);
      });
  });

export const getAllSensors = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    api.cellReport
      .getSensors(data)
      .then((res) => {
        dispatch({
          type: GET_ALL_SENSORS,
          payload: res.data.data,
        });
        return resolve(res.data.data);
      })
      .catch((err) => {
        const lat = !err.response.data.errors.lat ? '' : err.response.data.errors.lat[0];
        const long = !err.response.data.errors.long ? '' : err.response.data.errors.long[0];
        const phone = !err.response.data.errors.phone ? '' : err.response.data.errors.phone[0];
        const imsi = !err.response.data.errors.imsi ? '' : err.response.data.errors.imsi[0];
        const mcc = !err.response.data.errors.mcc ? '' : err.response.data.errors.mcc[0];
        const mnc = !err.response.data.errors.mnc ? '' : err.response.data.errors.mnc[0];
        const lac = !err.response.data.errors.lac ? '' : err.response.data.errors.lac[0];
        const cellId = !err.response.data.errors.cell_id ? '' : err.response.data.errors.cell_id[0];
        dispatch({
          type: FIRE_ALERT,
          payload: {
            specificMessage: `${lat} ${long} ${phone} ${imsi} ${mcc} ${mnc} ${lac} ${cellId}`,
            message: err.response.data.message,
          },
        });
        reject(err);
      });
  });
