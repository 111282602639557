import React from 'react';
import PropTypes from 'prop-types';
import { Switch } from 'react-router-dom';

import Routes from './constants/routesConfig';
import AdminRoute from './components/routes/adminRoute';
import UserRoute from './components/routes/userRoute';
import LoginRoute from './components/routes/loginRoute';
import UserAdminRoute from './components/routes/userAdminRoute';
import PresenceRoute from './components/routes/presenceRoute';

import LoginPage from './containers/LoginPage';
import DashboardPage from './containers/DashboardPage';
import ContactsPage from './containers/ContactsPage';
import GroupContactsPage from './containers/GroupContactsPage';
import GroupViewPage from './containers/GroupViewPage';
import UserProfilePage from './containers/UserProfilePage';
import UserSettingsPage from './containers/UserSettingsPage';
import ContactViewPage from './containers/ContactViewPage';
import RobotsPage from './containers/RobotsPage/robotsPage';
import RobotViewPage from './containers/RobotViewPage/robotViewPage';
import CellDataPage from './containers/CellDataImportPage';
import ReportsPage from './containers/ReportsPage';
import PresencePage from './containers/PresencePage';
import SurveyPage from './containers/SurveyPage';
import NotFoundPage from './containers/NotFoundPage';
import LicensePage from './containers/LicensePage';

const Router = ({ location }) => (
  <Switch>
    <LoginRoute location={location} exact path={Routes.login} component={LoginPage} />
    <UserRoute location={location} exact path={Routes.dashboard} component={DashboardPage} />
    <UserRoute location={location} exact path={Routes.contacts} component={ContactsPage} />
    <UserRoute location={location} exact path={Routes.groups} component={GroupContactsPage} />
    <UserRoute location={location} exact path={Routes.group} component={GroupViewPage} />
    <UserRoute location={location} exact path={Routes.profile} component={UserProfilePage} />
    <AdminRoute location={location} exact path={Routes.settings} component={UserSettingsPage} />
    <AdminRoute location={location} exact path={Routes.cellDataImport} component={CellDataPage} />
    <UserRoute location={location} exact path={Routes.contactView} component={ContactViewPage} />
    <UserRoute location={location} exact path={Routes.robots} component={RobotsPage} />
    <UserRoute location={location} exact path={Routes.robotView} component={RobotViewPage} />
    <UserRoute location={location} exact path={Routes.license} component={LicensePage} />
    <PresenceRoute location={location} exact path={Routes.presence} component={PresencePage} />
    <UserAdminRoute location={location} exact path={Routes.report} component={ReportsPage} />
    <AdminRoute location={location} exact path={Routes.survey} component={SurveyPage} />
    <UserRoute location={location} exact component={NotFoundPage} />
  </Switch>
);

Router.propTypes = {
  location: PropTypes.shape({
    path: PropTypes.string,
  }).isRequired,
};

export default Router;
