import {
  ADD_JOB_TO_LIST,
  GET_JOBS,
  JOB_SEARCH_LOADING,
  SEARCH_DATA_ON_CHANGE,
  UPDATE_JOB_VISIBILITY,
  SORT_BY,
  CLEAR_JOBS,
  UPDATE_JOB_PERCENTAGE_PROGRESS,
} from '../../constants/actionTypes';
import api from '../../utils/appApi';
import { notification } from 'antd';

export const setJobSearchLoading = (status) => (dispatch) =>
  dispatch({
    type: JOB_SEARCH_LOADING,
    payload: status,
  });

export const searchDataOnChange = (data) => (dispatch) =>
  dispatch({
    type: SEARCH_DATA_ON_CHANGE,
    payload: data,
  });

export const getSearchJobs = () => (dispatch) =>
  new Promise((resolve, reject) =>
    api.search
      .getSearchJobs()
      .then((res) => {
        dispatch({
          type: GET_JOBS,
          payload: res.data.data,
        });
        return resolve(res);
      })
      .catch((err) => reject(err)),
  );

export const addJobToList = (data) => (dispatch) =>
  dispatch({
    type: ADD_JOB_TO_LIST,
    payload: data,
  });

export const setJobVisibility = (status, id) => (dispatch) =>
  new Promise((resolve, reject) =>
    api.search
      .setJobVisibility(status, id)
      .then((res) => {
        dispatch({
          type: UPDATE_JOB_VISIBILITY,
          payload: res.data.data,
        });
      })
      .catch((err) => reject(err)),
  );

export const sortJobsBy = (sort) => (dispatch) =>
  dispatch({
    type: SORT_BY,
    payload: sort,
  });

export const clearJobs = (jobs) => (dispatch) =>
  new Promise((resolve, reject) =>
    api.search
      .clearJobs(jobs)
      .then((res) => {
        dispatch({
          type: CLEAR_JOBS,
        });
        return resolve(res.data);
      })
      .catch((err) => {
        notification.error({
          message: 'Error',
          description: 'Locations clear forbidden',
        });
        return reject(err);
      }),
  );

export const updateJobPercentageProgress = (data) => (dispatch) =>
  dispatch({
    type: UPDATE_JOB_PERCENTAGE_PROGRESS,
    payload: data,
  });
