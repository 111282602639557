import {
  SET_TOKEN_TIME,
  GET_APP_DATA,
  TOGGLE_APP_STATUS,
  SEARCH_ON_GOING,
  TOGGLE_DARK_MODE,
  SET_DARK_MODE,
  LOGOUT,
} from '../../constants/actionTypes';

export default function app(state = { searchOnGoing: false, darkMode: false }, action = {}) {
  switch (action.type) {
    case SET_TOKEN_TIME:
      return {
        ...action.payload,
      };
    case GET_APP_DATA:
      return {
        ...action.payload,
      };
    case TOGGLE_APP_STATUS:
      return {
        ...action.payload,
      };
    case SEARCH_ON_GOING:
      return {
        ...state,
        searchOnGoing: action.payload,
      };
    case SET_DARK_MODE:
      return {
        ...state,
        darkMode: action.payload,
      };
    case TOGGLE_DARK_MODE:
      return {
        ...state,
        darkMode: !state.darkMode,
      };
    case LOGOUT:
      return { searchOnGoing: false, darkMode: false };
    default:
      return state;
  }
}
