import sound1 from '../assets/sounds/soft_notification.mp3';
import sound2 from '../assets/sounds/accomplished.mp3';
import sound3 from '../assets/sounds/office-2.mp3';
import sound4 from '../assets/sounds/nasty-error-long.mp3';
import sound5 from '../assets/sounds/dont-think-so.mp3';
import sound6 from '../assets/sounds/gentle-alarm.mp3';
import sound7 from '../assets/sounds/eventually.mp3';
import sound8 from '../assets/sounds/glitch-in-the-matrix.mp3';
import sound9 from '../assets/sounds/arpeggio.mp3';
import sound10 from '../assets/sounds/oringz-w424.mp3';

const VOLUME = 0.2;

export const sounds = {
  1: { id: 1, src: sound1 },
  2: { id: 2, src: sound2 },
  3: { id: 3, src: sound3 },
  4: { id: 4, src: sound4 },
  5: { id: 5, src: sound5 },
  6: { id: 6, src: sound6 },
  7: { id: 7, src: sound7 },
  8: { id: 8, src: sound8 },
  9: { id: 9, src: sound9 },
  10: { id: 10, src: sound10 },
};

export let successAudioNotification = null;

export let errorAudioNotification = null;

export const configSounds = (successId, errorId) => {
  successAudioNotification = new Audio(sounds[successId].src);
  errorAudioNotification = new Audio(sounds[errorId].src);
  successAudioNotification.volume = VOLUME;
  errorAudioNotification.volume = VOLUME;
};
