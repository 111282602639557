import {
  GET_ALL_USERS,
  EDIT_USER,
  EDIT_PERMISSIONS,
  SUSPEND_USER,
  RESTORED_USER,
  CREATE_USER,
  FIRE_ALERT,
  EDIT_ADMIN_CREDITS,
  EDIT_USER_CREDITS,
  DELETE_USER,
} from '../../constants/actionTypes';
import api from '../../utils/appApi';

export const getUsers = () => (dispatch) =>
  api.users.getAllUsers().then((res) =>
    dispatch({
      type: GET_ALL_USERS,
      payload: res.data.data,
    }),
  );

export const editUser = (userId, data) => (dispatch) =>
  api.users
    .editUser(userId, data)
    .then((res) =>
      dispatch({
        type: EDIT_USER,
        payload: res.data.data,
      }),
    )
    .catch((err) => {
      const username = !err.response.data.errors.username ? '' : err.response.data.errors.username[0];
      dispatch({
        type: FIRE_ALERT,
        payload: {
          specificMessage: `${username}`,
          message: err.response.data.message,
        },
      });
      return Promise.reject(err);
    });

export const editUserPermissions = (userId, data) => (dispatch) =>
  api.users.addPermissionsToUser(userId, data).then((res) =>
    dispatch({
      type: EDIT_PERMISSIONS,
      payload: res.data.data,
    }),
  );

export const suspendUser = (userId) => (dispatch) =>
  api.users.deleteUser(userId).then((res) =>
    dispatch({
      type: SUSPEND_USER,
      payload: res.data.data,
    }),
  );

export const restoreUser = (userId) => (dispatch) =>
  api.users.restoreUser(userId).then((res) =>
    dispatch({
      type: RESTORED_USER,
      payload: res.data.data,
    }),
  );

export const createUser = (data) => (dispatch) =>
  new Promise((resolve, reject) =>
    api.users
      .createUser(data)
      .then((res) => {
        dispatch({
          type: CREATE_USER,
          payload: res.data.data,
        });
        return resolve(res.data.data);
      })
      .catch((err) => {
        const username = !err.response.data.errors.username ? '' : err.response.data.errors.username[0];
        const limit = !err.response.data.errors.limit ? '' : err.response.data.errors.limit[0];
        dispatch({
          type: FIRE_ALERT,
          payload: {
            specificMessage: `${username} ${limit}`,
            message: err.response.data.message,
          },
        });
        return reject(err);
      }),
  );

export const editAdminCredits = (adminId, credits) => (dispatch) =>
  api.users
    .editAdminCredits(adminId, credits)
    .then((res) => {
      dispatch({
        type: EDIT_ADMIN_CREDITS,
        payload: res.data.data,
      });
      return Promise.resolve(res.data.data);
    })
    .catch((err) => {
      const allocatedCredits = !err.response.data.errors.allocated_credits
        ? ''
        : err.response.data.errors.allocated_credits[0];
      dispatch({
        type: FIRE_ALERT,
        payload: {
          specificMessage: `${allocatedCredits}`,
          message: err.response.data.message,
        },
      });
      return Promise.reject(err);
    });

export const editUserCredits = (userId, credits) => (dispatch) =>
  api.users
    .editUserCredits(userId, credits)
    .then((res) => {
      dispatch({
        type: EDIT_USER_CREDITS,
        payload: res.data.data,
      });
      return Promise.resolve(res.data.data);
    })
    .catch((err) => {
      const allocatedCredits = !err.response.data.errors.allocated_credits
        ? ''
        : err.response.data.errors.allocated_credits[0];
      const dailyCredits = !err.response.data.errors.daily_credits ? '' : err.response.data.errors.daily_credits[0];
      dispatch({
        type: FIRE_ALERT,
        payload: {
          specificMessage: `${allocatedCredits} ${dailyCredits}`,
          message: err.response.data.message,
        },
      });
      return Promise.reject(err);
    });

export const deleteUser = (userId, data) => (dispatch) =>
  new Promise((resolve, reject) =>
    api.users
      .deleteUserForever(userId, data)
      .then((res) => {
        dispatch({
          type: DELETE_USER,
          payload: res.data.data,
        });
        return resolve(res.data.data);
      })
      .catch((err) => {
        const modeError = !err.response.data.errors.mode ? '' : err.response.data.errors.mode[0];
        dispatch({
          type: FIRE_ALERT,
          payload: {
            specificMessage: `${modeError}`,
            message: err.response.data.message,
          },
        });
        return reject(err);
      }),
  );
