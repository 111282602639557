import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Card, Dimmer, Loader, Button } from 'semantic-ui-react';
import { Popconfirm } from 'antd';

import history from '../../utils/history';
import TimeStamp from '../../utils/timeStamp';
import PermissionHandler from '../../utils/permissionsHandler';
import robotIconType from '../../utils/robotIconType';
import capitalizeFirstCharacter from '../../utils/capitalizeFirstCharacter';

import './robotInfoView.sass';

const statusObject = [
  { status_code: 0, status: 'Inactive', status_color: '#878787' },
  { status_code: 1, status: 'Active running', status_color: '#70cade' },
  { status_code: 2, status: 'Finished success', status_color: '#70c74a' },
  { status_code: 3, status: 'Finished time end', status_color: '#f4a641' },
  { status_code: 4, status: 'Cannot run', status_color: '#f50' },
  { status_code: 5, status: 'Schedule finished', status_color: '#87d068' },
];

class RobotInfoView extends Component {
  state = {};

  goToRobotDetail = () => history.push(`/robots/${this.props.robotData.id}`);
  goToRobotClone = () => history.push(`/robots/${this.props.robotData.id}`, 'isRedirected');

  render() {
    const { robotData } = this.props;
    const status = Object.values(statusObject).map((statusCode) => {
      if (this.props.robotData.status === statusCode.status_code) {
        return (
          <span key={statusCode} style={{ color: `${statusCode.status_color}` }}>
            {statusCode.status}
          </span>
        );
      }
      return null;
    });

    return (
      <Card className="robot-info-view" style={{ height: 315 }}>
        <Dimmer active={this.props.tableLoading} inverted>
          <Loader inverted content="Fetching robots..." />
        </Dimmer>
        <Card.Content>
          {robotIconType(robotData.type, statusObject[robotData.status]?.status_color)}
          <Card.Header className="robot-info-view__header">{robotData.name}</Card.Header>
          <span className="robot-info-view__header-type">{capitalizeFirstCharacter(robotData?.type)}</span>
          <Card.Meta>
            <div className="robot-info-view__info">
              ID: <span className="robot-info-view__info_text">{robotData.id}</span>
            </div>
            <div className="robot-info-view__info">
              Status: <span className="robot-info-view__info_text">{status}</span>
            </div>

            <div className="robot-info-view__info">
              Query type: <span className="robot-info-view__info_text">{robotData.query_type}</span>
            </div>
            {robotData.configuration?.number_of_searches && (
              <div className="robot-info-component__info">
                Nr. Of Searches:{' '}
                <span className="robot-info-component__info_text">
                  {robotData.configuration?.number_of_searches || 0}
                </span>
              </div>
            )}
            {robotData.configuration?.performed_searches >= 0 ? (
              <div className="robot-info-component__info">
                Performed Searches:{' '}
                <span className="robot-info-component__info_text">
                  {robotData.configuration?.performed_searches || 0}
                </span>
              </div>
            ) : null}
            {robotData.configuration?.number_of_searches > 1 && (
              <div className="robot-info-view__info">
                Repeat time:{' '}
                <span className="robot-info-view__info_text">
                  {robotData.repeat_time} <span style={{ opacity: 0.5 }}>(min)</span>
                </span>
              </div>
            )}
            <div className="robot-info-view__info">
              Start Time:{' '}
              <span className="robot-info-view__info_text">
                <TimeStamp date={robotData.start} />
              </span>
            </div>
            {robotData.configuration?.number_of_searches === undefined ? (
              <div className="robot-info-component__info">
                End Time:{' '}
                <span className="robot-info-component__info_text">
                  <TimeStamp date={robotData.end} />
                </span>
              </div>
            ) : null}
          </Card.Meta>
          <div style={{ display: 'flex' }}>
            <PermissionHandler button="robot-delete">
              <div className="robot-info-view__clone_button" floated="right" onClick={this.goToRobotClone}>
                Clone
              </div>
            </PermissionHandler>
            <PermissionHandler button="robot-delete">
              <Popconfirm
                className="robot-info-view__delete"
                title="Are you sure you want to delete this Robot?"
                onConfirm={() => this.props.deleteRobot(robotData.id)}
              >
                <div className="robot-info-view__delete_button">Delete</div>
              </Popconfirm>
            </PermissionHandler>
          </div>
        </Card.Content>
        <Card.Content extra>
          <Button floated="right" onClick={this.goToRobotDetail}>
            Details
          </Button>
        </Card.Content>
      </Card>
    );
  }
}

RobotInfoView.defaultProps = {
  robots: {},
  tableLoading: true,
};
RobotInfoView.propTypes = {
  tableLoading: PropTypes.bool,
  deleteRobot: PropTypes.func.isRequired,
  cloneRobot: PropTypes.func.isRequired,
  robotData: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.number,
    status: PropTypes.number,
    type: PropTypes.string,
    query_type: PropTypes.string,
    description: PropTypes.string,
    updated_at: PropTypes.string,
  }).isRequired,
};
export default RobotInfoView;
