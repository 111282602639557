import React, { memo } from 'react';
import SearchSignal from '../searchSignal';
import styled from 'styled-components';
import { Spin } from 'antd';

const AutoPassiveSearchResult = ({ mols, data, loading, message }) => {
  return (
    <SearchWrapperStyled isLoading={loading}>
      {loading ? (
        <Spin className="spinning" spinning={loading} size="small" />
      ) : (
        <>
          {data ? (
            <>
              <div>
                <div>Country: {data.country ? data.country : 'No data'}</div>
                <div>NetworkBrand: {data.networkBrand ? data.networkBrand : 'No data'}</div>
                <div>Id: {data.networkId ? data.networkId : 'No data'}</div>
                <div>Name: {data.networkName ? data.networkName : 'No data'}</div>
              </div>
              <SearchSignal mols={mols} />
            </>
          ) : (
            <span>{message}</span>
          )}
        </>
      )}
    </SearchWrapperStyled>
  );
};

export const SearchWrapperStyled = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: ${({ isLoading }) => `${isLoading ? 'center' : 'space-between'}`};
  position: absolute;
  z-index: 1;
  width: 100%;
  top: 31px;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  font-size: 12px;
  border-radius: 0 0 5px 5px;
  padding: 10px;
`;
export default memo(AutoPassiveSearchResult);
