import React, { PureComponent } from 'react';
import { DeleteOutlined } from '@ant-design/icons';
import { Tag, Button, Popconfirm } from 'antd';

class ListControls extends PureComponent {
  state = {
    clearLoading: false,
  };

  clearAction = () => {
    const { clearAllJobs } = this.props;
    this.setState({ clearLoading: true }, () => clearAllJobs().then(() => this.setState({ clearLoading: false })));
  };
  render() {
    const { sort, sortOnClick } = this.props;
    return (
      <div className="jobs-list__list-controls">
        <div className="jobs-list__sort-wrapper">
          <span className="jobs-list__sort_label">Sorted by:</span>
          <Tag color={`${sort === 'byTime' ? 'blue' : ''}`} onClick={sortOnClick}>
            Submit time
          </Tag>
          <Tag color={`${sort === 'byUnread' ? 'blue' : ''}`} onClick={sortOnClick}>
            Unread
          </Tag>
          <Tag color={`${sort === 'byFavorite' ? 'blue' : ''}`} onClick={sortOnClick}>
            Favorite
          </Tag>
        </div>
        <Popconfirm
          title="Are you sure you want to delete all queries?"
          onConfirm={this.clearAction}
          okText="Yes"
          cancelText="No"
        >
          <Button className="jobs-list__list-controls_clear" type="default" icon={<DeleteOutlined />} size="small">
            Clear all
          </Button>
        </Popconfirm>
      </div>
    );
  }
}

export default ListControls;
