import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Dropzone from 'react-dropzone';
import { DeleteOutlined, FileOutlined, FolderOutlined, InboxOutlined } from '@ant-design/icons';
import { List, Popconfirm, Button } from 'antd';

import { addFile, getFiles, deleteFile, deleteFolder, deleteCells } from '../../actions/files/files';
import NavigationMenu from '../../components/navigationMenu';
import DropDown from '../../components/addDropDown';
import FilesTable from './filesTable';
import CellTable from './cellTable';

import './cellDataPage.sass';

class CellDataPage extends Component {
  state = {
    uploaded: false,
    percent: 0,
    filesHeight: 0,
    deleteCellsLoading: false,
  };

  componentDidMount() {
    this.props.getFiles();
  }

  onDrop = (file) => {
    const config = {
      onUploadProgress: (progressEvent) => {
        const percent = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        if (percent >= 100) {
          this.setState({ percent: 100 });
        } else {
          this.setState({ percent });
        }
      },
    };

    this.props.addFile(file[0], config);
  };

  deleteCells = () => {
    this.setState({ deleteCellsLoading: true }, () => {
      this.props.deleteCells().then(() => this.setState({ deleteCellsLoading: false }));
    });
  };

  render() {
    const dataFiles = this.props?.files?.data || [];

    const filesHeight = () => {
      const foldersCount = this.props.files?.folders.length;
      const filesCount = this.props.files?.files.length;
      let maxCount = foldersCount >= filesCount ? foldersCount : filesCount;
      if (foldersCount === 0 && filesCount === 0) maxCount = 1.5;
      return 150 + 40 * maxCount;
    };
    return (
      <div>
        <NavigationMenu statusPage="cell-data" />
        <div className={`cell-data-page ${this.props.user.sticky_header ? '--fixed' : ''}`}>
          <DropDown
            className="cell-data-page__drop-down"
            createSuccess={this.state.uploaded}
            buttonName="Upload Cell data"
            height={190}
          >
            <Dropzone
              accept="application/zip, application/octet-stream, application/x-zip-compressed, multipart/x-zip"
              multiple={false}
              onDrop={this.onDrop}
              className="cell-data-page__drop-zone"
            >
              <div className="cell-data-page__drop-zone_wrapper">
                <p className="cell-data-page__drop-zone_icon">
                  <InboxOutlined />
                </p>
                <p className="cell-data-page__drop-zone_text">Click or drag file (only one) to this area to upload</p>
                <p className="cell-data-page__drop-zone_hint">Support only for ZIP files</p>
                <div
                  className="cell-data-page__drop-zone_percent"
                  style={{ opacity: this.state.percent === 0 ? 0 : 1 }}
                >
                  <div className="cell-data-page__drop-zone_percent-count" style={{ width: `${this.state.percent}%` }}>
                    <div
                      className="cell-data-page__drop-zone_percent-done"
                      style={{ opacity: this.state.percent === 100 ? 1 : 0 }}
                    >
                      Successfully Uploaded
                    </div>
                  </div>
                </div>
              </div>
            </Dropzone>
          </DropDown>
          <div className="cell-data-page__table-files">
            <div className="cell-data-page__table-files_name">File Imports</div>
            <FilesTable dataFiles={dataFiles || []} />
          </div>
          <div className="cell-data-page__files-system">
            <DropDown
              className="cell-data-page__drop-down"
              createSuccess={this.state.uploaded}
              buttonName="Files"
              height={this.props.files.folders && filesHeight()}
            >
              <div className="cell-data-page__files-system_wrapper">
                <List
                  className="cell-data-page__files-system_table-folders"
                  size="small"
                  header={<div>Folders</div>}
                  bordered
                  dataSource={this.props.files.folders}
                  renderItem={(item) => (
                    <List.Item>
                      <span style={{ marginRight: 5 }}>
                        <FolderOutlined />
                      </span>
                      <span style={{ marginRight: 30 }}>{item}</span>
                      <span>
                        <DeleteOutlined
                          className="cell-data-page__files-system_delete"
                          onClick={() => this.props.deleteFolder(item)}
                        />
                      </span>
                    </List.Item>
                  )}
                />
                <List
                  className="cell-data-page__files-system_table-files"
                  size="small"
                  header={<div>Files</div>}
                  bordered
                  dataSource={this.props.files.files}
                  renderItem={(name) => (
                    <List.Item>
                      <span style={{ marginRight: 5 }}>
                        <FileOutlined />
                      </span>
                      <span style={{ marginRight: 30 }}>{name}</span>
                      <span>
                        <DeleteOutlined
                          className="cell-data-page__files-system_delete"
                          onClick={() => this.props.deleteFile(name)}
                        />
                      </span>
                    </List.Item>
                  )}
                />
              </div>
            </DropDown>
          </div>
          <div className="cell-data-page__table-cell">
            <div className="cell-data-page__table-cell_name">Cells</div>
            <CellTable />
          </div>
          <div>
            {this.props.user.role_id === 1 && (
              <Popconfirm
                title="Are you sure you want to delete all cells?"
                okText="Yes"
                cancelText="No"
                onConfirm={this.deleteCells}
              >
                <Button loading={this.state.deleteCellsLoading} type="danger" size="default">
                  Delete all Cells
                </Button>
              </Popconfirm>
            )}
          </div>
        </div>
      </div>
    );
  }
}

CellDataPage.defaultProps = {
  files: {},
  user: {},
  cells: { data: {} },
};

CellDataPage.propTypes = {
  addFile: PropTypes.func.isRequired,
  getFiles: PropTypes.func.isRequired,
  deleteFile: PropTypes.func.isRequired,
  deleteFolder: PropTypes.func.isRequired,
  deleteCells: PropTypes.func.isRequired,
  files: PropTypes.shape({
    files: PropTypes.array,
    folders: PropTypes.array,
  }),
  user: PropTypes.shape({
    role_id: PropTypes.number,
    sticky_header: PropTypes.number,
  }),
};

const mapStateToProps = (store) => ({
  files: store.files?.files,
  user: store.user,
});

export default connect(mapStateToProps, {
  addFile,
  getFiles,
  deleteFile,
  deleteFolder,
  deleteCells,
})(CellDataPage);
