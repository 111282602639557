import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { Menu, Dropdown, Icon } from 'semantic-ui-react';
import { Popover, Button, Modal, Tooltip, Popconfirm, Empty } from 'antd';

import ClockWall from './ClockWall';

import { logout, getUser } from '../../actions/user/auth';
import {
  markAsSeenNotification,
  deleteNotification,
  markAllNotificationAsSeen,
  deleteAllNotifications,
} from '../../actions/notification/notification';
import { switchTeam } from '../../actions/teams/teams';
import history from '../../utils/history';
import PermissionHandler from '../../utils/permissionsHandler';

import Logo from '../../assets/images/Logo Cyber.png';
import image from '../../assets/images/mapLogo.png';

import './navigationMenu.sass';
import TimeStamp from '../../utils/timeStamp';

class NavigationMenu extends PureComponent {
  state = {
    activeItem: '',
    tagColor: 'green',
    logo: false,
    modalVisible: false,
    lastPathClick: '/',
  };

  UNSAFE_componentWillMount() {
    const { statusPage } = this.props;
    if (statusPage) {
      this.setState({ activeItem: statusPage });
    }
    this.props.getUser();
  }

  handleItemClick = (e, { name }) => {
    if (!this.props.searchOnGoing) {
      this.setState({ activeItem: name });
      history.push(name);
    } else {
      this.setState({ modalVisible: true, lastPathClick: name });
    }
  };

  notificationClick = (robotId, notificationId) => {
    this.props.markAsSeenNotification(notificationId).then(() => history.push(`/robots/${robotId}`));
  };

  handleModalOk = () => history.push(this.state.lastPathClick);
  handleModalCancel = () => this.setState({ modalVisible: false, lastPathClick: '/' });

  goTo = (link) => history.push(link);

  deleteAllNotifications = () => this.props.deleteAllNotifications();
  markAllSeen = () => this.props.markAllNotificationAsSeen();

  render() {
    if (!this.props.user.username) return null;
    const { activeItem } = this.state;

    const trigger = (
      <span className="navigation-menu__user-icon">
        <Icon name="user" />
        {this.props.user.username}
      </span>
    );

    let number = 0;
    if (this.props.user?.notifications?.data) {
      Object.values(this.props.user.notifications.data).forEach((notif) => {
        if (notif.read_at === null) number += 1;
      });
    }

    const notification = (
      <span className="navigation-menu__notification-icon">
        <Icon style={{ color: number > 0 && 'green' }} name="alarm" />
        {number}
      </span>
    );

    const notificationsList =
      this.props.user?.notifications?.data &&
      Object.values(this.props.user.notifications.data).length > 0 &&
      Object.values(this.props.user.notifications.data).map((note) => {
        const key = `notif-${note.id}`;
        return note?.data?.result_data ? (
          <Dropdown.Item
            className="drop-down-item"
            key={key}
            style={note.data.teamId !== this.props.user.team_id ? { cursor: 'no-drop' } : {}}
          >
            <div className="drop-down-item_delete">
              <Icon name="delete" onClick={() => this.props.deleteNotification(note.id)} />
            </div>
            <Tooltip
              overlayStyle={note.data.teamId === this.props.user.team_id ? { display: 'none' } : {}}
              placement="left"
              title={
                <div>
                  Please switch to{' '}
                  {note.data.teamId ? (
                    <span>
                      <span style={{ fontStyle: 'italic', fontWeight: 'bold' }}>
                        {' '}
                        &apos;{this.props.user?.teams?.data[note.data.teamId].name}&apos;
                      </span>{' '}
                      team
                    </span>
                  ) : (
                    <span style={{ fontStyle: 'italic', fontWeight: 'bold' }}>Personal view</span>
                  )}{' '}
                  to see the result
                </div>
              }
            >
              <div
                className="navigation-menu__notification"
                onClick={() =>
                  note.data.teamId === this.props.user.team_id && this.notificationClick(note.data.robot_id, note.id)
                }
                role="button"
                tabIndex={0}
              >
                {note.read_at === null ? (
                  <div className="navigation-menu__notification_status --new">new</div>
                ) : (
                  <div className="navigation-menu__notification_status --seen">seen</div>
                )}
                <div className="navigation-menu__notification_name">
                  <span className="navigation-menu__notification_name --semi-bold">Robot: </span>
                  <span className="navigation-menu__notification_name --regular">{note.data.robot_name}</span>
                </div>
                <div className="navigation-menu__notification_info">
                  {note.data.result_data.result === 'success' && (
                    <div className="navigation-menu__notification_info__collection">
                      <div className="navigation-menu__notification_info__collection_status --green">
                        {note.data.result_data.message.status}
                      </div>
                      <div className="navigation-menu__notification_info__collection_explanation">
                        {note.data.result_data.message.explanation}
                      </div>
                    </div>
                  )}
                  {note.data.result_data.result === 'fail' && (
                    <div className="navigation-menu__notification_info__collection">
                      <div className="navigation-menu__notification_info__collection_status  --red">
                        {note.data.result_data.message.status}
                      </div>
                      <div className="navigation-menu__notification_info__collection_explanation">
                        {note.data.result_data.message.explanation}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </Tooltip>
          </Dropdown.Item>
        ) : (
          <Dropdown.Item
            className="drop-down-item"
            key={key}
            style={note.data?.teamId !== this.props.user?.team_id ? { cursor: 'no-drop' } : {}}
          >
            <div className="drop-down-item_delete">
              <Icon name="delete" onClick={() => this.props.deleteNotification(note.id)} />
            </div>
            <Tooltip
              overlayStyle={note.data?.teamId === this.props.user?.team_id ? { display: 'none' } : {}}
              placement="left"
              title={
                <div>
                  Please switch to{' '}
                  {note.data?.teamId ? (
                    <span>
                      <span style={{ fontStyle: 'italic', fontWeight: 'bold' }}>
                        {' '}
                        &apos;{this.props.user?.teams?.data[note.data?.teamId].name}&apos;
                      </span>{' '}
                      team
                    </span>
                  ) : (
                    <span style={{ fontStyle: 'italic', fontWeight: 'bold' }}>Personal view</span>
                  )}{' '}
                  to see the result
                </div>
              }
            >
              <div
                className="navigation-menu__notification"
                onClick={() =>
                  note.data?.teamId === this.props.user.team_id && this.notificationClick(note.data?.robotId, note.id)
                }
                role="button"
                tabIndex={0}
              >
                {note.read_at === null ? (
                  <div className="navigation-menu__notification_status --new">new</div>
                ) : (
                  <div className="navigation-menu__notification_status --seen">seen</div>
                )}
                <div className="navigation-menu__notification_name">
                  <span className="navigation-menu__notification_name --semi-bold">Robot: </span>
                  <span className="navigation-menu__notification_name --regular">{note.data?.robotName}</span>
                </div>
                <div className="navigation-menu__notification_info">
                  {note.data?.result === 'success' && (
                    <div className="navigation-menu__notification_info__collection">
                      <div className="navigation-menu__notification_info__collection_status --green">
                        {note.data?.status}
                      </div>
                      <div className="navigation-menu__notification_info__collection_explanation">
                        {note.data?.explanation}
                      </div>
                    </div>
                  )}
                  {note.data?.result === 'fail' && (
                    <div className="navigation-menu__notification_info__collection">
                      <div className="navigation-menu__notification_info__collection_status  --red">
                        {note.data?.status}
                      </div>
                      <div className="navigation-menu__notification_info__collection_explanation">
                        {note.data?.explanation}
                      </div>
                    </div>
                  )}
                  <div className="navigation-menu__notification_info">
                    <div className="navigation-menu__notification_info__collection_explanation">
                      Updated at: <TimeStamp date={note.updated_at} />
                    </div>
                  </div>
                </div>
              </div>
            </Tooltip>
          </Dropdown.Item>
        );
      });

    const teams = (
      <div className="team-text">
        <span style={{ lineHeight: '20px' }}>
          <Icon name="users" />
          {this.props.user.team && this.props.user.team.name ? this.props.user.team.name : 'Personal view'}
        </span>
      </div>
    );
    const teamsList =
      this.props.user.teams?.data && Object.values(this.props.user.teams?.data).length > 0
        ? Object.values(this.props.user?.teams?.data).map((team) => {
            const key = `team-${team.id}`;
            const disabled = this.props.user.team && this.props.user.team.id === team.id;
            return (
              <Dropdown.Item disabled={disabled} key={key} className="drop-down-item">
                <div style={{ cursor: 'auto' }}>
                  {team.name}
                  <span>
                    <Button
                      className="drop-down-item_button"
                      size="small"
                      type="primary"
                      ghost
                      onClick={() => this.props.switchTeam(team.id)}
                    >
                      Switch
                    </Button>
                  </span>
                </div>
              </Dropdown.Item>
            );
          })
        : [];

    let creditsUser = '10 / 100 / 1';
    let creditsAdmin = '100 / 15';
    if (this.props.user.day_credit_row !== null) {
      creditsUser = `
      ${this.props.user.day_credit_row.used_credits === null ? '∞' : this.props.user.day_credit_row.used_credits} / 
      ${this.props.user.allocated_credits === null ? '∞' : this.props.user.allocated_credits} / 
      ${this.props.user.daily_credits === null ? '∞' : this.props.user.daily_credits}`;

      creditsAdmin = `
      ${this.props.user.day_credit_row.used_credits === null ? '∞' : this.props.user.day_credit_row.used_credits} / 
      ${this.props.user.allocated_credits === null ? '∞' : this.props.user.allocated_credits}`;
    }
    return (
      <div className={`navigation-menu${this.props.user.sticky_header ? ' --fixed' : ''}`}>
        <Modal
          title="A Search Is Ongoing"
          visible={this.state.modalVisible}
          onOk={this.handleModalOk}
          onCancel={this.handleModalCancel}
          okText="Go"
        >
          <p>There is a search being processed. If you leave this page, the result will be lost.</p>
        </Modal>
        <Menu>
          <Menu.Item
            onClick={this.handleItemClick}
            name="/"
            style={{ display: 'flex', flexWrap: 'wrap', position: 'relative' }}
          >
            <img
              alt="M2MAP"
              className="navigation-menu__logo"
              style={{ width: this.state.logo ? '6.8em' : '1.8em' }}
              name="dashboard"
              src={this.state.logo ? Logo : image}
            />
          </Menu.Item>
          {/*<Menu.Item name="/" active={activeItem === '/' || activeItem === 'dashboard'} onClick={this.handleItemClick}>*/}
          {/*  Dashboard*/}
          {/*</Menu.Item>*/}

          <Menu.Item
            name="/"
            active={activeItem === '/' || activeItem === 'dashboard-new'}
            onClick={this.handleItemClick}
          >
            Dashboard
          </Menu.Item>

          <Menu.Item
            name="/contacts"
            active={activeItem === 'contacts' || activeItem === '/contacts' || activeItem === 'contact-view'}
            onClick={this.handleItemClick}
          >
            Contacts
          </Menu.Item>

          <Menu.Item
            name="/groups"
            active={activeItem === 'groups' || activeItem === '/groups'}
            onClick={this.handleItemClick}
          >
            Contacts Group
          </Menu.Item>

          <Menu.Item
            name="/robots"
            active={activeItem === 'robots' || activeItem === '/robots'}
            onClick={this.handleItemClick}
          >
            Robots
          </Menu.Item>

          <Menu.Menu position="right">
            {this.props?.serviceStatus?.response?.data ? (
              <Popover
                content={
                  <div>
                    <div>
                      <span style={{ fontWeight: 'bold' }}>Api Operational: </span>
                      <span>{this.props?.serviceStatus?.response?.data?.apiOperational ? 'Yes' : 'No'}</span>
                    </div>
                    <div>
                      <span style={{ fontWeight: 'bold' }}>Service Status: </span>
                      <span>{this.props?.serviceStatus?.response?.data?.serviceStatus}</span>
                    </div>
                  </div>
                }
                title="Service Status"
              >
                <Menu.Item>
                  <div
                    id="status-indicator-dot"
                    style={{
                      width: '10px',
                      height: '10px',
                      borderRadius: '50%',
                      background: this.props?.serviceStatus.response.data?.statusColour,
                    }}
                  />
                </Menu.Item>
              </Popover>
            ) : null}
            <Dropdown
              simple
              item
              closeOnChange={false}
              trigger={notification}
              className="navigation-menu__user-drop-down"
            >
              <Dropdown.Menu>
                <div className="drop-down-actions">
                  <Button
                    style={{ marginRight: 10 }}
                    size="small"
                    onClick={this.markAllSeen}
                    disabled={!notificationsList || notificationsList?.length === 0}
                  >
                    Mark all as seen
                  </Button>
                  <Popconfirm
                    placement="bottom"
                    title="Are you sure to delete all notifications?"
                    onConfirm={this.deleteAllNotifications}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button size="small" type="danger" disabled={!notificationsList || notificationsList?.length === 0}>
                      Delete all
                    </Button>
                  </Popconfirm>
                </div>
                <div className="drop-down-notifications">
                  {notificationsList?.length > 0 ? notificationsList : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                </div>
              </Dropdown.Menu>
            </Dropdown>

            <Dropdown simple item trigger={trigger} className="navigation-menu__user-drop-down">
              <Dropdown.Menu>
                <Dropdown.Item onClick={() => this.goTo('/profile')}>Profile</Dropdown.Item>
                {this.props.user.role_id <= 2 && (
                  <Dropdown.Item onClick={() => this.goTo('/settings')}>Settings</Dropdown.Item>
                )}
                {this.props.user.role_id <= 2 && (
                  <Dropdown.Item onClick={() => this.goTo('/cell-data-import')}>Cell Import</Dropdown.Item>
                )}
                {this.props.user.role_id >= 2 && (
                  <PermissionHandler button="report-view">
                    <Dropdown.Item onClick={() => this.goTo('/report')}>Report</Dropdown.Item>
                  </PermissionHandler>
                )}
                {this.props.user.role_id >= 2 && (
                  <PermissionHandler button="cell-report-view">
                    <Dropdown.Item onClick={() => this.goTo('/presence')}>Presence</Dropdown.Item>
                  </PermissionHandler>
                )}
                {this.props.user.role_id <= 2 && (
                  <PermissionHandler button="cell-report-view">
                    <Dropdown.Item onClick={() => this.goTo('/survey')}>Survey</Dropdown.Item>
                  </PermissionHandler>
                )}
              </Dropdown.Menu>
            </Dropdown>

            {this.props.user.role_id === 3 && (
              <Dropdown simple item closeOnChange={false} trigger={teams} className="navigation-menu__team">
                <Dropdown.Menu>
                  <Dropdown.Item disabled={!this.props.user.team} key="team-personal-1" className="drop-down-item">
                    <div style={{ cursor: 'auto' }}>
                      Personal view
                      <span>
                        <Button
                          className="drop-down-item_button"
                          size="small"
                          type="primary"
                          ghost
                          onClick={() => this.props.switchTeam(0)}
                        >
                          Switch
                        </Button>
                      </span>
                    </div>
                  </Dropdown.Item>
                  {teamsList}
                </Dropdown.Menu>
              </Dropdown>
            )}

            {this.props.user.role_id > 2 && (
              <Popover
                title="Credits"
                trigger="hover"
                content={
                  <div className="navigation-menu__popup-credits">
                    <div className="navigation-menu__popup-credits_block">
                      <span className="navigation-menu__popup-credits_title">Used Credits:</span>
                      <span className="navigation-menu__popup-credits_number">
                        {this.props.user &&
                          this.props.user.day_credit_row !== null &&
                          (this.props.user.day_credit_row.used_credits === null
                            ? '∞'
                            : this.props.user.day_credit_row.used_credits)}
                      </span>
                    </div>
                    <div className="navigation-menu__popup-credits_block">
                      <span className="navigation-menu__popup-credits_title">Allocated Credits:</span>
                      <span className="navigation-menu__popup-credits_number">
                        {this.props.user && this.props.user.allocated_credits === null
                          ? '∞'
                          : this.props.user.allocated_credits}
                      </span>
                    </div>
                    <div className="navigation-menu__popup-credits_block">
                      <span className="navigation-menu__popup-credits_title">Daily Credit Limit:</span>
                      <span className="navigation-menu__popup-credits_number">
                        {this.props.user && this.props.user.daily_credits === null
                          ? '∞'
                          : this.props.user.daily_credits}
                      </span>
                    </div>
                  </div>
                }
              >
                <Menu.Item>
                  <div className="navigation-menu__credits">{creditsUser}</div>
                </Menu.Item>
              </Popover>
            )}

            {this.props.user.role_id === 2 && (
              <Popover
                title="Credits"
                trigger="hover"
                content={
                  <div className="navigation-menu__popup-credits">
                    <div className="navigation-menu__popup-credits_block">
                      <span className="navigation-menu__popup-credits_title">Used Credits:</span>
                      <span className="navigation-menu__popup-credits_number">
                        {this.props.user.day_credit_row !== null && this.props.user.day_credit_row.used_credits}
                      </span>
                    </div>
                    <div className="navigation-menu__popup-credits_block">
                      <span className="navigation-menu__popup-credits_title">Allocated Credits:</span>
                      <span className="navigation-menu__popup-credits_number">
                        {this.props.user && this.props.user.allocated_credits === null
                          ? '∞'
                          : this.props.user.allocated_credits}
                      </span>
                    </div>
                  </div>
                }
              >
                <Menu.Item>
                  <div className="navigation-menu__credits">{creditsAdmin}</div>
                </Menu.Item>
              </Popover>
            )}

            <Menu.Item>
              <ClockWall />
            </Menu.Item>

            <Menu.Item name="logout" active={activeItem === 'logout'} onClick={() => this.props.logout()}>
              Log Out
            </Menu.Item>
          </Menu.Menu>
          {/*
            <div className="navigation-menu__watermark">
            <span>Training</span><span>Training</span><span>Training</span><span>Training</span>
            <span>Training</span><span>Training</span><span>Training</span><span>Training</span>
            <span>Training</span><span>Training</span><span>Training</span><span>Training</span>
            <span>Training</span><span>Training</span><span>Training</span><span>Training</span>
            <span>Training</span><span>Training</span><span>Training</span><span>Training</span>
          </div>
          */}
        </Menu>
      </div>
    );
  }
}

NavigationMenu.propTypes = {
  statusPage: PropTypes.string.isRequired,
  logout: PropTypes.func.isRequired,
  getUser: PropTypes.func.isRequired,
  markAsSeenNotification: PropTypes.func.isRequired,
  deleteNotification: PropTypes.func.isRequired,
  switchTeam: PropTypes.func.isRequired,
  markAllNotificationAsSeen: PropTypes.func.isRequired,
  deleteAllNotifications: PropTypes.func.isRequired,
  user: PropTypes.shape({
    username: PropTypes.string,
    notifications: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    role_id: PropTypes.number,
    daily_credits: PropTypes.number,
    allocated_credits: PropTypes.number,
    day_credit_row: PropTypes.shape({
      used_credits: PropTypes.number,
    }),
    sticky_header: PropTypes.number,
    team: PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.number,
    }),
    team_id: PropTypes.number,
    teams: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  }).isRequired,
  searchOnGoing: PropTypes.bool.isRequired,
  serviceStatus: PropTypes.shape({
    response: PropTypes.shape({
      data: PropTypes.shape({
        statusColour: PropTypes.string,
        apiOperational: PropTypes.bool,
        serviceStatus: PropTypes.string,
      }),
    }),
  }),
};

const mapStateToProps = (store) => ({
  user: store.user,
  searchOnGoing: store.app.searchOnGoing,
  serviceStatus: store.serviceStatus,
});

export default connect(mapStateToProps, {
  logout,
  getUser,
  markAsSeenNotification,
  deleteNotification,
  switchTeam,
  markAllNotificationAsSeen,
  deleteAllNotifications,
})(NavigationMenu);
