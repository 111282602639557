import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Tabs, Select, DatePicker, InputNumber, Input, Checkbox, Popconfirm } from 'antd';
import { Button } from 'semantic-ui-react';

import moment from 'moment-timezone';

import countrys from '../../utils/country';
import { robotTypes } from '../../containers/RobotsPage/robotsPage';
import NewMap from '../newMap/newMap';
import checkForNumber from '../../utils/checkForNumber';

import './robotCreation.sass';

const TabPane = Tabs.TabPane;
const Option = Select.Option;
const { RangePicker } = DatePicker;
const { TextArea } = Input;

class RobotComponent extends Component {
  state = {
    timeToShow: [],
    scheduleTime: undefined,
    robotOnline: {
      source: 'contact',
      source_mode: 'primary',
      source_id: '',
      terminals: [],
      type: 'tracker',
      status: 1,
      query_type: 'passive',
      start: null,
      end: null,
      schedule_time: null,
      number_of_searches: 1,
      repeat_time: 60,
      description: '',
      name: '',
      country_code: '',
      country_condition: '',
      lat: '',
      long: '',
      distance: 100,
      target_id: '',
      group: 1,
      points: [],
      alert_stop: 1,
      condition: '',
    },
    typeOfSearch: 'standard',
    savedPoints: [],
    showMarker: true,
    country: '',
    loading: false,
    populated: false,
    groupCount: null,
    initialGroupCount: 1,
  };

  componentDidMount() {
    moment.tz.setDefault(this.props.timeZone);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.populateData.data === true &&
      nextProps.populateData.from === 'contact' &&
      nextProps.populateData.id !== this.state.robotOnline.source_id &&
      this.state.populated === false
    ) {
      this.setState({
        populated: true,
        robotOnline: {
          ...this.state.robotOnline,
          source_id: `${nextProps.populateData.id}`,
        },
      });
    } else if (
      nextProps.populateData.data === true &&
      nextProps.populateData.from === 'group' &&
      nextProps.populateData.id !== this.state.robotOnline.source_id &&
      this.state.populated === false
    ) {
      this.setState({
        populated: true,
        robotOnline: {
          ...this.state.robotOnline,
          source: 'contact-group',
          source_id: `${nextProps.populateData.id}`,
        },
      });
    }
  }

  callback = (key) =>
    this.setState({
      robotOnline: {
        ...this.state.robotOnline,
        type: key,
        lat: '',
        long: '',
        points: [],
      },
      showMarker: false,
      savedPoints: [],
    });

  handleChangeName = (value) => this.setState({ robotOnline: { ...this.state.robotOnline, name: value } });

  handleChangeSource = (value) => {
    if (value === 'contact-group' && this.state.source_mode === 'manual') this.setState({ source_mode: 'primary' });
    this.setState({ robotOnline: { ...this.state.robotOnline, source: value, source_id: '', terminals: [] } });
  };

  handleChangeSourceMode = (value) => {
    if (this.state.robotOnline.source === 'contact' && this.state.robotOnline.source_id !== '') {
      this.props.getContactTerminals(this.state.robotOnline.source_id);
    }
    this.setState({
      robotOnline: {
        ...this.state.robotOnline,
        source_mode: value,
        terminals: [],
      },
    });
  };

  handleChangeCondition = (value) => this.setState({ robotOnline: { ...this.state.robotOnline, condition: value } });

  handleChangeSourceId = (value) => {
    this.setState(
      {
        robotOnline: { ...this.state.robotOnline, source_id: value, terminals: [] },
      },
      () => {
        if (this.state.robotOnline.source === 'contact-group') {
          this.setState({
            groupCount: this.props.groups[value].contact_count,
            robotOnline: { ...this.state.robotOnline, group: this.props.groups[value].contact_count },
            initialGroupCount: this.props.groups[value].contact_count || 1,
          });
        }
      },
    );
    if (this.state.robotOnline.source === 'contact') {
      this.props.getContactTerminals(value);
    }
  };

  handleChangeTerminals = (value) => {
    this.setState({ robotOnline: { ...this.state.robotOnline, terminals: value } });
    if (value.length > 3) {
      const amount = (value.length - 3) * 13;
      this.props.expandDropDown(amount);
    }
  };

  handleChangeQueryType = (value) => this.setState({ robotOnline: { ...this.state.robotOnline, query_type: value } });

  handleChangeTime = (times) => {
    if (times) {
      this.setState({
        timeToShow: [times[0], times[1]],
        robotOnline: {
          ...this.state.robotOnline,
          start: moment(times[0]).utc(false).format('YYYY-MM-DD HH:mm'),
          end: moment(times[1]).utc(false).format('YYYY-MM-DD HH:mm'),
        },
      });
    } else {
      this.setState({
        timeToShow: [],
        robotOnline: {
          ...this.state.robotOnline,
          start: null,
          end: null,
        },
      });
    }
  };

  handleScheduleTimeChange = (time) => {
    if (time) {
      this.setState({
        scheduleTime: time,
        robotOnline: {
          ...this.state.robotOnline,
          start: moment(time).utc(false).format('YYYY-MM-DD HH:mm'),
        },
      });
    } else {
      this.setState({
        scheduleTime: null,
        robotOnline: {
          ...this.state.robotOnline,
          start: null,
        },
      });
    }
  };

  handleChangeRepeatTime = (value) => this.setState({ robotOnline: { ...this.state.robotOnline, repeat_time: value } });
  handleChangeNumberOfChanges = (value) =>
    this.setState({ robotOnline: { ...this.state.robotOnline, number_of_searches: value } });
  handleChangeDescription = (value) =>
    this.setState({ robotOnline: { ...this.state.robotOnline, description: value } });

  handleChangeCountry = (country, iso) =>
    this.setState({ country, robotOnline: { ...this.state.robotOnline, country_code: iso } });

  handleChangeCountryCondition = (value) =>
    this.setState({ robotOnline: { ...this.state.robotOnline, country_condition: value } });

  handleChangeLat = (value) => this.setState({ robotOnline: { ...this.state.robotOnline, lat: value } });

  handleChangeLng = (value) => this.setState({ robotOnline: { ...this.state.robotOnline, long: value } });

  handleChangeDistance = (value) => this.setState({ robotOnline: { ...this.state.robotOnline, distance: value } });

  handleChangeTargetId = (value) => this.setState({ robotOnline: { ...this.state.robotOnline, target_id: value } });

  handleChangeGroup = (value) => this.setState({ robotOnline: { ...this.state.robotOnline, group: value } });

  createRobot = () => {
    this.setState({ loading: true });
    const data = {
      ...this.state.robotOnline,
      country_code: this.state.robotOnline.country_code === 'ge2' ? 'ge' : this.state.robotOnline.country_code,
    };

    if (this.state.typeOfSearch === 'standard') {
      delete data.number_of_searches;
    } else {
      delete data.end;
    }

    this.props
      .createRobot(data)
      .then(
        () =>
          this.setState({
            loading: false,
            savedPoints: [],
            scheduleTime: undefined,
            timeToShow: [],
            robotOnline: {
              source: 'contact',
              source_mode: 'primary',
              source_id: '',
              terminals: [],
              type: this.state.robotOnline.type,
              status: 1,
              query_type: 'passive',
              start: moment(this.state.timeToShow[0]).utc(0).format('YYYY-MM-DD HH:mm'),
              end: moment(this.state.timeToShow[1]).utc(0).format('YYYY-MM-DD HH:mm'),
              search_time: moment(this.state.scheduleTime).utc(0).format('YYYY-MM-DD HH:mm'),
              number_of_searches: 1,
              repeat_time: 60,
              description: '',
              name: '',
              country_code: this.state.robotOnline.country_code,
              country_condition: this.state.robotOnline.country_condition,
              lat: this.state.robotOnline.lat,
              long: this.state.robotOnline.long,
              distance: 100,
              target_id: '',
              points: [],
              group: 1,
              alert_stop: 1,
              condition: '',
            },
          }),
        this.props.setVisibility(false),
      )
      .catch(() => this.setState({ loading: false }));
  };

  savePoint = () => {
    const point = {
      lat: parseFloat(this.state.robotOnline.lat),
      long: parseFloat(this.state.robotOnline.long),
      distance: this.state.robotOnline.distance,
    };
    const savedPoints = this.state.savedPoints;
    savedPoints.push(point);

    const points = this.state.robotOnline.points;
    const keyPoint = {};

    keyPoint.lat = parseFloat(this.state.robotOnline.lat);
    keyPoint.long = parseFloat(this.state.robotOnline.long);
    keyPoint.distance = this.state.robotOnline.distance;
    points.push(keyPoint);

    this.setState({
      robotOnline: {
        ...this.state.robotOnline,
        lat: '',
        long: '',
        distance: 100,
        points,
      },
      showMarker: false,
      savedPoints,
    });
  };

  removePoint = (index) => {
    const savedPoints = this.state.savedPoints;
    savedPoints.splice(index, 1);
    this.setState({
      robotOnline: {
        ...this.state.robotOnline,
      },
      savedPoints,
    });
  };

  getLatLng = (lat, lng) =>
    this.setState({ robotOnline: { ...this.state.robotOnline, lat, long: lng }, showMarker: true });

  searchTypeOnChange = (type) =>
    this.setState({
      typeOfSearch: type,
    });

  render() {
    const { robotOnline } = this.state;
    const contacts =
      this.props.contacts &&
      Object.values(this.props.contacts).length > 0 &&
      Object.values(this.props.contacts).map((contact) => {
        const key = `contact-${contact.id}`;
        return (
          <Option key={key} value={`${contact.id}`}>
            {contact.name}
          </Option>
        );
      });

    const groups =
      this.props.groups &&
      Object.values(this.props.groups).length > 0 &&
      Object.values(this.props.groups).map((group) => {
        const key = `group-${group.id}`;
        return (
          <Option key={key} value={`${group.id}`}>
            {group.name}
          </Option>
        );
      });

    const terminalDisabled = robotOnline.source_mode === 'manual' && robotOnline.source_id !== '';
    let terminals = [];
    if (robotOnline.source === 'contact' && robotOnline.source_id !== '') {
      terminals =
        this.props.terminals.length > 0 &&
        this.props.terminals.map((terminal) => {
          let info = '';
          if (terminal.phone && terminal.imsi) info = `Phone: ${terminal.phone}, IMSI: ${terminal.imsi}`;
          else if (terminal.phone && !terminal.imsi) info = `Phone: ${terminal.phone}`;
          else if (terminal.imsi && !terminal.phone) info = `IMSI: ${terminal.imsi}`;
          return (
            <Option key={terminal.id} value={`${terminal.id}`}>
              {info}
            </Option>
          );
        });
    }

    let countrysOptions = [];
    if (robotOnline.type === 'country-zone') {
      countrysOptions = countrys.map((country, index) => {
        const key = `country-${index}-${country.network}`;
        return (
          <Option key={key} title={country.iso} value={`${country.country}`}>
            {country.country}
          </Option>
        );
      });
    }

    const points = this.state.savedPoints.map((point, index) => {
      const key = `point-${index}`;
      return (
        <div key={key} className="robot-creation-component__online-robot_points__point">
          <span className="robot-creation-component__online-robot_points__point_number">{index + 1}</span>
          <span
            role="button"
            tabIndex={0}
            className="robot-creation-component__online-robot_points__point_delete"
            onClick={() => this.removePoint(index)}
          >
            X
          </span>
        </div>
      );
    });

    return (
      <div className="robot-creation-component">
        <Tabs defaultActiveKey="tracker" onChange={this.callback}>
          {robotTypes.includes('tracker') && (
            <TabPane tab="Tracker Robot" key="tracker">
              <div className="robot-creation-component__online-robot">
                <div className="robot-creation-component__online-robot_row">
                  <div className="robot-creation-component__online-robot_item col-1-2">
                    <div className="robot-creation-component__online-robot_label">
                      <span>*</span>Robot name
                    </div>
                    <Input
                      onChange={(e) => this.handleChangeName(e.target.value)}
                      style={{ width: '100%' }}
                      value={robotOnline.name}
                    />
                  </div>
                </div>

                <div className="robot-creation-component__online-robot_row">
                  <div className="robot-creation-component__online-robot_item col-1-4">
                    <div className="robot-creation-component__online-robot_label">Source</div>
                    <Select
                      onChange={this.handleChangeSource}
                      defaultValue="contact"
                      style={{ width: '100%' }}
                      value={robotOnline.source}
                    >
                      <Option value="contact">Contact</Option>
                      <Option value="contact-group">Contact Group</Option>
                    </Select>
                  </div>

                  <div className="robot-creation-component__online-robot_item col-1-4">
                    <div className="robot-creation-component__online-robot_label">Source Mode</div>
                    <Select
                      onChange={this.handleChangeSourceMode}
                      defaultValue="primary"
                      style={{ width: '100%' }}
                      value={robotOnline.source_mode}
                    >
                      <Option value="primary">Primary</Option>
                      <Option disabled={robotOnline.source === 'contact-group'} value="manual">
                        Manual
                      </Option>
                      <Option value="all">All</Option>
                    </Select>
                  </div>

                  <div className="robot-creation-component__online-robot_item col-1-2">
                    <div className="robot-creation-component__online-robot_label">
                      <span>*</span>Source ID
                    </div>
                    <Select
                      onChange={this.handleChangeSourceId}
                      style={{ width: '100%' }}
                      value={robotOnline.source_id}
                      showSearch
                      filterOption={(input, option) =>
                        option?.props?.children?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0 ||
                        option?.props?.value?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0
                      }
                    >
                      {robotOnline.source === 'contact' ? contacts : groups}
                    </Select>
                  </div>
                </div>

                <div className="robot-creation-component__online-robot_row">
                  <div className="robot-creation-component__online-robot_item col-3-4">
                    <div className="robot-creation-component__online-robot_label">Terminals</div>
                    <Select
                      mode="tags"
                      onChange={this.handleChangeTerminals}
                      disabled={!terminalDisabled}
                      style={{ width: '100%', maxHeight: 45, overflow: 'auto' }}
                      value={robotOnline.terminals}
                    >
                      {terminals}
                    </Select>
                  </div>

                  <div className="robot-creation-component__online-robot_item col-1-4">
                    <div className="robot-creation-component__online-robot_label">Query type</div>
                    <Select
                      onChange={this.handleChangeQueryType}
                      style={{ width: '100%' }}
                      value={robotOnline.query_type}
                    >
                      <Option value="active">Active</Option>
                      <Option value="passive">Passive</Option>
                    </Select>
                  </div>
                </div>

                <div className="robot-creation-component__online-robot_row">
                  <div className="robot-creation-component__online-robot_item" style={{ width: '100%' }}>
                    <Tabs
                      defaultActiveKey="standard"
                      onChange={this.searchTypeOnChange}
                      activeKey={this.state.typeOfSearch}
                    >
                      <TabPane tab="Standard Search" key="standard">
                        <div className="gap-align">
                          <div className="col-3-4">
                            <div className="robot-creation-component__online-robot_label">
                              <span>*</span>Start & End Time
                            </div>
                            <RangePicker
                              showTime
                              format="YYYY/MM/DD HH:mm"
                              style={{ width: '100%' }}
                              placeholder={['Start Time', 'End Time']}
                              value={this.state.timeToShow}
                              onChange={this.handleChangeTime}
                              onOk={this.handleChangeTime}
                              disabledDate={(current) => {
                                return current && current <= moment().add(-1, 'day');
                              }}
                            />
                          </div>
                          <div className="robot-creation-component__online-robot_item col-1-4">
                            <div className="robot-creation-component__online-robot_label">
                              Repeat time <span style={{ opacity: 0.5 }}>(min)</span>
                            </div>
                            <InputNumber
                              onChange={this.handleChangeRepeatTime}
                              parser={(value) => value.replace(/[^0-9]/g, '')}
                              style={{ width: '100%' }}
                              min={1}
                              defaultValue={60}
                              value={robotOnline.repeat_time}
                            />
                          </div>
                        </div>
                      </TabPane>
                      <TabPane tab="Scheduled search" key="scheduled">
                        <div className="gap-align">
                          <div className="col-1-2">
                            <div className="robot-creation-component__online-robot_label">
                              <span>*</span>Initial Search Time
                            </div>
                            <DatePicker
                              showTime
                              format="YYYY/MM/DD HH:mm"
                              style={{ width: '100%' }}
                              placeholder="Schedule Time"
                              value={this.state.scheduleTime}
                              onChange={this.handleScheduleTimeChange}
                              onOk={this.handleScheduleTimeChange}
                              disabledDate={(current) => {
                                return current && current <= moment().add(-1, 'day');
                              }}
                            />
                          </div>
                          <div className="robot-creation-component__online-robot_item col-1-4">
                            <div className="robot-creation-component__online-robot_label">Nr. Of Searches</div>
                            <InputNumber
                              onChange={this.handleChangeNumberOfChanges}
                              parser={(value) => value.replace(/[^0-9]/g, '')}
                              style={{ width: '100%' }}
                              min={1}
                              defaultValue={1}
                              value={robotOnline.number_of_searches}
                            />
                          </div>
                          <div className="robot-creation-component__online-robot_item col-1-4">
                            <div className="robot-creation-component__online-robot_label">
                              Repeat time <span style={{ opacity: 0.5 }}>(min)</span>
                            </div>
                            <InputNumber
                              onChange={this.handleChangeRepeatTime}
                              parser={(value) => value.replace(/[^0-9]/g, '')}
                              style={{ width: '100%' }}
                              min={1}
                              defaultValue={60}
                              value={robotOnline.repeat_time}
                              disabled={!robotOnline.number_of_searches || robotOnline.number_of_searches <= 1}
                            />
                          </div>
                        </div>
                      </TabPane>
                    </Tabs>
                  </div>
                </div>

                <div className="robot-creation-component__online-robot_row">
                  <div className="robot-creation-component__online-robot_item" style={{ width: '100%' }}>
                    <div className="robot-creation-component__online-robot_label">Description</div>
                    <TextArea
                      style={{ width: '100%', resize: 'none' }}
                      onChange={(e) => this.handleChangeDescription(e.target.value)}
                      value={robotOnline.description}
                      rows={5}
                    />
                  </div>
                </div>

                <div className="robot-creation-component__online-robot_row">
                  <Popconfirm
                    title="Are you sure you want to create this robot?"
                    onConfirm={this.createRobot}
                    okText="Yes"
                    cancelText="No"
                    placement="topLeft"
                  >
                    <Button size="mini" loading={this.state.loading}>
                      Create Robot
                    </Button>
                  </Popconfirm>
                </div>
              </div>
            </TabPane>
          )}
          {/* TARGET ROBOT */}

          {robotTypes.includes('online') && (
            <TabPane tab="Online Robot" key="online">
              <div className="robot-creation-component__online-robot">
                <div className="robot-creation-component__online-robot_row">
                  <div className="robot-creation-component__online-robot_item col-1-2">
                    <div className="robot-creation-component__online-robot_label">
                      <span>*</span>Robot name
                    </div>
                    <Input
                      onChange={(e) => this.handleChangeName(e.target.value)}
                      style={{ width: '100%' }}
                      value={robotOnline.name}
                    />
                  </div>
                  <div className="robot-creation-component__online-robot_item">
                    <div className="robot-creation-component__online-robot_label">Stop on result</div>
                    <Checkbox
                      style={{ marginTop: 5 }}
                      onChange={(e) =>
                        this.setState({
                          robotOnline: {
                            ...robotOnline,
                            alert_stop: e.target.checked === true ? 1 : 0,
                          },
                        })
                      }
                      checked={robotOnline.alert_stop === 1}
                    />
                  </div>
                </div>

                <div className="robot-creation-component__online-robot_row">
                  <div className="robot-creation-component__online-robot_item col-1-4">
                    <div className="robot-creation-component__online-robot_label">Source</div>
                    <Select
                      onChange={this.handleChangeSource}
                      defaultValue="contact"
                      style={{ width: '100%' }}
                      value={robotOnline.source}
                    >
                      <Option value="contact">Contact</Option>
                      <Option value="contact-group">Contact Group</Option>
                    </Select>
                  </div>

                  <div className="robot-creation-component__online-robot_item col-1-4">
                    <div className="robot-creation-component__online-robot_label">Source Mode</div>
                    <Select
                      onChange={this.handleChangeSourceMode}
                      defaultValue="primary"
                      style={{ width: '100%' }}
                      value={robotOnline.source_mode}
                    >
                      <Option value="primary">Primary</Option>
                      <Option disabled={robotOnline.source === 'contact-group'} value="manual">
                        Manual
                      </Option>
                      <Option value="all">All</Option>
                    </Select>
                  </div>

                  <div className="robot-creation-component__online-robot_item col-1-2">
                    <div className="robot-creation-component__online-robot_label">
                      <span>*</span>Source ID
                    </div>
                    <Select
                      onChange={this.handleChangeSourceId}
                      style={{ width: '100%' }}
                      value={robotOnline.source_id}
                      showSearch
                      filterOption={(input, option) =>
                        option?.props?.children?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0 ||
                        option?.props?.value?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0
                      }
                    >
                      {robotOnline.source === 'contact' ? contacts : groups}
                    </Select>
                  </div>
                </div>

                <div className="robot-creation-component__online-robot_row">
                  <div className="robot-creation-component__online-robot_item col-3-4">
                    <div className="robot-creation-component__online-robot_label">Terminals</div>
                    <Select
                      mode="tags"
                      onChange={this.handleChangeTerminals}
                      disabled={!terminalDisabled}
                      style={{ width: '100%', maxHeight: 45, overflow: 'auto' }}
                      value={robotOnline.terminals}
                    >
                      {terminals}
                    </Select>
                  </div>

                  <div className="robot-creation-component__online-robot_item col-1-4">
                    <div className="robot-creation-component__online-robot_label">Query type</div>
                    <Select
                      onChange={this.handleChangeQueryType}
                      style={{ width: '100%' }}
                      value={robotOnline.query_type}
                    >
                      <Option value="active">Active</Option>
                      <Option value="passive">Passive</Option>
                    </Select>
                  </div>
                </div>

                <div className="robot-creation-component__online-robot_row">
                  <div className="robot-creation-component__online-robot_item" style={{ width: '100%' }}>
                    <Tabs
                      defaultActiveKey="standard"
                      onChange={this.searchTypeOnChange}
                      activeKey={this.state.typeOfSearch}
                    >
                      <TabPane tab="Standard Search" key="standard">
                        <div className="gap-align">
                          <div className="col-3-4">
                            <div className="robot-creation-component__online-robot_label">
                              <span>*</span>Start & End Time
                            </div>
                            <RangePicker
                              showTime
                              format="YYYY/MM/DD HH:mm"
                              style={{ width: '100%' }}
                              placeholder={['Start Time', 'End Time']}
                              value={this.state.timeToShow}
                              onChange={this.handleChangeTime}
                              onOk={this.handleChangeTime}
                              disabledDate={(current) => {
                                return current && current <= moment().add(-1, 'day');
                              }}
                            />
                          </div>
                          <div className="robot-creation-component__online-robot_item col-1-4">
                            <div className="robot-creation-component__online-robot_label">
                              Repeat time <span style={{ opacity: 0.5 }}>(min)</span>
                            </div>
                            <InputNumber
                              onChange={this.handleChangeRepeatTime}
                              parser={(value) => value.replace(/[^0-9]/g, '')}
                              style={{ width: '100%' }}
                              min={1}
                              defaultValue={60}
                              value={robotOnline.repeat_time}
                            />
                          </div>
                        </div>
                      </TabPane>
                      <TabPane tab="Scheduled search" key="scheduled">
                        <div className="gap-align">
                          <div className="col-1-2">
                            <div className="robot-creation-component__online-robot_label">
                              <span>*</span>Initial Search Time
                            </div>
                            <DatePicker
                              showTime
                              format="YYYY/MM/DD HH:mm"
                              style={{ width: '100%' }}
                              placeholder="Schedule Time"
                              value={this.state.scheduleTime}
                              onChange={this.handleScheduleTimeChange}
                              onOk={this.handleScheduleTimeChange}
                              disabledDate={(current) => {
                                return current && current <= moment().add(-1, 'day');
                              }}
                            />
                          </div>
                          <div className="robot-creation-component__online-robot_item col-1-4">
                            <div className="robot-creation-component__online-robot_label">Nr. Of Searches</div>
                            <InputNumber
                              onChange={this.handleChangeNumberOfChanges}
                              parser={(value) => value.replace(/[^0-9]/g, '')}
                              style={{ width: '100%' }}
                              min={1}
                              defaultValue={1}
                              value={robotOnline.number_of_searches}
                            />
                          </div>
                          <div className="robot-creation-component__online-robot_item col-1-4">
                            <div className="robot-creation-component__online-robot_label">
                              Repeat time <span style={{ opacity: 0.5 }}>(min)</span>
                            </div>
                            <InputNumber
                              onChange={this.handleChangeRepeatTime}
                              parser={(value) => value.replace(/[^0-9]/g, '')}
                              style={{ width: '100%' }}
                              min={1}
                              defaultValue={60}
                              value={robotOnline.repeat_time}
                              disabled={!robotOnline.number_of_searches || robotOnline.number_of_searches <= 1}
                            />
                          </div>
                        </div>
                      </TabPane>
                    </Tabs>
                  </div>
                </div>

                <div className="robot-creation-component__online-robot_row">
                  <div className="robot-creation-component__online-robot_item" style={{ width: '100%' }}>
                    <div className="robot-creation-component__online-robot_label">Description</div>
                    <TextArea
                      style={{ width: '100%', resize: 'none' }}
                      onChange={(e) => this.handleChangeDescription(e.target.value)}
                      value={robotOnline.description}
                      rows={5}
                    />
                  </div>
                </div>

                <div className="robot-creation-component__online-robot_row">
                  <Popconfirm
                    title="Are you sure you want to create this robot?"
                    onConfirm={this.createRobot}
                    okText="Yes"
                    cancelText="No"
                    placement="topLeft"
                  >
                    <Button size="mini" loading={this.state.loading}>
                      Create Robot
                    </Button>
                  </Popconfirm>
                </div>
              </div>
            </TabPane>
          )}
          {/* ONLINE ROBOT */}

          {robotTypes.includes('country-zone') && (
            <TabPane tab="Country Zone Robot" key="country-zone">
              <div className="robot-creation-component__online-robot">
                <div className="robot-creation-component__online-robot_row">
                  <div className="robot-creation-component__online-robot_item col-1-2">
                    <div className="robot-creation-component__online-robot_label">
                      <span>*</span>Robot name
                    </div>
                    <Input
                      onChange={(e) => this.handleChangeName(e.target.value)}
                      style={{ width: '100%' }}
                      value={robotOnline.name}
                    />
                  </div>
                  <div className="robot-creation-component__online-robot_item col-1-4">
                    <div className="robot-creation-component__online-robot_label">
                      <span>*</span>Country
                    </div>
                    <Select
                      onSelect={(e, option) => this.handleChangeCountry(e, option.props.title)}
                      showSearch
                      style={{ width: '100%' }}
                      value={this.state.country}
                    >
                      {countrysOptions}
                    </Select>
                  </div>
                  <div className="robot-creation-component__online-robot_item col-1-4">
                    <div className="robot-creation-component__online-robot_label">
                      <span>*</span>Country condition
                    </div>
                    <Select
                      onChange={this.handleChangeCountryCondition}
                      style={{ width: '100%' }}
                      value={this.state.robotOnline.country_condition}
                    >
                      <Option value="enter-into">Enter</Option>
                      <Option value="exit-from">Exit</Option>
                    </Select>
                  </div>
                </div>

                <div className="robot-creation-component__online-robot_row">
                  <div className="robot-creation-component__online-robot_item col-1-4">
                    <div className="robot-creation-component__online-robot_label">Source</div>
                    <Select
                      onChange={this.handleChangeSource}
                      defaultValue="contact"
                      style={{ width: '100%' }}
                      value={robotOnline.source}
                    >
                      <Option value="contact">Contact</Option>
                      <Option value="contact-group">Contact Group</Option>
                    </Select>
                  </div>

                  <div className="robot-creation-component__online-robot_item col-1-4">
                    <div className="robot-creation-component__online-robot_label">Source Mode</div>
                    <Select
                      onChange={this.handleChangeSourceMode}
                      defaultValue="primary"
                      style={{ width: '100%' }}
                      value={robotOnline.source_mode}
                    >
                      <Option value="primary">Primary</Option>
                      <Option disabled={robotOnline.source === 'contact-group'} value="manual">
                        Manual
                      </Option>
                      <Option value="all">All</Option>
                    </Select>
                  </div>

                  <div className="robot-creation-component__online-robot_item col-1-2">
                    <div className="robot-creation-component__online-robot_label">
                      <span>*</span>Source ID
                    </div>
                    <Select
                      onChange={this.handleChangeSourceId}
                      style={{ width: '100%' }}
                      value={robotOnline.source_id}
                      showSearch
                      filterOption={(input, option) =>
                        option?.props?.children?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0 ||
                        option?.props?.value?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0
                      }
                    >
                      {robotOnline.source === 'contact' ? contacts : groups}
                    </Select>
                  </div>
                </div>

                <div className="robot-creation-component__online-robot_row">
                  <div className="robot-creation-component__online-robot_item col-3-4">
                    <div className="robot-creation-component__online-robot_label">Terminals</div>
                    <Select
                      mode="tags"
                      onChange={this.handleChangeTerminals}
                      disabled={!terminalDisabled}
                      style={{ width: '100%', maxHeight: 45, overflow: 'auto' }}
                      value={robotOnline.terminals}
                    >
                      {terminals}
                    </Select>
                  </div>

                  <div className="robot-creation-component__online-robot_item col-1-4">
                    <div className="robot-creation-component__online-robot_label">Query type</div>
                    <Select
                      onChange={this.handleChangeQueryType}
                      style={{ width: '100%' }}
                      value={robotOnline.query_type}
                    >
                      <Option value="active">Active</Option>
                      <Option value="passive">Passive</Option>
                    </Select>
                  </div>
                </div>

                <div
                  className="robot-creation-component__online-robot_row gap-align"
                  style={{ alignItems: 'flex-end' }}
                >
                  <div className="robot-creation-component__online-robot_item col-4-5">
                    <Tabs
                      defaultActiveKey="standard"
                      onChange={this.searchTypeOnChange}
                      activeKey={this.state.typeOfSearch}
                    >
                      <TabPane tab="Standard Search" key="standard">
                        <div className="gap-align">
                          <div className="col-3-4">
                            <div className="robot-creation-component__online-robot_label">
                              <span>*</span>Start & End Time
                            </div>
                            <RangePicker
                              showTime
                              format="YYYY/MM/DD HH:mm"
                              style={{ width: '100%' }}
                              placeholder={['Start Time', 'End Time']}
                              value={this.state.timeToShow}
                              onChange={this.handleChangeTime}
                              onOk={this.handleChangeTime}
                              disabledDate={(current) => {
                                return current && current <= moment().add(-1, 'day');
                              }}
                            />
                          </div>
                          <div className="robot-creation-component__online-robot_item col-1-4">
                            <div className="robot-creation-component__online-robot_label">
                              Repeat time <span style={{ opacity: 0.5 }}>(min)</span>
                            </div>
                            <InputNumber
                              onChange={this.handleChangeRepeatTime}
                              parser={(value) => value.replace(/[^0-9]/g, '')}
                              style={{ width: '100%' }}
                              min={1}
                              defaultValue={60}
                              value={robotOnline.repeat_time}
                            />
                          </div>
                        </div>
                      </TabPane>
                      <TabPane tab="Scheduled search" key="scheduled">
                        <div className="gap-align">
                          <div className="col-1-2">
                            <div className="robot-creation-component__online-robot_label">
                              <span>*</span>Initial Search Time
                            </div>
                            <DatePicker
                              showTime
                              format="YYYY/MM/DD HH:mm"
                              style={{ width: '100%' }}
                              placeholder="Schedule Time"
                              value={this.state.scheduleTime}
                              onChange={this.handleScheduleTimeChange}
                              onOk={this.handleScheduleTimeChange}
                              disabledDate={(current) => {
                                return current && current <= moment().add(-1, 'day');
                              }}
                            />
                          </div>
                          <div className="robot-creation-component__online-robot_item col-1-4">
                            <div className="robot-creation-component__online-robot_label">Nr. Of Searches</div>
                            <InputNumber
                              onChange={this.handleChangeNumberOfChanges}
                              parser={(value) => value.replace(/[^0-9]/g, '')}
                              style={{ width: '100%' }}
                              min={1}
                              defaultValue={1}
                              value={robotOnline.number_of_searches}
                            />
                          </div>
                          <div className="robot-creation-component__online-robot_item col-1-4">
                            <div className="robot-creation-component__online-robot_label">
                              Repeat time <span style={{ opacity: 0.5 }}>(min)</span>
                            </div>
                            <InputNumber
                              onChange={this.handleChangeRepeatTime}
                              parser={(value) => value.replace(/[^0-9]/g, '')}
                              style={{ width: '100%' }}
                              min={1}
                              defaultValue={60}
                              value={robotOnline.repeat_time}
                              disabled={!robotOnline.number_of_searches || robotOnline.number_of_searches <= 1}
                            />
                          </div>
                        </div>
                      </TabPane>
                    </Tabs>
                  </div>
                  <div
                    className="robot-creation-component__online-robot_item"
                    style={{ width: '20%', marginBottom: '7px' }}
                  >
                    <div className="robot-creation-component__online-robot_label">Stop on result</div>
                    <Checkbox
                      style={{ marginTop: 5 }}
                      onChange={(e) =>
                        this.setState({
                          robotOnline: {
                            ...robotOnline,
                            alert_stop: e.target.checked === true ? 1 : 0,
                          },
                        })
                      }
                      checked={robotOnline.alert_stop === 1}
                    />
                  </div>
                </div>

                <div className="robot-creation-component__online-robot_row">
                  <div className="robot-creation-component__online-robot_item" style={{ width: '100%' }}>
                    <div className="robot-creation-component__online-robot_label">Description</div>
                    <TextArea
                      style={{ width: '100%', resize: 'none' }}
                      onChange={(e) => this.handleChangeDescription(e.target.value)}
                      rows={5}
                      value={robotOnline.description}
                    />
                  </div>
                </div>

                <div className="robot-creation-component__online-robot_row">
                  <Popconfirm
                    title="Are you sure you want to create this robot?"
                    onConfirm={this.createRobot}
                    okText="Yes"
                    cancelText="No"
                    placement="topLeft"
                  >
                    <Button size="mini" loading={this.state.loading}>
                      Create Robot
                    </Button>
                  </Popconfirm>
                </div>
              </div>
            </TabPane>
          )}
          {/* COUNTRY ZONE */}

          {robotTypes.includes('geo-point') && (
            <TabPane tab="Geo Point Robot" key="geo-point">
              <div className="robot-creation-component__online-robot">
                <div className="robot-creation-component__online-robot_row">
                  <div className="robot-creation-component__online-robot_item col-2-5">
                    <div className="robot-creation-component__online-robot_label">
                      <span>*</span>Robot name
                    </div>
                    <Input
                      onChange={(e) => this.handleChangeName(e.target.value)}
                      style={{ width: '100%' }}
                      value={robotOnline.name}
                    />
                  </div>

                  <div className="robot-creation-component__online-robot_item col-1-5">
                    <div className="robot-creation-component__online-robot_label">
                      <span>*</span>Distance
                    </div>
                    <InputNumber
                      onChange={this.handleChangeDistance}
                      style={{ width: '100%' }}
                      parser={(value) => value.replace(/[^0-9]/g, '')}
                      min={100}
                      defaultValue={100}
                      value={robotOnline.distance}
                    />
                  </div>

                  <div className="robot-creation-component__online-robot_item col-1-5">
                    <div className="robot-creation-component__online-robot_label">
                      <span>*</span>Lat
                    </div>
                    <Input
                      style={{ width: '100%' }}
                      onChange={(e) => (checkForNumber(e.target.value) ? '' : this.handleChangeLat(e.target.value))}
                      value={robotOnline.lat}
                    />
                  </div>

                  <div className="robot-creation-component__online-robot_item col-1-5">
                    <div className="robot-creation-component__online-robot_label">
                      <span>*</span>Lng
                    </div>
                    <Input
                      style={{ width: '100%' }}
                      onChange={(e) => (checkForNumber(e.target.value) ? '' : this.handleChangeLng(e.target.value))}
                      value={robotOnline.long}
                    />
                  </div>
                </div>

                <div className="robot-creation-component__online-robot_row">
                  <div className="robot-creation-component__online-robot_item col-1-5">
                    <div className="robot-creation-component__online-robot_label">Source</div>
                    <Select
                      onChange={this.handleChangeSource}
                      defaultValue="contact"
                      style={{ width: '100%' }}
                      value={robotOnline.source}
                    >
                      <Option value="contact">Contact</Option>
                      <Option value="contact-group">Contact Group</Option>
                    </Select>
                  </div>

                  <div className="robot-creation-component__online-robot_item col-1-5">
                    <div className="robot-creation-component__online-robot_label">Source Mode</div>
                    <Select
                      onChange={this.handleChangeSourceMode}
                      defaultValue="primary"
                      style={{ width: '100%' }}
                      value={robotOnline.source_mode}
                    >
                      <Option value="primary">Primary</Option>
                      <Option disabled={robotOnline.source === 'contact-group'} value="manual">
                        Manual
                      </Option>
                      <Option value="all">All</Option>
                    </Select>
                  </div>

                  <div className="robot-creation-component__online-robot_item col-2-5">
                    <div className="robot-creation-component__online-robot_label">
                      <span>*</span>Source ID
                    </div>
                    <Select
                      onChange={this.handleChangeSourceId}
                      style={{ width: '100%' }}
                      value={robotOnline.source_id}
                      showSearch
                      filterOption={(input, option) =>
                        option?.props?.children?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0 ||
                        option?.props?.value?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0
                      }
                    >
                      {robotOnline.source === 'contact' ? contacts : groups}
                    </Select>
                  </div>
                  <div className="robot-creation-component__online-robot_item col-1-5">
                    <div className="robot-creation-component__online-robot_label">
                      <span>*</span>Condition
                    </div>
                    <Select
                      onChange={this.handleChangeCondition}
                      style={{ width: '100%' }}
                      value={robotOnline.condition}
                    >
                      <Option value="exit-from">Exit from</Option>
                      <Option value="enter-into">Enter into</Option>
                    </Select>
                  </div>
                </div>

                <div className="robot-creation-component__online-robot_row">
                  <div className="robot-creation-component__online-robot_item col-3-4">
                    <div className="robot-creation-component__online-robot_label">Terminals</div>
                    <Select
                      mode="tags"
                      onChange={this.handleChangeTerminals}
                      disabled={!terminalDisabled}
                      style={{ width: '100%', maxHeight: 45, overflow: 'auto' }}
                      value={robotOnline.terminals}
                    >
                      {terminals}
                    </Select>
                  </div>

                  <div className="robot-creation-component__online-robot_item col-1-4">
                    <div className="robot-creation-component__online-robot_label">Query type</div>
                    <Select
                      onChange={this.handleChangeQueryType}
                      style={{ width: '100%' }}
                      value={robotOnline.query_type}
                    >
                      <Option value="active">Active</Option>
                      <Option value="passive">Passive</Option>
                    </Select>
                  </div>
                </div>

                <div className="robot-creation-component__online-robot_row" style={{ alignItems: 'flex-end' }}>
                  <div className="robot-creation-component__online-robot_item col-4-5">
                    <Tabs
                      defaultActiveKey="standard"
                      onChange={this.searchTypeOnChange}
                      activeKey={this.state.typeOfSearch}
                    >
                      <TabPane tab="Standard Search" key="standard">
                        <div className="gap-align">
                          <div className="col-3-4">
                            <div className="robot-creation-component__online-robot_label">
                              <span>*</span>Start & End Time
                            </div>
                            <RangePicker
                              showTime
                              format="YYYY/MM/DD HH:mm"
                              style={{ width: '100%' }}
                              placeholder={['Start Time', 'End Time']}
                              value={this.state.timeToShow}
                              onChange={this.handleChangeTime}
                              onOk={this.handleChangeTime}
                              disabledDate={(current) => {
                                return current && current <= moment().add(-1, 'day');
                              }}
                            />
                          </div>
                          <div className="robot-creation-component__online-robot_item col-1-4">
                            <div className="robot-creation-component__online-robot_label">
                              Repeat time <span style={{ opacity: 0.5 }}>(min)</span>
                            </div>
                            <InputNumber
                              onChange={this.handleChangeRepeatTime}
                              parser={(value) => value.replace(/[^0-9]/g, '')}
                              style={{ width: '100%' }}
                              min={1}
                              defaultValue={60}
                              value={robotOnline.repeat_time}
                            />
                          </div>
                        </div>
                      </TabPane>
                      <TabPane tab="Scheduled search" key="scheduled">
                        <div className="gap-align">
                          <div className="col-1-2">
                            <div className="robot-creation-component__online-robot_label">
                              <span>*</span>Initial Search Time
                            </div>
                            <DatePicker
                              showTime
                              format="YYYY/MM/DD HH:mm"
                              style={{ width: '100%' }}
                              placeholder="Schedule Time"
                              value={this.state.scheduleTime}
                              onChange={this.handleScheduleTimeChange}
                              onOk={this.handleScheduleTimeChange}
                              disabledDate={(current) => {
                                return current && current <= moment().add(-1, 'day');
                              }}
                            />
                          </div>
                          <div className="robot-creation-component__online-robot_item col-1-4">
                            <div className="robot-creation-component__online-robot_label">Nr. Of Searches</div>
                            <InputNumber
                              onChange={this.handleChangeNumberOfChanges}
                              parser={(value) => value.replace(/[^0-9]/g, '')}
                              style={{ width: '100%' }}
                              min={1}
                              defaultValue={1}
                              value={robotOnline.number_of_searches}
                            />
                          </div>
                          <div className="robot-creation-component__online-robot_item col-1-4">
                            <div className="robot-creation-component__online-robot_label">
                              Repeat time <span style={{ opacity: 0.5 }}>(min)</span>
                            </div>
                            <InputNumber
                              onChange={this.handleChangeRepeatTime}
                              parser={(value) => value.replace(/[^0-9]/g, '')}
                              style={{ width: '100%' }}
                              min={1}
                              defaultValue={60}
                              value={robotOnline.repeat_time}
                              disabled={!robotOnline.number_of_searches || robotOnline.number_of_searches <= 1}
                            />
                          </div>
                        </div>
                      </TabPane>
                    </Tabs>
                  </div>
                  <div
                    className="robot-creation-component__online-robot_item"
                    style={{ width: '20%', marginBottom: '7px' }}
                  >
                    <div className="robot-creation-component__online-robot_label">Stop on result</div>
                    <Checkbox
                      style={{ marginTop: 5 }}
                      onChange={(e) =>
                        this.setState({
                          robotOnline: {
                            ...robotOnline,
                            alert_stop: e.target.checked === true ? 1 : 0,
                          },
                        })
                      }
                      checked={robotOnline.alert_stop === 1}
                    />
                  </div>
                </div>

                <div className="robot-creation-component__online-robot_row">
                  <div className="robot-creation-component__online-robot_item" style={{ width: '100%' }}>
                    <div className="robot-creation-component__online-robot_label">Description</div>
                    <TextArea
                      style={{ width: '100%', resize: 'none' }}
                      onChange={(e) => this.handleChangeDescription(e.target.value)}
                      rows={5}
                      value={robotOnline.description}
                    />
                  </div>
                </div>

                <div className="robot-creation-component__online-robot_row">
                  <Popconfirm
                    title="Are you sure you want to create this robot?"
                    onConfirm={this.createRobot}
                    okText="Yes"
                    cancelText="No"
                    placement="topLeft"
                  >
                    <Button size="mini" loading={this.state.loading}>
                      Create Robot
                    </Button>
                  </Popconfirm>
                </div>

                <div className="robot-creation-component__online-robot_map">
                  <NewMap
                    mapInfo={{ markers: [], type: 'geo-point-map', zoom: 1, range: robotOnline.distance }}
                    getLatLng={this.getLatLng}
                  />
                </div>
              </div>
            </TabPane>
          )}
          {/* GEO POINT ROBOT */}

          {robotTypes.includes('point-to-point') && (
            <TabPane tab="Point to Point Robot" key="point-to-point">
              <div className="robot-creation-component__online-robot">
                <div className="robot-creation-component__online-robot_row">
                  <div className="robot-creation-component__online-robot_item col-1-2">
                    <div className="robot-creation-component__online-robot_label">
                      <span>*</span>Robot name
                    </div>
                    <Input
                      onChange={(e) => this.handleChangeName(e.target.value)}
                      style={{ width: '100%' }}
                      value={robotOnline.name}
                    />
                  </div>
                  <div className="robot-creation-component__online-robot_item col-1-4">
                    <div className="robot-creation-component__online-robot_label">
                      <span>*</span>Distance
                    </div>
                    <InputNumber
                      onChange={this.handleChangeDistance}
                      style={{ width: '100%' }}
                      parser={(value) => value.replace(/[^0-9]/g, '')}
                      min={100}
                      defaultValue={100}
                      value={robotOnline.distance}
                    />
                  </div>
                  <div className="robot-creation-component__online-robot_item col-1-4">
                    <div className="robot-creation-component__online-robot_label">
                      <span>*</span>Target ID
                    </div>
                    <Select
                      onChange={this.handleChangeTargetId}
                      style={{ width: '100%' }}
                      value={robotOnline.target_id}
                    >
                      {contacts}
                    </Select>
                  </div>
                </div>

                <div className="robot-creation-component__online-robot_row">
                  <div className="robot-creation-component__online-robot_item col-1-2">
                    <div className="robot-creation-component__online-robot_label">Source</div>
                    <Select
                      onChange={this.handleChangeSource}
                      defaultValue="contact"
                      style={{ width: '100%' }}
                      value={robotOnline.source}
                    >
                      <Option value="contact">Contact</Option>
                      <Option value="contact-group">Contact Group</Option>
                    </Select>
                  </div>

                  <div className="robot-creation-component__online-robot_item col-1-4">
                    <div className="robot-creation-component__online-robot_label">Source Mode</div>
                    <Select
                      onChange={this.handleChangeSourceMode}
                      defaultValue="primary"
                      style={{ width: '100%' }}
                      value={robotOnline.source_mode}
                    >
                      <Option value="primary">Primary</Option>
                      <Option disabled={robotOnline.source === 'contact-group'} value="manual">
                        Manual
                      </Option>
                      <Option value="all">All</Option>
                    </Select>
                  </div>

                  <div className="robot-creation-component__online-robot_item col-1-4">
                    <div className="robot-creation-component__online-robot_label">
                      <span>*</span>Source ID
                    </div>
                    <Select
                      onChange={this.handleChangeSourceId}
                      style={{ width: '100%' }}
                      value={robotOnline.source_id}
                      showSearch
                      filterOption={(input, option) =>
                        option?.props?.children?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0 ||
                        option?.props?.value?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0
                      }
                    >
                      {robotOnline.source === 'contact' ? contacts : groups}
                    </Select>
                  </div>
                </div>

                <div className="robot-creation-component__online-robot_row">
                  <div className="robot-creation-component__online-robot_item col-3-4">
                    <div className="robot-creation-component__online-robot_label">Terminals</div>
                    <Select
                      mode="tags"
                      onChange={this.handleChangeTerminals}
                      disabled={!terminalDisabled}
                      style={{ width: '100%', maxHeight: 45, overflow: 'auto' }}
                      value={robotOnline.terminals}
                    >
                      {terminals}
                    </Select>
                  </div>

                  <div className="robot-creation-component__online-robot_item col-1-4">
                    <div className="robot-creation-component__online-robot_label">Query type</div>
                    <Select
                      onChange={this.handleChangeQueryType}
                      style={{ width: '100%' }}
                      value={robotOnline.query_type}
                    >
                      <Option value="active">Active</Option>
                      <Option value="passive">Passive</Option>
                    </Select>
                  </div>
                </div>

                <div className="robot-creation-component__online-robot_row" style={{ alignItems: 'flex-end' }}>
                  <div className="robot-creation-component__online-robot_item col-4-5">
                    <Tabs
                      defaultActiveKey="standard"
                      onChange={this.searchTypeOnChange}
                      activeKey={this.state.typeOfSearch}
                    >
                      <TabPane tab="Standard Search" key="standard">
                        <div className="gap-align">
                          <div className="col-3-4">
                            <div className="robot-creation-component__online-robot_label">
                              <span>*</span>Start & End Time
                            </div>
                            <RangePicker
                              showTime
                              format="YYYY/MM/DD HH:mm"
                              style={{ width: '100%' }}
                              placeholder={['Start Time', 'End Time']}
                              value={this.state.timeToShow}
                              onChange={this.handleChangeTime}
                              onOk={this.handleChangeTime}
                              disabledDate={(current) => {
                                return current && current <= moment().add(-1, 'day');
                              }}
                            />
                          </div>
                          <div className="robot-creation-component__online-robot_item col-1-4">
                            <div className="robot-creation-component__online-robot_label">
                              Repeat time <span style={{ opacity: 0.5 }}>(min)</span>
                            </div>
                            <InputNumber
                              onChange={this.handleChangeRepeatTime}
                              parser={(value) => value.replace(/[^0-9]/g, '')}
                              style={{ width: '100%' }}
                              min={1}
                              defaultValue={60}
                              value={robotOnline.repeat_time}
                            />
                          </div>
                        </div>
                      </TabPane>
                      <TabPane tab="Scheduled search" key="scheduled">
                        <div className="gap-align">
                          <div className="col-1-2">
                            <div className="robot-creation-component__online-robot_label">
                              <span>*</span>Initial Search Time
                            </div>
                            <DatePicker
                              showTime
                              format="YYYY/MM/DD HH:mm"
                              style={{ width: '100%' }}
                              placeholder="Schedule Time"
                              value={this.state.scheduleTime}
                              onChange={this.handleScheduleTimeChange}
                              onOk={this.handleScheduleTimeChange}
                              disabledDate={(current) => {
                                return current && current <= moment().add(-1, 'day');
                              }}
                            />
                          </div>
                          <div className="robot-creation-component__online-robot_item col-1-4">
                            <div className="robot-creation-component__online-robot_label">Nr. Of Searches</div>
                            <InputNumber
                              onChange={this.handleChangeNumberOfChanges}
                              parser={(value) => value.replace(/[^0-9]/g, '')}
                              style={{ width: '100%' }}
                              min={1}
                              defaultValue={1}
                              value={robotOnline.number_of_searches}
                            />
                          </div>
                          <div className="robot-creation-component__online-robot_item col-1-4">
                            <div className="robot-creation-component__online-robot_label">
                              Repeat time <span style={{ opacity: 0.5 }}>(min)</span>
                            </div>
                            <InputNumber
                              onChange={this.handleChangeRepeatTime}
                              parser={(value) => value.replace(/[^0-9]/g, '')}
                              style={{ width: '100%' }}
                              min={1}
                              defaultValue={60}
                              value={robotOnline.repeat_time}
                              disabled={!robotOnline.number_of_searches || robotOnline.number_of_searches <= 1}
                            />
                          </div>
                        </div>
                      </TabPane>
                    </Tabs>
                  </div>
                  <div
                    className="robot-creation-component__online-robot_item"
                    style={{ width: '20%', marginBottom: '7px' }}
                  >
                    <div className="robot-creation-component__online-robot_label">Stop on result</div>
                    <Checkbox
                      style={{ marginTop: 5 }}
                      onChange={(e) =>
                        this.setState({
                          robotOnline: {
                            ...robotOnline,
                            alert_stop: e.target.checked === true ? 1 : 0,
                          },
                        })
                      }
                      checked={robotOnline.alert_stop === 1}
                    />
                  </div>
                </div>

                <div className="robot-creation-component__online-robot_row">
                  <div className="robot-creation-component__online-robot_item" style={{ width: '100%' }}>
                    <div className="robot-creation-component__online-robot_label">Description</div>
                    <TextArea
                      style={{ width: '100%', resize: 'none' }}
                      onChange={(e) => this.handleChangeDescription(e.target.value)}
                      rows={5}
                      value={robotOnline.description}
                    />
                  </div>
                </div>

                <div className="robot-creation-component__online-robot_row">
                  <Popconfirm
                    title="Are you sure you want to create this robot?"
                    onConfirm={this.createRobot}
                    okText="Yes"
                    cancelText="No"
                    placement="topLeft"
                  >
                    <Button size="mini" loading={this.state.loading}>
                      Create Robot
                    </Button>
                  </Popconfirm>
                </div>
              </div>
            </TabPane>
          )}
          {/* POINT TO POINT ROBOT */}

          {robotTypes.includes('geo-points') && (
            <TabPane tab="Geo Points Robot" key="geo-points">
              <div className="robot-creation-component__online-robot">
                <div className="robot-creation-component__online-robot_row">
                  <div className="robot-creation-component__online-robot_item col-1-2">
                    <div className="robot-creation-component__online-robot_label">
                      <span>*</span>Robot name
                    </div>
                    <Input
                      onChange={(e) => this.handleChangeName(e.target.value)}
                      style={{ width: '100%' }}
                      value={robotOnline.name}
                    />
                  </div>
                  <div className="robot-creation-component__online-robot_item col-1-2">
                    <div className="robot-creation-component__online-robot_points">{points}</div>
                  </div>
                </div>

                <div className="robot-creation-component__online-robot_row robot-creation-component__geo-points">
                  <div className="robot-creation-component__online-robot_item col-1-4">
                    <div className="robot-creation-component__online-robot_label">
                      <span>*</span>Distance
                    </div>
                    <InputNumber
                      onChange={this.handleChangeDistance}
                      style={{ width: '100%' }}
                      parser={(value) => value.replace(/[^0-9]/g, '')}
                      min={100}
                      defaultValue={100}
                      value={robotOnline.distance}
                    />
                  </div>

                  <div className="robot-creation-component__online-robot_item col-1-4">
                    <div className="robot-creation-component__online-robot_label">
                      <span>*</span>Lat
                    </div>
                    <Input
                      style={{ width: '100%' }}
                      onChange={(e) => (checkForNumber(e.target.value) ? '' : this.handleChangeLat(e.target.value))}
                      value={robotOnline.lat}
                    />
                  </div>

                  <div className="robot-creation-component__online-robot_item col-1-4">
                    <div className="robot-creation-component__online-robot_label">
                      <span>*</span>Lng
                    </div>
                    <Input
                      style={{ width: '100%' }}
                      onChange={(e) => (checkForNumber(e.target.value) ? '' : this.handleChangeLng(e.target.value))}
                      value={robotOnline.long}
                    />
                  </div>
                  <Button
                    className="robot-creation-component__geo-points_button col-1-4"
                    size="mini"
                    onClick={this.savePoint}
                    disabled={!robotOnline.lat || !robotOnline.long || !robotOnline.distance}
                  >
                    Save Point
                  </Button>
                </div>

                <div className="robot-creation-component__online-robot_row">
                  <div className="robot-creation-component__online-robot_item col-1-4">
                    <div className="robot-creation-component__online-robot_label">Source</div>
                    <Select
                      onChange={this.handleChangeSource}
                      defaultValue="contact"
                      style={{ width: '100%' }}
                      value={robotOnline.source}
                    >
                      <Option value="contact">Contact</Option>
                      <Option value="contact-group">Contact Group</Option>
                    </Select>
                  </div>

                  <div className="robot-creation-component__online-robot_item col-1-4">
                    <div className="robot-creation-component__online-robot_label">Source Mode</div>
                    <Select
                      onChange={this.handleChangeSourceMode}
                      defaultValue="primary"
                      style={{ width: '100%' }}
                      value={robotOnline.source_mode}
                    >
                      <Option value="primary">Primary</Option>
                      <Option disabled={robotOnline.source === 'contact-group'} value="manual">
                        Manual
                      </Option>
                      <Option value="all">All</Option>
                    </Select>
                  </div>

                  <div className="robot-creation-component__online-robot_item col-1-4">
                    <div className="robot-creation-component__online-robot_label">
                      <span>*</span>Source ID
                    </div>
                    <Select
                      onChange={this.handleChangeSourceId}
                      style={{ width: '100%' }}
                      value={robotOnline.source_id}
                      showSearch
                      filterOption={(input, option) =>
                        option?.props?.children?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0 ||
                        option?.props?.value?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0
                      }
                    >
                      {robotOnline.source === 'contact' ? contacts : groups}
                    </Select>
                  </div>

                  {this.state.robotOnline.source === 'contact-group' && this.state.robotOnline.source_id !== '' && (
                    <div className="robot-creation-component__online-robot_item col-1-4">
                      <div className="robot-creation-component__online-robot_label">Contacts</div>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <InputNumber
                          value={robotOnline.group}
                          max={this.state.initialGroupCount}
                          min={1}
                          onChange={this.handleChangeGroup}
                          style={{
                            width: '70%',
                          }}
                        />
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            width: '30%',
                            padding: '5px',
                            background: '#ededed',
                            borderRadius: '2px',
                            marginLeft: '2px',
                            height: '32px',
                            border: '1px solid #d9d9d9',
                          }}
                        >
                          /{this.state.groupCount}
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                <div className="robot-creation-component__online-robot_row">
                  <div className="robot-creation-component__online-robot_item col-1-2">
                    <div className="robot-creation-component__online-robot_label">Terminals</div>
                    <Select
                      mode="tags"
                      onChange={this.handleChangeTerminals}
                      disabled={!terminalDisabled}
                      style={{ width: '100%', maxHeight: 45, overflow: 'auto' }}
                      value={robotOnline.terminals}
                    >
                      {terminals}
                    </Select>
                  </div>

                  <div className="robot-creation-component__online-robot_item col-1-4">
                    <div className="robot-creation-component__online-robot_label">Query type</div>
                    <Select
                      onChange={this.handleChangeQueryType}
                      style={{ width: '100%' }}
                      value={robotOnline.query_type}
                    >
                      <Option value="active">Active</Option>
                      <Option value="passive">Passive</Option>
                    </Select>
                  </div>
                  <div className="robot-creation-component__online-robot_item col-1-4">
                    <div className="robot-creation-component__online-robot_label">
                      <span>*</span>Condition
                    </div>
                    <Select
                      onChange={this.handleChangeCondition}
                      style={{ width: '100%' }}
                      value={robotOnline.condition}
                    >
                      <Option value="exit-from">Exit from</Option>
                      <Option value="enter-into">Enter into</Option>
                    </Select>
                  </div>
                </div>

                <div
                  className="robot-creation-component__online-robot_row gap-align"
                  style={{ alignItems: 'flex-end' }}
                >
                  <div className="robot-creation-component__online-robot_item col-4-5">
                    <Tabs
                      defaultActiveKey="standard"
                      onChange={this.searchTypeOnChange}
                      activeKey={this.state.typeOfSearch}
                    >
                      <TabPane tab="Standard Search" key="standard">
                        <div className="gap-align">
                          <div className="col-3-4">
                            <div className="robot-creation-component__online-robot_label">
                              <span>*</span>Start & End Time
                            </div>
                            <RangePicker
                              showTime
                              format="YYYY/MM/DD HH:mm"
                              style={{ width: '100%' }}
                              placeholder={['Start Time', 'End Time']}
                              value={this.state.timeToShow}
                              onChange={this.handleChangeTime}
                              onOk={this.handleChangeTime}
                              disabledDate={(current) => {
                                return current && current <= moment().add(-1, 'day');
                              }}
                            />
                          </div>
                          <div className="robot-creation-component__online-robot_item col-1-4">
                            <div className="robot-creation-component__online-robot_label">
                              Repeat time <span style={{ opacity: 0.5 }}>(min)</span>
                            </div>
                            <InputNumber
                              onChange={this.handleChangeRepeatTime}
                              parser={(value) => value.replace(/[^0-9]/g, '')}
                              style={{ width: '100%' }}
                              min={1}
                              defaultValue={60}
                              value={robotOnline.repeat_time}
                            />
                          </div>
                        </div>
                      </TabPane>
                      <TabPane tab="Scheduled search" key="scheduled">
                        <div className="gap-align">
                          <div className="col-1-2">
                            <div className="robot-creation-component__online-robot_label">
                              <span>*</span>Initial Search Time
                            </div>
                            <DatePicker
                              showTime
                              format="YYYY/MM/DD HH:mm"
                              style={{ width: '100%' }}
                              placeholder="Schedule Time"
                              value={this.state.scheduleTime}
                              onChange={this.handleScheduleTimeChange}
                              onOk={this.handleScheduleTimeChange}
                              disabledDate={(current) => {
                                return current && current <= moment().add(-1, 'day');
                              }}
                            />
                          </div>
                          <div className="robot-creation-component__online-robot_item col-1-4">
                            <div className="robot-creation-component__online-robot_label">Nr. Of Searches</div>
                            <InputNumber
                              onChange={this.handleChangeNumberOfChanges}
                              parser={(value) => value.replace(/[^0-9]/g, '')}
                              style={{ width: '100%' }}
                              min={1}
                              defaultValue={1}
                              value={robotOnline.number_of_searches}
                            />
                          </div>
                          <div className="robot-creation-component__online-robot_item col-1-4">
                            <div className="robot-creation-component__online-robot_label">
                              Repeat time <span style={{ opacity: 0.5 }}>(min)</span>
                            </div>
                            <InputNumber
                              onChange={this.handleChangeRepeatTime}
                              parser={(value) => value.replace(/[^0-9]/g, '')}
                              style={{ width: '100%' }}
                              min={1}
                              defaultValue={60}
                              value={robotOnline.repeat_time}
                              disabled={!robotOnline.number_of_searches || robotOnline.number_of_searches <= 1}
                            />
                          </div>
                        </div>
                      </TabPane>
                    </Tabs>
                  </div>
                  <div
                    className="robot-creation-component__online-robot_item"
                    style={{ width: '20%', marginBottom: '7px' }}
                  >
                    <div className="robot-creation-component__online-robot_label">Stop on result</div>
                    <Checkbox
                      style={{ marginTop: 5 }}
                      onChange={(e) =>
                        this.setState({
                          robotOnline: {
                            ...robotOnline,
                            alert_stop: e.target.checked === true ? 1 : 0,
                          },
                        })
                      }
                      checked={robotOnline.alert_stop === 1}
                    />
                  </div>
                </div>

                <div className="robot-creation-component__online-robot_row">
                  <div className="robot-creation-component__online-robot_item" style={{ width: '100%' }}>
                    <div className="robot-creation-component__online-robot_label">Description</div>
                    <TextArea
                      style={{ width: '100%', resize: 'none' }}
                      onChange={(e) => this.handleChangeDescription(e.target.value)}
                      rows={2}
                      value={robotOnline.description}
                    />
                  </div>
                </div>

                <div className="robot-creation-component__online-robot_row">
                  <Popconfirm
                    title="Are you sure you want to create this robot?"
                    onConfirm={this.createRobot}
                    okText="Yes"
                    cancelText="No"
                    placement="topLeft"
                  >
                    <Button size="mini" loading={this.state.loading}>
                      Create Robot
                    </Button>
                  </Popconfirm>
                </div>

                <div className="robot-creation-component__online-robot_map robot-creation-component__online-robot_map-geo-points">
                  <NewMap
                    mapInfo={{
                      markers: [],
                      type: 'geo-points-map',
                      zoom: 2,
                      range: robotOnline.distance,
                      savedPoints: this.state.savedPoints,
                      showMarker: this.state.showMarker,
                    }}
                    getLatLng={this.getLatLng}
                  />
                </div>
              </div>
            </TabPane>
          )}
          {/* GEO POINTS ROBOT */}

          {robotTypes.includes('offline') && (
            <TabPane tab="Offline Robot" key="offline">
              <div className="robot-creation-component__online-robot">
                <div className="robot-creation-component__online-robot_row">
                  <div className="robot-creation-component__online-robot_item col-1-2">
                    <div className="robot-creation-component__online-robot_label">
                      <span>*</span>Robot name
                    </div>
                    <Input
                      onChange={(e) => this.handleChangeName(e.target.value)}
                      style={{ width: '100%' }}
                      value={robotOnline.name}
                    />
                  </div>
                  <div className="robot-creation-component__online-robot_item">
                    <div className="robot-creation-component__online-robot_label">Stop on result</div>
                    <Checkbox
                      style={{ marginTop: 5 }}
                      onChange={(e) =>
                        this.setState({
                          robotOnline: {
                            ...robotOnline,
                            alert_stop: e.target.checked === true ? 1 : 0,
                          },
                        })
                      }
                      checked={robotOnline.alert_stop === 1}
                    />
                  </div>
                </div>

                <div className="robot-creation-component__online-robot_row">
                  <div className="robot-creation-component__online-robot_item col-1-4">
                    <div className="robot-creation-component__online-robot_label">Source</div>
                    <Select
                      onChange={this.handleChangeSource}
                      defaultValue="contact"
                      style={{ width: '100%' }}
                      value={robotOnline.source}
                    >
                      <Option value="contact">Contact</Option>
                      <Option value="contact-group">Contact Group</Option>
                    </Select>
                  </div>

                  <div className="robot-creation-component__online-robot_item col-1-4">
                    <div className="robot-creation-component__online-robot_label">Source Mode</div>
                    <Select
                      onChange={this.handleChangeSourceMode}
                      defaultValue="primary"
                      style={{ width: '100%' }}
                      value={robotOnline.source_mode}
                    >
                      <Option value="primary">Primary</Option>
                      <Option disabled={robotOnline.source === 'contact-group'} value="manual">
                        Manual
                      </Option>
                      <Option value="all">All</Option>
                    </Select>
                  </div>

                  <div className="robot-creation-component__online-robot_item col-1-2">
                    <div className="robot-creation-component__online-robot_label">
                      <span>*</span>Source ID
                    </div>
                    <Select
                      onChange={this.handleChangeSourceId}
                      style={{ width: '100%' }}
                      value={robotOnline.source_id}
                      showSearch
                      filterOption={(input, option) =>
                        option?.props?.children?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0 ||
                        option?.props?.value?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0
                      }
                    >
                      {robotOnline.source === 'contact' ? contacts : groups}
                    </Select>
                  </div>
                </div>

                <div className="robot-creation-component__online-robot_row">
                  <div className="robot-creation-component__online-robot_item col-3-4">
                    <div className="robot-creation-component__online-robot_label">Terminals</div>
                    <Select
                      mode="tags"
                      onChange={this.handleChangeTerminals}
                      disabled={!terminalDisabled}
                      style={{ width: '100%', maxHeight: 45, overflow: 'auto' }}
                      value={robotOnline.terminals}
                    >
                      {terminals}
                    </Select>
                  </div>

                  <div className="robot-creation-component__online-robot_item col-1-4">
                    <div className="robot-creation-component__online-robot_label">Query type</div>
                    <Select
                      onChange={this.handleChangeQueryType}
                      style={{ width: '100%' }}
                      value={robotOnline.query_type}
                    >
                      <Option value="active">Active</Option>
                      <Option value="passive">Passive</Option>
                    </Select>
                  </div>
                </div>

                <div className="robot-creation-component__online-robot_row">
                  <div className="robot-creation-component__online-robot_item" style={{ width: '100%' }}>
                    <Tabs
                      defaultActiveKey="standard"
                      onChange={this.searchTypeOnChange}
                      activeKey={this.state.typeOfSearch}
                    >
                      <TabPane tab="Standard Search" key="standard">
                        <div className="gap-align">
                          <div className="col-3-4">
                            <div className="robot-creation-component__online-robot_label">
                              <span>*</span>Start & End Time
                            </div>
                            <RangePicker
                              showTime
                              format="YYYY/MM/DD HH:mm"
                              style={{ width: '100%' }}
                              placeholder={['Start Time', 'End Time']}
                              value={this.state.timeToShow}
                              onChange={this.handleChangeTime}
                              onOk={this.handleChangeTime}
                              disabledDate={(current) => {
                                return current && current <= moment().add(-1, 'day');
                              }}
                            />
                          </div>
                          <div className="robot-creation-component__online-robot_item col-1-4">
                            <div className="robot-creation-component__online-robot_label">
                              Repeat time <span style={{ opacity: 0.5 }}>(min)</span>
                            </div>
                            <InputNumber
                              onChange={this.handleChangeRepeatTime}
                              parser={(value) => value.replace(/[^0-9]/g, '')}
                              style={{ width: '100%' }}
                              min={1}
                              defaultValue={60}
                              value={robotOnline.repeat_time}
                            />
                          </div>
                        </div>
                      </TabPane>
                      <TabPane tab="Scheduled search" key="scheduled">
                        <div className="gap-align">
                          <div className="col-1-2">
                            <div className="robot-creation-component__online-robot_label">
                              <span>*</span>Initial Search Time
                            </div>
                            <DatePicker
                              showTime
                              format="YYYY/MM/DD HH:mm"
                              style={{ width: '100%' }}
                              placeholder="Schedule Time"
                              value={this.state.scheduleTime}
                              onChange={this.handleScheduleTimeChange}
                              onOk={this.handleScheduleTimeChange}
                              disabledDate={(current) => {
                                return current && current <= moment().add(-1, 'day');
                              }}
                            />
                          </div>
                          <div className="robot-creation-component__online-robot_item col-1-4">
                            <div className="robot-creation-component__online-robot_label">Nr. Of Searches</div>
                            <InputNumber
                              onChange={this.handleChangeNumberOfChanges}
                              parser={(value) => value.replace(/[^0-9]/g, '')}
                              style={{ width: '100%' }}
                              min={1}
                              defaultValue={1}
                              value={robotOnline.number_of_searches}
                            />
                          </div>
                          <div className="robot-creation-component__online-robot_item col-1-4">
                            <div className="robot-creation-component__online-robot_label">
                              Repeat time <span style={{ opacity: 0.5 }}>(min)</span>
                            </div>
                            <InputNumber
                              onChange={this.handleChangeRepeatTime}
                              parser={(value) => value.replace(/[^0-9]/g, '')}
                              style={{ width: '100%' }}
                              min={1}
                              defaultValue={60}
                              value={robotOnline.repeat_time}
                              disabled={!robotOnline.number_of_searches || robotOnline.number_of_searches <= 1}
                            />
                          </div>
                        </div>
                      </TabPane>
                    </Tabs>
                  </div>
                </div>

                <div className="robot-creation-component__online-robot_row">
                  <div className="robot-creation-component__online-robot_item" style={{ width: '100%' }}>
                    <div className="robot-creation-component__online-robot_label">Description</div>
                    <TextArea
                      style={{ width: '100%', resize: 'none' }}
                      onChange={(e) => this.handleChangeDescription(e.target.value)}
                      value={robotOnline.description}
                      rows={5}
                    />
                  </div>
                </div>

                <div className="robot-creation-component__online-robot_row">
                  <Popconfirm
                    title="Are you sure you want to create this robot?"
                    onConfirm={this.createRobot}
                    okText="Yes"
                    cancelText="No"
                    placement="topLeft"
                  >
                    <Button size="mini" loading={this.state.loading}>
                      Create Robot
                    </Button>
                  </Popconfirm>
                </div>
              </div>
            </TabPane>
          )}
          {/* OFFLINE ROBOT */}
        </Tabs>
      </div>
    );
  }
}

RobotComponent.defaultProps = {
  contacts: {},
  groups: {},
  terminals: {},
  populateData: {
    id: '',
    data: false,
  },
  timeZone: '',
  createRobot: () => null,
  getContactTerminals: () => null,
  setVisibility: () => null,
};

RobotComponent.propTypes = {
  contacts: PropTypes.shape({}),
  groups: PropTypes.oneOfType([PropTypes.array, PropTypes.shape({})]),
  getContactTerminals: PropTypes.func,
  terminals: PropTypes.object,
  createRobot: PropTypes.func,
  populateData: PropTypes.shape({
    id: PropTypes.string,
    data: PropTypes.bool,
    from: PropTypes.string,
  }),
  timeZone: PropTypes.string,
  expandDropDown: PropTypes.func.isRequired,
  setVisibility: PropTypes.func,
};

export default RobotComponent;
