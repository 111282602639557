import React from 'react';
import ReactDOM from 'react-dom';

import { Router, Route } from 'react-router-dom';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';

import 'semantic-ui-css/semantic.min.css';
import 'antd/dist/antd.css';

import { setUserToAuthorized } from './actions/user/auth';

import history from './utils/history';
import rootReducer from './reducers/rootReducer';
import App from './App';

// Enable HOT-Reload for development
const storeConf = () => createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)));

// Browser Notification and Audio - CONFIG
export const notificationPermissionResult = Notification.permission;

const store = storeConf();
export default store;

if (sessionStorage.getItem('access_token')) {
  store.dispatch(setUserToAuthorized());
}

ReactDOM.render(
  <Provider store={store}>
    <Router history={history}>
      <Route component={App} />
    </Router>
  </Provider>,
  document.getElementById('root'),
);
