import React, { PureComponent } from 'react';

class JobResultE164Search extends PureComponent {
  render() {
    if (!this.props?.data?.additional_info?.data) return <div>Data is missing</div>;
    const {
      data: { additional_info: info },
    } = this.props;

    if (info && info.data) {
      if (info.data.found)
        return (
          <div className="job-content__default-result">
            {info.data.numberOwnership && !info.data.numberOwnership.errorCode ? (
              <div className="dashboard-page__search-result_info">
                <div className="job-content__default-result_header">Number Ownership</div>
                <div className="dashboard-page__search-result_info__tables">
                  <div>
                    <div className="table-item">
                      <div className="table-item__name">Country</div>
                      <div className="table-item__value">{info.data.numberOwnership.country}</div>
                    </div>
                    <div className="table-item">
                      <div className="table-item__name">NetworkBrand</div>
                      <div className="table-item__value">{info.data.numberOwnership.networkBrand}</div>
                    </div>
                    <div className="table-item">
                      <div className="table-item__name">NetworkName</div>
                      <div className="table-item__value">{info.data.numberOwnership.networkName}</div>
                    </div>
                    <div className="table-item">
                      <div className="table-item__name">NetworkId</div>
                      <div className="table-item__value">{info.data.numberOwnership.networkId}</div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              info.data.numberOwnership && (
                <div className="dashboard-page__search-result_info">
                  <div className="job-content__default-result_header">Subscription With</div>
                  <div className="job-content__error">
                    {info.data.numberOwnership.errorCode && (
                      <div>
                        <span className="job-content__error_title">Code:</span>{' '}
                        <span style={{ color: '#f4393f', fontWeight: 600 }}>{info.data.numberOwnership.errorCode}</span>
                      </div>
                    )}
                    {info.data.numberOwnership.errorDetail && (
                      <div>
                        <span className="job-content__error_title">Detail:</span>{' '}
                        {info.data.numberOwnership.errorDetail}
                      </div>
                    )}
                  </div>
                </div>
              )
            )}

            {info.data.subscriptionWith && !info.data.subscriptionWith.errorCode ? (
              <div className="dashboard-page__search-result_info">
                <div className="job-content__default-result_header">Subscription With</div>
                <div className="dashboard-page__search-result_info__tables">
                  <div>
                    <div className="table-item">
                      <div className="table-item__name">Country</div>
                      <div className="table-item__value">{info.data.subscriptionWith.country}</div>
                    </div>
                    <div className="table-item">
                      <div className="table-item__name">NetworkBrand</div>
                      <div className="table-item__value">{info.data.subscriptionWith.networkBrand}</div>
                    </div>
                    <div className="table-item">
                      <div className="table-item__name">NetworkName</div>
                      <div className="table-item__value">{info.data.subscriptionWith.networkName}</div>
                    </div>
                    <div className="table-item">
                      <div className="table-item__name">NetworkId</div>
                      <div className="table-item__value">{info.data.subscriptionWith.networkId}</div>
                    </div>
                    {info.data.subscriptionWith.plmnId && (
                      <div className="table-item">
                        <div className="table-item__name">PlmnId</div>
                        <div className="table-item__value">
                          mnc: {info.data.subscriptionWith.plmnId.mnc}, mcc: {info.data.subscriptionWith.plmnId.mcc}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              info.data.subscriptionWith && (
                <div className="dashboard-page__search-result_info">
                  <div className="job-content__default-result_header">Subscription With</div>
                  <div className="job-content__error">
                    {info.data.subscriptionWith.errorCode && (
                      <div>
                        <span className="job-content__error_title">Code:</span>{' '}
                        <span style={{ color: '#f4393f', fontWeight: 600 }}>
                          {info.data.subscriptionWith.errorCode}
                        </span>
                      </div>
                    )}
                    {info.data.subscriptionWith.errorDetail && (
                      <div>
                        <span className="job-content__error_title">Detail:</span>{' '}
                        {info.data.subscriptionWith.errorDetail}
                      </div>
                    )}
                  </div>
                </div>
              )
            )}
          </div>
        );
    }

    return <div>Result failed</div>;
  }
}

export default JobResultE164Search;
