import React, { PureComponent } from 'react';

class JobResultDosSearch extends PureComponent {
  render() {
    if (!this.props?.data) return <div>Data is missing</div>;

    const {
      data: { additional_info },
    } = this.props;

    if (!additional_info) return null;

    const { data: info } = additional_info;

    if (info && info.dosState !== null)
      return (
        <div className="job-content__default-result">
          <div className="dashboard-page__search-result_info">
            <div className="dashboard-page__search-result_info__tables">
              <div>
                <div className="table-item">
                  <div className="table-item__name">Dos State</div>
                  <div className="table-item__value">{info.dosState}</div>
                </div>
                {info.subNetworkInfo && (
                  <React.Fragment>
                    <div className="table-item">
                      <div className="table-item__name">Country</div>
                      <div className="table-item__value">{info.subNetworkInfo.country}</div>
                    </div>
                    <div className="table-item">
                      <div className="table-item__name">NetworkBrand</div>
                      <div className="table-item__value">{info.subNetworkInfo.networkBrand}</div>
                    </div>
                    <div className="table-item">
                      <div className="table-item__name">NetworkName</div>
                      <div className="table-item__value">{info.subNetworkInfo.networkName}</div>
                    </div>
                    <div className="table-item">
                      <div className="table-item__name">NetworkId</div>
                      <div className="table-item__value">{info.subNetworkInfo.networkId}</div>
                    </div>
                    {info.subNetworkInfo.plmnId && (
                      <div className="table-item">
                        <div className="table-item__name">PlmnId</div>
                        <div className="table-item__value">
                          mnc: {info.subNetworkInfo.plmnId.mnc}, mcc: {info.subNetworkInfo.plmnId.mcc}
                        </div>
                      </div>
                    )}
                  </React.Fragment>
                )}
              </div>
            </div>
          </div>
        </div>
      );
    else
      return (
        <div className="job-content__default-result">
          <div>Result: NULL</div>
        </div>
      );
  }
}

export default JobResultDosSearch;
