import { GET_AGENTS, STORE_AGENT, DELETE_AGENT, LOGOUT } from '../../constants/actionTypes';
import initialState from './initState';

export default function agents(state = initialState, action = {}) {
  switch (action.type) {
    case GET_AGENTS:
      return {
        ...state,
        ...action.payload,
      };
    case STORE_AGENT:
      return {
        ...state,
        [action.payload.id]: action.payload,
      };
    case DELETE_AGENT: {
      const newObj = { ...state };
      delete newObj[action.payload];
      return newObj;
    }
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
}
