import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Button } from 'semantic-ui-react';
import { SyncOutlined } from '@ant-design/icons';
import { Table, Pagination, Input, Tooltip, Button as AntdButton } from 'antd';

import { getSensors } from '../../actions/cellReport/cellReport';

import DropDown from '../../components/addDropDown/index';
import TimeStamp from '../../utils/timeStamp';

class SensorTable extends Component {
  state = {
    loading: true,
    cells: {
      per_page: 10,
      page: 1,
    },
    reportInfo: {
      total: 1,
      page: 1,
      direction: 'desc',
      column: 'sensor_time',
    },
    reportData: [],
    loadingRefresh: false,
  };

  componentDidMount() {
    this.props.getSensors({ per_page: 10, order_direction: 'desc', order_column: 'sensor_time' }).then((res) =>
      this.setState({
        loading: false,
        reportInfo: {
          ...this.state.reportInfo,
          total: res.meta.total,
          page: res.meta.current_page,
        },
        reportData: Object.values(res.data),
      }),
    );
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.cells.data) this.setState({ reportData: Object.values(nextProps.cells.data) });
  }

  registerFilter = (e) => {
    if (e.target.value === '') {
      const obj = { ...this.state.cells };
      delete obj[e.target.name];
      this.setState({ cells: { ...obj } });
    } else if (e.target.value === 'null') this.setState({ cells: { ...this.state.cells, [e.target.name]: null } });
    else if (e.target.value !== 'null')
      this.setState({ cells: { ...this.state.cells, [e.target.name]: e.target.value } });
  };

  submitFilter = (e) => {
    e.preventDefault();
    this.setState({ loading: true }, () => {
      const { direction, column } = this.state.reportInfo;
      const filters = { ...this.state.cells, order_direction: direction, order_column: column };
      delete filters.page;
      this.props
        .getSensors({ ...filters })
        .then((res) =>
          this.setState({
            loading: false,
            reportInfo: {
              total: res.meta.total,
              page: res.meta.current_page,
            },
            reportData: Object.values(res.data),
          }),
        )
        .catch(() => this.setState({ loading: false }));
    });
  };

  refreshData = () => {
    this.setState({ loadingRefresh: true }, () => {
      const { direction, column } = this.state.reportInfo;
      const filters = { ...this.state.cells, order_direction: direction, order_column: column };
      this.props.getSensors({ ...filters }).then(() => this.setState({ loadingRefresh: false }));
    });
  };

  onPaginationChange = (current, pageSize) => {
    this.setState({ loading: true }, () => {
      const { direction, column } = this.state.reportInfo;
      this.props
        .getSensors({
          ...this.state.cells,
          per_page: pageSize,
          page: current,
          order_direction: direction,
          order_column: column,
        })
        .then(() => {
          this.setState({
            loading: false,
            cells: {
              ...this.state.cells,
              per_page: pageSize,
              page: current,
            },
            reportInfo: {
              ...this.state.reportInfo,
              page: current,
            },
          });
        });
    });
  };

  sortAction = (a, b, c) => {
    this.setState({ loading: true }, () => {
      const filters = {
        ...this.state.cells,
        order_column: c.columnKey,
        order_direction: c.order === 'ascend' ? 'asc' : 'desc',
        page: this.state.cells.page,
      };
      this.props
        .getSensors({ ...filters })
        .then((res) =>
          this.setState({
            loading: false,
            reportInfo: {
              total: res.meta.total,
              page: res.meta.current_page,
              direction: c.order === 'ascend' ? 'asc' : 'desc',
              column: c.columnKey,
            },
            reportData: Object.values(res.data),
          }),
        )
        .catch(() => this.setState({ loading: false }));
    });
  };

  render() {
    const { loadingRefresh, reportData, loading, reportInfo, cells, uploaded } = this.state;
    const columns = [
      { title: 'ID', dataIndex: 'id', key: 'id', sorter: true, width: '6%' },
      { title: 'Sensor ID', dataIndex: 'sensor_id', key: 'sensor_id', sorter: true, width: '17%' },
      { title: 'Lat', dataIndex: 'lat', key: 'lat', sorter: true, width: '12%' },
      { title: 'Lng', dataIndex: 'long', key: 'long', sorter: true, width: '12%' },
      {
        title: 'Sensor time',
        dataIndex: 'sensor_time',
        key: 'sensor_time',
        sorter: true,
        width: '15%',
        render: (text) => <TimeStamp date={text} />,
      },
      { title: 'Description', dataIndex: 'description', key: 'description', sorter: true, width: '38%' },
    ];

    const toolTipText = 'this field can match partial strings';

    return (
      <React.Fragment>
        <DropDown className="cell-data-page__drop-down" createSuccess={uploaded} buttonName="Filters" height={110}>
          <form onSubmit={this.submitFilter} className="table-cell-form">
            <div onChange={this.registerFilter} className="table-cell-form__inputs">
              <Tooltip placement="top" title={toolTipText}>
                <Input
                  name="sensor_id"
                  style={{ width: 300 }}
                  addonBefore={<span style={{ color: '#1890ff' }}>Sensor ID</span>}
                />
              </Tooltip>
              <Tooltip placement="top" title={toolTipText}>
                <Input
                  name="sensor_time"
                  style={{ width: 300 }}
                  addonBefore={<span style={{ color: '#1890ff' }}>Sensor time</span>}
                />
              </Tooltip>
              <Tooltip placement="top" title={toolTipText}>
                <Input
                  name="description"
                  style={{ width: 400 }}
                  addonBefore={<span style={{ color: '#1890ff' }}>Description</span>}
                />
              </Tooltip>
            </div>
            <Button style={{ marginTop: 10 }} loading={loading} type="submit" size="mini">
              Filter
            </Button>
          </form>
        </DropDown>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <AntdButton
            type="primary"
            shape="circle"
            icon={<SyncOutlined />}
            loading={loadingRefresh}
            onClick={this.refreshData}
          />
          <Pagination
            className="cell-pagination"
            showSizeChanger
            pageSizeOptions={['10', '20', '50', '100', '500']}
            pageSize={cells.per_page}
            onChange={this.onPaginationChange}
            onShowSizeChange={this.onPaginationChange}
            current={reportInfo.page}
            total={reportInfo.total}
          />
        </div>
        <Table
          loading={loading}
          rowKey={'id'}
          columns={columns}
          dataSource={reportData}
          scroll={{ x: 1280 }}
          pagination={false}
          onChange={this.sortAction}
        />
      </React.Fragment>
    );
  }
}

SensorTable.defaultProps = {
  cells: {},
};

SensorTable.propTypes = {
  getSensors: PropTypes.func.isRequired,
  cells: PropTypes.shape({
    current_page: PropTypes.number,
    data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    per_page: PropTypes.number,
  }),
};

const mapStateToProps = (store) => ({
  cells: store.cellReport.sensorCells,
  user: store.user,
});

export default connect(mapStateToProps, { getSensors })(SensorTable);
