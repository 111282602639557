import api from '../../utils/appApi';
import { SEARCH_UNSAVED_CONTACT_RESULT } from '../../constants/actionTypes';

export const searchUnsavedContacts = (page, perPage, searchText) => (dispatch) =>
  new Promise((resolve, reject) =>
    api.unsavedContacts
      .searchUnsavedContacts(page, perPage, searchText)
      .then((res) => {
        dispatch({
          type: SEARCH_UNSAVED_CONTACT_RESULT,
          payload: res.data,
        });
        return resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      }),
  );
