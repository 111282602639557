import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class JobErrorContent extends PureComponent {
  render() {
    const {
      data: { code, title, detail },
    } = this.props;
    return (
      <div className="job-content__error">
        {code && (
          <div>
            <span className="job-content__error_title">Code:</span>{' '}
            <span style={{ color: '#f4393f', fontWeight: 600 }}>{code}</span>
          </div>
        )}
        {title && (
          <div>
            <span className="job-content__error_title">Title:</span> {title}
          </div>
        )}
        {detail && (
          <div>
            <span className="job-content__error_title">Detail:</span> {detail}
          </div>
        )}
      </div>
    );
  }
}

JobErrorContent.propTypes = {
  data: PropTypes.shape({
    code: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    title: PropTypes.string,
    detail: PropTypes.string,
  }).isRequired,
};

export default JobErrorContent;
