import React from 'react';
import {
  GET_WHITE_LIST,
  ADD_TO_WHITE_LIST,
  DELETE_FROM_WHITE_LIST,
  FIRE_ALERT,
  CREATE_TEAM_WHITELIST,
  GET_TEAM_WHITE_LIST,
  DELETE_TEAM_NUMBER_FROM_WHITE_LIST,
} from '../../constants/actionTypes';
import api from '../../utils/appApi';

export const getWhiteList = () => (dispatch) =>
  api.whiteList.getWhiteList().then((res) =>
    dispatch({
      type: GET_WHITE_LIST,
      payload: res.data,
    }),
  );

export const adToWhiteList = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    api.whiteList
      .adToWhiteList(data)
      .then((res) => {
        const errors = res.data.errors;
        delete res.data.errors;
        delete res.data.stats;
        dispatch({
          type: ADD_TO_WHITE_LIST,
          payload: res.data,
        });
        if (errors !== null) {
          let errorText = '';
          Object.values(errors).forEach((error) =>
            errorText
              ? (errorText = (
                  <>
                    {errorText}
                    <br />
                    {error}
                  </>
                ))
              : (errorText = error),
          );
          dispatch({
            type: FIRE_ALERT,
            payload: {
              specificMessage: <div style={{ fontSize: 11 }}>{errorText}</div>,
              message: 'Error',
            },
          });
        }
        return resolve(res.data);
      })
      .catch((err) => reject(err));
  });

export const deleteFromWhiteList = (whiteListId) => (dispatch) =>
  new Promise((resolve, reject) => {
    api.whiteList
      .deleteFromWhiteList(whiteListId)
      .then((res) => {
        dispatch({
          type: DELETE_FROM_WHITE_LIST,
          payload: res.data,
        });
        return resolve(res.data);
      })
      .catch((err) => reject(err));
  });

export const getTeamWhiteList = () => (dispatch) =>
  api.whiteList
    .getTeamWhiteList()
    .then((res) =>
      dispatch({
        type: GET_TEAM_WHITE_LIST,
        payload: res.data.data,
      }),
    )
    .catch((err) => err);

export const createTeamWhiteList = (data, id) => (dispatch) =>
  new Promise((resolve, reject) => {
    api.whiteList
      .createTeamWhiteList(data, id)
      .then((res) => {
        dispatch({
          type: CREATE_TEAM_WHITELIST,
          payload: res.data.data,
        });
        return resolve(res.data.data);
      })
      .catch((err) => reject(err));
  });

export const deleteTeamNumberFromWhiteList = (id) => (dispatch) =>
  new Promise((resolve, reject) => {
    api.whiteList
      .deleteTeamFromWhiteList(id)
      .then((res) => {
        dispatch({
          type: DELETE_TEAM_NUMBER_FROM_WHITE_LIST,
          payload: res.data.data,
        });
        return resolve(res.data.data);
      })
      .catch((err) => reject(err));
  });
