import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Truncate from 'react-truncate';

class TruncateComponent extends Component {
  state = {
    expanded: false,
    truncated: false,
  };

  handleTruncate = (truncated) => {
    if (this.state.truncated !== truncated) this.setState({ truncated });
  };

  toggleLines = (event) => {
    event.preventDefault();
    this.setState({ expanded: !this.state.expanded });
  };

  render() {
    const { children, more, less, lines } = this.props;
    const { expanded, truncated } = this.state;

    return (
      <div>
        <Truncate
          lines={!expanded && lines}
          ellipsis={
            <span>
              ...
              <span
                role="button"
                tabIndex={0}
                onClick={this.toggleLines}
                style={{
                  marginLeft: 5,
                  color: '#40a9ff',
                  fontSize: 12,
                  cursor: 'pointer',
                }}
              >
                {more}
              </span>
            </span>
          }
          onTruncate={this.handleTruncate}
        >
          {children}
        </Truncate>
        {!truncated && expanded && (
          <span>
            <span
              role="button"
              tabIndex={0}
              onClick={this.toggleLines}
              style={{
                marginLeft: 5,
                color: '#40a9ff',
                fontSize: 12,
                cursor: 'pointer',
              }}
            >
              {less}
            </span>
          </span>
        )}
      </div>
    );
  }
}

TruncateComponent.defaultProps = {
  lines: 2,
  more: 'Read more',
  less: 'Show less',
};

TruncateComponent.propTypes = {
  children: PropTypes.node.isRequired,
  lines: PropTypes.number,
  more: PropTypes.string,
  less: PropTypes.string,
};

export default TruncateComponent;
