import axios from 'axios';
import history from '../../utils/history';
import logoutSession from '../../utils/logoutSesion';

import {
  LOGIN,
  LOGOUT,
  CHANGE_TIMEZONE,
  CHANGE_USER,
  FIRE_ALERT,
  STICKY_HEADER,
  SOUND_NOTIFICATION,
  BROWSER_NOTIFICATION,
} from '../../constants/actionTypes';
import api from '../../utils/appApi';
import { echo } from '../../utils/socketConfig';
import { getServiceStatus } from '../serviceStatus/serviceStatus';

export const login = (loginData) => (dispatch) =>
  api.user.login(loginData).then((res) => {
    sessionStorage.setItem('access_token', res.data?.access_token);
    sessionStorage.setItem('refresh_token', res.data?.refresh_token);
    sessionStorage.setItem('isAuthorized', 'true');
    axios.defaults.headers.common.Authorization = `Bearer ${res.data?.access_token}`;
    return api.user.getUser().then((res) => {
      sessionStorage.setItem('hash', res.data.data?.hash);
      return dispatch({
        type: LOGIN,
        payload: res.data.data,
      });
    });
  });
export const getUser = () => (dispatch) =>
  new Promise((resolve) =>
    api.user
      .getUser()
      .then((res) => {
        sessionStorage.setItem('hash', res.data.data?.hash);
        dispatch(getServiceStatus());
        dispatch({
          type: LOGIN,
          payload: res.data.data,
        });
        return resolve(res.data.data);
      })
      .catch((err) => Promise.reject(err)),
  );

export const editUser = (userId, data) => (dispatch) =>
  new Promise((resolve, reject) => {
    api.user
      .editUser(userId, data)
      .then((res) => {
        dispatch({
          type: CHANGE_USER,
          payload: res.data.data,
        });
        return resolve(res.data.data);
      })
      .catch((err) => {
        const emailError = !err.response.data.errors.email ? '' : err.response.data.errors.email[0];
        const phoneError = !err.response.data.errors.phone ? '' : err.response.data.errors.phone[0];
        dispatch({
          type: FIRE_ALERT,
          payload: {
            specificMessage: `${emailError} ${phoneError}`,
            message: err.response.data.message,
          },
        });
        return reject(err);
      });
  });

export const setUserToAuthorized = () => (dispatch) =>
  dispatch({
    type: LOGIN,
    payload: {},
  });

export const changeTimeZone = (id, timezone) => (dispatch) =>
  api.user.changeTimeZone(id, timezone).then((res) =>
    dispatch({
      type: CHANGE_TIMEZONE,
      payload: res.data.data,
    }),
  );

export const setUserToNotAuthorized = () => (dispatch) => {
  dispatch({
    type: LOGOUT,
    payload: {},
  });
  logoutSession();
  history.push('/login');
};

export const logout = () => (dispatch) =>
  api.user.logOut().then(() => {
    echo.disconnect();
    logoutSession();
    dispatch({
      type: LOGOUT,
      payload: null,
    });
  });

export const switchHeader = (id, checkedValue) => (dispatch) =>
  api.user.switchHeader(id, checkedValue).then((res) => {
    return dispatch({
      type: STICKY_HEADER,
      payload: res.data.data?.sticky_header,
    });
  });

export const switchSoundNotification = (id, checkedValue) => (dispatch) =>
  api.user.switchSoundNotification(id, checkedValue).then((res) =>
    dispatch({
      type: SOUND_NOTIFICATION,
      payload: res.data.data?.sound,
    }),
  );

export const switchBrowserNotification = (id, checkedValue) => (dispatch) =>
  api.user.switchBrowserNotification(id, checkedValue).then((res) =>
    dispatch({
      type: BROWSER_NOTIFICATION,
      payload: res.data.data?.browser_notification,
    }),
  );
