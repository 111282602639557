import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { DeleteOutlined, PlusCircleOutlined, RightCircleOutlined } from '@ant-design/icons';

import { Popconfirm, Popover, Table } from 'antd';

import { createRobot, deleteRobot, getContactTerminals, getRobots } from '../../actions/robots/robots';

import RobotCreation from '../robotCreation';
import getColumnSearchProps from '../dashboardTables/getColumnSearchProps';
import TimeStamp from '../../utils/timeStamp';
import PermissionHandler from '../../utils/permissionsHandler';
import history from '../../utils/history';

import './dashboardRobots.sass';
import { robotTypes } from '../../containers/RobotsPage/robotsPage';

const statusObject = [
  { status_code: 0, status: 'Inactive', status_color: '#878787' },
  { status_code: 1, status: 'Active running', status_color: '#d0d068' },
  { status_code: 2, status: 'Finished success', status_color: '#87d068' },
  { status_code: 3, status: 'Finished time end', status_color: '#f4a641' },
  { status_code: 4, status: 'Cannot run', status_color: '#f50' },
  { status_code: 5, status: 'Schedule finished', status_color: '#87d068' },
];

class DashboardRobots extends PureComponent {
  state = {
    searchText: '',
    searchedColumn: '',
    pagination: {
      current: 1,
      pageSize: 10,
    },
    populateData: {
      data: false,
      from: '',
      id: '',
    },
    isVisiblePopover: false,
  };

  componentDidMount() {
    this.props.getRobots();
  }

  handleTableChange = (pagination) => {
    this.setState({ pagination });
  };

  viewRobot = (id) => history.push(`/robots/${id}`);

  setVisiblePopover = (status) => {
    this.setState({ isVisiblePopover: status });
  };

  setHiddenPopover = (status) => {
    this.setState({
      isVisiblePopover: status,
    });
  };

  render() {
    const { robots } = this.props;
    const {
      pagination: { pageSize },
    } = this.state;
    const columns = [
      {
        title: 'Robot name',
        dataIndex: 'name',
        width: 200,
        ...getColumnSearchProps('name', this),
      },
      {
        title: 'Query',
        dataIndex: 'query_type',
        width: 90,
        filters: [
          { text: 'active', value: 'active' },
          { text: 'passive', value: 'passive' },
        ],
        onFilter: (value, record) => record.query_type.indexOf(value) === 0,
      },
      {
        title: 'Status',
        dataIndex: 'status',
        width: 170,
        filters: statusObject.map((status) => ({ text: status.status, value: status.status_code })),
        onFilter: (value, record) => record.status === value,
        render: (value, data) => {
          return statusObject.map((statusCode) => {
            if (data.status === statusCode.status_code) {
              return (
                <span key={`status-code-${statusCode}`} style={{ color: `${statusCode.status_color}` }}>
                  {statusCode.status}
                </span>
              );
            }
            return null;
          });
        },
      },
      {
        title: 'Type',
        dataIndex: 'type',
        width: 130,
        filters: robotTypes.map((type) => ({ text: type, value: type })),
        onFilter: (value, record) => record.type === value,
      },
      {
        title: 'Repeat',
        dataIndex: 'repeat_time',
        width: 100,
        render: (time) => (
          <div>
            {time}
            <span style={{ color: 'grey', fontSize: 13 }}>(min)</span>
          </div>
        ),
      },
      {
        title: 'Start',
        dataIndex: 'start',
        width: 165,
        sorter: (a, b) => new Date(a.start) - new Date(b.start),
        render: (startTime) => <TimeStamp date={startTime} />,
      },
      {
        title: 'End',
        dataIndex: 'end',
        width: 165,
        sorter: (a, b) => new Date(a.end) - new Date(b.end),
        render: (endTime) => <TimeStamp date={endTime} />,
      },
      {
        title: '',
        dataIndex: 'id',
        width: 50,
        render: (id) => (
          <div className="dashboard-tables__table_actions-wrapper" style={{ textAlign: 'center' }}>
            <RightCircleOutlined className="dashboard-tables__table_action-enter" onClick={() => this.viewRobot(id)} />
            <PermissionHandler button="contact-delete">
              <Popconfirm
                style={{ zIndex: 100 }}
                title="Are you sure you want to delete this Robot?"
                okText="Yes"
                cancelText="No"
                onConfirm={() => this.props.deleteRobot(id)}
                placement="topRight"
              >
                <DeleteOutlined className="dashboard-tables__table_action-delete" />
              </Popconfirm>
            </PermissionHandler>
          </div>
        ),
      },
    ];

    return (
      <div className="dashboard-robots">
        <div className="dashboard-robots__create-wrapper">
          <Popover
            content={
              <div className="dashboard-robots__create-form">
                <RobotCreation
                  populateData={this.state.populateData}
                  groups={this.props.groups}
                  contacts={this.props.contacts}
                  getContactTerminals={this.props.getContactTerminals}
                  terminals={this.props.terminals}
                  createRobot={this.props.createRobot}
                  timeZone={this.props.timeZone}
                  expandDropDown={(amount) => this.setState({ dropDownHeight: this.state.dropDownHeight + amount })}
                  setVisibility={this.setHiddenPopover}
                />
              </div>
            }
            title={null}
            className="dashboard-robots__create-action"
            placement="left"
            trigger="click"
            visible={this.state.isVisiblePopover}
            onVisibleChange={this.setVisiblePopover}
          >
            <PlusCircleOutlined />
            <span className="dashboard-robots__create-action_label">Create robot</span>
          </Popover>
        </div>
        <Table
          onChange={this.handleTableChange}
          columns={columns}
          dataSource={robots}
          pagination={
            robots && robots.length > 10
              ? {
                  pageSize: pageSize,
                  showSizeChanger: true,
                  defaultPageSize: 10,
                  pageSizeOptions: ['5', '10', '20', '50'],
                }
              : false
          }
          size="small"
          rowKey="id"
          loading={robots === null}
          scroll={{ x: 'auto' }}
        />
      </div>
    );
  }
}

DashboardRobots.propTypes = {
  getRobots: PropTypes.func.isRequired,
  getContactTerminals: PropTypes.func.isRequired,
  createRobot: PropTypes.func.isRequired,
  deleteRobot: PropTypes.func.isRequired,
  robots: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      status: PropTypes.number,
      query_type: PropTypes.string,
      description: PropTypes.string,
    }),
  ),
  contacts: PropTypes.shape({}),
  groups: PropTypes.oneOfType([PropTypes.array, PropTypes.shape({})]),
  terminals: PropTypes.array,
  timeZone: PropTypes.string,
};

const mapStateToProps = (store) => ({
  robots: store.robots.allRobots ? Object.values(store.robots.allRobots).reverse() : null,
  contacts: store.contact.allContacts.contacts,
  terminals: store.robots.contactTerminals,
  groups: store.groups.allGroups,
  timeZone: store.user.timezone,
});

export default connect(mapStateToProps, { getRobots, createRobot, deleteRobot, getContactTerminals })(DashboardRobots);
