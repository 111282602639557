import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router';

import { Result, Button } from 'antd';

import NavigationMenu from '../../components/navigationMenu';

const NotFoundPage = () => {
  const history = useHistory();
  const goToDashboard = () => history.push('/');
  return (
    <div>
      <NavigationMenu statusPage="not-found" />
      <NotFoundWrapper>
        <Result
          status="404"
          title="404"
          subTitle="Sorry, the page you visited does not exist."
          extra={
            <Button type="primary" onClick={goToDashboard}>
              Go to Dashboard
            </Button>
          }
        />
      </NotFoundWrapper>
    </div>
  );
};

const NotFoundWrapper = styled.div`
  width: 100%;
  height: 100vh;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export default NotFoundPage;
