import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { Checkbox, DatePicker, Popover } from 'antd';

import {
  NodeIndexOutlined,
  CloseCircleOutlined,
  PlayCircleOutlined,
  LeftOutlined,
  RightOutlined,
} from '@ant-design/icons';

import { MapButtonStyled } from './mapButton';

const { RangePicker } = DatePicker;

const PlayItemDescription = ({ data }) => {
  if (!data) return null;
  return (
    <div style={{ maxWidth: '240px' }}>
      <div>
        <span style={{ fontWeight: 600 }}>Name: </span>
        <span>{data.name}</span>
      </div>
      <div>
        <span style={{ fontWeight: 600 }}>Network: </span>
        <span>{data.network}</span>
      </div>
      <div>
        <span style={{ fontWeight: 600 }}>Lat/Lng: </span>
        <span>
          {data.lat}/{data.lng}
        </span>
      </div>
    </div>
  );
};

const PlotRouteInterface = ({
  active,
  showRoot,
  groupSameLocationsToggle,
  filterByDateAndTime,
  isGrouped,
  rangePickerDates,
  markers,
  onPlayStart,
  onPlayStop,
  onPlayDirectionAction,
  playItemPosition,
  playState,
  setPlayState,
  filteredMarkersCount,
  currentActiveMarker,
}) => {
  const checkIfAllEmpty = useMemo(() => {
    return markers?.map(({ lat, lng }) => [lat, lng]).filter((ar) => ar[0] && ar[1]).length === 0;
  }, [markers]);

  if (!active)
    return (
      <PlotRouteButton onClick={showRoot} disabled={checkIfAllEmpty}>
        <NodeIndexOutlined /> <span>Plot route</span>
      </PlotRouteButton>
    );

  const playRoutes = () => {
    setPlayState(true);
    onPlayStart();
  };

  const stopPlayRoutes = () => {
    setPlayState(false);
    onPlayStop();
  };

  const forwardAction = () => onPlayDirectionAction('forward');
  const backwardAction = () => onPlayDirectionAction('backward');

  return (
    <>
      <PlotRouteWrapper>
        <StyledRangePicker value={rangePickerDates} onChange={filterByDateAndTime} showTime format="YYYY-MM-DD HH:mm" />
        <FilterWrapper>
          <Checkbox checked={isGrouped} onChange={groupSameLocationsToggle}>
            Group same locations
          </Checkbox>
        </FilterWrapper>
        <CloseRouteButton active onClick={showRoot}>
          <CloseCircleOutlined />
        </CloseRouteButton>
      </PlotRouteWrapper>

      <PlotRoutePlayWrapper>
        <PlotRoutePlayButton active={playState} onClick={playState ? stopPlayRoutes : playRoutes}>
          {!playState ? <PlayCircleOutlined /> : <CloseCircleOutlined />}
        </PlotRoutePlayButton>
        {playState && (
          <>
            <PlotRoutePlayButton disabled={playItemPosition === 0} onClick={backwardAction}>
              <LeftOutlined />
            </PlotRoutePlayButton>
            <PlotRoutePlayButton disabled={filteredMarkersCount === playItemPosition + 1} onClick={forwardAction}>
              <RightOutlined />
            </PlotRoutePlayButton>
            <Popover content={<PlayItemDescription data={currentActiveMarker} />} title="Marker Info">
              <PositionNumber>{playItemPosition + 1}</PositionNumber>
            </Popover>
          </>
        )}
      </PlotRoutePlayWrapper>
    </>
  );
};

const PlotRouteWrapper = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 1000;

  display: flex;
  align-items: center;
`;

const PlotRoutePlayWrapper = styled.div`
  position: absolute;
  left: 10px;
  bottom: 10px;
  z-index: 1000;

  display: flex;
  align-items: center;
`;

const PlotRoutePlayButton = styled(MapButtonStyled)`
  position: initial;
  display: flex;
  align-items: center;
  justify-content: center;

  margin-right: 10px;

  width: 30px;
  height: 30px;
  font-size: 14px;

  svg {
    font-size: 18px;

    path {
      fill: black;
    }
  }

  ${({ active }) =>
    active &&
    css`
      svg {
        path {
          fill: white;
        }
      }
    `}
`;

const PositionNumber = styled.div`
  width: 22px;
  height: 22px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 600;
  color: white;
  background-color: #92c453;
  cursor: pointer;
`;

const PlotRouteButton = styled(MapButtonStyled)`
  width: 100px;
  height: 30px;
  font-size: 14px;

  svg {
    margin-right: 5px;
    font-size: 18px;

    path {
      fill: black;
    }
  }

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `}
`;

const CloseRouteButton = styled(MapButtonStyled)`
  width: 30px;
  height: 30px;
  font-size: 14px;

  position: initial;

  svg {
    font-size: 18px;
    margin: 0;

    path {
      fill: #2f2f2f;
    }
  }

  ${({ active }) =>
    active &&
    css`
      background: #1890ff;

      svg {
        path {
          fill: white;
        }
      }

      &:hover {
        background: #1687ef !important;
      }
    `}
`;

const FilterWrapper = styled.div`
  width: 200px;
  height: 30px;
  background: white;

  box-shadow: rgb(0 0 0 / 24%) 0 3px 8px;
  border-radius: 2px;

  margin-right: 10px;

  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledRangePicker = styled(RangePicker)`
  margin-right: 10px;
  box-shadow: rgb(0 0 0 / 24%) 0 3px 8px;
`;

PlotRouteInterface.propTypes = {
  active: PropTypes.bool.isRequired,
  showRoot: PropTypes.func.isRequired,
};

export default memo(PlotRouteInterface);
