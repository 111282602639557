import React, { memo } from 'react';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import { Marker, Popup } from 'react-leaflet';
import styled from 'styled-components';

import TimeStamp from '../../utils/timeStamp';

import PlotRouteInterface from './components/plotRouteInterface';

const MarkerCluster = ({ markers, maxClusterRadius, showRoot, disablePlotRoute }) => (
  <MarkerClusterGroup maxClusterRadius={maxClusterRadius}>
    {markers?.length && markers.length > 2 && !disablePlotRoute ? (
      <PlotRouteInterface active={false} showRoot={showRoot} markers={markers.filter((marker) => marker !== null)} />
    ) : (
      ''
    )}
    {markers?.length
      ? markers
          .filter((marker) => marker !== null)
          .map(({ id, lat, lng, name, location_time, phone, imsi }) =>
            lat ? (
              <div key={id}>
                <Marker position={[lat, lng]}>
                  {name && (
                    <StyledPopup>
                      <div>{name}</div>
                      <div>{phone ?? imsi}</div>
                      <TimeStamp style={{ fontWeight: 600 }} date={location_time} />
                    </StyledPopup>
                  )}
                </Marker>
              </div>
            ) : (
              ''
            ),
          )
      : ''}
  </MarkerClusterGroup>
);

const StyledPopup = styled(Popup)`
  span {
    font-weight: 600;
  }
`;

export default memo(MarkerCluster);
