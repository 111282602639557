import React, { PureComponent } from 'react';

import { Popover, Tooltip } from 'antd';
import { Link } from 'react-router-dom';

import './jobContacts.sass';
import history from '../../../../utils/history';

class JobContactsItem extends PureComponent {
  goToContact = (e, id) => {
    e.stopPropagation();
    history.push(`/contact/${id}`);
  };

  render() {
    const { data } = this.props;
    const itemContacts = data && Object.values(data).length ? Object.values(data) : null;

    if (itemContacts) {
      const circleContacts = [];
      itemContacts.forEach((contact, index) => {
        if (index <= 2)
          circleContacts.push(
            <Tooltip key={contact.id} placement="top" title={contact.name}>
              <div className="job-item-contacts__logos_circle" onClick={(e) => this.goToContact(e, contact.id)}>
                {contact.name?.charAt(0)?.toUpperCase()}
              </div>
            </Tooltip>,
          );
      });

      return (
        <div className="job-item-contacts">
          <div className="job-item-contacts__logos-wrapper">
            <div className="job-item-contacts__logos">{circleContacts}</div>
            {itemContacts.length > 3 && (
              <Popover
                content={
                  <React.Fragment>
                    {itemContacts.map((contact) => (
                      <Link key={contact.id} to={`/contact/${contact.id}`}>
                        {contact.name}
                      </Link>
                    ))}
                  </React.Fragment>
                }
                title="Related Contacts"
                trigger="hover"
                overlayClassName="job-item-contacts__popup"
              >
                <div className="job-item-contacts__more">+{itemContacts.length - 3}</div>
              </Popover>
            )}
          </div>
          <div className="job-item-contacts__text">
            {itemContacts.length ? <span>{itemContacts.length}</span> : ''} Contacts
          </div>
        </div>
      );
    } else {
      return (
        <div className="job-item-contacts">
          <div className="job-item-contacts__logos job-item-contacts__logos--empty">
            <div className="job-item-contacts__logos_circle" />
            <div className="job-item-contacts__logos_circle" />
            <div className="job-item-contacts__logos_circle" />
          </div>
          <div className="job-item-contacts__text">No Contacts</div>
        </div>
      );
    }
  }
}

export default JobContactsItem;
