import { GET_WHITE_LIST, ADD_TO_WHITE_LIST, DELETE_FROM_WHITE_LIST, LOGOUT } from '../../constants/actionTypes';

export default function whiteList(state = {}, action = {}) {
  switch (action.type) {
    case GET_WHITE_LIST:
      return {
        ...state,
        ...action.payload,
      };
    case ADD_TO_WHITE_LIST:
      return {
        ...state,
        ...action.payload,
      };
    case DELETE_FROM_WHITE_LIST: {
      const newObj = state;
      delete newObj[action.payload.id];
      return {
        ...state,
        ...newObj,
      };
    }
    case LOGOUT:
      return {};
    default:
      return state;
  }
}
