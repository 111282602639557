import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import './doughnut.sass';

const FIRST_RADIUS = 30;
const FIRST_PERIMETER = 2 * 3.14 * FIRST_RADIUS;

const SECOND_RADIUS = 22;
const SECOND_PERIMETER = 2 * 3.14 * SECOND_RADIUS;

const getColor = (percent) => {
  const r = percent < 50 ? 225 : Math.floor(255 - ((percent * 2 - 50) * 150) / 100);
  const g = percent > 50 ? 186 : Math.floor((percent * 2 * 180) / 100);
  return 'rgb(' + r + ',' + g + ',69)';
};

class Doughnut extends PureComponent {
  render() {
    const { major, minor, progress } = this.props;
    const firstDashOffset = FIRST_PERIMETER - (FIRST_PERIMETER * minor) / 100;
    const secondDashOffset = SECOND_PERIMETER - (SECOND_PERIMETER * major) / 100;

    return (
      <div className="doughnut-component">
        <svg className="doughnut-component__svg" width="100px" height="70px" viewBox="0 0 100 100">
          <circle
            className="doughnut-component__svg_circle"
            cx="50"
            cy="50"
            transform="rotate(-90 50 50)"
            r={FIRST_RADIUS}
            fill="transparent"
            strokeWidth="5"
            stroke="rgb(220,220,220)"
            strokeDasharray={FIRST_PERIMETER}
            strokeDashoffset={0}
          />
          <circle
            className="doughnut-component__svg_circle"
            cx="50"
            cy="50"
            transform="rotate(-90 50 50)"
            r={FIRST_RADIUS}
            fill="transparent"
            strokeWidth="5"
            stroke={getColor(major)}
            strokeDasharray={FIRST_PERIMETER}
            strokeDashoffset={firstDashOffset}
          />
          <circle
            className="doughnut-component__svg_circle"
            cx="50"
            cy="50"
            transform="rotate(-90 50 50)"
            r={SECOND_RADIUS}
            fill="transparent"
            strokeWidth="5"
            stroke="rgb(220,220,220)"
            strokeDasharray={SECOND_PERIMETER}
            strokeDashoffset={0}
          />
          <circle
            className="doughnut-component__svg_circle"
            cx="50"
            cy="50"
            transform="rotate(-90 50 50)"
            r={SECOND_RADIUS}
            fill="transparent"
            strokeWidth="5"
            stroke="#33adff"
            strokeDasharray={SECOND_PERIMETER}
            strokeDashoffset={secondDashOffset}
          />
          <text x="49%" y="55%" textAnchor="middle" fill="black" fontSize="16">
            {progress}
          </text>
        </svg>
      </div>
    );
  }
}

Doughnut.propTypes = {
  major: PropTypes.number.isRequired,
  minor: PropTypes.number.isRequired,
};

export default Doughnut;
