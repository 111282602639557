const setRange = (r) => {
  let zoom = 16;
  const range = parseInt(r, 10);
  if (parseInt(range, 10) > 200000) zoom = 5;
  else if (range > 100000) zoom = 7;
  else if (range > 50000) zoom = 8;
  else if (range > 30000) zoom = 9;
  else if (range > 10000) zoom = 10;
  else if (range > 5000) zoom = 11;
  else if (range > 2000) zoom = 12;
  else if (range > 1200) zoom = 13;
  else if (range > 700) zoom = 14;
  else if (range > 300) zoom = 15;
  else if (range > 200) zoom = 16;
  else if (range <= 200) zoom = 17;

  return zoom;
};

export default setRange;
