import React from 'react';
import PropTypes from 'prop-types';

const InlineError = ({ style, text }) => (
  <span className="login-span" style={{ color: '#ae5856', ...style }}>
    {text}
  </span>
);

InlineError.defaultProps = {
  style: {},
};

InlineError.propTypes = {
  text: PropTypes.string.isRequired,
  style: PropTypes.shape({}),
};

export default InlineError;
