import {
  GET_ROBOTS,
  GET_SINGLE_ROBOT,
  GET_CONTACT_TERMINALS,
  CREATE_ROBOT,
  FIRE_ALERT,
  DELETE_ROBOT,
  CLONE_ROBOT,
  DELETE_ROBOT_GROUP,
  EDIT_ROBOT,
  UPDATE_ROBOT_DATA,
} from '../../constants/actionTypes';
import api from '../../utils/appApi';

export const getRobots = () => (dispatch) =>
  api.robots.getRobots().then((res) =>
    dispatch({
      type: GET_ROBOTS,
      payload: res.data.data,
    }),
  );
export const getSingleRobot = (robotId) => (dispatch) =>
  api.robots.getSingleRobot(robotId).then((res) =>
    dispatch({
      type: GET_SINGLE_ROBOT,
      payload: res.data.data,
    }),
  );
export const getContactTerminals = (contactId) => (dispatch) =>
  new Promise((resolve) =>
    api.robots.getContactTerminals(contactId).then((res) => {
      dispatch({
        type: GET_CONTACT_TERMINALS,
        payload: res.data.data,
      });
      return resolve(res.data.data);
    }),
  );

export const createRobot = (data) => (dispatch) => {
  return new Promise((resolve, reject) =>
    api.robots
      .createRobot(data)
      .then((res) => {
        dispatch({
          type: CREATE_ROBOT,
          payload: res.data.data,
        });
        return resolve(res.data.data);
      })
      .catch((err) => {
        if (err.response?.data?.errors) {
          const nameError = !err.response.data.errors.name ? '' : err.response.data.errors.name[1];
          const endError = !err.response.data.errors.end ? '' : err.response.data.errors.end[0];
          const startError = !err.response.data.errors.start ? '' : err.response.data.errors.start[0];
          const sourceIdError = !err.response.data.errors.source_id ? '' : err.response.data.errors.source_id[0];
          const descriptionError = !err.response.data.errors.description ? '' : err.response.data.errors.description[1];
          const terminalsError = !err.response.data.errors.terminals ? '' : err.response.data.errors.terminals[1];
          const points = !err.response.data.errors.points ? '' : err.response.data.errors.points[0];
          const repeatTime = !err.response.data.errors.repeat_time ? '' : err.response.data.errors.repeat_time[0];
          const condition = !err.response.data.errors.condition ? '' : err.response.data.errors.condition[0];
          const lat = !err.response.data.errors.lat ? '' : err.response.data.errors.lat[0];
          const long = !err.response.data.errors.long ? '' : err.response.data.errors.long[0];
          const countryCode = !err.response.data.errors.country_code ? '' : err.response.data.errors.country_code[0];
          const distance = !err.response.data.errors.distance ? '' : err.response.data.errors.distance[0];
          const countryCondition = !err.response.data.errors.country_condition
            ? ''
            : err.response.data.errors.country_condition[0];
          const targetId = !err.response.data.errors.target_id ? '' : err.response.data.errors.target_id[0];
          const pointsDistanceZero = !err.response.data.errors['points.0.distance']
            ? ''
            : err.response.data.errors['points.0.distance'][0];
          const pointsDistanceOne = !err.response.data.errors['points.1.distance']
            ? ''
            : err.response.data.errors['points.1.distance'][1];

          dispatch({
            type: FIRE_ALERT,
            payload: {
              specificMessage: `${nameError} ${endError} ${startError} ${sourceIdError} ${descriptionError} ${terminalsError} ${points} ${repeatTime} ${condition} ${lat} ${long} ${countryCode} ${countryCondition} ${targetId} ${distance} ${pointsDistanceZero} ${pointsDistanceOne}`,
              message: err.response.data.message,
            },
          });
        }

        return reject(err);
      }),
  );
};

export const deleteRobot = (robotId) => (dispatch) =>
  new Promise((resolve, reject) =>
    api.robots
      .deleteRobot(robotId)
      .then((res) => {
        dispatch({
          type: DELETE_ROBOT,
          payload: res.data.data,
        });
        return resolve(res.data.data);
      })
      .catch((err) => {
        dispatch({
          type: FIRE_ALERT,
          payload: {
            specificMessage: 'Request access from Administrator',
            message: err.response.data.message,
          },
        });
        return reject(err);
      }),
  );

export const cloneRobot = (robotId) => (dispatch) =>
  new Promise((resolve, reject) =>
    api.robots
      .cloneRobot(robotId)
      .then((res) => {
        dispatch({
          type: CLONE_ROBOT,
          payload: res.data.data,
        });
        return resolve(res.data.data);
      })
      .catch((err) => {
        dispatch({
          type: FIRE_ALERT,
          payload: {
            specificMessage: 'Request access from Administrator',
            message: err.response.data.message,
          },
        });
        return reject(err);
      }),
  );

export const deleteRobotGroup = (robotId) => (dispatch) =>
  new Promise((resolve, reject) =>
    api.robots
      .deleteRobot(robotId)
      .then((res) => {
        dispatch({
          type: DELETE_ROBOT_GROUP,
          payload: res.data.data,
        });
        return resolve(res.data.data);
      })
      .catch((err) => reject(err)),
  );

export const editRobot = (robotId, data) => (dispatch) =>
  new Promise((resolve, reject) => {
    api.robots
      .editRobot(robotId, data)
      .then((res) => {
        dispatch({
          type: EDIT_ROBOT,
          payload: res.data.data,
        });
        return resolve(res.data.data);
      })
      .catch((err) => {
        if (!err.response.data.errors) {
          dispatch({
            type: FIRE_ALERT,
            payload: {
              specificMessage: 'Request access from Administrator',
              message: err.response.data.message,
            },
          });
        } else {
          const descriptionError = !err.response.data.errors.description ? '' : err.response.data.errors.description[0];
          const endError = !err.response.data.errors.end ? '' : err.response.data.errors.end[0];
          const nameError = !err.response.data.errors.name ? '' : err.response.data.errors.name[0];
          const alertStopError = !err.response.data.errors.alert_stop ? '' : err.response.data.errors.alert_stop[0];
          const terminalsError = !err.response.data.errors.terminals ? '' : err.response.data.errors.terminals[0];
          const sourceModeError = !err.response.data.errors.source_mode ? '' : err.response.data.errors.source_mode[0];
          const sourceIdError = !err.response.data.errors.source_id ? '' : err.response.data.errors.source_id[0];
          const startError = !err.response.data.errors.start ? '' : err.response.data.errors.start[0];
          const pointsError = !err.response.data.errors.points ? '' : err.response.data.errors.points[0];
          const repeatTime = !err.response.data.errors.repeat_time ? '' : err.response.data.errors.repeat_time[0];
          const limit = !err.response.data.errors.limit ? '' : err.response.data.errors.limit[0];
          const condition = !err.response.data.errors.condition ? '' : err.response.data.errors.condition[0];
          const lat = !err.response.data.errors.lat ? '' : err.response.data.errors.lat[0];
          const long = !err.response.data.errors.long ? '' : err.response.data.errors.long[0];

          dispatch({
            type: FIRE_ALERT,
            payload: {
              specificMessage: `
            ${nameError}
            ${endError}
            ${descriptionError}
            ${alertStopError}
            ${terminalsError}
            ${sourceModeError}
            ${sourceIdError}
            ${startError}
            ${pointsError}
            ${repeatTime}
            ${limit}
            ${condition}
            ${lat}
            ${long}
          `,
              message: err.response.data.message,
            },
          });
        }
        return reject(err);
      });
  });

export const updateRobotData = (data) => (dispatch) =>
  dispatch({
    type: UPDATE_ROBOT_DATA,
    payload: data,
  });
