import {
  GET_SENSOR_GROUPS,
  CRATE_SENSOR_GROUP,
  SHOW_SENSORS_GROUP,
  ADD_SENSOR_TOGROUP,
  DELETE_SENSOR_GROUP,
  REMOVE_SENSOR_FROM_GROUP,
  FIRE_ALERT,
} from '../../constants/actionTypes';
import api from '../../utils/appApi';

export const getSensorGroups = () => (dispatch) =>
  api.sensorGroups.getGroups().then((res) =>
    dispatch({
      type: GET_SENSOR_GROUPS,
      payload: res.data.data,
    }),
  );

export const createSensorGroup = (name) => (dispatch) =>
  api.sensorGroups.createSensorGroup(name).then((groupData) =>
    dispatch({
      type: CRATE_SENSOR_GROUP,
      payload: groupData.data.data,
    }),
  );

export const addSensorToGroup = (sensorId, sensorGroupId, start, end) => (dispatch) => {
  return api.sensorGroups
    .addSensorToGroup(sensorId, sensorGroupId, start, end)
    .then((res) =>
      dispatch({
        type: ADD_SENSOR_TOGROUP,
        payload: { data: res.data.data, id: sensorGroupId },
      }),
    )
    .catch((err) => {
      dispatch({
        type: FIRE_ALERT,
        payload: {
          message: err.response.data.message,
        },
      });
    });
};

export const showSensorsGroup = (groupId) => (dispatch) =>
  api.sensorGroups.showSensorsGroup(groupId).then((res) =>
    dispatch({
      type: SHOW_SENSORS_GROUP,
      payload: { data: res.data, groupId },
    }),
  );

export const deleteSensorGroup = (groupId) => (dispatch) =>
  api.sensorGroups.deleteSensorGroup(groupId).then((res) => {
    dispatch({
      type: DELETE_SENSOR_GROUP,
      payload: res.data.data,
    });
  });

export const removeSensorFromGroup = (sensorId, groupId) => (dispatch) =>
  api.sensorGroups.removeSensorFromGroup(sensorId, groupId).then((res) => {
    dispatch({
      type: REMOVE_SENSOR_FROM_GROUP,
      payload: { id: groupId, data: res.data.data },
    });
  });
