import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import NavigationMenu from '../../components/navigationMenu';
import AdminContent from './AdminContent';
import SuperAdminContent from './SuperAdminContent';

import './userSettingsPage.sass';

class UserSettings extends Component {
  state = {};

  render() {
    return (
      <div>
        <NavigationMenu statusPage="settings" />
        <div className={`user-settings-page ${this.props.user.sticky_header ? '--fixed' : ''}`}>
          {this.props.user.role && this.props.user.role.id === 1 && <SuperAdminContent />}
          {this.props.user.role && this.props.user.role.id === 2 && <AdminContent />}
        </div>
      </div>
    );
  }
}

UserSettings.propTypes = {
  user: PropTypes.shape({
    sticky_header: PropTypes.number,
    role: PropTypes.shape({
      id: PropTypes.number,
    }),
  }).isRequired,
};

const mapStateToProps = (store) => ({
  user: store.user,
});

export default connect(mapStateToProps)(UserSettings);
