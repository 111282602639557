export default function (heatMapSettings) {
  const arrayToSort = [...heatMapSettings];

  const greaterThenIndex = arrayToSort.findIndex((item) => item.value === 'greaterThen');
  const greaterThenItem = arrayToSort[greaterThenIndex];
  arrayToSort.splice(greaterThenIndex, 1);

  const lowerThenIndex = arrayToSort.findIndex((item) => item.value === 'lowerThen');
  const lowerThenItem = arrayToSort[lowerThenIndex];
  arrayToSort.splice(lowerThenIndex, 1);

  arrayToSort.sort((a, b) => +a.value - +b.value).reverse();
  arrayToSort.unshift(greaterThenItem);
  arrayToSort.push(lowerThenItem);

  return arrayToSort;
}
