import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { DeleteOutlined, PlusCircleOutlined, RightCircleOutlined, UploadOutlined } from '@ant-design/icons';

import { Table, Popconfirm, Popover } from 'antd';

import { createGroup } from '../../../actions/groups/groups';
import { deleteGroup } from '../../../actions/groups/groups';
import { uploadContactsGroup as uploadGroup } from '../../../actions/groups/groups';

import TimeStamp from '../../../utils/timeStamp';
import PermissionHandler from '../../../utils/permissionsHandler';
import CreateGroupForm from './createGroupForm';
import UploadObject from '../uploadObject';
import getColumnSearchProps from '../getColumnSearchProps';

import history from '../../../utils/history';

import './groupTable.sass';

class GroupTable extends PureComponent {
  state = {
    searchText: '',
    searchedColumn: '',
    pagination: {
      current: 1,
      pageSize: 5,
    },
    isVisiblePopover: false,
  };

  viewContact = (id) => history.push(`/groups/${id}`);

  handleTableChange = (pagination) => {
    this.setState({ pagination });
  };

  setVisiblePopover = (status) => {
    this.setState({ isVisiblePopover: status });
  };

  setHiddenPopover = (status) => {
    this.setState({
      isVisiblePopover: status,
    });
  };

  render() {
    const { data, deleteGroup, createGroup } = this.props;
    const {
      pagination: { pageSize },
    } = this.state;

    const columns = [
      {
        title: 'Group Name',
        dataIndex: 'name',
        width: 210,
        ...getColumnSearchProps('name', this),
      },
      {
        title: 'Contacts',
        dataIndex: 'contact_count',
        width: 90,
        sorter: (a, b) => a.contact_count - b.contact_count,
      },
      {
        title: 'Created at',
        dataIndex: 'created_at',
        width: 110,
        sorter: (a, b) => new Date(a.created_at) - new Date(b.created_at),
        render: (created_at) => <TimeStamp date={created_at} format="YYYY-MM-DD" />,
      },
      {
        title: '',
        dataIndex: 'id',
        width: 50,
        render: (id) => (
          <div className="dashboard-tables__table_actions-wrapper" style={{ textAlign: 'center' }}>
            <RightCircleOutlined
              className="dashboard-tables__table_action-enter"
              onClick={() => this.viewContact(id)}
            />
            <PermissionHandler button="contact-delete">
              <Popconfirm
                style={{ zIndex: 100 }}
                title="Are you sure you want to delete this Group?"
                okText="Yes"
                cancelText="No"
                onConfirm={() => deleteGroup(id)}
                placement="topRight"
              >
                <DeleteOutlined className="dashboard-tables__table_action-delete" />
              </Popconfirm>
            </PermissionHandler>
          </div>
        ),
      },
    ];
    return (
      <div className={`table-component${data && data.length === 0 ? ' table-component--fix-empty-height' : ''}`}>
        <div className="dashboard-tables__create-wrapper">
          <Popover
            content={
              <div className="dashboard-tables__create-form dashboard-tables__create-form--group">
                <CreateGroupForm setVisibility={this.setHiddenPopover} createGroup={createGroup} />
              </div>
            }
            title={null}
            className="dashboard-tables__create-action"
            placement="bottomLeft"
            trigger="click"
            visible={this.state.isVisiblePopover}
            onVisibleChange={this.setVisiblePopover}
          >
            <PlusCircleOutlined />
            <span className="dashboard-tables__create-action_label">Create group</span>
          </Popover>
          <Popover
            content={
              <div className="dashboard-tables__create-form">
                <UploadObject upload={this.props.uploadGroup} />
              </div>
            }
            title={null}
            className="dashboard-tables__create-action"
            placement="bottomLeft"
            trigger="click"
          >
            <UploadOutlined />
            <span className="dashboard-tables__create-action_label">Upload group</span>
          </Popover>
        </div>
        <Table
          onChange={this.handleTableChange}
          columns={columns}
          dataSource={data}
          pagination={
            data && data.length > 5
              ? { pageSize: pageSize, showSizeChanger: true, defaultPageSize: 5, pageSizeOptions: ['5', '10', '20'] }
              : false
          }
          size="small"
          rowKey="id"
          loading={data === null}
          scroll={{ x: 'auto' }}
        />
      </div>
    );
  }
}

GroupTable.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        alias: PropTypes.string,
        primary_terminal: PropTypes.shape({
          imsi: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([null])]),
          phone: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([null])]),
        }),
        created_at: PropTypes.string,
      }),
    ),
    PropTypes.oneOf([null]),
  ]),
  deleteGroup: PropTypes.func,
  createGroup: PropTypes.func,
  uploadGroup: PropTypes.func,
};

const mapStateToProps = (store) => ({
  data: store.groups.allGroups ? Object.values(store.groups.allGroups).reverse() : null,
});

export default connect(mapStateToProps, { deleteGroup, createGroup, uploadGroup })(GroupTable);
