import React, { PureComponent } from 'react';

import PropTypes from 'prop-types';

import {
  CopyOutlined,
  LoadingOutlined,
  MoreOutlined,
  SearchOutlined,
  StopOutlined,
  GlobalOutlined,
  NumberOutlined,
  PhoneOutlined,
  ApiOutlined,
  SecurityScanOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';

import { message, Tooltip, Dropdown, Menu } from 'antd';

import { searchTypes } from '../../searchComponent/searchComponent';

import JobContactsItem from './jobContacts/jobContacts';
import Doughnut from './doughnut';
import JobContent from './jobContent/jobContent';
import TimeStamp from '../../../utils/timeStamp';

import { SEEN_VISIBLE, SEEN_VISIBLE_FAV, UN_VISIBLE, UNSEEN_VISIBLE } from '../jobConstants';

import './jobItem.sass';

class JobItem extends PureComponent {
  state = {
    isOpen: false,
  };

  scrollIntoView = (id) => {
    setTimeout(() => {
      const myElement = document.getElementById(id);
      if (document.getElementById('job-list') && myElement) {
        document.getElementById('job-list').scrollTop = myElement?.offsetTop - 70;
      }
    }, 200);
  };

  toggleItem = (e, scrollId) => {
    if (e) e.stopPropagation();

    const { isOpen } = this.state;
    const {
      data: { id, visibility },
      setJobVisibility,
    } = this.props;

    if (visibility === UNSEEN_VISIBLE) setJobVisibility(SEEN_VISIBLE, id);

    if (!isOpen) this.scrollIntoView(scrollId);

    this.setState({ isOpen: !isOpen });
  };

  getJobStatus = ({ resolved, code, detail, title }) => {
    if (resolved === 0) return 'loading';
    else if (resolved === 1 && code === null && detail === null && title === null) return 'result';
    else if (resolved === 1 && (code || detail || title)) return 'error';

    return 'error';
  };

  getItemName = (jobStatus) => {
    const {
      data: { id, name, api_request_type, additional_info, code, title, phone, imsi, query_type },
    } = this.props;

    if (jobStatus === 'loading') return <div>Loading...</div>;
    else if (jobStatus === 'error') {
      if (code)
        return (
          <div className="item__title-name-wrapper">
            <span>
              <span className="item__title-name-wrapper_label">Code:</span>{' '}
              <span className="item__title-name-wrapper_error">{code}</span>
            </span>
            <span> {title}</span>
          </div>
        );
    }

    switch (api_request_type) {
      case 1: {
        return (
          <div className="item__title-name-wrapper">
            {name.length > 38 ? (
              <Tooltip key={id} placement="top" title={name}>
                <div className="item__title-name-wrapper_label__location">{name}</div>
              </Tooltip>
            ) : (
              <div className="item__title-name-wrapper_label__location">{name}</div>
            )}
          </div>
        );
      }
      case 4: {
        return <div className="item__title-name-wrapper">{imsi}</div>;
      }
      case 5: {
        return <div className="item__title-name-wrapper">{phone}</div>;
      }
      case 6: {
        if (additional_info && additional_info.data) {
          if (additional_info.data.forwarded)
            return (
              <div className="item__title-name-wrapper">
                <span className="item__title-name-wrapper_label">Result:</span> {additional_info.data.forwardingDetails}
              </div>
            );
          else if (additional_info.data.forwarded === null)
            return (
              <div className="item__title-name-wrapper">
                <span className="item__title-name-wrapper_label">Result:</span> NULL
              </div>
            );
          else if (additional_info.data.forwarded === false)
            return (
              <div className="item__title-name-wrapper">
                <span className="item__title-name-wrapper_label">Result:</span> FALSE
              </div>
            );
          return <div>Forward search failed</div>;
        } else return <div>Result failed</div>;
      }
      case 7: {
        if (additional_info && additional_info.data && additional_info.data.dosState !== null)
          return (
            <div className="item__title-name-wrapper">
              <span className="item__title-name-wrapper_label">Result:</span> {additional_info.data.dosState}
            </div>
          );
        else return <div>Result failed</div>;
      }
      case 8: {
        if (additional_info && additional_info.data) {
          if (additional_info.data.found) {
            if (query_type === 'passive')
              return (
                <div className="item__title-name-wrapper">
                  {additional_info?.data?.numberOwnership?.networkName ||
                    additional_info?.data?.subscriptionWith?.networkName}
                </div>
              );
            else if (query_type === 'active')
              return (
                <div className="item__title-name-wrapper">
                  {additional_info?.data?.subscriptionWith?.networkName ||
                    additional_info?.data?.numberOwnership?.networkName}
                </div>
              );
          }
          return <div>Result failed</div>;
        } else return <div>Result failed</div>;
      }
      case 10: {
        if (additional_info && additional_info.data) {
          if (additional_info.data.found) {
            if (query_type === 'passive')
              return (
                <div className="item__title-name-wrapper">
                  {additional_info.data?.plmnNetwork?.networkName || 'Unknown'}
                </div>
              );
          }
          return <div>Result failed</div>;
        } else return <div>Result failed</div>;
      }
      default:
        return name;
    }
  };

  getIconTypeFromResult = () => {
    const {
      data: { api_request_type },
    } = this.props;
    switch (api_request_type) {
      case 1:
        return <GlobalOutlined />;
      case 4:
        return <NumberOutlined />;
      case 5:
        return <PhoneOutlined />;
      case 6:
        return <ApiOutlined />;
      case 7:
        return <SecurityScanOutlined />;
      case 8:
        return <InfoCircleOutlined />;
      case 10:
        return <InfoCircleOutlined />;
      default:
        return <GlobalOutlined />;
    }
  };

  getSearchType = () => {
    const {
      data: { api_request_type, query_type },
    } = this.props;
    switch (api_request_type) {
      case 1:
      case 8:
      case 10:
        return `${searchTypes[api_request_type]}, Query type: ${query_type}`;
      default:
        return searchTypes[api_request_type];
    }
  };

  copyToClipBoard = (e, text) => {
    e.stopPropagation();
    navigator.clipboard.writeText(text).then(() => message.info('Number copied to clipboard.'));
  };

  copyToInput = (e, type, number) => {
    const { searchData, searchDataOnChange } = this.props;
    e.stopPropagation();
    searchDataOnChange({ ...searchData, [type]: number });
  };

  getForwardedPhone = () => {
    const {
      data: { additional_info },
    } = this.props;
    const text = additional_info.data.forwardingDetails;
    const indexParse = text.indexOf('>') + 1;
    return text.slice(indexParse, text.length);
  };

  stopPropagation = (e) => e.stopPropagation();

  jobOptionsOnClick = (e) => {
    e.domEvent.stopPropagation();
    const {
      data: { id, visibility },
      setJobVisibility,
    } = this.props;
    switch (e.key) {
      case 'fav': {
        setJobVisibility(SEEN_VISIBLE_FAV, id);
        break;
      }
      case 'unfav': {
        setJobVisibility(SEEN_VISIBLE, id);
        break;
      }
      case 'del': {
        setJobVisibility(UN_VISIBLE, id);
        message.info(
          <span>
            <span
              style={{ color: '#58b4f5', marginRight: 3, fontWeight: 600, cursor: 'pointer' }}
              onClick={() => setJobVisibility(visibility, id)}
            >
              Undo
            </span>
            <span>deleting</span>
          </span>,
        );
        break;
      }
      default:
        break;
    }
  };

  render() {
    const { isOpen } = this.state;
    const {
      data,
      data: {
        imsi,
        phone,
        contact_ids,
        api_request_type,
        additional_info,
        visibility,
        location_time,
        resolved,
        majorStatusUpdate,
        minorStatusUpdate,
        rawMessage,
      },
    } = this.props;

    const jobStatus = this.getJobStatus(data);

    return (
      <div className={`job-wrapper${isOpen ? ' job-wrapper--opened' : ''}`}>
        <div
          className="job-item"
          onClick={(e) => (jobStatus !== 'loading' ? this.toggleItem(e, `job-item-${data.id}`) : undefined)}
        >
          <div
            className={`job-item__icon${visibility === 1 ? ' job-item__icon--unseen' : ''}${
              visibility === 3 ? ' job-item__icon--fav' : ''
            }`}
          >
            {/*<Icon style={{ color: '#ff9f00' }} type="warning" />*/}
            {jobStatus === 'loading' && <LoadingOutlined />}
            {jobStatus === 'result' && this.getIconTypeFromResult()}
            {jobStatus === 'error' && <StopOutlined style={{ color: '#f61c1c' }} />}
          </div>
          <div className="job-item__name">
            <span className="job-item__name_type">
              {this.getSearchType()}, <TimeStamp date={location_time} seconds />
            </span>
            <span className="job-item__name_text">{this.getItemName(jobStatus)}</span>
          </div>

          <JobContactsItem data={contact_ids} />

          <div className="job-item__num-info">
            {phone && (
              <div className="job-item__num-info_row">
                <span className="job-item__num-info_title">MSISDN:</span>
                <span className="job-item__num-info_value">{phone}</span>
                <CopyOutlined className="job-item__num-info_copy" onClick={(e) => this.copyToClipBoard(e, phone)} />
                <SearchOutlined
                  className="job-item__num-info_copy"
                  onClick={(e) => this.copyToInput(e, 'phone', phone)}
                />
              </div>
            )}
            {api_request_type === 6 && additional_info && additional_info.data && additional_info.data.forwarded ? (
              <div className="job-item__num-info_row">
                <span className="job-item__num-info_title">Forward:</span>
                <span className="job-item__num-info_value">{this.getForwardedPhone()}</span>
                <CopyOutlined
                  className="job-item__num-info_copy"
                  onClick={(e) => this.copyToClipBoard(e, this.getForwardedPhone())}
                />
              </div>
            ) : null}
            {imsi && (
              <div className="job-item__num-info_row">
                <span className="job-item__num-info_title">IMSI:</span>
                <span className="job-item__num-info_value">{imsi}</span>
                <CopyOutlined className="job-item__num-info_copy" onClick={(e) => this.copyToClipBoard(e, imsi)} />
                <SearchOutlined
                  className="job-item__num-info_copy"
                  onClick={(e) => this.copyToInput(e, 'imsi', imsi)}
                />
              </div>
            )}
          </div>

          <div className="job-item__graph">
            {resolved === 1 ? (
              <Doughnut major={100} minor={100} progress={100} />
            ) : (
              <Doughnut
                major={majorStatusUpdate ? majorStatusUpdate : 0}
                minor={minorStatusUpdate ? minorStatusUpdate : 0}
                progress={(rawMessage && rawMessage.data && rawMessage.data.progress) || 0}
              />
            )}
          </div>
          <div className="job-item__actions">
            <Dropdown
              overlay={
                <Menu onClick={this.jobOptionsOnClick}>
                  {visibility > 0 && visibility < 3 ? (
                    <Menu.Item key="fav">
                      <div>Favorite</div>
                    </Menu.Item>
                  ) : (
                    <Menu.Item key="unfav">
                      <div>Unfavorite</div>
                    </Menu.Item>
                  )}
                  <Menu.Item key="del">
                    <div>Clear</div>
                  </Menu.Item>
                </Menu>
              }
              disabled={jobStatus === 'loading'}
              trigger={['click']}
            >
              <MoreOutlined onClick={this.stopPropagation} />
            </Dropdown>
          </div>
          {jobStatus !== 'loading' && (
            <div className="job-item__arrow-wrapper">
              <div
                className="job-item__arrow --arrow-down"
                onClick={(e) => this.toggleItem(e, `job-item-${data.id}`)}
              />
            </div>
          )}
        </div>
        <div className="job-content">
          <div id={`job-item-${data.id}`} className="job-content__wrapper">
            {jobStatus !== 'loading' && isOpen ? <JobContent data={data} jobStatus={jobStatus} /> : null}
          </div>
        </div>
      </div>
    );
  }
}

JobItem.defaultProps = {
  data: PropTypes.shape({
    name: PropTypes.string,
    api_id: PropTypes.string,
    phone: PropTypes.number,
    imsi: PropTypes.number,
    contact_ids: PropTypes.number,
    resolved: PropTypes.number,
    visibility: PropTypes.number,
    api_request_type: PropTypes.number,
    location_time: PropTypes.string,
    majorStatusUpdate: PropTypes.number,
    minorStatusUpdate: PropTypes.number,
    additional_info: PropTypes.shape({
      data: PropTypes.shape({
        forwarded: PropTypes.bool,
        dosState: PropTypes.string,
        forwardingDetails: PropTypes.string,
        id: PropTypes.string,
        found: PropTypes.bool,
        imsi: PropTypes.string,
        state: PropTypes.string,
        age: PropTypes.string,
        ported: PropTypes.string,
        additionalInfo: PropTypes.shape({
          foundImsi: PropTypes.string,
          imsiFirewalled: PropTypes.string,
        }),
        numberOwnership: PropTypes.shape({
          country: PropTypes.string,
          networkBrand: PropTypes.string,
          networkName: PropTypes.string,
          networkId: PropTypes.number,
        }),
        subscriptionWith: PropTypes.shape({
          country: PropTypes.string,
          networkBrand: PropTypes.string,
          networkName: PropTypes.string,
          networkId: PropTypes.number,
          plmnId: PropTypes.shape({
            mnc: PropTypes.number,
            mcc: PropTypes.number,
          }),
        }),
        subNetworkInfo: PropTypes.shape({
          country: PropTypes.string,
          networkBrand: PropTypes.string,
          networkName: PropTypes.string,
          networkId: PropTypes.number,
          plmnId: PropTypes.shape({
            mnc: PropTypes.number,
            mcc: PropTypes.number,
          }),
        }),
      }),
      error: PropTypes.shape({
        id: PropTypes.string,
        code: PropTypes.string,
        title: PropTypes.string,
        detail: PropTypes.string,
      }),
    }),
  }).isRequired,
};

export default JobItem;
