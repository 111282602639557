const highlightedWords = [
  '%RESULT_MCC%',
  '%RESULT_MNC%',
  '%RESULT_LAC%',
  '%RESULT_CELLID%',
  '%RESULT_MSISDN%',
  '%RESULT_IMSI%',
  '%RESULT_LAT%',
  '%RESULT_LON%',
  '%RESULT_LOCATION_NAME%',
  '%RESULT_LOCATION_TIME%',
  '%2NDCONTACT_MCC%',
  '%2NDCONTACT_MNC%',
  '%2NDCONTACT_LAC%',
  '%2NDCONTACT_CELLID%',
  '%2NDCONTACT_MSISDN%',
  '%2NDCONTACT_IMSI%',
  '%2NDCONTACT_LAT%',
  '%2NDCONTACT_LON%',
  '%2NDCONTACT_LOCATION_NAME%',
  '%FOUND_DISTANCE%',
  '%SET_DISTANCE%',
  '%COUNTRY_CODE%',
  '%COUNTRY_CONDITION%',
  '%EXPLANATION%',
  '%STATUS%',
  '%FIXED_POINT_LAT%',
  '%FIXED_POINT_LON%',
  '%FOUND_CONTACTS_WITH_DISTANCE%',
  '%ROBOT_ID%',
  '%ROBOT_NAME%',
  '%RESULT_AGE%',
  '%RESULT_RADIUS%',
  '%2NDCONTACT_AGE%',
  '%2NDCONTACT_RADIUS%',
  '%2NDCONTACT_LOCATION_TIME%',
];

export default highlightedWords;
