import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { getRobots, getContactTerminals, createRobot, deleteRobot, cloneRobot } from '../../actions/robots/robots';
import { getAllContacts } from '../../actions/contacts/contact';
import { getGroups } from '../../actions/groups/groups';

import NavigationMenu from '../../components/navigationMenu';
import RobotInfoVIew from '../../components/robotInfoView';
import AddDropDown from '../../components/addDropDown';
import RobotCreation from '../../components/robotCreation';
import history from '../../utils/history';
import PermissionHandler from '../../utils/permissionsHandler';

import './robotsPage.sass';

export const robotTypes = ['tracker', 'online', 'country-zone', 'geo-point', 'geo-points', 'point-to-point', 'offline'];

class RobotsPage extends Component {
  state = {
    tableLoading: true,
    createSuccess: false,
    expand: false,
    populateData: {
      data: false,
      from: '',
      id: '',
    },
    dropDownHeight: 580,
  };

  componentDidMount() {
    this.props.getRobots().then(() => {
      this.setState({ tableLoading: false });
    });
    this.props.getAllContacts();
    this.props.getGroups();

    if (this.props.history.location.state !== undefined && this.props.history.location.state.fromContact === true) {
      this.expand(true, 'contact');
    }
    if (this.props.history.location.state !== undefined && this.props.history.location.state.fromGroup === true) {
      this.expand(true, 'group');
    }
  }

  expand = (action, from) => {
    if (action && from === 'contact') {
      this.setState({
        expand: action,
        populateData: {
          data: true,
          from: 'contact',
          id: `${this.props.history.location.state.contactInfo.id}`,
        },
      });
    } else if (action && from === 'group') {
      this.setState({
        expand: action,
        populateData: {
          data: true,
          from: 'group',
          id: `${this.props.history.location.state.groupInfo.id}`,
        },
      });
    } else this.setState({ expand: action });
  };

  createRobot = (data) => {
    return this.props.createRobot(data).then(() => {
      this.setState({ createSuccess: true, dropDownHeight: 580 });
      this.setState({ expand: false });
    });
  };

  onClose = () => {
    history.push({ state: undefined });
    this.expand(false);
  };

  render() {
    const allRobots =
      Object.values(this.props.robots).length !== 0 &&
      Object.values(this.props.robots)
        .reverse()
        .map((robot) => {
          const key = `robot-${robot.id}`;
          return (
            <RobotInfoVIew
              key={key}
              robotData={robot}
              tableLoading={this.state.tableLoading}
              deleteRobot={this.props.deleteRobot}
              cloneRobot={this.props.cloneRobot}
            />
          );
        });

    return (
      <div>
        <NavigationMenu statusPage="robots" />
        <div className={`robots-page ${this.props.user.sticky_header ? '--fixed' : ''}`}>
          <PermissionHandler button="robot-create" style={{ display: 'inline-flex', width: '100%' }}>
            <AddDropDown
              buttonName="Create Robot"
              createSuccess={this.state.createSuccess}
              height={this.state.dropDownHeight}
              expand={this.state.expand}
              onClose={this.onClose}
              autoClose
            >
              <RobotCreation
                populateData={this.state.populateData}
                groups={this.props.groups}
                contacts={this.props.contacts}
                getContactTerminals={(contactId) => this.props.getContactTerminals(contactId)}
                terminals={this.props.terminals}
                createRobot={this.createRobot}
                timeZone={this.props.timeZone}
                expandDropDown={(amount) => this.setState({ dropDownHeight: this.state.dropDownHeight + amount })}
              />
            </AddDropDown>
          </PermissionHandler>

          <div className="robots-page__robots">{allRobots}</div>
        </div>
      </div>
    );
  }
}

RobotsPage.defaultProps = {
  robots: {},
  terminals: [],
  history: {},
  timeZone: '',
  user: {},
};
RobotsPage.propTypes = {
  getRobots: PropTypes.func.isRequired,
  getAllContacts: PropTypes.func.isRequired,
  getGroups: PropTypes.func.isRequired,
  getContactTerminals: PropTypes.func.isRequired,
  createRobot: PropTypes.func.isRequired,
  deleteRobot: PropTypes.func.isRequired,
  cloneRobot: PropTypes.func.isRequired,
  robots: PropTypes.shape({
    id: PropTypes.number,
    status: PropTypes.number,
    query_type: PropTypes.string,
    description: PropTypes.string,
  }),
  contacts: PropTypes.shape({}),
  groups: PropTypes.oneOfType([PropTypes.array, PropTypes.shape({})]),
  terminals: PropTypes.object,
  user: PropTypes.shape({
    sticky_header: PropTypes.number,
  }),
  history: PropTypes.shape({
    location: PropTypes.shape({
      state: PropTypes.shape({
        fromContact: PropTypes.bool,
        fromGroup: PropTypes.bool,
        contactInfo: PropTypes.shape({
          id: PropTypes.number,
        }),
        groupInfo: PropTypes.shape({
          id: PropTypes.number,
        }),
      }),
    }),
  }),
  timeZone: PropTypes.string.isRequired,
};

const mapStateToProps = (store) => ({
  robots: store.robots.allRobots,
  contacts: store.contact.allContacts.contacts,
  terminals: store.robots.contactTerminals,
  groups: store.groups.allGroups,
  timeZone: store.user.timezone,
  user: store.user,
});

export default connect(mapStateToProps, {
  getRobots,
  getAllContacts,
  getGroups,
  getContactTerminals,
  createRobot,
  deleteRobot,
  cloneRobot,
})(RobotsPage);
