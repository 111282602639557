import {
  LOGIN,
  LOGOUT,
  CHANGE_TIMEZONE,
  CHANGE_USER,
  MAR_AS_SEEN,
  DELETE_NOTIFICATION,
  STICKY_HEADER,
  SOUND_NOTIFICATION,
  BROWSER_NOTIFICATION,
  ADD_HEATMAP_SETTING,
  EDIT_HEATMAP_SETTING,
  DELETE_HEATMAP_SETTING,
} from '../../constants/actionTypes';
import initialState from './initState';

export default function user(state = initialState, action = {}) {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        isAuthorized: true,
        ...action.payload,
      };
    case CHANGE_TIMEZONE:
      return {
        ...state,
        timezone: action.payload.timezone,
      };
    case CHANGE_USER:
      return {
        ...state,
        ...action.payload,
      };
    case MAR_AS_SEEN: {
      return {
        ...state,
        notifications: {
          ...state.notifications,
          data: {
            ...state.notifications.data,
            [action.payload.id]: {
              ...state.notifications.data[action.payload.id],
              read_at: action.payload.read_at,
            },
          },
        },
      };
    }
    case DELETE_NOTIFICATION: {
      const notifications = { ...state.notifications.data };
      delete notifications[action.payload.id];
      return {
        ...state,
        notifications: { data: notifications },
      };
    }
    case STICKY_HEADER: {
      return {
        ...state,
        sticky_header: action.payload,
      };
    }
    case SOUND_NOTIFICATION: {
      return {
        ...state,
        sound: action.payload,
      };
    }
    case BROWSER_NOTIFICATION: {
      return {
        ...state,
        browser_notification: action.payload,
      };
    }
    case ADD_HEATMAP_SETTING: {
      return {
        ...state,
        map: {
          ...state.map,
          settings: [...state.map.settings, { ...action.payload }],
        },
      };
    }
    case EDIT_HEATMAP_SETTING: {
      const newSettings = [...state.map.settings];
      const index = newSettings.findIndex((item) => item.id === action.payload.id);

      newSettings[index] = { ...newSettings[index], ...action.payload.data };
      return {
        ...state,
        map: {
          ...state.map,
          settings: newSettings,
        },
      };
    }
    case DELETE_HEATMAP_SETTING: {
      const newSettings = [...state.map.settings];
      const index = newSettings.findIndex((item) => item.id === action.payload);

      newSettings.splice(index, 1);
      return {
        ...state,
        map: {
          ...state.map,
          settings: newSettings,
        },
      };
    }
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
}
