import { MAR_AS_SEEN, DELETE_NOTIFICATION } from '../../constants/actionTypes';
import api from '../../utils/appApi';
import { getUser } from '../user/auth';

export const markAsSeenNotification = (notificationId) => (dispatch) =>
  new Promise((resolve, reject) =>
    api.notifications
      .markSeen(notificationId)
      .then((res) => {
        dispatch({
          type: MAR_AS_SEEN,
          payload: res.data,
        });
        return resolve(res.data);
      })
      .catch((err) => reject(err)),
  );

export const deleteNotification = (notificationId) => (dispatch) =>
  new Promise((resolve, reject) =>
    api.notifications
      .deleteNotification(notificationId)
      .then((res) => {
        dispatch({
          type: DELETE_NOTIFICATION,
          payload: res.data,
        });
        return resolve(res.data);
      })
      .catch((err) => reject(err)),
  );

export const markAllNotificationAsSeen = () => (dispatch) =>
  new Promise((resolve, reject) =>
    api.notifications
      .markAllSeen()
      .then((res) => {
        dispatch(getUser());
        return resolve(res.data);
      })
      .catch((err) => reject(err)),
  );
export const deleteAllNotifications = () => (dispatch) =>
  new Promise((resolve, reject) =>
    api.notifications
      .deleteAll()
      .then((res) => {
        dispatch(getUser());
        return resolve(res.data);
      })
      .catch((err) => reject(err)),
  );
