import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import JobResultLocationSearch from './jobResultLocationSearch';
import JobResultImsiSearch from './jobResultImsiSearch';
import JobResultPhoneSearch from './jobResultPhoneSearch';
import JobResultForwardingSearch from './jobResultForwardingSearch';
import JobResultDosSearch from './jobResultDosSearch';
import JobResultE164Search from './jobResultE164Search';
import JobResultE212Search from './jobResultE212Search';

import '../jobContent.sass';

class ResultParser extends PureComponent {
  getParsedResult = () => {
    const { data } = this.props;
    switch (data.api_request_type) {
      case 1:
        return <JobResultLocationSearch data={data} />;
      case 4:
        return <JobResultImsiSearch data={data} />;
      case 5:
        return <JobResultPhoneSearch data={data} />;
      case 6:
        return <JobResultForwardingSearch data={data} />;
      case 7:
        return <JobResultDosSearch data={data} />;
      case 8:
        return <JobResultE164Search data={data} />;
      case 10:
        return <JobResultE212Search data={data} />;
      default:
        return null;
    }
  };
  render() {
    return this.getParsedResult();
  }
}

ResultParser.propTypes = {
  data: PropTypes.shape({}).isRequired,
};

export default ResultParser;
