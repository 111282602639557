import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Button } from 'semantic-ui-react';
import { SyncOutlined } from '@ant-design/icons';
import { Table, Pagination, Input, Select, Tooltip, Button as AntdButton } from 'antd';

import { getCellReport } from '../../actions/cellReport/cellReport';

import DropDown from '../../components/addDropDown/index';
import TimeStamp from '../../utils/timeStamp';

import lte from '../../assets/images/lte.png';
import threeG from '../../assets/images/3g.png';

class PresenceTable extends Component {
  state = {
    loading: true,
    cells: {
      per_page: 10,
      page: 1,
    },
    reportInfo: {
      total: 1,
      page: 1,
      direction: 'desc',
      column: 'location_time',
    },
    reportData: [],
    loadingRefresh: false,
  };

  componentDidMount() {
    this.props.getCellReport({ per_page: 10, order_direction: 'desc', order_column: 'location_time' }).then((res) => {
      this.setState({
        loading: false,
        reportInfo: {
          ...this.state.reportInfo,
          total: res.meta.total,
          page: res.meta.current_page,
        },
        reportData: Object.values(res.data),
      });
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.cells.data) this.setState({ reportData: Object.values(nextProps.cells.data) });
  }

  registerFilter = (e) => {
    if (e.target.value === '') {
      const obj = { ...this.state.cells };
      delete obj[e.target.name];
      this.setState({ cells: { ...obj } });
    } else if (e.target.value === 'null') this.setState({ cells: { ...this.state.cells, [e.target.name]: null } });
    else if (e.target.value !== 'null')
      this.setState({ cells: { ...this.state.cells, [e.target.name]: e.target.value } });
  };

  submitFilter = (e) => {
    e.preventDefault();
    this.setState({ loading: true }, () => {
      const { direction, column } = this.state.reportInfo;
      const filters = { ...this.state.cells, order_direction: direction, order_column: column };
      delete filters.page;
      this.props
        .getCellReport({ ...filters })
        .then((res) =>
          this.setState({
            loading: false,
            reportInfo: {
              ...this.state.reportInfo,
              total: res.meta.total,
              page: res.meta.current_page,
            },
            reportData: Object.values(res.data),
          }),
        )
        .catch(() => this.setState({ loading: false }));
    });
  };

  refreshData = () => {
    this.setState({ loadingRefresh: true }, () => {
      const { direction, column } = this.state.reportInfo;
      const filters = { ...this.state.cells, order_direction: direction, order_column: column };
      this.props.getCellReport({ ...filters }).then(() => this.setState({ loadingRefresh: false }));
    });
  };

  onPaginationChange = (current, pageSize) => {
    this.setState({ loading: true }, () => {
      const { direction, column } = this.state.reportInfo;
      this.props
        .getCellReport({
          ...this.state.cells,
          per_page: pageSize,
          page: current,
          order_direction: direction,
          order_column: column,
        })
        .then(() => {
          this.setState({
            loading: false,
            cells: {
              ...this.state.cells,
              per_page: pageSize,
              page: current,
            },
            reportInfo: {
              ...this.state.reportInfo,
              page: current,
            },
          });
        });
    });
  };

  sortAction = (a, b, c) => {
    this.setState({ loading: true }, () => {
      const filters = {
        ...this.state.cells,
        order_column: c.columnKey,
        order_direction: c.order === 'ascend' ? 'asc' : 'desc',
        page: this.state.cells.page,
      };
      this.props
        .getCellReport({ ...filters })
        .then((res) =>
          this.setState({
            loading: false,
            reportInfo: {
              total: res.meta.total,
              page: res.meta.current_page,
              direction: c.order === 'ascend' ? 'asc' : 'desc',
              column: c.columnKey,
            },
            reportData: Object.values(res.data),
          }),
        )
        .catch(() => this.setState({ loading: false }));
    });
  };

  handleSelectSensor = (value) => {
    if (value !== 'No sensor') this.setState({ cells: { ...this.state.cells, sensor_technology: value } });
    else {
      const cells = { ...this.state.cells };
      delete cells.sensor_technology;
      this.setState({ cells: { ...cells } });
    }
  };

  render() {
    const { loadingRefresh, loading, reportData, reportInfo, cells, uploaded } = this.state;
    const columns = [
      { title: 'ID', dataIndex: 'id', key: 'id', sorter: true, width: '6%' },
      { title: 'Name', dataIndex: 'name', key: 'name', width: '19%', sorter: true },
      { title: 'IMSI', dataIndex: 'imsi', key: 'imsi', sorter: true, width: '10%' },
      { title: 'Lat', dataIndex: 'lat', key: 'lat', sorter: true, width: '8%' },
      { title: 'Lng', dataIndex: 'lng', key: 'long', sorter: true, width: '8%' },
      {
        title: 'Location time',
        dataIndex: 'location_time',
        key: 'location_time',
        sorter: true,
        width: '12%',
        render: (text) => <TimeStamp date={text} />,
      },
      {
        title: 'Sensor ID',
        dataIndex: 'sensor_id',
        key: 'sensor_id',
        sorter: true,
        width: '11%',
      },
      {
        title: 'Sensor technology',
        dataIndex: 'sensor_technology',
        key: 'sensor_technology',
        sorter: true,
        render: (text, data) => {
          let tech = null;
          if (data.sensor_technology === 1) tech = <img src={threeG} alt="3g" className="img-lte-location" />;
          else if (data.sensor_technology === 2) tech = <img src={lte} alt="lte" className="img-lte-location" />;
          else tech = 'No technology';
          return <div style={{ textAlign: 'center' }}>{tech}</div>;
        },
        width: '11%',
      },
      {
        title: 'Sensors serial',
        dataIndex: 'sensor_serial',
        key: 'sensor_serial',
        sorter: true,
        width: '10%',
      },
      {
        title: 'rsrp',
        dataIndex: 'rsrp',
        key: 'rsrp',
        sorter: true,
        width: '5%',
      },
    ];

    const toolTipText = 'this field can match partial strings';

    return (
      <React.Fragment>
        <DropDown className="cell-data-page__drop-down" createSuccess={uploaded} buttonName="Filters" height={150}>
          <form onSubmit={this.submitFilter} className="table-cell-form">
            <div onChange={this.registerFilter} className="table-cell-form__inputs">
              <Tooltip placement="top" title={toolTipText}>
                <Input
                  name="name"
                  style={{ width: 250 }}
                  addonBefore={<span style={{ color: '#1890ff' }}>Name</span>}
                />
              </Tooltip>
              <Tooltip placement="top" title={toolTipText}>
                <Input
                  name="sensor_id"
                  style={{ width: 250 }}
                  addonBefore={<span style={{ color: '#1890ff' }}>Sensor ID</span>}
                />
              </Tooltip>
              <Tooltip placement="top" title={toolTipText}>
                <Input
                  name="sensor_serial"
                  style={{ width: 200 }}
                  addonBefore={<span style={{ color: '#1890ff' }}>Sensor Serial</span>}
                />
              </Tooltip>
              <Select defaultValue="No sensor" style={{ width: 200 }} onChange={this.handleSelectSensor}>
                <Select.Option value="No sensor">No sensor</Select.Option>
                <Select.Option key="TECHNOLOGY_3G_1" value={1}>
                  TECHNOLOGY_3G
                </Select.Option>
                <Select.Option key="TECHNOLOGY_LTE_2" value={2}>
                  TECHNOLOGY_LTE
                </Select.Option>
              </Select>
              <Tooltip placement="top" title={toolTipText}>
                <Input
                  name="imsi"
                  style={{ width: 230 }}
                  addonBefore={<span style={{ color: '#1890ff' }}>IMSI</span>}
                />
              </Tooltip>
              <Tooltip placement="top" title={toolTipText}>
                <Input
                  name="rsrp"
                  style={{ width: 230 }}
                  addonBefore={<span style={{ color: '#1890ff' }}>rsrp</span>}
                />
              </Tooltip>
              <Tooltip placement="top" title={toolTipText}>
                <Input
                  name="location_time"
                  style={{ width: 230 }}
                  addonBefore={<span style={{ color: '#1890ff' }}>Location time</span>}
                />
              </Tooltip>
            </div>
            <Button style={{ marginTop: 10 }} loading={loading} type="submit" size="mini">
              Filter
            </Button>
          </form>
        </DropDown>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <AntdButton
            type="primary"
            shape="circle"
            icon={<SyncOutlined />}
            loading={loadingRefresh}
            onClick={this.refreshData}
          />
          <Pagination
            className="cell-pagination"
            showSizeChanger
            pageSizeOptions={['10', '20', '50', '100', '500']}
            pageSize={cells.per_page}
            onChange={this.onPaginationChange}
            onShowSizeChange={this.onPaginationChange}
            current={reportInfo.page}
            total={reportInfo.total}
          />
        </div>
        <Table
          loading={loading}
          rowKey={'id'}
          columns={columns}
          dataSource={reportData}
          scroll={{ x: 1680 }}
          pagination={false}
          onChange={this.sortAction}
        />
      </React.Fragment>
    );
  }
}

PresenceTable.defaultProps = {
  cells: {},
};

PresenceTable.propTypes = {
  getCellReport: PropTypes.func.isRequired,
  cells: PropTypes.shape({
    current_page: PropTypes.number,
    data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    per_page: PropTypes.number,
  }),
};

const mapStateToProps = (store) => ({
  cells: store.cellReport.reportCells,
  user: store.user,
});

export default connect(mapStateToProps, { getCellReport })(PresenceTable);
