/* eslint-disable */

module.exports = [
  {
    country: 'Afghanistan',
    mcc: '412',
    iso: 'af',
    mnc: '88',
    country_code: '93',
  },
  {
    country: 'Albania',
    mcc: '276',
    iso: 'al',
    mnc: '01',
    country_code: '355',
  },
  {
    country: 'Algeria',
    mcc: '603',
    iso: 'dz',
    mnc: '01',
    country_code: '213',
  },
  {
    country: 'American Samoa',
    mcc: '544',
    iso: 'as',
    mnc: '11',
    country_code: '684',
  },
  {
    country: 'Andorra',
    mcc: '213',
    iso: 'ad',
    mnc: '03',
    country_code: '376',
  },
  {
    country: 'Angola',
    mcc: '631',
    iso: 'ao',
    mnc: '04',
    country_code: '244',
  },
  {
    country: 'Anguilla',
    mcc: '365',
    iso: 'ai',
    mnc: '840',
    country_code: '1264',
  },
  {
    country: 'Antigua and Barbuda',
    mcc: '344',
    iso: 'ag',
    mnc: '030',
    country_code: '1268',
  },
  {
    country: 'Argentina Republic',
    mcc: '722',
    iso: 'ar',
    mnc: '310',
    country_code: '54',
  },
  {
    country: 'Armenia',
    mcc: '283',
    iso: 'am',
    mnc: '01',
    country_code: '374',
  },
  {
    country: 'Aruba',
    mcc: '363',
    iso: 'aw',
    mnc: '20',
    country_code: '297',
  },
  {
    country: 'Australia',
    mcc: '505',
    iso: 'au',
    mnc: '14',
    country_code: '61',
  },
  {
    country: 'Austria',
    mcc: '232',
    iso: 'at',
    mnc: '01',
    country_code: '43',
  },
  {
    country: 'Azerbaijan',
    mcc: '400',
    iso: 'az',
    mnc: '01',
    country_code: '994',
  },
  {
    country: 'Bahamas',
    mcc: '364',
    iso: 'bs',
    mnc: '390',
    country_code: '1242',
  },
  {
    country: 'Bahrain',
    mcc: '426',
    iso: 'bh',
    mnc: '01',
    country_code: '973',
  },
  {
    country: 'Bangladesh',
    mcc: '470',
    iso: 'bd',
    mnc: '02',
    country_code: '880',
  },
  {
    country: 'Barbados',
    mcc: '342',
    iso: 'bb',
    mnc: '600',
    country_code: '1246',
  },
  {
    country: 'Belarus',
    mcc: '257',
    iso: 'by',
    mnc: '03',
    country_code: '375',
  },
  {
    country: 'Belgium',
    mcc: '206',
    iso: 'be',
    mnc: '20',
    country_code: '32',
  },
  {
    country: 'Belize',
    mcc: '702',
    iso: 'bz',
    mnc: '67',
    country_code: '501',
  },
  {
    country: 'Benin',
    mcc: '616',
    iso: 'bj',
    mnc: '04',
    country_code: '229',
  },
  {
    country: 'Bermuda',
    mcc: '350',
    iso: 'bm',
    mnc: '000',
    country_code: '1441',
  },
  {
    country: 'Bhutan',
    mcc: '402',
    iso: 'bt',
    mnc: '11',
    country_code: '975',
  },
  {
    country: 'Bolivia',
    mcc: '736',
    iso: 'bo',
    mnc: '02',
    country_code: '591',
  },
  {
    country: 'Bosnia & Herzegov.',
    mcc: '218',
    iso: 'ba',
    mnc: '90',
    country_code: '387',
  },
  {
    country: 'Botswana',
    mcc: '652',
    iso: 'bw',
    mnc: '04',
    country_code: '267',
  },
  {
    country: 'Brazil',
    mcc: '724',
    iso: 'br',
    mnc: '12',
    country_code: '55',
  },
  {
    country: 'British Virgin Islands',
    mcc: '348',
    iso: 'vg',
    mnc: '570',
    country_code: '284',
  },
  {
    country: 'Brunei Darussalam',
    mcc: '528',
    iso: 'bn',
    mnc: '02',
    country_code: '673',
  },
  {
    country: 'Bulgaria',
    mcc: '284',
    iso: 'bg',
    mnc: '06',
    country_code: '359',
  },
  {
    country: 'Burkina Faso',
    mcc: '613',
    iso: 'bf',
    mnc: '03',
    country_code: '226',
  },
  {
    country: 'Burundi',
    mcc: '642',
    iso: 'bi',
    mnc: '02',
    country_code: '257',
  },
  {
    country: 'Cambodia',
    mcc: '456',
    iso: 'kh',
    mnc: '04',
    country_code: '855',
  },
  {
    country: 'Cameroon',
    mcc: '624',
    iso: 'cm',
    mnc: '01',
    country_code: '237',
  },
  {
    country: 'Canada',
    mcc: '302',
    iso: 'ca',
    mnc: '652',
    country_code: '1',
  },
  {
    country: 'Cape Verde',
    mcc: '625',
    iso: 'cv',
    mnc: '01',
    country_code: '238',
  },
  {
    country: 'Cayman Islands',
    mcc: '346',
    iso: 'ky',
    mnc: '050',
    country_code: '1345',
  },
  {
    country: 'Central African Rep.',
    mcc: '623',
    iso: 'cf',
    mnc: '01',
    country_code: '236',
  },
  {
    country: 'Chad',
    mcc: '622',
    iso: 'td',
    mnc: '04',
    country_code: '235',
  },
  {
    country: 'Chile',
    mcc: '730',
    iso: 'cl',
    mnc: '06',
    country_code: '56',
  },
  {
    country: 'China',
    mcc: '460',
    iso: 'cn',
    mnc: '07',
    country_code: '86',
  },
  {
    country: 'Colombia',
    mcc: '732',
    iso: 'co',
    mnc: '130',
    country_code: '57',
  },
  {
    country: 'Comoros',
    mcc: '654',
    iso: 'km',
    mnc: '01',
    country_code: '269',
  },
  {
    country: 'Congo, Dem. Rep.',
    mcc: '630',
    iso: 'cd',
    mnc: '90',
    country_code: '243',
  },
  {
    country: 'Congo, Republic',
    mcc: '629',
    iso: 'cg',
    mnc: '01',
    country_code: '242',
  },
  {
    country: 'Cook Islands',
    mcc: '548',
    iso: 'ck',
    mnc: '01',
    country_code: '682',
  },
  {
    country: 'Costa Rica',
    mcc: '712',
    iso: 'cr',
    mnc: '03',
    country_code: '506',
  },
  {
    country: 'Croatia',
    mcc: '219',
    iso: 'hr',
    mnc: '01',
    country_code: '385',
  },
  {
    country: 'Cuba',
    mcc: '368',
    iso: 'cu',
    mnc: '01',
    country_code: '53',
  },
  {
    country: 'Curacao',
    mcc: '362',
    iso: 'cw',
    mnc: '95',
    country_code: '599',
  },
  {
    country: 'Cyprus',
    mcc: '280',
    iso: 'cy',
    mnc: '10',
    country_code: '357',
  },
  {
    country: 'Czech Rep.',
    mcc: '230',
    iso: 'cz',
    mnc: '08',
    country_code: '420',
  },
  {
    country: 'Denmark',
    mcc: '238',
    iso: 'dk',
    mnc: '05',
    country_code: '45',
  },
  {
    country: 'Djibouti',
    mcc: '638',
    iso: 'dj',
    mnc: '01',
    country_code: '253',
  },
  {
    country: 'Dominica',
    mcc: '366',
    iso: 'dm',
    mnc: '110',
    country_code: '1767',
  },
  {
    country: 'Dominican Republic',
    mcc: '370',
    iso: 'do',
    mnc: '02',
    country_code: '1809',
  },
  {
    country: 'Ecuador',
    mcc: '740',
    iso: 'ec',
    mnc: '02',
    country_code: '593',
  },
  {
    country: 'Egypt',
    mcc: '602',
    iso: 'eg',
    mnc: '01',
    country_code: '20',
  },
  {
    country: 'El Salvador',
    mcc: '706',
    iso: 'sv',
    mnc: '01',
    country_code: '503',
  },
  {
    country: 'Equatorial Guinea',
    mcc: '627',
    iso: 'gq',
    mnc: '03',
    country_code: '240',
  },
  {
    country: 'Eritrea',
    mcc: '657',
    iso: 'er',
    mnc: '01',
    country_code: '291',
  },
  {
    country: 'Estonia',
    mcc: '248',
    iso: 'ee',
    mnc: '01',
    country_code: '372',
  },
  {
    country: 'Ethiopia',
    mcc: '636',
    iso: 'et',
    mnc: '01',
    country_code: '251',
  },
  {
    country: 'Falkland Islands (Malvinas)',
    mcc: '750',
    iso: 'fk',
    mnc: '001',
    country_code: '500',
  },
  {
    country: 'Faroe Islands',
    mcc: '288',
    iso: 'fo',
    mnc: '03',
    country_code: '298',
  },
  {
    country: 'Fiji',
    mcc: '542',
    iso: 'fj',
    mnc: '02',
    country_code: '679',
  },
  {
    country: 'Finland',
    mcc: '244',
    iso: 'fi',
    mnc: '14',
    country_code: '358',
  },
  {
    country: 'France',
    mcc: '208',
    iso: 'fr',
    mnc: '27',
    country_code: '33',
  },
  {
    country: 'French Guiana',
    mcc: '340',
    iso: 'fg',
    mnc: '20',
    country_code: '594',
  },
  {
    country: 'French Polynesia',
    mcc: '547',
    iso: 'pf',
    mnc: '15',
    country_code: '689',
  },
  {
    country: 'Gabon',
    mcc: '628',
    iso: 'ga',
    mnc: '04',
    country_code: '241',
  },
  {
    country: 'Gambia',
    mcc: '607',
    iso: 'gm',
    mnc: '02',
    country_code: '220',
  },
  {
    country: 'Georgia',
    mcc: '282',
    iso: 'ge',
    mnc: '01',
    country_code: '995',
  },
  {
    country: 'Germany',
    mcc: '262',
    iso: 'de',
    mnc: '17',
    country_code: '49',
  },
  {
    country: 'Ghana',
    mcc: '620',
    iso: 'gh',
    mnc: '04',
    country_code: '233',
  },
  {
    country: 'Gibraltar',
    mcc: '266',
    iso: 'gi',
    mnc: '06',
    country_code: '350',
  },
  {
    country: 'Greece',
    mcc: '202',
    iso: 'gr',
    mnc: '07',
    country_code: '30',
  },
  {
    country: 'Greenland',
    mcc: '290',
    iso: 'gl',
    mnc: '01',
    country_code: '299',
  },
  {
    country: 'Grenada',
    mcc: '352',
    iso: 'gd',
    mnc: '110',
    country_code: '1473',
  },
  {
    country: 'Guadeloupe ',
    mcc: '340',
    iso: 'gp',
    mnc: '08',
    country_code: '590',
  },
  {
    country: 'Guam',
    mcc: '310',
    iso: 'gu',
    mnc: '370',
    country_code: '1671',
  },
  {
    country: 'Guatemala',
    mcc: '704',
    iso: 'gt',
    mnc: '01',
    country_code: '502',
  },
  {
    country: 'Guinea',
    mcc: '611',
    iso: 'gn',
    mnc: '04',
    country_code: '224',
  },
  {
    country: 'Guinea-Bissau',
    mcc: '632',
    iso: 'gw',
    mnc: '01',
    country_code: '245',
  },
  {
    country: 'Guyana',
    mcc: '738',
    iso: 'gy',
    mnc: '02',
    country_code: '592',
  },
  {
    country: 'Haiti',
    mcc: '372',
    iso: 'ht',
    mnc: '01',
    country_code: '509',
  },
  {
    country: 'Honduras',
    mcc: '708',
    iso: 'hn',
    mnc: '040',
    country_code: '504',
  },
  {
    country: 'Hongkong, China',
    mcc: '454',
    iso: 'hk',
    mnc: '13',
    country_code: '852',
  },
  {
    country: 'Hungary',
    mcc: '216',
    iso: 'hu',
    mnc: '01',
    country_code: '36',
  },
  {
    country: 'Iceland',
    mcc: '274',
    iso: 'is',
    mnc: '09',
    country_code: '354',
  },
  {
    country: 'India',
    mcc: '404',
    iso: 'in',
    mnc: '28',
    country_code: '91',
  },
  {
    country: 'Indonesia',
    mcc: '510',
    iso: 'id',
    mnc: '08',
    country_code: '62',
  },
  {
    country: 'Iran ',
    mcc: '432',
    iso: 'ir',
    mnc: '19',
    country_code: '98',
  },
  {
    country: 'Iraq',
    mcc: '418',
    iso: 'iq',
    mnc: '05',
    country_code: '964',
  },
  {
    country: 'Ireland',
    mcc: '272',
    iso: 'ie',
    mnc: '04',
    country_code: '353',
  },
  {
    country: 'Israel',
    mcc: '425',
    iso: 'il',
    mnc: '14',
    country_code: '972',
  },
  {
    country: 'Italy',
    mcc: '222',
    iso: 'it',
    mnc: '34',
    country_code: '39',
  },
  {
    country: 'Ivory Coast',
    mcc: '612',
    iso: 'ci',
    mnc: '07',
    country_code: '225',
  },
  {
    country: 'Jamaica',
    mcc: '338',
    iso: 'jm',
    mnc: '020',
    country_code: '1876',
  },
  {
    country: 'Japan',
    mcc: '440',
    iso: 'jp',
    mnc: '00',
    country_code: '81',
  },
  {
    country: 'Jordan',
    mcc: '416',
    iso: 'jo',
    mnc: '77',
    country_code: '962',
  },
  {
    country: 'Kazakhstan',
    mcc: '401',
    iso: 'kz',
    mnc: '01',
    country_code: '7',
  },
  {
    country: 'Kenya',
    mcc: '639',
    iso: 'ke',
    mnc: '05',
    country_code: '254',
  },
  {
    country: 'Kiribati',
    mcc: '545',
    iso: 'ki',
    mnc: '09',
    country_code: '686',
  },
  {
    country: "Korea N., Dem. People's Rep.",
    mcc: '467',
    iso: 'kp',
    mnc: '193',
    country_code: '850',
  },
  {
    country: 'Korea S, Republic of',
    mcc: '450',
    iso: 'kr',
    mnc: '02',
    country_code: '82',
  },
  {
    country: 'Kuwait',
    mcc: '419',
    iso: 'kw',
    mnc: '04',
    country_code: '965',
  },
  {
    country: 'Kyrgyzstan',
    mcc: '437',
    iso: 'kg',
    mnc: '03',
    country_code: '996',
  },
  {
    country: 'Laos P.D.R.',
    mcc: '457',
    iso: 'la',
    mnc: '02',
    country_code: '856',
  },
  {
    country: 'Latvia',
    mcc: '247',
    iso: 'lv',
    mnc: '05',
    country_code: '371',
  },
  {
    country: 'Lebanon',
    mcc: '415',
    iso: 'lb',
    mnc: '32',
    country_code: '961',
  },
  {
    country: 'Lesotho',
    mcc: '651',
    iso: 'ls',
    mnc: '02',
    country_code: '266',
  },
  {
    country: 'Liberia',
    mcc: '618',
    iso: 'lr',
    mnc: '07',
    country_code: '231',
  },
  {
    country: 'Libya',
    mcc: '606',
    iso: 'ly',
    mnc: '02',
    country_code: '218',
  },
  {
    country: 'Liechtenstein',
    mcc: '295',
    iso: 'li',
    mnc: '06',
    country_code: '423',
  },
  {
    country: 'Lithuania',
    mcc: '246',
    iso: 'lt',
    mnc: '02',
    country_code: '370',
  },
  {
    country: 'Luxembourg',
    mcc: '270',
    iso: 'lu',
    mnc: '77',
    country_code: '352',
  },
  {
    country: 'Macao, China',
    mcc: '455',
    iso: 'mo',
    mnc: '04',
    country_code: '853',
  },
  {
    country: 'Macedonia',
    mcc: '294',
    iso: 'mk',
    mnc: '75',
    country_code: '389',
  },
  {
    country: 'Madagascar',
    mcc: '646',
    iso: 'mg',
    mnc: '01',
    country_code: '261',
  },
  {
    country: 'Malawi',
    mcc: '650',
    iso: 'mw',
    mnc: '01',
    country_code: '265',
  },
  {
    country: 'Malaysia',
    mcc: '502',
    iso: 'my',
    mnc: '01',
    country_code: '60',
  },
  {
    country: 'Maldives',
    mcc: '472',
    iso: 'mv',
    mnc: '01',
    country_code: '960',
  },
  {
    country: 'Mali',
    mcc: '610',
    iso: 'ml',
    mnc: '01',
    country_code: '223',
  },
  {
    country: 'Malta',
    mcc: '278',
    iso: 'mt',
    mnc: '21',
    country_code: '356',
  },
  {
    country: 'Martinique (French Department of)',
    mcc: '340',
    iso: 'mq',
    mnc: '12',
    country_code: '596',
  },
  {
    country: 'Mauritania',
    mcc: '609',
    iso: 'mr',
    mnc: '02',
    country_code: '222',
  },
  {
    country: 'Mauritius',
    mcc: '617',
    iso: 'mu',
    mnc: '10',
    country_code: '230',
  },
  {
    country: 'Mexico',
    mcc: '334',
    iso: 'mx',
    mnc: '50',
    country_code: '52',
  },
  {
    country: 'Micronesia',
    mcc: '550',
    iso: 'fm',
    mnc: '01',
    country_code: '691',
  },
  {
    country: 'Moldova',
    mcc: '259',
    iso: 'md',
    mnc: '04',
    country_code: '373',
  },
  {
    country: 'Monaco',
    mcc: '212',
    iso: 'mc',
    mnc: '10',
    country_code: '377',
  },
  {
    country: 'Mongolia',
    mcc: '428',
    iso: 'mn',
    mnc: '98',
    country_code: '976',
  },
  {
    country: 'Montenegro',
    mcc: '297',
    iso: 'me',
    mnc: '02',
    country_code: '382',
  },
  {
    country: 'Montserrat',
    mcc: '354',
    iso: 'ms',
    mnc: '860',
    country_code: '1664',
  },
  {
    country: 'Morocco',
    mcc: '604',
    iso: 'ma',
    mnc: '01',
    country_code: '212',
  },
  {
    country: 'Mozambique',
    mcc: '643',
    iso: 'mz',
    mnc: '01',
    country_code: '258',
  },
  {
    country: 'Myanmar (Burma)',
    mcc: '414',
    iso: 'mm',
    mnc: '01',
    country_code: '95',
  },
  {
    country: 'Namibia',
    mcc: '649',
    iso: 'na',
    mnc: '03',
    country_code: '264',
  },
  {
    country: 'Nepal',
    mcc: '429',
    iso: 'np',
    mnc: '02',
    country_code: '977',
  },
  {
    country: 'Netherlands',
    mcc: '204',
    iso: 'nl',
    mnc: '14',
    country_code: '31',
  },
  {
    country: 'Netherlands Antilles',
    mcc: '362',
    iso: 'an',
    mnc: '630',
    country_code: '599',
  },
  {
    country: 'New Caledonia',
    mcc: '546',
    iso: 'nc',
    mnc: '01',
    country_code: '687',
  },
  {
    country: 'New Zealand',
    mcc: '530',
    iso: 'nz',
    mnc: '28',
    country_code: '64',
  },
  {
    country: 'Nicaragua',
    mcc: '710',
    iso: 'ni',
    mnc: '21',
    country_code: '505',
  },
  {
    country: 'Niger',
    mcc: '614',
    iso: 'ne',
    mnc: '03',
    country_code: '227',
  },
  {
    country: 'Nigeria',
    mcc: '621',
    iso: 'ng',
    mnc: '20',
    country_code: '234',
  },
  {
    country: 'Niue',
    mcc: '555',
    iso: 'nu',
    mnc: '01',
    country_code: '683',
  },
  {
    country: 'Norway',
    mcc: '242',
    iso: 'no',
    mnc: '09',
    country_code: '47',
  },
  {
    country: 'Oman',
    mcc: '422',
    iso: 'om',
    mnc: '03',
    country_code: '968',
  },
  {
    country: 'Pakistan',
    mcc: '410',
    iso: 'pk',
    mnc: '08',
    country_code: '92',
  },
  {
    country: 'Palau (Republic of)',
    mcc: '552',
    iso: 'pw',
    mnc: '80',
    country_code: '680',
  },
  {
    country: 'Palestinian Territory',
    mcc: '425',
    iso: 'ps',
    mnc: '05',
    country_code: '970',
  },
  {
    country: 'Panama',
    mcc: '714',
    iso: 'pa',
    mnc: '01',
    country_code: '507',
  },
  {
    country: 'Papua New Guinea',
    mcc: '537',
    iso: 'pg',
    mnc: '03',
    country_code: '675',
  },
  {
    country: 'Paraguay',
    mcc: '744',
    iso: 'py',
    mnc: '02',
    country_code: '595',
  },
  {
    country: 'Peru',
    mcc: '716',
    iso: 'pe',
    mnc: '20',
    country_code: '51',
  },
  {
    country: 'Philippines',
    mcc: '515',
    iso: 'ph',
    mnc: '00',
    country_code: '63',
  },
  {
    country: 'Poland',
    mcc: '260',
    iso: 'pl',
    mnc: '17',
    country_code: '48',
  },
  {
    country: 'Portugal',
    mcc: '268',
    iso: 'pt',
    mnc: '04',
    country_code: '351',
  },
  {
    country: 'Puerto Rico',
    mcc: '330',
    iso: 'pr',
    mnc: '110',
    country_code: '',
  },
  {
    country: 'Qatar',
    mcc: '427',
    iso: 'qa',
    mnc: '01',
    country_code: '974',
  },
  {
    country: 'Reunion',
    mcc: '647',
    iso: 're',
    mnc: '00',
    country_code: '262',
  },
  {
    country: 'Romania',
    mcc: '226',
    iso: 'ro',
    mnc: '03',
    country_code: '40',
  },
  {
    country: 'Russian Federation',
    mcc: '250',
    iso: 'ru',
    mnc: '12',
    country_code: '79',
  },
  {
    country: 'Rwanda',
    mcc: '635',
    iso: 'rw',
    mnc: '14',
    country_code: '250',
  },
  {
    country: 'Saint Kitts and Nevis',
    mcc: '356',
    iso: 'kn',
    mnc: '110',
    country_code: '1869',
  },
  {
    country: 'Saint Lucia',
    mcc: '358',
    iso: 'lc',
    mnc: '110',
    country_code: '1758',
  },
  {
    country: 'Samoa',
    mcc: '549',
    iso: 'ws',
    mnc: '27',
    country_code: '685',
  },
  {
    country: 'San Marino',
    mcc: '292',
    iso: 'sm',
    mnc: '01',
    country_code: '378',
  },
  {
    country: 'Sao Tome & Principe',
    mcc: '626',
    iso: 'st',
    mnc: '01',
    country_code: '239',
  },
  {
    country: 'Saudi Arabia',
    mcc: '420',
    iso: 'sa',
    mnc: '07',
    country_code: '966',
  },
  {
    country: 'Senegal',
    mcc: '608',
    iso: 'sn',
    mnc: '03',
    country_code: '221',
  },
  {
    country: 'Serbia ',
    mcc: '220',
    iso: 'rs',
    mnc: '03',
    country_code: '381',
  },
  {
    country: 'Seychelles',
    mcc: '633',
    iso: 'sc',
    mnc: '10',
    country_code: '248',
  },
  {
    country: 'Sierra Leone',
    mcc: '619',
    iso: 'sl',
    mnc: '03',
    country_code: '232',
  },
  {
    country: 'Singapore',
    mcc: '525',
    iso: 'sg',
    mnc: '12',
    country_code: '65',
  },
  {
    country: 'Slovakia',
    mcc: '231',
    iso: 'sk',
    mnc: '03',
    country_code: '421',
  },
  {
    country: 'Slovenia',
    mcc: '293',
    iso: 'si',
    mnc: '41',
    country_code: '386',
  },
  {
    country: 'Solomon Islands',
    mcc: '540',
    iso: 'sb',
    mnc: '02',
    country_code: '677',
  },
  {
    country: 'Somalia',
    mcc: '637',
    iso: 'so',
    mnc: '30',
    country_code: '252',
  },
  {
    country: 'South Africa',
    mcc: '655',
    iso: 'za',
    mnc: '02',
    country_code: '27',
  },
  {
    country: 'South Sudan (Republic of)',
    mcc: '659',
    iso: 'ss',
    mnc: '03',
    country_code: '',
  },
  {
    country: 'Spain',
    mcc: '214',
    iso: 'es',
    mnc: '23',
    country_code: '34',
  },
  {
    country: 'Sri Lanka',
    mcc: '413',
    iso: 'lk',
    mnc: '05',
    country_code: '94',
  },
  {
    country: 'St. Pierre & Miquelon',
    mcc: '308',
    iso: 'pm',
    mnc: '01',
    country_code: '508',
  },
  {
    country: 'St. Vincent & Gren.',
    mcc: '360',
    iso: 'vc',
    mnc: '110',
    country_code: '1784',
  },
  {
    country: 'Sudan',
    mcc: '634',
    iso: 'sd',
    mnc: '00',
    country_code: '249',
  },
  {
    country: 'Suriname',
    mcc: '746',
    iso: 'sr',
    mnc: '03',
    country_code: '597',
  },
  {
    country: 'Swaziland',
    mcc: '653',
    iso: 'sz',
    mnc: '10',
    country_code: '268',
  },
  {
    country: 'Sweden',
    mcc: '240',
    iso: 'se',
    mnc: '35',
    country_code: '46',
  },
  {
    country: 'Switzerland',
    mcc: '228',
    iso: 'ch',
    mnc: '51',
    country_code: '41',
  },
  {
    country: 'Syrian Arab Republic',
    mcc: '417',
    iso: 'sy',
    mnc: '02',
    country_code: '963',
  },
  {
    country: 'Taiwan',
    mcc: '466',
    iso: 'tw',
    mnc: '68',
    country_code: '886',
  },
  {
    country: 'Tajikistan',
    mcc: '436',
    iso: 'tk',
    mnc: '04',
    country_code: '992',
  },
  {
    country: 'Tanzania',
    mcc: '640',
    iso: 'tz',
    mnc: '08',
    country_code: '255',
  },
  {
    country: 'Thailand',
    mcc: '520',
    iso: 'th',
    mnc: '20',
    country_code: '66',
  },
  {
    country: 'Timor-Leste',
    mcc: '514',
    iso: 'tp',
    mnc: '01',
    country_code: '670',
  },
  {
    country: 'Togo',
    mcc: '615',
    iso: 'tg',
    mnc: '02',
    country_code: '228',
  },
  {
    country: 'Tonga',
    mcc: '539',
    iso: 'to',
    mnc: '43',
    country_code: '676',
  },
  {
    country: 'Trinidad and Tobago',
    mcc: '374',
    iso: 'tt',
    mnc: '120',
    country_code: '1868',
  },
  {
    country: 'Tunisia',
    mcc: '605',
    iso: 'tn',
    mnc: '01',
    country_code: '216',
  },
  {
    country: 'Turkey',
    mcc: '286',
    iso: 'tr',
    mnc: '04',
    country_code: '90',
  },
  {
    country: 'Turkmenistan',
    mcc: '438',
    iso: 'tm',
    mnc: '01',
    country_code: '993',
  },
  {
    country: 'Turks and Caicos Islands',
    mcc: '376',
    iso: 'tc',
    mnc: '350',
    country_code: '',
  },
  {
    country: 'Tuvalu',
    mcc: '553',
    iso: 'tv',
    mnc: '01',
    country_code: '',
  },
  {
    country: 'Uganda',
    mcc: '641',
    iso: 'ug',
    mnc: '01',
    country_code: '256',
  },
  {
    country: 'Ukraine',
    mcc: '255',
    iso: 'ua',
    mnc: '06',
    country_code: '380',
  },
  {
    country: 'United Arab Emirates',
    mcc: '424',
    iso: 'ae',
    mnc: '03',
    country_code: '971',
  },
  {
    country: 'United Kingdom',
    mcc: '234',
    iso: 'gb',
    mnc: '03',
    country_code: '44',
  },
  {
    country: 'United States',
    mcc: '310',
    iso: 'us',
    mnc: '050',
    country_code: '1',
  },
  {
    country: 'Uruguay',
    mcc: '748',
    iso: 'uy',
    mnc: '03',
    country_code: '598',
  },
  {
    country: 'Uzbekistan',
    mcc: '434',
    iso: 'uz',
    mnc: '04',
    country_code: '998',
  },
  {
    country: 'Vanuatu',
    mcc: '541',
    iso: 'vu',
    mnc: '05',
    country_code: '678',
  },
  {
    country: 'Venezuela',
    mcc: '734',
    iso: 've',
    mnc: '03',
    country_code: '58',
  },
  {
    country: 'Viet Nam',
    mcc: '452',
    iso: 'vn',
    mnc: '07',
    country_code: '84',
  },
  {
    country: 'Virgin Islands, U.S.',
    mcc: '376',
    iso: 'vi',
    mnc: '50',
    country_code: '1340',
  },
  {
    country: 'Yemen',
    mcc: '421',
    iso: 'ye',
    mnc: '04',
    country_code: '967',
  },
  {
    country: 'Zambia',
    mcc: '645',
    iso: 'zm',
    mnc: '03',
    country_code: '260',
  },
  {
    country: 'Zimbabwe',
    mcc: '648',
    iso: 'zw',
    mnc: '04',
    country_code: '263',
  },
];
