import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Card, Checkbox, Grid, Form, Button, Input } from 'semantic-ui-react';
import { CloseCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Input as antdInput, Tag, Tabs, Switch } from 'antd';
import Highlighter from 'react-highlight-words';

import {
  getUsers,
  editUser,
  editUserPermissions,
  suspendUser,
  restoreUser,
  createUser,
  editAdminCredits,
  deleteUser,
} from '../../../actions/users/users';
import {
  updateAppData,
  getAppData,
  toggleAppStatus,
  generateCredits,
  sendNoticeMessage,
  getLogs,
} from '../../../actions/app/application';
import { getGlobalNotifications, updateGlobalNotifications } from '../../../actions/smsNotifications/smsNotifications';

import ExpandableTable from '../../../components/expandableTables/UserTable/index';
import DropDown from '../../../components/addDropDown';
import InlineError from '../../../containers/LoginPage/inlineError';
import highlightedWords from '../../../utils/highlightedWords';
import getCursorPos from '../../../utils/getTextAreaPos';
import LogTable from './logTable';
import MapSettings from './mapSettings/mapSettings';

import './superAdminContent.sass';

class SuperAdminContent extends Component {
  state = {
    generateLoading: false,
    loading: true,
    newContact: {
      username: '',
      password: '',
      repeatPassword: '',
      createSuccess: false,
      error: {},
    },
    token: {
      accessToken: '',
      refreshToken: '',
      loading: false,
    },
    daysOld: {
      expirationCheckbox: true,
      days: 0,
      recency: 0,
      cell: 0,
      loadingRecency: false,
      loadingDays: false,
      loadingCell: false,
      dashboardQueriesAge: 0,
      loadingDashboardQueriesAge: false,
      loadingComputedCellMinThreshold: false,
      computedCellMinThreshold: 0,
    },
    mapConfig: {
      api: '',
      url: '',
      loading: false,
    },
    globalMapSettings: {
      public_osm_url: '',
      hosted_osm_url: '',
      google_map_token: '',
      mapbox_style_basic: '',
      mapbox_style_satellite: '',
      mapbox_token: '',
      mapbox_account_name: '',
      loading: false,
    },
    appToken: {
      pass: '',
      url: '',
      loading: false,
    },
    rabbitMq: {
      amqp_password: '',
      amqp_host: '',
      amqp_port: '',
      amqp_queue: '',
    },
    rabbitMqLoading: false,
    mailConfig: {
      mail_driver: '',
      mail_host: '',
      mail_port: '',
      mail_username: '',
      mail_password: '',
      mail_encryption: '',
      mail_from_address: '',
      mail_from_name: '',
      sendmail_system_path: '',
    },
    heightAdminToggle: 0,
    opacityAdminToggle: 0,
    heightEditAppToggle: 0,
    opacityEditAppToggle: 0,
    radius_threshold: '',
    loadingThreshold: false,
    daily_app_credits: '',
    loadingDailyAppCredits: false,
    mailLoading: false,
    used_app_credits: 0,
    sms_url: '',
    sms_config_header_name: '',
    sms_config_header_value: '',
    loadingSmsUrl: false,
    loadingHeaderName: false,
    loadingHeaderValue: false,
    robotTextNotification: '',
    smsTextNotification: '',
    currentSmsTab: '1',
    textAreaPos: null,
    robotTextButtonLoading: false,
    smsTextButtonLoading: false,
    notificationsLock: null,
    lockLoading: false,
    robotTime: 0,
    loadingRobotTime: false,
    notificationMessage: '',
    noticeLoading: false,
    logs: [],
  };

  componentDidMount() {
    this.props.getUsers().then(() => this.props.getLogs().then((res) => this.setState({ logs: res, loading: false })));
    this.props.getAppData().then((res) => {
      this.setState(
        {
          token: {
            ...this.state.token,
            accessToken: res.payload.access_token_lifetime,
            refreshToken: res.payload.refresh_token_lifetime,
          },
          daysOld: {
            ...this.state.daysOld,
            days: res.payload.days_old,
            recency: res.payload.recency,
            expirationCheckbox: res.payload.cell_expiration !== null,
            cell: res.payload.cell_expiration === null ? 0 : res.payload.cell_expiration,
            dashboardQueriesAge: res.payload.dashboard_queries_age === null ? 0 : res.payload.dashboard_queries_age,
            computedCellMinThreshold:
              res.payload.computed_cell_min_threshold === null ? 0 : res.payload.computed_cell_min_threshold,
          },
          mapConfig: {
            ...this.state.mapConfig,
            api: !res.payload.map_api_key ? '' : res.payload.map_api_key,
            url: !res.payload.map_url ? '' : res.payload.map_url,
          },
          globalMapSettings: {
            ...this.state.globalMapSettings,
            public_osm_url: res.payload.public_osm_url,
            hosted_osm_url: res.payload.hosted_osm_url,
            google_map_token: res.payload.google_map_token,
            mapbox_style_basic: res.payload.mapbox_style_basic,
            mapbox_style_satellite: res.payload.mapbox_style_satellite,
            mapbox_token: res.payload.mapbox_token,
            mapbox_account_name: res.payload.mapbox_account_name,
          },
          rabbitMq: {
            ...this.state.rabbitMq,
            amqp_password: !res.payload.amqp_password ? '' : res.payload.amqp_password,
            amqp_host: !res.payload.amqp_host ? '' : res.payload.amqp_host,
            amqp_port: !res.payload.amqp_port ? '' : res.payload.amqp_port,
            amqp_queue: !res.payload.amqp_queue ? '' : res.payload.amqp_queue,
          },
          appToken: {
            pass: !res.payload.password ? '' : res.payload.password,
            url: !res.payload.app_url ? '' : res.payload.app_url,
            loading: false,
          },
          mailConfig: {
            mail_driver: !res.payload.mail_driver ? '' : res.payload.mail_driver,
            mail_host: !res.payload.mail_host ? '' : res.payload.mail_host,
            mail_port: !res.payload.mail_port ? '' : res.payload.mail_port,
            mail_username: !res.payload.mail_username ? '' : res.payload.mail_username,
            mail_password: !res.payload.mail_password ? '' : res.payload.mail_password,
            mail_encryption: !res.payload.mail_encryption ? '' : res.payload.mail_encryption,
            mail_from_address: !res.payload.mail_from_address ? '' : res.payload.mail_from_address,
            mail_from_name: !res.payload.mail_from_name ? '' : res.payload.mail_from_name,
            sendmail_system_path: !res.payload.sendmail_system_path ? '' : res.payload.sendmail_system_path,
          },
          radius_threshold: res.payload.radius_threshold,
          daily_app_credits: res.payload.daily_app_credits,
          used_app_credits: res.payload.used_app_credits,
          sms_url: !res.payload.sms_url ? '' : res.payload.sms_url,
          sms_config_header_name: !res.payload.sms_config_header_name ? '' : res.payload.sms_config_header_name,
          sms_config_header_value: !res.payload.sms_config_header_value ? '' : res.payload.sms_config_header_value,
          lock_templates: !!res.payload.lock_templates,
          notificationMessage: this.props.app.notice,
          robotTime: !res.payload.minimum_robot_repeat_time ? 0 : res.payload.minimum_robot_repeat_time,
        },
        () => {
          this.props.getGlobalNotifications().then((notificationData) => {
            this.setState({
              robotTextNotification:
                notificationData[Object.values(notificationData).filter((notif) => notif.type === 1)[0].id].content,
              smsTextNotification:
                notificationData[Object.values(notificationData).filter((notif) => notif.type === 3)[0].id].content,
            });
          });
        },
      );
    });
  }

  editUser = (userId, data) => this.props.editUser(userId, data);
  editUserPermissions = (userId, data) => this.props.editUserPermissions(userId, data);
  suspendUser = (userId) => this.props.suspendUser(userId);
  restoreUser = (userId) => this.props.restoreUser(userId);

  onChangeCreateUser = (e) =>
    this.setState({
      ...this.state,
      newContact: { ...this.state.newContact, [e.target.name]: e.target.value, error: {} },
    });

  submitHasErrors = (password, repeatPassword) => {
    if (password.length <= 5 || !/[a-z]/.test(password) || !/[A-Z]/.test(password) || !/[0-9]/.test(password)) {
      return { result: true, message: 'Incorrect password' };
    } else if (password !== repeatPassword) return { result: true, message: "Passwords don't match" };

    return { result: false, message: '' };
  };

  submitCreateUser = () => {
    const { error, username, password, repeatPassword } = this.state.newContact;
    const checkResult = this.submitHasErrors(password, repeatPassword);
    if (checkResult.result) {
      this.setState({
        newContact: { ...this.state.newContact, error: { message: checkResult.message } },
      });
    }
    if (!error.message && username && !checkResult.result && password === repeatPassword) {
      this.props
        .createUser({
          username: this.state.newContact.username,
          password: this.state.newContact.password,
          password_confirmation: this.state.newContact.repeatPassword,
          timezone: 'Europe/London',
        })
        .then(() =>
          this.setState({
            ...this.state,
            newContact: {
              username: '',
              password: '',
              repeatPassword: '',
              createSuccess: true,
              error: {},
            },
          }),
        );
    }
  };

  setTokenTime = () => {
    this.setState({ token: { ...this.state.token, loading: true } });
    const { accessToken, refreshToken } = this.state.token;
    this.props
      .updateAppData({
        access_token_lifetime: accessToken,
        refresh_token_lifetime: refreshToken,
      })
      .then(() => this.setState({ token: { ...this.state.token, loading: false } }))
      .catch(() => this.setState({ token: { ...this.state.token, loading: false } }));
  };

  setMapConfig = () => {
    this.setState({ mapConfig: { ...this.state.mapConfig, loading: true } });
    const { api, url } = this.state.mapConfig;
    this.props
      .updateAppData({
        map_api_key: api,
        map_url: url,
      })
      .then(() => this.setState({ mapConfig: { ...this.state.mapConfig, loading: false } }))
      .catch(() => this.setState({ mapConfig: { ...this.state.mapConfig, loading: false } }));
  };

  setGlobalMapSettings = (values) => {
    this.setState({ globalMapSettings: { ...this.state.globalMapSettings, ...values, loading: true } });
    const {
      public_osm_url,
      hosted_osm_url,
      google_map_token,
      mapbox_style_basic,
      mapbox_style_satellite,
      mapbox_token,
      mapbox_account_name,
    } = values;
    this.props
      .updateAppData({
        public_osm_url,
        hosted_osm_url,
        google_map_token,
        mapbox_style_basic,
        mapbox_style_satellite,
        mapbox_token,
        mapbox_account_name,
      })
      .then(() => this.setState({ globalMapSettings: { ...this.state.globalMapSettings, loading: false } }))
      .catch(() => this.setState({ globalMapSettings: { ...this.state.globalMapSettings, loading: false } }));
  };

  setAppToken = () => {
    this.setState({ appToken: { ...this.state.appToken, loading: true } });
    const { pass, url } = this.state.appToken;
    this.props
      .updateAppData({
        password: pass,
        app_url: url,
      })
      .then(() => this.setState({ appToken: { ...this.state.appToken, loading: false } }))
      .catch(() => this.setState({ appToken: { ...this.state.appToken, loading: false } }));
  };

  setDaysOld = (e) => {
    this.setState({ daysOld: { ...this.state.daysOld, loadingDays: true } });
    this.props
      .updateAppData({ days_old: e })
      .then(() => this.setState({ daysOld: { ...this.state.daysOld, loadingDays: false } }))
      .catch(() => this.setState({ daysOld: { ...this.state.daysOld, loadingDays: false } }));
  };
  setRecency = (e) => {
    this.setState({ daysOld: { ...this.state.daysOld, loadingRecency: true } });
    this.props
      .updateAppData({ recency: e })
      .then(() => this.setState({ daysOld: { ...this.state.daysOld, loadingRecency: false } }))
      .catch(() => this.setState({ daysOld: { ...this.state.daysOld, loadingRecency: false } }));
  };

  setDashboardQueriesAge = (e) => {
    this.setState({ daysOld: { ...this.state.daysOld, loadingDashboardQueriesAge: true } });
    this.props
      .updateAppData({ dashboard_queries_age: e })
      .then(() => this.setState({ daysOld: { ...this.state.daysOld, loadingDashboardQueriesAge: false } }))
      .catch(() => this.setState({ daysOld: { ...this.state.daysOld, loadingDashboardQueriesAge: false } }));
  };

  setComputedMinThreshold = (e) => {
    this.setState({ daysOld: { ...this.state.daysOld, loadingComputedCellMinThreshold: true } });
    this.props
      .updateAppData({ computed_cell_min_threshold: e })
      .then(() => this.setState({ daysOld: { ...this.state.daysOld, loadingComputedCellMinThreshold: false } }))
      .catch(() => this.setState({ daysOld: { ...this.state.daysOld, loadingComputedCellMinThreshold: false } }));
  };

  setCell = (e) => {
    this.setState({ daysOld: { ...this.state.daysOld, loadingCell: true } });
    this.props
      .updateAppData({
        cell_expiration: this.state.daysOld.expirationCheckbox ? e : null,
      })
      .then(() => this.setState({ daysOld: { ...this.state.daysOld, loadingCell: false } }))
      .catch(() => this.setState({ daysOld: { ...this.state.daysOld, loadingCell: false } }));
  };

  setMail = () => {
    this.setState({ mailLoading: true });
    this.props.updateAppData(this.state.mailConfig).then(() => this.setState({ mailLoading: false }));
  };
  setRabbitMq = () => {
    this.setState({ rabbitMqLoading: true });
    const parsedData = {};
    Object.keys(this.state.rabbitMq).forEach((key) => {
      if (this.state.rabbitMq[key] !== '' && this.state.rabbitMq[key][0] !== '*') {
        parsedData[key] = this.state.rabbitMq[key];
      }
    });
    this.props.updateAppData(parsedData).then(() => this.setState({ rabbitMqLoading: false }));
  };
  setNotificationsMessage = () => {
    this.setState({ noticeLoading: true });
    this.props.sendNoticeMessage(this.state.notificationMessage).then(() => this.setState({ noticeLoading: false }));
  };

  setRadius = () => {
    this.setState({ loadingThreshold: true });
    this.props
      .updateAppData({ radius_threshold: this.state.radius_threshold })
      .then(() => this.setState({ loadingThreshold: false }));
  };

  setDailyAppCredits = () => {
    this.setState({ loadingDailyAppCredits: true });
    this.props
      .updateAppData({ daily_app_credits: this.state.daily_app_credits })
      .then(() => this.setState({ loadingDailyAppCredits: false }));
  };

  setRobotTime = () => {
    this.setState({ loadingRobotTime: true });
    this.props
      .updateAppData({ minimum_robot_repeat_time: this.state.robotTime })
      .then(() => this.setState({ loadingRobotTime: false }));
  };

  generateCredits = () => {
    this.setState({ generateLoading: true }, () => {
      this.props
        .generateCredits()
        .then(() => this.setState({ generateLoading: false }))
        .catch(() => this.setState({ generateLoading: false }));
    });
  };

  setSms = () => {
    this.setState({ loadingSmsUrl: true }, () => {
      this.props.updateAppData({ sms_url: this.state.sms_url }).then(() => this.setState({ loadingSmsUrl: false }));
    });
  };

  setHeaderName = () => {
    this.setState({ loadingHeaderName: true }, () => {
      this.props
        .updateAppData({ sms_config_header_name: this.state.sms_config_header_name })
        .then(() => this.setState({ loadingHeaderName: false }));
    });
  };

  setHeaderValue = () => {
    this.setState({ loadingHeaderValue: true }, () => {
      this.props
        .updateAppData({ sms_config_header_value: this.state.sms_config_header_value })
        .then(() => this.setState({ loadingHeaderValue: false }));
    });
  };

  setNotificationsLock = (value) => {
    this.setState(
      {
        lock_templates: value,
        lockLoading: true,
      },
      () => {
        this.props
          .updateAppData({ lock_templates: value === false ? 0 : 1 })
          .then(() => this.setState({ lockLoading: false }));
      },
    );
  };

  toggleCreateAdmin = () => {
    const { heightAdminToggle } = this.state;
    if (heightAdminToggle === 0) this.setState({ heightAdminToggle: 180, opacityAdminToggle: 1 });
    else if (heightAdminToggle !== 0) {
      this.setState({ heightAdminToggle: 0, opacityAdminToggle: 0 });
    }
  };

  closeCreateAdmin = () => {
    this.toggleCreateAdmin();
  };

  toggleEditApp = () => {
    const { heightEditAppToggle } = this.state;
    if (heightEditAppToggle === 0) {
      this.setState({ heightEditAppToggle: 2200, opacityEditAppToggle: 1 });
    } else if (heightEditAppToggle !== 0) {
      this.setState({ heightEditAppToggle: 0, opacityEditAppToggle: 0 });
    }
  };

  closeEditApp = () => {
    this.toggleEditApp();
  };

  robotTextNotification = (e) => {
    this.setState({
      robotTextNotification: e.target.value,
      textAreaPos: getCursorPos(document.getElementById('text-area-test')),
    });
  };

  smsTextNotification = (e) => {
    this.setState({
      smsTextNotification: e.target.value,
      textAreaPos: getCursorPos(document.getElementById('text-area-test2')),
    });
  };

  setCurrentValue = (tab, currentValue) => {
    const stateValue = this.state[tab];
    const textarea = document.getElementById('text-area-notification');
    const hasValue = stateValue && stateValue.length > 0;
    const newText = hasValue ? `${stateValue} ${currentValue}` : currentValue;

    if (textarea) {
      textarea.focus();
    }

    this.setState({
      [tab]: newText,
      textAreaPos: {
        start: newText.length,
        end: newText.length,
      },
    });
  };

  handleVariableClick = (e) => {
    const value = e.target.getAttribute('value');

    if (this.state.currentSmsTab === '1') {
      this.setCurrentValue('robotTextNotification', value);
    } else {
      this.setCurrentValue('smsTextNotification', value);
    }
  };

  updateRobotNotification = () => {
    const templateId = Object.values(this.props.globalNotifications).filter((notif) => notif.type === 1)[0].id;

    this.setState({ robotTextButtonLoading: true }, () => {
      this.props
        .updateGlobalNotifications(templateId, { content: this.state.robotTextNotification })
        .then(() => this.setState({ robotTextButtonLoading: false }));
    });
  };

  updateSmsNotification = () => {
    const templateId = Object.values(this.props.globalNotifications).filter((notif) => notif.type === 3)[0].id;

    this.setState({ smsTextButtonLoading: true }, () => {
      this.props
        .updateGlobalNotifications(templateId, { content: this.state.smsTextNotification })
        .then(() => this.setState({ smsTextButtonLoading: false }));
    });
  };

  setCurrentTab = (e) => {
    this.setState({ currentSmsTab: e });
  };

  focusTextArea = () => {
    this?.robotTextAreaInput?.focus();
    this.setState({
      textAreaPos: {
        start: this.state.robotTextNotification ? this.state.robotTextNotification.length : 0,
        end: this.state.robotTextNotification ? this.state.robotTextNotification.length : 0,
      },
    });
  };

  render() {
    const { error } = this.state.newContact;
    const { robotTextNotification, smsTextNotification } = this.state;
    return (
      <div className="super-admin-content">
        <div className="add-drop-down-component super-admin-content__drop-down">
          <div className="add-drop-down-component__buttons-area">
            <div className="add-drop-down-component__buttons-area_button" onClick={this.toggleEditApp}>
              <PlusCircleOutlined className="button-icon" />
              <span className="button-text">Edit App Settings</span>
            </div>
            <div className="add-drop-down-component__buttons-area_button  --close-button" onClick={this.closeEditApp}>
              <CloseCircleOutlined
                className="button-icon"
                style={{
                  opacity: this.state.opacityEditAppToggle,
                  pointerEvents: this.state.opacityEditAppToggle === 0 ? 'none' : 'initial',
                }}
              />
              <span
                style={{
                  opacity: this.state.opacityEditAppToggle,
                  pointerEvents: this.state.opacityEditAppToggle === 0 ? 'none' : 'initial',
                }}
                className="button-text"
              >
                Close
              </span>
            </div>
          </div>
          {this.state.opacityEditAppToggle !== 0 && (
            <div
              className="add-drop-down-component__expand-area"
              style={{ height: this.state.heightEditAppToggle, opacity: this.state.opacityEditAppToggle }}
            >
              <Grid className="super-admin-content__grid" centered columns={2}>
                <Grid.Row stretched>
                  <Grid.Column width={10}>
                    <Card style={{ height: '100%' }} className="super-admin-content__create-user-card">
                      <div className="super-admin-content__create-user-card_content">
                        <div className="super-admin-content__create-user-card_content__name">Map Config</div>
                        <Form>
                          <Form.Group>
                            <Form.Input
                              value={this.state.mapConfig.api}
                              size="mini"
                              placeholder="Map API key"
                              width={7}
                              onChange={(e) =>
                                this.setState({
                                  mapConfig: {
                                    ...this.state.mapConfig,
                                    api: e.target.value,
                                  },
                                })
                              }
                            />
                            <Form.Input
                              value={this.state.mapConfig.url}
                              size="mini"
                              placeholder="MAP Url"
                              width={7}
                              onChange={(e) =>
                                this.setState({
                                  mapConfig: {
                                    ...this.state.mapConfig,
                                    url: e.target.value,
                                  },
                                })
                              }
                            />
                            <Button
                              loading={this.state.mapConfig.loading}
                              className="super-admin-content__grid_button"
                              size="mini"
                              circular
                              icon="save"
                              width={3}
                              onClick={this.setMapConfig}
                            />
                          </Form.Group>
                        </Form>

                        <div className="super-admin-content__create-user-card_content__name">
                          Token expiration <span style={{ opacity: 0.5 }}>(min)</span>
                        </div>
                        <Form
                          onChange={(e) =>
                            this.setState({
                              token: { ...this.state.token, [e.target.name]: e.target.value },
                            })
                          }
                        >
                          <Form.Group>
                            <Form.Input
                              value={this.state.token.accessToken}
                              name="accessToken"
                              size="mini"
                              placeholder="Access Token (min)"
                              width={5}
                            />
                            <Form.Input
                              value={this.state.token.refreshToken}
                              name="refreshToken"
                              size="mini"
                              placeholder="Refresh Token (min)"
                              width={5}
                            />
                            <Button
                              className="super-admin-content__grid_button"
                              size="mini"
                              loading={this.state.token.loading}
                              circular
                              icon="save"
                              width={3}
                              onClick={this.setTokenTime}
                            />
                          </Form.Group>
                        </Form>

                        <div className="super-admin-content__create-user-card_content__name">App Token</div>
                        <Form>
                          <Form.Group>
                            <Form.Input
                              value={this.state.appToken.pass}
                              size="mini"
                              placeholder="Password"
                              width={5}
                              onChange={(e) =>
                                this.setState({
                                  appToken: {
                                    ...this.state.appToken,
                                    pass: e.target.value,
                                  },
                                })
                              }
                            />
                            <Form.Input
                              value={this.state.appToken.url}
                              size="mini"
                              placeholder="URL"
                              width={5}
                              onChange={(e) =>
                                this.setState({
                                  appToken: {
                                    ...this.state.appToken,
                                    url: e.target.value,
                                  },
                                })
                              }
                            />
                            <Button
                              onClick={this.setAppToken}
                              loading={this.state.appToken.loading}
                              className="super-admin-content__grid_button"
                              size="mini"
                              circular
                              icon="save"
                              width={2}
                            />
                          </Form.Group>
                        </Form>

                        <div className="super-admin-content__create-user-card_content__name">
                          Radius threshold<span style={{ opacity: 0.5 }}>(m)</span>
                        </div>
                        <Form onChange={(e) => this.setState({ radius_threshold: e.target.value })}>
                          <Form.Group>
                            <Form.Input
                              value={this.state.radius_threshold}
                              name="accessToken"
                              size="mini"
                              placeholder="Radius threshold (m)"
                              width={5}
                            />
                            <Button
                              className="super-admin-content__grid_button"
                              size="mini"
                              loading={this.state.loadingThreshold}
                              circular
                              icon="save"
                              width={3}
                              onClick={this.setRadius}
                            />
                          </Form.Group>
                        </Form>

                        <div className="super-admin-content__create-user-card_content__name">
                          Daily app credits<span style={{ opacity: 0.5 }}>(credits)</span>
                        </div>
                        <Form onChange={(e) => this.setState({ daily_app_credits: e.target.value })}>
                          <Form.Group>
                            <Form.Input
                              value={this.state.daily_app_credits}
                              name="accessToken"
                              size="mini"
                              placeholder="Radius threshold (m)"
                              width={5}
                            />
                            <Button
                              className="super-admin-content__grid_button"
                              size="mini"
                              loading={this.state.loadingDailyAppCredits}
                              circular
                              icon="save"
                              width={3}
                              onClick={this.setDailyAppCredits}
                            />
                          </Form.Group>
                        </Form>

                        <div className="super-admin-content__create-user-card_content__name">
                          Robot repeat time<span style={{ opacity: 0.5 }}>(s)</span>
                        </div>
                        <Form onChange={(e) => this.setState({ robotTime: e.target.value })}>
                          <Form.Group>
                            <Form.Input
                              value={this.state.robotTime}
                              name="robotTime"
                              size="mini"
                              placeholder="Robot repeat time (s)"
                              width={5}
                            />
                            <Button
                              className="super-admin-content__grid_button"
                              size="mini"
                              loading={this.state.loadingRobotTime}
                              circular
                              icon="save"
                              width={3}
                              onClick={this.setRobotTime}
                            />
                          </Form.Group>
                        </Form>
                      </div>
                    </Card>
                  </Grid.Column>

                  <Grid.Column width={6}>
                    <Card className="super-admin-content__app-status">
                      <Card.Content>
                        <Card.Header>App Status</Card.Header>
                        <Card.Meta>Toggle button to ON/OFF</Card.Meta>
                        <Checkbox
                          className="super-admin-content__toggle"
                          toggle
                          checked={!!this.props.app.status}
                          onClick={this.props.toggleAppStatus}
                        />
                        <Card.Meta>Generate Credit Records</Card.Meta>
                        <Button loading={this.state.generateLoading} size="mini" onClick={this.generateCredits}>
                          Generate
                        </Button>
                      </Card.Content>
                    </Card>
                    <Card className="super-admin-content__app-status">
                      <Card.Content>
                        <Card.Meta>Robot data expiry days</Card.Meta>
                        <Form onSubmit={(e) => this.setDaysOld(e.target.days.value)}>
                          <Form.Group>
                            <Form.Input
                              onChange={(e) =>
                                this.setState({
                                  daysOld: {
                                    ...this.state.daysOld,
                                    days: e.target.value,
                                  },
                                })
                              }
                              value={this.state.daysOld.days}
                              name="days"
                              size="mini"
                              placeholder="Days Old"
                              width={6}
                            />
                            <Button
                              type="submit"
                              className="super-admin-content__grid_button"
                              size="mini"
                              circular
                              icon="save"
                              width={4}
                              loading={this.state.daysOld.loadingDays}
                            />
                          </Form.Group>
                        </Form>
                        <Card.Meta>Set the minutes for recency</Card.Meta>
                        <Form onSubmit={(e) => this.setRecency(e.target.recency.value)}>
                          <Form.Group>
                            <Form.Input
                              onChange={(e) =>
                                this.setState({
                                  daysOld: {
                                    ...this.state.daysOld,
                                    recency: e.target.value,
                                  },
                                })
                              }
                              value={this.state.daysOld.recency}
                              name="recency"
                              size="mini"
                              placeholder="Recency"
                              width={6}
                            />
                            <Button
                              type="submit"
                              className="super-admin-content__grid_button"
                              size="mini"
                              circular
                              icon="save"
                              width={4}
                              loading={this.state.daysOld.loadingRecency}
                            />
                          </Form.Group>
                        </Form>
                        <Card.Meta>{'Config cell expiration (day)'}</Card.Meta>
                        <Form onSubmit={(e) => this.setCell(e.target.cell.value)}>
                          <Form.Group style={{ display: 'flex', alignItems: 'center' }}>
                            <Form.Checkbox
                              checked={this.state.daysOld.expirationCheckbox}
                              onChange={() =>
                                this.setState({
                                  daysOld: {
                                    ...this.state.daysOld,
                                    expirationCheckbox: !this.state.daysOld.expirationCheckbox,
                                  },
                                })
                              }
                            />
                            <Form.Input
                              onChange={(e) =>
                                this.setState({
                                  daysOld: {
                                    ...this.state.daysOld,
                                    cell: e.target.value,
                                  },
                                })
                              }
                              value={this.state.daysOld.cell}
                              name="cell"
                              size="mini"
                              disabled={this.state.daysOld.expirationCheckbox === false}
                              placeholder="Cell expiration"
                              width={6}
                            />
                            <Button
                              type="submit"
                              className="super-admin-content__grid_button"
                              size="mini"
                              circular
                              icon="save"
                              width={4}
                              loading={this.state.daysOld.loadingCell}
                            />
                          </Form.Group>
                        </Form>
                        <Card.Meta>Dashboard queries age</Card.Meta>
                        <Form onSubmit={(e) => this.setDashboardQueriesAge(e.target.dashboard_queries_age.value)}>
                          <Form.Group>
                            <Form.Input
                              onChange={(e) =>
                                this.setState({
                                  daysOld: {
                                    ...this.state.daysOld,
                                    dashboardQueriesAge: e.target.value,
                                  },
                                })
                              }
                              value={this.state.daysOld.dashboardQueriesAge}
                              name="dashboard_queries_age"
                              size="mini"
                              placeholder="Dashboard queries age"
                              width={6}
                            />
                            <Button
                              type="submit"
                              className="super-admin-content__grid_button"
                              size="mini"
                              circular
                              icon="save"
                              width={4}
                              loading={this.state.daysOld.loadingDashboardQueriesAge}
                            />
                          </Form.Group>
                        </Form>
                        <Card.Meta>Computed cell min threshold</Card.Meta>
                        <Form
                          onSubmit={(e) => this.setComputedMinThreshold(e.target.computed_cell_min_threshold.value)}
                        >
                          <Form.Group>
                            <Form.Input
                              onChange={(e) =>
                                this.setState({
                                  daysOld: {
                                    ...this.state.daysOld,
                                    computedCellMinThreshold: e.target.value,
                                  },
                                })
                              }
                              value={this.state.daysOld.computedCellMinThreshold}
                              name="computed_cell_min_threshold"
                              size="mini"
                              placeholder="Computed cell min threshold"
                              width={6}
                            />
                            <Button
                              type="submit"
                              className="super-admin-content__grid_button"
                              size="mini"
                              circular
                              icon="save"
                              width={4}
                              loading={this.state.daysOld.loadingComputedCellMinThreshold}
                            />
                          </Form.Group>
                        </Form>
                      </Card.Content>
                    </Card>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row className="super-admin-content__edit-mail-card">
                  <Card style={{ height: '100%' }} className="super-admin-content__create-user-card">
                    <MapSettings
                      globalMapSettings={this.state.globalMapSettings}
                      setGlobalMapSettings={this.setGlobalMapSettings}
                    />
                  </Card>
                </Grid.Row>
                <Grid.Row className="super-admin-content__edit-mail-card">
                  <Card className="super-admin-content__create-user-card">
                    <div className="super-admin-content__edit-mail-card_content__name">SMS Config</div>
                    <Form>
                      <Form.Group>
                        <div style={{ display: 'flex', marginLeft: 10 }}>
                          <Form.Input
                            label="Url"
                            onChange={(e) =>
                              this.setState({
                                sms_url: e.target.value,
                              })
                            }
                            value={this.state.sms_url}
                            name="url"
                            size="mini"
                            placeholder="Sms url"
                            style={{ width: 200 }}
                          />
                          <Button
                            style={{ marginLeft: 10, marginTop: 22 }}
                            className="super-admin-content__grid_button"
                            size="mini"
                            circular
                            icon="save"
                            loading={this.state.loadingSmsUrl}
                            onClick={this.setSms}
                          />
                        </div>
                        <div style={{ display: 'flex', marginLeft: 20 }}>
                          <Form.Input
                            label="Header name"
                            onChange={(e) =>
                              this.setState({
                                sms_config_header_name: e.target.value,
                              })
                            }
                            value={this.state.sms_config_header_name}
                            name="header-name"
                            size="mini"
                            placeholder="Header name"
                            style={{ width: 200 }}
                          />
                          <Button
                            style={{ marginLeft: 10, marginTop: 22 }}
                            className="super-admin-content__grid_button"
                            size="mini"
                            circular
                            icon="save"
                            onClick={this.setHeaderName}
                            loading={this.state.loadingHeaderName}
                          />
                        </div>
                        <div style={{ display: 'flex', marginLeft: 20 }}>
                          <Form.Input
                            label="Header value"
                            onChange={(e) =>
                              this.setState({
                                sms_config_header_value: e.target.value,
                              })
                            }
                            value={this.state.sms_config_header_value}
                            name="sms"
                            size="mini"
                            placeholder="Header value"
                            style={{ width: 200 }}
                          />
                          <Button
                            style={{ marginLeft: 10, marginTop: 22 }}
                            className="super-admin-content__grid_button"
                            size="mini"
                            circular
                            icon="save"
                            onClick={this.setHeaderValue}
                            loading={this.state.loadingHeaderValue}
                          />
                        </div>
                      </Form.Group>
                    </Form>
                  </Card>
                </Grid.Row>
                <Grid.Row className="super-admin-content__edit-mail-card">
                  <Card className="super-admin-content__create-user-card">
                    <div className="super-admin-content__edit-mail-card_content__name">Mail Config</div>
                    <Form>
                      <Form.Group>
                        <Form.Input
                          onChange={(e) =>
                            this.setState({ mailConfig: { ...this.state.mailConfig, [e.target.name]: e.target.value } })
                          }
                          value={this.state.mailConfig.mail_driver}
                          label="Mail driver"
                          name="mail_driver"
                          size="mini"
                          placeholder="Mail driver"
                          width={5}
                        />
                        <Form.Input
                          onChange={(e) =>
                            this.setState({ mailConfig: { ...this.state.mailConfig, [e.target.name]: e.target.value } })
                          }
                          value={this.state.mailConfig.mail_host}
                          label="Mail host"
                          name="mail_host"
                          size="mini"
                          placeholder="Mail host"
                          width={5}
                        />
                        <Form.Input
                          onChange={(e) =>
                            this.setState({ mailConfig: { ...this.state.mailConfig, [e.target.name]: e.target.value } })
                          }
                          value={this.state.mailConfig.mail_port}
                          label="Mail port"
                          name="mail_port"
                          size="mini"
                          placeholder="Mail port"
                          width={5}
                        />
                      </Form.Group>
                      <Form.Group>
                        <Form.Input
                          onChange={(e) =>
                            this.setState({ mailConfig: { ...this.state.mailConfig, [e.target.name]: e.target.value } })
                          }
                          value={this.state.mailConfig.mail_username}
                          label="Mail username"
                          name="mail_username"
                          size="mini"
                          placeholder="Mail username"
                          width={5}
                        />
                        <Form.Input
                          onChange={(e) =>
                            this.setState({ mailConfig: { ...this.state.mailConfig, [e.target.name]: e.target.value } })
                          }
                          value={this.state.mailConfig.mail_password}
                          label="Mail password"
                          name="mail_password"
                          size="mini"
                          type="password"
                          placeholder="Mail password"
                          width={5}
                        />
                        <Form.Input
                          onChange={(e) =>
                            this.setState({ mailConfig: { ...this.state.mailConfig, [e.target.name]: e.target.value } })
                          }
                          value={this.state.mailConfig.mail_encryption}
                          label="Mail encryption"
                          name="mail_encryption"
                          size="mini"
                          placeholder="Mail encryption"
                          width={5}
                        />
                      </Form.Group>
                      <Form.Group>
                        <Form.Input
                          onChange={(e) =>
                            this.setState({ mailConfig: { ...this.state.mailConfig, [e.target.name]: e.target.value } })
                          }
                          value={this.state.mailConfig.mail_from_address}
                          label="Mail from address"
                          name="mail_from_address"
                          size="mini"
                          placeholder="Mail from address"
                          width={5}
                        />
                        <Form.Input
                          onChange={(e) =>
                            this.setState({ mailConfig: { ...this.state.mailConfig, [e.target.name]: e.target.value } })
                          }
                          value={this.state.mailConfig.mail_from_name}
                          label="Mail from name"
                          name="mail_from_name"
                          size="mini"
                          placeholder="Mail from name"
                          width={5}
                        />
                        <Form.Input
                          onChange={(e) =>
                            this.setState({ mailConfig: { ...this.state.mailConfig, [e.target.name]: e.target.value } })
                          }
                          value={this.state.mailConfig.sendmail_system_path}
                          label="Send mail system path"
                          name="sendmail_system_path"
                          size="mini"
                          placeholder="Send mail system path"
                          width={5}
                        />
                      </Form.Group>
                      <Form.Group className="super-admin-content__edit-mail-card_end-field">
                        <Button
                          className="super-admin-content__edit-mail-card_button"
                          size="mini"
                          loading={this.state.mailLoading}
                          onClick={this.setMail}
                        >
                          Save
                        </Button>
                      </Form.Group>
                    </Form>
                  </Card>
                </Grid.Row>
                <Grid.Row className="super-admin-content__edit-mail-card">
                  <Card className="super-admin-content__create-user-card">
                    <div className="super-admin-content__edit-mail-card_content__name">Rabit MQ</div>
                    <Form>
                      <Form.Group>
                        <Form.Input
                          onChange={(e) =>
                            this.setState({ rabbitMq: { ...this.state.rabbitMq, [e.target.name]: e.target.value } })
                          }
                          value={this.state.rabbitMq.amqp_password}
                          label="Password"
                          name="amqp_password"
                          size="mini"
                          placeholder="Password"
                          width={5}
                        />
                        <Form.Input
                          onChange={(e) =>
                            this.setState({ rabbitMq: { ...this.state.rabbitMq, [e.target.name]: e.target.value } })
                          }
                          value={this.state.rabbitMq.amqp_host}
                          label="Host"
                          name="amqp_host"
                          size="mini"
                          placeholder="Host"
                          width={5}
                        />
                        <Form.Input
                          onChange={(e) =>
                            this.setState({ rabbitMq: { ...this.state.rabbitMq, [e.target.name]: e.target.value } })
                          }
                          value={this.state.rabbitMq.amqp_port}
                          label="Port"
                          name="amqp_port"
                          size="mini"
                          placeholder="Port"
                          width={5}
                        />
                      </Form.Group>
                      <Form.Group>
                        <Form.Input
                          onChange={(e) =>
                            this.setState({ rabbitMq: { ...this.state.rabbitMq, [e.target.name]: e.target.value } })
                          }
                          value={this.state.rabbitMq.amqp_queue}
                          label="Queue"
                          name="amqp_queue"
                          size="mini"
                          placeholder="Queue"
                          width={5}
                        />
                      </Form.Group>
                      <Form.Group className="super-admin-content__edit-mail-card_end-field">
                        <Button
                          className="super-admin-content__edit-mail-card_button"
                          size="mini"
                          loading={this.state.rabbitMqLoading}
                          onClick={this.setRabbitMq}
                        >
                          Save
                        </Button>
                      </Form.Group>
                    </Form>
                  </Card>
                </Grid.Row>
                <Grid.Row className="super-admin-content__notification">
                  <Card className="super-admin-content__notification_create">
                    <div className="super-admin-content__notification_name">Notification message</div>
                    <Form.Group>
                      <Form.TextArea
                        className="super-admin-content__notification_textArea"
                        onChange={(e) => this.setState({ notificationMessage: e.target.value })}
                        value={this.state.notificationMessage}
                      />
                      <Button
                        className="super-admin-content__notification_button"
                        size="mini"
                        loading={this.state.noticeLoading}
                        onClick={this.setNotificationsMessage}
                      >
                        Save
                      </Button>
                    </Form.Group>
                  </Card>
                </Grid.Row>
              </Grid>
            </div>
          )}
        </div>

        <DropDown
          className="super-admin-content__drop-down --app-settings"
          buttonName="Notification template settings"
          height={790}
          onClick={this.focusTextArea}
        >
          <div className="super-admin-content__drop-down_sms-wrap">
            <div className="super-admin-content__drop-down_sms-wrap__lock">
              <Switch
                loading={this.state.lockLoading}
                checked={this.state.lock_templates}
                onChange={this.setNotificationsLock}
              />
              <span className="super-admin-content__drop-down_sms-wrap__lock_text">Lock Templates</span>
            </div>
            <div className="super-admin-content__drop-down_sms-wrap__variables">
              <Tag color="blue" value="%RESULT_MCC%" onClick={this.handleVariableClick}>
                RESULT_MCC
              </Tag>
              <Tag color="blue" value="%RESULT_MNC%" onClick={this.handleVariableClick}>
                RESULT_MNC
              </Tag>
              <Tag color="blue" value="%RESULT_LAC%" onClick={this.handleVariableClick}>
                RESULT_LAC
              </Tag>
              <Tag color="blue" value="%RESULT_CELLID%" onClick={this.handleVariableClick}>
                RESULT_CELLID
              </Tag>
              <Tag color="blue" value="%RESULT_MSISDN%" onClick={this.handleVariableClick}>
                RESULT_MSISDN
              </Tag>
              <Tag color="blue" value="%RESULT_IMSI%" onClick={this.handleVariableClick}>
                RESULT_IMSI
              </Tag>
              <Tag color="blue" value="%RESULT_LAT%" onClick={this.handleVariableClick}>
                RESULT_LAT
              </Tag>
              <Tag color="blue" value="%RESULT_LON%" onClick={this.handleVariableClick}>
                RESULT_LON
              </Tag>
              <Tag color="blue" value="%RESULT_LOCATION_NAME%" onClick={this.handleVariableClick}>
                RESULT_LOCATION_NAME
              </Tag>
              <Tag color="blue" value="%RESULT_LOCATION_TIME%" onClick={this.handleVariableClick}>
                RESULT_LOCATION_TIME
              </Tag>
              <div className="super-admin-content__drop-down_sms-wrap__variables_br" />
              <Tag color="blue" value="%2NDCONTACT_MCC%" onClick={this.handleVariableClick}>
                2NDCONTACT_MCC
              </Tag>
              <Tag color="blue" value="%2NDCONTACT_MNC%" onClick={this.handleVariableClick}>
                2NDCONTACT_MNC
              </Tag>
              <Tag color="blue" value="%2NDCONTACT_LAC%" onClick={this.handleVariableClick}>
                2NDCONTACT_LAC
              </Tag>
              <Tag color="blue" value="%2NDCONTACT_CELLID%" onClick={this.handleVariableClick}>
                2NDCONTACT_CELLID
              </Tag>
              <Tag color="blue" value="%2NDCONTACT_MSISDN%" onClick={this.handleVariableClick}>
                2NDCONTACT_MSISDN
              </Tag>
              <Tag color="blue" value="%2NDCONTACT_IMSI%" onClick={this.handleVariableClick}>
                2NDCONTACT_IMSI
              </Tag>
              <Tag color="blue" value="%2NDCONTACT_LAT%" onClick={this.handleVariableClick}>
                2NDCONTACT_LAT
              </Tag>
              <Tag color="blue" value="%2NDCONTACT_LON%" onClick={this.handleVariableClick}>
                2NDCONTACT_LON
              </Tag>
              <Tag color="blue" value="%2NDCONTACT_LOCATION_NAME%" onClick={this.handleVariableClick}>
                2NDCONTACT_LOCATION_NAME
              </Tag>
              <div className="super-admin-content__drop-down_sms-wrap__variables_br" />
              <Tag color="blue" value="%FOUND_DISTANCE%" onClick={this.handleVariableClick}>
                FOUND_DISTANCE
              </Tag>
              <Tag color="blue" value="%SET_DISTANCE%" onClick={this.handleVariableClick}>
                SET_DISTANCE
              </Tag>
              <div className="super-admin-content__drop-down_sms-wrap__variables_br" />
              <Tag color="blue" value="%COUNTRY_CODE%" onClick={this.handleVariableClick}>
                COUNTRY_CODE
              </Tag>
              <Tag color="blue" value="%COUNTRY_CONDITION%" onClick={this.handleVariableClick}>
                COUNTRY_CONDITION
              </Tag>
              <div className="super-admin-content__drop-down_sms-wrap__variables_br" />
              <Tag color="blue" value="%EXPLANATION%" onClick={this.handleVariableClick}>
                EXPLANATION
              </Tag>
              <Tag color="blue" value="%STATUS%" onClick={this.handleVariableClick}>
                STATUS
              </Tag>
              <div className="super-admin-content__drop-down_sms-wrap__variables_br" />
              <Tag color="blue" value="%FIXED_POINT_LAT%" onClick={this.handleVariableClick}>
                FIXED_POINT_LAT
              </Tag>
              <Tag color="blue" value="%FIXED_POINT_LON%" onClick={this.handleVariableClick}>
                FIXED_POINT_LON
              </Tag>
              <Tag color="blue" value="%FOUND_CONTACTS_WITH_DISTANCE%" onClick={this.handleVariableClick}>
                FOUND_CONTACTS_WITH_DISTANCE
              </Tag>
              <div className="super-admin-content__drop-down_sms-wrap__variables_br" />
              <Tag color="blue" value="%ROBOT_ID%" onClick={this.handleVariableClick}>
                ROBOT_ID
              </Tag>
              <Tag color="blue" value="%ROBOT_NAME%" onClick={this.handleVariableClick}>
                ROBOT_NAME
              </Tag>
              <div className="super-admin-content__drop-down_sms-wrap__variables_br" />
              <Tag color="blue" value="%RESULT_AGE%" onClick={this.handleVariableClick}>
                RESULT_AGE
              </Tag>
              <Tag color="blue" value="%RESULT_RADIUS%" onClick={this.handleVariableClick}>
                RESULT_RADIUS
              </Tag>
              <div className="super-admin-content__drop-down_sms-wrap__variables_br" />
              <Tag color="blue" value="%2NDCONTACT_AGE%" onClick={this.handleVariableClick}>
                2NDCONTACT_AGE
              </Tag>
              <Tag color="blue" value="%2NDCONTACT_RADIUS%" onClick={this.handleVariableClick}>
                2NDCONTACT_RADIUS
              </Tag>
              <Tag color="blue" value="%2NDCONTACT_LOCATION_TIME%" onClick={this.handleVariableClick}>
                2NDCONTACT_LOCATION_TIME
              </Tag>
            </div>
            <Tabs animated={false} defaultActiveKey="1" onChange={this.setCurrentTab}>
              <Tabs.TabPane tab="Robot notification" key="1">
                <div className="text-area-highlight" style={{ display: 'flex' }}>
                  <antdInput.TextArea
                    className="text-area-highlight__text-only"
                    id="text-area-test"
                    onClick={() =>
                      this.setState({ textAreaPos: getCursorPos(document.getElementById('text-area-test')) })
                    }
                    ref={(ref) => {
                      this.robotTextAreaInput = ref;
                      return null;
                    }}
                    rows={9}
                    autosize={{ minRows: 9, maxRows: 9 }}
                    onChange={this.robotTextNotification}
                    value={robotTextNotification}
                  />
                  <Highlighter
                    className="text-area-highlight__highlighted-text"
                    searchWords={highlightedWords}
                    autoEscape={!!1}
                    textToHighlight={robotTextNotification}
                  />
                </div>
                <Button
                  style={{ marginTop: 20 }}
                  loading={this.state.robotTextButtonLoading}
                  onClick={this.updateRobotNotification}
                  size="mini"
                >
                  Update
                </Button>
              </Tabs.TabPane>
              <Tabs.TabPane tab="Default SMS forward" key="2">
                <div className="text-area-highlight" style={{ display: 'flex' }}>
                  <antdInput.TextArea
                    className="text-area-highlight__text-only"
                    id="text-area-test2"
                    onClick={() =>
                      this.setState({ textAreaPos: getCursorPos(document.getElementById('text-area-test2')) })
                    }
                    ref={(smsTextAreaInput) => {
                      this.smsTextAreaInput = smsTextAreaInput;
                      return null;
                    }}
                    rows={9}
                    autosize={{ minRows: 9, maxRows: 9 }}
                    onChange={this.smsTextNotification}
                    value={smsTextNotification}
                  />
                  <Highlighter
                    className="text-area-highlight__highlighted-text"
                    searchWords={highlightedWords}
                    autoEscape={!!1}
                    textToHighlight={smsTextNotification}
                  />
                </div>
                <Button
                  loading={this.state.smsTextButtonLoading}
                  style={{ marginTop: 20 }}
                  onClick={this.updateSmsNotification}
                  size="mini"
                >
                  Update
                </Button>
              </Tabs.TabPane>
            </Tabs>
          </div>
        </DropDown>

        <div className="super-admin-content__table-name">Admin List</div>
        <div className="add-drop-down-component super-admin-content__drop-down --create-admin">
          <div className="add-drop-down-component__buttons-area --create-admin">
            <div className="add-drop-down-component__buttons-area_button" onClick={this.toggleCreateAdmin}>
              <PlusCircleOutlined className="button-icon" />
              <span className="button-text">Create Admin</span>
            </div>
            <div
              className="add-drop-down-component__buttons-area_button --close-button"
              onClick={this.closeCreateAdmin}
            >
              <CloseCircleOutlined
                className="button-icon"
                style={{
                  opacity: this.state.opacityAdminToggle,
                  pointerEvents: this.state.opacityAdminToggle === 0 ? 'none' : 'initial',
                }}
              />
              <span
                style={{
                  opacity: this.state.opacityAdminToggle,
                  pointerEvents: this.state.opacityAdminToggle === 0 ? 'none' : 'initial',
                }}
                className="button-text"
              >
                Close
              </span>
            </div>
          </div>
          {this.state.opacityAdminToggle !== 0 && (
            <div
              className="add-drop-down-component__expand-area"
              style={{ height: this.state.heightAdminToggle, opacity: this.state.opacityAdminToggle }}
            >
              <Form onChange={this.onChangeCreateUser}>
                <Form.Group widths="equal">
                  <Form.Field>
                    <Input
                      autoComplete="new-password"
                      value={this.state.newContact.username}
                      size="mini"
                      name="username"
                      fluid
                      placeholder="Username"
                    />
                  </Form.Field>
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Field error={!!error.message}>
                    <Input
                      autoComplete="new-password"
                      type="password"
                      value={this.state.newContact.password}
                      size="mini"
                      name="password"
                      fluid
                      placeholder="Password"
                    />
                    {error.message && <InlineError style={{ position: 'absolute' }} text={error.message} />}
                  </Form.Field>
                  <Form.Field error={!!error.message}>
                    <Input
                      autoComplete="new-password"
                      type="password"
                      value={this.state.newContact.repeatPassword}
                      size="mini"
                      name="repeatPassword"
                      fluid
                      placeholder="Repeat Password"
                    />
                    {error.message && <InlineError style={{ position: 'absolute' }} text={error.message} />}
                  </Form.Field>
                </Form.Group>
                <div style={{ fontSize: 14 }}>
                  {
                    'Password must contain at least one upper & lower case character, one number and be at least 6 characters long.'
                  }
                </div>
                <Button style={{ marginTop: 10 }} onClick={this.submitCreateUser} size="mini">
                  Create
                </Button>
              </Form>
            </div>
          )}
        </div>
        <ExpandableTable
          loading={this.state.loading}
          userRole={!this.props.user.role ? 0 : this.props.user.role.id}
          editUser={this.editUser}
          editAdminCredits={this.props.editAdminCredits}
          editUserPermissions={this.editUserPermissions}
          suspendUser={this.suspendUser}
          suspendTable={false}
          data={this.props.users}
          teams={this.props.teams}
          tableData="admin"
          adminTable={!!1}
        />

        <div className="super-admin-content__table-name">User List</div>
        <ExpandableTable
          loading={this.state.loading}
          userRole={!this.props.user.role ? 0 : this.props.user.role.id}
          editUser={this.editUser}
          editUserPermissions={this.editUserPermissions}
          suspendUser={this.suspendUser}
          suspendTable={false}
          data={this.props.users}
          teams={this.props.teams}
          users={this.props.users}
          tableData="user"
          deleteUser={this.props.deleteUser}
        />

        <div className="super-admin-content__table-name">Suspended Users</div>
        <ExpandableTable
          loading={this.state.loading}
          userRole={!this.props.user.role ? 0 : this.props.user.role.id}
          editUser={this.editUser}
          editUserPermissions={this.editUserPermissions}
          suspendUser={this.suspendUser}
          restoreUser={this.restoreUser}
          suspendTable={!!1}
          data={this.props.users}
          users={this.props.users}
          tableData="suspended"
          deleteUser={this.props.deleteUser}
        />

        <div style={{ marginTop: 20 }} className="admin-content__table-name">
          Logs
        </div>
        <LogTable data={this.state.logs} />
      </div>
    );
  }
}

SuperAdminContent.propTypes = {
  app: PropTypes.shape({
    status: PropTypes.number,
    lock_templates: PropTypes.oneOfType([PropTypes.number]),
    notice: PropTypes.string,
  }).isRequired,
  users: PropTypes.shape({
    allUsers: PropTypes.object,
  }).isRequired,
  user: PropTypes.shape({
    role: PropTypes.shape({
      id: PropTypes.number,
    }),
  }).isRequired,
  teams: PropTypes.shape({
    allTeams: PropTypes.array,
    singleTeam: PropTypes.object,
  }).isRequired,
  getUsers: PropTypes.func.isRequired,
  editUser: PropTypes.func.isRequired,
  editUserPermissions: PropTypes.func.isRequired,
  suspendUser: PropTypes.func.isRequired,
  restoreUser: PropTypes.func.isRequired,
  createUser: PropTypes.func.isRequired,
  updateAppData: PropTypes.func.isRequired,
  getAppData: PropTypes.func.isRequired,
  toggleAppStatus: PropTypes.func.isRequired,
  editAdminCredits: PropTypes.func.isRequired,
  generateCredits: PropTypes.func.isRequired,
  deleteUser: PropTypes.func.isRequired,
  globalNotifications: PropTypes.shape({}).isRequired,
  getGlobalNotifications: PropTypes.func.isRequired,
  updateGlobalNotifications: PropTypes.func.isRequired,
  sendNoticeMessage: PropTypes.func.isRequired,
  getLogs: PropTypes.func.isRequired,
};

const mapStateToProps = (store) => ({
  users: store.users,
  user: store.user,
  teams: store.teams,
  app: store.app,
  globalNotifications: store.smsNotifications.globalNotifications,
});

export default connect(mapStateToProps, {
  getUsers,
  editUser,
  editUserPermissions,
  suspendUser,
  restoreUser,
  createUser,
  updateAppData,
  getAppData,
  toggleAppStatus,
  editAdminCredits,
  generateCredits,
  deleteUser,
  getGlobalNotifications,
  updateGlobalNotifications,
  sendNoticeMessage,
  getLogs,
})(SuperAdminContent);
