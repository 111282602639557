import React, { memo, useEffect, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import debounce from 'lodash.debounce';
import PropTypes from 'prop-types';

import { Input } from 'semantic-ui-react';
import { Popconfirm, Select, Table, Pagination, Button, Col, Row } from 'antd';

import { deleteContact, searchContact } from '../../actions/contacts/contact';
import { getSearchJobs } from '../../actions/jobs/jobs';

import history from '../../utils/history';
import TimeStamp from '../../utils/timeStamp';
import PermissionHandler from '../../utils/permissionsHandler';

import '../../containers/ContactsPage/contactsPage.sass';

const SavedContactsTable = ({ trigger }) => {
  const dispatch = useDispatch();
  const [activePageData, setActivePageData] = useState([]);
  const [activePageNum, setActivePageNum] = useState(1);
  const [perPage, setPerPage] = useState(5);
  const [allData, setAllData] = useState([]);
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [searchGroups, setSearchGroups] = useState([]);
  const [searchGroupId, setSearchGroupId] = useState(null);
  const [searchText, setSearchText] = useState('');
  const [columnName, setColumnName] = useState('created_at');
  const [sortDirection, setSortDirection] = useState('desc');

  const pageSizeOptions = ['5', '10', '20', '50', '100'];

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      columnId: '1',
      sorter: true,
      render: (_, contact) => (
        <span>
          <span
            onClick={() => history.push(`/contact/${contact.id}`)}
            style={{ textDecoration: 'underline', textUnderlineOffset: 3, cursor: 'pointer' }}
          >
            {contact.name}
          </span>
        </span>
      ),
    },
    {
      title: 'Alias',
      dataIndex: 'alias',
      key: 'alias',
      columnId: '2',
      sorter: true,
    },
    {
      title: 'Phone/IMSI',
      key: 'phoneIMSI',
      columnId: '3',
      render: (_, contact) => {
        let phoneImsiNone = 'No information';
        if (contact.primary_terminal !== null) {
          if (contact.primary_terminal && contact.primary_terminal.phone) {
            phoneImsiNone = contact.primary_terminal.phone;
          } else if (contact.primary_terminal && contact.primary_terminal.imsi) {
            phoneImsiNone = contact.primary_terminal.imsi;
          }
        }
        return <span>{phoneImsiNone}</span>;
      },
    },
    {
      title: 'Frequently searched',
      dataIndex: 'searched_times',
      key: 'searched_times',
      columnId: '4',
      sorter: true,
    },
    {
      title: 'Last searched',
      dataIndex: 'last_searched',
      key: 'last_searched',
      columnId: '5',
      sorter: true,
      render: (_, contact) => (
        <span>
          <TimeStamp date={contact.last_searched} />
        </span>
      ),
    },
    {
      title: 'Created at',
      dataIndex: 'created_at',
      key: 'created_at',
      columnId: '6',
      sorter: true,
      render: (_, contact) => (
        <span>
          <TimeStamp date={contact.created_at} />
        </span>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      columnId: '7',
      render: (_, contact) => (
        <PermissionHandler button="contact-delete">
          <Popconfirm
            style={{ zIndex: 100 }}
            title="Are you sure you want to delete this Contact?"
            okText="Yes"
            cancelText="No"
            onConfirm={() => deleteContactAction(contact.id)}
          >
            <Button danger>Delete</Button>
          </Popconfirm>
        </PermissionHandler>
      ),
    },
  ];

  async function getData(page, perPage, searchText, groupId, column, sort) {
    try {
      setIsTableLoading(true);
      const res = await dispatch(searchContact(page, perPage, searchText, groupId, column, sort));
      setActivePageData(Object.values(res.contacts.data).reverse());
      setAllData(res.contacts);
      setSearchGroups(res.contactGroups);
    } catch (err) {
      console.error(err);
    } finally {
      setIsTableLoading(false);
    }
  }

  const searchAction = (inputValue, id, pageSize) => {
    setIsTableLoading(true);
    dispatch(searchContact(1, pageSize, inputValue, id, 'created_at', 'asc')).then((res) => {
      setIsTableLoading(false);
      setSearchGroups(res.contactGroups);
      setActivePageData(Object.values(res.contacts.data).reverse());
      setAllData(res.contacts);
      setActivePageNum(res.contacts.meta.current_page);
      document.getElementById('saved-contacts-input').focus();
    });
  };

  const sortAction = (pagination, filters, sorter) => {
    const columnNameChecked = sorter.order ? sorter.field : 'created_at';
    const sortDirectionChecked = sorter.order && sorter.order === 'ascend' ? 'asc' : 'desc';

    setColumnName(columnNameChecked);
    setSortDirection(sortDirectionChecked);
    setActivePageNum(1);

    getData(1, perPage, searchText, searchGroupId, columnNameChecked, sortDirectionChecked);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceHandle = useCallback(debounce(searchAction, 1000), []);

  const handleSearchByContact = (e) => {
    setSearchText(e.target.value);
    debounceHandle(e.target.value, searchGroupId, perPage);
  };

  const handleSearchByGroup = (groupId) => {
    setSearchGroupId(groupId);
    getData(activePageNum, perPage, searchText, groupId, columnName, sortDirection);
  };

  const deleteContactAction = (contactId) => {
    dispatch(deleteContact(contactId))
      .then(() => dispatch(getSearchJobs()))
      .then(() => getData(activePageNum, perPage, '', searchGroupId, columnName, sortDirection));
  };

  const handlePaginationChange = (page, pageSize) => {
    setActivePageNum(page);
    setPerPage(pageSize);

    getData(page, pageSize, searchText, searchGroupId, columnName, sortDirection);
  };

  const handlePageSizeChange = (page, pageSize) => {
    setPerPage(pageSize);
  };

  // get data on first page load
  useEffect(() => {
    getData(activePageNum, perPage, searchText, searchGroupId, 'created_at', 'desc');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // get data when new contact was created
  useEffect(() => {
    if (trigger) {
      setActivePageNum(1);
      getData(1, perPage, '', '', 'created_at', 'desc');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger]);

  // get data on Change Page Size
  useEffect(() => {
    setActivePageNum(1);

    getData(1, perPage, searchText, searchGroupId, 'created_at', 'desc');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [perPage]);

  return (
    <div className="grey-wrapper" style={{ marginTop: 20 }}>
      <Row>
        <Col span={12}>
          <h4>SAVED CONTACTS</h4>
        </Col>
      </Row>
      <Row style={{ margin: '10px 0' }}>
        <Col span={12}>
          <Input
            placeholder="Search in saved contacts"
            style={{ width: 200, marginRight: 10 }}
            size="mini"
            value={searchText}
            onChange={handleSearchByContact}
            disabled={isTableLoading}
            id="saved-contacts-input"
          />
          <Select
            onChange={handleSearchByGroup}
            style={{ width: 200, height: '100%', fontSize: 12 }}
            placeholder="Search by Group"
            disabled={isTableLoading}
          >
            <Select.Option key="default-group-id-0" value={null}>
              All
            </Select.Option>
            {Object.values(searchGroups).map((group) => (
              <Select.Option key={group.id} value={group.id}>
                {group.name}
              </Select.Option>
            ))}
          </Select>
        </Col>

        <Col span={12}>
          <Pagination
            showSizeChanger
            className="table-pagination"
            defaultCurrent={1}
            pageSizeOptions={pageSizeOptions}
            pageSize={perPage}
            onChange={handlePaginationChange}
            onShowSizeChange={handlePageSizeChange}
            current={activePageNum}
            total={allData.meta?.total}
            size="small"
            disabled={isTableLoading}
          />
        </Col>
      </Row>

      {activePageData ? (
        <Table
          key="dataIndex"
          onChange={sortAction}
          loading={isTableLoading}
          columns={columns}
          dataSource={activePageData}
          pagination={false}
          size="small"
          scroll={{ x: 'auto' }}
        />
      ) : null}
    </div>
  );
};

SavedContactsTable.defaultProps = {
  trigger: false,
};

SavedContactsTable.propTypes = {
  trigger: PropTypes.bool,
};

export default memo(SavedContactsTable);
