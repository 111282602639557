import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Button, Dimmer, Form, Input, Loader, Segment, Table } from 'semantic-ui-react';
import { Popconfirm } from 'antd';

import { getSensorGroups, createSensorGroup, deleteSensorGroup } from '../../actions/sensorGroups/groups';

import PermissionHandler from '../../utils/permissionsHandler';
import DropDown from '../../components/addDropDown';
import TimeStamp from '../../utils/timeStamp';

class CorrelationContainer extends PureComponent {
  state = {
    createSuccess: false,
    newGroupName: '',
    loading: false,
  };

  componentDidMount() {
    this.props.getSensorGroups();
  }

  onChangeCreateGroup = (e) => this.setState({ newGroupName: e.target.value });

  submitCreateGroup = () => {
    const { newGroupName } = this.state;
    if (newGroupName) {
      this.setState({ loading: true }, () => {
        this.props
          .createSensorGroup(newGroupName)
          .then(() => this.setState({ createSuccess: true, loading: false, newGroupName: '' }))
          .catch(() => this.setState({ createSuccess: true, loading: false, newGroupName: '' }));
      });
    }
  };

  deleteGroup = (groupId) => {
    this.props.deleteSensorGroup(groupId);
    this.props.deleteGroupCallback(groupId);
  };

  render() {
    const { createSuccess, newGroupName, loading } = this.state;
    const { groupsData, openGroupCallback } = this.props;
    const correlationsObj =
      Object.values(groupsData).length !== 0 &&
      Object.values(groupsData).map((group) => {
        const key = `contact-${group.id}`;
        return (
          <Table.Row key={key}>
            <Table.Cell onClick={() => openGroupCallback(group.id, group.name)}>{group.name}</Table.Cell>
            <Table.Cell onClick={() => openGroupCallback(group.id, group.name)}>{group.sensor_count}</Table.Cell>
            <Table.Cell onClick={() => openGroupCallback(group.id, group.name)}>
              <TimeStamp date={group.created_at} />
            </Table.Cell>
            <Table.Cell>
              <PermissionHandler button="contact-group-delete">
                <Popconfirm
                  style={{ zIndex: 100 }}
                  title="Delete this Sensor Group?"
                  okText="Yes"
                  cancelText="No"
                  onConfirm={() => this.deleteGroup(group.id)}
                >
                  <Button size="mini">Delete</Button>
                </Popconfirm>
              </PermissionHandler>
            </Table.Cell>
          </Table.Row>
        );
      });

    return (
      <div className="correlation-container">
        <PermissionHandler button="contact-group-create">
          <DropDown
            className="groups-page__drop-down"
            createSuccess={createSuccess}
            buttonName="Create Correlation"
            height={45}
          >
            <Form onChange={(e) => this.onChangeCreateGroup(e)}>
              <Form.Group widths="equal">
                <Form.Field>
                  <Input value={newGroupName} size="mini" name="name" fluid placeholder="Correlation name" />
                </Form.Field>
                <Button loading={loading} onClick={this.submitCreateGroup} size="mini">
                  Create
                </Button>
              </Form.Group>
            </Form>
          </DropDown>
        </PermissionHandler>
        {correlationsObj && (
          <Segment vertical>
            <Dimmer active={this.state.tableLoading} inverted>
              <Loader inverted content="Fetching table..." />
            </Dimmer>
            <Table basic selectable>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell width={9}>Group Name</Table.HeaderCell>
                  <Table.HeaderCell width={2}>Sensors</Table.HeaderCell>
                  <Table.HeaderCell width={3}>Created at</Table.HeaderCell>
                  <Table.HeaderCell width={2}>Actions</Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>{correlationsObj}</Table.Body>
            </Table>
          </Segment>
        )}
      </div>
    );
  }
}

CorrelationContainer.propTypes = {
  getSensorGroups: PropTypes.func.isRequired,
  createSensorGroup: PropTypes.func.isRequired,
  openGroupCallback: PropTypes.func.isRequired,
  deleteGroupCallback: PropTypes.func.isRequired,
  deleteSensorGroup: PropTypes.func.isRequired,
  groupsData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
};

const mapStateToProps = (store) => ({
  groupsData: store.sensorGroups.groups,
});

export default connect(mapStateToProps, {
  getSensorGroups,
  createSensorGroup,
  deleteSensorGroup,
})(CorrelationContainer);
