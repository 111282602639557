import { combineReducers } from 'redux';

import app from './app';
import user from './user';
import users from './users';
import contact from './contact';
import teams from './teams';
import groups from './groups';
import alert from './alert';
import robots from './robots';
import search from './search';
import whiteList from './whiteList';
import teamWhiteList from './teamWhiteList';
import files from './files';
import report from './report';
import cellReport from './cellReport';
import smsNotifications from './smsNotifications';
import agents from './agents';
import sensorGroups from './sensorGroups';
import jobs from './jobs';
import serviceStatus from './serviceStatus';
import survey from './survey';

export default combineReducers({
  app,
  user,
  users,
  contact,
  teams,
  groups,
  alert,
  robots,
  search,
  whiteList,
  teamWhiteList,
  files,
  report,
  cellReport,
  smsNotifications,
  agents,
  sensorGroups,
  jobs,
  serviceStatus,
  survey,
});
