import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { CheckCircleOutlined } from '@ant-design/icons';

import { Table, notification, Popover, Popconfirm, Select, Tag, Switch } from 'antd';
import { Button, Form, Radio } from 'semantic-ui-react';

import TimeStamp from '../../../utils/timeStamp';
import Permissions from './permissions';

import './expandableTable.sass';

const emptyPermissions = {
  'robot-create': false,
  'robot-edit': false,
  'robot-delete': false,
  'contact-create': false,
  'contact-edit': false,
  'contact-delete': false,
  'contact-group-create': false,
  'contact-group-edit': false,
  'contact-group-delete': false,
  'locate-create': false,
  'locate-edit': false,
  'locate-delete': false,
  'terminal-create': false,
  'terminal-edit': false,
  'terminal-delete': false,
  'team-create': false,
  'team-edit': false,
  'team-delete': false,
  'search-create': false,
  'report-view': false,
  'team-contact-edit': false,
  'team-contact-delete': false,
  'team-robot-edit': false,
  'team-robot-delete': false,
  'team-contact-group-edit': false,
  'team-contact-group-delete': false,
  'cell-create': false,
  'cell-edit': false,
  'cell-delete': false,
  'cell-report-view': false,
  'hold-enable': false,
};

const truePermissions = {
  'robot-create': true,
  'robot-edit': true,
  'robot-delete': true,
  'contact-create': true,
  'contact-edit': true,
  'contact-delete': true,
  'contact-group-create': true,
  'contact-group-edit': true,
  'contact-group-delete': true,
  'locate-create': true,
  'locate-edit': true,
  'locate-delete': true,
  'terminal-create': true,
  'terminal-edit': true,
  'terminal-delete': true,
  'team-create': true,
  'team-edit': true,
  'team-delete': true,
  'search-create': true,
  'report-view': true,
  'team-contact-edit': true,
  'team-contact-delete': true,
  'team-robot-edit': true,
  'team-robot-delete': true,
  'team-contact-group-edit': true,
  'team-contact-group-delete': true,
  'cell-create': true,
  'cell-edit': true,
  'cell-delete': true,
  'cell-report-view': true,
  'hold-enable': true,
};

class ExpandableTableUserTable extends PureComponent {
  state = {
    sortedInfo: {
      order: 'descend',
      columnKey: 'username',
    },
    editableUser: false,
    editableTeam: false,
    editableCredits: false,
    creditsLoading: false,
    editableAllocCredits: false,
    allocCreditsLoading: false,
    expandedEdit: [],
    userEditSelected: false,
    userEditLoading: false,
    permissionsSelected: false,
    permissionsLoading: false,
    teamSelected: false,
    teamLoading: false,
    editStateUser: false,
    editStateTeam: false,
    user: {
      userName: '',
      newPassword: '',
      repeatPassword: '',
      dailyCredits: 0,
      permissions: {
        'robot-create': false,
        'robot-edit': false,
        'robot-delete': false,
        'contact-create': false,
        'contact-edit': false,
        'contact-delete': false,
        'contact-group-create': false,
        'contact-group-edit': false,
        'contact-group-delete': false,
        'locate-create': false,
        'locate-edit': false,
        'locate-delete': false,
        'terminal-create': false,
        'terminal-edit': false,
        'terminal-delete': false,
        'team-create': false,
        'team-edit': false,
        'team-delete': false,
        'search-create': false,
        'report-view': false,
        'team-contact-edit': false,
        'team-contact-delete': false,
        'team-robot-edit': false,
        'team-robot-delete': false,
        'team-contact-group-edit': false,
        'team-contact-group-delete': false,
        'cell-create': false,
        'cell-edit': false,
        'cell-delete': false,
        'cell-report-view': false,
        'hold-enable': false,
      },
      team: {
        teamId: null,
        teamName: '',
        leader: null,
      },
    },
    admin: {
      allocated_credits: 0,
    },
    deleteSelected: {
      mode: '',
      user_id: '',
      name: '',
    },
    activePopUp: '',
    activePopUpId: '',
  };

  onChangeCheckbox = (checkedValues, data) => {
    const name = data.name;
    this.setState({
      ...this.state,
      permissionsSelected: true,
      user: {
        ...this.state.user,
        permissions: {
          ...this.state.user.permissions,
          [name]: data.checked,
        },
      },
    });
  };

  onChangeUsername = (e) =>
    this.setState({
      ...this.state,
      userEditSelected: true,
      user: {
        ...this.state.user,
        userName: e.target.value,
      },
    });

  onChangePassword = (e) =>
    this.setState({
      ...this.state,
      userEditSelected: true,
      user: {
        ...this.state.user,
        [e.target.name]: e.target.value,
      },
    });

  setPermissions = (permissions) => {
    const permissionsData = { ...emptyPermissions };
    permissions?.map((permission) => {
      permissionsData[permission.name] = true;
      return null;
    });
    return permissionsData;
  };

  setTeam = (id, name) => {
    if (id === null) {
      return {
        teamId: null,
        teamName: '',
      };
    }
    return {
      teamId: id,
      teamName: !name ? '' : name.name,
    };
  };

  handleChange = (pagination, filters, sorter) => {
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter,
    });
  };

  submitHasErrors = (username, password, repeatPassword) => {
    if (username !== '' && password === '' && repeatPassword === '') {
      return { result: false, message: '' };
    } else if (username === '') {
      return { result: true, message: "Name can't be empty" };
    } else if (password.length <= 5 || !/[a-z]/.test(password) || !/[A-Z]/.test(password) || !/[0-9]/.test(password)) {
      return {
        result: true,
        message: (
          <div>
            <div>Incorrect password format.</div>
            <div>Password must have at least:</div>
            <ul>
              <li>6 characters long</li>
              <li>1 uppercase & 1 lowercase character</li>
              <li>1 number</li>
            </ul>
          </div>
        ),
      };
    } else if (password !== repeatPassword) return { result: true, message: "Passwords don't match" };

    return { result: false, message: '' };
  };

  submitEditUser = (e, userId) => {
    const username = this.state.user.userName;
    const password = e.target.newPassword.value;
    const passwordConfirmation = e.target.repeatPassword.value;
    const checkResult = this.submitHasErrors(username, password, passwordConfirmation);
    this.setState({ ...this.state, userEditLoading: true });

    if (!checkResult.result && password !== '' && passwordConfirmation !== '') {
      this.props
        .editUser(userId, { username, password, password_confirmation: passwordConfirmation })
        .then(() =>
          this.setState({
            ...this.state,
            editableUser: false,
            userEditSelected: false,
            userEditLoading: false,
            user: {
              ...this.state.user,
              newPassword: '',
              repeatPassword: '',
            },
          }),
        )
        .catch(() => this.setState({ userEditLoading: false }));
    } else if (!checkResult.result && password === '' && passwordConfirmation === '') {
      this.props
        .editUser(userId, { username })
        .then(() =>
          this.setState({
            editableUser: false,
            userEditSelected: false,
            userEditLoading: false,
          }),
        )
        .catch(() => this.setState({ userEditLoading: false }));
    } else if (checkResult.result) {
      this.setState({
        ...this.state,
        userEditLoading: false,
        user: {
          ...this.state.user,
          newPassword: '',
          repeatPassword: '',
        },
      });
      notification.error({
        message: 'Error',
        description: checkResult.message,
      });
    }
  };

  submitPermissions = (permissions, userId) => {
    const data = { permissions: [] };
    const permissionsArray = [];

    this.setState({ ...this.state, permissionsLoading: true });

    Object.keys(permissions).map((permissionKey) => {
      if (permissions[permissionKey]) {
        permissionsArray.push(permissionKey);
      }
      return null;
    });

    data.permissions = permissionsArray;

    return this.props.editUserPermissions(userId, data);
  };

  expandRow = (expanded, key, data) => {
    let keys = this.state.expandedEdit;
    const index = keys.indexOf(key);

    if (expanded && index === -1) keys = [key];
    else keys.splice(index, 1);

    let username = '';
    if (typeof data.username === 'string') username = data.username;
    else if (Array.isArray(data.username.props.children)) {
      username = data.username.props.children[0].props.children;
    } else username = data.username.props.children;

    const permissions = this.setPermissions(data.permissions);
    const team = this.setTeam(data.team_id, data.team);
    this.setState({
      expandedEdit: keys,
      editableTeam: false,
      editableUser: false,
      permissionsSelected: false,
      editableCredits: false,
      user: {
        ...this.state.user,
        userName: username,
        dailyCredits: data.daily_credits === null ? '-1' : data.daily_credits,
        permissions,
        team: {
          ...this.state.user.team,
          teamName: team.teamName,
          teamId: team.teamId,
          leader: !!data.leader,
        },
      },
      admin: {
        allocated_credits: data.allocated_credits === null ? '-1' : data.allocated_credits,
      },
    });
  };

  saveCredits = (userId, credits) => {
    this.setState({ creditsLoading: true });
    this.props
      .editUserCredits(userId, { daily_credits: credits })
      .then(() => this.setState({ creditsLoading: false, editableCredits: false }))
      .catch(() => this.setState({ creditsLoading: false, editableCredits: false }));
  };

  saveAllocCredits = (userId, credits) => {
    this.setState({ allocCreditsLoading: true });
    this.props
      .editAdminCredits(userId, credits)
      .then(() => this.setState({ allocCreditsLoading: false, editableAllocCredits: false }))
      .catch(() => this.setState({ allocCreditsLoading: false, editableAllocCredits: false }));
  };

  saveAllocCreditsAdmin = (userId, credits) => {
    this.setState({ allocCreditsLoading: true });
    this.props
      .editUserCredits(userId, { allocated_credits: credits })
      .then(() => this.setState({ allocCreditsLoading: false, editableAllocCredits: false }))
      .catch(() => this.setState({ allocCreditsLoading: false, editableAllocCredits: false }));
  };

  compareByAlpha = (a, b) => {
    if (a > b) {
      return -1;
    }
    if (a < b) {
      return 1;
    }
    return 0;
  };

  togglePopUp = (userIdString, userId) => {
    if (this.state.activePopUp === userIdString) {
      this.setState({
        activePopUp: '',
        activePopUpId: '',
        deleteSelected: {
          mode: '',
          user_id: '',
          name: '',
        },
      });
    } else if (userIdString === '') {
      this.setState({
        activePopUp: '',
        activePopUpId: '',
        deleteSelected: {
          mode: '',
          user_id: '',
          name: '',
        },
      });
    } else {
      this.setState({
        activePopUp: userIdString,
        activePopUpId: userId,
        deleteSelected: {
          mode: '',
          user_id: '',
          name: '',
        },
      });
    }
  };

  handleChangeRadio = (e, { value }) => this.setState({ deleteSelected: { user_id: '', mode: value } });

  handleTransferToUser = (value, data) => {
    this.setState({
      deleteSelected: {
        ...this.state.deleteSelected,
        user_id: data.key,
        name: value,
      },
    });
  };

  confirmDeletion = (idToDelete) => {
    if (this.state.deleteSelected.mode !== '') {
      this.togglePopUp('');
      const {
        deleteSelected: { mode, user_id: userId },
      } = this.state;
      this.props.deleteUser(idToDelete, { mode, user_id: userId });
    } else {
      notification.error({
        message: 'Delete fail',
        description: 'You must select DELETE or Transfer option',
      });
    }
  };

  editLeaderStatus = (status, userId) => {
    this.props
      .editUser(userId, { leader: status ? 1 : 0 })
      .then(() =>
        this.setState({
          ...this.state,
          user: {
            ...this.state.user,
            team: {
              ...this.state.user.team,
              leader: status ? 1 : 0,
            },
          },
        }),
      )
      .catch(() => this.setState({ userEditLoading: false }));
  };

  render() {
    const columns = [
      {
        title: 'User Name',
        dataIndex: 'username',
        width: '19%',
        key: 'username',
        sorter: (a, b) =>
          this.compareByAlpha(a.username?.props?.children?.toLowerCase(), b.username?.props?.children?.toLowerCase()),
        render: (text) => <div style={{ wordBreak: 'break-word' }}>{text}</div>,
      },
      {
        title: 'Created at',
        dataIndex: 'created',
        width: '15%',
        key: 'created',
      },
      {
        title: 'Credits',
        dataIndex: 'credits',
        width: '10%',
        key: 'credits',
        render: (text, data) => (
          <div>
            {data.credits.day_credit_row ? data.credits.day_credit_row.used_credits : 'null'}/
            {data.credits.allocated_credits === null ? '∞' : data.credits.allocated_credits}/
            {data.credits.daily_credits === null ? '∞' : data.credits.daily_credits}
          </div>
        ),
      },
      {
        title: 'In teams',
        dataIndex: 'team.name',
        width: '10%',
        key: 'team',
        align: 'center',
        render: (text, data) => {
          const content = data?.team?.map((team) => <div key={team.name}>{team.name}</div>) || [];
          if (data?.team?.length > 0) {
            return (
              <Popover content={content} title="Teams" trigger="hover">
                <div>{data.team.length}</div>
              </Popover>
            );
          }
          return <div>{data?.team?.length || 0}</div>;
        },
      },
      {
        className: 'leader-column',
        title: 'Is Leader',
        dataIndex: 'leader',
        width: '9%',
        key: 'leader',
        render: (text, data) => {
          const teamLead = data.leader === 1 && true;
          return (
            <CheckCircleOutlined
              style={{ fontSize: 20, color: teamLead ? 'green' : 'grey', opacity: teamLead ? 1 : 0.5 }}
            />
          );
        },
      },
      { className: 'last-online', title: 'Last online', dataIndex: 'online', width: '13%', key: 'online' },
      { className: 'actions-column', title: 'Actions', dataIndex: 'actions', width: '26%', key: 'actions' },
    ];

    const usersOptions =
      this.props.users.allUsers &&
      Object.values(this.props.users.allUsers).map((user) => {
        if (user.id !== this.state.activePopUpId) {
          return (
            <Select.Option key={user.id} value={user.username}>
              {user.username}
            </Select.Option>
          );
        }
        return null;
      });

    const userData = {};
    if (this.props.data.allUsers) {
      Object.values(this.props.data.allUsers).map((user) => {
        if (user.role_id === 2 && !user.deleted_at && this.props.tableData === 'admin') {
          userData.allUsers = { ...userData.allUsers, [user.id]: { ...user } };
        } else if (user.role_id === 3 && !user.deleted_at && this.props.tableData === 'user') {
          userData.allUsers = { ...userData.allUsers, [user.id]: { ...user } };
        } else if (user.deleted_at && this.props.tableData === 'suspended') {
          userData.allUsers = { ...userData.allUsers, [user.id]: { ...user } };
        }
        return null;
      });
    }
    const users = userData.allUsers;
    const { userName, newPassword, repeatPassword } = this.state.user;
    const { userRole, adminTable } = this.props;
    const data =
      users && Object.values(users).length > 0
        ? Object.values(users)
            .reverse()
            .map((user) => ({
              key: user.id,
              username:
                (this.props.tableData === 'user' || this.props.tableData === 'suspended') &&
                this.props.userRole === 1 ? (
                  <div>
                    <span>{user.username}</span>
                    {user && user.restricted_admin && (
                      <Tag style={{ marginLeft: 3 }} color="#2db7f5">
                        {user.restricted_admin.username}
                      </Tag>
                    )}
                  </div>
                ) : (
                  <div>{user.username}</div>
                ),
              created: <TimeStamp date={user.created_at} />,
              credits: user,
              team_id: user.team_id,
              daily_credits: user.daily_credits,
              allocated_credits: user.allocated_credits,
              team: user.team_many,
              leader: user.leader,
              permissions: user.permissions,
              online: user.last_online,
              actions: (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <Button style={{ marginBottom: 3 }} onClick={() => this.expandRow(true, user.id, user)} size="mini">
                    Edit
                  </Button>
                  {this.props.suspendTable ? (
                    <Button
                      style={{ marginBottom: 3 }}
                      onClick={() => this.props.restoreUser(user.id)}
                      className="actions-column__suspend"
                      size="mini"
                    >
                      Restore
                    </Button>
                  ) : (
                    <Button
                      style={{ marginBottom: 3 }}
                      onClick={() => this.props.suspendUser(user.id)}
                      className="actions-column__suspend"
                      size="mini"
                    >
                      Suspend
                    </Button>
                  )}
                  {!adminTable && user.role_id !== 2 && (
                    <Popconfirm
                      title={
                        <div>
                          <Form className="team-management-component__delete_form">
                            <Form.Field className="team-management-component__delete_form__text">
                              Delete mode will remove all user data then delete the user himself, transfer mode will
                              transfer all user data to a specific user then remove the user only.
                            </Form.Field>
                            <Form.Group className="team-management-component__delete_form__mode">
                              <Radio
                                className="team-management-component__delete_form__mode_radio"
                                label="Delete"
                                name="delete"
                                value="delete"
                                checked={this.state.deleteSelected.mode === 'delete'}
                                onChange={this.handleChangeRadio}
                              />
                              <Radio
                                className="team-management-component__delete_form__mode_radio"
                                label="Transfer"
                                name="transfer"
                                value="transfer"
                                checked={this.state.deleteSelected.mode === 'transfer'}
                                onChange={this.handleChangeRadio}
                              />
                            </Form.Group>
                            <Form.Field>
                              <Select
                                className="team-management-component__delete_form__select"
                                disabled={
                                  this.state.deleteSelected.mode === 'delete' || this.state.deleteSelected.mode === ''
                                }
                                showSearch
                                style={{ width: 200 }}
                                value={this.state.deleteSelected.name}
                                placeholder="Select user for transfer"
                                optionFilterProp="children"
                                onChange={(e, data2) => this.handleTransferToUser(e, data2)}
                                filterOption={(input, option) =>
                                  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                              >
                                {usersOptions}
                              </Select>
                            </Form.Field>
                          </Form>
                        </div>
                      }
                      visible={this.state.activePopUp === `userId-${user.id}`}
                      onCancel={() => this.togglePopUp('')}
                      onConfirm={() => this.confirmDeletion(user.id)}
                    >
                      <Button
                        style={{ marginBottom: 3 }}
                        onClick={() => this.togglePopUp(`userId-${user.id}`, user.id)}
                        size="mini"
                      >
                        Delete
                      </Button>
                    </Popconfirm>
                  )}
                </div>
              ),
              expand: (
                <div className={`expandable-content ${this.props.suspendTable ? '--disabled' : ''}`}>
                  <Permissions
                    authRoleId={userRole}
                    tableData={this.props.tableData}
                    userId={user.id}
                    userRoleId={user.role_id}
                    permissions={this.state.user?.permissions}
                    submitPermissions={this.submitPermissions}
                  />
                  <div className="expandable-content__edit">
                    <h3>Credentials & Credits Edit</h3>
                    <Form onSubmit={(e) => this.submitEditUser(e, user.id)}>
                      <Form.Group>
                        <Form.Input
                          style={!this.state.editableUser ? { opacity: '0.3', pointerEvents: 'none' } : {}}
                          onChange={this.onChangeUsername}
                          value={userName}
                          size="mini"
                          placeholder="User Name"
                          width={5}
                        />
                        <Form.Input
                          style={!this.state.editableUser ? { opacity: '0.3', pointerEvents: 'none' } : {}}
                          onChange={this.onChangePassword}
                          value={newPassword}
                          type="password"
                          name="newPassword"
                          size="mini"
                          placeholder="New Password"
                          width={4}
                        />
                        <Form.Input
                          style={!this.state.editableUser ? { opacity: '0.3', pointerEvents: 'none' } : {}}
                          onChange={this.onChangePassword}
                          value={repeatPassword}
                          type="password"
                          name="repeatPassword"
                          size="mini"
                          placeholder="Repeat Password"
                          width={4}
                        />
                        {this.state.editableUser ? (
                          <Button
                            type="submit"
                            className="super-admin-content__grid_button"
                            loading={this.state.userEditLoading}
                            disabled={!this.state.userEditSelected}
                            size="mini"
                            width={3}
                          >
                            Save
                          </Button>
                        ) : (
                          <Button
                            className="super-admin-content__grid_button"
                            onClick={() => this.setState({ ...this.state, editableUser: true })}
                            size="mini"
                            style={{ width: '64px' }}
                            width={3}
                          >
                            Edit
                          </Button>
                        )}
                      </Form.Group>
                    </Form>
                    {userRole === 2 && (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Form>
                          <Form.Group style={{ position: 'relative' }}>
                            <Form.Input
                              value={this.state.user.dailyCredits}
                              size="mini"
                              placeholder="Daily Credits"
                              style={
                                !this.state.editableCredits
                                  ? { width: 76, height: 32, opacity: '0.3', pointerEvents: 'none' }
                                  : { width: 76, height: 32 }
                              }
                              onChange={(e) =>
                                this.setState({
                                  user: {
                                    ...this.state.user,
                                    dailyCredits: e.target.value,
                                  },
                                })
                              }
                            />
                            {this.state.editableCredits ? (
                              <Button
                                type="submit"
                                style={{ height: 32, width: 65 }}
                                className="super-admin-content__grid_button"
                                onClick={() => this.saveCredits(user.id, this.state.user.dailyCredits)}
                                size="mini"
                                loading={this.state.creditsLoading}
                              >
                                Save
                              </Button>
                            ) : (
                              <Button
                                style={{ height: 32, width: 65 }}
                                className="super-admin-content__grid_button"
                                onClick={() => this.setState({ editableCredits: true })}
                                size="mini"
                              >
                                Edit
                              </Button>
                            )}
                            <div
                              style={{
                                position: 'absolute',
                                bottom: '-20px',
                                fontSize: '12px',
                                color: 'gray',
                                left: '7px',
                                width: 275,
                              }}
                            >
                              Daily Credits. (type -1 for unlimited credits)
                            </div>
                          </Form.Group>
                          <Form.Group style={{ position: 'relative', marginTop: 30 }}>
                            <Form.Input
                              value={this.state.admin.allocated_credits}
                              size="mini"
                              placeholder="Allocated Credits"
                              style={
                                !this.state.editableAllocCredits
                                  ? { width: 76, height: 32, opacity: '0.3', pointerEvents: 'none' }
                                  : { width: 76, height: 32 }
                              }
                              onChange={(e) =>
                                this.setState({
                                  admin: {
                                    ...this.state.admin,
                                    allocated_credits: e.target.value,
                                  },
                                })
                              }
                            />
                            {this.state.editableAllocCredits ? (
                              <Button
                                type="submit"
                                style={{ height: 32, width: 65 }}
                                className="super-admin-content__grid_button"
                                onClick={() => this.saveAllocCreditsAdmin(user.id, this.state.admin.allocated_credits)}
                                size="mini"
                                loading={this.state.allocCreditsLoading}
                              >
                                Save
                              </Button>
                            ) : (
                              <Button
                                style={{ height: 32, width: 65 }}
                                className="super-admin-content__grid_button"
                                onClick={() => this.setState({ editableAllocCredits: true })}
                                size="mini"
                              >
                                Edit
                              </Button>
                            )}
                            <div
                              style={{
                                position: 'absolute',
                                bottom: '-20px',
                                fontSize: '12px',
                                color: 'gray',
                                left: '7px',
                                width: 320,
                              }}
                            >
                              Allocated Credits. (type -1 to remove allocated credits)
                            </div>
                          </Form.Group>
                        </Form>
                      </div>
                    )}
                    {this.props.tableData === 'admin' && (
                      <Form>
                        <Form.Group>
                          <Form.Input
                            value={this.state.admin.allocated_credits}
                            size="mini"
                            placeholder="Daily Credits"
                            style={
                              !this.state.editableAllocCredits
                                ? { opacity: '0.3', pointerEvents: 'none' }
                                : { opacity: '1', pointerEvents: 'initial' }
                            }
                            onChange={(e) =>
                              this.setState({
                                admin: {
                                  ...this.state.admin,
                                  allocated_credits: e.target.value,
                                },
                              })
                            }
                          />
                          {this.state.editableAllocCredits ? (
                            <Button
                              type="submit"
                              style={{ height: 32, width: 65 }}
                              className="super-admin-content__grid_button"
                              onClick={() => this.saveAllocCredits(user.id, this.state.admin.allocated_credits)}
                              size="mini"
                              loading={this.state.allocCreditsLoading}
                            >
                              Save
                            </Button>
                          ) : (
                            <Button
                              style={{ height: 32, width: 65 }}
                              className="super-admin-content__grid_button"
                              onClick={() => this.setState({ editableAllocCredits: true })}
                              size="mini"
                            >
                              Edit
                            </Button>
                          )}
                          <div
                            style={{
                              position: 'absolute',
                              bottom: '-20px',
                              fontSize: '12px',
                              color: 'gray',
                              left: '1px',
                              width: 320,
                            }}
                          >
                            Allocated Credits. (type -1 to remove allocated credits)
                          </div>
                        </Form.Group>
                      </Form>
                    )}
                    <Form style={{ margin: '24px 0px 0px 7px' }}>
                      <Form.Group>
                        <Switch
                          checkedChildren="Leader"
                          unCheckedChildren="Not leader"
                          checked={!!this.state.user.team.leader}
                          onChange={(status) => this.editLeaderStatus(status, user.id)}
                        />
                      </Form.Group>
                    </Form>
                  </div>
                </div>
              ),
            }))
        : [];

    return (
      <div className="expandable-table-component">
        <Table
          loading={this.props.loading}
          columns={columns}
          dropdownPrefixCls=""
          onChange={this.handleChange}
          pagination={{ pageSize: 5 }}
          expandedRowRender={(record) => <div>{record.expand}</div>}
          expandedRowKeys={this.state.expandedEdit}
          onExpand={(expanded, record) => this.expandRow(expanded, record.key, record)}
          dataSource={data}
          size="middle"
          rowKey="key"
        />
      </div>
    );
  }
}

ExpandableTableUserTable.defaultProps = {
  restoreUser: () => null,
  addUserToTeam: () => null,
  makeTeamLead: () => null,
  removeUserFromTeam: () => null,
  tableData: '',
  editAdminCredits: () => null,
  editUserCredits: () => null,
  users: {},
  deleteUser: () => null,
  adminTable: false,
};

ExpandableTableUserTable.propTypes = {
  data: PropTypes.shape({
    allUsers: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  }).isRequired,
  userRole: PropTypes.number.isRequired,
  editUser: PropTypes.func.isRequired,
  editUserPermissions: PropTypes.func.isRequired,
  suspendUser: PropTypes.func.isRequired,
  restoreUser: PropTypes.func,
  suspendTable: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  editAdminCredits: PropTypes.func,
  editUserCredits: PropTypes.func,
  tableData: PropTypes.string,
  users: PropTypes.shape({
    allUsers: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  }).isRequired,
  deleteUser: PropTypes.func.isRequired,
  adminTable: PropTypes.bool,
};

export default ExpandableTableUserTable;
