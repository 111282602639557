import {
  ADD_JOB_TO_LIST,
  GET_JOBS,
  JOB_SEARCH_LOADING,
  SEARCH_DATA_ON_CHANGE,
  UPDATE_JOB_VISIBILITY,
  SORT_BY,
  CLEAR_JOBS,
  UPDATE_JOB_PERCENTAGE_PROGRESS,
  LOGOUT,
} from '../../constants/actionTypes';

import initialState from './initState';

export default function jobs(state = initialState, action = {}) {
  switch (action.type) {
    case GET_JOBS: {
      const reversedArray = action.payload.reverse();

      // Progress logic for percentage on page change or refresh. We get the object of progress
      // from SessionStorage and apply to jobs array from DB
      const stringifyProgress = sessionStorage.getItem('jobsProgress');
      if (stringifyProgress && stringifyProgress !== 'null') {
        const parsedProgress = JSON.parse(stringifyProgress);
        Object.keys(parsedProgress).forEach((key) => {
          const index = reversedArray.findIndex((job) => job.api_id === key);
          if (index !== -1)
            reversedArray[index] = {
              ...reversedArray[index],
              majorStatusUpdate: parsedProgress[key].majorStatusUpdate,
              minorStatusUpdate: parsedProgress[key].minorStatusUpdate,
            };
        });
      }

      return { ...state, jobList: reversedArray, originalJobList: reversedArray };
    }
    case ADD_JOB_TO_LIST: {
      const newArray = state.jobList ? [...state.jobList] : [];
      const index = newArray.findIndex((job) => job.id === action.payload.id);

      if (index !== -1) {
        newArray[index] = action.payload;
      } else newArray.unshift(action.payload);

      return { ...state, jobList: newArray, originalJobList: newArray };
    }
    case JOB_SEARCH_LOADING:
      return {
        ...state,
        jobSearchLoading: action.payload,
      };
    case SEARCH_DATA_ON_CHANGE:
      return {
        ...state,
        searchData: action.payload,
      };
    case UPDATE_JOB_VISIBILITY: {
      const newArray = state.jobList ? [...state.jobList] : [];
      const index = newArray.findIndex((job) => job.id === action.payload.id);

      newArray[index] = action.payload;

      return { ...state, jobList: newArray, originalJobList: newArray };
    }
    case SORT_BY: {
      const oldArray = [...state.originalJobList];
      let sortedArray = [];

      switch (action.payload) {
        case 'byTime':
          sortedArray = oldArray.sort((a, b) => b.id - a.id);
          break;
        case 'byUnread':
          oldArray.forEach((q) => (q.visibility === 1 ? sortedArray.unshift(q) : sortedArray.push(q)));
          break;
        case 'byFavorite':
          oldArray.forEach((q) => (q.visibility === 3 ? sortedArray.unshift(q) : sortedArray.push(q)));
          break;
        default:
          break;
      }

      return {
        ...state,
        sort: action.payload,
        jobList: sortedArray,
      };
    }
    case CLEAR_JOBS:
      return {
        ...state,
        jobList: [],
        originalJobList: [],
      };
    case UPDATE_JOB_PERCENTAGE_PROGRESS: {
      const newArray = state.jobList ? [...state.jobList] : [];
      const index = newArray.findIndex((job) => job.api_id === action.payload.apiId);

      if (index !== -1) newArray[index] = { ...newArray[index], ...action.payload };
      return {
        ...state,
        jobList: newArray,
        originalJobList: newArray,
      };
    }
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
}
