import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Form, Input, Button, TextArea } from 'semantic-ui-react';
import { CloseCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { notification, Select } from 'antd';

import {
  getUsers,
  editUser,
  editUserPermissions,
  suspendUser,
  restoreUser,
  createUser,
  editUserCredits,
  deleteUser,
} from '../../../actions/users/users';
import {
  getTeams,
  addUserToTeam,
  makeUserTeamLeader,
  removeUserFromTeam,
  getSingleTeam,
  createTeam,
  deleteTeam,
  editTeam,
} from '../../../actions/teams/teams';
import {
  getWhiteList,
  adToWhiteList,
  deleteFromWhiteList,
  createTeamWhiteList,
  getTeamWhiteList,
  deleteTeamNumberFromWhiteList,
} from '../../../actions/whiteList/whiteList';

import ExpandableTable from '../../../components/expandableTables/UserTable/index';
import TeamManagement from './TeamManagement';
import DropDown from '../../../components/addDropDown';
import InlineError from '../../../containers/LoginPage/inlineError';
import WhiteLIstTable from '../../../components/whiteListTable';
import TeamWhiteLIstTable from '../../../components/teamWhiteListTable';

import './adminContent.sass';

class AdminContent extends Component {
  state = {
    loading: true,
    newContact: {
      username: '',
      password: '',
      repeatPassword: '',
      createSuccess: false,
      error: {},
    },
    whiteList: {
      textArea: '',
      createSuccess: false,
    },
    deleteLoading: [],
    addWhiteListLoading: false,
    heightUserToggle: 0,
    opacityUserToggle: 0,
    teamWhiteList: {
      teamId: undefined,
      msisdn: '',
      imsi: '',
    },
  };

  componentDidMount() {
    this.props.getUsers().then(() => this.setState({ loading: false }));
    this.props.getTeams();
    this.props.getWhiteList();
    this.props.getTeamWhiteList();
  }

  editUser = (userId, data) => this.props.editUser(userId, data);
  editUserPermissions = (userId, data) => this.props.editUserPermissions(userId, data);
  suspendUser = (userId) => this.props.suspendUser(userId);
  restoreUser = (userId) => this.props.restoreUser(userId);
  addUserToTeam = (data) => this.props.addUserToTeam(data);
  removeUserFromTeam = (userId, teamId) => this.props.removeUserFromTeam(userId, teamId);
  makeTeamLead = (userId) => this.props.makeUserTeamLeader(userId);

  submitHasErrors = (password, repeatPassword) => {
    if (password.length <= 5 || !/[a-z]/.test(password) || !/[A-Z]/.test(password) || !/[0-9]/.test(password)) {
      return { result: true, message: 'Incorrect password' };
    } else if (password !== repeatPassword) return { result: true, message: "Passwords don't match" };

    return { result: false, message: '' };
  };

  submitCreateUser = () => {
    const { error, username, password, repeatPassword } = this.state.newContact;
    const checkResult = this.submitHasErrors(password, repeatPassword);
    if (checkResult.result) {
      this.setState({
        newContact: { ...this.state.newContact, error: { message: checkResult.message } },
      });
    }
    if (!error.message && username && !checkResult.result && password === repeatPassword) {
      this.props
        .createUser({
          username: this.state.newContact.username,
          password: this.state.newContact.password,
          password_confirmation: this.state.newContact.repeatPassword,
          timezone: 'Europe/London',
        })
        .then(() =>
          this.setState({
            newContact: {
              username: '',
              password: '',
              repeatPassword: '',
              createSuccess: true,
              error: {},
            },
          }),
        );
    }
  };

  onChangeCreateUser = (e) =>
    this.setState({
      ...this.state,
      newContact: { ...this.state.newContact, [e.target.name]: e.target.value, error: {} },
    });

  getSplitAndAdd = () => {
    this.setState({ addWhiteListLoading: true });
    const { textArea } = this.state.whiteList;
    const terminalArray = [];
    textArea.split('\n').map((res) => {
      if (res !== '') terminalArray.push(res.trim());
      return null;
    });
    const object = {
      records: [],
    };
    terminalArray.map((terminals) => {
      const terminal = terminals.split(',');
      const whiteListTerminal = {};
      whiteListTerminal.note = '';
      whiteListTerminal.phone = terminal[0] === '' ? null : terminal[0];
      whiteListTerminal.imsi = terminal[1] === '' ? null : terminal[1];
      object.records.push(whiteListTerminal);
      return null;
    });
    this.props.adToWhiteList(object).then((res) => {
      if (Object.values(res).length !== 0) {
        this.setState({ addWhiteListLoading: false, whiteList: { textArea: '', createSuccess: true } });
      } else this.setState({ addWhiteListLoading: false });
    });
  };

  deleteFromWhiteList = (id) => {
    const loadingArray = this.state.deleteLoading;
    loadingArray.push(id);
    this.setState({ deleteLoading: loadingArray });
    this.props.deleteFromWhiteList(id).then(() => {
      const index = loadingArray.indexOf(id);
      loadingArray.splice(index, 1);
      this.setState({ deleteLoading: loadingArray });
    });
  };

  toggleCreateUser = () => {
    const { heightUserToggle } = this.state;
    if (heightUserToggle === 0) this.setState({ heightUserToggle: 210, opacityUserToggle: 1 });
    else if (heightUserToggle !== 0) {
      this.setState({ heightUserToggle: 0, opacityUserToggle: 0 });
    }
  };

  closeCreateUser = () => {
    this.toggleCreateUser();
  };

  onChangeTeamSelect = (teamId) => this.setState({ teamWhiteList: { ...this.state.teamWhiteList, teamId } });
  onChangeMsisdnInput = (e) =>
    this.setState({ teamWhiteList: { ...this.state.teamWhiteList, msisdn: e.target.value } });
  onChangeImsiInput = (e) => this.setState({ teamWhiteList: { ...this.state.teamWhiteList, imsi: e.target.value } });

  createTeamWhiteList = () => {
    const {
      teamWhiteList: { imsi, msisdn, teamId },
    } = this.state;
    if (teamId && (imsi || msisdn)) {
      this.props
        .createTeamWhiteList({ range: imsi || msisdn, range_type: msisdn ? 1 : 2 }, teamId)
        .then(() =>
          this.setState({
            teamWhiteList: { imsi: '', msisdn: '', teamId: undefined },
          }),
        )
        .catch((err) => {
          if (err.response.data.message) {
            notification.error({
              message: 'Error',
              description: err.response.data.message,
            });
          }
        });
    }
  };

  render() {
    const { error } = this.state.newContact;
    let users = {};
    let suspended = {};
    let teams = { allTeams: [] };
    if (this.props.teams.allTeams) teams = this.props.teams;
    if (this.props.users.allUsers) {
      users = { allUsers: {} };
      suspended = { allUsers: {} };

      this.props.users.allUsers.map((user) => {
        if (user.role_id === 3 && !user.deleted_at) {
          users.allUsers = { ...users.allUsers, [user.id]: { ...user } };
        } else if (user.deleted_at) {
          suspended.allUsers = { ...suspended.allUsers, [user.id]: { ...user } };
        }
        return null;
      });
    }

    const {
      teamWhiteList: { imsi, msisdn, teamId },
    } = this.state;

    return (
      <div className="admin-content">
        <div className="admin-content__table-name">User List</div>

        <div
          className="add-drop-down-component super-admin-content__drop-down --create-admin"
          style={{ marginBottom: 10 }}
        >
          <div className="add-drop-down-component__buttons-area --create-admin">
            <div className="add-drop-down-component__buttons-area_button" onClick={this.toggleCreateUser}>
              <PlusCircleOutlined className="button-icon" />
              <span className="button-text">Create User</span>
            </div>
            <div
              className="add-drop-down-component__buttons-area_button  --close-button"
              onClick={this.closeCreateUser}
            >
              <CloseCircleOutlined
                className="button-icon"
                style={{
                  opacity: this.state.opacityUserToggle,
                  pointerEvents: this.state.opacityUserToggle === 0 ? 'none' : 'initial',
                }}
              />
              <span
                style={{
                  opacity: this.state.opacityUserToggle,
                  pointerEvents: this.state.opacityUserToggle === 0 ? 'none' : 'initial',
                }}
                className="button-text"
              >
                Close
              </span>
            </div>
          </div>
          {this.state.opacityUserToggle !== 0 && (
            <div
              className="add-drop-down-component__expand-area"
              style={{ height: this.state.heightUserToggle, opacity: this.state.opacityUserToggle }}
            >
              <Form onChange={this.onChangeCreateUser}>
                <Form.Group widths="equal">
                  <Form.Field>
                    <Input
                      autoComplete="new-password"
                      value={this.state.newContact.username}
                      size="small"
                      name="username"
                      fluid
                      placeholder="Username"
                    />
                  </Form.Field>
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Field error={!!error.message}>
                    <Input
                      autoComplete="new-password"
                      value={this.state.newContact.password}
                      type="password"
                      size="small"
                      name="password"
                      fluid
                      placeholder="Password"
                    />
                    {error.message && (
                      <InlineError style={{ position: 'absolute', fontSize: '12px' }} text={error.message} />
                    )}
                  </Form.Field>
                  <Form.Field error={!!error.message}>
                    <Input
                      autoComplete="new-password"
                      value={this.state.newContact.repeatPassword}
                      type="password"
                      size="small"
                      name="repeatPassword"
                      fluid
                      placeholder="Repeat Password"
                    />
                    {error.message && (
                      <InlineError style={{ position: 'absolute', fontSize: '12px' }} text={error.message} />
                    )}
                  </Form.Field>
                </Form.Group>
                <div style={{ fontSize: 14 }}>
                  {
                    'Password must contain at least one upper & lower case character, one number and be at least 6 characters long.'
                  }
                </div>
                <Button style={{ marginTop: 10 }} onClick={this.submitCreateUser} size="small">
                  Create
                </Button>
              </Form>
            </div>
          )}
        </div>

        <ExpandableTable
          loading={this.state.loading}
          userRole={!this.props.user.role ? 0 : this.props.user.role.id}
          editUser={this.editUser}
          editUserCredits={this.props.editUserCredits}
          editUserPermissions={this.editUserPermissions}
          suspendUser={this.suspendUser}
          restoreUser={this.restoreUser}
          addUserToTeam={this.addUserToTeam}
          removeUserFromTeam={this.removeUserFromTeam}
          makeTeamLead={this.makeTeamLead}
          suspendTable={false}
          data={this.props.users}
          teams={this.props.teams}
          users={this.props.users}
          tableData="user"
          deleteUser={this.props.deleteUser}
        />

        <div className="admin-content__table-name">Suspended Users</div>
        <ExpandableTable
          loading={this.state.loading}
          userRole={!this.props.user.role ? 0 : this.props.user.role.id}
          editUser={this.editUser}
          editUserPermissions={this.editUserPermissions}
          suspendUser={this.suspendUser}
          restoreUser={this.restoreUser}
          suspendTable={!!1}
          data={this.props.users}
          users={this.props.users}
          tableData="suspended"
          deleteUser={this.props.deleteUser}
        />

        <div className="admin-content__table-name">Team Management</div>
        <TeamManagement
          teams={teams}
          users={users}
          singleTeam={this.props.teams.singleTeam}
          removeUserFromTeam={this.removeUserFromTeam}
          getSingleTeam={this.props.getSingleTeam}
          // makeTeamLead={this.makeTeamLead}
          createTeam={this.props.createTeam}
          addUserToTeam={this.addUserToTeam}
          deleteTeam={this.props.deleteTeam}
          user={users.allUsers}
          editTeam={this.props.editTeam}
        />

        <div className="admin-content__table-name">Teams Enabled List (MSISDN/IMSI)</div>
        <div style={{ display: 'flex', marginTop: 10 }}>
          <Select
            placeholder="Select Team"
            style={{ width: 200, marginRight: 20 }}
            onChange={this.onChangeTeamSelect}
            showSearch
            value={teamId}
            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          >
            {teams.allTeams.map((team, index) => (
              <Select.Option key={`team-whitelist-options-${team.id}`} value={team.id}>
                {team.name}
              </Select.Option>
            ))}
          </Select>
          <Input
            style={{ width: 200, marginRight: 20 }}
            size="mini"
            name="MSISDN"
            fluid
            placeholder="MSISDN"
            disabled={!!imsi}
            value={msisdn}
            onChange={this.onChangeMsisdnInput}
          />
          <Input
            style={{ width: 200, marginRight: 20 }}
            size="mini"
            name="IMSI"
            fluid
            placeholder="IMSI"
            disabled={!!msisdn}
            value={imsi}
            onChange={this.onChangeImsiInput}
          />
          <Button size="mini" onClick={this.createTeamWhiteList} disabled={!teamId && (!imsi || !msisdn)}>
            Create
          </Button>
        </div>
        <TeamWhiteLIstTable
          whiteList={this.props.teamWhiteList}
          deleteFromWhiteList={this.props.deleteTeamNumberFromWhiteList}
        />

        <div className="admin-content__table-name" style={{ marginTop: 40 }}>
          Blocked List
        </div>
        <DropDown
          className="admin-content__drop-down-white-list"
          createSuccess={this.state.whiteList.createSuccess}
          buttonName="Add phone/IMSI to Blocked list"
          height={275}
        >
          <div className="admin-content__white-list">
            <div className="admin-content__white-list_explanation">
              <div>First number is Phone and second is IMSI. The terminals are separated by new line.</div>
              <div>Ex: 007483829,888271888200000</div>
              <div>If you want to enter only phone or Imsi. Ex: 007483829, or ,342423423433</div>
            </div>
            <TextArea
              autoheight={false}
              style={{ height: 120, maxHeight: 120, width: '100%' }}
              onChange={(e) => this.setState({ whiteList: { ...this.state.whiteList, textArea: e.target.value } })}
              value={this.state.whiteList.textArea}
              className="admin-content__white-list_text-area"
              rows={5}
            />
            <Button loading={this.state.addWhiteListLoading} onClick={this.getSplitAndAdd} size="mini">
              Add
            </Button>
          </div>
        </DropDown>
        <WhiteLIstTable
          whiteList={this.props.whiteList}
          deleteFromWhiteList={(id) => this.deleteFromWhiteList(id)}
          loading={this.state.deleteLoading}
        />
      </div>
    );
  }
}

AdminContent.propTypes = {
  users: PropTypes.shape({
    allUsers: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  }).isRequired,
  user: PropTypes.shape({
    role: PropTypes.shape({
      id: PropTypes.number,
    }),
  }).isRequired,
  teams: PropTypes.shape({
    allTeams: PropTypes.array,
    singleTeam: PropTypes.object,
  }).isRequired,
  getUsers: PropTypes.func.isRequired,
  editUser: PropTypes.func.isRequired,
  editUserPermissions: PropTypes.func.isRequired,
  suspendUser: PropTypes.func.isRequired,
  restoreUser: PropTypes.func.isRequired,
  getTeams: PropTypes.func.isRequired,
  addUserToTeam: PropTypes.func.isRequired,
  makeUserTeamLeader: PropTypes.func.isRequired,
  removeUserFromTeam: PropTypes.func.isRequired,
  getSingleTeam: PropTypes.func.isRequired,
  createUser: PropTypes.func.isRequired,
  createTeam: PropTypes.func.isRequired,
  getWhiteList: PropTypes.func.isRequired,
  whiteList: PropTypes.shape({}).isRequired,
  teamWhiteList: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  adToWhiteList: PropTypes.func.isRequired,
  deleteFromWhiteList: PropTypes.func.isRequired,
  deleteTeam: PropTypes.func.isRequired,
  editUserCredits: PropTypes.func.isRequired,
  editTeam: PropTypes.func.isRequired,
  deleteUser: PropTypes.func.isRequired,
  createTeamWhiteList: PropTypes.func.isRequired,
  getTeamWhiteList: PropTypes.func.isRequired,
  deleteTeamNumberFromWhiteList: PropTypes.func.isRequired,
};

const mapStateToProps = (store) => ({
  users: store.users,
  user: store.user,
  teams: store.teams,
  whiteList: store.whiteList,
  teamWhiteList: store.teamWhiteList,
});

export default connect(mapStateToProps, {
  getUsers,
  editUser,
  editUserPermissions,
  suspendUser,
  restoreUser,
  getTeams,
  addUserToTeam,
  makeUserTeamLeader,
  removeUserFromTeam,
  getSingleTeam,
  createUser,
  createTeam,
  getWhiteList,
  adToWhiteList,
  deleteFromWhiteList,
  deleteTeam,
  editUserCredits,
  editTeam,
  deleteUser,
  createTeamWhiteList,
  getTeamWhiteList,
  deleteTeamNumberFromWhiteList,
})(AdminContent);
