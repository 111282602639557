import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { CloseOutlined } from '@ant-design/icons';

import { Card, List, Avatar } from 'antd';

import avatarImage from '../../assets/images/avatar_default.jpg';
import TruncateComponent from '../../components/truncateComponent';
import TimeStamp from '../../utils/timeStamp';

import './contactComments.sass';

class ContactComments extends Component {
  state = {
    comment: '',
  };

  insertComments = (comment, index) => {
    const key = `comment-${index}`;
    return (
      <List.Item>
        <List.Item.Meta
          key={key}
          avatar={<Avatar src={avatarImage} />}
          title={
            <div>
              {comment.user.username}
              <span className="contact-view-page__left-part_comments__title">
                <TimeStamp date={comment.updated_at} />
              </span>
            </div>
          }
          className="contact-view-page__left-part_comments__text"
          description={
            <div>
              <div
                role="button"
                tabIndex={0}
                className="delete-comment"
                onClick={() => this.props.deleteComment(comment.id)}
              >
                <CloseOutlined className="delete-comment__button" />
              </div>
              <TruncateComponent lines={2}>{comment.text}</TruncateComponent>
            </div>
          }
        />
      </List.Item>
    );
  };

  submitComment = (e) => {
    e.preventDefault();
    this.props.createComment(this.state.comment).then(() => this.setState({ comment: '' }));
  };

  render() {
    return (
      <Card title="Contact comments" style={{ minWidth: 290 }} className="contact-view-page__left-part_comments">
        <div className="contact-view-page__left-part_comments__input">
          <form onSubmit={this.submitComment}>
            <input
              name="comment"
              value={this.state.comment}
              onChange={(e) => this.setState({ comment: e.target.value })}
              className="contact-view-page__left-part_comments__input_text"
              placeholder="Write comment..."
            />
          </form>
        </div>
        <List
          itemLayout="horizontal"
          dataSource={Object.values(this.props.comments).reverse()}
          renderItem={(comment, index) => this.insertComments(comment, index)}
        />
      </Card>
    );
  }
}

ContactComments.defaultProps = {
  comments: {},
};

ContactComments.propTypes = {
  comments: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.array]),
  createComment: PropTypes.func.isRequired,
  deleteComment: PropTypes.func.isRequired,
};

export default ContactComments;
