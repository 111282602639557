import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { Button, Form, Message } from 'semantic-ui-react';

import { getApplicationVersion } from '../../actions/app/application';

import { login } from '../../actions/user/auth';
import { getAgents } from '../../actions/agents/smsNotifications';
import { initSocket } from '../../utils/socketConfig';

import InlineError from './inlineError';

import './loginPage.sass';

class LoginPage extends Component {
  state = {
    data: {
      name: '',
      pass: '',
    },
    loading: false,
    errors: {},
    appV: process.env.REACT_APP_APP_VERSION,
  };

  onChange = (e) => {
    this.setState({ data: { ...this.state.data, [e.target.name]: e.target.value } });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const errors = this.validate(this.state.data);
    const { data } = this.state;
    this.setState({ errors });

    if (Object.keys(errors).length === 0) {
      this.setState({ loading: true }, () => {
        this.props
          .login(data)
          .then((res) => {
            this.props.getAgents();
            initSocket(res.payload.id);
            this.props.history.push('/');
          })
          .catch((err) => {
            if (err.response) {
              this.setState({
                loading: false,
                errors: {
                  global: err.response.data,
                },
              });
            } else {
              this.setState({
                loading: false,
                errors: {
                  global: { message: 'Something went wrong. Please ask Admin!' },
                },
              });
            }
          });
      });
    }
  };

  validate = (data) => {
    const errors = {};
    if (!data.name) errors.name = "User name field can't be blank";
    if (!data.pass) errors.pass = "Password field can't be blank";

    return errors;
  };

  getErrorMessage = () => {
    if (this.state.errors.global) {
      return (
        <div>
          <div>{this.state.errors.global.notice ? '' : this.state.errors.global.message}</div>
          {this.state.errors.global.notice && <div>{this.state.errors.global.notice}</div>}
        </div>
      );
    }
    return '';
  };

  componentDidMount() {
    getApplicationVersion().then((res) => {
      if (res.data) this.setState((state) => ({ ...state, appV: res.data }));
    });
  }

  render() {
    const { data, errors, loading, appV } = this.state;
    return (
      <div className="login-page">
        <h1 className="login-page__header">Login Page</h1>
        <Form className="login-form" error={!!this.state.errors.global} onSubmit={this.handleSubmit} loading={loading}>
          <Message error header="Login Fail" content={this.getErrorMessage()} />
          <Form.Field error={!!errors.name}>
            <label htmlFor="name">User Name</label>
            <input onChange={this.onChange} type="text" placeholder="User name" name="name" value={data.name} />
            {errors.name && <InlineError text={errors.name} />}
          </Form.Field>
          <Form.Field error={!!errors.pass}>
            <label htmlFor="last-name">Password</label>
            <input onChange={this.onChange} type="password" placeholder="Password" name="pass" value={data.pass} />
            {errors.pass && <InlineError text={errors.pass} />}
          </Form.Field>
          <Button type="submit">Login</Button>
        </Form>
        <div style={{ fontSize: 15, opacity: 0.8 }}>V {appV}</div>
      </div>
    );
  }
}

LoginPage.defaultProps = {
  history: {
    push: () => null,
  },
  login: () => null,
};

LoginPage.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
  login: PropTypes.func,
  getAgents: PropTypes.func,
};

const mapStateToProps = (store) => ({
  user: store.user,
});

export default connect(mapStateToProps, { login, getAgents })(LoginPage);
