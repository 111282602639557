import React, { memo, useRef, useState, useEffect } from 'react';
import L from 'leaflet';

import { Circle } from 'react-leaflet';

const GeoPointsMap = ({ map, getLatLng, range, savedPoints, showMarker }) => {
  const [markerPosition, setMarkerPosition] = useState(null);
  const markerRef = useRef();

  useEffect(() => {
    map.on('click', (e) => {
      getLatLng(e.latlng.lat, e.latlng.lng);
      setMarkerPosition([e.latlng.lat, e.latlng.lng]);
      if (markerRef.current !== undefined) map.removeLayer(markerRef.current);
      markerRef.current = L.marker([e.latlng.lat, e.latlng.lng]).addTo(map);
      map.panTo(new L.LatLng(e.latlng.lat, e.latlng.lng));
    });
  }, [getLatLng, map]);

  useEffect(() => {
    if (!showMarker && markerRef.current !== undefined) map.removeLayer(markerRef.current);
  }, [showMarker, map]);

  if (!markerPosition || !savedPoints.length) return null;

  return (
    <>
      {showMarker && range ? (
        <Circle center={markerPosition} pathOptions={{ color: 'green', weight: 1 }} radius={range} />
      ) : (
        ''
      )}
      {savedPoints.length
        ? savedPoints.map((point) => (
            <Circle
              key={`${point.lat}-${point.long}`}
              center={[point.lat, point.long]}
              pathOptions={{ color: 'green', weight: 1 }}
              radius={point.distance}
            />
          ))
        : ''}
    </>
  );
};

export default memo(GeoPointsMap);
