import React from 'react';

import { SearchOutlined } from '@ant-design/icons';

import { Button, Input } from 'antd';
import Highlighter from 'react-highlight-words';

export default (dataIndex, obj) => ({
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
    <div style={{ padding: 8 }}>
      <Input
        ref={(node) => {
          obj.searchInput = node;
        }}
        placeholder={`Search ${dataIndex}`}
        value={selectedKeys[0]}
        onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex, obj)}
        style={{ width: 188, marginBottom: 8, display: 'block' }}
      />
      <Button
        type="primary"
        onClick={() => handleSearch(selectedKeys, confirm, dataIndex, obj)}
        icon={<SearchOutlined />}
        size="small"
        style={{ width: 90, marginRight: 8 }}
      >
        Search
      </Button>
      <Button onClick={() => handleReset(clearFilters, obj)} size="small" style={{ width: 90 }}>
        Reset
      </Button>
    </div>
  ),
  filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
  onFilter: (value, record) => {
    if (dataIndex === 'phone-imsi' && record.primary_terminal) {
      const phone = record.primary_terminal.phone;
      const imsi = record.primary_terminal.imsi;
      return phone
        ? phone?.toString().toLowerCase().includes(value.toLowerCase())
        : imsi?.toString().toLowerCase().includes(value.toLowerCase());
    } else if (record?.[dataIndex]) {
      return record?.[dataIndex]?.toString().toLowerCase().includes(value.toLowerCase());
    }
  },
  onFilterDropdownVisibleChange: (visible) => {
    if (visible) {
      setTimeout(() => obj.searchInput.select());
    }
  },
  render: (text, data) => {
    return obj.state.searchedColumn === dataIndex ? (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[obj.state.searchText]}
        autoEscape
        textToHighlight={
          dataIndex === 'phone-imsi'
            ? data?.primary_terminal
              ? (data.primary_terminal?.phone || data.primary_terminal?.imsi)?.toString()
              : ''
            : text && text?.toString()
        }
      />
    ) : dataIndex === 'phone-imsi' ? (
      data.primary_terminal ? (
        data.primary_terminal.phone || data.primary_terminal.imsi
      ) : (
        ''
      )
    ) : (
      text
    );
  },
});

const handleSearch = (selectedKeys, confirm, dataIndex, obj) => {
  confirm();
  obj.setState({
    searchText: selectedKeys[0],
    searchedColumn: dataIndex,
  });
};

const handleReset = (clearFilters, obj) => {
  clearFilters();
  obj.setState({ searchText: '' });
};
