import React, { memo, useRef, useState, useEffect, useCallback } from 'react';
import L from 'leaflet';

import { Circle } from 'react-leaflet';

const GeoPointMap = ({ markers, map, getLatLng, range }) => {
  const [markerPosition, setMarkerPosition] = useState(null);
  const markerRef = useRef();

  const setMarkerOnMap = useCallback(
    (lat, lng) => {
      setMarkerPosition([lat, lng]);
      if (markerRef.current !== undefined) map.removeLayer(markerRef.current);
      markerRef.current = L.marker([lat, lng]).addTo(map);
      map.panTo(new L.LatLng(lat, lng));
    },
    [map],
  );

  useEffect(() => {
    if (markers?.length) {
      const [lat, lng] = markers;
      map.panTo(new L.LatLng(lat, lng));
      setMarkerOnMap(lat, lng);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, setMarkerOnMap]);

  useEffect(() => {
    map.on('click', (e) => {
      getLatLng(e.latlng.lat, e.latlng.lng);
      setMarkerOnMap(e.latlng.lat, e.latlng.lng);
    });
  }, [getLatLng, map, setMarkerOnMap]);

  if (!markerPosition) return null;

  return <Circle center={markerPosition} pathOptions={{ color: 'green', weight: 1 }} radius={range} />;
};

export default memo(GeoPointMap);
