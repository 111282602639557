import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Button as ButtonAntd, Table } from 'antd';

import { getReport, getArchivedReports, archiveCellReport } from '../../actions/report/report';

import TimeStamp from '../../utils/timeStamp';

import { searchTypes } from '../../components/searchComponent/searchComponent';

class ReportsTable extends Component {
  state = {
    loading: true,
    requestLoadings: [],
  };

  componentDidMount() {
    this.props
      .getArchivedReports()
      .then(() => this.setState({ loading: false }))
      .catch(() => this.setState({ loading: false }));
  }

  unarchiveLocation = (id) => {
    if (this.state.requestLoadings.indexOf(id) === -1) {
      this.setState({ requestLoadings: [...this.state.requestLoadings, id] });
      this.props
        .archiveCellReport(id)
        .then(() => {
          const newArray = [...this.state.requestLoadings];
          newArray.splice(this.state.requestLoadings.indexOf(id), 1);
          this.setState({ requestLoadings: newArray });
        })
        .then(() => {
          this.props.triggerAction(true);
          this.props.getReport({ page: 1, 'csv-export': 0 }).then(() => this.props.triggerAction(false));
        });
    }
  };

  render() {
    const columns = [
      { title: 'ID', dataIndex: 'id', key: 'id', fixed: 'left', width: 100 },
      { title: 'Contact name', dataIndex: 'contact', key: 'contact', fixed: 'left', width: 200 },
      { title: 'MSISDN', dataIndex: 'phone', key: 'phone', width: 180 },
      { title: 'IMSI', dataIndex: 'imsi', key: 'imsi', width: 180 },
      {
        title: `Result Time ( ${this.props.user.timezone_offset} )`,
        dataIndex: 'location_time',
        key: 'location_time',
        render: (text, record) => <TimeStamp date={record.location_time} />,
        width: 210,
      },
      {
        title: 'MCC',
        dataIndex: 'mcc',
        key: 'mcc',
        width: 100,
      },
      {
        title: 'MNC',
        dataIndex: 'mnc',
        key: 'mnc',
        width: 100,
      },
      {
        title: 'LAC/TAC',
        dataIndex: 'lac',
        key: 'lac',
        width: 100,
      },
      {
        title: 'CELLID',
        dataIndex: 'cell_id',
        key: 'cell_id',
        width: 120,
        render: (text) => <div>{!text ? 'null' : text}</div>,
      },
      {
        title: 'IMEI',
        dataIndex: 'imei',
        key: 'imei',
        width: 200,
      },
      {
        title: 'State',
        dataIndex: 'state',
        key: 'state',
        width: 180,
      },
      {
        title: 'Country ISO',
        dataIndex: 'country_iso',
        key: 'country_iso',
        width: 180,
      },
      {
        title: 'Age',
        dataIndex: 'age',
        key: 'age',
        width: 100,
      },
      {
        title: 'Range',
        dataIndex: 'range',
        key: 'range',
        width: 120,
      },
      {
        title: 'Angle',
        dataIndex: 'angle',
        key: 'angle',
        width: 80,
        render: (angle, data) => (data?.width !== 360 ? angle : null),
      },
      {
        title: 'Width',
        dataIndex: 'width',
        key: 'width',
        width: 80,
        render: (width) => (width !== 360 ? width : null),
      },
      {
        title: 'Error code',
        dataIndex: 'code',
        key: 'code',
        width: 150,
      },
      {
        title: 'Query type',
        dataIndex: 'query_type',
        key: 'query_type',
        width: 140,
      },
      {
        title: 'LAT',
        dataIndex: 'lat',
        key: 'lat',
        width: 140,
      },
      {
        title: 'LON',
        dataIndex: 'lon',
        key: 'lon',
        width: 140,
      },
      { title: 'Username', dataIndex: 'username', key: 'username', width: 200 },
      {
        title: 'Team',
        dataIndex: 'team_name',
        key: 'team_name',
        width: 200,
        render: (text, record) => {
          return record.team_name === null ? 'No team' : text;
        },
      },
      {
        title: 'Search type',
        dataIndex: 'api_request_type',
        key: 'api_request_type',
        width: 200,
        render: (text) => {
          return searchTypes[text];
        },
      },
      {
        title: 'Action',
        dataIndex: 'action',
        key: 'action',
        width: 120,
        fixed: 'right',
        render: (text, record) => (
          <ButtonAntd
            loading={this.state.requestLoadings.indexOf(record.id) !== -1}
            style={{ background: '#ffd04d', borderColor: '#ffce45', color: 'black' }}
            type="danger"
            size="small"
            onClick={() => this.unarchiveLocation(record.id)}
          >
            Unarchive
          </ButtonAntd>
        ),
      },
    ];

    return (
      <div style={{ position: 'relative', marginTop: 40 }}>
        <h3 style={{ position: 'absolute', top: 20, left: 0 }}>Archived Locations</h3>
        <Table
          loading={this.state.loading || this.props.triggerLoading}
          rowKey={'id'}
          columns={columns}
          dataSource={Object.values(this.props.archivedCells)}
          scroll={{ x: 3000 }}
          pagination={{ position: ['topRight'], showSizeChanger: true }}
          size="small"
        />
      </div>
    );
  }
}

ReportsTable.defaultProps = {
  archivedCells: {},
  user: {},
  triggerLoading: false,
};

ReportsTable.propTypes = {
  triggerAction: PropTypes.func.isRequired,
  triggerLoading: PropTypes.func.bool,
  user: PropTypes.shape({
    role_id: PropTypes.number,
    timezone: PropTypes.string,
    timezone_offset: PropTypes.string,
  }),
  archivedCells: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  getArchivedReports: PropTypes.func.isRequired,
  archiveCellReport: PropTypes.func.isRequired,
};

const mapStateToProps = (store) => ({
  archivedCells: store.report.archivedCells,
  user: store.user,
});

export default connect(mapStateToProps, { getReport, getArchivedReports, archiveCellReport })(ReportsTable);
