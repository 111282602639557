import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { notification } from 'antd';
import { Form, Header, Select, Button } from 'semantic-ui-react';
import { editUser } from '../../../actions/user/auth';

import './changeNotificationMethod.sass';

const options = [
  { key: 'off', text: 'Off', value: 'off' },
  { key: 'user-mail', text: 'User mail', value: 'user-mail' },
  { key: 'user-sms', text: 'User sms', value: 'user-sms' },
  { key: 'user-both', text: 'User both', value: 'user-both' },
  { key: 'team-mail', text: 'Team mail', value: 'team-mail' },
  { key: 'team-sms', text: 'Team sms', value: 'team-sms' },
  { key: 'team-both', text: 'Team both', value: 'team-both' },
];

class ChangePhoneEmail extends Component {
  state = {
    method: 'off',
    loading: false,
  };

  componentDidMount() {
    this.updateState();
  }

  updateState = () =>
    this.setState({
      method: this.props.userInfo.notification_method !== null ? this.props.userInfo.notification_method : '',
    });

  editUser = (e) => {
    e.preventDefault();
    this.setState({ loading: true }, () => {
      this.props
        .editUser(this.props.userInfo.id, { notification_method: this.state.method })
        .then(() => {
          this.setState({ loading: false });
          notification.success({
            message: 'Success',
            description: 'The notification method was changed successfully',
          });
        })
        .catch(() => this.setState({ loading: false }));
    });
  };

  render() {
    return (
      <div className="change-notification-method">
        <Form size="mini" onSubmit={this.editUser}>
          <Header as="h3">Change Notification Method</Header>
          <div className="change-notification-method__wrapper">
            <Select
              className="change-notification-method__wrapper_select"
              value={this.state.method}
              name="method"
              fluid
              size="mini"
              options={options}
              placeholder="Notification method"
              onChange={(e, data) => this.setState({ method: data.value })}
            />
            <Button
              className="change-notification-method__wrapper_button"
              loading={this.state.loading}
              size="mini"
              type="submit"
              onClick={this.changePasswordOnSubmit}
            >
              Submit
            </Button>
          </div>
        </Form>
      </div>
    );
  }
}

ChangePhoneEmail.defaultProps = {
  userInfo: {
    notification_method: '',
  },
  editUser: () => null,
};

ChangePhoneEmail.propTypes = {
  editUser: PropTypes.func,
  userInfo: PropTypes.shape({
    id: PropTypes.number,
    notification_method: PropTypes.string,
  }),
};

export default connect(null, { editUser })(ChangePhoneEmail);
