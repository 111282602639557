import React, { PureComponent } from 'react';

class JobResultForwardingSearch extends PureComponent {
  render() {
    if (!this.props?.data?.additional_info) return <div>Data is missing</div>;
    const {
      data: { additional_info },
    } = this.props;

    if (additional_info && additional_info.data) {
      if (additional_info.data.forwarded)
        return (
          <div className="job-content__default-result">
            <div className="dashboard-page__search-result_info --full-width">
              <div className="dashboard-page__search-result_info__tables">
                <div>
                  <div className="table-item">
                    <div className="table-item__name">Forwarded</div>
                    <div className="table-item__value">{additional_info.data.forwarded ? 'True' : 'False'}</div>
                  </div>
                  <div className="table-item">
                    <div className="table-item__name">ID</div>
                    <div className="table-item__value">{additional_info.data.id}</div>
                  </div>
                  <div className="table-item">
                    <div className="table-item__name">Details</div>
                    <div className="table-item__value">{additional_info.data.forwardingDetails}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      else if (additional_info.data.forwarded === false)
        return (
          <div className="job-content__default-result">
            <div className="dashboard-page__search-result_info --full-width">
              <div className="dashboard-page__search-result_info__tables">
                <div>
                  <div className="table-item">
                    <div className="table-item__name">Forwarded</div>
                    <div className="table-item__value">{additional_info.data.forwarded ? 'True' : 'False'}</div>
                  </div>
                  <div className="table-item">
                    <div className="table-item__name">ID</div>
                    <div className="table-item__value">{additional_info.data.id}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      else if (additional_info.data.forwarded === null)
        return (
          <div className="job-content__default-result">
            <div className="dashboard-page__search-result_info --full-width">
              <div className="dashboard-page__search-result_info__tables">
                <div>
                  <div className="table-item">
                    <div className="table-item__name">Forwarded</div>
                    <div className="table-item__value">{additional_info.data.forwarded}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
    }

    return <div>Result: NULL</div>;
  }
}

export default JobResultForwardingSearch;
