import moment from 'moment-timezone';
import { FIRE_ALERT, GET_REPORT, GET_ARCHIVED_REPORT, ARCHIVE_REPORT_CELL } from '../../constants/actionTypes';
import api from '../../utils/appApi';

export const getReport = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    api.report
      .getReport(data)
      .then((res) => {
        dispatch({
          type: GET_REPORT,
          payload: res.data,
        });
        return resolve(res.data);
      })
      .catch((err) => {
        const lat = !err.response.data.errors.lat ? '' : err.response.data.errors.lat[0];
        const long = !err.response.data.errors.long ? '' : err.response.data.errors.long[0];
        const phone = !err.response.data.errors.phone ? '' : err.response.data.errors.phone[0];
        const imsi = !err.response.data.errors.imsi ? '' : err.response.data.errors.imsi[0];
        const mcc = !err.response.data.errors.mcc ? '' : err.response.data.errors.mcc[0];
        const mnc = !err.response.data.errors.mnc ? '' : err.response.data.errors.mnc[0];
        const lac = !err.response.data.errors.lac ? '' : err.response.data.errors.lac[0];
        const cellId = !err.response.data.errors.cell_id ? '' : err.response.data.errors.cell_id[0];
        dispatch({
          type: FIRE_ALERT,
          payload: {
            specificMessage: `${lat} ${long} ${phone} ${imsi} ${mcc} ${mnc} ${lac} ${cellId}`,
            message: err.response.data.message,
          },
        });
        reject(err);
      });
  });

export const downloadReport = (data) => () =>
  new Promise((resolve, reject) => {
    api.report
      .downloadReport(data)
      .then((response) => {
        const date = moment(new Date()).format('YYYY-MM-DD');
        const blob = new Blob([response.data], { type: 'text/csv' });
        const a = document.createElement('a');
        a.style = 'display: none';
        document.body.appendChild(a);
        const url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = `Report-${date}.csv`;
        a.click();
        window.URL.revokeObjectURL(url);
        return resolve(response);
      })
      .catch((err) => reject(err));
  });

export const downloadPdf = (data) => () =>
  new Promise((resolve, reject) =>
    api.report
      .downloadPdf(data)
      .then((res) => {
        const file = new Blob([res.data], { type: 'application/pdf' });
        const a = document.createElement('a');
        const url = window.URL.createObjectURL(file);
        const date = new Date();
        a.href = url;
        a.download = `Report ${date}`;
        a.click();
        return resolve(res);
      })
      .catch((err) => reject(err)),
  );

export const getArchivedReports = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    api.report
      .getArchivedReports(data)
      .then((res) => {
        dispatch({
          type: GET_ARCHIVED_REPORT,
          payload: res.data.data,
        });
        return resolve(res.data.data);
      })
      .catch((err) => {
        const lat = !err.response.data.errors.lat ? '' : err.response.data.errors.lat[0];
        const long = !err.response.data.errors.long ? '' : err.response.data.errors.long[0];
        const phone = !err.response.data.errors.phone ? '' : err.response.data.errors.phone[0];
        const imsi = !err.response.data.errors.imsi ? '' : err.response.data.errors.imsi[0];
        const mcc = !err.response.data.errors.mcc ? '' : err.response.data.errors.mcc[0];
        const mnc = !err.response.data.errors.mnc ? '' : err.response.data.errors.mnc[0];
        const lac = !err.response.data.errors.lac ? '' : err.response.data.errors.lac[0];
        const cellId = !err.response.data.errors.cell_id ? '' : err.response.data.errors.cell_id[0];
        dispatch({
          type: FIRE_ALERT,
          payload: {
            specificMessage: `${lat} ${long} ${phone} ${imsi} ${mcc} ${mnc} ${lac} ${cellId}`,
            message: err.response.data.message,
          },
        });
        reject(err);
      });
  });

export const archiveCellReport = (id) => (dispatch) =>
  api.report
    .archiveReportLocation(id)
    .then((res) => {
      dispatch({
        type: ARCHIVE_REPORT_CELL,
        payload: res.data.data,
      });
      return res.data.data;
    })
    .catch((err) => err);
