import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Clock from 'react-live-clock';

import './clockWall.sass';

class ClockWall extends Component {
  state = {};
  render() {
    const {
      user: { timezone },
    } = this.props;
    return (
      <div className="clock-wall">
        <Clock format={'HH:mm:ss'} ticking={true} timezone={timezone} />
      </div>
    );
  }
}

ClockWall.propTypes = {
  user: PropTypes.shape({
    timezone: PropTypes.string,
  }).isRequired,
};

const mapStateToProps = (store) => ({
  user: store.user,
});

export default connect(mapStateToProps)(ClockWall);
