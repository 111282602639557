import React, { memo, useState, useEffect, useRef, useCallback } from 'react';
import styled from 'styled-components';

import { Polyline, Popup, SVGOverlay, Tooltip, Circle } from 'react-leaflet';
import L from 'leaflet';
import moment from 'moment';

import PlotRouteInterface from './components/plotRouteInterface';
import TimeStamp from '../../utils/timeStamp';

const filterAsc = (markers) => {
  return markers
    .filter((marker) => marker.lat && marker.lng)
    .sort((a, b) => moment(a.location_time).diff(b.location_time));
};

const PlotMap = ({ markers, map, showRoot }) => {
  const initialMarkers = useRef(filterAsc(markers));
  const [filteredMarkers, setFilteredMarkers] = useState(initialMarkers.current);

  const [isGrouped, setIsGrouped] = useState(false);
  const [rangePickerDates, setRangePickerDates] = useState(null);

  const [sizeInMeters, setSizeInMeters] = useState(500);

  const [playState, setPlayState] = useState(false);
  const [playItemPosition, setPlayItemPosition] = useState(0);

  const root = filteredMarkers.map(({ lat, lng }) => [lat, lng]);

  const groupLocations = (locations) =>
    locations.filter((v, i, a) => a.findIndex((t) => t.lat === v.lat && t.lng === v.lng) === i);

  const groupSameLocationsToggle = () => {
    if (isGrouped) {
      setFilteredMarkers(initialMarkers.current);
      setIsGrouped(false);
      setRangePickerDates(null);
      setPlayItemPosition(0);
      setPlayState(false);
    } else {
      setFilteredMarkers(groupLocations([...filteredMarkers]));
      setIsGrouped(true);
      setPlayItemPosition(0);
      setPlayState(false);
    }
  };

  const filterByDateAndTime = (dates) => {
    setRangePickerDates(dates);
    if (!dates) {
      if (isGrouped) setFilteredMarkers(groupLocations([...initialMarkers.current]));
      else setFilteredMarkers(initialMarkers.current);
    } else {
      const data = isGrouped ? [...filteredMarkers] : [...initialMarkers.current];
      const newData = data.filter(({ location_time }) => {
        const time = new moment(location_time).utc();
        return time.isBetween(
          new moment(dates[0].format('YYYY-MM-DD HH:mm'), 'YYYY-MM-DD HH:mm').utc(),
          new moment(dates[1].format('YYYY-MM-DD HH:mm'), 'YYYY-MM-DD HH:mm').utc(),
        );
      });
      setFilteredMarkers(newData);
    }
  };

  const fitBounds = useCallback(() => {
    if (filteredMarkers.length) {
      const bounds = L.latLngBounds(filteredMarkers);
      map.fitBounds(bounds);
    }
  }, [filteredMarkers, map]);

  const onPlayStart = () => {
    if (filteredMarkers?.length) {
      const { lat, lng } = filteredMarkers[playItemPosition];
      map.setView([lat, lng], 17);
    }
  };

  const onPlayDirectionAction = (direction) => {
    const newForwardPosition = playItemPosition + 1;
    const newBackwardPosition = playItemPosition - 1;

    if (direction === 'forward' && filteredMarkers.length > newForwardPosition) {
      const { lat, lng } = filteredMarkers[newForwardPosition];
      map.setView([lat, lng], 17);
      setPlayItemPosition(newForwardPosition);
    } else if (direction === 'backward' && newBackwardPosition >= 0) {
      const { lat, lng } = filteredMarkers[newBackwardPosition];
      map.setView([lat, lng], 17);
      setPlayItemPosition(newBackwardPosition);
    }
  };

  const onPlayStop = () => {
    fitBounds();
    setPlayItemPosition(0);
  };

  useEffect(() => {
    fitBounds();
  }, [fitBounds]);

  useEffect(() => {
    map.on('zoomend', function (e) {
      switch (e.target._zoom) {
        case 1:
          setSizeInMeters(800000);
          break;
        case 2:
          setSizeInMeters(600000);
          break;
        case 3:
          setSizeInMeters(400000);
          break;
        case 4:
          setSizeInMeters(140000);
          break;
        case 5:
          setSizeInMeters(60000);
          break;
        case 6:
          setSizeInMeters(40000);
          break;
        case 7:
          setSizeInMeters(26000);
          break;
        case 8:
          setSizeInMeters(9000);
          break;
        case 9:
          setSizeInMeters(4000);
          break;
        case 10:
          setSizeInMeters(2000);
          break;
        case 11:
          setSizeInMeters(900);
          break;
        case 12:
          setSizeInMeters(500);
          break;
        case 13:
          setSizeInMeters(280);
          break;
        case 14:
          setSizeInMeters(130);
          break;
        case 15:
          setSizeInMeters(70);
          break;
        case 16:
          setSizeInMeters(35);
          break;
        case 17:
          setSizeInMeters(20);
          break;
        case 18:
          setSizeInMeters(10);
          break;
        case 19:
          setSizeInMeters(5);
          break;
        case 20:
          setSizeInMeters(3);
          break;
        case 21:
          setSizeInMeters(2);
          break;
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <PlotRouteInterface
        active
        showRoot={showRoot}
        groupSameLocationsToggle={groupSameLocationsToggle}
        filterByDateAndTime={filterByDateAndTime}
        isGrouped={isGrouped}
        rangePickerDates={rangePickerDates}
        onPlayStart={onPlayStart}
        onPlayStop={onPlayStop}
        onPlayDirectionAction={onPlayDirectionAction}
        playItemPosition={playItemPosition}
        playState={playState}
        setPlayState={setPlayState}
        filteredMarkersCount={filteredMarkers.length}
        currentActiveMarker={filteredMarkers?.[playItemPosition]}
      />
      <Polyline pathOptions={{ color: '#3da0ea' }} positions={root} />
      {filteredMarkers.map(({ lat, lng, id, location_time }, index) => (
        <div key={id}>
          <SVGOverlay bounds={L.latLng([lat, lng]).toBounds(sizeInMeters)}>
            <Circle center={[lat, lng]} pathOptions={{ opacity: 0, fillOpacity: 0 }} radius={9}>
              <Tooltip direction="top" offset={[0, -15]}>
                <div>
                  <div>
                    <span>LAT, LNG: </span>[{lat}, {lng}]
                  </div>
                  <div>
                    <span>Location time: </span>
                    <TimeStamp style={{ fontWeight: 600 }} date={location_time} />
                  </div>
                </div>
              </Tooltip>
            </Circle>
            <circle r="50%" cx="50%" cy="50%" fill="#1890ff" />
            <text fontSize="9px" x="50%" y="50%" dominantBaseline="middle" textAnchor="middle" stroke="white">
              {index + 1}
            </text>
          </SVGOverlay>
        </div>
      ))}
    </>
  );
};

const StyledPopup = styled(Popup)`
  span {
    font-weight: 600;
  }
`;

export default memo(PlotMap);
