import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Dimmer, Loader } from 'semantic-ui-react';

import { getUser, setUserToNotAuthorized } from './actions/user/auth';
import { getAgents } from './actions/agents/smsNotifications';
import { setDarkMode } from './actions/app/application';

import Routes from './routes';
import NotificationAlert from './utils/notification';
import { initSocket } from './utils/socketConfig';

import { configSounds } from './utils/notificationSound';

import './styles/base/App.sass';

class App extends Component {
  state = {
    loading: true,
  };

  componentDidMount() {
    if (sessionStorage.getItem('access_token')) {
      this.props
        .getUser()
        .then((user) => {
          configSounds(user.sound_success, user.sound_failure);
          // setSocket(user.id);
          initSocket(user.id);
          this.props.getAgents();
          this.setState({ loading: false, userId: user.id });
        })
        .catch(() => {
          this.setState({ loading: false });
        });
    } else {
      this.setLoading(false);
    }

    const darkMode = localStorage.getItem('darkMode');
    if (darkMode && darkMode === 'true') this.props.setDarkMode(true);
    else if (darkMode && darkMode === 'false') this.props.setDarkMode(false);
    else this.props.setDarkMode(false);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // This was done because of Loading TRUE on Server 500 or 503 Server Error
    if (nextProps.location.pathname === '/login') this.setLoading(false);
  }

  setLoading = (bool) => this.setState({ loading: bool });

  render() {
    return (
      <div className="app-base-style">
        <div>
          <NotificationAlert />
          <Dimmer active={this.state.loading} inverted>
            <Loader />
          </Dimmer>
          {!this.state.loading && <Routes location={this.props.location} />}
        </div>
      </div>
    );
  }
}

App.propTypes = {
  getUser: PropTypes.func.isRequired,
  getAgents: PropTypes.func.isRequired,
  setDarkMode: PropTypes.func.isRequired,
  location: PropTypes.shape({
    path: PropTypes.string,
    pathname: PropTypes.string,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  darkMode: state.app.darkMode,
});

export default connect(mapStateToProps, { getUser, setUserToNotAuthorized, getAgents, setDarkMode })(App);
