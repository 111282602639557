// import React from 'react';

export default class SessionUnresolvedLocationsHelper {
  constructor() {
    this.state = JSON.parse(sessionStorage.getItem('sessionUnresolvedLocations'));
  }

  getData() {
    return this.state;
  }

  setSession(data) {
    this.state = data;
    sessionStorage.setItem('sessionUnresolvedLocations', JSON.stringify(data));
  }

  setLocationToSession(data) {
    if (!this.state) {
      const object = {
        [data.location.contact_id]: {
          [data.location.id]: data.location,
        },
      };
      this.setSession(object);
    } else {
      const object = {
        ...this.state,
        [data.location.contact_id]: {
          ...this.state[data.location.contact_id],
          [data.location.id]: data.location,
        },
      };
      this.setSession(object);
    }
  }

  updateLocationToSession(data) {
    if (!this.state || !this.state[data.contactId] || !this.state[data.contactId][data.locationId]) return null;
    const object = {
      ...this.state,
      [data.contactId]: {
        ...this.state[data.contactId],
        [data.locationId]: {
          ...this.state[data.contactId][data.locationId],
          ...data,
        },
      },
    };
    this.setSession(object);
  }

  removeLocationFromSession(data) {
    if (!this.state) return;
    delete this.state[data.location.contact_id][data.location.id];
    this.setSession(this.state);
    if (
      Object.keys(this.state[data.location.contact_id]).length === 0 &&
      this.state[data.location.contact_id].constructor === Object
    ) {
      delete this.state[data.location.contact_id];
      this.setSession(this.state);
    }
  }

  destroySession() {
    this.state = {};
    sessionStorage.removeItem('sessionUnresolvedLocations');
  }
}
