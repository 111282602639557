import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Button } from 'semantic-ui-react';
import moment from 'moment-timezone';

import {
  Table,
  Pagination,
  Input,
  Menu,
  InputNumber,
  Dropdown,
  Select,
  Transfer,
  DatePicker,
  Button as ButtonAntd,
  Space,
} from 'antd';
import { DownOutlined, SearchOutlined, UserOutlined } from '@ant-design/icons';

import { getReport, downloadReport, downloadPdf, archiveCellReport } from '../../actions/report/report';
import { getTeams } from '../../actions/teams/teams';

import TimeStamp from '../../utils/timeStamp';
import DropDown from '../../components/addDropDown/index';

import { searchTypes } from '../../components/searchComponent/searchComponent';
import timeToUTC from '../../utils/timeToUTC';

const { RangePicker } = DatePicker;

const transfer = [
  { key: '1', title: 'ID', description: 'description1', disabled: true, keyName: 'id' },
  { key: '2', title: 'Contact name', description: 'description2', disabled: true, keyName: 'contact' },
  { key: '3', title: 'Phone', description: 'description3', keyName: 'phone' },
  { key: '4', title: 'IMSI', description: 'description4', keyName: 'imsi' },
  { key: '5', title: 'Result Time', description: 'description5', keyName: 'location_time' },
  { key: '6', title: 'MCC', description: 'description6', keyName: 'mcc' },
  { key: '7', title: 'MNC', description: 'description7', keyName: 'mnc' },
  { key: '8', title: 'LAC/TAC', description: 'description8', keyName: 'lac' },
  { key: '9', title: 'CELLID', description: 'description9', keyName: 'cell_id' },
  { key: '10', title: 'IMEI', description: 'description9', keyName: 'imei' },
  { key: '11', title: 'State', description: 'description9', keyName: 'state' },
  { key: '12', title: 'Country ISO', description: 'description9', keyName: 'country_iso' },
  { key: '13', title: 'Age', description: 'description9', keyName: 'age' },
  { key: '14', title: 'Range', description: 'description9', keyName: 'range' },
  { key: '15', title: 'Angle', description: 'description9', keyName: 'angle' },
  { key: '16', title: 'Width', description: 'description9', keyName: 'width' },
  { key: '17', title: 'Error code', description: 'description9', keyName: 'code' },
  { key: '18', title: 'Query type', description: 'description9', keyName: 'query_type' },
  { key: '19', title: 'LAT', description: 'description10', keyName: 'lat' },
  { key: '20', title: 'LONG', description: 'description10', keyName: 'lon' },
  { key: '21', title: 'Username', description: 'description10', keyName: 'username' },
  { key: '22', title: 'Team', description: 'description10', keyName: 'team' },
  { key: '23', title: 'Search type', description: 'description10', keyName: 'readable_search_type' },
  { key: '24', title: 'Action', description: 'description10', keyName: 'action' },
];

const toolTipText = 'this field can match partial strings';

let clearF = {};

class ReportsTable extends Component {
  state = {
    loading: true,
    downloadLoadingCsv: false,
    downloadLoadingPdf: false,
    cells: {
      per_page: 10,
      page: 1,
      hide_others: 0,
    },
    reportInfo: {
      total: 1,
      page: 1,
    },
    reportData: [],
    teams: [],
    selectedKeys: [],
    targetKeys: [
      '1',
      '2',
      '3',
      '4',
      '5',
      '6',
      '7',
      '8',
      '9',
      '10',
      '11',
      '12',
      '13',
      '14',
      '15',
      '16',
      '17',
      '18',
      '19',
      '20',
      '21',
      '22',
      '23',
      '24',
    ],
    loadingPDF: false,
    requestLoadings: [],
    globalLocationTime: null,
  };

  getReportAction = () => {
    this.props.triggerAction(true);

    this.props.getReport({ per_page: 10, 'csv-export': 0 }).then((res) => {
      this.props.triggerAction(false);

      this.setState(
        {
          loading: false,
          reportInfo: {
            total: res.meta.total,
            page: res.meta.current_page,
          },
          reportData: Object.values(res.data).reverse(),
        },
        () => {
          if (this.props.user.role_id === 2) {
            this.props.getTeams().then((teams) => this.setState({ teams }));
          }
        },
      );
    });
  };

  componentDidMount() {
    this.getReportAction();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.cells.data) {
      this.setState({ reportData: Object.values(nextProps.cells.data).reverse() });
    }
  }

  submitFilterWithRange = (range) => {
    this.setState({ loading: true });
    const filters = { ...this.state.cells };
    delete filters.page;

    if (range) {
      filters.location_time = timeToUTC(range[0].format('YYYY-MM-DD HH:mm:ss'), this.props.user.timezone);
      filters.location_time_end = timeToUTC(range[1].format('YYYY-MM-DD HH:mm:ss'), this.props.user.timezone);
    } else {
      if (filters.location_time) delete filters.location_time;
      if (filters.location_time_end) delete filters.location_time_end;
    }

    if (clearF['location_time']) clearF['location_time']();

    return new Promise((resolve, reject) =>
      this.props
        .getReport({ ...filters, 'csv-export': 0 })
        .then((res) => {
          this.setState(
            {
              loading: false,
              reportInfo: {
                total: res?.meta?.total || 1,
                page: res?.meta?.current_page || 1,
              },
              reportData: Object.values(res.data || {}).reverse(),
              cells: filters,
            },
            () => resolve(res),
          );
        })
        .catch((err) => {
          this.setState({ loading: false });
          return reject(err);
        }),
    );
  };

  submitFilter = (e) => {
    e?.preventDefault();
    this.setState({ loading: true });

    const filters = { ...this.state.cells };
    delete filters.page;

    // if (filters.location_time) {
    //   this.setState({ globalLocationTime: null });
    // }

    return new Promise((resolve, reject) =>
      this.props
        .getReport({ ...filters, 'csv-export': 0 })
        .then((res) => {
          this.setState(
            {
              loading: false,
              reportInfo: {
                total: res?.meta?.total || 1,
                page: res?.meta?.current_page || 1,
              },
              reportData: Object.values(res?.data || {}).reverse(),
            },
            () => resolve(res),
          );
        })
        .catch((err) => {
          this.setState({ loading: false });
          return reject(err);
        }),
    );
  };

  onPaginationChange = (current, pageSize) => {
    const filters = { ...this.state.cells };

    if (filters.location_time) filters.location_time = timeToUTC(filters.location_time, this.props.user.timezone);
    if (filters.location_time_end)
      filters.location_time_end = timeToUTC(filters.location_time_end, this.props.user.timezone);

    this.setState({ loading: true }, () => {
      this.props.getReport({ ...filters, per_page: pageSize, page: current }).then((res) => {
        this.setState({
          loading: false,
          reportInfo: {
            total: res.meta.total,
            page: res.meta.current_page,
          },
          reportData: Object.values(res.data).reverse(),
          cells: {
            ...this.state.cells,
            per_page: pageSize,
            page: current,
          },
        });
      });
    });
  };

  downloadReport = ({ key }) => {
    this.setState({ downloadLoadingCsv: true }, () => {
      const filters = { ...this.state.cells };

      delete filters.page;
      delete filters.per_page;

      if (filters.location_time && filters.location_time_end) {
        filters.location_time = timeToUTC(filters.location_time, this.props.user.timezone);
        filters.location_time_end = timeToUTC(filters.location_time_end, this.props.user.timezone);
      }

      this.props
        .downloadReport({
          ...filters,
          with_archived: key,
          'csv-export': 1,
          output_columns: this.state.targetKeys
            ?.filter((key) => key !== '24' && key !== '22')
            ?.map((tKey) => transfer.find((item) => item.key === tKey)?.keyName),
        })
        .then(() => this.setState({ downloadLoadingCsv: false }))
        .catch(() => this.setState({ downloadLoadingCsv: false }));
    });
  };

  handleCountrySearch = (value) => {
    if (value !== 'No country') this.setState({ cells: { ...this.state.cells, country_iso: value } });
    else {
      const cells = { ...this.state.cells };
      delete cells.country_iso;
      this.setState({ cells: { ...cells } });
    }
  };

  handleChange = (nextTargetKeys) => this.setState({ targetKeys: nextTargetKeys });

  handleSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
    this.setState({ selectedKeys: [...sourceSelectedKeys, ...targetSelectedKeys] });
  };

  downloadPDF = ({ key }) => {
    this.setState({ loadingPDF: true });
    this.props
      .downloadPdf({ ...this.state.cells, with_archived: key, 'pdf-export': 1 })
      .then(() => this.setState({ loadingPDF: false }));
  };

  archiveCell = (data) => {
    if (this.state.requestLoadings.indexOf(data.id) === -1) {
      this.setState({ loading: true, requestLoadings: [...this.state.requestLoadings, data.id] });
      this.props
        .archiveCellReport(data.id)
        .then(() => {
          const newArray = [...this.state.requestLoadings];
          newArray.splice(this.state.requestLoadings.indexOf(data.id), 1);
          this.setState({ requestLoadings: newArray, loading: false });
        })
        .then(() => {
          this.getReportAction();
        });
    }
  };

  handleSearch = (selectedKeys, confirm, dataIndex, clearFilters) => {
    if (!selectedKeys[0]) {
      const filters = { ...this.state.cells };
      delete filters[dataIndex];
      delete clearF[dataIndex];
      this.setState({ cells: filters });
    } else {
      clearF[dataIndex] = clearFilters;
      if (dataIndex === 'age') {
        const filters = { ...this.state.cells };
        delete filters['age'];
        this.setState(
          {
            cells: filters,
          },
          () => {
            this.submitFilter().then(() => confirm());
          },
        );
      } else if (dataIndex === 'range') {
        const filters = { ...this.state.cells };
        delete filters['range'];
        this.setState(
          {
            cells: filters,
          },
          () => {
            this.submitFilter().then(() => confirm());
          },
        );
      } else {
        this.setState(
          {
            cells: {
              ...this.state.cells,
              [dataIndex]: selectedKeys[0],
            },
          },
          () => {
            this.submitFilter().then(() => confirm());
          },
        );
      }
    }
  };

  handleReset = (clearFilters, dataIndex) => {
    const filters = { ...this.state.cells };
    delete filters[dataIndex];
    delete clearF[dataIndex];
    this.setState({ cells: filters }, () => {
      this.submitFilter().then(() => clearFilters());
    });
  };

  handleAgeReset = (clearFilters) => {
    const filters = { ...this.state.cells };
    delete filters['age_start'];
    delete filters['age_end'];
    delete clearF['age'];
    this.setState({ cells: filters }, () => {
      this.submitFilter().then(() => clearFilters());
    });
  };

  handleRangeReset = (clearFilters) => {
    const filters = { ...this.state.cells };
    delete filters['range_start'];
    delete filters['range_end'];
    delete clearF['range'];
    this.setState({ cells: filters }, () => {
      this.submitFilter().then(() => clearFilters());
    });
  };

  getColumnCalendarSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      if (clearF[dataIndex]) clearF[dataIndex] = clearFilters;
      return (
        <div style={{ padding: 8 }}>
          <DatePicker
            className="date_picker"
            showTime
            format="YYYY/MM/DD HH:mm"
            size="default"
            name="location_time"
            style={{ width: 170, marginRight: 10 }}
            onChange={(momentDate) => {
              const utcDate = timeToUTC(momentDate?.format('YYYY-MM-DD HH:mm:ss'), this.props.user.timezone);
              setSelectedKeys([utcDate]);
            }}
          />
          <Space>
            <ButtonAntd
              type="primary"
              onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex, clearFilters)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Search
            </ButtonAntd>
            <ButtonAntd
              onClick={() => {
                this.handleReset(clearFilters, dataIndex);
                this.setState({ globalLocationTime: null });
              }}
              size="small"
              style={{ width: 90 }}
            >
              Reset
            </ButtonAntd>
          </Space>
        </div>
      );
    },
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: () => true,
  });

  setAgeRanges = (value, key, setSelectedKeys) => {
    this.setState(
      {
        cells: {
          ...this.state.cells,
          [key]: value,
        },
      },
      () => setSelectedKeys([true]),
    );
  };

  getAgeRangeSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      if (clearF[dataIndex]) clearF[dataIndex] = clearFilters;
      return (
        <div style={{ padding: 8 }}>
          {dataIndex === 'age' && (
            <div style={{ marginBottom: 10 }}>
              <InputNumber
                style={{ width: 100 }}
                placeholder="Start"
                onChange={(e) => this.setAgeRanges(e, 'age_start', setSelectedKeys)}
              />
              <span> - </span>
              <InputNumber
                style={{ width: 100 }}
                placeholder="End"
                onChange={(e) => this.setAgeRanges(e, 'age_end', setSelectedKeys)}
              />
            </div>
          )}

          {dataIndex === 'range' && (
            <div style={{ marginBottom: 10 }}>
              <InputNumber
                style={{ width: 100 }}
                placeholder="Start"
                onChange={(e) => this.setAgeRanges(e, 'range_start', setSelectedKeys)}
              />
              <span> - </span>
              <InputNumber
                style={{ width: 100 }}
                placeholder="End"
                onChange={(e) => this.setAgeRanges(e, 'range_end', setSelectedKeys)}
              />
            </div>
          )}

          <Space>
            <ButtonAntd
              type="primary"
              onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex, clearFilters)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Search
            </ButtonAntd>
            <ButtonAntd
              onClick={() =>
                dataIndex === 'age' ? this.handleAgeReset(clearFilters) : this.handleRangeReset(clearFilters)
              }
              size="small"
              style={{ width: 90 }}
            >
              Reset
            </ButtonAntd>
          </Space>
        </div>
      );
    },
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: () => true,
  });

  getColumnSelectSearchProps = (dataIndex) => {
    if (this.props.user.role_id !== 2 && dataIndex === 'team')
      return {
        filterIcon: (filtered) => <SearchOutlined disabled style={{ color: filtered ? '#1890ff' : undefined }} />,
        filterDropdown: () => <div style={{ padding: 8 }}>You don't have permissions to use this filter</div>,
        filtered: false,
      };
    return {
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
        if (clearF[dataIndex]) clearF[dataIndex] = clearFilters;
        return (
          <div style={{ padding: 8 }}>
            {dataIndex === 'country_iso' && (
              <Select
                defaultValue="No country"
                style={{ width: 200, margin: '4px 8px 4px 0px' }}
                onChange={(country) => {
                  const index = this.props.cells?.report_meta?.countries.findIndex((item) => item.Country === country);
                  if (country !== 'No country' && index > -1)
                    setSelectedKeys([this.props.cells?.report_meta?.countries[index].ISO]);
                  else this.handleReset(clearFilters, dataIndex);
                }}
                showSearch
              >
                <Select.Option value="No country">No country</Select.Option>
                {this.props.cells?.report_meta?.countries?.map((c) => (
                  <Select.Option key={c.ISO} value={c.Country}>
                    {c.Country}
                  </Select.Option>
                ))}
              </Select>
            )}
            {dataIndex === 'query_type' && (
              <Select
                defaultValue="Query type"
                style={{ width: 200, margin: '4px 8px 4px 0px' }}
                onChange={(type) => {
                  if (type !== 'Query type') setSelectedKeys([type]);
                  else this.handleReset(clearFilters, dataIndex);
                }}
              >
                <Select.Option value="Query type">All Query types</Select.Option>
                <Select.Option value="active">Active</Select.Option>
                <Select.Option value="passive">Passive</Select.Option>
              </Select>
            )}
            {dataIndex === 'state' && (
              <Select
                defaultValue="All"
                style={{ width: 200, margin: '4px 8px 4px 0px' }}
                onChange={(type) => {
                  if (type !== 'All') setSelectedKeys([type]);
                  else this.handleReset(clearFilters, dataIndex);
                }}
              >
                <Select.Option value="All">All</Select.Option>
                <Select.Option value="Idle">Idle</Select.Option>
                <Select.Option value="PrePayBusy">PrePayBusy</Select.Option>
                <Select.Option value="NotReachableOff">NotReachableOff</Select.Option>
                <Select.Option value="NotProvidedFromVLR">NotProvidedFromVLR</Select.Option>
                <Select.Option value="AttachedNotReachable">AttachedNotReachable</Select.Option>
                <Select.Option value="AttachedReachable">AttachedReachable</Select.Option>
                <Select.Option value="PDPActiveNotReachable">PDPActiveNotReachable</Select.Option>
                <Select.Option value="PDPActiveReachable">PDPActiveReachable</Select.Option>
                <Select.Option value="NetDetNotReachable">NetDetNotReachable</Select.Option>
                <Select.Option value="StateUnknown">StateUnknown</Select.Option>
              </Select>
            )}
            {dataIndex === 'api_request_type' ? (
              <Select
                defaultValue="No Search types"
                style={{ width: 200, margin: '4px 8px 4px 0px' }}
                onChange={(team) => {
                  if (team !== 'No team') setSelectedKeys([team]);
                  else this.handleReset(clearFilters, dataIndex);
                }}
              >
                {Object.keys(searchTypes).map((key) => {
                  return (
                    <Select.Option key={key} value={key}>
                      {searchTypes[key]}
                    </Select.Option>
                  );
                })}
              </Select>
            ) : null}
            {this.props.user.role_id === 2 && dataIndex === 'team' ? (
              <Select
                defaultValue="No team"
                style={{ width: 200, margin: '4px 8px 4px 0px' }}
                onChange={(team) => {
                  if (team !== 'No team') setSelectedKeys([team]);
                  else this.handleReset(clearFilters, dataIndex);
                }}
                showSearch
              >
                <Select.Option value="No team">No team</Select.Option>
                {this.state.teams.length > 0
                  ? this.state.teams.map((res, index) => {
                      const key = `${res.name}-${index}`;
                      return (
                        <Select.Option key={key} value={res.name}>
                          {res.name}
                        </Select.Option>
                      );
                    })
                  : ''}
              </Select>
            ) : null}
            <Space>
              <ButtonAntd
                type="primary"
                onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex, clearFilters)}
                icon={<SearchOutlined />}
                size="small"
                style={{ width: 90 }}
              >
                Search
              </ButtonAntd>
              <ButtonAntd onClick={() => this.handleReset(clearFilters, dataIndex)} size="small" style={{ width: 90 }}>
                Reset
              </ButtonAntd>
            </Space>
          </div>
        );
      },
      filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
      onFilter: () => true,
    };
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      if (clearF[dataIndex]) clearF[dataIndex] = clearFilters;
      return (
        <div style={{ padding: 8 }}>
          <Input
            ref={(node) => {
              this.searchInput = node;
            }}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex, clearFilters)}
            style={{ marginBottom: 8, display: 'block' }}
          />
          <Space>
            <ButtonAntd
              type="primary"
              onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex, clearFilters)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Search
            </ButtonAntd>
            <ButtonAntd onClick={() => this.handleReset(clearFilters, dataIndex)} size="small" style={{ width: 90 }}>
              Reset
            </ButtonAntd>
          </Space>
        </div>
      );
    },
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
  });

  resetAllFilters = () => {
    Object.keys(clearF).forEach((key, index) => {
      setTimeout(() => clearF[key](), 10);
    });

    setTimeout(
      () =>
        this.setState(
          {
            ...this.state,
            cells: {
              per_page: 10,
              page: 1,
            },
            searchText: '',
            searchedColumn: null,
            loading: true,
          },
          () => {
            this.submitFilter()
              .then(() => this.setState({ loading: false }))
              .catch(() => this.setState({ loading: false }));
          },
        ),
      100,
    );
  };

  filterWithRange = (range) => {
    if (range === null) {
      this.setState(
        {
          globalLocationTime: range,
        },
        () => {
          this.submitFilterWithRange(range);
        },
      );
    } else {
      this.setState(
        {
          globalLocationTime: range,
        },
        () => {
          this.submitFilterWithRange(range);
        },
      );
    }
  };

  render() {
    moment.tz.setDefault(this.props.timezone);

    const columns = [
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
        columnId: '1',
        width: 100,
        fixed: 'left',
        ...this.getColumnSearchProps('id'),
      },
      {
        title: 'Contact name',
        dataIndex: 'contact',
        key: 'contact',
        columnId: '2',
        width: 200,
        fixed: 'left',
        ...this.getColumnSearchProps('contact'),
      },
      {
        title: 'MSISDN',
        dataIndex: 'phone',
        key: 'phone',
        columnId: '3',
        width: 180,
        ...this.getColumnSearchProps('phone'),
      },
      {
        title: 'IMSI',
        dataIndex: 'imsi',
        key: 'imsi',
        columnId: '4',
        width: 180,
        ...this.getColumnSearchProps('imsi'),
      },
      {
        title: `Result Time ( ${this.props.user.timezone_offset} )`,
        dataIndex: 'location_time',
        key: 'location_time',
        columnId: '5',
        render: (text, record) => <TimeStamp date={record.location_time} />,
        width: 250,
        // ...this.getColumnCalendarSearchProps('location_time'),
      },
      {
        title: 'MCC',
        dataIndex: 'mcc',
        key: 'mcc',
        columnId: '6',
        width: 100,
        ...this.getColumnSearchProps('mcc'),
      },
      {
        title: 'MNC',
        dataIndex: 'mnc',
        key: 'mnc',
        columnId: '7',
        width: 100,
        ...this.getColumnSearchProps('mnc'),
      },
      {
        title: 'LAC/TAC',
        dataIndex: 'lac',
        key: 'lac',
        columnId: '8',
        width: 100,
        ...this.getColumnSearchProps('lac'),
      },
      {
        title: 'CELLID',
        dataIndex: 'cell_id',
        key: 'cell_id',
        columnId: '9',
        render: (text) => <div>{!text ? 'null' : text}</div>,
        width: 120,
        ...this.getColumnSearchProps('cell_id'),
      },
      {
        title: 'IMEI',
        dataIndex: 'imei',
        key: 'imei',
        columnId: '10',
        width: 200,
        ...this.getColumnSearchProps('imei'),
      },
      {
        title: 'State',
        dataIndex: 'state',
        key: 'state',
        columnId: '11',
        width: 180,
        ...this.getColumnSelectSearchProps('state'),
      },
      {
        title: 'Country ISO',
        dataIndex: 'country_iso',
        key: 'country_iso',
        columnId: '12',
        width: 180,
        ...this.getColumnSelectSearchProps('country_iso'),
      },
      {
        title: 'Age',
        dataIndex: 'age',
        key: 'age',
        columnId: '13',
        width: 100,
        ...this.getAgeRangeSearchProps('age'),
      },
      {
        title: 'Range',
        dataIndex: 'range',
        key: 'range',
        columnId: '14',
        width: 120,
        ...this.getAgeRangeSearchProps('range'),
      },
      {
        title: 'Angle',
        dataIndex: 'angle',
        key: 'angle',
        columnId: '15',
        width: 80,
        render: (angle, data) => (data.width !== 360 ? angle : null),
        ...this.getColumnSearchProps('angle'),
      },
      {
        title: 'Width',
        dataIndex: 'width',
        key: 'width',
        columnId: '16',
        width: 80,
        render: (width) => (width !== 360 ? width : null),
        ...this.getColumnSearchProps('width'),
      },
      {
        title: 'Error code',
        dataIndex: 'code',
        key: 'code',
        columnId: '17',
        width: 150,
        ...this.getColumnSearchProps('code'),
      },
      {
        title: 'Query type',
        dataIndex: 'query_type',
        key: 'query_type',
        columnId: '18',
        width: 140,
        ...this.getColumnSelectSearchProps('query_type'),
      },
      {
        title: 'LAT',
        dataIndex: 'lat',
        key: 'lat',
        columnId: '19',
        width: 140,
        ...this.getColumnSearchProps('lat'),
      },
      {
        title: 'LON',
        dataIndex: 'lon',
        key: 'lon',
        columnId: '20',
        width: 140,
        ...this.getColumnSearchProps('lon'),
      },
      {
        title: 'Username',
        dataIndex: 'username',
        key: 'username',
        columnId: '21',
        width: 200,
        ...this.getColumnSearchProps('username'),
      },
      {
        title: 'Team',
        dataIndex: 'team',
        key: 'team',
        columnId: '22',
        render: (text, record) => {
          return record.team === null ? 'No team' : text;
        },
        width: 200,
        ...this.getColumnSelectSearchProps('team'),
      },
      {
        title: 'Search type',
        dataIndex: 'api_request_type',
        key: 'api_request_type',
        columnId: '23',
        render: (text) => {
          return searchTypes[text];
        },
        width: 200,
        ...this.getColumnSelectSearchProps('api_request_type'),
      },
      {
        title: 'Action',
        dataIndex: 'action',
        key: 'action',
        width: 100,
        fixed: 'right',
        columnId: '24',
        render: (text, record) => (
          <ButtonAntd
            loading={this.state.requestLoadings.indexOf(record.id) !== -1}
            size="small"
            onClick={() => this.archiveCell(record)}
          >
            Archive
          </ButtonAntd>
        ),
      },
    ];

    return (
      <React.Fragment>
        <DropDown
          className="cell-data-page__drop-down"
          createSuccess={this.state.uploaded}
          buttonName="Selectors"
          height={220}
          hideContent={false}
        >
          <Transfer
            className="table-cell-form__transfer"
            dataSource={transfer}
            titles={['Available Columns', 'Active Columns']}
            targetKeys={this.state.targetKeys}
            selectedKeys={this.state.selectedKeys}
            onChange={this.handleChange}
            onSelectChange={this.handleSelectChange}
            onScroll={this.handleScroll}
            render={(item) => item.title}
            height={300}
          />
        </DropDown>

        <div style={{ marginBottom: 20 }}>
          <Button size="mini" disabled={Object.values(this.state.cells).length <= 2} onClick={this.resetAllFilters}>
            Reset filters
          </Button>
          <Dropdown
            overlay={
              <Menu onClick={this.downloadReport}>
                <Menu.Item key="1" icon={<UserOutlined />}>
                  With Archived
                </Menu.Item>
                <Menu.Item key="0" icon={<UserOutlined />}>
                  Without Archived
                </Menu.Item>
              </Menu>
            }
          >
            <Button size="mini" loading={this.state.downloadLoadingCsv}>
              Download CSV <DownOutlined />
            </Button>
          </Dropdown>
          {/*<span>*/}
          {/*  <Dropdown*/}
          {/*    overlay={*/}
          {/*      <Menu onClick={this.downloadPDF}>*/}
          {/*        <Menu.Item key="1" icon={<UserOutlined />}>*/}
          {/*          With Archived*/}
          {/*        </Menu.Item>*/}
          {/*        <Menu.Item key="0" icon={<UserOutlined />}>*/}
          {/*          Without Archived*/}
          {/*        </Menu.Item>*/}
          {/*      </Menu>*/}
          {/*    }*/}
          {/*  >*/}
          {/*    <Button size="mini" loading={this.state.loadingPDF}>*/}
          {/*      Download PDF <DownOutlined />*/}
          {/*    </Button>*/}
          {/*  </Dropdown>*/}
          {/*  {this.state.loadingPDF && (*/}
          {/*    <span style={{ marginLeft: 20, fontWeight: 300 }}>*/}
          {/*      This download may take a few seconds, please stay on this page.*/}
          {/*    </span>*/}
          {/*  )}*/}
          {/*</span>*/}
          <RangePicker
            onChange={this.filterWithRange}
            showTime
            format="YYYY/MM/DD HH:mm"
            value={this.state.globalLocationTime ?? null}
          />
        </div>

        <h3 style={{ marginBottom: 0 }}>Locations</h3>
        <div className="timezone_report">{`(${this.props.user.timezone_offset}) ${this.props.user.timezone}`}</div>
        <Pagination
          className="cell-pagination"
          showSizeChanger
          disabled={this.state.loading || this.props.triggerLoading}
          pageSizeOptions={['10', '20', '50', '100', '500']}
          pageSize={this.state.cells.per_page}
          onChange={this.onPaginationChange}
          onShowSizeChange={this.onPaginationChange}
          current={this.state.reportInfo.page}
          total={this.state.reportInfo.total}
          size="middle"
        />
        <Table
          loading={this.state.loading || this.props.triggerLoading}
          rowKey={'id'}
          columns={columns.filter((column) => this.state.targetKeys.indexOf(column.columnId) !== -1)}
          dataSource={this.props.cells?.data ? Object.values(this.props.cells?.data) : []}
          scroll={{ x: 'auto' }}
          pagination={false}
          size="small"
        />
      </React.Fragment>
    );
  }
}

ReportsTable.defaultProps = {
  cells: {},
  user: {},
  triggerLoading: false,
};

ReportsTable.propTypes = {
  triggerAction: PropTypes.func.isRequired,
  triggerLoading: PropTypes.func.bool,
  user: PropTypes.shape({
    role_id: PropTypes.number,
    timezone: PropTypes.string,
    timezone_offset: PropTypes.string,
  }),
  getReport: PropTypes.func.isRequired,
  getTeams: PropTypes.func.isRequired,
  downloadReport: PropTypes.func.isRequired,
  cells: PropTypes.shape({
    paginator: PropTypes.shape({
      current_page: PropTypes.number,
      data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
      per_page: PropTypes.number,
    }),
  }),
  downloadPdf: PropTypes.func.isRequired,
  archiveCellReport: PropTypes.func.isRequired,
  timezone: PropTypes.string,
};

const mapStateToProps = (store) => ({
  cells: store.report.cells,
  user: store.user,
  timezone: store.user.timezone,
});

export default connect(mapStateToProps, { getReport, downloadReport, downloadPdf, getTeams, archiveCellReport })(
  ReportsTable,
);
