import React, { memo, useState, useMemo, useEffect, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import L from 'leaflet';
import { Circle, Marker, Popup } from 'react-leaflet';

import { RadarChartOutlined } from '@ant-design/icons';

import { getHeatPoints, getPlots } from '../../actions/contacts/contact';

import MapButton from './components/mapButton';
import TimeStamp from '../../utils/timeStamp';
import HeatMap from './heatMap';

import './components/sector';

import markerRed from '../../assets/markers/marker-red.png';
import markerRedNone from '../../assets/markers/marker-red-none.png';
import markerBlue from '../../assets/markers/marker-blue.png';
import markerBlueNone from '../../assets/markers/marker-blue-none.png';
import markerPurple from '../../assets/markers/marker-purple.png';
import markerPurpleNone from '../../assets/markers/marker-purple-none.png';
import markerYellow from '../../assets/markers/marker-yellow.png';
import markerYellowNone from '../../assets/markers/marker-yellow-none.png';
import markerBlack from '../../assets/markers/marker-black.png';
import markerBlackNone from '../../assets/markers/marker-black-none.png';

const CIRCLE_COLORS = ['red', 'blue', 'yellow', 'black', 'purple'];

const SingleMarker = ({
  id: mainId,
  map,
  marker: { lat, lng, angle_start, angle_end, range, id, mcc, mnc, lac, title, hour, cellId },
  center,
  zoom,
  isOnDashboard,
}) => {
  const [plotsView, setPlotsView] = useState({ loading: false, active: false });

  const [heatMapView, setHeatMapView] = useState({ loading: false, active: false });
  const [heatPoints, setHeatPoints] = useState([]);

  const plots = useSelector((store) => store.contact.plotsLocations);

  const dispatch = useDispatch();

  const getPlotsAction = useCallback(() => {
    if (!plotsView.active) {
      if (!plots || !plots[id]) {
        setPlotsView({ loading: true, active: true });
        dispatch(getPlots(`${mcc}`, `${mnc}`, `${lac}`, `${id}`)).then(() => {
          setPlotsView((store) => ({ ...store, loading: false }));
          setTimeout(() => map.setView(new L.LatLng(lat, lng), zoom - 1, { animate: true }), 200);
        });
      } else if (plots[id]) {
        setPlotsView((state) => ({ ...state, active: true }));
        setTimeout(() => map.setView(new L.LatLng(lat, lng), zoom - 1, { animate: true }), 200);
      }
    } else {
      setPlotsView((state) => ({ ...state, active: false }));
      setTimeout(() => map.setView(new L.LatLng(lat, lng), zoom, { animate: true }), 200);
    }
  }, [plotsView, plots, dispatch, id, lac, mcc, lat, mnc, map, lng, zoom]);

  const getMarker = useCallback((color, lacf) => {
    const myIcon = L.icon({
      iconUrl: markerBlackNone,
      iconSize: [40, 40],
      iconAnchor: [20, 40],
      popupAnchor: [0, -40],
      shadowUrl: null,
      shadowSize: null,
      shadowAnchor: null,
    });

    if (color === 'red') {
      if (lacf) myIcon.options.iconUrl = markerRed;
      else myIcon.options.iconUrl = markerRedNone;
    } else if (color === 'blue') {
      if (lacf) myIcon.options.iconUrl = markerBlue;
      else myIcon.options.iconUrl = markerBlueNone;
    } else if (color === 'purple') {
      if (lacf) myIcon.options.iconUrl = markerPurple;
      else myIcon.options.iconUrl = markerPurpleNone;
    } else if (color === 'black') {
      if (lacf) myIcon.options.iconUrl = markerPurple;
      else myIcon.options.iconUrl = markerPurpleNone;
    } else if (color === 'yellow') {
      if (lacf) myIcon.options.iconUrl = markerYellow;
      else myIcon.options.iconUrl = markerYellowNone;
    } else if (color === 'black') {
      if (lacf) myIcon.options.iconUrl = markerBlack;
    }

    return myIcon;
  }, []);

  // Draw sector
  const azimuthRef = useRef(null);
  if ((angle_start !== 0 || angle_end !== 360) && !isNaN(angle_start) && !isNaN(angle_end)) {
    azimuthRef.current?.removeFrom(map);
    azimuthRef.current = null;
    azimuthRef.current = L.circle([lat, lng], {
      radius: range,
      startAngle: angle_start,
      endAngle: angle_end,
      weight: 1,
      color: 'green',
    }).addTo(map);
  } else if (azimuthRef.current && angle_start === 0 && angle_end === 360) {
    azimuthRef.current?.removeFrom(map);
    azimuthRef.current = null;
  }

  const plotsDisplay = useMemo(
    () =>
      plots && plots[id]?.id && plots[id]?.object?.length
        ? plots[id]?.object.map(({ location, accuracy, lacf }, index) => {
            const plotKey = `plots-location-${index}`;
            return (
              <div key={plotKey}>
                <Marker position={[location.lat, location.lng]} icon={getMarker(CIRCLE_COLORS[index], lacf)}>
                  <StyledPopup>
                    <div>
                      <div>Option-{index + 1}</div>
                      <div style={{ fontWeight: 600 }}>Lacf: {`${lacf}`}</div>
                    </div>
                  </StyledPopup>
                </Marker>
                <Circle
                  center={[location.lat, location.lng]}
                  pathOptions={{ color: CIRCLE_COLORS[index], weight: 1 }}
                  radius={accuracy}
                />
              </div>
            );
          })
        : '',
    [plots, id, getMarker],
  );

  // Heat map functionality
  const getHeatMapAction = useCallback(() => {
    if (cellId && mcc && mnc && lac) {
      if (!heatMapView.active) {
        setHeatMapView((state) => ({ ...state, loading: true }));
        dispatch(getHeatPoints(`${cellId}/${mcc}/${mnc}/${lac}`))
          .then((res) => {
            setHeatPoints(res?.data?.Data);
            setHeatMapView({ active: true, loading: false });
          })
          .catch(() => setHeatMapView((state) => ({ ...state, loading: false })));
      } else setHeatMapView({ active: false, loading: false });
    }
  }, [dispatch, heatMapView.active, cellId, mcc, mnc, lac]);

  useEffect(() => {
    setPlotsView({ active: false, loading: false });
  }, [id]);

  useEffect(() => {
    return () => {
      if (azimuthRef.current) {
        azimuthRef.current?.removeFrom(map);
        azimuthRef.current = null;
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <MapButton
        key="getPlots"
        Icon={RadarChartOutlined}
        active={plotsView.active}
        loading={plotsView.loading}
        onClick={getPlotsAction}
        toolTipText="Toggle plots view"
      />
      <Marker position={[lat, lng]}>
        <StyledPopup>
          <div>{title}</div>
          <TimeStamp style={{ fontWeight: 600 }} date={hour} />
        </StyledPopup>
      </Marker>
      {((angle_start === 0 && angle_end === 360) || (isNaN(angle_start) && isNaN(angle_end))) && (
        <Circle center={center} pathOptions={{ color: 'green', weight: 1 }} radius={range} />
      )}
      {plotsView.active && plotsDisplay}
      <HeatMap
        id={mainId}
        map={map}
        points={heatPoints}
        heatMapView={heatMapView}
        getHeatMapAction={getHeatMapAction}
        cellId={cellId}
        mcc={mcc}
        mnc={mnc}
        lac={lac}
        isOnDashboard={isOnDashboard}
      />
    </>
  );
};

const StyledPopup = styled(Popup)`
  span {
    font-weight: 600;
  }
`;

export default memo(SingleMarker);
