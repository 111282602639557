import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import history from '../../utils/history';

class AdminRoute extends Component {
  state = {};
  shouldComponentUpdate(nextProps) {
    if (nextProps.user.isAuthorized === false) {
      history.push('/login');
      return false;
    }
    return true;
  }

  render() {
    const { user, component: Components, ...rest } = this.props;
    return (
      <Route
        {...rest}
        render={(props) => {
          if (user.isAuthorized && user.role_id <= 2) return <Components {...props} />;
          else if (user.role_id > 2) history.goBack();
          else if (!user.isAuthorized) history.push('/login');
          return null;
        }}
      />
    );
  }
}

AdminRoute.propTypes = {
  component: PropTypes.shape({}),
  user: PropTypes.shape({
    isAuthorized: PropTypes.bool,
  }).isRequired,
};

const mapStateToProps = (store) => ({
  user: store.user,
});

export default connect(mapStateToProps)(AdminRoute);
