import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Card, Image, Dimmer, Input, Form, Radio } from 'semantic-ui-react';
import { CloseCircleOutlined, DownOutlined, EditOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Dropdown, Menu, Skeleton, Input as AntdInput } from 'antd';
import Truncate from 'react-truncate';

import Terminal from '../../../components/terminal/index';
import PermissionHandler from '../../../utils/permissionsHandler';

import {
  eraseContactInfo,
  setPrimary,
  saveTerminal,
  saveContact,
  addTerminal,
  archiveTerminal,
  deleteTerminal,
  locateContact,
} from '../../../actions/contacts/contact';

import maleAvatar from '../../../assets/images/userMaleAvatar.png';
import femaleAvatar from '../../../assets/images/userFemaleAvatar.png';
import noAvatar from '../../../assets/images/userNoAvatar.png';

import './contactComponent.sass';
import querySourceTypes from '../../../constants/querySourceTypes';
import { searchTypes } from '../../../components/searchComponent/searchComponent';

const { TextArea } = AntdInput;

const emptyNewTerminal = {
  phone: '',
  imsi: '',
  is_primary: 0,
};

class ContactComponent extends Component {
  state = {
    cardLoading: true,
    locateLoading: false,
    editContactHeight: 0,
    addTerminalHeight: 0,
    newTerminal: {
      phone: '',
      imsi: '',
      is_primary: 0,
    },
    contactInfoToChange: {
      name: '',
      alias: '',
      description: '',
      sex: '',
    },
    contactInfo: {
      name: '',
      alias: '',
      description: '',
      terminal: {
        locateSelected: [],
        allowedSelected: [],
        allAllowed: [],
      },
      query_type: 'passive',
    },
    readMore: 3,
    activeSearchType: 1,
    searchByTypeIsPossible: false,
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    let primaryTerminalId = 0;
    const { activeSearchType } = this.state;

    if (nextProps.contactInfo.terminals) {
      nextProps.contactInfo.terminals.map((terminal) => {
        if (terminal.is_primary === 1) primaryTerminalId = terminal.id;
        return null;
      });
      if (nextProps.contactInfo.id) {
        this.setState({
          ...this.state,
          cardLoading: false,
          contactInfoToChange: {
            ...this.state.contactInfoToChange,
            name: nextProps.contactInfo.name,
            alias: !nextProps.contactInfo.alias ? '' : nextProps.contactInfo.alias,
            description: !nextProps.contactInfo.description ? '' : nextProps.contactInfo.description,
            sex: nextProps.contactInfo.sex === null ? '' : nextProps.contactInfo.sex,
          },
          contactInfo: {
            ...this.state.contactInfo,
            name: nextProps.contactInfo.name,
            alias: nextProps.contactInfo.alias,
            description: nextProps.contactInfo.description,
            terminal: {
              ...this.state.terminal,
              locateSelected:
                primaryTerminalId !== 0 && !this.state.contactInfo.terminal.locateSelected.length
                  ? [primaryTerminalId]
                  : this.state.contactInfo.terminal.locateSelected,
              allowedSelected: this.getAllowedSelected(nextProps.contactInfo.terminals, primaryTerminalId),
              allAllowed: this.getAllAllowed(parseInt(activeSearchType, 10), nextProps.contactInfo.terminals),
            },
          },
        });
      }
    }
  }

  getAllowedSelected = (terminals, primaryTerminalId) => {
    const { activeSearchType } = this.state;
    const alreadySelected = [...this.state.contactInfo.terminal.locateSelected];

    const terminalsOb = {};
    terminals.forEach((terminal) => (terminalsOb[terminal.id] = terminal));

    if (!alreadySelected.length) return [primaryTerminalId];

    const result = [];

    alreadySelected.forEach((terminalId) => {
      switch (parseInt(activeSearchType, 10)) {
        case 1:
          if (terminalsOb[terminalId] && (terminalsOb[terminalId].phone || terminalsOb[terminalId].imsi))
            result.push(terminalId);
          break;
        case 4:
        case 6:
        case 8:
          if (terminalsOb[terminalId] && terminalsOb[terminalId].phone) result.push(terminalId);
          break;
        case 5:
        case 7:
        case 10:
          if (terminalsOb[terminalId] && terminalsOb[terminalId].imsi) result.push(terminalId);
          break;
        default:
          break;
      }
    });

    return result;
  };

  getAllAllowed = (key, terminals) => {
    if (!terminals) return [];

    let allAllowed = [];

    terminals.forEach((terminal) => {
      switch (key) {
        case 1:
          if (terminal.phone || terminal.imsi) allAllowed.push(terminal.id);
          break;
        case 4:
        case 6:
        case 8:
          if (terminal.phone) allAllowed.push(terminal.id);
          break;
        case 5:
        case 7:
        case 10:
          if (terminal.imsi) allAllowed.push(terminal.id);
          break;
        default:
          break;
      }
    });

    return allAllowed;
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    // Check the terminals on first load and highlight them for locationSearch
    if (this.props.contactInfo.terminals && !prevProps.contactInfo.terminals) {
      this.handleHighLightOnTerminalsChange();
    }
  }

  componentWillUnmount() {
    this.props.eraseContactInfo();
  }

  clearHighlightedTerminals = (terminals, archivedTerminals) => {
    terminals.forEach((terminal) => {
      terminal.style.color = '#515151';
      terminal.style.background = 'transparent';
      terminal.style.fontWeight = 400;
    });
    archivedTerminals.forEach((terminal) => {
      terminal.style.color = '#515151';
      terminal.style.background = 'transparent';
      terminal.style.fontWeight = 400;
    });
  };

  handleHighLightOnTerminalsChange = () => {
    this.setState({ searchByTypeIsPossible: false });
    const allTerminals = Array.from(this.terminalsRef.getElementsByClassName('terminal-component__contacts_info_data'));
    const allArchivedTerminals = Array.from(
      this.archivedTerminalsRef.getElementsByClassName('terminal-component__contacts_info_data'),
    );
    const phoneTerminals = Array.from(
      this.terminalsRef.getElementsByClassName('terminal-component__contacts_info_data-phone'),
    );
    const imsiTerminals = Array.from(
      this.terminalsRef.getElementsByClassName('terminal-component__contacts_info_data-imsi'),
    );
    const archivedPhoneTerminals = Array.from(
      this.archivedTerminalsRef.getElementsByClassName('terminal-component__contacts_info_data-phone'),
    );
    const archivedImsiTerminals = Array.from(
      this.archivedTerminalsRef.getElementsByClassName('terminal-component__contacts_info_data-imsi'),
    );

    const colorTerminal = (terminal, isArchived) => {
      terminal.style.color = '#515151';
      terminal.style.background = isArchived ? '#dadada' : '#e7e7e7';
      terminal.style.fontWeight = 400;
    };

    switch (this.state.activeSearchType) {
      case 1: {
        if (allTerminals.length) this.setState({ searchByTypeIsPossible: true });
        allTerminals.forEach((terminal) => colorTerminal(terminal));
        allArchivedTerminals.forEach((terminal) => colorTerminal(terminal, true));
        break;
      }
      case 4:
      case 6:
      case 8: {
        this.clearHighlightedTerminals(allTerminals, allArchivedTerminals);
        if (phoneTerminals.length) this.setState({ searchByTypeIsPossible: true });
        phoneTerminals.forEach((terminal) => colorTerminal(terminal));
        archivedPhoneTerminals.forEach((terminal) => colorTerminal(terminal, true));
        break;
      }
      case 5:
      case 7:
      case 10: {
        this.clearHighlightedTerminals(allTerminals, allArchivedTerminals);
        if (imsiTerminals.length) this.setState({ searchByTypeIsPossible: true });
        imsiTerminals.forEach((terminal) => colorTerminal(terminal));
        archivedImsiTerminals.forEach((terminal) => colorTerminal(terminal, true));
        break;
      }
      default:
        break;
    }
  };

  onChangeHandler = (e) => {
    const { state } = this;
    this.setState({
      ...state,
      contactInfoToChange: {
        ...state.contactInfoToChange,
        [e.target.name]: e.target.value,
      },
    });
  };

  setPrimary = (terminalId) => this.props.setPrimary(terminalId).then((res) => res);

  saveContactNewInfo = (contactId) => {
    const info = { ...this.state.contactInfoToChange };
    info.name = info.name.trim();
    info.alias = info.alias.trim();
    this.props.saveContact(contactId, info).then((res) => {
      this.setState({
        ...this.state,
        contactInfo: {
          ...this.state.contactInfo,
          ...res,
        },
      });
      this.toggleEditContact();
    });
  };

  saveTerminal = (terminalId, data) =>
    new Promise((resolve, reject) =>
      this.props
        .saveTerminal(terminalId, data)
        .then((res) => {
          setTimeout(() => this.handleHighLightOnTerminalsChange(), 1000);
          return resolve(res);
        })
        .catch((err) => reject(err)),
    );

  toggleEditContact = () => {
    const { editContactHeight } = this.state;
    if (editContactHeight === 0) this.setState({ editContactHeight: 'auto' });
    else if (editContactHeight !== 0) this.setState({ editContactHeight: 0 });
  };

  toggleAddTerminal = () => {
    const { addTerminalHeight } = this.state;
    if (addTerminalHeight === 0) this.setState({ addTerminalHeight: 150 });
    else if (addTerminalHeight !== 0) {
      this.setState({ addTerminalHeight: 0, newTerminal: emptyNewTerminal });
    }
  };

  saveNewTerminal = (contactId) => {
    this.props.addTerminal(contactId, this.state.newTerminal).then(() => {
      this.setState(
        {
          ...this.state,
          newTerminal: { ...emptyNewTerminal },
        },
        () => {
          setTimeout(() => this.handleHighLightOnTerminalsChange(), 1000);
          this.toggleAddTerminal();
        },
      );
    });
  };

  archiveTerminal = (terminalId) => {
    this.props.archiveTerminal(terminalId);
    const index = this.state.contactInfo.terminal.locateSelected.indexOf(terminalId);
    const newArray = this.state.contactInfo.terminal.locateSelected;
    if (index > -1) {
      newArray.splice(index, 1);
      this.setState({
        contactInfo: {
          ...this.state.contactInfo,
          terminal: {
            ...this.state.contactInfo.terminal,
            locateSelected: newArray,
          },
        },
      });
    }

    setTimeout(() => this.handleHighLightOnTerminalsChange(), 1000);
  };

  deleteTerminal = (terminalId) => {
    this.props.deleteTerminal(terminalId);
  };

  newTerminalOnChange = (e) => {
    const { name } = e.target;
    if (name !== 'is_primary') {
      this.setState({
        ...this.state,
        newTerminal: {
          ...this.state.newTerminal,
          [name]: e.target.value,
        },
      });
    } else {
      this.setState({
        ...this.state,
        newTerminal: {
          ...this.state.newTerminal,
          [name]: e.target.checked === true ? 1 : 0,
        },
      });
    }
  };

  editDescription = (e) => {
    const { state } = this;
    this.setState({
      ...state,
      contactInfoToChange: {
        ...state.contactInfoToChange,
        description: e.target.value,
      },
    });
  };

  locateSelected = (terminalId, action) => {
    const { state } = this;
    const { activeSearchType } = this.state;
    const array = [...state.contactInfo.terminal.locateSelected];

    const { terminals } = this.props.contactInfo;

    const terminalsOb = {};
    const allow = [...this.state.contactInfo.terminal.allowedSelected];
    terminals.forEach((terminal) => (terminalsOb[terminal.id] = terminal));

    if (action === 'add') {
      switch (parseInt(activeSearchType, 10)) {
        case 1:
          if (terminalsOb[terminalId].phone || terminalsOb[terminalId].imsi) allow.push(terminalId);
          break;
        case 4:
        case 6:
        case 8:
          if (terminalsOb[terminalId].phone) allow.push(terminalId);
          break;
        case 5:
        case 7:
        case 10:
          if (terminalsOb[terminalId].imsi) allow.push(terminalId);
          break;
        default:
          break;
      }

      array.push(terminalId);
      this.setState({
        ...state,
        contactInfo: {
          ...state.contactInfo,
          terminal: {
            ...state.contactInfo.terminal,
            locateSelected: array,
            allowedSelected: allow,
          },
        },
      });
    } else if (action === 'remove') {
      const indexAr = array.indexOf(terminalId);
      const indexAl = allow.indexOf(terminalId);
      array.splice(indexAr, 1);
      allow.splice(indexAl, 1);
      this.setState({
        ...state,
        contactInfo: {
          ...state.contactInfo,
          terminal: {
            ...state.contactInfo.terminal,
            locateSelected: array,
            allowedSelected: allow,
          },
        },
      });
    }
  };

  handleChangeSex = (e, { sex }) => this.setState({ contactInfoToChange: { ...this.state.contactInfoToChange, sex } });

  locateContact = () => {
    const { locateSelected, allowedSelected } = this.state.contactInfo.terminal;
    if (locateSelected.length !== 0 && allowedSelected.length !== 0) {
      const hasQueryType = this.state.activeSearchType === 1 || this.state.activeSearchType === 8;
      this.setState({ locateLoading: true });
      const data = {
        terminals: allowedSelected,
        query_type: hasQueryType ? this.state.contactInfo.query_type : 'passive',
        query_source: querySourceTypes.SOURCE_CONTACT,
        api_request_type: this.state.activeSearchType,
      };
      this.props.locateContact(data).then(() => this.setState({ locateLoading: false }));
    }
  };

  returnColor = (index) => {
    const blue = Math.floor(index % 256);
    const green = Math.floor((index / 256) % 256);
    const red = Math.floor((index / 256 / 256) % 256);

    return `rgb(${red},${green},${blue})`;
  };

  readMore = () => {
    if (this.state.readMore === 3) this.setState({ readMore: -1 });
    else this.setState({ readMore: 3 });
  };

  searchTypeChange = (key) => {
    const { locateSelected } = this.state.contactInfo.terminal;
    const { terminals } = this.props.contactInfo;

    const terminalsOb = {};
    terminals.forEach((terminal) => (terminalsOb[terminal.id] = terminal));
    const allow = [];
    let allAllowed = [];

    switch (parseInt(key, 10)) {
      case 1:
        locateSelected.forEach((terminalId) => {
          if (terminalsOb[terminalId].phone || terminalsOb[terminalId].imsi) allow.push(terminalId);
        });
        this.setState({
          contactInfo: {
            ...this.state.contactInfo,
            terminal: {
              ...this.state.contactInfo.terminal,
              allowedSelected: allow,
              allAllowed: terminals.map((terminal) => terminal.id),
            },
          },
        });
        break;
      case 4:
      case 6:
      case 8:
        locateSelected.forEach((terminalId) => {
          if (terminalsOb[terminalId].phone) allow.push(terminalId);
        });
        terminals.forEach((terminal) => terminal.phone && allAllowed.push(terminal.id));
        this.setState({
          contactInfo: {
            ...this.state.contactInfo,
            terminal: { ...this.state.contactInfo.terminal, allowedSelected: allow, allAllowed },
          },
        });
        break;
      case 5:
      case 7:
      case 10:
        locateSelected.forEach((terminalId) => {
          if (terminalsOb[terminalId].imsi) allow.push(terminalId);
        });
        terminals.forEach((terminal) => terminal.imsi && allAllowed.push(terminal.id));
        this.setState({
          contactInfo: {
            ...this.state.contactInfo,
            terminal: { ...this.state.contactInfo.terminal, allowedSelected: allow, allAllowed },
          },
        });
        break;
      default:
        break;
    }
    this.setState({ activeSearchType: parseInt(key, 10) }, () => this.handleHighLightOnTerminalsChange());
  };

  render() {
    const { id } = this.props.contactInfo;
    const { name, alias, description } = this.state.contactInfo;
    const { sex } = this.state.contactInfoToChange;
    let existArchived = false;

    const terminals =
      this.props.contactInfo.terminals &&
      this.props.contactInfo.terminals.map((terminal) => {
        const key = `terminal-${terminal.id}`;
        if (terminal.is_archived !== 1) {
          return (
            <Terminal
              selectedLocations={this.state.contactInfo.terminal.locateSelected}
              allowedSelected={this.state.contactInfo.terminal.allowedSelected}
              allAllowed={this.state.contactInfo.terminal.allAllowed}
              key={key}
              phone={terminal.phone}
              imsi={terminal.imsi}
              id={terminal.id}
              color={this.returnColor(terminal.phone ? terminal.phone : terminal.imsi)}
              locateSelected={this.locateSelected}
              setPrimary={this.setPrimary}
              saveTerminal={this.saveTerminal}
              archiveTerminal={this.archiveTerminal}
              deleteTerminal={this.deleteTerminal}
              isPrimary={terminal.is_primary}
              isArchived={terminal.is_archived}
              isEditable={terminal.is_editable}
              isRemovable={terminal.is_removable}
            />
          );
        }
        return null;
      });

    const archivedTerminals =
      this.props.contactInfo.terminals &&
      this.props.contactInfo.terminals.map((terminal) => {
        const key = `terminal-${terminal.id}`;
        if (terminal.is_archived === 1) {
          existArchived = true;
          return (
            <Terminal
              key={key}
              phone={terminal.phone}
              imsi={terminal.imsi}
              id={terminal.id}
              color={this.returnColor(terminal.phone ? terminal.phone : terminal.imsi)}
              locateSelected={this.locateSelected}
              setPrimary={this.setPrimary}
              saveTerminal={this.saveTerminal}
              archiveTerminal={this.archiveTerminal}
              isPrimary={terminal.is_primary}
              isArchived={terminal.is_archived}
              isEditable={terminal.is_editable}
              isRemovable={terminal.is_removable}
              allowedSelected={this.state.contactInfo.terminal.allowedSelected}
              allAllowed={this.state.contactInfo.terminal.allAllowed}
            />
          );
        }
        return null;
      });

    const searchMenu = (
      <Menu>
        {Object.keys(searchTypes).map((key) => (
          <Menu.Item key={`search-type-${key}`} onClick={() => this.searchTypeChange(key)}>
            {searchTypes[key]}
          </Menu.Item>
        ))}
      </Menu>
    );

    let contactAvatar = null;
    if (this.props.contactInfo.sex === 0) contactAvatar = femaleAvatar;
    else if (this.props.contactInfo.sex === 1) contactAvatar = maleAvatar;
    else if (this.props.contactInfo.sex === null) contactAvatar = noAvatar;

    return (
      <Card className={`contact-component ${this.props.className}`}>
        {this.state.cardLoading ? (
          <Skeleton active loading={this.state.cardLoading} />
        ) : (
          <>
            <Card.Content>
              <Image floated="right" size="mini" src={contactAvatar} />
              <Card.Header>
                <div style={{ position: 'relative', display: 'inline-block' }}>
                  <span>{name}</span>
                </div>
              </Card.Header>
              <Card.Meta>
                <div className="contact-component_meta">
                  Alias: {!alias ? <span className="contact-component_meta --no-meta">{'No alias'}</span> : alias}
                </div>

                <div ref={(ref) => (this.terminalsRef = ref)}>{terminals}</div>
                {existArchived && <hr />}
                <div ref={(ref) => (this.archivedTerminalsRef = ref)}>{archivedTerminals}</div>

                <div style={{ display: 'flex' }}>
                  <PermissionHandler button="contact-edit">
                    <div className="contact-component__edit-contact" onClick={this.toggleEditContact}>
                      <EditOutlined className="contact-component__add-terminal_add-button" />
                      <span className="contact-component__add-terminal_add-header">Edit contact</span>
                    </div>
                  </PermissionHandler>

                  <PermissionHandler button="terminal-create">
                    <div className="contact-component__add-terminal">
                      <div style={{ display: 'flex', alignItems: 'center' }} onClick={this.toggleAddTerminal}>
                        <PlusCircleOutlined className="contact-component__add-terminal_add-button" />
                        <span className="contact-component__add-terminal_add-header">Add terminal</span>
                      </div>
                    </div>
                  </PermissionHandler>

                  {this.state.addTerminalHeight !== 0 && (
                    <div
                      style={{ display: 'flex', cursor: 'pointer', alignItems: 'center' }}
                      onClick={this.toggleAddTerminal}
                    >
                      <CloseCircleOutlined className="contact-component__add-terminal_close-button" />
                      <span className="contact-component__add-terminal_add-header-close">Close</span>
                    </div>
                  )}
                </div>

                <Card.Content
                  extra
                  style={{ height: this.state.addTerminalHeight }}
                  className="contact-component__add-terminal-wrapper"
                >
                  <div className="contact-component__add-terminal_form">
                    <Input
                      className="contact-component_input"
                      size="mini"
                      label="Phone"
                      name="phone"
                      value={this.state.newTerminal.phone}
                      placeholder="0532 234 432"
                      onChange={(e) => this.newTerminalOnChange(e)}
                    />
                    <Input
                      className="contact-component_input"
                      size="mini"
                      label="IMSI"
                      name="imsi"
                      value={this.state.newTerminal.imsi}
                      placeholder="2432253323556743"
                      onChange={(e) => this.newTerminalOnChange(e)}
                    />
                    <div className="contact-component__add-terminal_form__is-primary">
                      <span className="contact-component__add-terminal_add-header">Is Primary</span>
                      <Input
                        name="is_primary"
                        type="checkbox"
                        checked={this.state.newTerminal.is_primary === 1 && true}
                        onChange={(e) => this.newTerminalOnChange(e)}
                      />
                    </div>
                    <Button
                      className="contact-component__add-terminal_form__save"
                      size="mini"
                      onClick={() => this.saveNewTerminal(id)}
                    >
                      Save
                    </Button>
                  </div>
                </Card.Content>
              </Card.Meta>
              <Card.Description>
                {description && (
                  <div>
                    <div className="contact-component_description">Description</div>
                    <Truncate
                      lines={this.state.readMore}
                      ellipsis={
                        <span>
                          ...
                          <span
                            style={{
                              color: '#1890ff',
                              fontSize: 14,
                              cursor: 'pointer',
                              marginLeft: 5,
                              outline: 'none',
                            }}
                            onClick={() => this.readMore()}
                            role="button"
                            tabIndex={0}
                          >
                            Read more
                          </span>
                        </span>
                      }
                    >
                      <span style={{ wordBreak: 'break-word' }}>{description}</span>
                      {this.state.readMore === -1 && (
                        <spans
                          style={{
                            color: '#1890ff',
                            fontSize: 14,
                            cursor: 'pointer',
                            marginLeft: 5,
                            outline: 'none',
                          }}
                          onClick={() => this.readMore()}
                          role="button"
                          tabIndex={0}
                        >
                          Close
                        </spans>
                      )}
                    </Truncate>
                  </div>
                )}
              </Card.Description>
            </Card.Content>
            <Card.Content
              extra
              className="contact-component_extra-content-wrapper"
              style={{ height: this.state.editContactHeight }}
            >
              <div className="contact-component_edit-content">
                <Input
                  className="contact-component_input"
                  size="mini"
                  label="Name"
                  name="name"
                  placeholder={name}
                  value={this.state.contactInfoToChange.name}
                  onChange={(e) => this.onChangeHandler(e)}
                />
                <Input
                  className="contact-component_input"
                  size="mini"
                  label="Alias"
                  name="alias"
                  placeholder={alias}
                  value={this.state.contactInfoToChange.alias}
                  onChange={(e) => this.onChangeHandler(e)}
                />
                <Form>
                  <TextArea
                    className="edit-content-textarea"
                    style={{ fontSize: 13, marginBottom: 10 }}
                    autoheight="true"
                    value={this.state.contactInfoToChange.description}
                    onChange={(e) => this.editDescription(e)}
                    autoSize={{ minRows: 3 }}
                  />
                  <Form.Group inline>
                    <label htmlFor="sss">Gender</label>
                    <Form.Field
                      control={Radio}
                      label="Male"
                      sex={1}
                      checked={parseInt(sex, 10) === 1}
                      onChange={this.handleChangeSex}
                    />
                    <Form.Field
                      control={Radio}
                      label="Female"
                      sex={0}
                      checked={parseInt(sex, 10) === 0}
                      onChange={this.handleChangeSex}
                    />
                  </Form.Group>
                </Form>
                <Button
                  size="mini"
                  onClick={() => this.saveContactNewInfo(id)}
                  disabled={!this.state.contactInfoToChange.name}
                >
                  Save
                </Button>
              </div>
            </Card.Content>
            <PermissionHandler button="locate-create">
              <div className="contact-component__locate-wrapper">
                <Dropdown overlay={searchMenu} trigger={['click']}>
                  <div className="contact-component__locate-dropdown">
                    <DownOutlined />
                  </div>
                </Dropdown>
                <Button
                  className="contact-component__locate-button"
                  disabled={
                    this.state.contactInfo.terminal.locateSelected.length === 0 ||
                    this.state.locateLoading ||
                    !this.state.searchByTypeIsPossible ||
                    this.state.contactInfo.terminal.allowedSelected.length === 0
                  }
                  loading={this.state.locateLoading}
                  onClick={this.locateContact}
                  size="mini"
                >
                  {searchTypes[this.state.activeSearchType]}
                </Button>
                {this.state.activeSearchType === 1 || this.state.activeSearchType === 8 ? (
                  <Button.Group size="mini" className="contact-component__active-passive-group">
                    <Button
                      className="contact-component__active-passive-group_active"
                      onClick={() =>
                        this.setState({ contactInfo: { ...this.state.contactInfo, query_type: 'active' } })
                      }
                      size="mini"
                      positive={this.state.contactInfo.query_type === 'active'}
                    >
                      Active
                    </Button>
                    <Button.Or size="mini" text="or" />
                    <Button
                      className="contact-component__active-passive-group_passive"
                      onClick={() =>
                        this.setState({ contactInfo: { ...this.state.contactInfo, query_type: 'passive' } })
                      }
                      size="mini"
                      positive={this.state.contactInfo.query_type === 'passive'}
                    >
                      Passive
                    </Button>
                  </Button.Group>
                ) : null}
              </div>
            </PermissionHandler>
          </>
        )}
      </Card>
    );
  }
}

ContactComponent.defaultProps = {
  contactInfo: {},
  className: '',
};

ContactComponent.propTypes = {
  className: PropTypes.string,
  eraseContactInfo: PropTypes.func.isRequired,
  setPrimary: PropTypes.func.isRequired,
  saveTerminal: PropTypes.func.isRequired,
  saveContact: PropTypes.func.isRequired,
  addTerminal: PropTypes.func.isRequired,
  locateContact: PropTypes.func.isRequired,
  archiveTerminal: PropTypes.func.isRequired,
  deleteTerminal: PropTypes.func.isRequired,
  contactInfo: PropTypes.shape({
    all_locations: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    name: PropTypes.string,
    alias: PropTypes.string,
    description: PropTypes.string,
    phone: PropTypes.string,
    imsi: PropTypes.string,
    sex: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    terminals: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        phone: PropTypes.number,
        imsi: PropTypes.number,
        is_archived: PropTypes.number,
        is_editable: PropTypes.number,
        is_removable: PropTypes.number,
        is_primary: PropTypes.number,
      }),
    ),
    id: PropTypes.number,
  }),
};

export default connect(null, {
  eraseContactInfo,
  setPrimary,
  saveTerminal,
  saveContact,
  addTerminal,
  archiveTerminal,
  deleteTerminal,
  locateContact,
})(ContactComponent);
