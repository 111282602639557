import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Select, DatePicker, InputNumber, Input, Checkbox, Popconfirm, Tabs } from 'antd';
import { Button } from 'semantic-ui-react';
import moment from 'moment-timezone';

import countrys from '../../utils/country';
import './robotCreation.sass';
import NewMap from '../newMap/newMap';
import checkForNumber from '../../utils/checkForNumber';

const TabPane = Tabs.TabPane;
const Option = Select.Option;
const { RangePicker } = DatePicker;
const { TextArea } = Input;

class RobotComponent extends Component {
  state = {
    timeToShow: [],
    robotOnline: {
      source: 'contact',
      source_mode: 'primary',
      source_id: '',
      terminals: [],
      type: 'online',
      status: 1,
      query_type: 'passive',
      start: null,
      end: null,
      number_of_searches: 1,
      repeat_time: 60,
      description: '',
      name: '',
      country_code: '',
      country_condition: '',
      lat: '',
      long: '',
      distance: 100,
      target_id: '',
      group: 1,
      points: [],
      alert_stop: 1,
      condition: '',
    },
    typeOfSearch: 'standard',
    savedPoints: [],
    showMarker: true,
    country: '',
    loading: false,
    populated: false,
    groupCount: null,
    initialGroupCount: 1,
  };

  componentDidMount() {
    this.insertRobotData(this.props.robotInfo);
    if (this.props.robotInfo.configuration.terminals) {
      this.props.getContactTerminals(this.props.robotInfo.configuration.source_id);
    }
    moment.tz.setDefault(this.props.timeZone);
  }

  insertRobotData = (data) => {
    this.setState({
      robotOnline: {
        ...this.state.robotOnline,
        name: data.name,
        description: data.description,
        source: data.configuration.source,
        source_mode: data.configuration.source_mode,
        source_id: data.configuration.source_id,
        type: data.type,
        status: data.status,
        query_type: data.query_type,
        start: moment.utc(data.start).format('YYYY-MM-DD HH:mm'),
        end: moment.utc(data.end).format('YYYY-MM-DD HH:mm'),
        number_of_searches: data.configuration.number_of_searches || 1,
        repeat_time: data.repeat_time,
        terminals: data.configuration.terminals ? data.configuration.terminals : [],
        points: data.configuration.points ? data.configuration.points : [],
        country_code: data.configuration.country_code,
        country_condition: data.configuration.country_condition,
        target_id: data.configuration.target_id,
        lat: data?.configuration?.point ? data.configuration.point[0] : '',
        long: data?.configuration?.point ? data.configuration.point[1] : '',
        alert_stop: data.configuration.alert_stop,
        distance: data.configuration.distance,
        condition: data.configuration.condition,
        group: data.configuration.group,
      },
      typeOfSearch: data.configuration.number_of_searches ? 'scheduled' : 'standard',
      savedPoints: data.configuration.points ? data.configuration.points : [],
      initialGroupCount: data.configuration.source === 'contact-group' ? data.source?.[0]?.contact_count : 1,
    });
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.populateData.data === true &&
      nextProps.populateData.from === 'contact' &&
      nextProps.populateData.id !== this.state.robotOnline.source_id &&
      this.state.populated === false
    ) {
      this.setState({
        populated: true,
        robotOnline: {
          ...this.state.robotOnline,
          source_id: `${nextProps.populateData.id}`,
        },
      });
    } else if (
      nextProps.populateData.data === true &&
      nextProps.populateData.from === 'group' &&
      nextProps.populateData.id !== this.state.robotOnline.source_id &&
      this.state.populated === false
    ) {
      this.setState({
        populated: true,
        robotOnline: {
          ...this.state.robotOnline,
          source: 'contact-group',
          source_id: `${nextProps.populateData.id}`,
        },
      });
    }
    if (nextProps.robotInfo.id !== this.props.robotInfo.id) {
      this.insertRobotData(nextProps.robotInfo);
    }
  }

  handleChangeName = (value) => this.setState({ robotOnline: { ...this.state.robotOnline, name: value } });

  handleChangeSource = (value) => {
    if (value === 'contact-group' && this.state.robotOnline.source_mode === 'manual') {
      this.setState({
        robotOnline: { ...this.state.robotOnline, source: value, source_mode: 'primary', source_id: '', terminals: [] },
      });
    } else {
      this.setState({ robotOnline: { ...this.state.robotOnline, source: value, source_id: '', terminals: [] } });
    }
  };

  handleChangeSourceMode = (value) => {
    if (this.state.robotOnline.source === 'contact' && this.state.robotOnline.source_id !== '') {
      this.props.getContactTerminals(this.state.robotOnline.source_id);
    }
    this.setState({
      robotOnline: {
        ...this.state.robotOnline,
        source_mode: value,
        terminals: [],
      },
    });
  };

  handleChangeCondition = (value) => this.setState({ robotOnline: { ...this.state.robotOnline, condition: value } });

  handleChangeSourceId = (value) => {
    this.setState(
      {
        robotOnline: { ...this.state.robotOnline, source_id: value, terminals: [] },
      },
      () => {
        if (this.state.robotOnline.source === 'contact-group') {
          this.setState({
            groupCount: this.props.groups[value].contact_count,
            robotOnline: { ...this.state.robotOnline, group: this.props.groups[value].contact_count },
            initialGroupCount: this.props.groups[value].contact_count || 1,
          });
        }
      },
    );
    if (this.state.robotOnline.source === 'contact') {
      this.props.getContactTerminals(value);
    }
  };

  handleChangeTerminals = (value) => this.setState({ robotOnline: { ...this.state.robotOnline, terminals: value } });
  handleChangeQueryType = (value) => this.setState({ robotOnline: { ...this.state.robotOnline, query_type: value } });

  handleChangeTime = (times) => {
    if (times) {
      const time1 = moment(times[0]).tz(this.props.timeZone);
      const time2 = moment(times[1]).tz(this.props.timeZone);
      this.setState({
        timeToShow: [time1, time2],
        robotOnline: {
          ...this.state.robotOnline,
          start: moment(times[0]).utc(0).format('YYYY-MM-DD HH:mm'),
          end: moment(times[1]).utc(0).format('YYYY-MM-DD HH:mm'),
        },
      });
    } else {
      this.setState({
        timeToShow: [],
        robotOnline: {
          ...this.state.robotOnline,
          start: null,
          end: null,
        },
      });
    }
  };

  handleScheduleTimeChange = (time) => {
    if (time) {
      this.setState({
        scheduleTime: time,
        robotOnline: {
          ...this.state.robotOnline,
          start: moment(time).utc(false).format('YYYY-MM-DD HH:mm'),
        },
      });
    } else {
      this.setState({
        scheduleTime: null,
        robotOnline: {
          ...this.state.robotOnline,
          start: null,
        },
      });
    }
  };

  handleChangeRepeatTime = (value) => this.setState({ robotOnline: { ...this.state.robotOnline, repeat_time: value } });
  handleChangeNumberOfChanges = (value) =>
    this.setState({ robotOnline: { ...this.state.robotOnline, number_of_searches: value } });
  handleChangeDescription = (value) =>
    this.setState({ robotOnline: { ...this.state.robotOnline, description: value } });

  handleChangeCountry = (country, iso) =>
    this.setState({ country, robotOnline: { ...this.state.robotOnline, country_code: iso } });

  handleChangeCountryCondition = (value) =>
    this.setState({ robotOnline: { ...this.state.robotOnline, country_condition: value } });

  handleChangeLat = (value) => this.setState({ robotOnline: { ...this.state.robotOnline, lat: value } });

  handleChangeLng = (value) => this.setState({ robotOnline: { ...this.state.robotOnline, long: value } });

  handleChangeDistance = (value) => this.setState({ robotOnline: { ...this.state.robotOnline, distance: value } });

  handleChangeTargetId = (value) => this.setState({ robotOnline: { ...this.state.robotOnline, target_id: value } });

  handleChangeGroup = (value) => this.setState({ robotOnline: { ...this.state.robotOnline, group: value } });

  editRobot = () => {
    const { robotOnline } = this.state;
    this.setState({ loading: true });
    let robotData = {
      repeat_time: robotOnline.repeat_time,
      description: robotOnline.description,
      name: robotOnline.name,
      source: robotOnline.source,
      group: robotOnline.group,
      source_mode: robotOnline.source_mode,
      source_id: robotOnline.source_id,
      terminals: robotOnline.terminals,
      start: moment(robotOnline.start).format('YYYY-MM-DD HH:mm'),
      end: moment(robotOnline.end).format('YYYY-MM-DD HH:mm'),
      status: robotOnline.status,
      query_type: robotOnline.query_type,
    };

    if (this.state.typeOfSearch === 'scheduled') {
      delete robotData.end;
      robotData.number_of_searches = robotOnline.number_of_searches;
    }

    if (this.props.type === 'online') {
      this.props
        .robotEdit(this.props.robotInfo.id, {
          ...robotData,
          alert_stop: robotOnline.alert_stop,
        })
        .then(() => this.setState({ loading: false }))
        .catch(() => this.setState({ loading: false }));
    } else if (this.props.type === 'offline') {
      this.props
        .robotEdit(this.props.robotInfo.id, {
          ...robotData,
          alert_stop: robotOnline.alert_stop,
        })
        .then(() => this.setState({ loading: false }))
        .catch(() => this.setState({ loading: false }));
    } else if (this.props.type === 'country-zone') {
      robotData = {
        ...robotData,
        alert_stop: robotOnline.alert_stop,
        country_code: robotOnline.country_code,
        country_condition: robotOnline.country_condition,
      };
      this.props
        .robotEdit(this.props.robotInfo.id, robotData)
        .then(() => this.setState({ loading: false }))
        .catch(() => this.setState({ loading: false }));
    } else if (this.props.type === 'geo-point') {
      robotData = {
        ...robotData,
        lat: robotOnline.lat,
        long: robotOnline.long,
        distance: robotOnline.distance,
        alert_stop: robotOnline.alert_stop,
        condition: robotOnline.condition,
      };
      this.props
        .robotEdit(this.props.robotInfo.id, robotData)
        .then(() => this.setState({ loading: false }))
        .catch(() => this.setState({ loading: false }));
    } else if (this.props.type === 'point-to-point') {
      robotData = {
        ...robotData,
        distance: robotOnline.distance,
        target_id: robotOnline.target_id,
        alert_stop: robotOnline.alert_stop,
      };
      this.props
        .robotEdit(this.props.robotInfo.id, robotData)
        .then(() => this.setState({ loading: false }))
        .catch(() => this.setState({ loading: false }));
    } else if (this.props.type === 'geo-points') {
      robotData = {
        ...robotData,
        points: robotOnline.points,
        alert_stop: robotOnline.alert_stop,
        condition: robotOnline.condition,
      };
      this.props
        .robotEdit(this.props.robotInfo.id, robotData)
        .then(() => this.setState({ loading: false }))
        .catch(() => this.setState({ loading: false }));
    } else if (this.props.type === 'tracker') {
      this.props
        .robotEdit(this.props.robotInfo.id, { ...robotData })
        .then(() => this.setState({ loading: false }))
        .catch(() => this.setState({ loading: false }));
    }
  };

  savePointEdit = () => {
    const point = {
      lat: parseFloat(this.state.robotOnline.lat),
      long: parseFloat(this.state.robotOnline.long),
      distance: this.state.robotOnline.distance,
    };
    const savedPoints = [...this.state.savedPoints];
    savedPoints.push(point);

    const points = [...this.state.robotOnline.points];
    const keyPoint = {};

    keyPoint.lat = parseFloat(this.state.robotOnline.lat);
    keyPoint.long = parseFloat(this.state.robotOnline.long);
    keyPoint.distance = this.state.robotOnline.distance;
    points.push(keyPoint);

    this.setState({
      robotOnline: {
        ...this.state.robotOnline,
        lat: '',
        long: '',
        distance: 100,
        points,
      },
      showMarker: false,
      savedPoints,
    });
  };

  removePoint = (index) => {
    const savedPoints = [...this.state.savedPoints];
    savedPoints.splice(index, 1);
    this.setState({
      robotOnline: {
        ...this.state.robotOnline,
        points: savedPoints,
      },
      savedPoints,
    });
  };

  getLatLng = (lat, lng) =>
    this.setState({ robotOnline: { ...this.state.robotOnline, lat, long: lng }, showMarker: true });

  searchTypeOnChange = (type) =>
    this.setState({
      typeOfSearch: type,
    });

  render() {
    const { robotOnline } = this.state;
    const contacts =
      Object.values(this.props.contacts).length > 0 &&
      Object.values(this.props.contacts).map((contact) => {
        const key = `contact-${contact.id}`;
        return (
          <Option key={key} value={`${contact.id}`}>
            {contact.name}
          </Option>
        );
      });

    const groups =
      Object.values(this.props.groups).length > 0 &&
      Object.values(this.props.groups).map((group) => {
        const key = `group-${group.id}`;
        return (
          <Option key={key} value={`${group.id}`}>
            {group.name}
          </Option>
        );
      });

    const terminalDisabled = robotOnline.source_mode === 'manual' && robotOnline.source_id !== '';
    let terminals = [];
    if (robotOnline.source === 'contact' && robotOnline.source_id !== '') {
      terminals =
        this.props.terminals.length > 0 &&
        this.props.terminals.map((terminal) => {
          let info = '';
          if (terminal.phone && terminal.imsi) info = `Phone: ${terminal.phone}, IMSI: ${terminal.imsi}`;
          else if (terminal.phone && !terminal.imsi) info = `Phone: ${terminal.phone}`;
          else if (terminal.imsi && !terminal.phone) info = `IMSI: ${terminal.imsi}`;
          return (
            <Option key={terminal.id} value={`${terminal.id}`}>
              {info}
            </Option>
          );
        });
    }

    let countrysOptions = [];
    if (robotOnline.type === 'country-zone') {
      countrysOptions = countrys.map((country, index) => {
        const key = `country-${index}-${country.network}`;
        return (
          <Option key={key} title={country.iso} value={`${country.iso}`}>
            {country.country}
          </Option>
        );
      });
    }

    const points = this.state.savedPoints.map((point, index) => {
      const key = `point-${index}`;
      return (
        <div key={key} className="robot-creation-component__online-robot_points__point">
          <span className="robot-creation-component__online-robot_points__point_number">{index + 1}</span>
          <span
            role="button"
            tabIndex={0}
            className="robot-creation-component__online-robot_points__point_delete"
            onClick={() => this.removePoint(index)}
          >
            X
          </span>
        </div>
      );
    });

    return (
      <div className="robot-creation-component">
        {this.props.type === 'online' && (
          <div className="robot-creation-component__online-robot">
            <div className="robot-creation-component__online-robot_row">
              <div className="robot-creation-component__online-robot_item col-1-2">
                <div className="robot-creation-component__online-robot_label">
                  <span>*</span>Robot name
                </div>
                <Input
                  onChange={(e) => this.handleChangeName(e.target.value)}
                  style={{ width: '100%' }}
                  value={robotOnline.name}
                />
              </div>
              <div className="robot-creation-component__online-robot_item">
                <div className="robot-creation-component__online-robot_label">Stop on result</div>
                <Checkbox
                  style={{ marginTop: 5 }}
                  onChange={(e) =>
                    this.setState({
                      robotOnline: {
                        ...robotOnline,
                        alert_stop: e.target.checked === true ? 1 : 0,
                      },
                    })
                  }
                  checked={robotOnline.alert_stop === 1}
                />
              </div>
            </div>

            <div className="robot-creation-component__online-robot_row">
              <div className="robot-creation-component__online-robot_item col-1-4">
                <div className="robot-creation-component__online-robot_label">Source</div>
                <Select
                  onChange={this.handleChangeSource}
                  defaultValue="contact"
                  style={{ width: '100%' }}
                  value={robotOnline.source}
                >
                  <Option value="contact">Contact</Option>
                  <Option value="contact-group">Contact Group</Option>
                </Select>
              </div>

              <div className="robot-creation-component__online-robot_item col-1-4">
                <div className="robot-creation-component__online-robot_label">Source Mode</div>
                <Select
                  onChange={this.handleChangeSourceMode}
                  defaultValue="primary"
                  style={{ width: '100%' }}
                  value={robotOnline.source_mode}
                >
                  <Option value="primary">Primary</Option>
                  <Option disabled={robotOnline.source === 'contact-group'} value="manual">
                    Manual
                  </Option>
                  <Option value="all">All</Option>
                </Select>
              </div>

              <div className="robot-creation-component__online-robot_item col-1-2">
                <div className="robot-creation-component__online-robot_label">Source ID</div>
                <Select
                  onChange={this.handleChangeSourceId}
                  style={{ width: '100%' }}
                  value={robotOnline.source_id}
                  showSearch
                  filterOption={(input, option) =>
                    option?.props?.children?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0 ||
                    option?.props?.value?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0
                  }
                >
                  {robotOnline.source === 'contact' ? contacts : groups}
                </Select>
              </div>
            </div>

            <div className="robot-creation-component__online-robot_row">
              <div className="robot-creation-component__online-robot_item col-3-4">
                <div className="robot-creation-component__online-robot_label">Terminals</div>
                <Select
                  mode="tags"
                  onChange={this.handleChangeTerminals}
                  disabled={!terminalDisabled}
                  style={{ width: '100%' }}
                  value={terminals ? robotOnline.terminals : []}
                >
                  {terminals}
                </Select>
              </div>

              <div className="robot-creation-component__online-robot_item col-1-4">
                <div className="robot-creation-component__online-robot_label">Query type</div>
                <Select onChange={this.handleChangeQueryType} style={{ width: '100%' }} value={robotOnline.query_type}>
                  <Option value="active">Active</Option>
                  <Option value="passive">Passive</Option>
                </Select>
              </div>
            </div>

            <div className="robot-creation-component__online-robot_row">
              <div className="robot-creation-component__online-robot_item" style={{ width: '100%' }}>
                <Tabs
                  defaultActiveKey="standard"
                  onChange={this.searchTypeOnChange}
                  activeKey={this.state.typeOfSearch}
                >
                  <TabPane tab="Standard Search" key="standard">
                    <div className="gap-align">
                      <div className="col-3-4">
                        <div className="robot-creation-component__online-robot_label">
                          <span>*</span>Start & End Time
                        </div>
                        <RangePicker
                          showTime
                          format="YYYY/MM/DD HH:mm"
                          style={{ width: '100%' }}
                          placeholder={['Start Time', 'End Time']}
                          onChange={this.handleChangeTime}
                          onOk={this.handleChangeTime}
                          defaultValue={[
                            this.props.robotInfo.start
                              ? moment.utc(this.props.robotInfo.start).tz(this.props.timeZone)
                              : null,
                            this.props.robotInfo.end
                              ? moment.utc(this.props.robotInfo.end).tz(this.props.timeZone)
                              : null,
                          ]}
                          disabledDate={(current) => {
                            return current && current <= moment().add(-1, 'day');
                          }}
                        />
                      </div>
                      <div className="robot-creation-component__online-robot_item col-1-4">
                        <div className="robot-creation-component__online-robot_label">
                          Repeat time <span style={{ opacity: 0.5 }}>(min)</span>
                        </div>
                        <InputNumber
                          onChange={this.handleChangeRepeatTime}
                          parser={(value) => value.replace(/[^0-9]/g, '')}
                          style={{ width: '100%' }}
                          min={1}
                          defaultValue={robotOnline.repeat_time || 60}
                          value={robotOnline.repeat_time}
                        />
                      </div>
                    </div>
                  </TabPane>
                  <TabPane tab="Scheduled search" key="scheduled">
                    <div className="gap-align">
                      <div className="col-1-2">
                        <div className="robot-creation-component__online-robot_label">
                          <span>*</span>Initial Search Time
                        </div>
                        <DatePicker
                          showTime
                          format="YYYY/MM/DD HH:mm"
                          style={{ width: '100%' }}
                          placeholder="Schedule Time"
                          onChange={this.handleScheduleTimeChange}
                          onOk={this.handleScheduleTimeChange}
                          defaultValue={moment.utc(this.props.robotInfo.start).tz(this.props.timeZone)}
                          disabledDate={(current) => {
                            return current && current <= moment().add(-1, 'day');
                          }}
                        />
                      </div>
                      <div className="robot-creation-component__online-robot_item col-1-4">
                        <div className="robot-creation-component__online-robot_label">Nr. Of Searches</div>
                        <InputNumber
                          onChange={this.handleChangeNumberOfChanges}
                          parser={(value) => value.replace(/[^0-9]/g, '')}
                          style={{ width: '100%' }}
                          min={1}
                          defaultValue={robotOnline.number_of_searches}
                          value={robotOnline.number_of_searches}
                        />
                      </div>
                      <div className="robot-creation-component__online-robot_item col-1-4">
                        <div className="robot-creation-component__online-robot_label">
                          Repeat time <span style={{ opacity: 0.5 }}>(min)</span>
                        </div>
                        <InputNumber
                          onChange={this.handleChangeRepeatTime}
                          parser={(value) => value.replace(/[^0-9]/g, '')}
                          style={{ width: '100%' }}
                          min={1}
                          defaultValue={robotOnline.repeat_time || 60}
                          value={robotOnline.repeat_time}
                          disabled={!robotOnline.number_of_searches || robotOnline.number_of_searches <= 1}
                        />
                      </div>
                    </div>
                  </TabPane>
                </Tabs>
              </div>
            </div>

            <div className="robot-creation-component__online-robot_row">
              <div className="robot-creation-component__online-robot_item" style={{ width: '100%' }}>
                <div className="robot-creation-component__online-robot_label">Description</div>
                <TextArea
                  style={{ width: '100%', resize: 'none' }}
                  onChange={(e) => this.handleChangeDescription(e.target.value)}
                  value={robotOnline.description}
                  rows={2}
                />
              </div>
            </div>

            <div className="robot-creation-component__online-robot_row" style={{ alignItems: 'flex-end' }}>
              <Popconfirm
                title="Are you sure you want to update this robot?"
                onConfirm={this.editRobot}
                okText="Yes"
                cancelText="No"
                placement="topLeft"
              >
                <Button style={{ height: 'fit-content' }} size="mini" loading={this.state.loading}>
                  Update Robot
                </Button>
              </Popconfirm>
              <div className="robot-creation-component__online-robot_item col-1-4">
                <div className="robot-creation-component__online-robot_label">Status</div>
                <Select
                  onChange={(value) => this.setState({ robotOnline: { ...this.state.robotOnline, status: value } })}
                  defaultValue={1}
                  style={{ width: '100%' }}
                  value={robotOnline.status}
                >
                  <Option value={0}>Inactive</Option>
                  <Option value={1}>Active</Option>
                  <Option disabled value={2}>
                    Success
                  </Option>
                  <Option disabled value={3}>
                    Time end
                  </Option>
                  <Option disabled value={4}>
                    Cannot run
                  </Option>
                </Select>
              </div>
            </div>
          </div>
        )}

        {this.props.type === 'country-zone' && (
          <div className="robot-creation-component__online-robot">
            <div className="robot-creation-component__online-robot_row">
              <div className="robot-creation-component__online-robot_item col-1-2">
                <div className="robot-creation-component__online-robot_label">
                  <span>*</span>Robot name
                </div>
                <Input
                  onChange={(e) => this.handleChangeName(e.target.value)}
                  style={{ width: '100%' }}
                  value={robotOnline.name}
                />
              </div>
              <div className="robot-creation-component__online-robot_item col-1-4">
                <div className="robot-creation-component__online-robot_label">Country</div>
                <Select
                  onSelect={(e, option) => this.handleChangeCountry(e, option.props.title)}
                  showSearch
                  style={{ width: '100%' }}
                  value={this.state.robotOnline.country_code}
                >
                  {countrysOptions}
                </Select>
              </div>
              <div className="robot-creation-component__online-robot_item col-1-4">
                <div className="robot-creation-component__online-robot_label">Country condition</div>
                <Select
                  onChange={this.handleChangeCountryCondition}
                  style={{ width: '100%' }}
                  value={this.state.robotOnline.country_condition}
                >
                  <Option value="enter-into">Enter</Option>
                  <Option value="exit-from">Exit</Option>
                </Select>
              </div>
            </div>

            <div className="robot-creation-component__online-robot_row">
              <div className="robot-creation-component__online-robot_item col-1-4">
                <div className="robot-creation-component__online-robot_label">Source</div>
                <Select
                  onChange={this.handleChangeSource}
                  defaultValue="contact"
                  style={{ width: '100%' }}
                  value={robotOnline.source}
                >
                  <Option value="contact">Contact</Option>
                  <Option value="contact-group">Contact Group</Option>
                </Select>
              </div>

              <div className="robot-creation-component__online-robot_item col-1-4">
                <div className="robot-creation-component__online-robot_label">Source Mode</div>
                <Select
                  onChange={this.handleChangeSourceMode}
                  defaultValue="primary"
                  style={{ width: '100%' }}
                  value={robotOnline.source_mode}
                >
                  <Option value="primary">Primary</Option>
                  <Option disabled={this.state.source === 'contact-group'} value="manual">
                    Manual
                  </Option>
                  <Option value="all">All</Option>
                </Select>
              </div>

              <div className="robot-creation-component__online-robot_item col-1-2">
                <div className="robot-creation-component__online-robot_label">Source ID</div>
                <Select
                  onChange={this.handleChangeSourceId}
                  style={{ width: '100%' }}
                  value={robotOnline.source_id}
                  showSearch
                  filterOption={(input, option) =>
                    option?.props?.children?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0 ||
                    option?.props?.value?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0
                  }
                >
                  {robotOnline.source === 'contact' ? contacts : groups}
                </Select>
              </div>
            </div>

            <div className="robot-creation-component__online-robot_row">
              <div className="robot-creation-component__online-robot_item col-3-4">
                <div className="robot-creation-component__online-robot_label">Terminals</div>
                <Select
                  mode="tags"
                  onChange={this.handleChangeTerminals}
                  disabled={!terminalDisabled}
                  style={{ width: '100%' }}
                  value={terminals ? robotOnline.terminals : []}
                >
                  {terminals}
                </Select>
              </div>

              <div className="robot-creation-component__online-robot_item col-1-4">
                <div className="robot-creation-component__online-robot_label">Query type</div>
                <Select onChange={this.handleChangeQueryType} style={{ width: '100%' }} value={robotOnline.query_type}>
                  <Option value="active">Active</Option>
                  <Option value="passive">Passive</Option>
                </Select>
              </div>
            </div>

            <div className="robot-creation-component__online-robot_row gap-align" style={{ alignItems: 'flex-end' }}>
              <div className="robot-creation-component__online-robot_item col-4-5">
                <Tabs
                  defaultActiveKey="standard"
                  onChange={this.searchTypeOnChange}
                  activeKey={this.state.typeOfSearch}
                >
                  <TabPane tab="Standard Search" key="standard">
                    <div className="gap-align">
                      <div className="col-3-4">
                        <div className="robot-creation-component__online-robot_label">
                          <span>*</span>Start & End Time
                        </div>
                        <RangePicker
                          showTime
                          format="YYYY/MM/DD HH:mm"
                          style={{ width: '100%' }}
                          placeholder={['Start Time', 'End Time']}
                          onChange={this.handleChangeTime}
                          onOk={this.handleChangeTime}
                          defaultValue={[
                            this.props.robotInfo.start
                              ? moment.utc(this.props.robotInfo.start).tz(this.props.timeZone)
                              : null,
                            this.props.robotInfo.end
                              ? moment.utc(this.props.robotInfo.end).tz(this.props.timeZone)
                              : null,
                          ]}
                          disabledDate={(current) => {
                            return current && current <= moment().add(-1, 'day');
                          }}
                        />
                      </div>
                      <div className="robot-creation-component__online-robot_item col-1-4">
                        <div className="robot-creation-component__online-robot_label">
                          Repeat time <span style={{ opacity: 0.5 }}>(min)</span>
                        </div>
                        <InputNumber
                          onChange={this.handleChangeRepeatTime}
                          parser={(value) => value.replace(/[^0-9]/g, '')}
                          style={{ width: '100%' }}
                          min={1}
                          defaultValue={robotOnline.repeat_time || 60}
                          value={robotOnline.repeat_time}
                        />
                      </div>
                    </div>
                  </TabPane>
                  <TabPane tab="Scheduled search" key="scheduled">
                    <div className="gap-align">
                      <div className="col-1-2">
                        <div className="robot-creation-component__online-robot_label">
                          <span>*</span>Initial Search Time
                        </div>
                        <DatePicker
                          showTime
                          format="YYYY/MM/DD HH:mm"
                          style={{ width: '100%' }}
                          placeholder="Schedule Time"
                          value={this.state.scheduleTime}
                          onChange={this.handleScheduleTimeChange}
                          onOk={this.handleScheduleTimeChange}
                          defaultValue={moment.utc(this.props.robotInfo.start).tz(this.props.timeZone)}
                          disabledDate={(current) => {
                            return current && current <= moment().add(-1, 'day');
                          }}
                        />
                      </div>
                      <div className="robot-creation-component__online-robot_item col-1-4">
                        <div className="robot-creation-component__online-robot_label">Nr. Of Searches</div>
                        <InputNumber
                          onChange={this.handleChangeNumberOfChanges}
                          parser={(value) => value.replace(/[^0-9]/g, '')}
                          style={{ width: '100%' }}
                          min={1}
                          defaultValue={robotOnline.number_of_searches}
                          value={robotOnline.number_of_searches}
                        />
                      </div>
                      <div className="robot-creation-component__online-robot_item col-1-4">
                        <div className="robot-creation-component__online-robot_label">
                          Repeat time <span style={{ opacity: 0.5 }}>(min)</span>
                        </div>
                        <InputNumber
                          onChange={this.handleChangeRepeatTime}
                          parser={(value) => value.replace(/[^0-9]/g, '')}
                          style={{ width: '100%' }}
                          min={1}
                          defaultValue={robotOnline.repeat_time || 60}
                          value={robotOnline.repeat_time}
                          disabled={!robotOnline.number_of_searches || robotOnline.number_of_searches <= 1}
                        />
                      </div>
                    </div>
                  </TabPane>
                </Tabs>
              </div>
              <div
                className="robot-creation-component__online-robot_item"
                style={{ width: '20%', marginBottom: '7px' }}
              >
                <div className="robot-creation-component__online-robot_label">Stop on result</div>
                <Checkbox
                  style={{ marginTop: 5 }}
                  onChange={(e) =>
                    this.setState({
                      robotOnline: {
                        ...robotOnline,
                        alert_stop: e.target.checked === true ? 1 : 0,
                      },
                    })
                  }
                  checked={robotOnline.alert_stop === 1}
                />
              </div>
            </div>

            <div className="robot-creation-component__online-robot_row">
              <div className="robot-creation-component__online-robot_item" style={{ width: '100%' }}>
                <div className="robot-creation-component__online-robot_label">Description</div>
                <TextArea
                  style={{ width: '100%', resize: 'none' }}
                  onChange={(e) => this.handleChangeDescription(e.target.value)}
                  rows={2}
                  value={robotOnline.description}
                />
              </div>
            </div>

            <div className="robot-creation-component__online-robot_row" style={{ alignItems: 'flex-end' }}>
              <Popconfirm
                title="Are you sure you want to update this robot?"
                onConfirm={this.editRobot}
                okText="Yes"
                cancelText="No"
                placement="topLeft"
              >
                <Button style={{ height: 'fit-content' }} size="mini" loading={this.state.loading}>
                  Update Robot
                </Button>
              </Popconfirm>
              <div className="robot-creation-component__online-robot_item col-1-4">
                <div className="robot-creation-component__online-robot_label">Status</div>
                <Select
                  onChange={(value) => this.setState({ robotOnline: { ...this.state.robotOnline, status: value } })}
                  defaultValue={1}
                  style={{ width: '100%' }}
                  value={robotOnline.status}
                >
                  <Option value={0}>Inactive</Option>
                  <Option value={1}>Active</Option>
                  <Option disabled value={2}>
                    Success
                  </Option>
                  <Option disabled value={3}>
                    Time end
                  </Option>
                  <Option disabled value={4}>
                    Cannot run
                  </Option>
                </Select>
              </div>
            </div>
          </div>
        )}

        {this.props.type === 'geo-point' && (
          <div className="robot-creation-component__online-robot">
            <div className="robot-creation-component__online-robot_row">
              <div className="robot-creation-component__online-robot_item col-2-5">
                <div className="robot-creation-component__online-robot_label">
                  <span>*</span>Robot name
                </div>
                <Input
                  onChange={(e) => this.handleChangeName(e.target.value)}
                  style={{ width: '100%' }}
                  value={robotOnline.name}
                />
              </div>

              <div className="robot-creation-component__online-robot_item col-1-5">
                <div className="robot-creation-component__online-robot_label">Distance</div>
                <InputNumber
                  onChange={this.handleChangeDistance}
                  style={{ width: '100%' }}
                  parser={(value) => value.replace(/[^0-9]/g, '')}
                  min={100}
                  defaultValue={100}
                  value={robotOnline.distance}
                />
              </div>

              <div className="robot-creation-component__online-robot_item col-1-5">
                <div className="robot-creation-component__online-robot_label">Lat</div>
                <Input
                  style={{ width: '100%' }}
                  onChange={(e) => (checkForNumber(e.target.value) ? '' : this.handleChangeLat(e.target.value))}
                  value={robotOnline.lat}
                />
              </div>

              <div className="robot-creation-component__online-robot_item col-1-5">
                <div className="robot-creation-component__online-robot_label">Lng</div>
                <Input
                  style={{ width: '100%' }}
                  onChange={(e) => (checkForNumber(e.target.value) ? '' : this.handleChangeLng(e.target.value))}
                  value={robotOnline.long}
                />
              </div>
            </div>

            <div className="robot-creation-component__online-robot_row">
              <div className="robot-creation-component__online-robot_item col-1-5">
                <div className="robot-creation-component__online-robot_label">Source</div>
                <Select
                  onChange={this.handleChangeSource}
                  defaultValue="contact"
                  style={{ width: '100%' }}
                  value={robotOnline.source}
                >
                  <Option value="contact">Contact</Option>
                  <Option value="contact-group">Contact Group</Option>
                </Select>
              </div>

              <div className="robot-creation-component__online-robot_item col-1-5">
                <div className="robot-creation-component__online-robot_label">Source Mode</div>
                <Select
                  onChange={this.handleChangeSourceMode}
                  defaultValue="primary"
                  style={{ width: '100%' }}
                  value={robotOnline.source_mode}
                >
                  <Option value="primary">Primary</Option>
                  <Option disabled={robotOnline.source === 'contact-group'} value="manual">
                    Manual
                  </Option>
                  <Option value="all">All</Option>
                </Select>
              </div>

              <div className="robot-creation-component__online-robot_item col-2-5">
                <div className="robot-creation-component__online-robot_label">Source ID</div>
                <Select
                  onChange={this.handleChangeSourceId}
                  style={{ width: '100%' }}
                  value={robotOnline.source_id}
                  showSearch
                  filterOption={(input, option) =>
                    option?.props?.children?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0 ||
                    option?.props?.value?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0
                  }
                >
                  {robotOnline.source === 'contact' ? contacts : groups}
                </Select>
              </div>
              <div className="robot-creation-component__online-robot_item col-1-5">
                <div className="robot-creation-component__online-robot_label">Condition</div>
                <Select onChange={this.handleChangeCondition} style={{ width: '100%' }} value={robotOnline.condition}>
                  <Option value="exit-from">Exit from</Option>
                  <Option value="enter-into">Enter into</Option>
                </Select>
              </div>
            </div>

            <div className="robot-creation-component__online-robot_row">
              <div className="robot-creation-component__online-robot_item col-3-4">
                <div className="robot-creation-component__online-robot_label">Terminals</div>
                <Select
                  mode="tags"
                  onChange={this.handleChangeTerminals}
                  disabled={!terminalDisabled}
                  style={{ width: '100%' }}
                  value={terminals ? robotOnline.terminals : []}
                >
                  {terminals}
                </Select>
              </div>

              <div className="robot-creation-component__online-robot_item col-1-4">
                <div className="robot-creation-component__online-robot_label">Query type</div>
                <Select onChange={this.handleChangeQueryType} style={{ width: '100%' }} value={robotOnline.query_type}>
                  <Option value="active">Active</Option>
                  <Option value="passive">Passive</Option>
                </Select>
              </div>
            </div>

            <div className="robot-creation-component__online-robot_row gap-align" style={{ alignItems: 'flex-end' }}>
              <div className="robot-creation-component__online-robot_item col-4-5">
                <Tabs
                  defaultActiveKey="standard"
                  onChange={this.searchTypeOnChange}
                  activeKey={this.state.typeOfSearch}
                >
                  <TabPane tab="Standard Search" key="standard">
                    <div className="gap-align">
                      <div className="col-3-4">
                        <div className="robot-creation-component__online-robot_label">
                          <span>*</span>Start & End Time
                        </div>
                        <RangePicker
                          showTime
                          format="YYYY/MM/DD HH:mm"
                          style={{ width: '100%' }}
                          placeholder={['Start Time', 'End Time']}
                          onChange={this.handleChangeTime}
                          onOk={this.handleChangeTime}
                          defaultValue={[
                            this.props.robotInfo.start
                              ? moment.utc(this.props.robotInfo.start).tz(this.props.timeZone)
                              : null,
                            this.props.robotInfo.end
                              ? moment.utc(this.props.robotInfo.end).tz(this.props.timeZone)
                              : null,
                          ]}
                          disabledDate={(current) => {
                            return current && current <= moment().add(-1, 'day');
                          }}
                        />
                      </div>
                      <div className="robot-creation-component__online-robot_item col-1-4">
                        <div className="robot-creation-component__online-robot_label">
                          Repeat time <span style={{ opacity: 0.5 }}>(min)</span>
                        </div>
                        <InputNumber
                          onChange={this.handleChangeRepeatTime}
                          parser={(value) => value.replace(/[^0-9]/g, '')}
                          style={{ width: '100%' }}
                          min={1}
                          defaultValue={robotOnline.repeat_time || 60}
                          value={robotOnline.repeat_time}
                        />
                      </div>
                    </div>
                  </TabPane>
                  <TabPane tab="Scheduled search" key="scheduled">
                    <div className="gap-align">
                      <div className="col-1-2">
                        <div className="robot-creation-component__online-robot_label">
                          <span>*</span>Initial Search Time
                        </div>
                        <DatePicker
                          showTime
                          format="YYYY/MM/DD HH:mm"
                          style={{ width: '100%' }}
                          placeholder="Schedule Time"
                          value={this.state.scheduleTime}
                          onChange={this.handleScheduleTimeChange}
                          onOk={this.handleScheduleTimeChange}
                          defaultValue={moment.utc(this.props.robotInfo.start).tz(this.props.timeZone)}
                          disabledDate={(current) => {
                            return current && current <= moment().add(-1, 'day');
                          }}
                        />
                      </div>
                      <div className="robot-creation-component__online-robot_item col-1-4">
                        <div className="robot-creation-component__online-robot_label">Nr. Of Searches</div>
                        <InputNumber
                          onChange={this.handleChangeNumberOfChanges}
                          parser={(value) => value.replace(/[^0-9]/g, '')}
                          style={{ width: '100%' }}
                          min={1}
                          defaultValue={robotOnline.number_of_searches}
                          value={robotOnline.number_of_searches}
                        />
                      </div>
                      <div className="robot-creation-component__online-robot_item col-1-4">
                        <div className="robot-creation-component__online-robot_label">
                          Repeat time <span style={{ opacity: 0.5 }}>(min)</span>
                        </div>
                        <InputNumber
                          onChange={this.handleChangeRepeatTime}
                          parser={(value) => value.replace(/[^0-9]/g, '')}
                          style={{ width: '100%' }}
                          min={1}
                          defaultValue={robotOnline.repeat_time || 60}
                          value={robotOnline.repeat_time}
                          disabled={!robotOnline.number_of_searches || robotOnline.number_of_searches <= 1}
                        />
                      </div>
                    </div>
                  </TabPane>
                </Tabs>
              </div>
              <div
                className="robot-creation-component__online-robot_item"
                style={{ width: '20%', marginBottom: '7px' }}
              >
                <div className="robot-creation-component__online-robot_label">Stop on result</div>
                <Checkbox
                  style={{ marginTop: 5 }}
                  onChange={(e) =>
                    this.setState({
                      robotOnline: {
                        ...robotOnline,
                        alert_stop: e.target.checked === true ? 1 : 0,
                      },
                    })
                  }
                  checked={robotOnline.alert_stop === 1}
                />
              </div>
            </div>

            <div className="robot-creation-component__online-robot_row">
              <div className="robot-creation-component__online-robot_item" style={{ width: '100%' }}>
                <div className="robot-creation-component__online-robot_label">Description</div>
                <TextArea
                  style={{ width: '100%', resize: 'none' }}
                  onChange={(e) => this.handleChangeDescription(e.target.value)}
                  rows={2}
                  value={robotOnline.description}
                />
              </div>
            </div>

            <div className="robot-creation-component__online-robot_row" style={{ alignItems: 'flex-end' }}>
              <Popconfirm
                title="Are you sure you want to update this robot?"
                onConfirm={this.editRobot}
                okText="Yes"
                cancelText="No"
                placement="topLeft"
              >
                <Button style={{ height: 'fit-content' }} size="mini" loading={this.state.loading}>
                  Update Robot
                </Button>
              </Popconfirm>
              <div className="robot-creation-component__online-robot_item col-1-4">
                <div className="robot-creation-component__online-robot_label">Status</div>
                <Select
                  onChange={(value) => this.setState({ robotOnline: { ...this.state.robotOnline, status: value } })}
                  defaultValue={1}
                  style={{ width: '100%' }}
                  value={robotOnline.status}
                >
                  <Option value={0}>Inactive</Option>
                  <Option value={1}>Active</Option>
                  <Option disabled value={2}>
                    Success
                  </Option>
                  <Option disabled value={3}>
                    Time end
                  </Option>
                  <Option disabled value={4}>
                    Cannot run
                  </Option>
                </Select>
              </div>
            </div>

            <div className="robot-creation-component__online-robot_map">
              <NewMap
                mapInfo={{
                  markers: [robotOnline.lat, robotOnline.long],
                  type: 'geo-point-map',
                  zoom: 1,
                  range: robotOnline.distance,
                }}
                getLatLng={this.getLatLng}
              />
            </div>
          </div>
        )}

        {this.props.type === 'point-to-point' && (
          <div className="robot-creation-component__online-robot">
            <div className="robot-creation-component__online-robot_row">
              <div className="robot-creation-component__online-robot_item col-1-2">
                <div className="robot-creation-component__online-robot_label">
                  <span>*</span>Robot name
                </div>
                <Input
                  onChange={(e) => this.handleChangeName(e.target.value)}
                  style={{ width: '100%' }}
                  value={robotOnline.name}
                />
              </div>
              <div className="robot-creation-component__online-robot_item col-1-4">
                <div className="robot-creation-component__online-robot_label">Distance</div>
                <InputNumber
                  onChange={this.handleChangeDistance}
                  style={{ width: '100%' }}
                  parser={(value) => value.replace(/[^0-9]/g, '')}
                  min={100}
                  defaultValue={100}
                  value={robotOnline.distance}
                />
              </div>
              <div className="robot-creation-component__online-robot_item col-1-4">
                <div className="robot-creation-component__online-robot_label">Target ID</div>
                <Select onChange={this.handleChangeTargetId} style={{ width: '100%' }} value={robotOnline.target_id}>
                  {contacts}
                </Select>
              </div>
            </div>

            <div className="robot-creation-component__online-robot_row">
              <div className="robot-creation-component__online-robot_item col-1-2">
                <div className="robot-creation-component__online-robot_label">Source</div>
                <Select
                  onChange={this.handleChangeSource}
                  defaultValue="contact"
                  style={{ width: '100%' }}
                  value={robotOnline.source}
                >
                  <Option value="contact">Contact</Option>
                  <Option value="contact-group">Contact Group</Option>
                </Select>
              </div>

              <div className="robot-creation-component__online-robot_item col-1-4">
                <div className="robot-creation-component__online-robot_label">Source Mode</div>
                <Select
                  onChange={this.handleChangeSourceMode}
                  defaultValue="primary"
                  style={{ width: '100%' }}
                  value={robotOnline.source_mode}
                >
                  <Option value="primary">Primary</Option>
                  <Option disabled={robotOnline.source === 'contact-group'} value="manual">
                    Manual
                  </Option>
                  <Option value="all">All</Option>
                </Select>
              </div>

              <div className="robot-creation-component__online-robot_item col-1-4">
                <div className="robot-creation-component__online-robot_label">Source ID</div>
                <Select
                  onChange={this.handleChangeSourceId}
                  style={{ width: '100%' }}
                  value={robotOnline.source_id}
                  showSearch
                  filterOption={(input, option) =>
                    option?.props?.children?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0 ||
                    option?.props?.value?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0
                  }
                >
                  {robotOnline.source === 'contact' ? contacts : groups}
                </Select>
              </div>
            </div>

            <div className="robot-creation-component__online-robot_row">
              <div className="robot-creation-component__online-robot_item col-3-4">
                <div className="robot-creation-component__online-robot_label">Terminals</div>
                <Select
                  mode="tags"
                  onChange={this.handleChangeTerminals}
                  disabled={!terminalDisabled}
                  style={{ width: '100%' }}
                  value={terminals ? robotOnline.terminals : []}
                >
                  {terminals}
                </Select>
              </div>

              <div className="robot-creation-component__online-robot_item col-1-4">
                <div className="robot-creation-component__online-robot_label">Query type</div>
                <Select onChange={this.handleChangeQueryType} style={{ width: '100%' }} value={robotOnline.query_type}>
                  <Option value="active">Active</Option>
                  <Option value="passive">Passive</Option>
                </Select>
              </div>
            </div>

            <div className="robot-creation-component__online-robot_row gap-align" style={{ alignItems: 'flex-end' }}>
              <div className="robot-creation-component__online-robot_item col-4-5">
                <Tabs
                  defaultActiveKey="standard"
                  onChange={this.searchTypeOnChange}
                  activeKey={this.state.typeOfSearch}
                >
                  <TabPane tab="Standard Search" key="standard">
                    <div className="gap-align">
                      <div className="col-3-4">
                        <div className="robot-creation-component__online-robot_label">
                          <span>*</span>Start & End Time
                        </div>
                        <RangePicker
                          showTime
                          format="YYYY/MM/DD HH:mm"
                          style={{ width: '100%' }}
                          placeholder={['Start Time', 'End Time']}
                          onChange={this.handleChangeTime}
                          onOk={this.handleChangeTime}
                          defaultValue={[
                            this.props.robotInfo.start
                              ? moment.utc(this.props.robotInfo.start).tz(this.props.timeZone)
                              : null,
                            this.props.robotInfo.end
                              ? moment.utc(this.props.robotInfo.end).tz(this.props.timeZone)
                              : null,
                          ]}
                          disabledDate={(current) => {
                            return current && current <= moment().add(-1, 'day');
                          }}
                        />
                      </div>
                      <div className="robot-creation-component__online-robot_item col-1-4">
                        <div className="robot-creation-component__online-robot_label">
                          Repeat time <span style={{ opacity: 0.5 }}>(min)</span>
                        </div>
                        <InputNumber
                          onChange={this.handleChangeRepeatTime}
                          parser={(value) => value.replace(/[^0-9]/g, '')}
                          style={{ width: '100%' }}
                          min={1}
                          defaultValue={robotOnline.repeat_time || 60}
                          value={robotOnline.repeat_time}
                        />
                      </div>
                    </div>
                  </TabPane>
                  <TabPane tab="Scheduled search" key="scheduled">
                    <div className="gap-align">
                      <div className="col-1-2">
                        <div className="robot-creation-component__online-robot_label">
                          <span>*</span>Initial Search Time
                        </div>
                        <DatePicker
                          showTime
                          format="YYYY/MM/DD HH:mm"
                          style={{ width: '100%' }}
                          placeholder="Schedule Time"
                          value={this.state.scheduleTime}
                          onChange={this.handleScheduleTimeChange}
                          onOk={this.handleScheduleTimeChange}
                          defaultValue={moment.utc(this.props.robotInfo.start).tz(this.props.timeZone)}
                          disabledDate={(current) => {
                            return current && current <= moment().add(-1, 'day');
                          }}
                        />
                      </div>
                      <div className="robot-creation-component__online-robot_item col-1-4">
                        <div className="robot-creation-component__online-robot_label">Nr. Of Searches</div>
                        <InputNumber
                          onChange={this.handleChangeNumberOfChanges}
                          parser={(value) => value.replace(/[^0-9]/g, '')}
                          style={{ width: '100%' }}
                          min={1}
                          defaultValue={robotOnline.number_of_searches}
                          value={robotOnline.number_of_searches}
                        />
                      </div>
                      <div className="robot-creation-component__online-robot_item col-1-4">
                        <div className="robot-creation-component__online-robot_label">
                          Repeat time <span style={{ opacity: 0.5 }}>(min)</span>
                        </div>
                        <InputNumber
                          onChange={this.handleChangeRepeatTime}
                          parser={(value) => value.replace(/[^0-9]/g, '')}
                          style={{ width: '100%' }}
                          min={1}
                          defaultValue={robotOnline.repeat_time || 60}
                          value={robotOnline.repeat_time}
                          disabled={!robotOnline.number_of_searches || robotOnline.number_of_searches <= 1}
                        />
                      </div>
                    </div>
                  </TabPane>
                </Tabs>
              </div>
              <div
                className="robot-creation-component__online-robot_item"
                style={{ width: '20%', marginBottom: '7px' }}
              >
                <div className="robot-creation-component__online-robot_label">Stop on result</div>
                <Checkbox
                  style={{ marginTop: 5 }}
                  onChange={(e) =>
                    this.setState({
                      robotOnline: {
                        ...robotOnline,
                        alert_stop: e.target.checked === true ? 1 : 0,
                      },
                    })
                  }
                  checked={robotOnline.alert_stop === 1}
                />
              </div>
            </div>

            <div className="robot-creation-component__online-robot_row">
              <div className="robot-creation-component__online-robot_item" style={{ width: '100%' }}>
                <div className="robot-creation-component__online-robot_label">Description</div>
                <TextArea
                  style={{ width: '100%', resize: 'none' }}
                  onChange={(e) => this.handleChangeDescription(e.target.value)}
                  rows={2}
                  value={robotOnline.description}
                />
              </div>
            </div>

            <div className="robot-creation-component__online-robot_row" style={{ alignItems: 'flex-end' }}>
              <Popconfirm
                title="Are you sure you want to update this robot?"
                onConfirm={this.editRobot}
                okText="Yes"
                cancelText="No"
                placement="topLeft"
              >
                <Button style={{ height: 'fit-content' }} size="mini" loading={this.state.loading}>
                  Update Robot
                </Button>
              </Popconfirm>
              <div className="robot-creation-component__online-robot_item col-1-4">
                <div className="robot-creation-component__online-robot_label">Status</div>
                <Select
                  onChange={(value) => this.setState({ robotOnline: { ...this.state.robotOnline, status: value } })}
                  defaultValue={1}
                  style={{ width: '100%' }}
                  value={robotOnline.status}
                >
                  <Option value={0}>Inactive</Option>
                  <Option value={1}>Active</Option>
                  <Option disabled value={2}>
                    Success
                  </Option>
                  <Option disabled value={3}>
                    Time end
                  </Option>
                  <Option disabled value={4}>
                    Cannot run
                  </Option>
                </Select>
              </div>
            </div>
          </div>
        )}

        {this.props.type === 'geo-points' && (
          <div className="robot-creation-component__online-robot">
            <div className="robot-creation-component__online-robot_row">
              <div className="robot-creation-component__online-robot_item col-1-2">
                <div className="robot-creation-component__online-robot_label">
                  <span>*</span>Robot name
                </div>
                <Input
                  onChange={(e) => this.handleChangeName(e.target.value)}
                  style={{ width: '100%' }}
                  value={robotOnline.name}
                />
              </div>
              <div className="robot-creation-component__online-robot_item col-1-2">
                <div className="robot-creation-component__online-robot_points">{points}</div>
              </div>
            </div>

            <div className="robot-creation-component__online-robot_row robot-creation-component__geo-points">
              <div className="robot-creation-component__online-robot_item col-1-4">
                <div className="robot-creation-component__online-robot_label">Distance</div>
                <InputNumber
                  onChange={this.handleChangeDistance}
                  style={{ width: '100%' }}
                  parser={(value) => value.replace(/[^0-9]/g, '')}
                  min={100}
                  defaultValue={100}
                  value={robotOnline.distance}
                />
              </div>

              <div className="robot-creation-component__online-robot_item col-1-4">
                <div className="robot-creation-component__online-robot_label">Lat</div>
                <Input
                  style={{ width: '100%' }}
                  onChange={(e) => (checkForNumber(e.target.value) ? '' : this.handleChangeLat(e.target.value))}
                  value={robotOnline.lat}
                />
              </div>

              <div className="robot-creation-component__online-robot_item col-1-4">
                <div className="robot-creation-component__online-robot_label">Lng</div>
                <Input
                  style={{ width: '100%' }}
                  onChange={(e) => (checkForNumber(e.target.value) ? '' : this.handleChangeLng(e.target.value))}
                  value={robotOnline.long}
                />
              </div>
              <Button
                disabled={robotOnline.lat === '' && robotOnline.long === '' ? !!1 : !!0}
                className="robot-creation-component__geo-points_button col-1-4"
                size="mini"
                onClick={this.savePointEdit}
              >
                Save Point
              </Button>
            </div>

            <div className="robot-creation-component__online-robot_row">
              <div className="robot-creation-component__online-robot_item col-1-4">
                <div className="robot-creation-component__online-robot_label">Source</div>
                <Select
                  onChange={this.handleChangeSource}
                  defaultValue="contact"
                  style={{ width: '100%' }}
                  value={robotOnline.source}
                >
                  <Option value="contact">Contact</Option>
                  <Option value="contact-group">Contact Group</Option>
                </Select>
              </div>

              <div className="robot-creation-component__online-robot_item col-1-4">
                <div className="robot-creation-component__online-robot_label">Source Mode</div>
                <Select
                  onChange={this.handleChangeSourceMode}
                  defaultValue="primary"
                  style={{ width: '100%' }}
                  value={robotOnline.source_mode}
                >
                  <Option value="primary">Primary</Option>
                  <Option disabled={robotOnline.source === 'contact-group'} value="manual">
                    Manual
                  </Option>
                  <Option value="all">All</Option>
                </Select>
              </div>

              <div className="robot-creation-component__online-robot_item col-1-4">
                <div className="robot-creation-component__online-robot_label">Source ID</div>
                <Select
                  onChange={this.handleChangeSourceId}
                  style={{ width: '100%' }}
                  value={robotOnline.source_id}
                  showSearch
                  filterOption={(input, option) =>
                    option?.props?.children?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0 ||
                    option?.props?.value?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0
                  }
                >
                  {robotOnline.source === 'contact' ? contacts : groups}
                </Select>
              </div>

              {/*{this.state.robotOnline.source === 'contact-group' && this.state.robotOnline.source_id !== '' && (*/}
              {/*  <div className="robot-creation-component__online-robot_item" style={{ width: '25%' }}>*/}
              {/*    <div className="robot-creation-component__online-robot_label">Contacts</div>*/}
              {/*    <Input*/}
              {/*      addonAfter={`/${this.state.groupCount}`}*/}
              {/*      value={robotOnline.group}*/}
              {/*      style={{ width: '100%' }}*/}
              {/*      onChange={(e) => this.handleChangeGroup(e.target.value)}*/}
              {/*    />*/}
              {/*  </div>*/}
              {/*)}*/}
              {this.state.robotOnline.source === 'contact-group' && this.state.robotOnline.source_id !== '' && (
                <div className="robot-creation-component__online-robot_item col-1-4">
                  <div className="robot-creation-component__online-robot_label">Contacts</div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <InputNumber
                      value={robotOnline.group}
                      max={this.state.initialGroupCount}
                      min={1}
                      onChange={this.handleChangeGroup}
                      style={{
                        width: '70%',
                      }}
                    />
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        width: '30%',
                        fontSize: '14px',
                        padding: '7px',
                        background: '#ededed',
                        borderRadius: '2px',
                        marginLeft: '2px',
                        height: '32px',
                        border: '1px solid #d9d9d9',
                      }}
                    >
                      /{this.state.initialGroupCount}
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className="robot-creation-component__online-robot_row">
              <div className="robot-creation-component__online-robot_item col-1-2">
                <div className="robot-creation-component__online-robot_label">Terminals</div>
                <Select
                  mode="tags"
                  onChange={this.handleChangeTerminals}
                  disabled={!terminalDisabled}
                  style={{ width: '100%' }}
                  value={terminals ? robotOnline.terminals : []}
                >
                  {terminals}
                </Select>
              </div>

              <div className="robot-creation-component__online-robot_item col-1-4">
                <div className="robot-creation-component__online-robot_label">Query type</div>
                <Select onChange={this.handleChangeQueryType} style={{ width: '100%' }} value={robotOnline.query_type}>
                  <Option value="active">Active</Option>
                  <Option value="passive">Passive</Option>
                </Select>
              </div>

              <div className="robot-creation-component__online-robot_item col-1-4">
                <div className="robot-creation-component__online-robot_label">Condition</div>
                <Select onChange={this.handleChangeCondition} style={{ width: '100%' }} value={robotOnline.condition}>
                  <Option value="exit-from">Exit from</Option>
                  <Option value="enter-into">Enter into</Option>
                </Select>
              </div>
            </div>

            <div className="robot-creation-component__online-robot_row gap-align" style={{ alignItems: 'flex-end' }}>
              <div className="robot-creation-component__online-robot_item col-4-5">
                <Tabs
                  defaultActiveKey="standard"
                  onChange={this.searchTypeOnChange}
                  activeKey={this.state.typeOfSearch}
                >
                  <TabPane tab="Standard Search" key="standard">
                    <div className="gap-align">
                      <div className="col-3-4">
                        <div className="robot-creation-component__online-robot_label">
                          <span>*</span>Start & End Time
                        </div>
                        <RangePicker
                          showTime
                          format="YYYY/MM/DD HH:mm"
                          style={{ width: '100%' }}
                          placeholder={['Start Time', 'End Time']}
                          onChange={this.handleChangeTime}
                          onOk={this.handleChangeTime}
                          defaultValue={[
                            this.props.robotInfo.start
                              ? moment.utc(this.props.robotInfo.start).tz(this.props.timeZone)
                              : null,
                            this.props.robotInfo.end
                              ? moment.utc(this.props.robotInfo.end).tz(this.props.timeZone)
                              : null,
                          ]}
                          disabledDate={(current) => {
                            return current && current <= moment().add(-1, 'day');
                          }}
                        />
                      </div>
                      <div className="robot-creation-component__online-robot_item col-1-4">
                        <div className="robot-creation-component__online-robot_label">
                          Repeat time <span style={{ opacity: 0.5 }}>(min)</span>
                        </div>
                        <InputNumber
                          onChange={this.handleChangeRepeatTime}
                          parser={(value) => value.replace(/[^0-9]/g, '')}
                          style={{ width: '100%' }}
                          min={1}
                          defaultValue={robotOnline.repeat_time || 60}
                          value={robotOnline.repeat_time}
                        />
                      </div>
                    </div>
                  </TabPane>
                  <TabPane tab="Scheduled search" key="scheduled">
                    <div className="gap-align">
                      <div className="col-1-2">
                        <div className="robot-creation-component__online-robot_label">
                          <span>*</span>Initial Search Time
                        </div>
                        <DatePicker
                          showTime
                          format="YYYY/MM/DD HH:mm"
                          style={{ width: '100%' }}
                          placeholder="Schedule Time"
                          value={this.state.scheduleTime}
                          onChange={this.handleScheduleTimeChange}
                          onOk={this.handleScheduleTimeChange}
                          defaultValue={moment.utc(this.props.robotInfo.start).tz(this.props.timeZone)}
                          disabledDate={(current) => {
                            return current && current <= moment().add(-1, 'day');
                          }}
                        />
                      </div>
                      <div className="robot-creation-component__online-robot_item col-1-4">
                        <div className="robot-creation-component__online-robot_label">Nr. Of Searches</div>
                        <InputNumber
                          onChange={this.handleChangeNumberOfChanges}
                          parser={(value) => value.replace(/[^0-9]/g, '')}
                          style={{ width: '100%' }}
                          min={1}
                          defaultValue={robotOnline.number_of_searches}
                          value={robotOnline.number_of_searches}
                        />
                      </div>
                      <div className="robot-creation-component__online-robot_item col-1-4">
                        <div className="robot-creation-component__online-robot_label">
                          Repeat time <span style={{ opacity: 0.5 }}>(min)</span>
                        </div>
                        <InputNumber
                          onChange={this.handleChangeRepeatTime}
                          parser={(value) => value.replace(/[^0-9]/g, '')}
                          style={{ width: '100%' }}
                          min={1}
                          defaultValue={robotOnline.repeat_time || 60}
                          value={robotOnline.repeat_time}
                          disabled={!robotOnline.number_of_searches || robotOnline.number_of_searches <= 1}
                        />
                      </div>
                    </div>
                  </TabPane>
                </Tabs>
              </div>
              <div
                className="robot-creation-component__online-robot_item"
                style={{ width: '20%', marginBottom: '7px' }}
              >
                <div className="robot-creation-component__online-robot_label">Stop on result</div>
                <Checkbox
                  style={{ marginTop: 5 }}
                  onChange={(e) =>
                    this.setState({
                      robotOnline: {
                        ...robotOnline,
                        alert_stop: e.target.checked === true ? 1 : 0,
                      },
                    })
                  }
                  checked={robotOnline.alert_stop === 1}
                />
              </div>
            </div>

            <div className="robot-creation-component__online-robot_row">
              <div className="robot-creation-component__online-robot_item" style={{ width: '100%' }}>
                <div className="robot-creation-component__online-robot_label">Description</div>
                <TextArea
                  style={{ width: '100%', resize: 'none' }}
                  onChange={(e) => this.handleChangeDescription(e.target.value)}
                  rows={2}
                  value={robotOnline.description}
                />
              </div>
            </div>

            <div className="robot-creation-component__online-robot_row" style={{ alignItems: 'flex-end' }}>
              <Popconfirm
                title="Are you sure you want to update this robot?"
                onConfirm={this.editRobot}
                okText="Yes"
                cancelText="No"
                placement="topLeft"
              >
                <Button style={{ height: 'fit-content' }} size="mini" loading={this.state.loading}>
                  Update Robot
                </Button>
              </Popconfirm>
              <div className="robot-creation-component__online-robot_item col-1-4">
                <div className="robot-creation-component__online-robot_label">Status</div>
                <Select
                  onChange={(value) => this.setState({ robotOnline: { ...this.state.robotOnline, status: value } })}
                  defaultValue={1}
                  style={{ width: '100%' }}
                  value={robotOnline.status}
                >
                  <Option value={0}>Inactive</Option>
                  <Option value={1}>Active</Option>
                  <Option disabled value={2}>
                    Success
                  </Option>
                  <Option disabled value={3}>
                    Time end
                  </Option>
                  <Option disabled value={4}>
                    Cannot run
                  </Option>
                </Select>
              </div>
            </div>

            <div className="robot-creation-component__online-robot_map robot-creation-component__online-robot_map-geo-points">
              <NewMap
                id="points-map"
                mapInfo={{
                  markers: [],
                  type: 'geo-points-map',
                  zoom: 2,
                  range: robotOnline.distance,
                  savedPoints: this.state.savedPoints,
                  showMarker: this.state.showMarker,
                }}
                getLatLng={this.getLatLng}
              />
            </div>
          </div>
        )}

        {this.props.type === 'offline' && (
          <div className="robot-creation-component__online-robot">
            <div className="robot-creation-component__online-robot_row">
              <div className="robot-creation-component__online-robot_item" style={{ width: '100%' }}>
                <div className="robot-creation-component__online-robot_label">
                  <span>*</span>Robot name
                </div>
                <Input
                  onChange={(e) => this.handleChangeName(e.target.value)}
                  style={{ width: '100%' }}
                  value={robotOnline.name}
                />
              </div>
              <div className="robot-creation-component__online-robot_item">
                <div className="robot-creation-component__online-robot_label">Stop on result</div>
                <Checkbox
                  style={{ marginTop: 5 }}
                  onChange={(e) =>
                    this.setState({
                      robotOnline: {
                        ...robotOnline,
                        alert_stop: e.target.checked === true ? 1 : 0,
                      },
                    })
                  }
                  checked={robotOnline.alert_stop === 1}
                />
              </div>
            </div>
            <div className="robot-creation-component__online-robot_row">
              <div className="robot-creation-component__online-robot_item col-1-4">
                <div className="robot-creation-component__online-robot_label">Source</div>
                <Select
                  onChange={this.handleChangeSource}
                  defaultValue="contact"
                  style={{ width: '100%' }}
                  value={robotOnline.source}
                >
                  <Option value="contact">Contact</Option>
                  <Option value="contact-group">Contact Group</Option>
                </Select>
              </div>
              <div className="robot-creation-component__online-robot_item col-1-4">
                <div className="robot-creation-component__online-robot_label">Source Mode</div>
                <Select
                  onChange={this.handleChangeSourceMode}
                  defaultValue="primary"
                  style={{ width: '100%' }}
                  value={robotOnline.source_mode}
                >
                  <Option value="primary">Primary</Option>
                  <Option disabled={robotOnline.source === 'contact-group'} value="manual">
                    Manual
                  </Option>
                  <Option value="all">All</Option>
                </Select>
              </div>

              <div className="robot-creation-component__online-robot_item col-1-2">
                <div className="robot-creation-component__online-robot_label">Source ID</div>
                <Select
                  onChange={this.handleChangeSourceId}
                  style={{ width: '100%' }}
                  value={robotOnline.source_id}
                  showSearch
                  filterOption={(input, option) =>
                    option?.props?.children?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0 ||
                    option?.props?.value?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0
                  }
                >
                  {robotOnline.source === 'contact' ? contacts : groups}
                </Select>
              </div>
            </div>

            <div className="robot-creation-component__online-robot_row">
              <div className="robot-creation-component__online-robot_item col-3-4">
                <div className="robot-creation-component__online-robot_label">Terminals</div>
                <Select
                  mode="tags"
                  onChange={this.handleChangeTerminals}
                  disabled={!terminalDisabled}
                  style={{ width: '100%' }}
                  value={terminals ? robotOnline.terminals : []}
                >
                  {terminals}
                </Select>
              </div>

              <div className="robot-creation-component__online-robot_item col-1-4">
                <div className="robot-creation-component__online-robot_label">Query type</div>
                <Select onChange={this.handleChangeQueryType} style={{ width: '100%' }} value={robotOnline.query_type}>
                  <Option value="active">Active</Option>
                  <Option value="passive">Passive</Option>
                </Select>
              </div>
            </div>

            <div className="robot-creation-component__online-robot_row">
              <div className="robot-creation-component__online-robot_item" style={{ width: '100%' }}>
                <Tabs
                  defaultActiveKey="standard"
                  onChange={this.searchTypeOnChange}
                  activeKey={this.state.typeOfSearch}
                >
                  <TabPane tab="Standard Search" key="standard">
                    <div className="gap-align">
                      <div className="col-3-4">
                        <div className="robot-creation-component__online-robot_label">
                          <span>*</span>Start & End Time
                        </div>
                        <RangePicker
                          showTime
                          format="YYYY/MM/DD HH:mm"
                          style={{ width: '100%' }}
                          placeholder={['Start Time', 'End Time']}
                          onChange={this.handleChangeTime}
                          onOk={this.handleChangeTime}
                          defaultValue={[
                            this.props.robotInfo.start
                              ? moment.utc(this.props.robotInfo.start).tz(this.props.timeZone)
                              : null,
                            this.props.robotInfo.end
                              ? moment.utc(this.props.robotInfo.end).tz(this.props.timeZone)
                              : null,
                          ]}
                          disabledDate={(current) => {
                            return current && current <= moment().add(-1, 'day');
                          }}
                        />
                      </div>
                      <div className="robot-creation-component__online-robot_item col-1-4">
                        <div className="robot-creation-component__online-robot_label">
                          Repeat time <span style={{ opacity: 0.5 }}>(min)</span>
                        </div>
                        <InputNumber
                          onChange={this.handleChangeRepeatTime}
                          parser={(value) => value.replace(/[^0-9]/g, '')}
                          style={{ width: '100%' }}
                          min={1}
                          defaultValue={robotOnline.repeat_time || 60}
                          value={robotOnline.repeat_time}
                        />
                      </div>
                    </div>
                  </TabPane>
                  <TabPane tab="Scheduled search" key="scheduled">
                    <div className="gap-align">
                      <div className="col-1-2">
                        <div className="robot-creation-component__online-robot_label">
                          <span>*</span>Initial Search Time
                        </div>
                        <DatePicker
                          showTime
                          format="YYYY/MM/DD HH:mm"
                          style={{ width: '100%' }}
                          placeholder="Schedule Time"
                          onChange={this.handleScheduleTimeChange}
                          onOk={this.handleScheduleTimeChange}
                          defaultValue={moment.utc(this.props.robotInfo.start).tz(this.props.timeZone)}
                          disabledDate={(current) => {
                            return current && current <= moment().add(-1, 'day');
                          }}
                        />
                      </div>
                      <div className="robot-creation-component__online-robot_item col-1-4">
                        <div className="robot-creation-component__online-robot_label">Nr. Of Searches</div>
                        <InputNumber
                          onChange={this.handleChangeNumberOfChanges}
                          parser={(value) => value.replace(/[^0-9]/g, '')}
                          style={{ width: '100%' }}
                          min={1}
                          defaultValue={robotOnline.number_of_searches}
                          value={robotOnline.number_of_searches}
                        />
                      </div>
                      <div className="robot-creation-component__online-robot_item col-1-4">
                        <div className="robot-creation-component__online-robot_label">
                          Repeat time <span style={{ opacity: 0.5 }}>(min)</span>
                        </div>
                        <InputNumber
                          onChange={this.handleChangeRepeatTime}
                          parser={(value) => value.replace(/[^0-9]/g, '')}
                          style={{ width: '100%' }}
                          min={1}
                          defaultValue={robotOnline.repeat_time || 60}
                          value={robotOnline.repeat_time}
                          disabled={!robotOnline.number_of_searches || robotOnline.number_of_searches <= 1}
                        />
                      </div>
                    </div>
                  </TabPane>
                </Tabs>
              </div>
            </div>

            <div className="robot-creation-component__online-robot_row">
              <div className="robot-creation-component__online-robot_item" style={{ width: '100%' }}>
                <div className="robot-creation-component__online-robot_label">Description</div>
                <TextArea
                  style={{ width: '100%', resize: 'none' }}
                  onChange={(e) => this.handleChangeDescription(e.target.value)}
                  value={robotOnline.description}
                  rows={2}
                />
              </div>
            </div>

            <div className="robot-creation-component__online-robot_row" style={{ alignItems: 'flex-end' }}>
              <Popconfirm
                title="Are you sure you want to update this robot?"
                onConfirm={this.editRobot}
                okText="Yes"
                cancelText="No"
                placement="topLeft"
              >
                <Button style={{ height: 'fit-content' }} size="mini" loading={this.state.loading}>
                  Update Robot
                </Button>
              </Popconfirm>
              <div className="robot-creation-component__online-robot_item col-1-4">
                <div className="robot-creation-component__online-robot_label">Status</div>
                <Select
                  onChange={(value) => this.setState({ robotOnline: { ...this.state.robotOnline, status: value } })}
                  defaultValue={1}
                  style={{ width: '100%' }}
                  value={robotOnline.status}
                >
                  <Option value={0}>Inactive</Option>
                  <Option value={1}>Active</Option>
                  <Option disabled value={2}>
                    Success
                  </Option>
                  <Option disabled value={3}>
                    Time end
                  </Option>
                  <Option disabled value={4}>
                    Cannot run
                  </Option>
                </Select>
              </div>
            </div>
          </div>
        )}

        {this.props.type === 'tracker' && (
          <div className="robot-creation-component__online-robot">
            <div className="robot-creation-component__online-robot_row">
              <div className="robot-creation-component__online-robot_item col-1-2">
                <div className="robot-creation-component__online-robot_label">
                  <span>*</span>Robot name
                </div>
                <Input
                  onChange={(e) => this.handleChangeName(e.target.value)}
                  style={{ width: '100%' }}
                  value={robotOnline.name}
                />
              </div>
            </div>
            <div className="robot-creation-component__online-robot_row">
              <div className="robot-creation-component__online-robot_item col-1-4">
                <div className="robot-creation-component__online-robot_label">Source</div>
                <Select
                  onChange={this.handleChangeSource}
                  defaultValue="contact"
                  style={{ width: '100%' }}
                  value={robotOnline.source}
                >
                  <Option value="contact">Contact</Option>
                  <Option value="contact-group">Contact Group</Option>
                </Select>
              </div>
              <div className="robot-creation-component__online-robot_item col-1-4">
                <div className="robot-creation-component__online-robot_label">Source Mode</div>
                <Select
                  onChange={this.handleChangeSourceMode}
                  defaultValue="primary"
                  style={{ width: '100%' }}
                  value={robotOnline.source_mode}
                >
                  <Option value="primary">Primary</Option>
                  <Option disabled={robotOnline.source === 'contact-group'} value="manual">
                    Manual
                  </Option>
                  <Option value="all">All</Option>
                </Select>
              </div>
              <div className="robot-creation-component__online-robot_item col-1-2">
                <div className="robot-creation-component__online-robot_label">Source ID</div>
                <Select
                  onChange={this.handleChangeSourceId}
                  style={{ width: '100%' }}
                  value={robotOnline.source_id}
                  showSearch
                  filterOption={(input, option) =>
                    option?.props?.children?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0 ||
                    option?.props?.value?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0
                  }
                >
                  {robotOnline.source === 'contact' ? contacts : groups}
                </Select>
              </div>
            </div>
            <div className="robot-creation-component__online-robot_row">
              <div className="robot-creation-component__online-robot_item col-3-4">
                <div className="robot-creation-component__online-robot_label">Terminals</div>
                <Select
                  mode="tags"
                  onChange={this.handleChangeTerminals}
                  disabled={!terminalDisabled}
                  style={{ width: '100%' }}
                  value={terminals ? robotOnline.terminals : []}
                >
                  {terminals}
                </Select>
              </div>
              <div className="robot-creation-component__online-robot_item col-1-4">
                <div className="robot-creation-component__online-robot_label">Query type</div>
                <Select onChange={this.handleChangeQueryType} style={{ width: '100%' }} value={robotOnline.query_type}>
                  <Option value="active">Active</Option>
                  <Option value="passive">Passive</Option>
                </Select>
              </div>
            </div>
            <div className="robot-creation-component__online-robot_row">
              <div className="robot-creation-component__online-robot_item" style={{ width: '100%' }}>
                <Tabs
                  defaultActiveKey="standard"
                  onChange={this.searchTypeOnChange}
                  activeKey={this.state.typeOfSearch}
                >
                  <TabPane tab="Standard Search" key="standard">
                    <div className="gap-align">
                      <div className="col-3-4">
                        <div className="robot-creation-component__online-robot_label">
                          <span>*</span>Start & End Time
                        </div>
                        <RangePicker
                          showTime
                          format="YYYY/MM/DD HH:mm"
                          style={{ width: '100%' }}
                          placeholder={['Start Time', 'End Time']}
                          onChange={this.handleChangeTime}
                          onOk={this.handleChangeTime}
                          defaultValue={[
                            this.props.robotInfo.start
                              ? moment.utc(this.props.robotInfo.start).tz(this.props.timeZone)
                              : null,
                            this.props.robotInfo.end
                              ? moment.utc(this.props.robotInfo.end).tz(this.props.timeZone)
                              : null,
                          ]}
                          disabledDate={(current) => {
                            return current && current <= moment().add(-1, 'day');
                          }}
                        />
                      </div>
                      <div className="robot-creation-component__online-robot_item col-1-4">
                        <div className="robot-creation-component__online-robot_label">
                          Repeat time <span style={{ opacity: 0.5 }}>(min)</span>
                        </div>
                        <InputNumber
                          onChange={this.handleChangeRepeatTime}
                          parser={(value) => value.replace(/[^0-9]/g, '')}
                          style={{ width: '100%' }}
                          min={1}
                          defaultValue={robotOnline.repeat_time || 60}
                          value={robotOnline.repeat_time}
                        />
                      </div>
                    </div>
                  </TabPane>
                  <TabPane tab="Scheduled search" key="scheduled">
                    <div className="gap-align">
                      <div className="col-1-2">
                        <div className="robot-creation-component__online-robot_label">
                          <span>*</span>Initial Search Time
                        </div>
                        <DatePicker
                          showTime
                          format="YYYY/MM/DD HH:mm"
                          style={{ width: '100%' }}
                          placeholder="Schedule Time"
                          onChange={this.handleScheduleTimeChange}
                          onOk={this.handleScheduleTimeChange}
                          defaultValue={moment.utc(this.props.robotInfo.start).tz(this.props.timeZone)}
                          disabledDate={(current) => {
                            return current && current <= moment().add(-1, 'day');
                          }}
                        />
                      </div>
                      <div className="robot-creation-component__online-robot_item col-1-4">
                        <div className="robot-creation-component__online-robot_label">Nr. Of Searches</div>
                        <InputNumber
                          onChange={this.handleChangeNumberOfChanges}
                          parser={(value) => value.replace(/[^0-9]/g, '')}
                          style={{ width: '100%' }}
                          min={1}
                          defaultValue={robotOnline.number_of_searches}
                          value={robotOnline.number_of_searches}
                        />
                      </div>
                      <div className="robot-creation-component__online-robot_item col-1-4">
                        <div className="robot-creation-component__online-robot_label">
                          Repeat time <span style={{ opacity: 0.5 }}>(min)</span>
                        </div>
                        <InputNumber
                          onChange={this.handleChangeRepeatTime}
                          parser={(value) => value.replace(/[^0-9]/g, '')}
                          style={{ width: '100%' }}
                          min={1}
                          defaultValue={robotOnline.repeat_time || 60}
                          value={robotOnline.repeat_time}
                          disabled={!robotOnline.number_of_searches || robotOnline.number_of_searches <= 1}
                        />
                      </div>
                    </div>
                  </TabPane>
                </Tabs>
              </div>
            </div>

            <div className="robot-creation-component__online-robot_row">
              <div className="robot-creation-component__online-robot_item" style={{ width: '100%' }}>
                <div className="robot-creation-component__online-robot_label">Description</div>
                <TextArea
                  style={{ width: '100%', resize: 'none' }}
                  onChange={(e) => this.handleChangeDescription(e.target.value)}
                  value={robotOnline.description}
                  rows={2}
                />
              </div>
            </div>

            <div className="robot-creation-component__online-robot_row" style={{ alignItems: 'flex-end' }}>
              <Popconfirm
                title="Are you sure you want to update this robot?"
                onConfirm={this.editRobot}
                okText="Yes"
                cancelText="No"
                placement="topLeft"
              >
                <Button style={{ height: 'fit-content' }} size="mini" loading={this.state.loading}>
                  Update Robot
                </Button>
              </Popconfirm>
              <div className="robot-creation-component__online-robot_item col-1-4">
                <div className="robot-creation-component__online-robot_label">Status</div>
                <Select
                  onChange={(value) => this.setState({ robotOnline: { ...this.state.robotOnline, status: value } })}
                  defaultValue={1}
                  style={{ width: '100%' }}
                  value={robotOnline.status}
                >
                  <Option value={0}>Inactive</Option>
                  <Option value={1}>Active</Option>
                  <Option disabled value={2}>
                    Success
                  </Option>
                  <Option disabled value={3}>
                    Time end
                  </Option>
                  <Option disabled value={4}>
                    Cannot run
                  </Option>
                  <Option disabled value={5}>
                    Schedule finished
                  </Option>
                </Select>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

RobotComponent.defaultProps = {
  contacts: {},
  robotInfo: {},
  type: 'online',
  groups: {},
  terminals: {},
  timeZone: 'Europe/London',
  populateData: {
    id: '',
    data: false,
  },
  createRobot: () => null,
  getContactTerminals: () => null,
};

RobotComponent.propTypes = {
  robotEdit: PropTypes.func.isRequired,
  contacts: PropTypes.shape({}),
  type: PropTypes.string,
  groups: PropTypes.oneOfType([PropTypes.array, PropTypes.shape({})]),
  getContactTerminals: PropTypes.func,
  terminals: PropTypes.object,
  populateData: PropTypes.shape({
    id: PropTypes.string,
    data: PropTypes.bool,
    from: PropTypes.string,
  }),
  timeZone: PropTypes.string,
  robotInfo: PropTypes.shape({
    id: PropTypes.number,
    start: PropTypes.string,
    end: PropTypes.string,
    configuration: PropTypes.shape({
      terminals: PropTypes.array,
      source_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
  }),
};

export default RobotComponent;
