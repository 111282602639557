const routes = {
  dashboard: '/',
  login: '/login',
  contacts: '/contacts',
  groups: '/groups',
  group: '/groups/:groupId',
  profile: '/profile',
  settings: '/settings',
  contactView: '/contact/:contactId',
  robots: '/robots',
  robotView: '/robots/:robotId',
  cellDataImport: '/cell-data-import',
  report: '/report',
  presence: '/presence',
  survey: '/survey',
  license: '/license',
};

routes.unauthorized = {
  login: routes.login,
};

routes.onlyUnauthorized = {
  login: routes.login,
};

export default routes;
