import React, { memo, useState, useCallback, useMemo } from 'react';
import styled, { css } from 'styled-components';
import { useSelector } from 'react-redux';

import L from 'leaflet';
import { LayerGroup, Rectangle } from 'react-leaflet';

import { Form, InputNumber, Button, Tooltip, message } from 'antd';
import { HeatMapOutlined, AimOutlined, SettingOutlined, CloseCircleOutlined } from '@ant-design/icons';

import MapButton from './components/mapButton';
import parseHeatMapSettings from '../../utils/parseHeatMapSettings';

const HeatPointsComponent = memo(({ points, heatMapSettings, parsedSignals }) => {
  const getColor = (signal) => {
    const global = {
      opacity: heatMapSettings.borderOpacity,
      fillOpacity: heatMapSettings.opacity,
    };

    if (parsedSignals && parsedSignals.length) {
      if (signal >= parseInt(parsedSignals[1].value)) return { ...global, color: parsedSignals[0].color };
      else if (signal < parseInt(parsedSignals[parsedSignals.length - 2].value) - 10)
        return { ...global, color: parsedSignals[parsedSignals.length - 1].color };

      let resultToReturn = null;

      parsedSignals.forEach((item, index) => {
        if (index !== 0 && index !== parsedSignals.length - 1) {
          const intValue = parseInt(item.value);
          const nextIntValue =
            parsedSignals[index + 1].value === 'lowerThen' ? item.value - 10 : parseInt(parsedSignals[index + 1].value);
          if (signal <= intValue && signal >= nextIntValue) resultToReturn = { ...global, color: item.color };
        }
      });

      return resultToReturn;
    }
  };

  const heatPoints = [];

  points &&
    Object.keys(points).length !== 0 &&
    points.constructor !== Object &&
    points.forEach(
      ({ lat, lng, signal }, index) =>
        signal &&
        heatPoints.push(
          <Rectangle
            key={`${lat}-${lng}-${index}`}
            bounds={L.latLng([lat, lng]).toBounds(heatMapSettings.pointSize)}
            pathOptions={getColor(parseFloat(signal))}
          />,
        ),
    );

  if (heatPoints.length === 0) {
    message.warning('No heat map points');
  }

  return <LayerGroup>{heatPoints}</LayerGroup>;
});

const HeatMap = ({ map, points = [], heatMapView, getHeatMapAction, cellId, mcc, mnc, lac, isOnDashboard }) => {
  const [settingsOpened, setSettingsOpened] = useState(false);

  const [heatMapSettings, setHeatMapSettings] = useState({ pointSize: 3, opacity: 0.5, borderOpacity: 0.5 });

  const signalSettings = useSelector((store) => store.user?.map?.settings);
  const parsedSignals = useMemo(() => parseHeatMapSettings(signalSettings), [signalSettings]);

  const toggleSettingsStatus = () => setSettingsOpened((state) => !state);

  const settingsOnSubmit = useCallback((values) => setHeatMapSettings({ ...values }), []);

  const targetHeatPointsBounds = useCallback(() => {
    const bounds = L.latLngBounds(points.map((point) => ({ lat: point.lat, lng: point.lng })));
    map.fitBounds(bounds);
  }, [map, points]);

  return (
    <>
      <MapButton
        key="getHeatMap"
        Icon={HeatMapOutlined}
        active={heatMapView.active}
        loading={heatMapView.loading}
        onClick={getHeatMapAction}
        position={{ top: 55, right: 10 }}
        toolTipText="Toggle heatmap view"
        disabled={!cellId || !mcc || !mnc || !lac}
      />

      {heatMapView.active && (
        <Tooltip placement="left" title="Target heat points">
          <TargetHeatPoints onClick={targetHeatPointsBounds}>
            <AimOutlined />
          </TargetHeatPoints>
        </Tooltip>
      )}

      {heatMapView.active && (
        <DropDownSetting onClick={toggleSettingsStatus} settingsOpened={settingsOpened}>
          <SettingOutlined />
        </DropDownSetting>
      )}

      {heatMapView.active && (
        <HeatColoInfoWrapper>
          {parsedSignals?.map((signal, index) => {
            if (index === 0)
              return <ColorBox key={signal.id} color={signal.color}>{`> ${parsedSignals[1].value} dBm`}</ColorBox>;
            else if (index === parsedSignals.length - 1)
              return (
                <ColorBox key={signal.id} color={signal.color}>{`< ${
                  parsedSignals[parsedSignals.length - 2].value - 10
                } dBm`}</ColorBox>
              );

            return <ColorBox key={signal.id} color={signal.color}>{`< ${signal.value} dBm`}</ColorBox>;
          })}
        </HeatColoInfoWrapper>
      )}

      {settingsOpened && heatMapView.active ? (
        <SettingsWrapper isOnDashboard={isOnDashboard}>
          {isOnDashboard && (
            <CloseButton onClick={toggleSettingsStatus}>
              <CloseCircleOutlined />
            </CloseButton>
          )}
          <Form initialValues={{ ...heatMapSettings }} onFinish={settingsOnSubmit}>
            <Form.Item label="Point Size (m)s" name="pointSize" style={{ marginBottom: 5, display: 'block' }}>
              <InputNumber style={{ display: 'block' }} min={1} max={100} />
            </Form.Item>
            <Form.Item label="Point Opacity" name="opacity" style={{ marginBottom: 10, display: 'block' }}>
              <InputNumber style={{ display: 'block' }} min={0} max={1} step={0.1} />
            </Form.Item>
            <Form.Item label="Border Opacity" name="borderOpacity" style={{ marginBottom: 10, display: 'block' }}>
              <InputNumber style={{ display: 'block' }} min={0} max={1} step={0.1} />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
        </SettingsWrapper>
      ) : null}

      {heatMapView.active && (
        <HeatPointsComponent points={points} heatMapSettings={heatMapSettings} parsedSignals={parsedSignals} />
      )}
    </>
  );
};

const SettingsWrapper = styled.div`
  position: absolute;
  top: 55px;
  right: 55px;

  width: 200px;
  height: 280px;

  padding: 10px;

  background: white;
  box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;

  cursor: auto;

  z-index: 1000;

  ${({ isOnDashboard }) =>
    isOnDashboard &&
    css`
      top: 55px;
      right: 55px;
    `}
`;

const DropDownSetting = styled.div`
  position: absolute;
  top: 130px;
  right: 10px;

  width: 40px;
  height: 25px;
  background: #efefef;
  z-index: 1000;

  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.2) 0 2px 4px;

  display: flex;
  align-items: center;
  justify-content: center;

  ${({ settingsOpened }) =>
    settingsOpened &&
    css`
      svg {
        path {
          fill: #73b920 !important;
        }
      }
    `}

  svg {
    font-size: 15px;
    path {
      fill: #343434;
    }
  }

  &:hover {
    background-color: #e5e5e5;

    svg {
      path {
        fill: #181818;
      }
    }
  }
`;

const TargetHeatPoints = styled(DropDownSetting)`
  top: 100px;
`;

const CloseButton = styled.div`
  position: absolute;
  right: 10px;
  cursor: pointer;
`;

const HeatColoInfoWrapper = styled.div`
  position: absolute;

  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  width: 700px;

  z-index: 1000;

  top: 10px;
  right: 50%;
  transform: translateX(50%);
`;

const ColorBox = styled.div`
  width: 80px;
  height: 25px;

  background: ${({ color }) => color};

  color: white;
  ${({ color }) =>
    color === 'yellow' &&
    css`
      color: black;
    `}

  font-size: 12px;

  display: flex;
  align-items: center;
  justify-content: center;

  &:first-child {
    border-bottom-left-radius: 3px;
    border-top-left-radius: 3px;
  }
  &:last-child {
    border-bottom-right-radius: 3px;
    border-top-right-radius: 3px;
  }
`;

export default memo(HeatMap);
