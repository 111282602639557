import { playSound } from '../../utils/notificationAndSound';
import { FIRE_ALERT, LOGOUT } from '../../constants/actionTypes';
import initialState from './initState';

export default function alert(state = initialState, action = {}) {
  switch (action.type) {
    case FIRE_ALERT: {
      playSound('error');
      return {
        ...action.payload,
      };
    }
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
}
