import { ARCHIVE_REPORT_CELL, GET_ARCHIVED_REPORT, GET_REPORT, LOGOUT } from '../../constants/actionTypes';

const initialState = {
  cells: {
    data: [],
  },
  archivedCells: {},
};

export default function report(state = initialState, action = {}) {
  switch (action.type) {
    case GET_REPORT:
      return {
        ...state,
        cells: action.payload,
      };
    case GET_ARCHIVED_REPORT:
      return {
        ...state,
        archivedCells: action.payload,
      };
    case ARCHIVE_REPORT_CELL: {
      const cellsData = { ...state.cells };
      const archivedCells = [...state.archivedCells];

      const index = cellsData.data.findIndex((cell) => cell.id === action.payload.id);
      const archIndex = archivedCells.findIndex((cell) => cell.id === action.payload.id);

      if (action.payload.is_archived && index > -1) {
        cellsData.data.splice(index, 1);
        archivedCells.unshift(action.payload);
      } else if (!action.payload.is_archived) {
        archivedCells.splice(archIndex, 1);
        cellsData.data.push(action.payload);
      }

      return {
        cells: cellsData,
        archivedCells,
      };
    }
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
}
