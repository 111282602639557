import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import NavigationMenu from '../../components/navigationMenu';
import ReportsTable from './reportsTable';
import ArchivedReportsTable from './archivedReports';

import './reportsPage.sass';

class ReportsPage extends Component {
  state = {
    triggerLoadingArchived: false,
    triggerLoadingUnarchived: false,
  };

  setTriggerLoadingArchived = (value) => {
    this.setState({
      triggerLoadingArchived: value,
    });
  };

  setTriggerLoadingUnarchived = (value) => {
    this.setState({
      triggerLoadingUnarchived: value,
    });
  };
  render() {
    return (
      <div>
        <NavigationMenu statusPage="cell-data" />
        <div className={`reports-page ${this.props.user.sticky_header ? '--fixed' : ''}`}>
          <ReportsTable
            triggerLoading={this.state.triggerLoadingUnarchived}
            triggerAction={this.setTriggerLoadingArchived}
          />
          <ArchivedReportsTable
            triggerLoading={this.state.triggerLoadingArchived}
            triggerAction={this.setTriggerLoadingUnarchived}
          />
        </div>
      </div>
    );
  }
}

ReportsPage.defaultProps = {
  user: {},
};

ReportsPage.propTypes = {
  user: PropTypes.shape({
    sticky_header: PropTypes.number,
  }),
};

const mapStateToProps = (store) => ({
  user: store.user,
});

export default connect(mapStateToProps)(ReportsPage);
