import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Table, Popconfirm, Pagination, Input, Checkbox, Space, Button as ButtonAntd } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { Button } from 'semantic-ui-react';

import TimeStamp from '../../utils/timeStamp';

import { getCells, updateCell, deleteCell } from '../../actions/files/files';

let clearF = {};

class CellTable extends Component {
  state = {
    loading: true,
    editState: false,
    editRow: {},
    cells: {
      per_page: 10,
      page: 1,
    },
    cellsInfo: {
      total: 1,
      page: 1,
    },
    cellsData: [],
    checkedManual: true,
    searchText: '',
    searchedColumn: null,
    clearFunctions: [],
  };

  componentDidMount() {
    this.props.getCells({ ...this.state.cells, show_manual: 1 }).then((res) =>
      this.setState({
        loading: false,
        cellsInfo: {
          total: res.meta.total,
          page: res.meta.current_page,
        },
        cellsData: Object.values(res.data),
      }),
    );
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // if ((nextProps.cells.current_page !== this.props.cells.current_page) ||
    //   (parseInt(nextProps.cells.per_page, 10) !== this.state.cells.per_page)) {
    //   this.setState({ cellsData: Object.values(nextProps.cells.data) });
    // }
    this.setState({ cellsData: Object.values(nextProps.cells.data) });
  }

  editRowAction = (record) => {
    this.setState({ editState: true, editRow: { ...record } });
  };

  onChangeValueRow = (key, value) => {
    this.setState({ editRow: { ...this.state.editRow, [key]: value === 'null' ? null : value } });
  };

  onPaginationChange = (current, pageSize) => {
    this.setState({ loading: true }, () => {
      this.props.getCells({ ...this.state.cells, per_page: pageSize, page: current }).then(() => {
        this.setState({
          loading: false,
          cells: {
            ...this.state.cells,
            per_page: pageSize,
            page: current,
          },
          cellsInfo: {
            ...this.state.cellsInfo,
            page: current,
          },
        });
      });
    });
  };

  switchCheckbox = () => {
    this.setState({ checkedManual: !this.state.checkedManual === true ? 1 : 0, loading: true });
    const filters = { ...this.state.cells, show_manual: !this.state.checkedManual === true ? 1 : 0 };
    this.props
      .getCells({ ...filters })
      .then((res) => {
        this.setState({
          loading: false,
          cellsInfo: {
            total: res.meta.total,
            page: res.meta.current_page,
          },
          cellsData: Object.values(res.data),
        });
      })
      .catch(() => {
        this.setState({ loading: false });
      });
  };

  submitFilter = (e) => {
    e?.preventDefault();
    this.setState({ loading: true });
    const filters = { ...this.state.cells, show_manual: this.state.checkedManual ? 1 : 0 };
    delete filters.page;
    return new Promise((resolve, reject) =>
      this.props
        .getCells({ ...filters })
        .then((res) => {
          this.setState(
            {
              loading: false,
              cellsInfo: {
                total: res.meta.total,
                page: res.meta.current_page,
              },
              cellsData: Object.values(res.data),
            },
            () => resolve(res),
          );
        })
        .catch((err) => {
          this.setState({ loading: false });
          return reject(err);
        }),
    );
  };

  updateCell = (cellId) => {
    const newObj = { ...this.state.editRow };
    newObj.fix_range = newObj.fix_range ? 1 : 0;
    this.props.updateCell(cellId, newObj).then(() => {
      this.setState({ editRow: {}, editState: false });
    });
  };

  handleSearch = (selectedKeys, confirm, dataIndex, clearFilters) => {
    if (!selectedKeys[0]) {
      const filters = { ...this.state.cells };
      delete filters[dataIndex];
      delete clearF[dataIndex];
      this.setState({ cells: filters });
    } else {
      clearF[dataIndex] = clearFilters;
      this.setState(
        {
          cells: {
            ...this.state.cells,
            [dataIndex]: selectedKeys[0],
          },
        },
        () => {
          this.submitFilter().then(() => confirm());
        },
      );
    }
  };

  handleReset = (clearFilters, dataIndex) => {
    const filters = { ...this.state.cells };
    delete filters[dataIndex];
    delete clearF[dataIndex];
    this.setState({ cells: filters }, () => {
      this.submitFilter().then(() => clearFilters());
      this.setState({ searchText: '' });
    });
  };

  resetAllFilters = () => {
    Object.keys(clearF).forEach((key, index) => {
      setTimeout(() => clearF[key](), 10);
    });

    setTimeout(
      () =>
        this.setState(
          {
            ...this.state,
            cells: {
              per_page: 10,
              page: 1,
            },
            searchText: '',
            searchedColumn: null,
            loading: true,
          },
          () => {
            this.submitFilter()
              .then(() => this.setState({ loading: false }))
              .catch(() => this.setState({ loading: false }));
          },
        ),
      100,
    );
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      if (clearF[dataIndex]) clearF[dataIndex] = clearFilters;
      return (
        <div style={{ padding: 8 }}>
          <Input
            ref={(node) => {
              this.searchInput = node;
            }}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex, clearFilters)}
            style={{ marginBottom: 8, display: 'block' }}
          />
          <Space>
            <ButtonAntd
              type="primary"
              onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex, clearFilters)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Search
            </ButtonAntd>
            <ButtonAntd onClick={() => this.handleReset(clearFilters, dataIndex)} size="small" style={{ width: 90 }}>
              Reset
            </ButtonAntd>
          </Space>
        </div>
      );
    },
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
  });

  render() {
    const columns = [
      { title: 'ID', width: 90, dataIndex: 'id', key: 'id', fixed: 'left', ...this.getColumnSearchProps('id') },
      {
        title: 'CELLID',
        width: 120,
        dataIndex: 'cell_id',
        key: 'cell_id',
        fixed: 'left',
        ...this.getColumnSearchProps('cell_id'),
        render: (text) => <div>{!text ? 'null' : text}</div>,
      },
      {
        title: 'MCC',
        dataIndex: 'mcc',
        key: 'mcc',
        width: 90,
        ...this.getColumnSearchProps('mcc'),
        render: (text, record) => {
          if (record.id === this.state.editRow.id) {
            return (
              <input
                onChange={(e) => this.onChangeValueRow('mcc', e.target.value)}
                style={{ width: '100%' }}
                value={this.state.editRow.mcc === null ? 'null' : this.state.editRow.mcc}
              />
            );
          }
          return <div>{record.mcc === null ? 'null' : record.mcc}</div>;
        },
      },
      {
        title: 'MNC',
        dataIndex: 'mnc',
        key: 'mnc',
        width: 90,
        ...this.getColumnSearchProps('mnc'),
        render: (text, record) => {
          if (record.id === this.state.editRow.id) {
            return (
              <input
                onChange={(e) => this.onChangeValueRow('mnc', e.target.value)}
                style={{ width: '100%' }}
                value={this.state.editRow.mnc === null ? 'null' : this.state.editRow.mnc}
              />
            );
          }
          return <div>{record.mnc === null ? 'null' : record.mnc}</div>;
        },
      },
      {
        title: 'LAC/TAC',
        dataIndex: 'lac',
        key: 'lac',
        width: 100,
        ...this.getColumnSearchProps('lac'),
        render: (text, record) => {
          if (record.id === this.state.editRow.id) {
            return (
              <input
                onChange={(e) => this.onChangeValueRow('lac', e.target.value)}
                style={{ width: '100%' }}
                value={this.state.editRow.lac === null ? 'null' : this.state.editRow.lac}
              />
            );
          }
          return <div>{record.lac === null ? 'null' : record.lac}</div>;
        },
      },
      {
        title: 'LAT',
        dataIndex: 'lat',
        key: 'lat',
        width: 140,
        ...this.getColumnSearchProps('lat'),
        render: (text, record) => {
          if (record.id === this.state.editRow.id) {
            return (
              <input
                onChange={(e) => this.onChangeValueRow('lat', e.target.value)}
                style={{ width: '100%' }}
                value={this.state.editRow.lat === null ? 'null' : this.state.editRow.lat}
              />
            );
          }
          return <div>{record.lat === null ? 'null' : record.lat}</div>;
        },
      },
      {
        title: 'LONG',
        dataIndex: 'long',
        key: 'long',
        width: 140,
        ...this.getColumnSearchProps('long'),
        render: (text, record) => {
          if (record.id === this.state.editRow.id) {
            return (
              <input
                onChange={(e) => this.onChangeValueRow('long', e.target.value)}
                style={{ width: '100%' }}
                value={this.state.editRow.long === null ? 'null' : this.state.editRow.long}
              />
            );
          }
          return <div>{record.long === null ? 'null' : record.long}</div>;
        },
      },
      {
        title: 'Range',
        dataIndex: 'range',
        key: 'range',
        width: 120,
        ...this.getColumnSearchProps('range'),
        render: (text, record) => {
          if (record.id === this.state.editRow.id) {
            return (
              <input
                onChange={(e) => this.onChangeValueRow('range', e.target.value)}
                style={{ width: '100%' }}
                value={this.state.editRow.range === null ? 'null' : this.state.editRow.range}
              />
            );
          }
          return <div>{record.range === null ? 'null' : record.range}</div>;
        },
      },
      {
        title: 'Angle',
        dataIndex: 'angle',
        key: 'angle',
        width: 120,
        ...this.getColumnSearchProps('angle'),
        render: (text, record) => {
          if (record.id === this.state.editRow.id) {
            return (
              <input
                onChange={(e) => this.onChangeValueRow('angle', e.target.value)}
                style={{ width: '100%' }}
                value={this.state.editRow.angle === null ? 'null' : this.state.editRow.angle}
              />
            );
          }
          return <div>{record.angle === null ? 'null' : record.angle}</div>;
        },
      },
      {
        title: 'Width',
        dataIndex: 'width',
        key: 'width',
        width: 120,
        ...this.getColumnSearchProps('width'),
        render: (text, record) => {
          if (record.id === this.state.editRow.id) {
            return (
              <input
                onChange={(e) => this.onChangeValueRow('width', e.target.value)}
                style={{ width: '100%' }}
                value={this.state.editRow.width === null ? 'null' : this.state.editRow.width}
              />
            );
          }
          return <div>{record.width === null ? 'null' : record.width}</div>;
        },
      },
      {
        title: 'Address',
        dataIndex: 'address',
        key: 'address',
        width: 300,
        render: (text, record) => {
          if (record.id === this.state.editRow.id) {
            return (
              <input
                onChange={(e) => this.onChangeValueRow('address', e.target.value)}
                style={{ width: '100%' }}
                value={this.state.editRow.address === null ? 'null' : this.state.editRow.address}
              />
            );
          }
          return <div>{record.address === null ? 'null' : record.address}</div>;
        },
      },
      {
        title: 'Created at',
        dataIndex: 'created_at',
        key: 'created_at',
        width: 200,
        render: (text) => <TimeStamp date={text} />,
      },
      {
        title: 'Updated at',
        dataIndex: 'updated_at',
        key: 'updated_at',
        width: 200,
        render: (text) => <TimeStamp date={text} />,
      },
      {
        title: 'Date of Expiry',
        dataIndex: 'expires_at',
        key: 'expires_at',
        width: 180,
        render: (text) => (text === null ? '' : text),
      },
      {
        title: 'Action',
        dataIndex: 'edit',
        key: 'edit',
        fixed: 'right',
        width: 200,
        className: 'fixed-actions-wrapper',
        render: (text, record) => {
          if (this.state.editState === true && record.id === this.state.editRow.id) {
            return (
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Popconfirm title="Sure to save?" onConfirm={() => this.updateCell(record.id)}>
                  <Button className="button-action" size="mini">
                    Save
                  </Button>
                </Popconfirm>
                <Button
                  className="button-action"
                  size="mini"
                  onClick={() => this.setState({ editState: false, editRow: {} })}
                >
                  Cancel
                </Button>
                <Popconfirm title="Sure to save?" onConfirm={() => this.props.deleteCell(record.id)}>
                  <Button className="button-action" size="mini">
                    Delete
                  </Button>
                </Popconfirm>
              </div>
            );
          }
          return (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Button className="button-action" size="mini" onClick={() => this.editRowAction(record)}>
                Edit
              </Button>
              <Popconfirm
                title="Are you sure you want to delete this Cell?"
                onConfirm={() => this.props.deleteCell(record.id)}
              >
                <Button className="button-action" size="mini">
                  Delete
                </Button>
              </Popconfirm>
            </div>
          );
        },
      },
    ];

    return (
      <div style={{ paddingBottom: 40 }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <ButtonAntd
            style={{ marginRight: 10 }}
            disabled={Object.values(this.state.cells).length <= 2}
            onClick={this.resetAllFilters}
          >
            Reset filters
          </ButtonAntd>
          <Checkbox
            className="table-cell-form__inputs_checkbox"
            name="manual"
            onChange={this.switchCheckbox}
            checked={this.state.checkedManual}
          >
            show manual
          </Checkbox>
        </div>
        <Pagination
          className="cell-pagination"
          showSizeChanger
          pageSizeOptions={['10', '20', '50', '100', '500']}
          pageSize={this.state.cells?.per_page}
          onChange={this.onPaginationChange}
          onShowSizeChange={this.onPaginationChange}
          current={this.state.cellsInfo.page}
          total={this.state.cellsInfo.total}
        />
        <Table
          className="cell-import-table"
          loading={this.state.loading}
          rowKey={'id'}
          columns={columns}
          dataSource={this.state.cellsData}
          scroll={{ x: 1800, y: 400 }}
          pagination={false}
        />
      </div>
    );
  }
}

CellTable.defaultProps = {
  cells: {},
};

CellTable.propTypes = {
  cells: PropTypes.shape({
    meta: PropTypes.shape({
      current_page: PropTypes.number,
      per_page: PropTypes.number,
      total: PropTypes.number,
    }),
    data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  }),
  getCells: PropTypes.func.isRequired,
  updateCell: PropTypes.func.isRequired,
  deleteCell: PropTypes.func.isRequired,
};

const mapStateToProps = (store) => ({
  cells: store.files.cells,
});

export default connect(mapStateToProps, { getCells, updateCell, deleteCell })(CellTable);
