import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { Table } from 'antd';

import NavigationMenu from '../../components/navigationMenu';

import api from '../../utils/appApi';

import packages from '../../../package.json';
import licenses from '../../license.json';

import './lincesePage.sass';

const p = Object.entries({ ...packages?.dependencies, ...packages?.devDependencies });

const LicensePage = () => {
  const user = useSelector((store) => store.user);
  const [backEndPackages, setBackEndPackages] = useState([]);

  const license = useMemo(() => {
    return [
      ...p.map((x) => {
        const version = x[1][0] === '^' ? `${x[1].substring(1)}` : `${x[1]}`;
        const path = `${x[0]}@${version}`;
        return { ...licenses[path], name: x[0], version: version };
      }),
      ...backEndPackages,
    ];
  }, [backEndPackages]);

  const columns = [
    {
      title: 'Package',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Repository',
      dataIndex: 'repository',
      key: 'repository',
      render: (value) =>
        value === 'No homepage' ? null : (
          <a href={value} target="_blank">
            {value}
          </a>
        ),
    },
    {
      title: 'Version',
      dataIndex: 'version',
      key: 'version',
    },
    {
      title: 'License',
      dataIndex: 'license',
      key: 'license',
    },
  ];

  useEffect(() => {
    api.app.packages().then((res) => {
      if (res.data?.require && res.data?.['require-dev']) {
        setBackEndPackages([...res.data?.require, ...res.data?.['require-dev']]);
      }
    });
  }, []);

  return (
    <div>
      <NavigationMenu statusPage="license-page" />
      <div className={`license-page ${user.sticky_header ? '--fixed' : ''}`}>
        <Table rowKey="name" columns={columns} dataSource={license} size="small" pagination={{ defaultPageSize: 20 }} />
      </div>
    </div>
  );
};

export default LicensePage;
