import React, { PureComponent } from 'react';

class JobResultImsiSearch extends PureComponent {
  render() {
    if (!this.props?.data?.additional_info?.data) return <div>Data is missing</div>;

    const {
      data: {
        additional_info: { data: info },
      },
    } = this.props;

    if (info)
      return (
        <div className="job-content__default-result">
          <div className="dashboard-page__search-result_info --full-width">
            <div className="dashboard-page__search-result_info__tables">
              <div>
                <div className="table-item">
                  <div className="table-item__name">Country</div>
                  <div className="table-item__value">{info.country}</div>
                </div>
                <div className="table-item">
                  <div className="table-item__name">Found</div>
                  <div className="table-item__value">{info.found ? 'True' : false}</div>
                </div>
                <div className="table-item">
                  <div className="table-item__name">NetworkName</div>
                  <div className="table-item__value">{info.networkName}</div>
                </div>
                <div className="table-item">
                  <div className="table-item__name">NetworkBrand</div>
                  <div className="table-item__value">{info.networkBrand}</div>
                </div>
                <div className="table-item">
                  <div className="table-item__name">NetworkId</div>
                  <div className="table-item__value">{info.networkId}</div>
                </div>
                <div className="table-item">
                  <div className="table-item__name">PlmnId</div>
                  <div className="table-item__value">
                    mnc: {info.plmnId?.mnc}, mcc: {info.plmnId?.mcc}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    else
      return (
        <div className="job-content__default-result">
          <div>Result: NULL</div>
        </div>
      );
  }
}

export default JobResultImsiSearch;
