import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { InboxOutlined } from '@ant-design/icons';
import Dropzone from 'react-dropzone';

class UploadObject extends PureComponent {
  state = {
    percent: 0,
  };

  onDrop = (file) => {
    const config = {
      onUploadProgress: (progressEvent) => {
        const percent = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        if (percent >= 100) {
          this.setState({ percent: 100 });
        } else {
          this.setState({ percent });
        }
      },
    };

    this.props.upload(file[0], config);
  };

  render() {
    const { percent } = this.state;
    return (
      <div>
        <Dropzone accept="text/csv" multiple={false} onDrop={this.onDrop} className="contacts-page__drop-zone">
          <div className="contacts-page__drop-zone_wrapper">
            <p className="contacts-page__drop-zone_icon">
              <InboxOutlined />
            </p>
            <p className="contacts-page__drop-zone_text">Click or drag file (only one) to this area to upload</p>
            <p className="contacts-page__drop-zone_hint">Support only for CSV file</p>
            <div className="contacts-page__drop-zone_percent" style={{ opacity: percent === 0 ? 0 : 1 }}>
              <div className="contacts-page__drop-zone_percent-count" style={{ width: `${percent}%` }}>
                <div className="contacts-page__drop-zone_percent-done" style={{ opacity: percent === 100 ? 1 : 0 }}>
                  Successfully Uploaded
                </div>
              </div>
            </div>
          </div>
        </Dropzone>
      </div>
    );
  }
}

UploadObject.propTypes = {
  upload: PropTypes.func,
};

export default UploadObject;
