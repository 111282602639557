import React, { memo, useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { Empty, Table, Upload, Button, message, Popconfirm } from 'antd';
import { UploadOutlined, DeleteOutlined, SettingFilled } from '@ant-design/icons';

import { deleteSurvey, getSurveys, uploadSurvey } from '../../actions/survey/survey';

import NavigationMenu from '../../components/navigationMenu';

import './survey.sass';
import formatBytes from '../../utils/formatBytes';

const SurveyPage = () => {
  const [tableLoading, setTableLoading] = useState(true);
  const [uploading, setUploading] = useState(false);

  const user = useSelector((store) => store.user);
  const surveys = useSelector((store) => store.survey);
  const dispatch = useDispatch();

  const upload = ({ file, onError, onSuccess }) => {
    setUploading(true);
    dispatch(uploadSurvey(file))
      .then(() => {
        onSuccess('OK');
        setUploading(false);
        getSurveysAction();
      })
      .catch((err) => {
        onError(err);
        setUploading(false);
      });
  };

  const uploadOnChange = (info) => {
    if (info.file.status === 'done') {
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === 'error') {
      message.error(`${info?.file?.error?.errors?.file[0]}`);
    }
  };

  const deleteSurveyAction = ({ id }) => {
    dispatch(deleteSurvey(id));
  };

  const getSurveysAction = () => {
    dispatch(getSurveys())
      .then(() => setTableLoading(false))
      .catch(() => setTableLoading(false));
  };

  useEffect(() => {
    getSurveysAction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const surveyColumn = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Original Name',
      dataIndex: 'original_name',
      key: 'original_name',
    },
    {
      title: 'Path',
      dataIndex: 'path',
      key: 'path',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Size',
      dataIndex: 'size',
      key: 'size',
      render: (size) => <div>{formatBytes(size)}</div>,
    },
    {
      title: 'Files',
      dataIndex: 'files',
      key: 'files',
    },
    {
      title: 'Processed',
      dataIndex: 'processed',
      key: 'processed',
      render: (value, data) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {value}{' '}
          {data.status === 'importing' && (
            <SettingFilled spin style={{ fontSize: '16px', color: '#08c', marginLeft: 5 }} />
          )}
        </div>
      ),
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (_, data) => (
        <Popconfirm
          title="Are you sure you want to delete this survey?"
          onConfirm={() => deleteSurveyAction(data)}
          okText="Delete"
          cancelText="Cancel"
        >
          <DeleteOutlined />
        </Popconfirm>
      ),
    },
  ];

  const fileColumn = [
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
      width: 100,
    },
    {
      title: 'File Name',
      dataIndex: 'file_name',
      key: 'file_name',
      width: 100,
    },
    {
      title: 'Path',
      dataIndex: 'path',
      key: 'path',
      width: 100,
    },
    {
      title: 'Total Rows',
      dataIndex: 'total_rows',
      key: 'total_rows',
      width: 100,
    },
    {
      title: 'Processed Rows',
      dataIndex: 'processed_rows',
      key: 'processed_rows',
      width: 100,
    },
    {
      title: 'File size',
      dataIndex: 'file_size',
      key: 'file_size',
      width: 100,
      render: (size) => <div>{formatBytes(size)}</div>,
    },
  ];

  return (
    <div>
      <NavigationMenu statusPage="cell-data" />
      <div className={`survey-page ${user.sticky_header ? '--fixed' : ''}`}>
        <Upload name="file" customRequest={upload} onChange={uploadOnChange} showUploadList={false}>
          <Button loading={uploading} icon={<UploadOutlined />} style={{ marginBottom: 20 }}>
            Click to Upload
          </Button>
        </Upload>
        <Table
          rowKey="id"
          size="small"
          dataSource={surveys?.data}
          columns={surveyColumn}
          loading={tableLoading}
          expandable={{
            expandedRowRender: (record) => (
              <div>
                {record?.survey_files?.length ? (
                  <Table size="small" rowKey="id" dataSource={record?.survey_files} columns={fileColumn} />
                ) : (
                  <Empty description="No files available" />
                )}
              </div>
            ),
            rowExpandable: (record) => record.name !== 'Not Expandable',
          }}
        />
      </div>
    </div>
  );
};

export default memo(SurveyPage);
