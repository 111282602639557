import React, { PureComponent } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Empty, Spin } from 'antd';

import { searchDataOnChange, setJobVisibility, sortJobsBy, clearJobs } from '../../actions/jobs/jobs';

import JobItem from './jobItem/jobItem';
import ListControls from './listControls';

import { UN_VISIBLE } from './jobConstants';

import './jobsList.sass';

class JobsList extends PureComponent {
  sortOnClick = ({ target: { innerHTML } }) => {
    const { sortJobsBy } = this.props;
    switch (innerHTML) {
      case 'Submit time':
        sortJobsBy('byTime');
        break;
      case 'Unread':
        sortJobsBy('byUnread');
        break;
      case 'Favorite':
        sortJobsBy('byFavorite');
        break;
      default:
        return;
    }
  };

  clearAllJobs = () => {
    const { jobs, clearJobs, teamId } = this.props;
    const jobsIds = jobs.filter((j) => j.visibility && j.team_id === teamId).map((q) => q.id);
    return clearJobs(jobsIds);
  };

  render() {
    const { jobs, searchData, searchDataOnChange, setJobVisibility, sort, teamId } = this.props;

    const jobsItems = [];
    jobs &&
      jobs.length &&
      jobs
        .filter((job) => job.team_id === teamId)
        .forEach((job) => {
          job.visibility > UN_VISIBLE &&
            jobsItems.push(
              <JobItem
                key={job.id}
                data={job}
                searchData={searchData}
                searchDataOnChange={searchDataOnChange}
                setJobVisibility={setJobVisibility}
                clearJobs={clearJobs}
              />,
            );
        });

    return (
      <div className="jobs-list__wrapper">
        {jobsItems.length ? (
          <div id="job-list" className="jobs-list">
            {jobsItems && jobsItems.length && (
              <ListControls sort={sort} sortOnClick={this.sortOnClick} clearAllJobs={this.clearAllJobs} />
            )}
            {jobsItems}
          </div>
        ) : (
          <div className="jobs-list">
            <Spin spinning={jobs === null}>
              <Empty description="No queries available" />
            </Spin>
          </div>
        )}
      </div>
    );
  }
}

JobsList.propTypes = {
  jobs: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.shape({ [PropTypes.string]: PropTypes.shape({ id: PropTypes.number }) }),
  ]),
  searchDataOnChange: PropTypes.func.isRequired,
  setJobVisibility: PropTypes.func.isRequired,
  sortJobsBy: PropTypes.func.isRequired,
  clearJobs: PropTypes.func.isRequired,
  teamId: PropTypes.number,
};

const mapStateToProps = (store) => ({
  jobs: store.jobs.jobList,
  searchData: store.jobs.searchData,
  sort: store.jobs.sort,
  teamId: store.user.team_id,
});

export default connect(mapStateToProps, { searchDataOnChange, setJobVisibility, sortJobsBy, clearJobs })(JobsList);
