import React, { memo, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { Button, Col, Form, Input, Radio, Row } from 'antd';
import { createContact } from '../../actions/contacts/contact';

const { TextArea } = Input;

const AddContactForm = ({ triggerAction, data }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [sex, setSex] = useState('');
  const [isContactCreateLoading, setIsContactCreateLoading] = useState(false);

  const handleChangeSex = (e) => {
    setSex(e.target.value);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const submitNewContact = (values) => {
    setIsContactCreateLoading(true);

    dispatch(createContact(values))
      .then(() => {
        form.resetFields();
        setSex('');
        setOpen(false);
        triggerAction('list');
        setIsContactCreateLoading(false);
      })
      .catch(() => setIsContactCreateLoading(false));
  };

  const showModal = () => {
    setOpen(true);
  };

  const handleFormStyles = (open) => ({
    height: open ? 220 : 0,
    width: 'calc(250% - 30px)',
    opacity: open ? 1 : 0,
    position: 'absolute',
    top: 55,
    right: 15,
    transition: 'all 300ms ease-in-out',
    background: '#f6f6f6',
    padding: '15px 15px 0 15px',
    border: '1px solid #ffffff',
  });

  const handleRowStyles = (open) => ({
    height: open ? 275 : 0,
    transition: 'all 300ms ease-in-out',
  });

  useEffect(() => {
    setOpen(false);
    form.setFieldsValue({ sex, phone: data.phone, imsi: data.imsi });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <div style={handleRowStyles(open)}>
      <Button type="primary" disabled={open} onClick={showModal}>
        Add contact
      </Button>
      <Form
        onFinish={submitNewContact}
        layout="vertical"
        form={form}
        initialValues={{ sex, phone: data.phone, imsi: data.imsi }}
        style={handleFormStyles(open)}
      >
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item name="name" label="Name" rules={[{ required: true }]}>
              <Input size="mini" placeholder="Name" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="alias" label="Alias">
              <Input size="mini" placeholder="Alias" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="phone" label="Phone">
              <Input size="mini" placeholder="Phone" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item name="imsi" label="IMSI">
              <Input size="mini" placeholder="IMSI" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="description" label="Description">
              <Input placeholder="Description" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="sex" label="Gender">
              <Radio.Group onChange={handleChangeSex}>
                <Radio value="1">Male</Radio>
                <Radio value="0">Female</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item>
              <Button
                type="secondary"
                style={{ marginRight: 15 }}
                onClick={handleCancel}
                disabled={isContactCreateLoading}
              >
                Cancel
              </Button>
              <Button type="primary" htmlType="submit" loading={isContactCreateLoading}>
                Create new contact
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

AddContactForm.defaultProps = {
  triggerAction: () => null,
  data: PropTypes.shape({
    phone: '',
    imsi: '',
  }),
};

AddContactForm.propTypes = {
  triggerAction: PropTypes.func,
  data: PropTypes.shape({
    phone: PropTypes.number,
    imsi: PropTypes.string,
  }),
};

export default memo(AddContactForm);
