import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Header } from 'semantic-ui-react';
import { Input, Button, Table, Popconfirm } from 'antd';

import { storeAgent, deleteAgent } from '../../../actions/agents/smsNotifications';

import './agents.sass';
import PhoneInput from 'react-phone-input-2';

class Agents extends PureComponent {
  state = {
    name: '',
    phone: '',
  };

  changeName = (e) => this.setState({ name: e.target.value });
  changePhone = (phone) => this.setState({ phone });

  addAgent = () => {
    const { name, phone } = this.state;
    if (name !== '' && phone !== '') {
      this.props.storeAgent({ name, phone: `+${phone}` }).then(() => this.setState({ name: '', phone: '' }));
    }
  };

  deleteAgent = (id) => this.props.deleteAgent(id);

  render() {
    const { agents } = this.props;
    const { name, phone } = this.state;

    const columns = [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        width: '40%',
      },
      {
        title: 'Phone',
        dataIndex: 'phone',
        key: 'phone',
        width: '40%',
      },
      {
        title: 'Action',
        dataIndex: 'action',
        key: 'action',
        width: '20%',
        render: (e, data) => (
          <Popconfirm
            title="Are you sure you want to delete this Agent?"
            onConfirm={() => this.deleteAgent(data.id)}
            okText="Yes"
            cancelText="No"
          >
            <Button size="small" type="danger">
              Delete
            </Button>
          </Popconfirm>
        ),
      },
    ];

    return (
      <div className="profile-agents">
        <Header as="h3">Agents</Header>
        <div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Input
              onChange={this.changeName}
              placeholder="Name"
              style={{ width: '300px', marginRight: '10px', height: '35px' }}
              value={name}
            />
            <PhoneInput
              style={{ width: '300px', marginRight: '10px' }}
              country={'gb'}
              value={this.state.phone}
              onChange={this.changePhone}
            />
            <Button disabled={name === '' || phone === ''} onClick={this.addAgent}>
              Add
            </Button>
          </div>
        </div>
        <Table
          rowKey="id"
          className="profile-agents__table"
          size="small"
          columns={columns}
          dataSource={Object.values(agents).reverse()}
          pagination={{ pageSize: 4 }}
        />
      </div>
    );
  }
}

Agents.defaultProps = {
  agents: {},
};

Agents.propTypes = {
  agents: PropTypes.shape({}),
  storeAgent: PropTypes.func.isRequired,
  deleteAgent: PropTypes.func.isRequired,
};

const mapStateToProps = (store) => ({
  agents: store.agents,
});

export default connect(mapStateToProps, { storeAgent, deleteAgent })(Agents);
