import { GET_SERVICE_STATUS } from '../../constants/actionTypes';
import initialState from '../user/initState';

export default function serviceStatus(state = { serviceStatus: null }, action = {}) {
  switch (action.type) {
    case GET_SERVICE_STATUS:
      return { ...action.payload };
    default:
      return state;
  }
}
