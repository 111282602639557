import { notification } from 'antd';
import { GET_AGENTS, STORE_AGENT, DELETE_AGENT } from '../../constants/actionTypes';
import api from '../../utils/appApi';

export const getAgents = () => (dispatch) =>
  new Promise((resolve, reject) =>
    api.agents
      .getAgents()
      .then((res) => {
        dispatch({
          type: GET_AGENTS,
          payload: res.data.data,
        });
        return resolve(res.data.data);
      })
      .catch((err) => reject(err)),
  );

export const storeAgent = (data) => (dispatch) =>
  new Promise((resolve, reject) =>
    api.agents
      .storeAgent(data)
      .then((res) => {
        dispatch({
          type: STORE_AGENT,
          payload: res.data.data,
        });
        return resolve(res.data.data);
      })
      .catch((err) => {
        notification.error({
          message: 'Error',
          description: err.response.data.errors.phone[0],
        });
        return reject(err);
      }),
  );

export const deleteAgent = (id) => (dispatch) =>
  new Promise((resolve, reject) =>
    api.agents
      .deleteAgent(id)
      .then((res) => {
        dispatch({
          type: DELETE_AGENT,
          payload: id,
        });
        return resolve(res.data.data);
      })
      .catch((err) => reject(err)),
  );

export const sendMessage = (data) => () =>
  new Promise((resolve, reject) =>
    api.agents
      .sendMessage(data)
      .then(() => {
        notification.success({
          message: 'Success',
          description: 'Information was sent',
        });
        return resolve();
      })
      .catch((err) => {
        notification.success({
          message: 'Error',
          description: 'Something went wrong...',
        });
        return reject(err);
      }),
  );
