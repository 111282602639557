import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { DeleteOutlined, PlusCircleOutlined, RightCircleOutlined, UploadOutlined } from '@ant-design/icons';

import { Table, Popconfirm, Popover } from 'antd';

import { deleteContact, uploadContacts, createContact } from '../../../actions/contacts/contact';
import { getSearchJobs } from '../../../actions/jobs/jobs';

import TimeStamp from '../../../utils/timeStamp';
import PermissionHandler from '../../../utils/permissionsHandler';
import CreateContactForm from './createContactForm';
import UploadObject from '../uploadObject';
import getColumnSearchProps from '../getColumnSearchProps';

import history from '../../../utils/history';

import './contactTable.sass';

class ContactTable extends PureComponent {
  state = {
    searchText: '',
    searchedColumn: '',
    pagination: {
      current: 1,
      pageSize: 5,
    },
    isVisiblePopover: false,
  };

  viewContact = (id) => history.push(`/contact/${id}`);

  handleTableChange = (pagination) => {
    this.setState({ pagination });
  };

  deleteContactAction = (id) => {
    this.props.deleteContact(id).then(() => this.props.getSearchJobs());
  };

  setVisiblePopover = (status) => {
    this.setState({ isVisiblePopover: status });
  };

  setHiddenPopover = (status) => {
    this.setState({
      isVisiblePopover: status,
    });
  };

  render() {
    const { data, deleteContact, createContact } = this.props;
    const {
      pagination: { pageSize },
    } = this.state;

    const columns = [
      {
        title: 'Contact name',
        dataIndex: 'name',
        width: 120,
        ...getColumnSearchProps('name', this),
      },
      {
        title: 'Alias',
        dataIndex: 'alias',
        width: 100,
        ...getColumnSearchProps('alias', this),
      },
      {
        title: 'Phone/IMSI',
        dataIndex: 'phone-imsi',
        width: 140,
        ...getColumnSearchProps('phone-imsi', this),
      },
      {
        title: 'Created at',
        dataIndex: 'created_at',
        width: 100,
        sorter: (a, b) => new Date(a.created_at) - new Date(b.created_at),
        render: (created_at) => <TimeStamp date={created_at} format="YYYY-MM-DD" />,
      },
      {
        title: '',
        dataIndex: 'id',
        width: 50,
        render: (id) => (
          <div className="dashboard-tables__table_actions-wrapper" style={{ textAlign: 'center' }}>
            <RightCircleOutlined
              className="dashboard-tables__table_action-enter"
              onClick={() => this.viewContact(id)}
            />
            <PermissionHandler button="contact-delete">
              <Popconfirm
                style={{ zIndex: 100 }}
                title="Are you sure you want to delete this Contact?"
                okText="Yes"
                cancelText="No"
                onConfirm={() => this.deleteContactAction(id)}
                placement="topRight"
              >
                <DeleteOutlined className="dashboard-tables__table_action-delete" />
              </Popconfirm>
            </PermissionHandler>
          </div>
        ),
      },
    ];
    return (
      <div className={`table-component${data && data.length === 0 ? ' table-component--fix-empty-height' : ''}`}>
        <div className="dashboard-tables__create-wrapper">
          <Popover
            content={
              <div className="dashboard-tables__create-form">
                <CreateContactForm setVisibility={this.setHiddenPopover} createContact={createContact} />
              </div>
            }
            title={null}
            className="dashboard-tables__create-action"
            placement="bottomLeft"
            trigger="click"
            visible={this.state.isVisiblePopover}
            onVisibleChange={this.setVisiblePopover}
          >
            <PlusCircleOutlined />
            <span className="dashboard-tables__create-action_label">Create contact</span>
          </Popover>
          <Popover
            content={
              <div className="dashboard-tables__create-form">
                <UploadObject upload={this.props.uploadContacts} />
              </div>
            }
            title={null}
            className="dashboard-tables__create-action"
            placement="bottomLeft"
            trigger="click"
          >
            <UploadOutlined />
            <span className="dashboard-tables__create-action_label">Upload contacts</span>
          </Popover>
        </div>
        <Table
          onChange={this.handleTableChange}
          columns={columns}
          dataSource={data}
          pagination={
            data && data.length > 5
              ? { pageSize: pageSize, showSizeChanger: true, defaultPageSize: 5, pageSizeOptions: ['5', '10', '20'] }
              : false
          }
          size="small"
          rowKey="id"
          loading={data === null}
          scroll={{ x: 'auto' }}
        />
      </div>
    );
  }
}

ContactTable.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      alias: PropTypes.string,
      primary_terminal: PropTypes.shape({
        imsi: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([null])]),
        phone: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([null])]),
      }),
      created_at: PropTypes.string,
    }),
    PropTypes.oneOf([null]),
  ),
  deleteContact: PropTypes.func,
  uploadContacts: PropTypes.func,
};

ContactTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
  deleteContact: PropTypes.func,
  getSearchJobs: PropTypes.func,
};

const mapStateToProps = (store) => ({
  data: store.contact.allContacts.contacts ? Object.values(store.contact.allContacts.contacts).reverse() : null,
});

export default connect(mapStateToProps, { deleteContact, createContact, uploadContacts, getSearchJobs })(ContactTable);
