import { notification } from 'antd';
import {
  FIRE_ALERT,
  SET_TOKEN_TIME,
  GET_APP_DATA,
  TOGGLE_APP_STATUS,
  NOTICE_MESSAGE,
  SEARCH_ON_GOING,
  TOGGLE_DARK_MODE,
  SET_DARK_MODE,
  ADD_HEATMAP_SETTING,
  EDIT_HEATMAP_SETTING,
  DELETE_HEATMAP_SETTING,
} from '../../constants/actionTypes';
import api from '../../utils/appApi';

export const updateAppData = (data) => (dispatch) =>
  new Promise((resolve, reject) =>
    api.app
      .updateAppData(data)
      .then((res) => {
        dispatch({
          type: SET_TOKEN_TIME,
          payload: res.data.data,
        });
        return resolve(res.data.data);
      })
      .catch((err) => {
        const error = !err.response.data.errors.access_token_lifetime
          ? ''
          : err.response.data.errors.access_token_lifetime[0];
        dispatch({
          type: FIRE_ALERT,
          payload: {
            specificMessage: `${error}`,
            message: err.response.data.message,
          },
        });
        return reject(err);
      }),
  );

export const sendNoticeMessage = (notice) => (dispatch) =>
  api.app.sendNoticeMessage(notice).then(() =>
    dispatch({
      type: NOTICE_MESSAGE,
      payload: '',
    }),
  );

export const getAppData = () => (dispatch) =>
  api.app.getAppData().then((res) =>
    dispatch({
      type: GET_APP_DATA,
      payload: res.data.data,
    }),
  );

export const toggleAppStatus = () => (dispatch) =>
  api.app.toggleAppStatus().then((res) =>
    dispatch({
      type: TOGGLE_APP_STATUS,
      payload: res.data.data,
    }),
  );

export const generateCredits = () => (dispatch) =>
  new Promise((resolve, reject) =>
    api.app
      .generateCredits()
      .then((res) => {
        notification.config({
          duration: 8,
        });
        notification.success({
          message: 'Success',
          description: 'Records were generated',
        });
        return resolve(res.data.data);
      })
      .catch((err) => {
        dispatch({
          type: FIRE_ALERT,
          payload: {
            specificMessage: 'Error',
            message: 'Credits generation filed',
          },
        });
        return reject(err);
      }),
  );

export const getLogs = () => () => api.app.getLogs().then((res) => res.data.data);

export const setSearchOnGoing = (state) => (dispatch) => dispatch({ type: SEARCH_ON_GOING, payload: state });

const setClassToHtml = (mode) => {
  const element = document.getElementsByTagName('html');

  if (mode) element[0].classList.add('app--dark-mode');
  else element[0].classList.remove('app--dark-mode');
};

export const setDarkMode = (mode) => (dispatch) => {
  localStorage.setItem('darkMode', mode);
  dispatch({ type: SET_DARK_MODE, payload: mode });
  setClassToHtml(mode);
};

export const toggleDarkMode = () => (dispatch) => {
  const darkMode = localStorage.getItem('darkMode');

  if (darkMode && darkMode === 'true') {
    localStorage.setItem('darkMode', 'false');
    setClassToHtml(false);
  } else if (darkMode && darkMode === 'false') {
    localStorage.setItem('darkMode', 'true');
    setClassToHtml(true);
  } else {
    localStorage.setItem('darkMode', 'true');
    setClassToHtml(true);
  }

  dispatch({ type: TOGGLE_DARK_MODE });
};

export const getHeatMapSettings = () => () =>
  new Promise((resolve, reject) =>
    api.app
      .getHeatMapSettings()
      .then((res) => {
        return resolve(res.data.data);
      })
      .catch((err) => reject(err)),
  );

export const addHeatMapSetting = (data) => (dispatch) =>
  new Promise((resolve, reject) =>
    api.app
      .addHeatMapSetting(data)
      .then((res) => {
        dispatch({ type: ADD_HEATMAP_SETTING, payload: res.data.data });
        return resolve(res.data.data);
      })
      .catch((err) => reject(err)),
  );

export const editHeatMapSetting = (id, data) => (dispatch) =>
  new Promise((resolve, reject) =>
    api.app
      .editHeatMapSetting(id, data)
      .then((res) => {
        dispatch({ type: EDIT_HEATMAP_SETTING, payload: { id, data } });
        return resolve(res.data.data);
      })
      .catch((err) => reject(err)),
  );

export const deleteHeatMapSetting = (id) => (dispatch) =>
  new Promise((resolve, reject) =>
    api.app
      .deleteHeatMapSetting(id)
      .then((res) => {
        dispatch({ type: DELETE_HEATMAP_SETTING, payload: id });
        return resolve(res.data.data);
      })
      .catch((err) => reject(err)),
  );

export const getApplicationVersion = () => api.app.appVersion();
