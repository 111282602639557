import {
  GET_GLOBAL_NOTIFICATIONS,
  GET_LOCAL_NOTIFICATIONS,
  LOGOUT,
  UPDATE_GLOBAL_NOTIFICATIONS,
  UPDATE_LOCAL_NOTIFICATIONS,
} from '../../constants/actionTypes';
import initialState from './initState';

export default function smsNotifications(state = initialState, action = {}) {
  switch (action.type) {
    case GET_GLOBAL_NOTIFICATIONS:
      return {
        ...state,
        globalNotifications: action.payload,
      };
    case GET_LOCAL_NOTIFICATIONS:
      return {
        ...state,
        localNotifications: action.payload,
      };
    case UPDATE_GLOBAL_NOTIFICATIONS:
      return {
        ...state,
        globalNotifications: {
          ...state.globalNotifications,
          [action.payload.id]: action.payload,
        },
      };
    case UPDATE_LOCAL_NOTIFICATIONS:
      return {
        ...state,
        localNotifications: {
          ...state.localNotifications,
          [action.payload.id]: action.payload,
        },
      };
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
}
