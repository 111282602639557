import axios from 'axios';
import { pix } from './apiConfig';
import { searchUnsavedContacts } from '../actions/unsavedContacts/unsavedContacts';

export default {
  app: {
    updateAppData: (data) => axios.post('/application/set-app-credentials', data),
    toggleAppStatus: () => axios.get('/application/status'),
    getAppData: () => axios.get('/application/data'),
    generateCredits: () => axios.get('/credits/allocate-credits'),
    sendNoticeMessage: (notice) => axios.post('/application/set-notice', { notice }),
    getLogs: () => axios.get('/trackers/logs'),
    getHeatMapSettings: () => axios.get('heat-map'),
    addHeatMapSetting: (data) => axios.post('/heat-map/add-setting', data),
    editHeatMapSetting: (id, data) => axios.put(`/heat-map/update/${id}`, data),
    deleteHeatMapSetting: (id) => axios.delete(`/heat-map/delete/${id}`),
    appVersion: () => axios.get('/application/version'),
    packages: () => axios.get('/application/packages'),
  },
  serviceStatus: {
    getServiceStatus: () => axios.get('/application/service-status'),
  },
  user: {
    login: (loginData) =>
      axios.post('/oauth/token', {
        pix,
        client_id: 2,
        scope: '*',
        grant_type: 'password',
        username: loginData.name,
        password: loginData.pass,
      }),

    getUser: () => axios.get('/users/get-user'),
    logOut: () => axios.get('/logout'),
    changeTimeZone: (id, timezone) => axios.put(`/users/edit/${id}`, { timezone }),
    editUser: (userId, data) => axios.put(`/users/edit/${userId}`, data),
    switchHeader: (id, value) => axios.put(`/users/edit/${id}`, { sticky_header: value }),
    switchSoundNotification: (id, value) => axios.put(`/users/edit/${id}`, { sound: value }),
    switchBrowserNotification: (id, value) => axios.put(`/users/edit/${id}`, { browser_notification: value }),
  },
  users: {
    getAllUsers: () => axios.get('/users').then((res) => res),
    editUser: (userId, data) => axios.put(`/users/edit/${userId}`, data),
    deleteUser: (userId) => axios.delete(`/users/suspend/${userId}`),
    restoreUser: (userId) => axios.get(`/users/restore/${userId}`),
    addPermissionsToUser: (userId, permissions) => axios.post(`/permissions/add/${userId}`, permissions),
    createUser: (data) => axios.post('/users/create', data),
    editAdminCredits: (adminId, credits) =>
      axios.put(`/credits/admin-credits/${adminId}`, { allocated_credits: credits }),
    editUserCredits: (userId, credits) => axios.put(`/credits/user-credits/${userId}`, credits),
    deleteUserForever: (userId, data) => axios.delete(`/users/force-delete/${userId}`, { params: { ...data } }),
  },
  contact: {
    getContacts: () => axios.get('/contacts'),
    getContact: (contactId, filter) => axios.post(`/contacts/${contactId}`, filter),
    searchContact: (page, perPage, searchText, groupId, column, sort) =>
      axios.post('contacts/search-contact', {
        page,
        per_page: perPage,
        search_term: searchText,
        contact_group_id: groupId,
        column,
        sort,
      }),
    createContact: (contactData) => axios.post('/contacts/create', contactData),
    setPrimary: (terminalId) => axios.get(`/terminals/make-primary/${terminalId}`),
    saveTerminal: (terminalId, data) => axios.put(`/terminals/edit/${terminalId}`, data),
    saveContact: (contactId, data) => axios.put(`/contacts/edit/${contactId}`, data),
    addTerminal: (contactId, data) => axios.post(`/terminals/create/${contactId}`, data),
    archiveTerminal: (terminalId) => axios.get(`/terminals/archive-terminal/${terminalId}`),
    deleteTerminal: (terminalId) => axios.delete(`/terminals/delete/${terminalId}`),
    locateContact: (terminals) => axios.post('/search/multiple-search', terminals),
    locateContactValidate: (terminals) => axios.post('/search/manual-search-validation', terminals),
    getDashboardData: () => axios.get('/search'),
    getSingleSearch: (data) => axios.post('/search/single-search', data),
    getImsiByPhone: (phone) => axios.post('/search/phone', { phone }),
    getPhoneByImsi: (imsi) => axios.post('/search/imsi', { imsi }),
    getForwarding: (phone) => axios.post('search/forwarding', { phone }),
    getDos: (imsi) => axios.post('/search/dos', { imsi }),
    getE164: (data) => axios.post('/search/e164', data),
    getE164validation: (phone, source_type) =>
      axios.post('/search/e164', { phone: phone, source_type: source_type, query_type: 'passive' }),
    getE212: (data) => axios.post('/search/e212', data),
    getE212validation: (imsi, source_type) =>
      axios.post('/search/e212', { imsi: imsi, source_type: source_type, query_type: 'passive' }),
    saveLocationFroTerminal: (terminalId, locationId) => axios.post(`/locations/create/${terminalId}/${locationId}`),
    deleteContact: (contactId) => axios.delete(`/contacts/delete/${contactId}`),
    getComment: (contactId) => axios.get(`/comments/${contactId}`),
    createComment: (contactId, text) => axios.post(`/comments/create/${contactId}`, { text }),
    editComment: (commentId, text) => axios.put(`/comments/edit/${commentId}`, { text }),
    deleteComment: (commentId) => axios.delete(`/comments/delete/${commentId}`),
    uploadContacts: (file, config) => axios.post('/contacts/upload', file, config),
    getPlots: (obj) => axios.post('/search/get-plotted-cells', { ...obj }),
    getHeatPoints: (string) =>
      axios({
        method: 'post',
        url: `${window.location.origin}/micro/v1/getData`,
        data: {
          unique_id: string,
        },
      }),
  },
  unsavedContacts: {
    searchUnsavedContacts: (page, perPage, searchText) =>
      axios.post('/contacts/unsaved', { page: page, per_page: perPage, search_term: searchText }),
  },
  teams: {
    getTeams: () => axios.get('/teams'),
    addUserToTeam: (data) => axios.post('/teams/add-to-team', data),
    removeUserFromTeam: (userId, teamId) => axios.post('/teams/remove-from-team', { user_id: userId, team_id: teamId }),
    makeUserTeamLeader: (userId) => axios.get(`/teams/make-team-lead/${userId}`),
    getSingleTeam: (teamId) => axios.get(`/teams/${teamId}`),
    createTeam: (name) => axios.post('/teams/create', { name }),
    deleteTeam: (teamId, data) => axios.delete(`/teams/delete/${teamId}`, { params: { ...data } }),
    switchTeam: (teamId) => axios.post('/teams/user-switch', { team_id: teamId }),
    editTeam: (teamId, name) => axios.put(`/teams/edit/${teamId}`, { name }),
  },
  groups: {
    getGroups: () => axios.get('/contact-groups'),
    getSingleGroup: (groupId, data) => axios.post(`/contact-groups/${groupId}`, data),
    createGroup: (name) => axios.post('/contact-groups/create', { name }),
    editGroup: (groupId, data) => axios.put(`/contact-groups/edit/${groupId}`, data),
    addContactToGroup: (data) => axios.post('/contact-groups/add-contact', data),
    deleteGroup: (groupId) => axios.delete(`/contact-groups/delete/${groupId}`),
    removeContactFromGroup: (data) => axios.post('/contact-groups/remove-contact', data),
    uploadContactsGroup: (file, config) => axios.post('/contacts/upload', file, config),
    searchContactGroup: (text) => axios.post('/contact-groups/search-group', { search_term: text }),
  },
  sensorGroups: {
    getGroups: () => axios.get('/sensor-groups'),
    createSensorGroup: (name) => axios.post('/sensor-groups/create', { name }),
    addSensorToGroup: (sensorId, sensorGroupId, start, end) =>
      axios.post('/sensor-groups/add-sensor', { sensor_id: sensorId, sensor_group_id: sensorGroupId, start, end }),
    showSensorsGroup: (groupId) => axios.post(`/sensor-groups/${groupId}`),
    deleteSensorGroup: (groupId) => axios.delete(`/sensor-groups/delete/${groupId}`),
    removeSensorFromGroup: (sensorId, groupId) =>
      axios.post('/sensor-groups/remove-sensor', { sensor_id: sensorId, sensor_group_id: groupId }),
  },
  robots: {
    getRobots: () => axios.get('/robots'),
    getSingleRobot: (robotId) => axios.get(`/robots/${robotId}`),
    getContactTerminals: (contactId) => axios.get(`/robots/terminals/${contactId}`),
    createRobot: (data) => axios.post('/robots/create', data),
    deleteRobot: (robotId) => axios.delete(`/robots/delete/${robotId}`),
    cloneRobot: (robotId) => axios.get(`/robots/clone/${robotId}`),
    editRobot: (robotId, data) => axios.put(`/robots/edit/${robotId}`, data),
  },
  notifications: {
    markSeen: (notificationId) => axios.get(`/notifications/mark-seen/${notificationId}`),
    deleteNotification: (notificationId) => axios.delete(`/notifications/delete/${notificationId}`),
    markAllSeen: () => axios.get('/notifications/seen-all'),
    deleteAll: () => axios.get('/notifications/delete-all'),
  },
  whiteList: {
    getWhiteList: () => axios.get('/white-list'),
    adToWhiteList: (data) => axios.post('/white-list/create', data),
    deleteFromWhiteList: (whiteListId) => axios.delete(`/white-list/delete/${whiteListId}`),
    getTeamWhiteList: () => axios.get('/ranges'),
    createTeamWhiteList: (data, id) => axios.post(`/ranges/add-range-to-team/${id}`, data),
    deleteTeamFromWhiteList: (whiteListItemId) => axios.delete(`/ranges/delete/${whiteListItemId}`),
  },
  files: {
    addFile: (file, config) => axios.post('/files/upload', file, config),
    getFiles: () => axios.get('/files'),
    getCells: (data) => axios.post('/cells', data),
    updateCell: (cellId, data) => axios.put(`/cells/update/${cellId}`, data),
    deleteCell: (cellId) => axios.delete(`/cells/delete/${cellId}`),
    deleteFolder: (folder) => axios.post('/files/delete-folder', { folder }),
    deleteFile: (file) => axios.post('/files/delete-file', { file }),
    deleteCells: () => axios.get('/cells/delete-all-cells'),
    deleteFileRow: (id) => axios.delete(`/files/delete/${id}`),
  },
  report: {
    getReport: (data) => axios.post('/reports', data),
    downloadReport: (data) => axios.post('/reports', data, { responseType: 'blob' }),
    downloadPdf: (data) => axios.post('/reports', data, { responseType: 'blob' }),
    getArchivedReports: () => axios.get('/reports/archived'),
    archiveReportLocation: (id) => axios.get(`/reports/${id}`),
  },
  cellReport: {
    getCellReport: (data) => axios.post('/cell-reports', data),
    getSensors: (data) => axios.post('/cell-reports/sensors', data),
  },
  smsNotifications: {
    getGlobalNotifications: () => axios.get('/notifications/templates'),
    getLocalNotifications: () => axios.get('/notifications/templates/local'),
    updateGlobalNotifications: (templateId, data) => axios.put(`/notifications/templates/${templateId}`, data),
    updateLocalNotifications: (templateId, data) => axios.put(`/notifications/templates/local/${templateId}`, data),
  },
  agents: {
    getAgents: () => axios.get('/agent/user-agents'),
    storeAgent: (data) => axios.post('/agent', data),
    sendMessage: (data) => axios.post('/agent/forward', data),
    deleteAgent: (id) => axios.delete(`/agent/delete/${id}`),
  },
  search: {
    getSearchJobs: () => axios.get('/search/recent'),
    setJobVisibility: (status, id) => axios.put(`/locations/update-visibility/${id}`, { visibility: status }),
    clearJobs: (locations) => axios.put('/locations/visibility-zero', { locations }),
  },
  survey: {
    getSurveys: () => axios.get('/surveys/folder-records'),
    upload: (data) => axios.post('/surveys/upload-archive', data),
    delete: (id) => axios.delete(`/surveys/delete-folder/${id}`),
  },
};
