import Echo from 'laravel-echo';
// eslint-disable-next-line no-unused-vars
import pusher from 'pusher-js';

import store from '../index';
import { dispatchNotificationOrSound } from './notificationAndSound';
import { getUser } from '../actions/user/auth';
import { getRobots } from '../actions/robots/robots';
import { getSingleRobot, updateRobotData } from '../actions/robots/robots';
import { locateContactResult } from '../actions/contacts/contact';
import { setJobSearchLoading, addJobToList, updateJobPercentageProgress } from '../actions/jobs/jobs';
import querySourceTypes from '../constants/querySourceTypes';
import { locateContactGroupAction } from '../actions/groups/groups';
import { searchTypes } from '../components/searchComponent/searchComponent';
import SessionUnresolvedLocationsHelper from './SessionUnresolvedLocationsHelper';
import { updateSurvey } from '../actions/survey/survey';
import { updateFile } from '../actions/files/files';

export let echo = null;

export const initSocket = (userId) => {
  echo = new Echo({
    key: 'f92fd08e5587042b4496',
    broadcaster: 'pusher',
    wsHost: window.location.hostname,
    // wsPort: 80,
    // enabledTransports: ['ws'],
    encrypted: true,
    authEndpoint: `${window.location.origin}/broadcasting/auth`,
    disableStats: true,
    auth: {
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ` + sessionStorage.getItem('access_token'),
      },
    },
  });

  echo.connector.pusher.connection.bind('disconnected', () => {
    echo.connector.pusher.connect();
  });

  // --------ROBOT LOCATION SOCKET--------
  echo.channel(`dashboard_${userId}`).listen('DashboardQueryRowReservedEvent', (data) => {
    store.dispatch(addJobToList(data.location));
    store.dispatch(setJobSearchLoading(false));
  });
  echo.channel(`dashboard_${userId}`).listen('DashboardLocationResolvedEvent', (data) => {
    const storeData = store.getState();
    const userStore = storeData.user;

    store.dispatch(addJobToList(data.location));
    dispatchNotificationOrSound(
      `${searchTypes[data.location.api_request_type]} search finished`,
      `${window.location.protocol}//${window.location.host}/`,
      data.location.code !== null ? 'error' : 'success',
      userStore,
    );
  });

  // --------SEARCH TIME SOCKET--------
  echo.channel(`search-time_${userId}`).listen('AlertSearchTimeEvent', () => {});

  // --------CREDITS SOCKET--------
  echo.channel(`credits_${userId}`).listen('CreditChangeEvent', () => {
    store.dispatch(getUser());
  });

  // --------PROGRESS UPDATE SOCKET--------
  echo.channel(`progress_update_${userId}`).listen('AlertProgressUpdateEvent', (data) => {
    store.dispatch(updateJobPercentageProgress(data));
    const sessionData = new SessionUnresolvedLocationsHelper();
    sessionData.updateLocationToSession(data);

    let parsedProgress = null;

    // Implementing progress info into SessionStorage for spread the information into JobList Array
    const progressString = sessionStorage.getItem('jobsProgress');
    if (progressString) {
      parsedProgress = JSON.parse(sessionStorage.getItem('jobsProgress'));
      parsedProgress = { ...parsedProgress, [data.apiId]: data };

      if (data.majorStatusUpdate === 100 && data.minorStatusUpdate === 100) {
        delete parsedProgress[data.apiId];
      }
    }

    sessionStorage.setItem('jobsProgress', JSON.stringify(parsedProgress));
  });

  // --------CONTACT LOCATION SOCKET--------
  echo.channel(`contact_${userId}`).listen('ContactLocationRowReservedEvent', (data) => {
    const url = window.location.href;
    const id = parseInt(/[^/]*$/.exec(url)[0], 10);
    const page = url.split('/')[3];
    const storeData = store.getState();

    if (data.location.query_source === querySourceTypes.SOURCE_CONTACT) {
      const locationTime = new Date().toISOString();

      if (id && page) {
        if (data.location.contact_id === id && page === 'contact') {
          store.dispatch(
            locateContactResult({
              locations: { [data.location.id]: { ...data.location, location_time: locationTime } },
            }),
          );
        }
      }
    } else if (data.location.query_source === querySourceTypes.SOURCE_CONTACT_GROUP) {
      const groupStore = storeData.groups;
      if (id && page) {
        if (
          data.location.contact_id &&
          groupStore.singleGroup.contacts[data.location.contact_id] &&
          page === 'groups'
        ) {
          store.dispatch(locateContactGroupAction(data.location));
        }
      }
    }

    const sessionData = new SessionUnresolvedLocationsHelper();
    sessionData.setLocationToSession(data);
  });

  echo.channel(`contact_${userId}`).listen('ContactLocationResolvedEvent', (data) => {
    const url = window.location.href;
    const id = parseInt(/[^/]*$/.exec(url)[0], 10);
    const page = url.split('/')[3];
    const storeData = store.getState();

    if (data.location.query_source === querySourceTypes.SOURCE_CONTACT) {
      // Store Data
      const userStore = storeData.user;
      const contacts = storeData.contact && storeData.contact.allContacts;
      if (id && page) {
        if (data.location.contact_id === id && page === 'contact') {
          store.dispatch(locateContactResult({ locations: { [data.location.id]: data.location } }));
        }
      }

      // Checking if User is in team and not. This is done for avoiding unavailable contacts
      if (userStore.team_id && userStore.team_id === data.location.team_id) {
        dispatchNotificationOrSound(
          `Contact: ${contacts[data.location.contact_id] && contacts[data.location.contact_id].name} was updated`,
          `${window.location.protocol}//${window.location.host}/contact/${
            data && data.location && data.location.contact_id && data.location.contact_id
          }`,
          data.location.code !== null ? 'error' : 'success',
          userStore,
        );
      } else if (data?.location?.contact_id && contacts.length > 0) {
        dispatchNotificationOrSound(
          `Contact: ${contacts[data.location.contact_id] && contacts[data.location.contact_id].name} was updated. `,
          null,
          data.location.code !== null ? 'error' : 'success',
          userStore,
        );
      }
    } else if (data.location.query_source === querySourceTypes.SOURCE_CONTACT_GROUP) {
      const groupStore = storeData.groups;
      if (id && page) {
        if (
          data.location.contact_id &&
          groupStore.singleGroup.contacts[data.location.contact_id] &&
          page === 'groups'
        ) {
          store.dispatch(locateContactGroupAction(data.location));
        }
      }
    }

    const sessionData = new SessionUnresolvedLocationsHelper();
    sessionData.removeLocationFromSession(data);
  });

  const getUrlRobotId = () => {
    const url = window.location.href;
    return parseInt(/[^/]*$/.exec(url)[0], 10);
  };

  // --------ROBOT LOCATION SOCKET--------
  echo.channel(`robot_${userId}`).listen('RobotLocationRowReservedEvent', (data) => {
    const id = getUrlRobotId();

    if (data.location && id === data.location.robot_id) {
      const locationTime = new Date().toISOString();
      store.dispatch(updateRobotData({ ...data.location, location_time: locationTime }));
    }
  });

  echo.channel(`robot_${userId}`).listen('RobotLocationResolvedEvent', (data) => {
    const id = getUrlRobotId();

    if (data.location && id === data.location.robot_id) {
      const locationTime = new Date().toISOString();
      store.dispatch(updateRobotData({ ...data.location, location_time: locationTime }));

      setTimeout(() => {
        store.dispatch(getSingleRobot(data.location.robot_id));
      }, 3000);
    }
  });

  echo.channel(`robot_${userId}`).listen('RobotResultNotificationEvent', (data) => {
    const id = getUrlRobotId();
    const userStore = store.getState().user;

    if (id === data.robot.id) {
      setTimeout(() => {
        store.dispatch(getSingleRobot(data.robot.id));
      }, 3000);
    } else store.dispatch(getRobots());

    store.dispatch(getUser());

    dispatchNotificationOrSound(
      `Robot '${data.robot.name}' finished. `,
      `${window.location.protocol}//${window.location.host}/robots/${data.robot.id}`,
      data.robot.id ? 'success' : 'error',
      userStore,
    );
  });

  // --------SURVEY SOCKET--------
  echo.channel(`survey_${userId}`).listen('FolderBeginImportEvent', (data) => {
    store.dispatch(updateSurvey(data.folder));
  });
  echo.channel(`survey_${userId}`).listen('FolderImportProgressEvent', (data) => {
    store.dispatch(updateSurvey(data.folder));
  });
  echo.channel(`survey_${userId}`).listen('FolderImportedEvent', (data) => {
    store.dispatch(updateSurvey(data.folder));
  });
  echo.channel(`survey_${userId}`).listen('FolderDeletedEvent', (data) => {
    store.dispatch(updateSurvey(data.folder));
  });

  // --------CELL IMPORT--------
  echo.channel(`cell_data_import_${userId}`).listen('CellDataBeginImportEvent', (data) => {
    store.dispatch(updateFile(data.cellDataFile));
  });
  echo.channel(`cell_data_import_${userId}`).listen('CellDataImportProgressEvent', (data) => {
    store.dispatch(updateFile(data.cellDataFile));
  });
  echo.channel(`cell_data_import_${userId}`).listen('CellDataImportedEvent', (data) => {
    store.dispatch(updateFile(data.cellDataFile));
  });
};
