import React, { PureComponent } from 'react';

import ContactTable from './contactTable/contactTable';
import GroupTable from './groupsTable/groupTable';

import './dashboardTables.sass';

class DashboardTables extends PureComponent {
  render() {
    return (
      <div className="dashboard-tables">
        <div className="dashboard-tables__left">
          <ContactTable />
        </div>
        <div className="dashboard-tables__right">
          <GroupTable />
        </div>
      </div>
    );
  }
}

export default DashboardTables;
