import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Tab, Button, Icon } from 'semantic-ui-react';
import { EditOutlined } from '@ant-design/icons';
import { Select, DatePicker, Input, Button as AntdButton } from 'antd';
import moment from 'moment-timezone';

import {
  getSingleGroup,
  addContactToGroup,
  locateContactGroup,
  removeContactFromGroup,
  editGroup,
} from '../../actions/groups/groups';
import { getAllContacts, getPlots } from '../../actions/contacts/contact';
import { deleteRobotGroup } from '../../actions/robots/robots';
import { getLocalNotifications } from '../../actions/smsNotifications/smsNotifications';
import { sendMessage } from '../../actions/agents/smsNotifications';

import NavigationMenu from '../../components/navigationMenu';
import ExpandAbleTable from '../../components/expandableTables/GroupContactsTable';
import ExpandAbleTableAll from '../../components/expandableTables/contactHistoryTable';
import RobotInfoVIew from '../../components/robotInfoView';
import DropDown from '../../components/addDropDown';
import setRange from '../../utils/setRange';
import history from '../../utils/history';
import PermissionHandler from '../../utils/permissionsHandler';

import querySourceTypes from '../../constants/querySourceTypes';
import NewMap from '../../components/newMap/newMap';

import './groupViewPage.sass';

class GroupViewPage extends Component {
  state = {
    tab: 0,
    loading: true,
    newContact: {
      createSuccess: false,
      loading: true,
      contactId: null,
    },
    locationInfo: {
      type: 'all-markers',
      zoom: 2,
      markers: [],
    },
    allLocations: {
      dateToShow: '',
    },
    plotsView: false,
    editState: false,
    groupName: '',
  };
  componentDidMount() {
    this.props.getSingleGroup(this.props.match.params.groupId).then((res) => {
      const markers = Object.values(res.payload.contacts).map((contact) => {
        const marker = {
          ...contact.lastLocation,
          contactName: contact.name,
        };
        return marker;
      });
      this.setState({
        loading: false,
        locationInfo: {
          ...this.state.locationInfo,
          markers,
        },
        groupName: res.payload.name,
      });
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      allLocations: {
        ...this.state.allLocations,
        dateToShow: moment().tz(this.props.timeZone),
      },
      // groupName: '',
    });
    if (nextProps.group.contacts && this.state.locationInfo.type !== 'single-marker') {
      const markers = Object.values(nextProps.group.contacts).map((contact) => contact.lastLocation);
      this.setState({
        locationInfo: {
          ...this.state.locationInfo,
          markers,
        },
      });
    }
  }

  handleContactSelect = (e, i) => {
    this.setState({ newContact: { ...this.state.newContact, contactId: i.value } });
  };

  addContactToGroup = () => {
    const { newContact } = this.state;
    const { groupId } = this.props.match.params;
    this.setState({ newContact: { ...newContact, loading: true } });
    if (newContact.contactId !== null) {
      this.props
        .addContactToGroup({ contact_id: newContact.contactId, group_id: groupId })
        .then(() => this.setState({ newContact: { createSuccess: false, loading: false, contactId: null } }));
    }
  };

  locateContact = (idTerminal) =>
    this.props
      .locateContactGroup({
        terminals: [idTerminal],
        query_type: 'passive',
        query_source: querySourceTypes.SOURCE_CONTACT_GROUP,
        api_request_type: 1,
      })
      .then((res) => {
        // const key = Object.keys(res).map((positionKey) => positionKey);
        // const zoom = setRange(res[key].range);
        // if (res[key].lat !== null) {
        //   this.setState({
        //     locationInfo: {
        //       ...this.state.locationInfo,
        //       type: 'single-marker',
        //       zoom,
        //       markers: [
        //         {
        //           lat: parseFloat(res[key].lat, 10),
        //           lng: parseFloat(res[key].lng),
        //           title: `${res[key].name}`,
        //           hour: res[key].updated_at,
        //           range: res[key].range,
        //         },
        //       ],
        //     },
        //   });
        // }
        return res;
      });

  showContactLocation = (location) => {
    const zoom = setRange(location.range);
    this.setState({
      locationInfo: {
        ...this.state.locationInfo,
        type: 'single-marker',
        zoom,
        markers: [location],
      },
    });
  };

  showAllLocations = () => {
    const markers = Object.values(this.props.group?.contacts || []).map((contact) => {
      const marker = {
        ...contact.lastLocation,
        contactName: contact.name,
      };
      return marker;
    });

    this.setState({
      locationInfo: {
        type: 'all-markers',
        zoom: 2,
        markers,
      },
    });
  };

  allHistoryShowLocations = () => {
    const markers = Object.values(this.props.group?.common_locations || []).map((contact) => {
      const marker = {
        ...contact,
        contactName: contact.name,
      };
      return marker;
    });

    this.setState({
      locationInfo: {
        type: 'all-markers',
        zoom: 2,
        markers,
      },
    });
  };

  setDate = (date) => {
    this.setState({
      loading: true,
      locationInfo: {
        type: 'all-markers',
        zoom: 2,
        markers: [],
      },
    });
    const dateTz =
      this.state.allLocations.dateToShow === null ? '' : date ? moment(date).tz(this.props.timeZone) : null;
    this.props
      .getSingleGroup(this.props.match.params.groupId, { date: date ? moment(date).utc(0).format('YYYY-MM-DD') : '' })
      .then((res) => {
        const markers = Object.values(res.payload.common_locations).map((contact) => {
          const marker = {
            ...contact,
            contactName: contact.name ? contact.name : '',
          };
          return marker;
        });
        this.setState({
          loading: false,
          locationInfo: {
            type: 'all-markers',
            ...this.state.locationInfo,
            markers,
          },
          allLocations: {
            ...this.state.allLocations,
            dateToShow: dateTz,
            dateToPass: date ? moment(date).utc(0).format('YYYY-MM-DD') : null,
          },
        });
      })
      .catch(() => this.setState({ loading: false }));
  };

  pinAction = (id, lat, lng, title, hour, range, startAngle, endAngle, mcc, mnc, lac, cellId) => {
    const zoom = setRange(range);
    this.setState({
      ...this.state,
      locationInfo: {
        type: 'single-marker',
        zoom,
        markers: [
          { id, lat, lng, title, hour, range, angle_start: startAngle, angle_end: endAngle, mcc, mnc, lac, cellId },
        ],
      },
    });
  };

  tabOnChange = (data) => {
    let contactMarkers = [];
    let historyMarkers = [];
    if (data.activeIndex === 0) {
      contactMarkers =
        this.props.group.contacts &&
        Object.values(this.props.group.contacts).map((contact) => {
          const marker = {
            ...contact.lastLocation,
            contactName: contact.name,
          };
          return marker;
        });
      this.setState({
        tab: data.activeIndex,
        locationInfo: {
          type: 'all-markers',
          zoom: 2,
          markers: contactMarkers,
        },
      });
    } else if (data.activeIndex === 1) {
      historyMarkers = this.props.group.common_locations
        ? Object.values(this.props.group.common_locations).map((contact) => {
            const marker = {
              ...contact,
              contactName: contact.name,
            };
            return marker;
          })
        : [];
      this.setState({
        tab: data.activeIndex,
        locationInfo: {
          type: 'all-markers',
          zoom: 2,
          markers: historyMarkers,
        },
      });
    } else if (data.activeIndex === 2) this.setState({ tab: data.activeIndex });
  };

  createRobot = () => {
    history.push({
      pathname: '/robots',
      state: {
        fromContact: false,
        fromGroup: true,
        groupInfo: {
          id: this.props.group.id,
          name: this.props.group.name,
        },
      },
    });
  };

  getPlots = (e) => {
    if (!this.state.plotsView) {
      const { markers } = e;
      if (!this.props.plotsLocations[markers[0].id]) {
        return this.props
          .getPlots(`${markers[0].mcc}`, `${markers[0].mnc}`, `${markers[0].lac}`, `${markers[0].id}`)
          .then((res) => {
            this.setState({ plotsView: !this.state.plotsView });
            return res;
          });
      }
      this.setState({ plotsView: !this.state.plotsView });
      return new Promise((resolve) => resolve(null));
    }

    this.setState({ plotsView: !this.state.plotsView });
    return new Promise((resolve) => resolve(null));
  };

  toggleEditGroup = () => this.setState({ editState: !this.state.editState, groupName: this.props.group.name });

  groupNameOnChange = (e) => this.setState({ groupName: e.target.value });

  saveGroupName = () =>
    this.props.editGroup(this.props.group.id, { name: this.state.groupName }).then(() => this.toggleEditGroup());

  render() {
    const { darkMode } = this.props;
    const { editState, groupName } = this.state;
    const allRobots =
      this.props.group.group_robots &&
      Object.values(this.props.group.group_robots).length !== 0 &&
      Object.values(this.props.group.group_robots)
        .reverse()
        .map((robot) => {
          const key = `robot-${robot.id}`;
          return (
            <RobotInfoVIew
              key={key}
              robotData={robot}
              tableLoading={this.state.loading}
              deleteRobot={(robotId) => this.props.deleteRobotGroup(robotId)}
            />
          );
        });
    const panes = [
      {
        menuItem: 'Contacts',
        render: () => (
          <Tab.Pane attached={false}>
            <ExpandAbleTable
              showContactLocation={this.showContactLocation}
              loading={this.state.loading}
              contacts={this.props.group.contacts && Object.values(this.props.group.contacts)}
              locate={this.locateContact}
              showAllLocations={this.showAllLocations}
              removeContactFromGroup={(data) => this.props.removeContactFromGroup(data)}
              groupId={this.props.match.params.groupId}
            />
          </Tab.Pane>
        ),
      },
      {
        menuItem: 'All locations history',
        render: () => (
          <Tab.Pane style={{ display: 'flex', justifyContent: 'space-around' }} attached={false}>
            <div className="group-page__all-locations">
              <DatePicker
                format="YYYY/MM/DD"
                onChange={this.setDate}
                value={this.state.allLocations.dateToShow}
                disabled={this.state.loading}
                dateRender={(current) => {
                  const style = {};
                  if (this.props.group.days_with_locations.indexOf(current.format('YYYY-MM-DD')) !== -1) {
                    style.border = '1px solid #1890ff';
                    style.borderRadius = '50%';
                  } else {
                    style.color = '#c0c0c0';
                    style.cursor = 'not-allowed';
                    style['pointer-events'] = 'none';
                  }
                  return (
                    <div className="ant-calendar-date" style={style}>
                      {current.date()}
                    </div>
                  );
                }}
              />
              <Button
                size="mini"
                className="group-page__all-locations__button-all"
                onClick={this.allHistoryShowLocations}
              >
                Show All Contacts
              </Button>
              <ExpandAbleTableAll
                className="group-page__all-locations__table"
                locations={this.props.group.common_locations ? Object.values(this.props.group.common_locations) : []}
                loading={this.state.loading}
                pinAction={(id, lat, lng, name, update, range, startAngle, endAngle, mcc, mnc, lac, cellId) =>
                  this.pinAction(id, lat, lng, name, update, range, startAngle, endAngle, mcc, mnc, lac, cellId)
                }
                agents={this.props.agents}
                sendMessage={this.props.sendMessage}
                darkMode={darkMode}
                sms={this.props.user.sms_service}
                getLocalNotifications={this.props.getLocalNotifications}
              />
            </div>
            <div className="group-page__all-locations-map">
              {navigator.onLine && <NewMap mapInfo={this.state.locationInfo} disablePlotRoute />}
            </div>
          </Tab.Pane>
        ),
      },
      {
        menuItem: 'Robots',
        render: () => (
          <Tab.Pane style={{ display: 'flex', flexWrap: 'wrap', gap: '20px', overflow: 'auto' }} attached={false}>
            {allRobots && allRobots.length ? allRobots : <div>No robots available for this group</div>}
          </Tab.Pane>
        ),
      },
    ];

    const groupContacts = this.props.group?.contacts && Object.values(this.props.group.contacts);

    const contactOptions = this.props.contacts?.allContacts?.contacts
      ? Object.values(this.props.contacts.allContacts.contacts).map((user) => {
          const isDisabled = groupContacts && groupContacts.some((contact) => contact.id === user.id);
          return {
            label: user.name,
            value: user.id,
            disabled: isDisabled,
          };
        })
      : [];

    return (
      <div>
        <NavigationMenu statusPage="group" />
        <div className={`group-page ${this.props.user.sticky_header ? '--fixed' : ''}`}>
          <PermissionHandler button="contact-group-edit">
            <DropDown
              className="group-page__drop-down"
              createSuccess={this.state.newContact.createSuccess}
              buttonName="Add Contact"
              height={55}
              onClick={() =>
                this.props
                  .getAllContacts()
                  .then(() => this.setState({ newContact: { ...this.state.newContact, loading: false } }))
              }
            >
              <div>
                <Select
                  className="group-page__drop-down_select-contact"
                  size="default"
                  combobox={!!1}
                  loading={this.state.newContact.loading}
                  showSearch
                  style={{ width: 200 }}
                  placeholder="Select User for Group"
                  optionFilterProp="children"
                  onChange={(e, i) => this.handleContactSelect(e, i)}
                  filterOption={(input, option) =>
                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  defaultValue={this.state.newContact.contactId}
                  value={this.state.newContact.contactId}
                  options={contactOptions}
                />
                <Button
                  onClick={this.addContactToGroup}
                  loading={this.state.newContact.loading}
                  size="mini"
                  disabled={!this.state.newContact.contactId}
                >
                  Add
                </Button>
              </div>
            </DropDown>
          </PermissionHandler>

          <PermissionHandler button="robot-create">
            <Button size="mini" onClick={this.createRobot} style={{ marginBottom: 15 }}>
              Create Robot <Icon className="contact-view-page__left-part_robot-button" name="add" />
            </Button>
          </PermissionHandler>
          <div className="group-page__tab-area">
            <div className="group-page__tab-area_group-name">
              {editState ? (
                <div className="group-page__tab-area_group-name__edit">
                  <Input value={groupName} onChange={this.groupNameOnChange} />
                  <AntdButton disabled={!groupName} onClick={this.saveGroupName}>
                    Save
                  </AntdButton>
                  <AntdButton onClick={this.toggleEditGroup}>Cancel</AntdButton>
                </div>
              ) : (
                <div className="group-page__tab-area_group-name__name">
                  <span>{groupName}</span>
                  <AntdButton disabled={!groupName} icon={<EditOutlined />} onClick={this.toggleEditGroup} />
                </div>
              )}
            </div>
            <Tab menu={{ pointing: true }} panes={panes} onTabChange={(event, data) => this.tabOnChange(data)} />
          </div>

          {navigator.onLine && this.state.tab === 0 && <NewMap mapInfo={this.state.locationInfo} disablePlotRoute />}
        </div>
      </div>
    );
  }
}

GroupViewPage.defaultProps = {
  group: {},
  contacts: {},
  timeZone: '',
  plotsLocations: {},
  user: {},
};

GroupViewPage.propTypes = {
  getSingleGroup: PropTypes.func.isRequired,
  group: PropTypes.shape({
    contacts: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.array]),
    name: PropTypes.string,
    id: PropTypes.number,
    common_locations: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    group_robots: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    days_with_locations: PropTypes.array,
  }),
  match: PropTypes.shape({
    params: PropTypes.shape({
      groupId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  }).isRequired,
  getAllContacts: PropTypes.func.isRequired,
  contacts: PropTypes.shape({
    allContacts: PropTypes.object,
  }),
  user: PropTypes.shape({
    sticky_header: PropTypes.number,
  }),
  addContactToGroup: PropTypes.func.isRequired,
  locateContactGroup: PropTypes.func.isRequired,
  timeZone: PropTypes.string,
  removeContactFromGroup: PropTypes.func.isRequired,
  deleteRobotGroup: PropTypes.func.isRequired,
  plotsLocations: PropTypes.shape({}),
  getPlots: PropTypes.func.isRequired,
  editGroup: PropTypes.func.isRequired,
  getLocalNotifications: PropTypes.func.isRequired,
  darkMode: PropTypes.bool.isRequired,
  sendMessage: PropTypes.func.isRequired,
  agents: PropTypes.shape({}),
};

const mapStateToProps = (store) => ({
  group: store.groups.singleGroup,
  contacts: store.contact,
  timeZone: store.user.timezone,
  agents: store.agents,
  plotsLocations: store.contact.plotsLocations ? store.contact.plotsLocations : {},
  user: store.user,
  darkMode: store.app.darkMode,
});

export default connect(mapStateToProps, {
  getSingleGroup,
  getAllContacts,
  addContactToGroup,
  locateContactGroup,
  removeContactFromGroup,
  deleteRobotGroup,
  sendMessage,
  getPlots,
  editGroup,
  getLocalNotifications,
})(GroupViewPage);
