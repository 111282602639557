import {
  GET_ROBOTS,
  GET_SINGLE_ROBOT,
  GET_CONTACT_TERMINALS,
  CREATE_ROBOT,
  DELETE_ROBOT,
  EDIT_ROBOT,
  UPDATE_ROBOT_DATA,
  LOGOUT,
  CLONE_ROBOT,
} from '../../constants/actionTypes';
import initialState from './initState';

export default function robots(state = initialState, action = {}) {
  switch (action.type) {
    case GET_ROBOTS: {
      const robots = {};
      Object.values(action.payload).forEach((robot) => (robots[robot.id] = robot));
      return {
        ...state,
        allRobots: robots,
      };
    }
    case GET_SINGLE_ROBOT:
      return {
        ...state,
        singleRobot: {
          ...action.payload,
        },
      };
    case EDIT_ROBOT:
      return {
        ...state,
        singleRobot: {
          ...state.singleRobot,
          ...action.payload,
        },
      };
    case GET_CONTACT_TERMINALS:
      return {
        ...state,
        contactTerminals: action.payload,
      };
    case CREATE_ROBOT:
      return {
        ...state,
        allRobots: {
          ...state.allRobots,
          [action.payload.id]: {
            ...action.payload,
          },
        },
      };
    case DELETE_ROBOT: {
      const robotsObj = state.allRobots;
      delete robotsObj[action.payload.id];
      return {
        ...state,
        allRobots: {
          ...robotsObj,
        },
      };
    }
    case CLONE_ROBOT: {
      const robotsObj = { ...state.allRobots, [action.payload.id]: action.payload };
      return {
        ...state,
        allRobots: robotsObj,
      };
    }
    case UPDATE_ROBOT_DATA: {
      return {
        ...state,
        singleRobot: {
          ...state.singleRobot,
          locations: {
            ...state.singleRobot.locations,
            [action.payload.id]: action.payload,
          },
        },
      };
    }
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
}
