import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import { Button } from 'semantic-ui-react';
import TimeStamp from '../../utils/timeStamp';

import './whiteListTable.sass';

const WhiteListTable = (props) => {
  const columns = [
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: 'IMSI',
      dataIndex: 'imsi',
      key: 'imsi',
    },
    {
      title: 'Created at',
      dataIndex: 'created',
      key: 'created',
      render: (text) => <TimeStamp date={text} />,
    },
    {
      title: 'Updated at',
      dataIndex: 'updated',
      key: 'updated',
      render: (text) => <TimeStamp date={text} />,
    },
    {
      title: 'Action',
      dataIndex: 'remove',
      key: 'remove',
      render: (text, record) => (
        <Button
          onClick={() => props.deleteFromWhiteList(record.id)}
          size="mini"
          loading={props.loading.includes(record.id) && true}
        >
          Remove
        </Button>
      ),
    },
  ];
  const data =
    props.whiteList && Object.values(props.whiteList).length !== 0
      ? Object.values(props.whiteList)
          .reverse()
          .map((list) => {
            const key = `white-list-${list.id}`;
            return {
              key,
              id: list.id,
              loading: props.loading,
              phone: list.phone,
              imsi: list.imsi,
              created: list.created_at,
              updated: list.updated_at,
            };
          })
      : [];

  return <Table className="white-list-table-component" columns={columns} dataSource={data} />;
};

WhiteListTable.defaultProps = {
  loading: 0,
};

WhiteListTable.propTypes = {
  whiteList: PropTypes.shape({}).isRequired,
  // eslint-disable-next-line
  deleteFromWhiteList: PropTypes.func.isRequired,
  loading: PropTypes.arrayOf(PropTypes.number),
};

export default WhiteListTable;
