import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { Header } from 'semantic-ui-react';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Select, Switch } from 'antd';

// eslint-disable-next-line no-unused-vars
import { sounds, configSounds } from '../../../utils/notificationSound';

class SoundNotification extends PureComponent {
  switchSoundNotification = (value) => this.props.switchSoundNotification(this.props.user.id, value === true ? 1 : 0);
  switchBrowserNotification = (value) =>
    this.props.switchBrowserNotification(this.props.user.id, value === true ? 1 : 0);
  selectSuccessOnChange = (id) =>
    this.props
      .editUser(this.props.user.id, { sound_success: id })
      .then(() => configSounds(id, this.props.user.sound_failure));
  selectErrorOnChange = (id) =>
    this.props
      .editUser(this.props.user.id, { sound_failure: id })
      .then(() => configSounds(this.props.user.sound_success, id));

  render() {
    return (
      <div>
        <div>
          <Header as="h3">Browser Notification</Header>
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: 20 }}>
            <Switch
              onChange={this.switchBrowserNotification}
              checked={!!this.props.user.browser_notification}
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
            />
          </div>
        </div>
        <div>
          <Header as="h3">Notification Sound</Header>
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: 20 }}>
            <Switch
              onChange={this.switchSoundNotification}
              checked={!!this.props.user.sound}
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
            />
            <div className="user-profile-page__sound-selects" style={{ display: 'flex' }}>
              <div>Success Sound: </div>
              <Select
                value={this.props.user.sound_success}
                style={{ width: 350 }}
                className="user-profile-page__select"
                dropdownClassName="user-profile-page__drop-down-select-sound"
                placeholder="Select success sound for Notification"
                onChange={this.selectSuccessOnChange}
              >
                {Object.values(sounds).map((sound) => (
                  <Select.Option value={sound.id} key={`success-select-${sound.id}`}>
                    <div>Sound {sound.id}</div>
                    <audio
                      key={`audio-success-sound-key-${sound.id}`}
                      className="user-profile-page__sound-select"
                      controls
                    >
                      <source src={sound.src} type="audio/mp3" />
                    </audio>
                  </Select.Option>
                ))}
              </Select>

              <div style={{ marginLeft: 20 }}>Error Sound: </div>
              <Select
                value={this.props.user.sound_failure}
                style={{ width: 350 }}
                className="user-profile-page__select"
                dropdownClassName="user-profile-page__drop-down-select-sound"
                placeholder="Select error sound for Notification"
                onChange={this.selectErrorOnChange}
              >
                {Object.values(sounds).map((sound) => (
                  <Select.Option value={sound.id} key={`error-select-${sound.id}`}>
                    <div>Sound {sound.id}</div>
                    <audio
                      key={`audio-error-sound-key-${sound.id}`}
                      className="user-profile-page__sound-select"
                      controls
                    >
                      <source src={sound.src} type="audio/mp3" />
                    </audio>
                  </Select.Option>
                ))}
              </Select>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

SoundNotification.propTypes = {
  user: PropTypes.shape({
    sticky_header: PropTypes.number,
    sound: PropTypes.number,
    browser_notification: PropTypes.number,
    id: PropTypes.number,
    sound_success: PropTypes.number,
    sound_failure: PropTypes.number,
  }).isRequired,
  switchSoundNotification: PropTypes.func.isRequired,
  switchBrowserNotification: PropTypes.func.isRequired,
  editUser: PropTypes.func.isRequired,
};

export default SoundNotification;
