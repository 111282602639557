import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Header, Segment } from 'semantic-ui-react';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Switch } from 'antd';

import { switchHeader, switchSoundNotification, editUser, switchBrowserNotification } from '../../actions/user/auth';
import { toggleDarkMode } from '../../actions/app/application';

import NavigationMenu from '../../components/navigationMenu';
import ChangePassword from './ChangePassword';
import ChangeTimeZone from './ChangeTimeZone';
import ChangePhoneEmail from './ChangePhoneEmail';
import NotificationMethod from './ChangeNotificationMethod';
import LocalNotifications from './setUpLocalNotification';
import Agents from './Agents';
import SoundNotification from './SoundNotification';

import './userProfilePage.sass';

class UserProfile extends Component {
  switchSticky = (value) => this.props.switchHeader(this.props.user.id, value === true ? 1 : 0);

  render() {
    const { user, toggleDarkMode, darkMode } = this.props;
    return (
      <div>
        <NavigationMenu statusPage="profile" />
        <Segment vertical>
          <div className={`user-profile-page ${this.props.user.sticky_header ? '--fixed' : ''}`}>
            <div className="user-profile-page__form-block">
              <ChangePassword userId={user.id} />
            </div>
            <div className="user-profile-page__form-block">
              {user.id && <ChangePhoneEmail userInfo={this.props.user} />}
            </div>
            <div>{user.id && <NotificationMethod userInfo={user} />}</div>
            <div className="user-profile-page__form-block">
              <ChangeTimeZone />
            </div>
            <SoundNotification
              user={this.props.user}
              switchSoundNotification={this.props.switchSoundNotification}
              switchBrowserNotification={this.props.switchBrowserNotification}
              editUser={this.props.editUser}
            />
            <div className="user-profile-page__form-block" style={{ marginRight: 40 }}>
              <Header as="h3">Navigation bar sticky</Header>
              <Switch
                onChange={this.switchSticky}
                checked={!!this.props.user.sticky_header}
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
              />
            </div>
            <div className="user-profile-page__form-block" style={{ marginRight: 40 }}>
              <Header as="h3">Dark mode</Header>
              <Switch
                onChange={toggleDarkMode}
                checked={darkMode}
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
              />
            </div>
            <div className="user-profile-page__form-block">
              <LocalNotifications place="profile" />
            </div>
            <div className="user-profile-page__form-block">
              <Agents />
            </div>
          </div>
        </Segment>
      </div>
    );
  }
}

UserProfile.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number,
    sticky_header: PropTypes.number,
    notification: PropTypes.bool,
    sound: PropTypes.number,
  }).isRequired,
  switchHeader: PropTypes.func.isRequired,
  switchSoundNotification: PropTypes.func.isRequired,
  switchBrowserNotification: PropTypes.func.isRequired,
  toggleDarkMode: PropTypes.func.isRequired,
  editUser: PropTypes.func.isRequired,
};

const mapStateToProps = (store) => ({
  user: store.user,
  darkMode: store.app.darkMode,
});

export default connect(mapStateToProps, {
  switchHeader,
  switchSoundNotification,
  switchBrowserNotification,
  editUser,
  toggleDarkMode,
})(UserProfile);
