import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Table, Popconfirm } from 'antd';
import { Button } from 'semantic-ui-react';

import history from '../../../utils/history';
import TimeStamp from '../../../utils/timeStamp';
import PermissionHandler from '../../../utils/permissionsHandler';
import JobErrorContent from '../../jobsList/jobItem/jobContent/jobErrorContent';
import ResultParser from '../../jobsList/jobItem/jobContent/jobResultContent/resultParser';

import './expandableTable.sass';

class ExpandableTableGroupContact extends Component {
  state = {
    expandedEdit: [],
    locating: {},
    loadingContactRemove: {
      state: false,
      id: null,
    },
  };

  locate = (terminalId, button) => {
    this.setState({ locating: { ...this.state.locating, [button]: true } });
    this.props.locate(terminalId).then(() => this.setState({ locating: { ...this.state.locating, [button]: false } }));
  };

  showContactLocation = (data) => {
    this.props.showContactLocation(data);
  };

  expandRow = (expanded, key) => {
    if (this.state.expandedEdit[0] === key) this.setState({ expandedEdit: [] });
    else {
      this.setState({
        expandedEdit: [key],
      });
    }
  };

  removeContactFromGroup = (contact) => {
    this.setState({ loadingContactRemove: { state: true, id: contact.id } });
    this.props
      .removeContactFromGroup({
        contact_id: contact.id,
        group_id: this.props.groupId,
      })
      .then(() => this.setState({ loadingContactRemove: { state: false, id: null } }));
  };

  render() {
    const columns = [
      { title: 'Contact Name', dataIndex: 'contactName', width: '250px', key: 'contactName' },
      { title: 'Alias', dataIndex: 'alias', width: '190px', key: 'alias' },
      { title: 'Primary Terminal', dataIndex: 'terminal', width: '200px', key: 'terminal' },
      { title: 'Last location', dataIndex: 'lastLocation', width: '190', key: 'lastLocation' },
      { title: 'Actions', dataIndex: 'actions', width: '380px', key: 'actions' },
    ];

    const data =
      this.props.contacts && this.props.contacts.length > 0
        ? this.props.contacts.map((contact) => {
            const key = `key-${contact.id}`;
            let terminal = '';
            if (contact.primary_terminal === null) terminal = 'No Terminal';
            else if (contact.primary_terminal.phone !== null) terminal = contact.primary_terminal.phone;
            else terminal = contact.primary_terminal.imsi;
            return {
              key,
              contactName: contact.name,
              alias: contact.alias,
              terminal,
              lastLocation: contact.lastLocation ? (
                <TimeStamp date={contact.lastLocation.location_time} />
              ) : (
                'No Locations'
              ),
              actions: (
                <div style={{ display: 'flex' }}>
                  <Button size="mini" onClick={() => history.push(`/contact/${contact.id}`)}>
                    View Contact
                  </Button>
                  <Button
                    disabled={!contact.lastLocation || !contact.lastLocation.lat}
                    size="mini"
                    onClick={() =>
                      this.showContactLocation({
                        lat: parseFloat(contact.lastLocation.lat),
                        lng: parseFloat(contact.lastLocation.lng),
                        title: `Name: ${contact.name}. Location: ${contact.lastLocation.name}`,
                        startAngle: contact.lastLocation.angle_start,
                        endAngle: contact.lastLocation.angle_end,
                        hour: contact.lastLocation.updated_at,
                        id: contact.lastLocation.id,
                        range: contact.lastLocation.range,
                        mcc: contact.lastLocation.mcc,
                        mnc: contact.lastLocation.mnc,
                        lac: contact.lastLocation.lac,
                        cellId: contact.lastLocation.cell_id,
                      })
                    }
                  >
                    Show
                  </Button>
                  <PermissionHandler button="locate-create">
                    {contact.primary_terminal === null ? (
                      <Button size="mini" disabled>
                        Locate
                      </Button>
                    ) : (
                      <Button
                        name={`button-${contact.id}`}
                        loading={
                          this.state.locating[`button-${contact.id}`] ||
                          (contact.lastLocation && contact.lastLocation.resolved === 0)
                        }
                        onClick={() => this.locate(contact.primary_terminal.id, `button-${contact.id}`)}
                        size="mini"
                      >
                        Locate
                      </Button>
                    )}
                  </PermissionHandler>
                  <PermissionHandler button="contact-group-edit">
                    <Popconfirm
                      title="Are you sure you want to delete this contact?"
                      onConfirm={() => this.removeContactFromGroup(contact)}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Button size="mini">Delete</Button>
                    </Popconfirm>
                  </PermissionHandler>
                </div>
              ),
              expand: (
                <div className="expandable-table-component-contact__expandable">
                  {contact.lastLocation &&
                  (contact.lastLocation.api_request_type === 1 || contact.lastLocation.api_request_type === null) &&
                  !contact.lastLocation.code ? (
                    <React.Fragment>
                      {!contact.lastLocation.code && (
                        <div className="expandable-table-component-contact__expandable_other-information">
                          <div className="expanded-row">
                            <div className="expanded-row__item">
                              <div className="expanded-row__item_name">MCC</div>
                              <div className="expanded-row__item_value">{contact.lastLocation.mcc}</div>
                            </div>
                            <div className="expanded-row__item">
                              <div className="expanded-row__item_name">MNC</div>
                              <div className="expanded-row__item_value">{contact.lastLocation.mnc}</div>
                            </div>
                            <div className="expanded-row__item">
                              <div className="expanded-row__item_name">LAC/TAC</div>
                              <div className="expanded-row__item_value">{contact.lastLocation.lac}</div>
                            </div>
                            <div className="expanded-row__item">
                              <div className="expanded-row__item_name">CELLID</div>
                              <div className="expanded-row__item_value">
                                {contact.lastLocation.cell_id}{' '}
                                {contact.lastLocation.sensor_serial && `(${contact.lastLocation.sensor_serial})`}
                              </div>
                            </div>
                            <div className="expanded-row__item">
                              <div className="expanded-row__item_name">Lat</div>
                              <div className="expanded-row__item_value">{contact.lastLocation.lat}</div>
                            </div>
                            <div className="expanded-row__item">
                              <div className="expanded-row__item_name">Lng</div>
                              <div className="expanded-row__item_value">{contact.lastLocation.lng}</div>
                            </div>
                            <div className="expanded-row__item">
                              <div className="expanded-row__item_name">Range</div>
                              <div className="expanded-row__item_value">
                                {contact.lastLocation.range}{' '}
                                {contact.lastLocation.range !== null && <span style={{ opacity: 0.5 }}>(m)</span>}
                              </div>
                            </div>
                            <div className="expanded-row__item">
                              <div className="expanded-row__item_name">Age</div>
                              <div className="expanded-row__item_value">
                                {contact.lastLocation.age ? `${contact.lastLocation.age}` : '0'}{' '}
                                <span style={{ opacity: 0.5 }}>(min)</span> at{' '}
                                {<TimeStamp date={contact.lastLocation.updated_at} />}{' '}
                                {(contact.lastLocation.age === 0 || contact.lastLocation.age === 1) && (
                                  <span className="age-blink-dot" />
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="expanded-row">
                            <div className="expanded-row__item">
                              <div className="expanded-row__item_name">IMEI</div>
                              <div className="expanded-row__item_value">{contact.lastLocation.imei}</div>
                            </div>
                            <div className="expanded-row__item">
                              <div className="expanded-row__item_name">State</div>
                              <div className="expanded-row__item_value">{contact.lastLocation.state}</div>
                            </div>
                            <div className="expanded-row__item">
                              <div className="expanded-row__item_name">Phone</div>
                              <div className="expanded-row__item_value">{contact.lastLocation.phone}</div>
                            </div>
                            <div className="expanded-row__item">
                              <div className="expanded-row__item_name">IMSI</div>
                              <div className="expanded-row__item_value">{contact.lastLocation.imsi}</div>
                            </div>
                            <div className="expanded-row__item">
                              <div className="expanded-row__item_name">Start Time</div>
                              <div className="expanded-row__item_value">
                                {contact.lastLocation.interval && (
                                  <TimeStamp date={contact.lastLocation.interval.start} />
                                )}
                              </div>
                            </div>
                            <div className="expanded-row__item">
                              <div className="expanded-row__item_name">End Time</div>
                              <div className="expanded-row__item_value">
                                {contact.lastLocation.interval && (
                                  <TimeStamp date={contact.lastLocation.interval.end} />
                                )}
                              </div>
                            </div>
                            <div className="expanded-row__item">
                              <div className="expanded-row__item_name">Locations</div>
                              <div className="expanded-row__item_value">
                                {location.interval?.location_ids
                                  ? Object.values(location.interval.location_ids)?.length
                                  : ''}
                              </div>
                            </div>
                            <div className="expanded-row__item">
                              <div className="expanded-row__item_name">Network</div>
                              <div className="expanded-row__item_value">{contact.lastLocation.network}</div>
                            </div>
                          </div>
                        </div>
                      )}
                      {!contact.lastLocation.code && contact.lastLocation.name && (
                        <div className="expanded-row-location">
                          <div className="expanded-row-location__item">
                            <div className="expanded-row-location__item_name">Location name</div>
                            <div className="expanded-row-location__item_value">{contact.lastLocation.name}</div>
                          </div>
                        </div>
                      )}
                      {contact.lastLocation.code && (
                        <div className="expanded-row-location">
                          <div className="expanded-row-location__item">
                            <div className="expanded-row-location__item_name --location-error-code">
                              {`Error code: ${contact.lastLocation.code}`}
                            </div>
                            <div className="expanded-row-location__item_value">{contact.lastLocation.detail}</div>
                          </div>
                        </div>
                      )}
                    </React.Fragment>
                  ) : contact.lastLocation ? (
                    contact.lastLocation.code ? (
                      <JobErrorContent data={contact.lastLocation} />
                    ) : (
                      <ResultParser data={contact.lastLocation} />
                    )
                  ) : (
                    <div>No last location</div>
                  )}
                </div>
              ),
            };
          })
        : [];

    return (
      <div className="expandable-table-component-group">
        <Button
          size="mini"
          className="expandable-table-component-group__show-all"
          onClick={this.props.showAllLocations}
        >
          Show All Contacts
        </Button>
        <Table
          loading={this.props.loading}
          columns={columns}
          dropdownPrefixCls=""
          onChange={() => null}
          pagination={{ pageSize: 5 }}
          expandedRowRender={(record) => <div>{record.expand}</div>}
          onExpand={(expanded, record) => this.expandRow(expanded, record.key, record)}
          dataSource={data}
          size="middle"
        />
      </div>
    );
  }
}

ExpandableTableGroupContact.defaultProps = {
  contacts: [],
  locate: () => null,
};

ExpandableTableGroupContact.propTypes = {
  contacts: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.bool.isRequired,
  locate: PropTypes.func,
  showContactLocation: PropTypes.func.isRequired,
  showAllLocations: PropTypes.func.isRequired,
  removeContactFromGroup: PropTypes.func.isRequired,
  groupId: PropTypes.string.isRequired,
};

export default ExpandableTableGroupContact;
