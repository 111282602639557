import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Card, Dimmer, Loader } from 'semantic-ui-react';
import Flag from 'react-world-flags';

import Country from '../../utils/country';
import TimeStamp from '../../utils/timeStamp';
import capitalizeFirstCharacter from '../../utils/capitalizeFirstCharacter';

import './robotInfoComponent.sass';
import robotIconType from '../../utils/robotIconType';

const statusObject = [
  { status_code: 0, status: 'Inactive', status_color: '#878787' },
  { status_code: 1, status: 'Active running', status_color: '#d0d068' },
  { status_code: 2, status: 'Finished success', status_color: '#87d068' },
  { status_code: 3, status: 'Finished time end', status_color: '#f4a641' },
  { status_code: 4, status: 'Cannot run', status_color: '#f50' },
  { status_code: 5, status: 'Schedule finished', status_color: '#87d068' },
];

class RobotInfoComponent extends Component {
  state = {};
  render() {
    const { robotData } = this.props;
    const status = Object.values(statusObject).map((statusCode) => {
      if (this.props.robotData.status === statusCode.status_code) {
        return (
          <span key={statusCode} style={{ color: `${statusCode.status_color}` }}>
            {statusCode.status}
          </span>
        );
      }
      return null;
    });
    return (
      <Card className="robot-info-component">
        <Dimmer active={this.props.tableLoading} inverted>
          <Loader inverted content="Fetching robots..." />
        </Dimmer>
        <Card.Content>
          <div style={{ float: 'right', fontSize: 22 }}>
            {robotIconType(robotData.type, statusObject[robotData.status]?.status_color)}
          </div>
          <Card.Header className="robot-info-component__header">{this.props.robotData.name}</Card.Header>
          <h4 className="robot-info-component__header-type">{capitalizeFirstCharacter(robotData?.type)}</h4>
          <Card.Meta>
            <div className="robot-info-component__info">
              ID: <span className="robot-info-component__info_text">{robotData.id}</span>
            </div>
            <div className="robot-info-component__info">
              Status: <span className="robot-info-component__info_text">{status}</span>
            </div>
            <div className="robot-info-component__info">
              Query type:{' '}
              <span className="robot-info-component__info_text">{capitalizeFirstCharacter(robotData.query_type)}</span>
            </div>
            {robotData.configuration?.number_of_searches && (
              <div className="robot-info-component__info">
                Nr. Of Searches:{' '}
                <span className="robot-info-component__info_text">
                  {robotData.configuration?.number_of_searches || 0}
                </span>
              </div>
            )}
            {robotData.configuration?.performed_searches >= 0 ? (
              <div className="robot-info-component__info">
                Performed Searches:{' '}
                <span className="robot-info-component__info_text">
                  {robotData.configuration?.performed_searches || 0}
                </span>
              </div>
            ) : null}

            {robotData.configuration?.number_of_searches > 1 && (
              <div className="robot-info-component__info">
                Repeat time:{' '}
                <span className="robot-info-component__info_text">
                  {robotData.repeat_time} <span style={{ opacity: 0.5 }}>(min)</span>
                </span>
              </div>
            )}
            <div className="robot-info-component__info">
              Start Time:{' '}
              <span className="robot-info-component__info_text">
                <TimeStamp date={robotData.start} />
              </span>
            </div>
            {robotData.configuration?.number_of_searches === undefined ? (
              <div className="robot-info-component__info">
                End Time:{' '}
                <span className="robot-info-component__info_text">
                  <TimeStamp date={robotData.end} />
                </span>
              </div>
            ) : null}
            {robotData.type !== 'tracker' && (
              <div className="robot-info-component__info">
                Stop on result:{' '}
                <span className="robot-info-component__info_text">
                  {robotData.configuration && robotData.configuration.alert_stop ? 'Yes' : 'No'}
                </span>
              </div>
            )}
            {(robotData.type === 'geo-point' || robotData.type === 'geo-points') && (
              <div className="robot-info-component__info">
                Condition:{' '}
                <span className="robot-info-component__info_text">
                  {capitalizeFirstCharacter(robotData?.configuration?.condition)}
                </span>
              </div>
            )}
            {robotData.type && (
              <div>
                <div className="robot-info-component__info">
                  Source:{' '}
                  <span className="robot-info-component__info_text">
                    {capitalizeFirstCharacter(robotData.configuration.source)}
                  </span>
                </div>
                <div className="robot-info-component__info">
                  Source Mode:{' '}
                  <span className="robot-info-component__info_text">
                    {capitalizeFirstCharacter(robotData.configuration.source_mode)}
                  </span>
                </div>
                <div className="robot-info-component__info">
                  Source Name:{' '}
                  {robotData.source && robotData.source.length > 0 && robotData.source[0].name && (
                    <span className="robot-info-component__info_text">
                      {capitalizeFirstCharacter(robotData.source[0].name)}
                    </span>
                  )}
                </div>
                {robotData.configuration.country_code && (
                  <div>
                    <div className="robot-info-component__info">
                      Country:{' '}
                      <span className="robot-info-component__info_text">
                        <Flag code={robotData.configuration.country_code} height="14" />
                        <span>
                          {' '}
                          {Country.find((country) => country.iso === robotData.configuration.country_code).country}
                        </span>
                      </span>
                    </div>
                    <div className="robot-info-component__info">
                      Action Condition:{' '}
                      <span className="robot-info-component__info_text">
                        {robotData.configuration.country_condition === 'enter-into' ? 'Enter' : 'Exit'}
                      </span>
                    </div>
                  </div>
                )}
                {robotData.target.length !== 0 && (
                  <div>
                    <div className="robot-info-component__info">
                      Target Name:{' '}
                      {robotData.target.length > 0 && robotData.target[0].name && (
                        <span className="robot-info-component__info_text">
                          {capitalizeFirstCharacter(robotData.target[0].name)}
                        </span>
                      )}
                    </div>
                  </div>
                )}
                {robotData.configuration.distance && (
                  <div className="robot-info-component__info">
                    Distance:{' '}
                    <span className="robot-info-component__info_text">
                      {robotData.configuration.distance} <span style={{ opacity: 0.5 }}>(m)</span>
                    </span>
                  </div>
                )}
                {robotData.configuration.point && (
                  <div>
                    <div className="robot-info-component__info">
                      Lat: <span className="robot-info-component__info_text">{robotData.configuration.point[0]}</span>
                    </div>
                    <div className="robot-info-component__info">
                      Lng: <span className="robot-info-component__info_text">{robotData.configuration.point[1]}</span>
                    </div>
                  </div>
                )}
              </div>
            )}
            <div>
              <div className="robot-info-component__info">
                Description:{' '}
                <span className="robot-info-component__info_text">
                  {capitalizeFirstCharacter(robotData.description)}
                </span>
              </div>
            </div>
          </Card.Meta>
        </Card.Content>
      </Card>
    );
  }
}

RobotInfoComponent.defaultProps = {
  robots: {},
  tableLoading: true,
};
RobotInfoComponent.propTypes = {
  tableLoading: PropTypes.bool,
  robotData: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.number,
    status: PropTypes.number,
    type: PropTypes.string,
    query_type: PropTypes.string,
    description: PropTypes.string,
    updated_at: PropTypes.string,
  }).isRequired,
};
export default RobotInfoComponent;
