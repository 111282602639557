import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { Table, Spin, Select, Popover, Progress } from 'antd';
import { Button } from 'semantic-ui-react';
import Flag from 'react-world-flags';

import Country from '../../../utils/country';
import TimeStamp from '../../../utils/timeStamp';
import ResultParser from '../../jobsList/jobItem/jobContent/jobResultContent/resultParser';
import JobErrorContent from '../../jobsList/jobItem/jobContent/jobErrorContent';

import lte from '../../../assets/images/lte.png';

import './expandableTable.sass';
import { searchTypes } from '../../searchComponent/searchComponent';

class ExpandableTable extends PureComponent {
  state = {
    expandedEdit: [],
    sortedInfoTime: {
      order: 'descend',
      columnKey: 'time',
    },
    rows: 5,
    activePopup: null,
    selectedAgents: [],
    sendLoading: false,
    templates: null,
  };

  expandRow = (expanded, key, record) => {
    // AutoPin on expand fro existing location. DONE only if is closed
    if (record.actions?.props?.children[0]?.props?.disabled === false && expanded)
      record.actions?.props?.children[0]?.props?.onClick();

    const newArray = [...this.state.expandedEdit];
    const index = newArray.indexOf(key);
    if (index === -1) this.setState({ expandedEdit: [...this.state.expandedEdit, key] });
    else {
      newArray.splice(index, 1);
      this.setState({
        expandedEdit: newArray,
      });
    }
  };

  pinAction = (id, lat, lng, name, update, range, startAngle, endAngle, mcc, mnc, lac, cellId) => {
    this.props.pinAction(id, lat, lng, name, update, range, startAngle, endAngle, mcc, mnc, lac, cellId);
    window.scrollTo(0, 0);
  };

  setRows = (value) => {
    this.setState({ rows: parseInt(value, 10) });
  };

  returnColor = (index) => {
    const blue = Math.floor(index % 256);
    const green = Math.floor((index / 256) % 256);
    const red = Math.floor((index / 256 / 256) % 256);

    return `rgb(${red},${green},${blue})`;
  };

  handleChange = (pagination, filters, sorter) => {
    this.setState({
      sortedInfoTime: sorter,
      rows: pagination.pageSize,
    });
  };

  setActivePopupNotification = (id) => {
    if (id === this.state.activePopup) this.setState({ activePopup: null });
    else this.setState({ activePopup: id });
  };
  sendMessage = (location) => {
    const {
      robot_id: robotId,
      mcc,
      lac,
      cell_id: cellId,
      name,
      imsi,
      phone,
      lat,
      long,
      mnc,
      range,
      age,
      location_time: locationTime,
    } = location;

    if (this.state.selectedAgents.length > 0) {
      this.setState({ sendLoading: true }, () => {
        this.props
          .sendMessage({
            agent_ids: this.state.selectedAgents,
            location: {
              robot_id: robotId,
              mcc,
              lac,
              cell_id: cellId,
              name,
              imsi,
              phone,
              lat,
              long,
              mnc,
              range,
              age,
              location_time: locationTime,
            },
          })
          .then(() => this.setState({ activePopup: null, sendLoading: false }));
      });
    }
  };

  getSearchName = (location) => {
    const { api_request_type, name, imsi, phone, additional_info, query_type } = location;

    if (location.resolved === 0) return '';

    if (location.code)
      return (
        <div
          style={{
            color: 'red',
            fontWeight: 'bold',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >{`Error code: ${location.code} - ${location.title}`}</div>
      );

    switch (api_request_type) {
      case 1: {
        return name;
      }
      case 4: {
        return imsi;
      }
      case 5: {
        return phone;
      }
      case 6: {
        if (additional_info && additional_info.data) {
          if (additional_info.data.forwarded) return additional_info.data.forwardingDetails;
          else if (additional_info.data.forwarded === null) return 'NULL';
          else if (additional_info.data.forwarded === false) return 'FALSE';
          return 'Forward search failed';
        } else return 'Result failed';
      }
      case 7: {
        if (additional_info && additional_info.data && additional_info.data.dosState !== null)
          return additional_info.data.dosState;
        else return 'Result failed';
      }
      case 8: {
        if (additional_info && additional_info.data) {
          if (
            additional_info.data.subscriptionWith &&
            (additional_info.data.subscriptionWith?.errorCode || additional_info.data?.numberOwnership?.errorCode)
          ) {
            if (additional_info.data.subscriptionWith?.errorCode)
              return (
                <div
                  style={{
                    color: 'red',
                    fontWeight: 'bold',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >{`Error code: ${additional_info.data.subscriptionWith.errorCode} - ${additional_info.data.subscriptionWith.errorDetail}`}</div>
              );
            if (additional_info.data.numberOwnership?.errorCode)
              return (
                <div
                  style={{
                    color: 'red',
                    fontWeight: 'bold',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >{`Error code: ${additional_info.data.numberOwnership.errorCode} - ${additional_info.data.numberOwnership.errorDetail}`}</div>
              );
          } else if (additional_info.data.found) {
            if (query_type === 'passive')
              return additional_info.data.numberOwnership && additional_info.data.numberOwnership.networkName;
            else if (query_type === 'active')
              return additional_info.data.subscriptionWith && additional_info.data.subscriptionWith.networkName;
          }

          return 'Result failed';
        } else return 'Result failed';
      }
      default:
        return !location.name || location.code ? 'No location name' : location.name;
    }
  };

  getTemplates = () => {
    if (this.state.templates === null) {
      this.setState((state) => ({ ...state, templates: { data: [], loading: true } }));
      this.props.getLocalNotifications().then((res) => {
        if (res && Object.values(res).length > 0) {
          const notifications = [];
          Object.keys(res).forEach((key) => notifications.push(res[key]));
          this.setState((state) => ({ ...state, templates: { data: notifications, loading: false } }));
        }
      });
    }
  };

  render() {
    const { sortedInfoTime } = this.state;
    const { darkMode } = this.props;
    const columns = [
      {
        className: 'location-name',
        title: 'Location name',
        dataIndex: 'locationName',
        width: '45%',
        key: 'locationName',
        render: (text, data) => (
          <div>
            {data.interval && (
              <span style={{ position: 'absolute', left: '-5px', top: 19 }}>
                <div style={{ width: 5, height: 5, backgroundColor: '#d48f0e', borderRadius: '50%' }} />
              </span>
            )}
            {text}
          </div>
        ),
      },
      { className: 'country', title: 'Country', dataIndex: 'country', width: '23%', key: 'country' },
      {
        title: 'Location time',
        dataIndex: 'time',
        width: '22%',
        key: 'time',
        sorter: (a, b) => {
          const c = new Date(a.time.props.date);
          const d = new Date(b.time.props.date);
          return c - d;
        },
        sortOrder: sortedInfoTime.columnKey === 'time' && sortedInfoTime.order,
      },
      { title: 'Actions', dataIndex: 'actions', width: '10%', key: 'actions' },
    ];

    const data =
      this.props.locations && this.props.locations.length > 0
        ? this.props.locations.map((location) => {
            const key = `key-${location.id}`;
            return {
              key,
              interval: !!location.interval,
              locationName: (
                <Spin spinning={location.resolved === 0}>
                  <div
                    className="table-location-name-color"
                    style={{
                      backgroundColor: this.returnColor(location.phone ? location.phone : location.imsi),
                    }}
                  />
                  {location?.serving_tech === 'EUTRAN' && <img src={lte} alt="lte" className="img-lte-location" />}
                  <div className="table-location-name">
                    <div className="table-location-name__type">
                      {location.api_request_type ? searchTypes[location.api_request_type] : 'No search type'}
                    </div>
                    {location.resolved ? (
                      <div className="table-location-name__name">{this.getSearchName(location)}</div>
                    ) : (
                      ''
                    )}
                  </div>
                </Spin>
              ),
              country: (
                <div>
                  {location.country_iso ? (
                    <span>
                      <span className="flag-component" style={{ filter: darkMode ? 'invert(1)' : 'initial' }}>
                        <Flag code={location.country_iso} height="14" />
                      </span>
                      <span> {Country.find((e) => e.iso === location.country_iso).country}</span>
                    </span>
                  ) : location.resolved ? (
                    !location.code && location.api_request_type === 1 && 'No location'
                  ) : (
                    <div className="table-location-name__loading-percentage">
                      <Progress
                        percent={
                          (location &&
                            location.rawMessage &&
                            location.rawMessage.data &&
                            location.rawMessage.data.progress) ||
                          0
                        }
                        size="small"
                        status="active"
                      />
                    </div>
                  )}
                </div>
              ),
              time: <TimeStamp date={location.location_time} />,
              actions: (
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Button
                    disabled={!location.lat}
                    onClick={() =>
                      this.pinAction(
                        location.id,
                        parseFloat(location.lat),
                        parseFloat(location.lng),
                        location.name,
                        location.location_time,
                        location.range,
                        location.angle_start,
                        location.angle_end,
                        location.mcc,
                        location.mnc,
                        location.lac,
                        location.cell_id,
                      )
                    }
                    size="mini"
                    circular
                    icon="map pin"
                  />
                  <Popover
                    placement="topRight"
                    content={
                      this.props.sms === 1 ? (
                        <div>
                          <div style={{ height: '30px' }}>
                            {this.state.templates?.loading && (
                              <div>
                                <Spin />
                                <span style={{ marginLeft: 10 }}>Checking for existing Templates</span>
                              </div>
                            )}
                            {this.state.templates?.data?.length > 0 && this.state.templates.data[0].content ? (
                              <p>You can check your existing template {<Link to="/profile">here</Link>}</p>
                            ) : (
                              <div>
                                <p>
                                  You don't have any template. Please <Link to="/profile">create</Link> one
                                </p>
                              </div>
                            )}
                          </div>
                          <Select
                            optionFilterProp="label"
                            showSearch
                            mode="multiple"
                            style={{ width: 400 }}
                            placeholder="Select Agents(s)"
                            onChange={(e) => this.setState({ selectedAgents: e })}
                            value={this.state.selectedAgents}
                            options={
                              this.props.agents
                                ? Object.values(this.props.agents)
                                    .reverse()
                                    .map((agent) => ({ label: agent.name, value: agent.id }))
                                : []
                            }
                          />
                          <div style={{ display: 'flex', marginTop: 30 }}>
                            <Button
                              negative
                              compact
                              size="mini"
                              onClick={() => this.setState({ activePopup: null, selectedAgents: [] })}
                            >
                              Cancel
                            </Button>
                            <Button
                              loading={this.state.sendLoading}
                              positive
                              compact
                              size="mini"
                              style={{ marginLeft: 10 }}
                              onClick={() => this.sendMessage(location)}
                            >
                              Send
                            </Button>
                          </div>
                        </div>
                      ) : (
                        <div>SMS service not configured</div>
                      )
                    }
                    title={this.props.sms === 1 ? 'Select Agent(s)' : 'SMS Disabled'}
                    trigger="click"
                    onClick={this.getTemplates}
                    visible={this.state.activePopup === location.id}
                  >
                    <Button
                      circular
                      size="mini"
                      icon="mail"
                      onClick={() => this.setActivePopupNotification(location.id)}
                    />
                  </Popover>
                </div>
              ),
              expand: (
                <div className="expandable-table-component-contact__expandable">
                  {(location.api_request_type === 1 || location.api_request_type === null) && !location.code ? (
                    <React.Fragment>
                      {!location.code && (
                        <div className="expandable-table-component-contact__expandable_other-information">
                          <div className="expanded-row">
                            <div className="expanded-row__item">
                              <div className="expanded-row__item_name">MCC</div>
                              <div className="expanded-row__item_value">{location.mcc}</div>
                            </div>
                            <div className="expanded-row__item">
                              <div className="expanded-row__item_name">MNC</div>
                              <div className="expanded-row__item_value">{location.mnc}</div>
                            </div>
                            <div className="expanded-row__item">
                              <div className="expanded-row__item_name">LAC/TAC</div>
                              <div className="expanded-row__item_value">{location.lac}</div>
                            </div>
                            <div className="expanded-row__item">
                              <div className="expanded-row__item_name">CELLID</div>
                              <div className="expanded-row__item_value">
                                {location.cell_id} {location.sensor_serial && `(${location.sensor_serial})`}
                                {location.serving_tech === 'EUTRAN' && (
                                  <img
                                    src={lte}
                                    alt="lte"
                                    style={{
                                      width: 14,
                                      height: 14,
                                      boxShadow: 'none',
                                      marginLeft: 5,
                                    }}
                                  />
                                )}
                              </div>
                            </div>
                            <div className="expanded-row__item">
                              <div className="expanded-row__item_name">Lat / Lng</div>
                              <div className="expanded-row__item_value">
                                {location.lat} / {location.lng}
                              </div>
                            </div>
                            <div className="expanded-row__item">
                              <div className="expanded-row__item_name">Azimuth</div>
                              <div className="expanded-row__item_value">
                                {location.width !== 360 && (
                                  <div>
                                    <span>{location.angle}&#176;</span> <span>({location.width}&#176;)</span>
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="expanded-row__item">
                              <div className="expanded-row__item_name">Range</div>
                              <div className="expanded-row__item_value">
                                {location.range} {location.range !== null && <span style={{ opacity: 0.5 }}>(m)</span>}
                              </div>
                            </div>
                            <div className="expanded-row__item">
                              <div className="expanded-row__item_name">Age</div>
                              <div className="expanded-row__item_value">
                                {location.age ? `${location.age}` : '0'} <span style={{ opacity: 0.5 }}>(min)</span> at{' '}
                                {<TimeStamp date={location.updated_at} />}{' '}
                                {(location.age === 0 || location.age === 1) && <span className="age-blink-dot" />}
                              </div>
                            </div>
                          </div>
                          <div className="expanded-row">
                            <div className="expanded-row__item">
                              <div className="expanded-row__item_name">IMEI</div>
                              <div className="expanded-row__item_value">{location.imei}</div>
                            </div>
                            <div className="expanded-row__item">
                              <div className="expanded-row__item_name">State</div>
                              <div className="expanded-row__item_value">{location.state}</div>
                            </div>
                            <div className="expanded-row__item">
                              <div className="expanded-row__item_name">Phone</div>
                              <div className="expanded-row__item_value">{location.phone}</div>
                            </div>
                            <div className="expanded-row__item">
                              <div className="expanded-row__item_name">IMSI</div>
                              <div className="expanded-row__item_value">{location.imsi}</div>
                            </div>
                            <div className="expanded-row__item">
                              <div className="expanded-row__item_name">Start Time</div>
                              <div className="expanded-row__item_value">
                                {location.interval && <TimeStamp date={location.interval.start} />}
                              </div>
                            </div>
                            <div className="expanded-row__item">
                              <div className="expanded-row__item_name">End Time</div>
                              <div className="expanded-row__item_value">
                                {location.interval && <TimeStamp date={location.interval.end} />}
                              </div>
                            </div>
                            <div className="expanded-row__item">
                              <div className="expanded-row__item_name">Locations</div>
                              <div className="expanded-row__item_value">
                                {location.interval?.location_ids
                                  ? Object.values(location.interval.location_ids)?.length
                                  : ''}
                              </div>
                            </div>
                            <div className="expanded-row__item">
                              <div className="expanded-row__item_name">Network</div>
                              <div className="expanded-row__item_value">{location.network}</div>
                            </div>
                          </div>
                        </div>
                      )}
                      {!location.code && location.name && (
                        <div className="expanded-row-location">
                          <div className="expanded-row-location__item">
                            <div className="expanded-row-location__item_name">Location name</div>
                            <div className="expanded-row-location__item_value">{location.name}</div>
                          </div>
                        </div>
                      )}
                      {location.code && (
                        <div className="expanded-row-location">
                          <div className="expanded-row-location__item">
                            <div className="expanded-row-location__item_name --location-error-code">
                              {`Error code: ${location.code}`}
                            </div>
                            <div className="expanded-row-location__item_value">{location.detail}</div>
                          </div>
                        </div>
                      )}
                    </React.Fragment>
                  ) : location.code ? (
                    <JobErrorContent data={location} />
                  ) : (
                    <ResultParser data={location} />
                  )}
                </div>
              ),
            };
          })
        : [];

    return (
      <div className={`expandable-table-component-contact ${this.props.className}`}>
        <Table
          loading={this.props.loading}
          columns={columns}
          dropdownPrefixCls=""
          onChange={this.handleChange}
          pagination={{ pageSize: this.state.rows, showSizeChanger: true, pageSizeOptions: [5, 10, 20, 50, 100] }}
          expandedRowRender={(record) => <div>{record.expand}</div>}
          expandedRowKeys={this.state.expandedEdit}
          onExpand={(expanded, record) => this.expandRow(expanded, record.key, record)}
          dataSource={data}
          size="middle"
          showHeader={false}
        />
      </div>
    );
  }
}

ExpandableTable.defaultProps = {
  locations: {},
  className: '',
  agents: {},
  sendMessage: () => null,
  sms: 0,
};

ExpandableTable.propTypes = {
  className: PropTypes.string,
  locations: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  loading: PropTypes.bool.isRequired,
  agents: PropTypes.shape({}),
  pinAction: PropTypes.func.isRequired,
  sendMessage: PropTypes.func,
  getLocalNotifications: PropTypes.func,
  sms: PropTypes.number,
  darkMode: PropTypes.bool.isRequired,
};

export default ExpandableTable;
