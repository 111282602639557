import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import history from '../../utils/history';

import { getAllContacts } from '../../actions/contacts/contact';
import { getGroups } from '../../actions/groups/groups';
import { getSearchJobs } from '../../actions/jobs/jobs';

import Notice from '../../components/notice';
import NavigationMenu from '../../components/navigationMenu';
import SearchComponent from '../../components/searchComponent';
import JobsList from '../../components/jobsList';
import TableGroups from '../../components/dashboardTables/dashboardTables';
import DashboardRobots from '../../components/dashboardRobots/dashboardRobots';

import './dashboardPage.sass';
import '../DashboardPage/dashboardPage.sass';
import { dispatchNotificationOrSound } from '../../utils/notificationAndSound';

class DashboardPage extends PureComponent {
  state = {
    tableLoading: true,
  };

  componentDidMount() {
    const { jobList } = this.props;

    if (jobList === null || jobList.length === 0) this.props.getSearchJobs();
    this.props.getAllContacts();
    this.props.getGroups();
  }

  render() {
    const {
      user: { sticky_header: stickyHeader, notice },
    } = this.props;

    return (
      <div>
        <NavigationMenu statusPage="dashboard-new" />
        <div className={`dashboard-page-new ${stickyHeader ? '--fixed' : ''}`}>
          {notice && <Notice notice={notice} />}
          <SearchComponent />
          <JobsList />
          <TableGroups />
          <DashboardRobots />
          <License onClick={() => history.push('/license')}>Legal</License>
        </div>
      </div>
    );
  }
}

const License = styled.div`
  font-size: 13px;
  color: #bfbfbf;
  padding-bottom: 20px;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

DashboardPage.defaultProps = {
  dashboardInfo: {},
  user: {},
};

DashboardPage.propTypes = {
  getSearchJobs: PropTypes.func.isRequired,
  getAllContacts: PropTypes.func.isRequired,
  getGroups: PropTypes.func.isRequired,
  user: PropTypes.shape({
    sms_service: PropTypes.number,
    sticky_header: PropTypes.number,
    notice: PropTypes.string,
  }),
};

const mapStateToProps = (store) => ({
  dashboardInfo: store.contact.dashboardInfo,
  user: store.user,
  jobList: store.jobs.jobList,
});

export default connect(mapStateToProps, {
  getSearchJobs,
  getAllContacts,
  getGroups,
})(DashboardPage);
