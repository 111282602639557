import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Input, Tag, message } from 'antd';
import { Button } from 'semantic-ui-react';
import Highlighter from 'react-highlight-words';

import { getLocalNotifications, updateLocalNotifications } from '../../../actions/smsNotifications/smsNotifications';

import highlightedWords from '../../../utils/highlightedWords';
import DropDown from '../../../components/addDropDown';
import getCursorPos from '../../../utils/getTextAreaPos';

import './setUpLocalNotification.sass';

class ChangePhoneEmail extends Component {
  state = {
    notificationTemplate: '',
    textAreaPos: null,
    notificationTemplateLoading: false,
  };

  componentDidMount() {
    this.props.getLocalNotifications().then((notificationData) => {
      this.setState({
        notificationTemplate:
          notificationData[Object.values(notificationData).filter((notif) => notif.type === 2)[0]?.id]?.content,
      });
    });
  }

  onChangeTemplate = (e) =>
    this.setState({
      notificationTemplate: e.target.value,
      textAreaPos: getCursorPos(document.getElementById('text-area-notification')),
    });

  handleVariableClick = (e) => {
    const value = e.target.getAttribute('value');

    const stateValue = this.state.notificationTemplate;
    const textarea = document.getElementById('text-area-notification');
    const hasValue = stateValue && stateValue.length > 0;
    const newText = hasValue ? `${stateValue} ${value}` : value;

    if (textarea) {
      textarea.focus();
    }

    this.setState({
      notificationTemplate: newText,
      textAreaPos: {
        start: newText.length,
        end: newText.length,
      },
    });
  };

  focusTextArea = () => {
    this?.notificationTextArea?.focus();
    this.setState({
      textAreaPos: {
        start: this.state.notificationTemplate !== null ? this.state.notificationTemplate.length : 0,
        end: this.state.notificationTemplate !== null ? this.state.notificationTemplate.length : 0,
      },
    });
  };

  updateLocalNotifications = () => {
    const templateId = Object.values(this.props.localNotifications).filter((notif) => notif.type === 2)[0].id;

    this.setState({ notificationTemplateLoading: true }, () => {
      this.props
        .updateLocalNotifications(templateId, { content: this.state.notificationTemplate })
        .then(() => {
          this.setState({ notificationTemplateLoading: false });
          message.success('Template was updated');
        })
        .catch((err) => message.success(err.response?.data?.message || 'Something went wrong. Update failed!'));
    });
  };

  render() {
    const { notificationTemplate } = this.state;
    return (
      <div className="change-notification-method">
        <DropDown
          className="super-admin-content__drop-down"
          buttonName="Agent template settings"
          height={370}
          onClick={this.focusTextArea}
        >
          <div className="super-admin-content__drop-down_sms-wrap">
            <div className="super-admin-content__drop-down_sms-wrap__variables">
              <Tag color="blue" value="%RESULT_MCC%" onClick={this.handleVariableClick}>
                RESULT_MCC
              </Tag>
              <Tag color="blue" value="%RESULT_MNC%" onClick={this.handleVariableClick}>
                RESULT_MNC
              </Tag>
              <Tag color="blue" value="%RESULT_LAC%" onClick={this.handleVariableClick}>
                RESULT_LAC
              </Tag>
              <Tag color="blue" value="%RESULT_CELLID%" onClick={this.handleVariableClick}>
                RESULT_CELLID
              </Tag>
              <Tag color="blue" value="%RESULT_MSISDN%" onClick={this.handleVariableClick}>
                RESULT_MSISDN
              </Tag>
              <Tag color="blue" value="%RESULT_IMSI%" onClick={this.handleVariableClick}>
                RESULT_IMSI
              </Tag>
              <Tag color="blue" value="%RESULT_LAT%" onClick={this.handleVariableClick}>
                RESULT_LAT
              </Tag>
              <Tag color="blue" value="%RESULT_LON%" onClick={this.handleVariableClick}>
                RESULT_LON
              </Tag>
              <Tag color="blue" value="%RESULT_LOCATION_NAME%" onClick={this.handleVariableClick}>
                RESULT_LOCATION_NAME
              </Tag>
              <Tag color="blue" value="%RESULT_LOCATION_TIME%" onClick={this.handleVariableClick}>
                RESULT_LOCATION_TIME
              </Tag>
              <Tag color="blue" value="%ROBOT_ID%" onClick={this.handleVariableClick}>
                ROBOT_ID
              </Tag>
              {this.props.place !== 'profile' && (
                <React.Fragment>
                  <Tag color="blue" value="%2NDCONTACT_MCC%" onClick={this.handleVariableClick}>
                    2NDCONTACT_MCC
                  </Tag>
                  <Tag color="blue" value="%2NDCONTACT_MNC%" onClick={this.handleVariableClick}>
                    2NDCONTACT_MNC
                  </Tag>
                  <Tag color="blue" value="%2NDCONTACT_LAC%" onClick={this.handleVariableClick}>
                    2NDCONTACT_LAC
                  </Tag>
                  <Tag color="blue" value="%2NDCONTACT_CELLID%" onClick={this.handleVariableClick}>
                    2NDCONTACT_CELLID
                  </Tag>
                  <Tag color="blue" value="%2NDCONTACT_MSISDN%" onClick={this.handleVariableClick}>
                    2NDCONTACT_MSISDN
                  </Tag>
                  <Tag color="blue" value="%2NDCONTACT_IMSI%" onClick={this.handleVariableClick}>
                    2NDCONTACT_IMSI
                  </Tag>
                  <Tag color="blue" value="%2NDCONTACT_LAT%" onClick={this.handleVariableClick}>
                    2NDCONTACT_LAT
                  </Tag>
                  <Tag color="blue" value="%2NDCONTACT_LON%" onClick={this.handleVariableClick}>
                    2NDCONTACT_LON
                  </Tag>
                  <Tag color="blue" value="%2NDCONTACT_LOCATION_NAME%" onClick={this.handleVariableClick}>
                    2NDCONTACT_LOCATION_NAME
                  </Tag>
                  <Tag color="blue" value="%RESULT_LOCATION_TIME%" onClick={this.handleVariableClick}>
                    RESULT_LOCATION_TIME
                  </Tag>
                  <Tag color="blue" value="%ROBOT_ID%" onClick={this.handleVariableClick}>
                    ROBOT_ID
                  </Tag>
                  <div className="super-admin-content__drop-down_sms-wrap__variables_br" />
                  <Tag color="blue" value="%FOUND_DISTANCE%" onClick={this.handleVariableClick}>
                    FOUND_DISTANCE
                  </Tag>
                  <Tag color="blue" value="%SET_DISTANCE%" onClick={this.handleVariableClick}>
                    SET_DISTANCE
                  </Tag>
                  <div className="super-admin-content__drop-down_sms-wrap__variables_br" />
                  <Tag color="blue" value="%COUNTRY_CODE%" onClick={this.handleVariableClick}>
                    COUNTRY_CODE
                  </Tag>
                  <Tag color="blue" value="%COUNTRY_CONDITION%" onClick={this.handleVariableClick}>
                    COUNTRY_CONDITION
                  </Tag>
                  <div className="super-admin-content__drop-down_sms-wrap__variables_br" />
                  <Tag color="blue" value="%EXPLANATION%" onClick={this.handleVariableClick}>
                    EXPLANATION
                  </Tag>
                  <Tag color="blue" value="%STATUS%" onClick={this.handleVariableClick}>
                    STATUS
                  </Tag>
                  <div className="super-admin-content__drop-down_sms-wrap__variables_br" />
                  <Tag color="blue" value="%FIXED_POINT_LAT%" onClick={this.handleVariableClick}>
                    FIXED_POINT_LAT
                  </Tag>
                  <Tag color="blue" value="%FIXED_POINT_LON%" onClick={this.handleVariableClick}>
                    FIXED_POINT_LON
                  </Tag>
                  <Tag color="blue" value="%FOUND_CONTACTS_WITH_DISTANCE%" onClick={this.handleVariableClick}>
                    FOUND_CONTACTS_WITH_DISTANCE
                  </Tag>
                  <div className="super-admin-content__drop-down_sms-wrap__variables_br" />
                  <Tag color="blue" value="%ROBOT_NAME%" onClick={this.handleVariableClick}>
                    ROBOT_NAME
                  </Tag>
                  <div className="super-admin-content__drop-down_sms-wrap__variables_br" />
                  <Tag color="blue" value="%RESULT_AGE%" onClick={this.handleVariableClick}>
                    RESULT_AGE
                  </Tag>
                  <Tag color="blue" value="%RESULT_RADIUS%" onClick={this.handleVariableClick}>
                    RESULT_RADIUS
                  </Tag>
                  <div className="super-admin-content__drop-down_sms-wrap__variables_br" />
                  <Tag color="blue" value="%2NDCONTACT_AGE%" onClick={this.handleVariableClick}>
                    2NDCONTACT_AGE
                  </Tag>
                  <Tag color="blue" value="%2NDCONTACT_RADIUS%" onClick={this.handleVariableClick}>
                    2NDCONTACT_RADIUS
                  </Tag>
                  <Tag color="blue" value="%2NDCONTACT_LOCATION_TIME%" onClick={this.handleVariableClick}>
                    2NDCONTACT_LOCATION_TIME
                  </Tag>
                </React.Fragment>
              )}
            </div>
            <div className="text-area-highlight" style={{ display: 'flex' }}>
              <Input.TextArea
                className="text-area-highlight__text-only"
                id="text-area-notification"
                onClick={() =>
                  this.setState({ textAreaPos: getCursorPos(document.getElementById('text-area-notification')) })
                }
                ref={(ref) => {
                  this.notificationTextArea = ref;
                  return null;
                }}
                rows={9}
                autosize={{ minRows: 9, maxRows: 9 }}
                onChange={this.onChangeTemplate}
                value={notificationTemplate}
              />
              <Highlighter
                className="text-area-highlight__highlighted-text"
                searchWords={highlightedWords}
                autoEscape={!!1}
                textToHighlight={notificationTemplate === null ? '' : notificationTemplate}
              />
            </div>
            <Button
              loading={this.state.notificationTemplateLoading}
              style={{ marginTop: 20 }}
              onClick={this.updateLocalNotifications}
              size="mini"
            >
              Update
            </Button>
          </div>
        </DropDown>
      </div>
    );
  }
}

ChangePhoneEmail.propTypes = {
  localNotifications: PropTypes.shape({}).isRequired,
  getLocalNotifications: PropTypes.func.isRequired,
  updateLocalNotifications: PropTypes.func.isRequired,
  place: PropTypes.string.isRequired,
};

const mapStateToProps = (store) => ({
  localNotifications: store.smsNotifications.localNotifications,
});

export default connect(mapStateToProps, {
  getLocalNotifications,
  updateLocalNotifications,
})(ChangePhoneEmail);
